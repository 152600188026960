import {postAjax,getAjax}  from "../common/axios.js"

//获取总店课程列表
export const adminList = params => {
    return postAjax('/api/run/courseName/adminList',params);
}

//获取门店课程列表
export const courseList = params => {
    return postAjax('/api/run/courseName/list',params);
}

export const listSimpleAll = params => {
    return postAjax('/api/run/courseName/listSimple?limit=9999',params);
}

//获取训练目的列表
export const trainPurposeList = params => {
    return postAjax('/api/run/trainPurpose/list',params);
}

//获取训练目的列表[全部]
export const trainPurposeListAll = params => {
    return postAjax('/api/run/trainPurpose/list?limit=9999',params);
}

//新增训练目的
export const trainPurposeInsert = params => {
    return postAjax('/api/run/trainPurpose/insert',params);
}

//编辑训练目的
export const trainPurposeUpdate = params => {
    return postAjax('/api/run/trainPurpose/update',params);
}
//删除训练目的
export const trainPurposeDelete = params => {
    return postAjax('/api/run/trainPurpose/delete',params);
}

//新增课程
export const courseInsert = params => {
    return postAjax('/api/run/courseName/insert',params);
}
//编辑课程
export const courseUpdate = params => {
    return postAjax('/api/run/courseName/update',params);
}

//课程详情
export const courseInfo = params => {
    return postAjax('/api/run/courseName/info',params);
}

// 班级编辑
export const editCourseCycle = params => {
    return postAjax('/api/course/editCourseCycle',params);
}

//查看教练
export const queryCourseTeacher = params => {
    return postAjax('/api/run/courseName/queryCourseTeacher',params);
}

//课程开启、关闭/设置价格
export const updateStateOrValue = params => {
    return postAjax('/api/run/courseName/updateStateOrValue',params);
}

// 课节详情
export const courseToEdit = params => {
    return getAjax('/api/course/courseToEdit',params);
}
// 编辑课节
export const courseEdit = params => {
    return postAjax('/api/course/courseEdit',params);
}

// 获取课节的教练
export const appointment = params => {
    return postAjax('/api/member/appointment/getAppointmentCourseNamePrivateDetail',params);
}

// 获取课节的教练的日程
export const getTeacherSchedule = params => {
    return postAjax('/api/member/appointment/getTeacherScheduleByStore',params);
}

// 获取会员可用资产数量
export const getAvailableAssets = params => {
    return postAjax('/api/member/appointment/getAppointmentCourseNamePrivateDetail',params);
}

// 获取会员可用资产
export const getAppointmentCards = params => {
    return postAjax('/api/member/appointment/getAppointmentCards',params);
}

// 新增私教预约
export const storeBook = params => {
    return postAjax('/api/member/appointment/storeBook',params);
}

// 编辑私教预约
export const courseTimeEditPlus = params => {
    return postAjax('/api/course/courseTimeEditPlus',params);
}

// 签到
export const courseInfoSign = params => {
    return postAjax('/api/course/courseInfoSign',params);
}

// 候补入课
export const courseAfterBatchTurnClass = params => {
    return postAjax('/api/course/courseAfterBatchTurnClass',params);

}
// 删除课程
export const courseNameDelete = params => {
    return postAjax('/api/course/courseNameDelete', params);

}
// 取消预约
export const storeBookCancel = params => {
    return postAjax('/api/member/appointment/storeBookCancel', params);
}

// 取消候补
export const storeBookAfterCancel = params => {
    return postAjax('/api/member/appointment/storeBookAfterCancel', params);
}


// 新增班级
export const addCourseCycle = params => {
    return postAjax('/api/course/addCourseCycle', params);
}

// 班级详情
export const courseCycleInfo = params => {
    return postAjax('/api/course/getCourseCycleInfo', params);
}

// 班级列表
export const courseCycleList = params => {
    return postAjax('/api/course/getCourseCycleList', params);
}

// 班级列表-下拉框
export const courseCycleListSimple = params => {
    return postAjax('/api/course/getCourseCycleListSimple', params);
}

// 班级的全部课节
export const coursegetCycleCourse = params => {
    return postAjax('/api/course/getCycleCourse', params);
}

// 班级在班会员
export const courseCycleStu = params => {
    return postAjax('/api/course/getCourseCycleStu', params);
}

// 班级出勤详情
export const courseCycleStatis = params => {
    return postAjax('/api/course/getCourseCycleStatis', params);
}

// 班级排课
export const courseCycleArrange = params => {
    return postAjax('/api/course/courseCycleArrange', params);
}

// 班级课表
export const courseListPlus = params => {
    return getAjax('/api/course/getCourseListPlus', params);
}

// 班级课表-列表模式
export const courseListPagePlus = params => {
    return getAjax('/api/course/getCourseListPagePlus', params);
}

// 班级全部课节-多条件搜索
export const cycleCourseList = params => {
    return postAjax('/api/course/getCycleCourseList', params);
}

// 撤销请假
export const courseInfoLeaveCancel = params => {
    return postAjax('/api/course/courseInfoLeaveCancel', params);
}

// 请假
export const courseInfoLeave = params => {
    return postAjax('/api/course/courseInfoLeave', params);
}

// 查询班级待补课的请假人员
export const cycleLeaveStu = params => {
    return getAjax('/api/course/getCycleLeaveStu', params);
}

// 补课排课安排
export const addReCourse = params => {
    return postAjax('/api/course/addReCourse', params, 'json');
}

// 班级删除
export const delCourseCycle = params => {
    return postAjax('/api/course/delCourseCycle', params);
}

// 班级主题编辑
export const updateCourseCustomName = params => {
    return postAjax('/api/course/updateCourseCustomName', params);
}

// 班级完结
export const closeCourseCycle = params => {
    return postAjax('/api/course/closeCourseCycle', params);
}
