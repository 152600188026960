<!-- 私教工作时间表 -->
<template>
    <workArea :barlist="barlist">
        <div class="p20">
            <div class="rx-bc h30">
                <div class="rx-sc">
                    <div class="w200 h30 mr12">
                        <el-select multiple collapse-tags clearable filterable v-model="pageData.searchForm.sysUserId" placeholder="请选择教练">
                            <el-option v-for="item in pageData.teacherList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </div>
                    <el-button @click="search" type="primary" :icon="Search">查询</el-button>
                </div>
                <div class="rx-cc">
                    <el-button  @click="changeWeek(-1)" >上周</el-button>
                    <div class="ml16 mr16">{{pageData.weekstart}}~{{pageData.weekend}}</div>
                    <el-button  @click="changeWeek(1)">下周</el-button>
                </div>
                <div class="rx-ec">
                    <el-button type="primary" @click="popSetPrivateTime">设置教练工作时间</el-button>
                    <el-button type="danger" plain @click="batchRemove" v-if="utils.getMenuRole(3150103)">批量删除本周</el-button>
                    <el-button type="danger" plain @click="allRemove" v-if="utils.getMenuRole(3150104)">删除全部排期</el-button>
                </div>
            </div>
            <div class="mt20 tips">
                <img class="w20 v_a_m ml10 mr5 ft12" src="@/assets/i_icon.png" alt="">
                <span class="v_a_m color-666">变更教练的排期时间仅可以变更未来日期，对于过去（即今日前不含今日）已排好的时间将不支持被删除或修改。</span>
            </div>
            <div class="pt20" v-loading="pageData.loadingTable">
                <el-table ref="multipleTable" stripe :data="pageData.tableData" :row-key="getRowKey" style="width: 100%" @selection-change="selectionChange" height="66vh">
                    <el-table-column type="selection" :reserve-selection="true" align="center" width="55"/>
                	<el-table-column prop="realName" fixed width="100" label="教练姓名" show-overflow-tooltip />
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[0] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week1 && scope.row.week1 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[1] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week2 && scope.row.week2 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[2] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week3 && scope.row.week3 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[3] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week4 && scope.row.week4 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[4] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week5 && scope.row.week5 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                           <span>{{ pageData.tableDataTitle[5] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week6 && scope.row.week6 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip>
                        <template #header>
                            <span>{{ pageData.tableDataTitle[6] }}</span>
                        </template>
                        <template #default="scope">
                            <span>{{ scope.row.week7 && scope.row.week7 || '-'}} </span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template #default="scope">
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="editThisWeek(scope.row)"  v-if="utils.getMenuRole(3150102)">编辑本周</a>
                            <a href="javascript:;" class="t_d_u color-F96667 tool_btn_r" @click="deleThisWeek(scope.row.sysUserId)" v-if="utils.getMenuRole(3150101)">删除本周</a>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="暂无数据"></el-empty>
                    </template>
                </el-table>
                <div class="mt20 rx-ec">
                    <el-pagination
                      v-model:currentPage="pageData.searchForm.page"
                      :page-sizes="[15,20,30,50,100]"
                      :page-size="pageData.searchForm.limit"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="pageData.totalCount"
                      @size-change="pageSizeChange"
                      @current-change="getTableData"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <setPrivateTime ref="setTimeMethods" @close="closeLsit"></setPrivateTime>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { ElMessage } from 'element-plus'
    import { Search } from '@element-plus/icons-vue'
    import moment from "moment";
    import value from '@/common/utils.js';
    import workArea from '@/components/branch-store/workArea.vue';
    import setPrivateTime from '@/components/branch-store/staff/setPrivateTime.vue';
    import Confirm from '@/components/Confirm.vue'
    import { sysUserScheduleList, deleteBatch } from '@/api/courseInfo.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import utils from '@/common/utils.js';

    const router    = useRouter();
    const barlist   = reactive([{ name : '返回', type:0 },{ name : '私教工作时间表' }]); // 面包屑
    const setTimeMethods = ref();
    const multipleTable = ref()
    const confirmRef = ref();
    const pageData = reactive({
        loadingTable: false,
        selectCheckData: [], // 选择的数据
        tableWeekNum : 0,      // 当前周编号
        weekstart : '',        // 周开始日期
        weekend : '',          // 周结束日期
        totalCount : 0,        // 总计条数
        searchForm : {         // 查询内容
            sysUserId : [],
            page   : 1,
            limit  : 15
        },
        teacherList : [], // 教练列表
        tableDataTitle : reactive([]),      // 表格头
        tableData  : [],        // 列表--团课列表数据
        popSetPrivateTime : false, // 显示教练工作时间
        confirmTitle:  '',
		confirmText: '',
        setTimeInfo: {}
    });
    let confirmType = '' //通用确认调用类型
    getStoreAccountStaffSimple({teachType: 2, page: 1, limit: 9999}).then((res)=>{
            if(res.code == 0){
                var typeArray = [];
                for(var i=0;i<res.data.length;i++){
                    typeArray.push({
                        value : res.data[i]['id'],
                        label : res.data[i]['realName'] 
                    });
                }
                pageData.teacherList = typeArray
            }
        })
    /**
     * 根据周一日期获取一周的日期名称
     */
    const getTableTitle =() => {
        pageData.tableDataTitle = utils.getWeekArray(pageData.weekstart);
    }
    /**
     * @param (Number) type -1上周1下周
     * 点击上周或下周
     */
    const changeWeek=(type) => {
        if (pageData.loadingTable){ return }
        pageData.tableWeekNum   = type == -1 ? pageData.tableWeekNum - 1 : pageData.tableWeekNum + 1;
        var tableWeekArray      = utils.getWeekDays(pageData.tableWeekNum);
        pageData.weekstart = tableWeekArray[0];
        pageData.weekend   = tableWeekArray[1];
        getTableTitle();
        getTableData()
    }
    function popSetPrivateTime(){
        setTimeMethods.value.show();
    }
    /**
     * 搜索按钮
     */
    const search=()=> {
       pageData.searchForm.page = 1
       getTableData()
    }
    /**
     * 获取列表
     */
    const getTableData=() => {
        pageData.loadingTable = true
        let params = {
            sysUserIds: utils.trimArray(pageData.searchForm.sysUserId).join(),
            dateStartStr: pageData.weekstart,
            dateEndStr: pageData.weekend,
        }
        sysUserScheduleList(params).then((res)=> {
            pageData.loadingTable = false
            if (res.code == 0) {
                pageData.tableData = res.results.list && res.results.list || []
                pageData.totalCount = res.count
            }
        }).catch(()=> {
            pageData.loadingTable = false
        })
    }
    /**
     * 批量删除本周
     */
    const batchRemove = () => {
        if(!pageData.selectCheckData.length) {
            ElMessage({ type : 'warning' ,message : '请先选择需要删除的教练'});
            return
        }
        pageData.confirmTitle = '确定批量删除排期吗？';
		pageData.confirmText = '删除本周教练工作时间，仅会删除从当前日期至本周最后一天范围内的工作安排，且已被预约的时段不受影响!';
        confirmType = 'batchRemove'
        confirmRef.value.show();
    }
    /**
     * 删除全部排期
     */
    const allRemove = () => {
        if(!pageData.selectCheckData.length) {
            ElMessage({ type : 'warning' ,message : '请先选择需要删除的教练'});
            return
        }
        pageData.confirmTitle = '确定批量删除全部排期吗？';
        pageData.confirmText = '删除全部教练工作时间，将会删除从当前日期至排班截止日期内的工作安排，但已被预约的时段不受影响！';
        confirmType = 'allRemove'
        confirmRef.value.show();
        
    }
    /**
     * @param(String) id 本周排课的id
     * 删除本周
     */
    const deleThisWeek=(id) => {
        pageData.confirmTitle = '确定删除本周吗？';
		pageData.confirmText = '删除本周教练工作时间，仅会删除从当前日期至本周最后一天范围内的工作安排，且已被预约的时段不受影响！';
        confirmType = id
        confirmRef.value.show();
    }
    const confirmSave=() => {
        if (confirmType == 'allRemove') {
            let arry = []
            pageData.selectCheckData.forEach((item) => {
                arry.push(item.sysUserId)
            })
            let params = {
                deleteType: 2,
                sysUserIds: arry.join() 
            }
            deleteBatch(params).then((res) => {
                if (res.code == 0) {
                    ElMessage({ type : 'success' ,message : '删除成功'});
                    multipleTable.value.clearSelection()
                    getTableData()
                }
            })
        } else if (confirmType == 'batchRemove') {
            let arry = []
            pageData.selectCheckData.forEach((item) => {
                arry.push(item.sysUserId)
            })
            let params = {
                deleteType: 1,
                sysUserIds: arry.join(),
                dateStartStr: pageData.weekstart,
                dateEndStr: pageData.weekend
            }
            deleteBatch(params).then((res) => {
                if (res.code == 0) {
                    ElMessage({ type : 'success' ,message : '删除成功'});
                    multipleTable.value.clearSelection()
                    getTableData()
                }
            })
           
        } else {
            let params = {
                deleteType: 1,
                sysUserIds: confirmType,
                dateStartStr: pageData.weekstart,
                dateEndStr: pageData.weekend
            }
            deleteBatch(params).then((res) => {
                if (res.code == 0) {
                    ElMessage({ type : 'success' ,message : '删除成功'})
                    getTableData()
                }
            })
        }
        
    }
    /**
     * @param(Object) row 本周排课的数据
     * 编辑本周
     */
    const editThisWeek=(row) => {
        pageData.setTimeInfo = Object.assign({}, row)
        pageData.setTimeInfo.dateEnd = pageData.weekend
        pageData.setTimeInfo.dateStart = pageData.weekstart
        setTimeMethods.value.show();
        setTimeMethods.value.edit( pageData.setTimeInfo);
    }
    /**
     * 切换分页数量
     */
	const pageSizeChange=(limit) => {
		pageData.searchForm.limit = limit;
	}
    /**
     * 记住分页
     */
    const getRowKey=(row) => {
       return row.sysUserId
    }
    /**
     * 获取选择分页数据
     */
    const selectionChange=(data) => {
	    pageData.selectCheckData = data;
	}
    /**
     * 编辑新增刷新列表
     */
    function closeLsit() {
        getTableData()
    }
    onMounted(()=>{
        var week = utils.getWeekDays(pageData.tableWeekNum);
        pageData.weekstart = week[0];
        pageData.weekend = week[1];
        getTableTitle()
        getTableData()
        localStorage.setItem('courseTable', '1')
    });

</script>

<style type="text/css" scoped>
    .tips{
        background-color: #E6F7FF;
        border: 1px solid #92D5FF;
        border-radius: 3px;
        padding: 8px 0;
    }
    .nowrap{
        white-space: nowrap
    }
    .color-F96667:hover{
        color: red;
    }
</style>