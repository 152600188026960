<template>
    <workArea :barlist="barlist">
    <div class="bg-fff pl16 pt10 pr16">
        <el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="收退款记录" name="0" v-if="utils.getMenuRole(32701)"></el-tab-pane>
            <el-tab-pane label="到款记录" name="1" v-if="utils.getMenuRole(32702)"></el-tab-pane>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </div>
    </workArea>
</template>
<script setup>
  import { reactive, onMounted, onBeforeUnmount } from 'vue';
  import revenueList from '@/views/branch-store/finance/collectionCode/revenueList.vue' // 收退记录
  import arrivalList from '@/views/branch-store/finance/collectionCode/arrivalList.vue' // 到账记录
  import workArea from '@/components/branch-store/workArea.vue'; 
  import utils from '@/common/utils.js'; 
  const barlist = [{ name : '首页' },{ name : '财务中心' },{ name : '收银台' }];
  const components = [revenueList, arrivalList];
  const pageData = reactive({
      tabNum : '0'
  });
  var menuId = [32701,32702]; //会员有权限tab默认项
  for(var i = 0;i < menuId.length;i++){
  	if(utils.getMenuRole(menuId[i])){
  		pageData.tabNum = i.toString();
  		break;
  	}
  }  
  function handleClick(tab) {
      localStorage.setItem('collectionCode', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('collectionCode') != null) {
          pageData.tabNum = localStorage.getItem('collectionCode')
	    }
	})
  onBeforeUnmount(() => {
		localStorage.removeItem('collectionCode')
	})
    
</script>

<style>
</style>
