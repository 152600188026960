<template>
	<el-dialog
	    v-model="data.openShow"
	    title="高级筛选"
		width="500px"
	    :before-close="close"
	  >
	    <div class="rx-ss pl20 pt20 pb30 h200">
			<el-form class="dialog_form mt30" :model="data.form" label-width="120px">
                <div v-if="propData.type == 1">
					<el-form-item label="剩余次数">
					   	<el-input class="w115 t_a_c" v-model="data.form.startNum" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                       	    <template #append>次</template>
                       	 </el-input>
                	   	<span class="ml10 mr10">~</span>
					   	<el-input class="w115 t_a_c" v-model="data.form.endNum" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>次</template>
                        </el-input>
                	</el-form-item>
                	<el-form-item label="剩余天数">
					  	<el-input class="w115 t_a_c" v-model="data.form.startDay" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>天</template>
                        </el-input>
                	   	<span class="ml10 mr10">~</span>
					   	<el-input class="w115 t_a_c" v-model="data.form.endDay" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>天</template>
                        </el-input>
                	</el-form-item>
				</div>
				<div v-if="propData.type == 2">
					<el-form-item label="剩余节数">
					   	<el-input class="w115 t_a_c" v-model="data.form.startNum" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>节</template>
                        </el-input>
                	   	<span class="ml10 mr10">~</span>
					   	<el-input class="w115 t_a_c" v-model="data.form.endNum" placeholder="请输入"  maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>节</template>
                        </el-input>
                	</el-form-item>
				</div>
            </el-form>
		</div>
	    <template #footer>
	      <div class="dialog-footer">
            <el-button @click="reset">重 置</el-button>
	        <el-button type="primary" @click="save">保 存</el-button>
	      </div>
	    </template>
	  </el-dialog>
</template>
<script setup>
	import {ref,reactive} from 'vue';
    import { ElMessage } from 'element-plus'
	const propData = defineProps({
		type:Number
	})
	const data = reactive({
		openShow:false,
        form: {}
	});
	const emit = defineEmits(['close','save']);
	function show(){
		data.openShow = !data.openShow;
	}
	function close(){
		show();
		emit('close');
	}
    function reset() {
        data.form = {}
        show();
		emit('reset', data.form);
    }
	function save(){
        if (data.form.endDay&& Number(data.form.endDay) < Number(data.form.startDay) || data.form.endNum&& Number(data.form.endNum) < Number(data.form.startNum)) {
            ElMessage.warning('请输入正确范围数值')
            return
        }
		show();
		emit('save', data.form);
	}
	//暴露方法
	defineExpose({
	  show
	})
</script>
<style scoped>
	p {
		line-height: 22px;
	}
</style>
