<template>
    <workArea class="posi-r" :barlist="pageData.barlist">
        <div class="nav-top p24 pb0 posi-f bg-fff">
	    	<ul class="step_box rx-bc pt10 pb10 pl100 pr100">
	    		<li class="rx-cc flex1">
	    			<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum == 1" :class="{'active':pageData.tabNum == 1}">1</div>
	    			<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum != 1}"><el-icon><Check /></el-icon></div>
	    			<div class="fwb ft16" :class="pageData.tabNum == 1 ? 'color-333' :'color-999'">选择会员</div>
	    			<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
	    		</li>
	    		<li class="rx-cc flex1">
	    			<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum <= 2" :class="{'active':pageData.tabNum == 2}">2</div>
	    			<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum > 2}"><el-icon><Check /></el-icon></div>
	    			<div class="fwb ft16" :class="pageData.tabNum == 2 ? 'color-333' :'color-999'">加入课节</div>
	    			<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
	    		</li>
	    		<li class="rx-cc flex1">
	    			<div class="step_icon ft18 fwb mr10" :class="{'active':pageData.tabNum == 3}">3</div>
	    			<div class="fwb ft16" :class="pageData.tabNum == 3 ? 'color-333' :'color-999'">完成</div>
	    		</li>
	    	</ul>
	    </div>
		<div class="update_course_card bg-fff p24 p16 mt75 hh90 o_a o_y_s" v-loading="pageData.loading">
			<div v-show="pageData.tabNum == 1">
				<div class="rx-c mb16" v-if="TYPE == 1">
					<div class="rx-sc w360 mr40">
		    			<span class="w80 t_a_r"><span class="color-red">*</span>预约课程：</span>
		    			<div class="w240">
                            <el-select v-model="pageData.courseNameId" placeholder="请选择小班课" @change="searchGetList">
		    			    	<el-option :label="item.courseName" :value="item.courseNameId" v-for="(item, index) in pageData.courseList" :key="index"></el-option>
		    			    </el-select>
                        </div>
		    		</div>
					<div class="rx-sc w360 mr40">
		    			<span class="w80 t_a_r"><span class="color-red">*</span>上课班级：</span>
		    			<div class="w240">
                            <el-select v-model="pageData.cycleId" placeholder="请选择班级" @change="searchGetList">
		    			    	<el-option :label="item.cycleName" :value="item.id" v-for="(item, index) in pageData.classList" :key="index"></el-option>
		    			    </el-select>
                        </div>
		    		</div>
				</div>
				<div>
					<el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
 					  	<el-form-item>
 					  	  <el-input v-model="pageData.formInline.searchKeys" placeholder="请输入姓名、手机号" />
 					  	</el-form-item>
 					  	<el-form-item>
 					  	  <el-button type="primary" :icon="Search" @click="searchGetList">搜索</el-button>
 					  	</el-form-item>
 					</el-form>
					<el-table ref="subscribeTable" :row-key="getRowId" :data="pageData.subscribePage.tableData.slice((pageData.subscribePage.searchForm.page-1)*pageData.subscribePage.searchForm.limit, pageData.subscribePage.searchForm.page*pageData.subscribePage.searchForm.limit)" stripe @selection-change="checkSubscribe" :height="TYPE == 1 ? '55vh': '58vh'">
                        <el-table-column type="selection" :reserve-selection="true" width="55" />
                        <el-table-column prop="realName" label="会员姓名" ></el-table-column>
                        <el-table-column prop="telephoneNum" label="手机号码" />
                        <el-table-column prop="batchCode" label="扣费支付方式">
                            <template #default="scope">
                                <el-select v-model="scope.row.deduction" value-key="value" :no-data-text="pageData.noDataText" clearable placeholder="请选择扣费方式" @focus="changeDeduction(scope.row)">
                                    <el-option v-for="item in scope.row.AvailableAssetsLsit" :key="item.value" :label="item.label" :value="item" />
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt10 ml20" v-show="pageData.subscribePage.tableData.length >= 0">
                        <el-pagination
                            v-model:currentPage="pageData.subscribePage.searchForm.page"
                            :page-sizes="[15,20,30,50,100]"
                            :page-size="pageData.subscribePage.searchForm.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageData.subscribePage.totalCount"
                            @size-change="pageSizeChange"
                            @current-change="getOrderList"
                        >
                        </el-pagination>
                    </div>
				</div>
			</div>
			<div v-show="pageData.tabNum == 2" >
				<div class="bg-eee p16">
					<el-row :gutter="20">
  					  	<el-col class="mb16" :span="9">
							<div class="rx-sc mr40">
								<span class="w120 t_a_r"><span class="color-red">*</span>选择班级：</span>
								{{ pageData.cycleName }}
							</div>
						</el-col>
  					  	<el-col class="mb16" :span="9">
							<div class="rx-sc mr40">
								<span class="w120 t_a_r">上课教练：</span>
								<div class="w240">
									<el-select style="width: 100%;" v-model="pageData.formData.teacherIds" multiple placeholder="请选择上课教练">
  									  	<el-option
  									  	  v-for="item in pageData.teacherList"
  									  	  :key="item.id"
                                          :label="item.realName"
                                          :value="item.id"
  									  	/>
  									</el-select>
								</div>
							</div>
						</el-col>
  					  	<el-col class="mb16" :span="9">
							<div class="rx-sc mr40">
								<span class="w120 t_a_r"><span class="color-red">*</span>选择上课日期：</span>
								<div class="w240">
									<el-date-picker
										style="width: 100%;"
    								  	v-model="pageData.formData.orderDateStr"
    								  	type="daterange"
    								  	range-separator="至"
    								  	start-placeholder="开始日期"
    								  	end-placeholder="结束日期"
									  	format="YYYY-MM-DD"
                	            		value-format="YYYY-MM-DD"
    								/>
								</div>
							</div>
						</el-col>
  					  	<el-col class="mb16" :span="9">
							<div class="mr40" :class="pageData.timerList.length > 1 ? 'rx-ss' : 'rx-sc'">
								<span class="w120 t_a_r" :class="pageData.timerList.length > 1 ? 'mt5' : ''"><span class="color-red">*</span>选择上课时段：</span>
								<div>
									<div class="rx-c " :class="pageData.timerList.length > 1 ? 'mb16' : ''" v-for="(item, index) in pageData.timerList" :key="index">
										<div class="w240">
											<el-time-picker 
												style="width: 100%;"
						    				  	v-model="item.timer"
											  	format="HH:mm"
												value-format="HH:mm"
						    				  	is-range
						    				  	range-separator="至"
						    				  	start-placeholder="开始时间"
						    				  	end-placeholder="结束时间"
												@visible-change="(val)=>{dateChange(val, index)}"
						    				/>
										</div>
										<el-icon class="c_p ml10" :size="20" color="#409EFC" @click="addTime">
											<CirclePlus style="width: 1.5em; height: 1.5em;"/>
										</el-icon>
										<el-icon v-if="pageData.timerList.length>1" class="c_p ml10" :size="20" color="#F56C6C" @click="deleTime(index)">
											<Remove style="width: 1.5em; height: 1.5em;"/>
										</el-icon>
									</div>
								</div>
							</div>
						</el-col>
					  	<el-col class="mb16" :span="9">
							<div class="rx-ss mr40">
								<span class="w120 t_a_r mt5">选择星期：</span>
								<div class="flex1">
									<el-checkbox-group v-model="pageData.formData.checkList">
  									  	<el-checkbox v-for="item in pageData.weekList" :key="item.value" :label="item.value">
                	                        <span class="color-333">{{ item.name }}</span>
                	                    </el-checkbox>
  									</el-checkbox-group>
								</div>
							</div>
						</el-col>
						<el-col class="mb16" :span="9">
							<div class="rx-sc mr40">
								<span class="w120 t_a_r"><span class="color-red">*</span>剩余可约人数：</span>
								<div class="w240 rx-c">
									<el-input-number v-model="pageData.formData.remainPeopleNum" :min="1" :max="99" :step="1" step-strictly :controls="false" @change="handleChange" />
									<span class="ml10">人及以上</span>
								</div>
							</div>
						</el-col>
  					</el-row>
					<div class="rx-be">
						<div></div>
						<div>
							<el-button type="primary" :icon="Search" @click="search">搜索</el-button>
							<el-button :icon="Refresh" @click="reset">重置</el-button>
						</div>
					</div>
				</div>
				<div class="mt16 mb16">
        		    <h4 class="p10 pl20 pr20 bg-FFB800 rx-c">
						<img class="w15 h15 mr5" src="@/assets/warning.png">
						<span>已选</span>
						<span class="ml5 mr5 color-red">{{ pageData.selecMemberList.length }}</span>
						<span>条数据</span>
					</h4>
        		</div>
				<div>
					<el-table ref="classTable" :row-key="getRowId1" :data="pageData.tableData.slice((pageData.searchForm.page-1)*pageData.searchForm.limit, pageData.searchForm.page*pageData.searchForm.limit)" stripe @selection-change="checkMemberList">
                        <el-table-column type="selection" :reserve-selection="true" width="55" />
                        <el-table-column prop="weekName" label="星期" ></el-table-column>
                        <el-table-column prop="date" label="课程日期" />
                        <el-table-column prop="classTimeStr" label="上课时间">
							<template #default="scope">
								{{ scope.row.classDate.split(' ')[1] }}
							</template>
						</el-table-column>
						<el-table-column prop="courseName" label="上课课程" />
						<el-table-column prop="teacherName" label="上课教练" />
						<el-table-column prop="roomName" label="场地" />
						<el-table-column prop="allCoursePeopleNum" label="剩余可约人数" />
						<el-table-column prop="allPeopleNum" label="最大可约人数" />
                    </el-table>
                    <div class="mt10 ml20" v-show="pageData.tableData.length >= 0">
                        <el-pagination
                            v-model:currentPage="pageData.searchForm.page"
                            :page-sizes="[15,20,30,50,100]"
                            :page-size="pageData.searchForm.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageData.totalCount"
                            @size-change="pageSize"
                            @current-change="getList"
                        >
                        </el-pagination>
                    </div>
				</div>
			</div>
			<div v-show="pageData.tabNum == 3" class="rx-cc">
				<div class="mt100 t_a_c">
					<div class="rx-cc">
						<circle-check-filled  style="width: 6em; height: 6em;color:#74C041"/>
					</div>
					<h3 class="ft24 mt20">加入成功</h3>
					<div class="mt20">
						<el-button @click="toCardList">返回</el-button>
					</div>
				</div>
			</div>
			<div class="rx-sc mt20">
				<div class="w75 mr16">
					<el-button v-show="pageData.tabNum == 2" @click="pageData.tabNum = 1">上一步</el-button>
				</div>
				<el-button v-show="pageData.tabNum != 3" type="primary" @click="addSave">{{pageData.tabNum == 2 ? '加入课节' : '下一步'}}</el-button>
			</div>
		</div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
	import { Search, Check, ArrowRight, ArrowDown, CircleCheckFilled, Remove, CirclePlus, Refresh } from '@element-plus/icons-vue'
	import { ElMessage } from 'element-plus'
	import utils from '@/common/utils.js';
	import moment from 'moment'
	import { useRouter } from 'vue-router';
	import { getStoreAccountStaffSimple } from '@/api/staff.js';
	import { getMemberList, storeBookBatch } from '@/api/member.js';
	import { courseList, courseCycleList, getAppointmentCards, courseInfo, cycleCourseList } from '@/api/course.js';


	const router = useRouter()
	const subscribeTable = ref()
	const classTable= ref()
	const ID = router.currentRoute.value.query.id;
	const TYPE = router.currentRoute.value.query.type;  // 0-班级详情的添加会员 1-课表的批量预约
	const CYCLEID = router.currentRoute.value.query.cycleId;
	const NAME = router.currentRoute.value.query.name;
	const encryptionId = router.currentRoute.value.query.encryptionId;
    const pageData = reactive({
        barlist:[{ name : '返回',type:0 },{ name : TYPE == 1 ? '批量预约' :'添加会员'}],
        tabNum: 1,
		formInline:{
			searchKeys: '',
		},
		courseNameId: '',
		courseList: [],
		classList: [],
		cycleId: '',
		subscribeSelection: [],
		subscribePage : {
            totalCount : 0,
            searchForm : {
                page : 1,
                limit : 15
            },
            tableData : []
        },
		formData: {
			name: '',
			teacherIds: [],
			checkList: [1,2,3,4,5,6,7],
			orderDateStr: [],
			remainPeopleNum: 1
		},  // 第二步表单
		teacherList: [], // 教练列表
		timerList: [{
			timer: []
		}], // 时间列表
		weekList: [
            {name: '星期一', value: 1},
            {name: '星期二', value: 2},
            {name: '星期三', value: 3},
            {name: '星期四', value: 4},
            {name: '星期五', value: 5},
            {name: '星期六', value: 6},
            {name: '星期日', value: 7},
        ],
		tableData: [],
		totalCount: 0,
        searchForm: {
            page: 1,
            limit: 15
        },
		selecMemberList: [],
		cycleName: TYPE == 1 ? '' : NAME,
		encryptionId: ''
    })
	function getCourseList() {
        courseList({
            page: 1,
            limit: 99999,
            courseType: 13
        }).then(res => {
            pageData.courseList = res.data
        })
    }
	 /**
     * 班级列表
     */
    function getCourseCycleList() {
		courseCycleList({ page: 1, limit: 99999, cycleType: 12 }).then(res => {
    	    if (res.code == 0) {
				pageData.classList = res.data
    	    }
    	})
	}
	/**
     * 获取选择会员数据
     */
    const checkSubscribe = (val) => {
        pageData.subscribeSelection = val
    }
	/**
	 * 搜索会员
	 */
	function searchGetList() {
		if (TYPE == 1 && !pageData.courseNameId) {
			ElMessage.warning('请先选择课程')
			return
		}
		if (TYPE == 1 && !pageData.cycleId) {
			ElMessage.warning('请先选择班级')
			return
		}
		if (TYPE == 1) {
			pageData.classList.forEach(item => {
       		    if (item.id == pageData.cycleId) {
       		        pageData.encryptionId = item.encryptionId
       		    }
       		})
		}
	   pageData.subscribePage.searchForm.page = 1;
       getOrderList()
	}
	/**
	 * 获取会员列表
	 */
	function getOrderList() {
		let params = {
            limit: pageData.subscribePage.searchForm.limit,
            page: pageData.subscribePage.searchForm.page,
			searchKeys: pageData.formInline.searchKeys,
			courseNameId: TYPE == 1 ? pageData.courseNameId :ID,
			cycleId:  TYPE == 1 ? pageData.cycleId : CYCLEID
        }
        getMemberList(params).then((res) =>{
            if(res.code == 0){
                res.data.forEach((item) => {
                    item.bookPeopleNum = 1
                })
                pageData.subscribePage.tableData = res.data.map((n)=>{
					n.show = false;
					return n;
				});
                pageData.subscribePage.totalCount = res.data.length
            }
        });
	}
	/**
     * 选择扣费方式
     */
    function changeDeduction(row){
		row.AvailableAssetsLsit = []
        let obj = {
			encryptionCardMyId: row.encryptionCardMyId,
            courseNameId:TYPE == 1 ? pageData.courseNameId : ID,
			cycleId: TYPE == 1 ? pageData.cycleId : CYCLEID
        }
        getAppointmentCards(obj).then((res) => {
            if ( res.code == 0)  {
                res.results.list.forEach((item) => {
					if (item.cycleId) {
						row.AvailableAssetsLsit.push({
						    label: item.productName+'(剩余'+item.productNum+'节)',
						    value: item.id,
						    type: '班级'
						})
					} else {
						row.AvailableAssetsLsit.push({
						    label: item.productName+'(剩余'+item.storeMoneyGive+'元)',
						    value: item.id,
						    type: '班级'
						})
					}
                })
            }
        })
    }
	/**
	 * 切换每页条数
	 */
	function pageSizeChange(limit){
		pageData.subscribePage.searchForm.limit = limit;
		getOrderList();
	}
	/**
     * 记住分页标记（课节信息）
     */
    const getRowId=(row) => {
        return row.id
    }
	/**
	 * 获取班级课节搜索-多条件搜索
	 */
	function getList() {
		let arr = []
		pageData.timerList.forEach(item => { 
			if (item.timer.length && item.timer) {
				arr.push(item.timer.join('-'))
			}
		})
		let params = {
			encryptionId: TYPE == 1 ? pageData.encryptionId : encryptionId,
			teacherIds: pageData.formData.teacherIds.join(),
			remainPeopleNum: pageData.formData.remainPeopleNum,
			searchDate: pageData.formData.orderDateStr.length && pageData.formData.orderDateStr.join('-') || '',
			searchTime: arr.join(),
			weeks: pageData.formData.checkList.join()
		}
		cycleCourseList(params).then(res => {
			if (res.code == 0) {
				pageData.tableData = res.data
				pageData.totalCount = res.count
			}
		})
	}
	
	/**
	 * 下一步
	 */
	function addSave() {
		if(pageData.tabNum == 1){
			if (!pageData.subscribeSelection.length) {
				ElMessage.warning('请选择会员！')
				return
			}
			for (let i = 0; i < pageData.subscribeSelection.length; i++){
				if (!pageData.subscribeSelection[i].deduction) {
					ElMessage.warning('有会员未选择扣费方式')
					return
				}
			}
			pageData.formData.remainPeopleNum = pageData.subscribeSelection.length
			if (TYPE == 1) {
				pageData.classList.forEach(item => {
            	    if (item.id == pageData.cycleId) {
            	        pageData.cycleName = item.cycleName
						pageData.encryptionId = item.encryptionId
            	    }
            	})
			}
			getStoreAccountStaffSimple({teachType: 1, page: 1, limit: 9999}).then((res)=>{
        	    if(res.code == 0){
        	        pageData.teacherList = res.data
        	    }
        	})
			getList()
			pageData.tabNum = 2
		} else {
			if (!pageData.selecMemberList.length) {
				ElMessage.warning('请选择课节')
				return
			}
			let arr = [], list=[]
			pageData.selecMemberList.forEach(item => {
				arr.push(item.id)
			})
			pageData.subscribeSelection.forEach(item => {
				list.push({
					encryptionCardMyId: item.encryptionCardMyId,
					chargeType: 4,
					orderId: item.deduction.value,
					bookPeopleNum: 1
				})
			})
			let params = {
				type: 1,
				ifRepeat: 1,
				courseIds: arr.join(),
				stuInfoList: list
			}
			storeBookBatch(params).then(res => {
				if (res.code == 0){
					pageData.tabNum = 3
				}
			})
		}
	}
	/**
	 * 添加时间段
	 */
	function addTime() {
		pageData.timerList.push({
			timer: []
		})
	}
	/**
	 * 删除时间段
	 */
	function deleTime(index) {
		pageData.timerList.splice(index,1);
	}
	/**
	 * 修改上课时间段
	 */
	function dateChange(val){
		if (!val) {
			let timeArr = []
			pageData.timerList.forEach(item => {
				let obj = {
					start: moment.duration(item.timer[0]).as('seconds'),
					end:  moment.duration(item.timer[1]).as('seconds'),
				}
				timeArr.push(obj)
			})
			if (timeArr.length > 1 && !Fn(timeArr)) {
				ElMessage.warning('所选时间段有重叠')
			}
		}
	}
	/**
	 * 判断时间段是否重叠
	 */
	function Fn(dateAr) {
	  for (let k in dateAr) {
	    if (!judege(k, dateAr)) {
	      return false
	    }
	  }
	  return true
	}
	function judege(idx, dateAr) {
	  for (let k in dateAr) {
	    if (idx !== k) {
	        if (dateAr[k].start <= dateAr[idx].start && dateAr[k].end > dateAr[idx].start) {
	          return false
	        }
	        if (dateAr[k].start < dateAr[idx].end && dateAr[k].end >= dateAr[idx].end) {
	          return false
	        }
	    }
	  }
	  return true
	}
	function handleChange(value) {
        console.log(value);
    }
	/**
	 * 第二步查询
	 */
	function search() {
		pageData.searchForm.page = 1
		getList();
	}
	/**
	 * 重置
	 */
	function reset() {
		pageData.formData = {
			name: '',
			teacherIds: [],
			checkList: [1,2,3,4,5,6,7],
		 	orderDateStr: [],
			remainPeopleNum: 1
		}
		pageData.timerList = [{
			timer: []
		}]
	}
	/**
     * 获取选择会员数据
     */
    const checkMemberList = (val) => {
        pageData.selecMemberList = val
    }
	/**
	 * 切换每页条数
	 */
	function pageSize(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	/**
     * 记住分页标记
     */
    const getRowId1=(row) => {
        return row.id
    }
	/**
	 * 返回
	 */
	function toCardList() {
		router.go(-1)
	}
	onMounted(() => {
		if (TYPE == 0) {
			getOrderList()
			localStorage.setItem('classManageDetails', '2')
		} else {
			getCourseList()
			getCourseCycleList()
			localStorage.setItem('courseTable', '2')
		}
		 
	})
</script>
<style scoped lang="stylus">
    .nav-top {
		z-index: 100;
		width: 83%;
	    .step_box{
			background-color:#FFFAE7;
			li{
				position: relative;
				.step_icon {
					width: 32px;
					height: 32px;
					line-height: 32px;
					text-align: center;
					border-radius: 100%;
					border: 1px solid #909399;
					color: #909399;
				}
				.step_icon.active {
					color: white;
					background-color: #409EFF;
					border-color: #409EFF;
				}
				.step_icon.complete{
					color: #409eff;
    				background: #fffae7;
					border-color: #409EFF;
				}
				.step_right{
					position: absolute;
					right: 0;
					top: 0;
				}
			}
		}
    }
</style>