<script setup>
	const propData = defineProps({
	  text: String,
	  title: String
	})
	import {ref,reactive} from 'vue';
	const data = reactive({
		openShow:false
	});
	const emit = defineEmits(['close','save']);
	function show(){
		data.openShow = true;
	}
	function close(){
		data.openShow = false;
		emit('close');
	}
	function save(){
		data.openShow = false;
		emit('save');
	}
	//暴露方法
	defineExpose({
	  show,close
	})
</script>

<template>
	<el-dialog
	    v-model="data.openShow"
	    title="温馨提示"
		width="400px"
	    :before-close="close"
	  >
	    <div class="rx-ss pl20 pt50 pb50">
			<img class="mr10 w25" src="../assets/warning.png" >
			<div class="color-333 ww80">
				<h3 class="mb5 ft16" v-html="title"></h3>
				<p class="color-666" v-show="text" v-html="text"></p>
			</div>
		</div>
	    <template #footer>
	      <div class="dialog-footer">
			<slot name="btns">
				<el-button @click="close">取消</el-button>
				<el-button type="primary" @click="save">确定</el-button>
			</slot>
	      </div>
	    </template>
	  </el-dialog>
</template>

<style scoped>
	p {
		line-height: 22px;
	}
</style>
