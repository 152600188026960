<template>
    <div>
    </div>
</template>

<script setup>
    import {ref} from 'vue'
    import axios from 'axios'
    import {useRoute} from 'vue-router'
    import { ElMessage } from 'element-plus'
    const route = useRoute();
    let uuid = route.query.uuid;
    var callback = location.protocol + '//' + location.host + '/#/weChart?uuid='+uuid;
    window.location.href = 'https://s.flow360.cn/payapi/wxAutho/wap/authorize?appType=2&callback='+encodeURIComponent(callback);
</script>

<style scoped lang="stylus">
    
</style>
