<template>
    <div class="mt25 mb50">
        <el-table :data="pageData.tableData" border stripe style="width: 800px">
            <el-table-column prop="date" align="center" label="提醒类型">
                <template #default="scope">
                    <a href="javascript:;" class="color-409EFF tool_btn" v-if="scope.row.ifOpenRemind == 1" @click="awaken(scope.row)">{{ scope.row.remindTypeName }}</a>
                    <el-tooltip
                        v-else
                        class="box-item"
                        effect="dark"
                        content="请先开启提醒后设置相关内容"
                        placement="top-start"
                    >
                        <span>{{ scope.row.remindTypeName }}</span>
                    </el-tooltip>
                </template>
            </el-table-column>
            <el-table-column prop="name" align="center" label="操作">
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.ifOpenRemind"
                        size="large"
                        :width="70"
                        :active-value="1"
                        :inactive-value="2"
                        inline-prompt
                        active-text="已开启"
                        inactive-text="已关闭"
                        disabled 
                        @click="changeval(scope.row)"
                    />
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            v-model="pageData.dialogVisible"
            title="修改消息设置"
            width="50%"
            :before-close="handleClose"
          >
            <template #default>
                <div class="dialog-data pl50 pt30 pr50 pb50">
                    <div class="ft14 pb15 color-333 fwb nav">{{ pageData.info.remindTypeName }}</div>
                    <div class="pb30 pl30 pr30 mb20" v-if="pageData.info.typeId != 22 && pageData.info.typeId != 30 && pageData.info.typeId != 31 && pageData.info.typeId != 33 && pageData.info.typeId != 34 && pageData.info.typeId != 36 && pageData.info.typeId != 38 && pageData.info.typeId != 39 && pageData.info.typeId != 40">
                        <div class="pt10 pb10 rx-bc top">
                            <div>
                                <label>消息提醒人：</label>
                                <span v-if="pageData.info.bRemind">{{ pageData.info.typeId == 6 || pageData.info.typeId == 19 || pageData.info.typeId == 32 || pageData.info.typeId == 37  ? '店长' : '上课教练' }}、</span>
                                <span v-for="(item, index) in pageData.roleNameList" :key="index">{{ item.roleName }} <span v-if="index+1 < pageData.roleNameList.length">、</span></span>
                            </div>
                            <div>
                                <a href="javascript:;" class="color-409EFF tool_btn" @click="modify">
                                    <el-icon class="v_a_m">
                                        <Plus />
                                    </el-icon>
                                    修改提醒人
                                </a>
                            </div>
                        </div>
                        <div class="pt10">
                            <label>提醒方式：</label>
                            <el-checkbox v-model="pageData.info.remindModeWechat">
                                <span class="color-666 fw400">微信提醒</span>
                            </el-checkbox>
                        </div>
                        <div v-if="pageData.info.typeId == 32">
                            <label>剩余：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemind"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>天</template>
                            </el-input>
                            <label>时提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 37">
                            <label>剩余：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemind"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>次</template>
                            </el-input>
                            <label>时提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 8">
                            <label>课前：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindCourseDayB"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>天</template>
                            </el-input>
                            <label>提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 8 || pageData.info.typeId == 20">
                            <label>每天：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindCourseTimeB" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')">
                               <template #append>点</template>
                            </el-input>
                            <label>点提醒</label>
                        </div>
                    </div>
                    <div class="pb30 pl30 pr30" v-if="pageData.info.typeId != 20">
                        <div class="pt10 pb10 top">
                            <el-checkbox v-model="pageData.info.uRemind">
                                <span class="color-333 fwb">提醒会员</span>
                            </el-checkbox>
                        </div>
                        <div class="pt10">
                            <label>提醒方式：</label>
                            <el-checkbox v-model="pageData.info.remindModeWechatU">
                                <span class="color-666 fw400">微信提醒</span>
                            </el-checkbox>
                        </div>
						<div v-if="pageData.info.typeId == 40">
						    <label>开始前：</label>
						    <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindClass"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>小时</template>
                            </el-input>
						    <label>提醒</label>
						</div>
                        <div v-if="pageData.info.typeId == 32">
                            <label>剩余：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindU"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>天</template>
                            </el-input>
                            <label>时提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 37 ">
                            <label>剩余：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindU"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>次</template>
                            </el-input>
                            <label>时提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 8">
                            <label>课前：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" v-model="pageData.info.advanceRemindCourseDay"  onkeyup="value=value.replace(/[^\d]/g,'')">
                               <template #append>天</template>
                            </el-input>
                            <label>提醒</label>
                        </div>
                        <div v-if="pageData.info.typeId == 8 || pageData.info.typeId == 20">
                            <label>每天：</label>
                            <el-input class="w90 h30 t_a_c mr5 mt5" max="23" v-model="pageData.info.advanceRemindCourseTime" onkeyup="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')">
                               <template #append>点</template>
                            </el-input>
                            <label>提醒</label>
                        </div>
                    </div>
                    <span class="tips mt15" v-if="pageData.info.typeId == 8">注：如设置为当天（课前0天）上课提醒推送时，设置提醒时间点之前的课程（已开课），系统将不在推送！</span>
                </div>
                <el-dialog
                    v-model="pageData.innerVisible"
                    width="450px"
                    title="修改提醒人"
                    append-to-body
                    :before-close="handleClose1"
                >
                <div class="pl30 pr30 pt30 h300">
                    <el-checkbox v-model="pageData.info.bRemind">
                        <span class="color-666 fw400">{{ pageData.info.typeId == 6 || pageData.info.typeId == 19 || pageData.info.typeId == 32 || pageData.info.typeId == 37  ? '店长' : '上课教练' }}</span>
                    </el-checkbox>
                    <div class="rx-c mt15">
                        <label class="w140">提醒其他岗位：</label>
                        <el-select
                          v-model="pageData.info.roleId"
                          multiple
                          :multiple-limit="pageData.info.bRemind ? 4 : 5"
                          placeholder="请选择"
                        >
                            <el-option v-for="item in pageData.roleList" :key="item.id" :value="item.id" :label="item.roleName"></el-option>
                        </el-select>
                    </div>
                    <div class="color-red mt15">注：最多可勾选5个</div>
                </div>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button @click="handleClose1">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                  </span>
                </template>
                </el-dialog>
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
              </span>
            </template>
          </el-dialog>
    </div>
</template>

<script setup>
    import { reactive } from 'vue';
    import { ElMessage } from 'element-plus'
    import { Plus } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import { getMessageSettingList, offMessage, onMessage, messageSettingToEdit, messageSettingEdit } from '@/api/storeCenter.js';
    import { roleManageStore } from '@/api/post.js';
    
    const pageData = reactive({
        tableData: [],
        dialogVisible: false,
        innerVisible: false,
        info: {},
        roleList: [],
        roleNameList: [],
        encryptionId: ''
    })
    /**
     * 获取列表
     */
    getList()
    function getList() {
        getMessageSettingList().then((res) => {
            if (res.code == 0) {
                let arr = []
                res.data.forEach((item) => {
                    if (item.remindTypeName != "跟进提醒") {
                        arr.push(item)
                    }
                })
                pageData.tableData = arr
            }
        })
    }
    /**
     * 按钮开关
     */
    function changeval(row) {
        let data = ''
      if (row.ifOpenRemind == 1) {
        data = offMessage
      } else {
        data = onMessage
      }
      data({ encryptionId: row.encryptionId }).then((res) => {
        ElMessage.success('设置成功')
        getList()
      })
    }
    /**
     * 唤醒修改设置弹框
     */
    function awaken(row) {
        pageData.encryptionId = row.encryptionId
        messageSettingToEdit({ encryptionId: row.encryptionId }).then((res) => {
            if (res.code == 0) {
                pageData.info = res.data
                pageData.info.remindModeWechat = pageData.info.remindModeWechat ? true : false
                pageData.info.remindModeWechatU = res.remark.remindModeWechat ? true : false
                pageData.info.bRemind = pageData.info.bRemind == 1 ? true : false
                pageData.info.uRemind = res.remark.uRemind == 1 ? true : false
                pageData.info.remindTypeName = row.remindTypeName
                pageData.info.roleId = pageData.info.roleId && pageData.info.roleId.split(',').map(Number) || []
                pageData.info.advanceRemindCourseDayB =  pageData.info.advanceRemindCourseDay,
                pageData.info.advanceRemindCourseTimeB =  pageData.info.advanceRemindCourseTime,
                pageData.info.advanceRemindCourseDay =  res.remark.advanceRemindCourseDay,
                pageData.info.advanceRemindCourseTime =  res.remark.advanceRemindCourseTime,
                pageData.info.advanceRemindU =  res.remark.advanceRemind,
                pageData.info.advanceRemindClass =  res.remark.advanceRemindClass,
                pageData.dialogVisible = true
                roleManageStore({page: 1, limit: 9999}).then((resolve)=>{
		        	if(resolve.code == 0){
                        let arr = []
                        resolve.data.forEach((item) => {
                            if (pageData.info.typeId == 6 || pageData.info.typeId == 19 || pageData.info.typeId == 32 || pageData.info.typeId == 37) {
                                if (item.id != 109) {
                                    arr.push(item)
                                }
                            } else {
                                if (item.id != 108) {
                                    arr.push(item)
                                }
                            }
                        })
		        		pageData.roleList = arr
                        pageData.roleNameList = []
                        pageData.info.roleId.forEach((item) => {
                            pageData.roleList.filter((val) => {
                                if (val.id == item) {
                                   pageData.roleNameList.push(val) 
                                }
                            })
                        })
		        	}
		        })
            }
        })
    }
    /**
     * 唤醒修改提醒人弹框
     */
    function modify() {
        pageData.innerVisible = true
    }
    /**
     * 取消修改提醒人
     */
    function handleClose1() {
        pageData.roleNameList = []
        pageData.info.roleId = []
        pageData.innerVisible = false
    }
    /**
     * 确定修改提醒人
     */
    function confirm() {
        if (pageData.info.bRemind && pageData.info.roleId.length > 4) {
            ElMessage.warning('最多只能选择五个')
            return
        }
        pageData.roleNameList = []
        pageData.info.roleId.forEach((item) => {
            pageData.roleList.filter((val) => {
                if (val.id == item) {
                   pageData.roleNameList.push(val) 
                }
            })
        })
        pageData.innerVisible = false
    }
    /**
     * 取消修改
     */
    function handleClose() {
        pageData.dialogVisible = false
    }
    /**
     * 确定修改
     */
    function onSubmit() {
        let params = {
            encryptionId: pageData.encryptionId,
            uRemind: pageData.info.uRemind ? 1 : 2,
            bRemind: pageData.info.bRemind ? 1 : 2,
            advanceRemindCourseDay: pageData.info.advanceRemindCourseDay,
            advanceRemindCourseTime: pageData.info.advanceRemindCourseTime,
            advanceRemindCourseDayB: pageData.info.advanceRemindCourseDayB,
            advanceRemindCourseTimeB: pageData.info.advanceRemindCourseTimeB,
            advanceRemind: pageData.info.advanceRemind,
            advanceRemindU: pageData.info.advanceRemindU,
            advanceRemindClass: pageData.info.advanceRemindClass,
            remindModeWechat: pageData.info.remindModeWechat ? 1 : 0,
            remindModeWechatU: pageData.info.remindModeWechatU ? 1 : 0,
            roleId: pageData.info.roleId.length && pageData.info.roleId.join(',') || ''
        }
        messageSettingEdit(params).then((res) => {
            if (res.code == 0) {
                ElMessage.success('修改成功')
                pageData.dialogVisible = false
                getList()
            }
        })
    }
</script>
<style type='css/style' scoped>
.dialog-data .nav {
    border:0;
}
.dialog-data>div{
    border:1px solid #DDDDDD
}
.dialog-data input{
    line-height: 30px;
}
.dialog-data .top {
    border-bottom: 1px solid #DDDDDD
}
.dialog-data .tips {
        display: inline-block;
        padding: 0 6px;
        font-size: 12px;
        text-align: center;
        background-color: #FF5722;
        color: #fff;
        border-radius: 2px;
        height: 18px;
        line-height: 18px;
    }
.el-table {
    border-left: 0;
    border-right: 0;
}
</style>