<template>
	<workArea :barlist="barlist">
	<div class="card_manage bg-fff pl16 pt10 pr16">
		<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
		    <el-tab-pane label="上课预约" name="0" v-if="utils.getMenuRole(31800)"></el-tab-pane>
		    <el-tab-pane label="预约到访" name="1" v-if="utils.getMenuRole(31801)"></el-tab-pane>
			<el-tab-pane label="场地预约记录" name="2"></el-tab-pane>
		</el-tabs>
		<component :is="components[pageData.tabNum]" :tab="pageData.tabNum" ></component>
	</div>
	</workArea>
</template>
<script>
  export default {
    name: 'BranchAppointment'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount } from 'vue';
	import OrderClass from '@/views/branch-store/work/appointment/orderClass.vue' //上课预约
	import OrderVisit from '@/views/branch-store/work/appointment/orderVisit.vue' //预约到访
	import record from '@/views/branch-store/appstore/place/record.vue' // 场地预约记录
	import workArea from '@/components/branch-store/workArea.vue';
	import utils from '@/common/utils.js';
	const components = [OrderClass, OrderVisit, record];
	const barlist = [{ name : '首页' },{ name : '教务中心' },{ name : '预约管理' }];
	const pageData = reactive({
		tabNum:'0',
	})
	var menuId = [31800,31801]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	} 
	function handleClick(tab) {
      localStorage.setItem('appointment', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('appointment') != null) {
          pageData.tabNum = localStorage.getItem('appointment')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('appointment')
	})
</script>

<style scoped lang="stylus">
	
</style>
