import {postAjax,getAjax}  from "../common/axios.js"

// 场地列表
export const sportRoomList = params => {
	return postAjax('/api/sportRoom/list',params);
}

// 团体课表的场地下拉框
export const sportRoomListAll = params => {
	return postAjax('/api/sportRoom/listAll',params);
}
// 场地状态
export const sportRoomUpdateState = params => {
	return postAjax('/api/sportRoom/updateState',params);
}

// 新增场地
export const sportRoomInsert = params => {
	return postAjax('/api/sportRoom/insert',params);
}

// 场地详情
export const sportRoomInfo = params => {
	return postAjax('/api/sportRoom/info',params);
}

// 编辑场地
export const sportRoomUpdate = params => {
	return postAjax('/api/sportRoom/update',params);
}

// 删除场地
export const sportRoomDelete = params => {
	return postAjax('/api/sportRoom/delete',params);
}

// 排课时查询场地列表
export const getRoomConflictList = params => {
	return postAjax('/api/course/getRoomConflictList',params);
}

// 调整课节时查询场地列表
export const getRoomConflictForEditList = params => {
	return postAjax('/api/course/getRoomConflictForEditList',params);
}

// 批量新增场地
export const insertBatch = params => {
	return postAjax('/api/sportRoom/insertBatch',params);
}
