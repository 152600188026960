import {postAjax,getAjax}  from "../common/axios.js"

// 教务中心-消费记录(会员)
export const orderConsume = params => {
    return postAjax('/api/orderConsume/list',params);
}
// 教务中心-上课明细(教练)
export const list4Teacher = params => {
    return postAjax('/api/courseLog/list4Teacher',params);
}

// 教务中心-消费记录(会员) 顶部统计
export const summary = params => {
    return postAjax('/api/orderConsume/summary',params);
}
// 教务中心-预约记录-列表
export const orderCourse = params => {
    return postAjax('/api/orderCourse/list',params);
}
// 教务中心-预约记录-门店获取课程列表
export const listSimple = params => {
    return postAjax('/api/run/courseName/listSimple',params);
}
// 教务中心-上课预约 顶部统计
export const orderCourseSummary = params => {
    return postAjax('/api/orderCourse/summary',params);
}
// 教务中心-预约到访-列表查询
export const orderVisitLsit = params => {
    return postAjax('/api/orderVisit/list',params);
}
// 教务中心-预约到访-新增
export const orderVisitUp = params => {
    return postAjax('/api/orderVisit/insert',params);
}
// 教务中心-预约到访-修改
export const orderVisitUpdate = params => {
    return postAjax('/api/orderVisit/update',params);
}
// 教务中心-预约到访-删除
export const orderVisitDelete = params => {
    return postAjax('/api/orderVisit/delete',params);
}
// 教务中心-消费记录-签到明细
export const courseSignInfo = params => {
    return postAjax('/api/courseLog/courseSignInfo',params);
}
// 教务中心-消费记录-课节明细
export const list4TeacherInfo = params => {
    return postAjax('/api/courseLog/list4TeacherInfo',params);
}

// 查询消费支付方式(资产名称)
export const listConsumeProductName = params => {
    return postAjax('/api/orderConsume/listConsumeProductName',params);
}
// 预约详情
export const orderCourseInfo = params => {
    return postAjax('/api/orderCourse/info',params);
}
// // 获取小程序码
// export const getQrcodeMiniProd = params => {
//     return getAjax('/api/open/getQrcodeMiniProd',params);
// }
