<template>
	<div class="pt20 pb20">
		<div class="mb20 rx-ec">
            <span class="color-999 ft12 mr10">温馨提示：当前页面数据仅统计截止至当前时间</span>
            <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12 mr20" @click="formula">计算公式</a>
        </div>
		<div class="rx-sc">
			<div class="ww50 mr35 h600" style="border: 1px solid #E0E0E0;padding:25px;">
				<div>
					<div  class="rx-bc">
						<div class="rx-sc">
							<b class="color-333 ft18 mr12">会员新增趋势</b>
						</div>
						<div class="rx-c">
							<div class="w100 mr10">
								<el-select v-model="pageData.formInline.timeIn" @change="( (val)=>{changeTimeIn(val, 1)} )">
        				      	  <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
        				      	</el-select>
							</div>
							<div v-if="pageData.formInline.timeIn == -1" >
								<el-date-picker
        				            style="height: 32px;width:220px"
        				            v-model="pageData.formInline.searchTime"
        				            type="daterange"
        				            range-separator="至"
        				            format="YYYY-MM-DD"
        				            value-format="YYYY-MM-DD"
        				            start-placeholder="开始日期"
        				            end-placeholder="结束日期"
        				            :disabled-date="pageData.disabledDate"
									@change="( (val)=>{daterange(val, 1)} )"
        				        />
							</div>
							<div v-if="pageData.formInline.timeIn == -2">
								<el-date-picker
        				            style="height: 32px;width:220px"
        				            v-model="pageData.formInline.searchTime"
        				            type="monthrange"
        				            format="YYYY-MM"
        				            value-format="YYYY-MM"
        				            range-separator="至"
        				            start-placeholder="开始月份"
        				            end-placeholder="结束月份"
        				            :disabled-date="pageData.setMonthDisabled"
									@change="( (val)=>{daterange(val, 1)} )"
        				        />
							</div>
						</div>
					</div>
					<div id="myChart" class="h475 mt45"></div>
				</div>
			</div>
			<div class="ww50 h600" style="border: 1px solid #E0E0E0;padding:25px;">
				<div>
					<div  class="rx-bc">
						<div class="rx-sc h35">
							<b class="color-333 ft18 c_p" :class="[pageData.num == 1 ? 'active' : '' ]" @click="tabClick(1)">新增客户成交</b>
							<div class="h20 ml10 mr10 bg-999" style="width:2px;"></div>
							<b class="color-333 ft18 c_p" :class="[pageData.num == 2 ? 'active' : '' ]" @click="tabClick(2)">整体客户成交</b>
						</div>
						<div class="rx-c" v-if="pageData.num == 1">
							<div class="w100 mr10">
								<el-select v-model="pageData.formInline1.timeIn" @change="( (val)=>{changeTimeIn(val, 2)} )">
        				      	  <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
        				      	</el-select>
							</div>
							<div v-if="pageData.formInline1.timeIn == -1" >
								<el-date-picker
        				            style="height: 32px;width:220px"
        				            v-model="pageData.formInline1.searchTime"
        				            type="daterange"
        				            range-separator="至"
        				            format="YYYY-MM-DD"
        				            value-format="YYYY-MM-DD"
        				            start-placeholder="开始日期"
        				            end-placeholder="结束日期"
        				            :disabled-date="pageData.disabledDate"
									@change="( (val)=>{daterange(val, 2)} )"
        				        />
							</div>
							<div v-if="pageData.formInline1.timeIn == -2">
								<el-date-picker
        				            style="height: 32px;width:220px"
        				            v-model="pageData.formInline1.searchTime"
        				            type="monthrange"
        				            format="YYYY-MM"
        				            value-format="YYYY-MM"
        				            range-separator="至"
        				            start-placeholder="开始月份"
        				            end-placeholder="结束月份"
        				            :disabled-date="pageData.setMonthDisabled"
									@change="( (val)=>{daterange(val, 2)} )"
        				        />
							</div>
						</div>
					</div>
					<div class="mt45 mb45">
						<span class="color-999 ft16">成交率：</span>
						<span class="color-EE4B36 ft18">{{ pageData.num == 1 ? pageData.closeRate : pageData.allCloseRate }}</span>
					</div>
					<div class="divs divs1">
						<div class="hh100 rx-sc">
							<div class="pl40">
								<h3 class="color-666 fwb ft19 mb12">新增客户</h3>
								<div class="rx-se">
									<div class="mr20">
										<span class="fwb ft24" style="color: #6C6DF6;">{{ pageData.num == 1 ? pageData.addCount : pageData.allAddCount }}</span>
										<span class="color-999">/人</span>
									</div>
								</div>
							</div>
						</div>
						<div class="chartBg"></div>
						<div class="chartBox color-fff fwb t_a_c">新增客户</div>
					</div>
					<div class="divs divs2">
						<div class="hh100 rx-sc">
							<div class="pl40">
								<h3 class="color-666 fwb ft19 mb12">已跟进客户</h3>
								<div class="rx-se">
									<div class="mr20">
										<span class="fwb ft24" style="color: #4DA692;">{{ pageData.num == 1 ? pageData.followUp : pageData.allFollowUp }}</span>
										<span class="color-999">/人</span>
									</div>
								</div>
							</div>
						</div>
						<div class="chartBg"></div>
						<div class="chartBox color-fff fwb">已跟进客户</div>
					</div>
					<div class="divs divs3">
						<div class="hh100 rx-sc">
							<div class="pl40">
								<h3 class="color-666 fwb ft19 mb12">已成交客户</h3>
								<div class="rx-se">
									<div class="mr20">
										<span class="fwb ft24" style="color: #F478AB;">{{ pageData.num == 1 ? pageData.closed : pageData.allClosed }}</span>
										<span class="color-999">/人</span>
									</div>
								</div>
							</div>
						</div>
						<div class="chartBg"></div>
						<div class="chartBox color-fff fwb">已成交客户</div>
					</div>
				</div>
			</div>
		</div>
		<Formula v-model="pageData.formulaShow" :type="'20'"></Formula>
	</div>
</template>

<script setup>
	import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
	import utils from '@/common/utils.js';
	import { ElMessage } from 'element-plus'
    import { getFollowProfile, getFollowDealProfile } from '@/api/dataCenter.js'
	import Formula from '@/components/branch-store/datacenter/formula.vue';
	import * as echarts from 'echarts';
    let myChart = null
    const dataList =[
        { value : "5" , label : '近7天' },
        { value : "7" , label : '近30天' },
        { value : "8" , label : '近60天' },
        { value : "9" , label : '近90天' },
        { value : "24", label : '近6个月' },
        { value : "25", label : '近12个月' },
        { value : "-1" , label : '自定义日期'},
        { value : "-2" , label : '自定义月份' }
    ]
	const pageData = reactive({
		formulaShow: false,
		num: 1,
		closeRate: '',
		allCloseRate: '',
		allAddCount: '',
		addCount: '',
		allFollowUp: '',
		followUp: '',
		allClosed: '',
		closed: '',
		formInline: {
            timeIn: '5',
            searchTime: []
        },
		formInline1: {
            timeIn: '5',
            searchTime: []
        },
        tableData: [],
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
        setMonthDisabled(time) {
            // 获取当前的月份信息
            const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
              // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
              month = "0" + month;
            }
            const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选 
            return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
		option: {
		  	title: [{
					text:  ['{a|潜在会员：}','{b| 0人}'].join(''),
					left:0,
					textStyle: {
        			    color: "#666",
        			    fontStyle: "normal",
        			    fontWeight: "normal",
        			    fontFamily: "sans-serif",
        			    fontSize: 16,
						rich:{
            	            a: {
            	                color: '#666'
            	            },
            	            b:{
            	                color: '#EE4B36'
            	            }
           				} 
        			}
				},{
					text:  ['{a|正式会员：}','{b| 0人}'].join(''),
					left:110,
					textStyle: {
        			    color: "#666",
        			    fontStyle: "normal",
        			    fontWeight: "normal",
        			    fontFamily: "sans-serif",
        			    fontSize: 16,
						rich:{
                	        a: {
                	            color: '#666'
                	        },
                	        b:{
                	            color: '#EE4B36'
                	        }
           				} 
        			}
			}],
		  	tooltip: {
		  	  	trigger: 'axis',
		  	},
			legend: {
  			  	data: ['正式会员', '潜在会员'],
				right: 10,
  			},
		  	grid: {
		  	  	left: '3%',
		  	  	right: '4%',
		  	  	bottom: '3%',
		  	  	containLabel: true
		  	},
		  	xAxis: [{
		  	  	  	type: 'category',
		  	  	  	boundaryGap: false,
		  	  	  	data: [],
					splitLine:{
         			  show: true, //让网格显示
         			  lineStyle: {//网格样式
         			    color:'rgba(0, 0, 0, 0.15)', //网格的颜色
         			    width:1, //网格的宽度
         			    type:'solid' //网格是实实线，可以修改成虚线以及其他的类型
         			  }
         			},
                    axisLabel: {
                        interval: 6,
                        rotate: 35
                    }
		  	  	}],
		  	yAxis: [{
		    	  	type: 'value'
		    	}],
		  	series: [{
		      	name: '潜在会员',
		      	type: 'line',
				data: [],
		      	areaStyle: {//区域填充渐变颜色
		            color: {
		                type: 'linear',
		                x: 0,
		                y: 0,
		                x2: 0,
		                y2: 1,
		                 colorStops: [{
		                    offset: 0, color: 'rgba(245, 117, 87,0.16)' // 0% 处的颜色
		                }, {
		                    offset: 1, color: 'rgba(245, 117, 87, 0)' // 100% 处的颜色
		                }]
		            }
		      	},
		      	symbol: 'circle',
		      	symbolSize: 6,
		      	itemStyle: {
				  	normal: {
				  	  	color: "#F57557", //改变折线点的颜色
				  	  	lineStyle: {
				  	  	  	color: "#F57557", //改变折线颜色
				  	  	},
				  	},
				}
		    }, {
		      	name: '正式会员',
		      	type: 'line',
		      	data: [],
		      	areaStyle: {//区域填充渐变颜色
		      	    color: {
		      	        type: 'linear',
		      	        x: 0,
		      	        y: 0,
		      	        x2: 0,
		      	        y2: 1,
		      	        colorStops: [{
		      	            offset: 0, color: 'rgba(59, 201, 231,0.16)' // 0% 处的颜色
		      	        }, {
		      	            offset: 1, color: 'rgba(59, 201, 231, 0)' // 100% 处的颜色
		      	        }],
		      	    }
		      	},
		      	symbol: 'circle',
		      	symbolSize: 6,
		      	itemStyle: {
				  	normal: {
				  	  	color: "#3BC9E7", //改变折线点的颜色
				  	  	lineStyle: {
				  	  	  	color: "#3BC9E7", //改变折线颜色
				  	  	},
				  	},
				}
		    }]
		}
	})
	/**
     * 计算公式
     */
    function formula() {
        pageData.formulaShow = true
    }
	/**
	 * 客户切换
	 */
	function tabClick(val) {
		pageData.num = val
	}
	/**
     * 切换日期范围
     */
    function changeTimeIn(val, type) {
		if (type == 1) {
			pageData.formInline.searchTime = []
			console.log(val)
        	if (val == -1) {
				ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
        	} else if (val == -2) {
				ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
        	} else {
				getInfo()
			}
		} else {
			pageData.formInline1.searchTime = []
        	if (val == -1) {
				ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
        	} else if (val == -2) {
				ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
        	} else {
				getInfo1()
			}
		}
		
    }
	/**
	 * 选择日期
	 */
	function daterange(val, type) {
		if (type == 1) {
			if (val) {
				pageData.formInline.searchTime = val
				getInfo()
			}
		} else {
			if (val) {
				pageData.formInline1.searchTime = val
				getInfo1()
			}
		}
	}
	/**
	 * 获取会员数据
	 */
	function getInfo() {
		let params = {
			timeIn: pageData.formInline.timeIn,
			someTimes: pageData.formInline.searchTime.join('-')
		}
		getFollowProfile(params).then((res) => {
			if (res.code == 0) {
				myChart = echarts.init(document.getElementById('myChart'))
				pageData.option.xAxis[0].data = [] // x轴
				pageData.option.series[0].data = [] // 潜在会员
				pageData.option.series[1].data = [] // 正式会员
				pageData.option.title[1].text=  ['{a|潜在会员：}','{b| '+ res.results.informalCount +'人}'].join(''),
				pageData.option.title[0].text=  ['{a|正式会员：}','{b| '+ res.results.toFormalCount +'人}'].join(''),
				res.results.informalList.forEach((item) => {
					pageData.option.xAxis[0].data.push(item.createDate)
					pageData.option.series[0].data.push(item.memberCount)
				})
				res.results.toFormalList.forEach((item) => {
					pageData.option.series[1].data.push(item.memberCount)
				})
				myChart.setOption( pageData.option, true);
				window.addEventListener("resize",function(){
        		    myChart.resize();
        		});
			}
		})
	}
	/**
	 * 获取客户数据
	 */
	function getInfo1(){
		let params = {
			timeIn: pageData.formInline1.timeIn,
			someTimes: pageData.formInline1.searchTime.join('-')
		}
		getFollowDealProfile(params).then((res) => {
			if (res.code == 0) {
				
				pageData.closeRate = res.results.financePercent*10000/100 + '%'
				pageData.allCloseRate = res.results.financeAllPercent*10000/100 + '%'
				pageData.allClosed = res.results.periodAllList[0].financeCount
				pageData.closed = res.results.periodList[0].financeCount
				pageData.allAddCount = res.results.periodAllList[0].memberCount
				pageData.addCount = res.results.periodList[0].memberCount
				pageData.allFollowUp = res.results.periodAllList[0].followCount
				pageData.followUp = res.results.periodList[0].followCount
			}
		})
	}
	onMounted(() => {
		getInfo()
		getInfo1()
	})
	onBeforeUnmount(() => {
      	if (!myChart) {
      	  	return
      	}
      	myChart.dispose()
      	myChart = null
    })
</script>

<style type='css/style' scoped>
	.active{
		color: #03A3FF;
	}
	.divs{
		height: 110px;
		margin-bottom: 12px;
		position: relative;
	}
	.divs .chartBg,.divs .chartBox{
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		line-height: 110px;
		font-size: 21px;
	}
	.divs1{
		background-color: rgba(108, 109, 246, .1);
	}
	.divs2{
		background-color: rgba(77, 166, 146, .1);
	}
	.divs3{
		background-color: rgba(244, 120, 171, .1);
	}
	.divs.divs1 .chartBg{
		width: 450px;
		background-color: white;
		clip-path: polygon(0px 0px,450px 0,450px 110px,40px 110px,0px 0px);
		z-index: 1;
	}
	.divs.divs1 .chartBox{
		width: 450px;
		background-color: #6C6DF6;
		clip-path: polygon(0px 0px,450px 0,410px 110px,40px 110px,0px 0px);
		z-index: 2;
	}
	.divs.divs2 .chartBg{
		width: 405px;
		background-color: white;
		clip-path: polygon(0px 0px,405px 0,405px 110px,40px 110px,0px 0px);
		z-index: 1;
	}
	.divs.divs2 .chartBox{
		width: 405px;
		background-color: #4DA692;
		clip-path: polygon(0px 0px,360px 0,320px 110px,40px 110px,0px 0px);
		z-index: 2;
		padding-left: 130px;
	}
	.divs.divs3 .chartBg{
		width: 360px;
		background-color: white;
		clip-path: polygon(0px 0px,360px 0,360px 110px,40px 110px,0px 0px);
		z-index: 1;
	}
	.divs.divs3 .chartBox{
		width: 360px;
		background-color: #F478AB;
		clip-path: polygon(0px 0px,270px 0,230px 110px,40px 110px,0px 0px);
		z-index: 2;
		padding-left: 85px;
	}
	@media screen and (max-width: 1370px) {
		.divs .chartBox{
			font-size: 14px !important;
		}
	    .divs.divs1 .chartBg{
			width: 300px !important;
			clip-path: polygon(0px 0px,300px 0,300px 110px,40px 110px,0px 0px) !important;
		}
		.divs.divs1 .chartBox{
			width: 300px !important;
			clip-path: polygon(0px 0px,300px 0,260px 110px,40px 110px,0px 0px) !important;	
		}
		.divs.divs2 .chartBg{
			width: 258px !important;
			clip-path: polygon(0px 0px,258px 0,258px 110px,40px 110px,0px 0px) !important;
		}
		.divs.divs2 .chartBox{
			width: 258px !important;
			clip-path: polygon(0px 0px,215px 0,175px 110px,40px 110px,0px 0px) !important;
			padding-left: 73px !important;
		}
		.divs.divs3 .chartBg{
			width: 215px !important;
			clip-path: polygon(0px 0px,215px 0,215px 110px,40px 110px,0px 0px) !important;
		}
		.divs.divs3 .chartBox{
			width: 215px !important;
			clip-path: polygon(0px 0px,130px 0,90px 110px,40px 110px,0px 0px) !important;
			padding-left: 30px !important;
		}
	}
</style>