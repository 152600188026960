<template>
    <workArea :barlist="barlist">
        <div class="pt25 pb25 pl35 pr35">
            <div class="title">功能拓展</div>
            <div class="title-box rx-c">
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p">
                        <img class="w50 h50" src="@/assets/shoppingMall.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">商城管理</div>
                            <div class="color-666">上架商品到会员端</div>
                        </div>
                    </div>
                    <div class="posi-a tip bg-8ADCFF color-0074FF">待上线</div>
                </div>
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p" @click="linkPath('/branch/appstore/place')">
                        <img class="w50 h50" src="@/assets/place.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">场地预约</div>
                            <div class="color-666">运动场馆在线预订查询</div>
                        </div>
                    </div>
                </div>
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p" @click="linkPath(1)">
                        <img class="w50 h50" src="@/assets/market.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">测评中心</div>
                            <div class="color-666">体验课测评，提高招生效率</div>
                        </div>
                    </div>
                </div>
                <div class="rx-c title-box_div c_p">
                    <img class="w50 h50" src="@/assets/more.png" alt="">
                    <div class="ml25">
                        <div class="color-333 fwb ft16 mb12">更多功能</div>
                        <div class="color-666">开发中，敬请期待…</div>
                    </div>
                </div>
            </div>
            <div class="title">营销玩法</div>
            <div class="title-box rx-c">
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p">
                        <img class="w50 h50" src="@/assets/coupon.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">优惠券</div>
                            <div class="color-666">扫码领券，拉新转化</div>
                        </div>
                    </div>
                    <div class="posi-a tip bg-FFD05C color-FE7C34">待上线</div>
                </div>
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p">
                        <img class="w50 h50" src="@/assets/invite.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">邀请有礼</div>
                            <div class="color-666">会员自发传播，社交裂变</div>
                        </div>
                    </div>
                    <div class="posi-a tip bg-FFD05C color-FE7C34">待上线</div>
                </div>
                <div class="rx-c title-box_div c_p">
                    <img class="w50 h50" src="@/assets/more1.png" alt="">
                    <div class="ml25">
                        <div class="color-333 fwb ft16 mb12">更多玩法</div>
                        <div class="color-666">开发中，敬请期待…</div>
                    </div>
                </div>
            </div>
            <div class="title">硬件接入</div>
            <div class="title-box rx-c">
                <div class="posi-r title-box_div">
                    <div class="rx-c hh100 c_p">
                        <img class="w50 h50" src="@/assets/entranceGuard.png" alt="">
                        <div class="ml25">
                            <div class="color-333 fwb ft16 mb12">智能门禁</div>
                            <div class="color-666">扫脸/扫码，轻松进入</div>
                        </div>
                    </div>
                    <div class="posi-a tip bg-99E8CB color-1B9467">待上线</div>
                </div>
                <div class="rx-c title-box_div c_p">
                    <img class="w50 h50" src="@/assets/more2.png" alt="">
                    <div class="ml25">
                        <div class="color-333 fwb ft16 mb12">更多硬件</div>
                        <div class="color-666">开发中，敬请期待…</div>
                    </div>
                </div>
            </div>
        </div>
    </workArea>
</template>

<script setup>
    import { reactive } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import {useRouter} from 'vue-router';
    import { ElMessage } from 'element-plus'
    import utils from '@/common/utils.js';
    const router = useRouter();
    const barlist = reactive([{ name : '首页' },{ name : '应用中心' }]); // 面包屑
    const pageData = reactive({})
    /**
     * 快捷入口
     */
    function linkPath(path) {
        if (path == 1) {
            if (utils.getStoresFun(1001)) {
                var nowUrl = window.location.href;
                var PAY_PATH = nowUrl.indexOf('https://s.yxysport.com') >-1 ? 'https://market.yxysport.com/#/login' : 'https://market.flow360.cn/#/login'
                window.open(PAY_PATH + '?from=2&info=' + localStorage.getItem('businessToken'), '_blank')
            } else {
                ElMessage.warning('该功能暂未开通，请联系销售顾问')
            }
        } else {
            if (utils.getStoresFun(1000)) {
                router.push(path);
            } else {
                ElMessage.warning('该功能暂未开通，请联系销售顾问')
            }
        }
        
    }
</script>
<style type='css/style' scoped>
    .title{
        margin-bottom: 18px;
        font-size: 18px;
        font-weight: bold;
        color: #4E4E4E;
    }
    .title-box{
        margin-bottom: 42px;
    }
    .title-box_div{
        background: #F9F8FD;
        border-radius: 4px;
        height: 96px;
        width: 325px;
        padding-left: 41px;
        margin-right: 32px;
    }
    .tip{
        top: 0;
        right: 0;
        padding: 2px 9px 4px 16px;
        border-radius: 0px 4px 0px 100px;
    }
</style>