<template>
	<div class="pt20 pb20">
        <div class="rx-bc">
            <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                <el-form-item>
                  <el-select v-model="pageData.formInline.timeIn" @change="changeTimeIn">
                    <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="pageData.formInline.timeIn == -1">
                    <el-date-picker
                        style="height: 32px ;height: 32px"
                        v-model="pageData.formInline.searchTime"
                        type="daterange"
                        range-separator="至"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled-date="pageData.disabledDate"
                    />
                </el-form-item>
                <el-form-item v-if="pageData.formInline.timeIn == -2">
                  <el-date-picker
                        style="height: 32px ;height: 32px"
                        v-model="pageData.formInline.searchTime"
                        type="monthrange"
                        format="YYYY-MM"
                        value-format="YYYY-MM"
                        range-separator="至"
                        start-placeholder="开始月份"
                        end-placeholder="结束月份"
                        :disabled-date="pageData.setMonthDisabled"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
            <div>
                <span class="color-999 ft12 mr10">温馨提示：当前页面数据仅统计截止至当前时间</span>
                <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12 mr20" @click="formula">计算公式</a>
            </div>
        </div>
		
		<div class="color-333 fwb ft20">会员来源分布</div>
		<div class="rx-cc mt100 h400" v-loading="pageData.loading">
			<div class="ww40 hh100">
				<div id="myChart" class="h400"></div>
			</div>
			<div class="ww60 hh100 posi-r">
				<el-form class="right_form" label-width="120px">
    				<el-form-item label="会员类型">
						<div class="nav">
							<span class="d_i_b c_p" :class="pageData.tabNum == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
						</div>
    				</el-form-item>
					<el-form-item v-for="(item, index) in pageData.tableData" :key="index" :label="item.channelName ? item.channelName : '未知'">  
						<span class="d_i_b h20 mr10" :style="{ width: 400*item.percent/100 +'px', background: pageData.myChartColor[index] }"></span>
						<span>{{ item.memberCount }}人, {{ item.percent }}%</span>
    				</el-form-item>
				</el-form>
			</div>
		</div>
        <Formula v-model="pageData.formulaShow" :type="'19'"></Formula>
	</div>
</template>

<script setup>
	import { reactive, onMounted, onBeforeUnmount, nextTick } from 'vue';
	import { Search, Upload, Download } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import utils from '@/common/utils.js';
    import { getChannelAnalysis } from '@/api/dataCenter.js'
    import Formula from '@/components/branch-store/datacenter/formula.vue';
	import * as echarts from 'echarts';
    let myChart = null
    const dataList =[
        { value : "5" , label : '近7天' },
        { value : "7" , label : '近30天' },
        { value : "8" , label : '近60天' },
        { value : "9" , label : '近90天' },
        { value : "24", label : '近6个月' },
        { value : "25", label : '近12个月' },
        { value : "-1" , label : '自定义日期'},
        { value : "-2" , label : '自定义月份' }
    ]
	const pageData = reactive({
        formulaShow: false,
        myChartColor: [],
        loading: false,
		tabNum: 1,
		tabeList: [{
            label: '正式会员',
            val: 1
        }, {
            label: '潜在会员',
            val: 2
        }],
		formInline: {
            timeIn: '5',
            searchTime: []
        },
        tableData: [],
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
        setMonthDisabled(time) {
            // 获取当前的月份信息
            const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
              // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
              month = "0" + month;
            }
            const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选 
            return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
		option: {
			title: {
                text: '共167人',
                x: 'center',
                y: 'center',
            },
          	// tooltip: {
          	//   	trigger: 'item'
          	// },
			series: [{
                name: '会员来源',
                type: 'pie',
                radius: ['40%', '60%'],
                avoidLabelOverlap: false,
                label: {
                    formatter: '{c}',
                    show: true,
                    position: 'inner',
                    color:'#FFF'
                },
                labelLine: {
                  show: true
                },
                data: []
            }]
        },
	})
    /**
     * 计算公式
     */
    function formula() {
        pageData.formulaShow = true
    }
	/**
     * 搜索
     */
    function search() {
        if (pageData.formInline.timeIn == '-1') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (pageData.formInline.timeIn == '-2') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
        getInfo()
    }
    /**
     * 切换日期范围
     */
    function changeTimeIn(val) {
        pageData.formInline.searchTime = []
    }
	/**
	 * 获取信息
	 */
	function getInfo (){
        pageData.loading = true
        let params = {
            timeIn: pageData.formInline.timeIn,
            searchTime: pageData.formInline.searchTime.join(' - '),
            trialFlag: pageData.tabNum
        }
        getChannelAnalysis(params).then((res) => {
            pageData.loading = false
            if(res.code == 0){
                myChart = echarts.init(document.getElementById('myChart'))
                nextTick(() => {
                    pageData.myChartColor = myChart.getOption().color
                })
                pageData.option.series[0].data = []
                let object = {}
                pageData.tableData = res.data
                let num = 0
                res.data.forEach((item) => {
                    object = {
                        value: item.memberCount,
                        name: item.channelName
                    }
                    num += item.memberCount
                    pageData.option.series[0].data.push(object)
                })
                pageData.option.title.text = `共${num}人`
		        myChart.setOption( pageData.option, true);
		        window.addEventListener("resize",function(){
                    myChart.resize();
                });
            }
        }).catch(() => {
            pageData.loading = false
        })
		
	}
	/**
     * tabe数组切换
     */
    function getContentList(val) { 
        pageData.tabNum = val
        getInfo()
    }
	onMounted(() => {
        getInfo()
    })
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
	.right_form{
		position: absolute;
		top: 50%;		
		transform:translate(0%,-50%); /*针对元素本身向左以及向上移动50%*/
	}
	.nav span{
        padding: 0 15px 0 15px;
		border: 1px solid #D6D6D6;
		background: rgba(214, 214, 214, 0.1);
		margin-right: 16px;
		border-radius: 16px;
        color: #666;
    }
    .nav .active {
        background: rgba(5, 164, 255, 0.1);
		border-color: #03A3FF;
        color: #05A4FF;
    }
    ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 15px;
    }
</style>