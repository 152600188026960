<!-- 会员课程页面 -->
<template>
    <div class="bg-fff">
		<div class="nav">
            <formQuery :queryOpts="pageData.queryOpts" ref="queryOptsRef" @query="searchGetList" style="flex: 1"></formQuery>
			<div class="derive">
				<el-button @click="screen" :icon="Operation">筛选</el-button>
				<el-button :icon="Upload" @click="getList(true)">导出</el-button>
			</div>
        </div>
        <!-- 按钮区域 -->
        <!-- <div class="rx-sc mb16">
            <el-button class="query_btn_blue" type="small" @click="delayCard">会员卡延期</el-button>
            <el-button class="query_btn_gray" size="small" @click="batchUnfrozen(1)">批量解冻</el-button>
            <el-button class="query_btn_red" size="small" @click="batchFrozen(1)">批量冻结</el-button>
        </div> -->
        <!-- 数据表格 -->
        <div class="">
            <el-table :data="pageData.memberList" stripe style="width: 100%" v-loading="pageData.loading">
                <el-table-column prop="realName" fixed label="会员姓名" show-overflow-tooltip>
                    <template #default="scope">
                        <span @click="toMemberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="telephoneNum" label="手机号"  width="120"></el-table-column>
                <el-table-column label="课程名称" show-overflow-tooltip>
					<template #default="scope">
						<div>
							<div>{{ scope.row.productName }}</div>
							<div v-if="scope.row.courseType == 13">
								<span class="ft12 color-999">班级:{{ scope.row.cycleName || '-' }}</span>
							</div>
						</div>
					</template>
				</el-table-column>
                <el-table-column label="课程类型">
                    <template #default="scope">
                        <span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30',border:'1px solid '+courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="actualMoney" label="购买金额(元)" ></el-table-column>
                <el-table-column prop="productNumBuy" label="购买节数" ></el-table-column>
                <el-table-column prop="buyGiveNum" label="赠送节数" >
					<template #default="scope">
						{{ scope.row.buyGiveNum ? scope.row.buyGiveNum : '--' }}
					</template>
				</el-table-column>
                <el-table-column prop="remainMoney" label="剩余金额(元)" >
					<template #default="scope">
						<span v-if="pageData.results && pageData.results.stuAssetBalance == 1">{{ scope.row.remainMoney || scope.row.remainMoney == 0 ? scope.row.remainMoney : '--' }}</span>
						<span v-else>{{ scope.row.remainMoney ? '***' : '--' }}</span>
					</template>
				</el-table-column>
                <el-table-column label="剩余次数">
					<template #default="scope">
						{{ scope.row.productNum || scope.row.productNum == 0 ? scope.row.productNum : '--' }}
					</template>
				</el-table-column>
                <el-table-column label="状态">
                    <template #default="scope">
						<el-link v-if="scope.row.orderState == 1" :underline="false" type="success">正常</el-link>
						<el-link v-if="scope.row.orderState == 2" :underline="false">已冻结</el-link>
						<el-link v-if="scope.row.orderState == 3" :underline="false" type="danger">已结束</el-link>
                    </template>
                </el-table-column>
                <el-table-column label="跟进人" show-overflow-tooltip>
					<template #default="scope">
						{{ scope.row.adviserNames ? scope.row.adviserNames : '--' }}
					</template>
				</el-table-column>
                <el-table-column label="操作" width="160" fixed="right" align="left">
                    <template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.orderState != 2 && utils.getMenuRole(3130200) && scope.row.courseType !=13" @click="batchFrozen(scope.row.encryptionId)">冻结</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.orderState == 2 && utils.getMenuRole(3130201) && scope.row.courseType !=13" @click="batchUnfrozen(scope.row.encryptionId)">解冻</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.courseType == 13 && scope.row.cycleType == 12" @click="continuation(scope.row)">续课</a>
						<el-link class="t_d_u v_a_m" :underline="false" type="danger" v-if="scope.row.orderState != 2 && utils.getMenuRole(3130202) " @click="memberRefundEvent(scope.row)">退款</el-link>
                    </template>
                </el-table-column>
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec" v-show="pageData.memberList && pageData.memberList.length > 0">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="()=>{getList()}"
                >
                </el-pagination>
            </div>

            <!-- //冻结 -->
		<el-dialog
		     v-model="pageData.frozenShow"
		     title="冻结设置"
		 	 width="530px"
		     :before-close="()=>{pageData.frozenShow = false}"
		     custom-class="popwechat"
		>
			<div class="pl20 pt20 pb20 pr20">
				<CommonTip text="延期后，期限卡的单价会进行变更，单价=会员卡剩余价值/剩余天数。未开始计时的期限卡和次卡不受影响。"></CommonTip>
				<div class="rx-cc mb16">
					<span class="w90 t_a_r">冻结类型：</span>
					<div class="w300">
						<el-radio v-model="pageData.frozenType" label="1" size="large">手动冻结</el-radio>
						<el-radio v-model="pageData.frozenType" label="2" size="large">自动冻结</el-radio>
					</div>
				</div>
				<div class="rx-cc mb16" v-show="pageData.frozenType == 2">
					<span class="w90 t_a_r ml20">冻结时间：</span>
					<div class="data-Input">
						<el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="pageData.frozenForm.autoFrozenDate" type="date" placeholder="冻结时间"></el-date-picker>
						<span style="padding-top: 4px;">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="若选择当天，则冻结时间为当前时间；若选择其他日期，则冻结时间为00：00：00"
							    placement="top"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							  </el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-cc mb16" v-show="pageData.frozenType == 2">
					<span class="w90 t_a_r ml20">解冻时间：</span>
					<div class="data-Input">
						<el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="pageData.frozenForm.autoUnFrozenDate" type="date"  placeholder="解冻时间"></el-date-picker>
						<span style="padding-top: 4px;">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="自动解冻时间为选择日期的00：00：00"
							    placement="top"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							  </el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-cc mb16">
					<span class="w90 t_a_r">备注：</span>
					<div class="w300">
						 <el-input
						    v-model="pageData.frozenForm.remark"
						    :rows="3"
						    type="textarea"
						    placeholder="请输入备注"
						  />
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.frozenShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchFrozenSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<MemberRefund v-model="pageData.memberRefundShow" :memberid="pageData.memberId" :type="pageData.type" :orderid="pageData.orderId" @close="memberRefundClose" @save="memberRefundSave"></MemberRefund>
		<Screen ref="screenRef" :type="2" @reset="screenReset" @save="screenSave"></Screen>
		<MemberSignature v-model="pageData.memberSignatureShow" :from="8" :type="99" :cycleId="pageData.cycleId" :memberId="pageData.memberId" @close="memberSignatureClose" @save="memberSignatureSave" />
		<!-- <ExportMember v-model="pageData.exportMemberShow" type="4" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember> -->
        </div>
    </div>
</template>
<script setup>
    import { ref,reactive, onMounted } from 'vue';
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    import { DArrowRight, Close, Upload, Operation } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import Confirm from '@/components/Confirm.vue'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import AddMember from '@/components/branch-store/member/AddMember.vue';
	import Screen from '@/components/branch-store/member/Screen.vue'
	import MemberSignature from '@/components/branch-store/member/MemberSignature.vue'
	// import ExportMember from '@/components/branch-store/member/ExportMember.vue';
	import { queryAllBuyCourseList, unFrozen, frozen, autoFrozen } from '@/api/member.js';
    import MemberRefund from '@/components/branch-store/member/MemberRefund.vue';
	const queryOptsRef = ref()
	const screenRef = ref()
    const store = useStore();
    const router = useRouter();
    const pageData = reactive({
		memberRefundShow:false,
		orderId:'',
		memberId:'',
		// exportMemberShow:false,
		memberSignatureShow : false,
        memberList : [],
		results: {},
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15
        },
        frozenShow:false,//冻结
		frozenType:'1',
		type: 0,
		frozenForm:{
			encryptionId:'',
			autoFrozenDate: '',
			autoUnFrozenDate: '',
			remark: ''
		},
		screenData: {},
		cycleId: '',
        queryOpts:{
            fields:[{
                type : 'input',
                fieldName : 'searchKeys',
                placehold : '会员姓名/手机号',
                value:''
            },{
                type : 'input',
                fieldName : 'productName',
                placehold : '课程名称',
                value:''
            },{
                type : 'select',
                fieldName : 'courseType',
                placehold : '课程类型',
                options :  [{label : '全部类型' ,value : ''},{label : '团体课' ,value : '11'},{label : '私教课' ,value : '12'},{label : '小班课' ,value : '13'}],
                value:''
            },{
                type : 'select',
                fieldName : 'orderState',
                placehold : '状态',
                options : [{label : '全部状态' ,value : '0'},{label : '正常' ,value : '1'},{label : '已冻结' ,value : '2'},{label : '已结束' ,value : '3'}],
                value: '0'
            },{
                type : 'selectMany',
                fieldName : 'adviserIds',
                placehold : '跟进人',
                options : [],
                value:''
            }]
        }
    })
    const confirmRef = ref();
    const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		}
	}
    //获取字典
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[4].options = res;
	});

	// /**
	//  * 导出
	//  */
	// function exportMember(){
	// 	pageData.exportMemberShow = true;
	// }
	// function exportMemberSave(res){
	// 	pageData.addMemberShow = false;
	// }
	// function exportMemberClose(){
	// 	pageData.addMemberShow = false;
	// }
	/**
	 * 续课
	 */
	function continuation(row) {
		pageData.cycleId = row.cycleId
		pageData.memberId = row.encryptionCardMyId;
        pageData.memberSignatureShow = true;
	}
	// 保存会员签单
    function memberSignatureSave(res){
		memberSignatureClose();
    }
    // 关闭会员签单
    function memberSignatureClose(){
        pageData.memberSignatureShow = false;
    }
	/**
	 * 筛选
	 */
	function screen() {
		screenRef.value.show();
	}
	/**
	 * 确定筛选
	 */
	function screenSave(data) {
		pageData.screenData = data
		getList()
	}
	/**
	 * 筛选重置
	 */
	function screenReset(data) {
		pageData.screenData = data
		getList()
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取会员课程列表
     */
    function getList(isExport) {
        pageData.loading = true
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		if (Object.keys(pageData.screenData).length > 0) {
			params = Object.assign(pageData.screenData, params)
		}
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
		params.adviserIds = params.adviserIds && params.adviserIds.length > 0 ? params.adviserIds.join(',') : '';
		if(isExport){ //导出
			params.page = 1;
			params.limit = 9999;
			params.exportData = 1;
		}
        queryAllBuyCourseList(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.memberList = res.data
					pageData.totalCount = res.count
					pageData.results = res.results
				}
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    //ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		getList();
		callback && callback();
	}
	//冻结
	function batchFrozen(id){
		pageData.frozenForm = {}
		pageData.frozenType = '1'
		pageData.frozenForm.encryptionId = id;
		pageData.frozenShow = true;
	}
	function batchFrozenSave(){
		var formData = Object.assign({},pageData.frozenForm);
		if(pageData.frozenType == 1){
			frozen({encryptionId: formData.encryptionId }).then((res)=>{
		    	if(res.code == 0){
		    		ajaxSaveCallBack('冻结成功');
		    		pageData.frozenShow = false;
		            getList()
		    	}
		    })
		}else{
			if(!formData.autoUnFrozenDate){
				ElMessage({
					type: 'warning',
					message: '请选择冻结时间',
				})
				return false;
			}
			if(!formData.autoFrozenDate){
				ElMessage({
					type: 'warning',
					message: '请选择解冻时间',
				})
				return false;
			}
			autoFrozen(formData).then((res)=>{
		    	if(res.code == 0){
		    		ajaxSaveCallBack('冻结成功');
		    		pageData.frozenShow = false;
		            getList()
		    	}
		    })
		}
		
	}
	
	
	//解冻
	let unfrozenId = '';
	function batchUnfrozen(id){
		unfrozenId = id;
		pageData.confirmTitle = '确定要解冻该记录吗？';
		confirmRef.value.show();
	}
	
 //退款
	function memberRefundEvent(row){
		pageData.orderId = row.encryptionFinanceId;
		pageData.memberId = row.encryptionCardMyId;
		pageData.type = row.courseType
		pageData.memberRefundShow = true;
	}
	function memberRefundClose(){
		pageData.memberRefundShow = false;
	}
	function memberRefundSave(){
		pageData.memberRefundShow = false;
		getList();
	}
	//通用确认框确认事件回调
	function confirmSave(){
		unFrozen({
			encryptionId: unfrozenId
		}).then((res)=>{
			if(res.code == 0){
				ajaxSaveCallBack('解冻成功');
				getList()
			}
		})
	}
    function toMemberDetail(id){
        router.push('/branch/memberDetail?encryptionId='+id);
    }
    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        // if (to.path === '/branch/memberDetail') {
        //     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
        // } else {
        //     store.commit('SetKeep',{keepLiveRoute : []});
        // }
    })
    onMounted(()=>{
        // 查询会员卡项列表
        getList();
    });
</script>
<style scoped>
	.nav{
		display: flex;
	}
    .derive {
        margin-top: 16px;
    }
	.data-Input /deep/ .el-input {
        width: 300px;
	}
</style>
