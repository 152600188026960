import {postAjax,getAjax}  from "../common/axios.js"

//标签类表
export const getCustomTag = params => {
	return postAjax('/api/system/getCustomTag',params)
}
//删除标签
export const customTagDelete = params => {
	return postAjax('/api/system/customTagDelete',params)
}
//新增标签
export const customTagInsert = params => {
	return postAjax('/api/system/customTagInsert',params)
}
//编辑标签
export const customTagUpdate = params => {
	return postAjax('/api/system/customTagUpdate',params)
}
//跟进状态列表
export const followStatusManage = params => {
	return postAjax('/api/associator/followStatusManage',params)
}
//跟进状态删除
export const deleteStatus = params => {
	return postAjax('/api/associator/deleteStatus',params)
}
//跟进状态新增
export const statusAdd = params => {
	return postAjax('/api/associator/statusAdd',params)
}
//跟进状态修改
export const updateInterimUpStatus = params => {
	return postAjax('/api/associator/updateInterimUpStatus',params)
}
//跟进状态开启、关闭
export const updateStatusStatus = params => {
	return postAjax('/api/associator/updateStatusStatus',params)
}
//会员来源新增
export const channelAdd = params => {
	return postAjax('/api/system/channelAdd',params)
}
//会员来源编辑
export const channelEdit = params => {
	return postAjax('/api/system/channelEdit',params)
}
//会员来源删除
export const channelDelete = params => {
	return postAjax('/api/system/channelDelete',params)
}
//多个设置数据集合
export const getOtherSetting = params => {
	return postAjax('/api/system/getOtherSetting',params)
}
//获取收款方式
export const payDetailToEdit = params => {
	return postAjax('/api/system/payDetailToEdit',params)
}
//修改收款方式
export const payDetailSave = params => {
	return postAjax('/api/system/payDetailSave',params)
}
// 查询预约设置
export const getBaseInfo = params => {
	return postAjax('/api/courseSetting/info', params)
}
// 设置预约设置
export const setBaseInfo = params => {
	return postAjax('/api/courseSetting/update', params)
}
//  查询机构基础信息
export const getMyLineStore = params => {
	return postAjax('/api/system/getMyLineStore', params)
}
// 新增机构地址
export const storeAddAddr = params => {
	return postAjax('/api/system/storeAddAddr', params)
}
// 编辑机构地址
export const storeUpdAddr = params => {
	return postAjax('/api/system/storeEdit', params)
}
// 删除机构地址
export const storeDeleteAddr = params => {
	return postAjax('/api/system/storeDeleteAddr', params)
}
//  查询机构地址
export const getLineStoreAddress = params => {
	return postAjax('/api/system/getLineStoreAddress', params)
}
//  设置入离场
export const enterOutSiteSetting = params => {
	return postAjax('/api/system/enterOutSiteSetting', params)
}

//  消息设置
export const getMessageSettingList = params => {
	return postAjax('api/system/getMessageSettingList', params)
}

//  消息设置-关闭
export const offMessage = params => {
	return postAjax('api/system/offMessage', params)
}

//  消息设置-开启
export const onMessage = params => {
	return postAjax('api/system/onMessage', params)
}

//  消息设置-详情
export const messageSettingToEdit = params => {
	return getAjax('api/system/messageSettingToEdit', params)
}

//  消息设置-详情
export const messageSettingEdit = params => {
	return postAjax('api/system/messageSettingEdit', params)
}

