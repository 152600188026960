<!-- 课程高级设置 -->
<template>
	<el-collapse accordion @change="changeCollapse">
		<el-collapse-item>
			<template #title>
				<div class="ww100 h30 rx-sc">
					<div class="w100 h30 bd-main rx-cc color-main bdr4" style="font-weight:normal;"
						:style="{'margin-left':(recept.titleleft || 25)+'px'}">
						<div>高级设置</div>
						<DArrowLeft v-show="!pageData.show" class="w15 h15 ml5 trans"></DArrowLeft>
						<DArrowRight v-show="pageData.show" class="w15 h15 ml5 trans"></DArrowRight>
					</div>
					<div class="ml15 mr15" style="width:92%;height:1px;border-bottom:1px solid #ddd;"></div>
				</div>
			</template>
			<div class="ww100" style="background: #F7F7F7;">
				<div class="ww100 h40 rx-sc" style="border-bottom:1px solid #dddddd;">
					<div v-show="recept.classType != 11" class="w100 h30 mt10 ml15 rx-cc c_p" @click="()=>{pageData.tab = 1}"
						:class="[pageData.tab == 1 ? 'tab-on' :'tab-off']">预约设置</div>
					<div class="w100 h30 mt10 rx-cc c_p" @click="()=>{pageData.tab = 2}"
						:class="[pageData.tab == 2 ? 'tab-on' :'tab-off']">签到设置</div>
				</div>
				<div v-show="pageData.tab == 1 && recept.classType != 11" class="ww100 pb30">
					<div class="h30 rx-sc mt20">
						<div class="ml35">是否开放预约：</div>
						<el-radio-group v-model="pageData.seniorSet.ifOpenBook">
							<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
							<el-radio :label="2"><span class="color-666 fw400">否</span></el-radio>
						</el-radio-group>
						<el-tooltip class="box-item" effect="dark" content="开放预约后，会员才能预约此课程" placement="right-start">
							<img class="w15 h15 ml30" src="@/assets/wenhao.png">
						</el-tooltip>
					</div>
					<div v-show="pageData.seniorSet.ifOpenBook == 1">
						<div class="h30 rx-sc mt10">
							<div class="ml135 mr5">预约开始时间：上课前</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.bookingStartRule" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>小时</template>
                        	</el-input>
							<el-tooltip class="box-item" effect="dark" content="如填入1，则上课前1小时内，会员可进行预约"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
							<div class="ml135 mr5">预约截止时间：上课前</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.bookingEndRule" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>小时</template>
                        	</el-input>
							<el-tooltip class="box-item" effect="dark" content="如填入1，则上课前1小时内，截止预约"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div class="h30 rx-sc mt20" v-show="pageData.seniorSet.ifOpenBook == 1 && !recept.type">
							<div class="ml35">是否满员开课：</div>
							<el-radio-group v-model="pageData.seniorSet.ifFull">
								<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
								<el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
							</el-radio-group>
							<el-tooltip class="box-item" effect="dark" content="当会员人数满足设置的人数时，才会开课；不满足则课程取消"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div v-show="pageData.seniorSet.ifFull == 1" class="h30 rx-sc mt10">
							<div class="ml135 mr5">课节满足</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.fullNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>人</template>
                        	</el-input>
							<div>开课</div>
							<el-tooltip class="box-item" effect="dark" content="当课节内会员预约人数满足时，课节保留，正常开课"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div v-show="pageData.seniorSet.ifFull == 1" class="h30 rx-sc mt10">
							<div class="ml135 mr5">截止课前</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.fullEndRule" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>分钟</template>
                        	</el-input>
							<div>未满足开课要求，将自动关闭课节。</div>
							<el-tooltip class="box-item" effect="dark" content="关闭后，公众号会推送给会员课程取消通知；注意在设置中开启课程取消提醒；课程关闭后不能进行约课"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div class="h30 rx-sc mt20">
							<div class="ml35">预约可以取消：</div>
							<el-radio-group v-model="pageData.seniorSet.ifCancelBook">
								<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
								<el-radio :label="2"><span class="color-666 fw400">否</span></el-radio>
							</el-radio-group>
							<el-tooltip class="box-item" effect="dark" content="会员预约后，是否支持取消预约"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div v-show="pageData.seniorSet.ifCancelBook == 1" class="h30 rx-sc mt10">
							<div class="ml135 mr5">取消截止时间：上课前</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.cancelEndRule" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>小时</template>
                        	</el-input>
							<el-tooltip class="box-item" effect="dark" content="如果填入1，则上课前1小时以前可以取消预约，上课前1小时以后不能取消预约"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div v-show="pageData.seniorSet.ifCancelBook == 1 && pageData.seniorSet.ifFull == 1"
							class="h30 rx-sc mt10">
							<div class="ml135 mr5">满员后当课节内会员人数大于</div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.fullCancelNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>人</template>
                        	</el-input>
							<div>可取消预约</div>
							<el-tooltip class="box-item" effect="dark" content="课节内人数大于所设置的人数时，会员端才可取消预约"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div class="h30 rx-sc mt20" v-if="!recept.type" >
							<div class="ml35">是否支持候补：</div>
							<el-radio-group v-model="pageData.seniorSet.ifAfter">
								<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
								<el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
							</el-radio-group>
							<el-tooltip class="box-item" effect="dark" content="预约人数等于容纳人数时，给会员候补上课的机会"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
						<div v-show="pageData.seniorSet.ifAfter == 1" class="h30 rx-sc mt10">
							<div class="ml135 mr5">候补人数上限 </div>
							<el-input class="w115 t_a_c" v-model="pageData.seniorSet.afterNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                        	    <template #append>人</template>
                        	</el-input>
							<el-tooltip class="box-item" effect="dark" content="可以候补多少人"
								placement="right-start">
								<img class="w15 h15 ml30" src="@/assets/wenhao.png">
							</el-tooltip>
						</div>
					</div>
				</div>
				<div v-show="pageData.tab == 2" class="ww100 pb30">
					<div class="h30 rx-sc mt20">
						<div class="ml35">会员扫码签到：</div>
						<el-radio-group v-model="pageData.seniorSet.ifConfirmClass">
							<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
							<el-radio :label="2"><span class="color-666 fw400">否</span></el-radio>
						</el-radio-group>
						<el-tooltip class="box-item" effect="dark" content="是否允许会员主动扫二维码签到"
							placement="right-start">
							<img class="w15 h15 ml30" src="@/assets/wenhao.png">
						</el-tooltip>
					</div>
					<div class="h30 rx-sc mt20">
						<div class="ml35">系统自动签到：</div>
						<el-radio-group v-model="pageData.seniorSet.ifBook">
							<el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
							<el-radio :label="2"><span class="color-666 fw400">否</span></el-radio>
						</el-radio-group>
						<el-tooltip class="box-item" effect="dark" content="是否允许系统自动签到"
							placement="right-start">
							<img class="w15 h15 ml30" src="@/assets/wenhao.png">
						</el-tooltip>
					</div>
					<div class="h30 rx-sc mt10" v-show="pageData.seniorSet.ifBook == 1">
						<span class="ml135 mr5">自动签到时间：课前&nbsp;</span>
						<el-input class="w115 t_a_c"  style="vertical-align: middle;" v-model="pageData.seniorSet.autoConsumeRule" maxlength="4"  @blur="autoConsume" onkeyup="value=value.match(/^-?[0-9]\d*$/)||value.match(/-?/)">
                            <template #append>分钟</template>
                        </el-input>
						<el-tooltip class="box-item" effect="dark" content="到了这个时间，自动签到；只能输入5的倍数，输入范围-360~360"
							placement="right-start">
							<img class="w15 h15 ml30" src="@/assets/wenhao.png">
						</el-tooltip>
					</div>
				</div>
			</div>
		</el-collapse-item>
	</el-collapse>
</template>

<script setup>
import { reactive, onMounted, watch} from 'vue';
import { DArrowLeft, DArrowRight } from '@element-plus/icons-vue';
import { getBaseInfo } from '@/api/storeCenter.js';
import utils from '@/common/utils.js';
const recept = defineProps({
    titleleft : Number,
    info: Object,
	type: Number,
	classType: Number,
})
const pageData = reactive({
    tab: 1,
	show: '',
    seniorSet : {
        autoConsumeRule: 0
    }
});
watch(() => recept.classType, (newValue, oldValue) => {
    pageData.tab = newValue != 11 ? 1 : 2
  },
  { immediate: true }
)

/**
 * 是编辑还是新增
 */
function addOrEdit() {
    if (recept.info) {
        pageData.seniorSet = recept.info
    } else {
        getBaseInfo({ courseType: recept.type ? recept.type : 11 }).then((res) => {
            if (res.code == 0) {
                pageData.seniorSet.ifOpenBook = 1, // 是否开放预约1是2否
                pageData.seniorSet.bookingStartRule= res.data.orderStartTime,// 预约开始时间前多少小时
                pageData.seniorSet.bookingEndRule= res.data.orderEndTime,// 预约截止时间前多少小时
                pageData.seniorSet.ifFull= res.data.fullPeopleFlag ? res.data.fullPeopleFlag : 0,// 是否满员开课0否1是
                pageData.seniorSet.fullNum = res.data.fullPeopleNum,// 满足多少人开课
                pageData.seniorSet.fullEndRule= res.data.fullCancelTime,// 截止课前多少分钟关闭课节
                pageData.seniorSet.ifCancelBook= res.data.orderCancel ? res.data.orderCancel : 2 ,//是否可以取消预约1是2否
                pageData.seniorSet.cancelEndRule= res.data.orderCancelTime,// 取消预约截止规则上课前多少小时
                pageData.seniorSet.fullCancelNum= res.data.orderCancelPeople,// 满员取消预约人数限制
                pageData.seniorSet.ifAfter = res.data.afterFlag,// 0禁止候补，1：支持候补
                pageData.seniorSet.afterNum = res.data.afterNum,// 候补人数
                pageData.seniorSet.ifConfirmClass= res.data.stuSignFlag  ? 1 : 2,//会员扫码签到1否2是
                pageData.seniorSet.ifBook= res.data.autoSignFlag ? 1 : 2 // 自动签到1是2否
                pageData.seniorSet.autoConsumeRule= res.data.autoSignTime // 自动签到规则
            }
        })
    }
}
function autoConsume(e) {
	if (e.target.value > 360) {
        e.target.value = 360
    }
    if (e.target.value < -360) {
         e.target.value = -360
    }
    if (e.target.value % 5 == 0) {
        pageData.seniorSet.autoConsumeRule = e.target.value
    } else {
        ElMessage.warning('课前签到时间仅支持5的倍数')
        pageData.seniorSet.autoConsumeRule = ''
    }
}
function getSeniorSet(){
    return pageData.seniorSet
}
function changeCollapse(val) {
	pageData.show = val
}
onMounted(()=>{
    addOrEdit()
})
//暴露方法
defineExpose({
  getSeniorSet
})
</script>

<style type="text/css" scoped>
	.trans {
		transform: rotate(-90deg);
		-ms-transform: rotate(-90deg);
		/* IE 9 */
		-moz-transform: rotate(-90deg);
		/* Firefox */
		-webkit-transform: rotate(-90deg);
		/* Safari 和 Chrome */
		-o-transform: rotate(-90deg);
		/* Opera */
	}

	.tab-on {
		color: #00a3ff;
		border: 1px solid #dddddd;
		background-color: #f5f5f5;
		border-bottom: 1px solid #F7F7F7;
		margin-bottom: -1px;
		position: relative;
	}

	.tab-off {
		color: #666666;
		border: none;
	}

	.el-collapse {
		border-top: none !important;
		border-bottom: none !important;
	}

	::v-deep .el-collapse-item__header, ::v-deep .el-collapse-item__wrap {
		border-bottom: none
	}
	::v-deep .el-collapse-item__content{
		padding-bottom: 10px;
		padding-left:  23px;
		padding-right: 15px;
	}
	::v-deep .el-collapse-item__arrow {
		display: none;
	}
</style>
