<template>
	<div class="store_info">
		<img class="ww100" :src="storeData.banners.length > 1 ? storeData.banners[1]:require('@/assets/applet/banner.png')" alt="">
		<div class="">
			<div class="rx-bs mt10 mb10">
				<div class="rx-cc info_user">
					<img class="w50 h50 mr5" :src="storeData.logo || require('@/assets/applet/photo.png')" alt="">
					<div>
						<h3 class="fwb mb5 ft12" :class="{'color-fff':themeStore.bgColor == 2}">{{storeData.storeName || 'DTO Dance Studio' }} </h3>
						<p>
							<span class="pl5 pr5 ft12" :style="{color:themeStore.list[themeStore.active].textColor,backgroundColor:themeStore.list[themeStore.active].color+'33'}">营业时间：{{storeData.time || '09:00 ~ 23:00'}}</span>
						</p>
					</div>
				</div>
				<div class="mt5 checkIcon">
					<el-icon :style="{color:themeStore.list[themeStore.active].color}"><Sort /></el-icon>
				</div>
			</div>
			<div class="rx-bs info_contact " :style="{backgroundColor:themeStore.bgColor == 2 ? 'rgba(255,255,255,.1)' : ''}">
				<div class="ww50 rx-sc">
					<img class="w20 mr5" src="@/assets/applet/phone.png" alt="">
					<div :class="{'color-fff':themeStore.bgColor == 2}">
						<p class="mb5 ft12" :class="{'color-fff':themeStore.bgColor == 2}">联系电话</p>
						<p class="ft12">{{storeData.phone || ''}}</p>
					</div>
				</div>
				<div class="ww50 rx-sc">
					<img class="w20 mr5" src="@/assets/applet/area.png" alt="">
					<div>
						<p class="info_area ft12" :class="{'color-fff':themeStore.bgColor == 2}">{{storeData.city.length > 0 || storeData.address ? storeData.city.join('')+storeData.address:'所在地址'}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	import {Sort} from '@element-plus/icons-vue'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeStore = store.state.appletFormData[4].list[0];
</script>

<style scoped lang="stylus">
	.store_info{
		font-size 12px !important;
		.checkIcon{
			transform rotate(90deg);
		}
		.info_user{
			img{
				border-radius 100px;
			}
			span{
				display inline-block;
				background-color #FEF3CC;
				color #CEAB32;
				font-size 12px;
			}
		}
		
		.info_contact{
			background-color #FAFAFC;
			border-radius 4px;
			padding 10px;
			.info_area{
				line-height 18px;
				overflow:hidden;
				text-overflow:ellipsis;
				display:-webkit-box;
				-webkit-box-orient:vertical;
				-webkit-line-clamp:2; 
			}
		}
	}
</style>
