<template>
    <el-dialog 
            width="1000px"
            title="签到详情"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            @opened="opened"
        >
            <template #default>
                <div class="pt25 pb25 pl40 pr40">
                    <!-- <formQuery ref="queryOptsRef" :queryOpts="signInQueryOpts" @query="searchGetList" /> -->
                    <el-table class="ww100" ref="subscribeTable" :data="pageData.signInList" stripe v-loading="pageData.loading">
                        <el-table-column prop="classTimeStr" fixed label="上课时间" width="230"></el-table-column>
                        <el-table-column prop="courseName" label="课程名称" width="100" show-overflow-tooltip />
                        <el-table-column label="课节类型" width="100">
                            <template #default="scope">
                                <span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30',border:'1px solid '+courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="courseCustomName" label="课节主题" width="100" show-overflow-tooltip />
                        <el-table-column prop="courseTeacherName" label="上课教练" width="100" show-overflow-tooltip />
                        <el-table-column prop="realName" label="上课会员" width="100" show-overflow-tooltip />
                        <el-table-column prop="merFee" label="签到状态" width="100">
                            <template #default="scope">
                                <el-button size="small" v-if="scope.row.alreadyCome == 0 && scope.row.ifLeave == 0" type="success">未签到</el-button>
						        <el-button size="small" v-if="scope.row.alreadyCome == 1 && scope.row.ifLeave == 0" type="primary">已签到</el-button>
                                <el-button size="small" v-if="scope.row.alreadyCome == 0 && scope.row.ifLeave == 1" type="danger">已请假</el-button>
					         </template>
                        </el-table-column>
                        <el-table-column prop="productName" label="扣费支付方式" width="110" show-overflow-tooltip />
                        <el-table-column label="本次扣除" width="100">
			            	<template #default="scope">
			            		<div>{{ scope.row.cardType  == 3 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次'  }}</div> 
			            	</template>
			            </el-table-column>
                        <el-table-column prop="consumeMoney" label="消课金额" width="100"/>
                        <template #empty>
                            <el-empty description="暂无数据"></el-empty>
                        </template>
                    </el-table>
                    <div class="mt10 rx-bc" v-show="pageData.signInList.length >= 0">
                        <div>
                            <span class="mr20">已签到人数：<span class="color-red mr5">{{ pageData.remark.confirmClassCourseAll }}</span>人</span>
                            <span>消课金额总计：<span class="color-red mr5">{{ pageData.remark.consumeMoneyAll || 0 }}</span>元</span>
                        </div>
                        <el-pagination
                            v-model:currentPage="pageData.signInForm.page"
                            :page-sizes="[15,20,30,50,100]"
                            :page-size="pageData.signInForm.limit"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageData.signInForm.totalCount"
                            @size-change="pageSizeChange"
                            @current-change="()=>{getList()}"
                        >
                        </el-pagination>
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" @click="close">确定</el-button>
                </div>
            </template>
        </el-dialog>
</template>

<script setup>
    import { ref, reactive } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js'
    import { courseSignInfo } from '@/api/consume.js'
    const emit = defineEmits(['close']);
    // const queryOptsRef = ref()
    const propData = defineProps({
        sid:String,
        enId: String,
        type: String
    })
    const pageData = reactive({
        loading: false,
        signInDetail: false, // 签到详情弹框
        signInForm: {
            page : 1,
            limit : 15,
            totalCount : 0
        },
        signInList:[], // 签到列表数据
        remark: {}
    })
    //课程类型
	const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
	}
    /**
     * 关闭弹框
     */
    const close = () =>{
        emit('close');
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.signInForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.signInForm.page = 1;
       getList()
	}
    /**
     * 获取列表
     */
    const getList=() => {
		let params = {}
		params.limit = pageData.signInForm.limit
		params.page = pageData.signInForm.page
        params.encryptionTeacherId = propData.sid
        params.encryptionId = propData.enId ? propData.enId : ''
        pageData.loading = true
        params.courseType = propData.type == 2 ? 13 : ''
        courseSignInfo(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.signInList = res.data
                pageData.signInForm.totalCount = res.count
                pageData.remark = res.remark
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 打开弹框后的回调
     */
    const opened=() => {
        getList()
    }
</script>

<style type="text/css" scoped>
    .courseType{
			display: inline-block;
			background-color: #D5F0FB;
			border: 1px solid #6CD5FD;
			color: #6CD5FD;
			border-radius: 2px;
		}
		.box-FA7D29{
			border: 1px solid #FA7D29;
			color: #FA7D29;
			background-color: #FEE5D4;
		}
		.box-9476FF{
			border: 1px solid #9476FF;
			color: #9476FF;
			background-color: #EAE4FF;
		}
		.box-3FC9FF{
			border: 1px solid #3FC9FF;
			color: #3FC9FF;
			background-color: #D9F4FF;
		}
        .box-009688{
	    		border: 1px solid #009688;
	    		color: #FFF;
	    		background-color: #009688;
	    	}
        .box-1E9FFF{
			border: 1px solid #1E9FFF;
			color: #FFF;
			background-color: #1E9FFF;
		}
</style>