<template>
	<div class="course_card">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb16">
			<el-button type="primary" @click="updateRow('')" v-if="utils.getMenuRole(3160300)">新增体验卡</el-button>
			<el-button type="danger" plain @click="deletSomeOne" v-if="utils.getMenuRole(3160301)">批量删除</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" :selectable="(row)=>{return row.sid != 0}" />
			    <el-table-column prop="productName" label="体验卡名称" show-overflow-tooltip>
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3160304)" @click="updateRow(scope.row.encryptionId)">{{ scope.row.productName }}</a>
						<span v-else>{{scope.row.productName}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productTyp" label="体验卡类型">
					<template #default="scope">
						<span>{{scope.row.productTyp === 1 ? '次卡' : '期限卡'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productNum" label="可消费次数">
					<template #default="scope">
						{{ scope.row.productTyp === 1 ? scope.row.productNum + '次' : scope.row.productNum ? scope.row.productNum + '次' : '不限次' }}	
					</template>
				</el-table-column>
          <el-table-column prop="productCycle" label="可消费时限" >
            <template #default="scope">
              <span>
                {{
                  (scope.row.productCycle || '') + (scope.row.productCycle ? scope.row.ifMonth === 1 ? '天' : scope.row.ifMonth === 2 ? '月' : '年' : '不限时')
                }}
              </span>
            </template>
          </el-table-column>
			    <el-table-column prop="experiencePrice" label="售价(元)" />
			    <el-table-column prop="consumeStoreName" label="可体验门店" />
			    <el-table-column prop="name" label="体验卡状态" v-if="utils.getMenuRole(3160302)">
			    	<template #default="scope">
			    		<div @click="updateShelfState(scope.row)">
			    			<!-- el-switch 初始值为0默认回走一次onchange事件 所以不用 -->
			    			<el-switch v-model="scope.row.shelfState" inline-prompt :active-value="1" :inactive-value="0" ></el-switch>
			    		</div>
			    	</template>
			    </el-table-column>
			    <el-table-column prop="storeName" label="发布渠道" />
				<el-table-column label="操作" width="160">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3160303)" type="text" @click="bindCourseClick(scope.row)">绑定课程</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="lookDetail(scope.row.encryptionId)">查看详情</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
   
    <!-- 绑定课程-->
    <el-dialog
        v-model="pageData.bindCourseShow"
        title="绑定课程"
        width="700px"
        :before-close="()=>{pageData.bindCourseShow = false}"
        custom-class="popwechat"
    >
      <div class="pl20 pr20 pt20">
        <div class="rx-sc mb20">
          <span class="mr16">课程名称</span>
          <div class="w200 mr16">
          	<el-input v-model="pageData.bindCourseForm.courseName" type="text" placeholder="输入关键字"></el-input>
          </div>
          <span class="mr16">发布渠道</span>
          <div class="w200 mr16">
			  <el-select v-model="pageData.bindCourseForm.sid" placeholder="请选择">
				  <el-option label="全部发布渠道" value=""></el-option>
				  <el-option v-for="item in pageData.queryOpts.fields[3].options" :key="item.value" :label="item.label" :value="item.value">
			  	</el-option>
			  </el-select>
          </div>
          <el-button type="primary" @click="searchBindCourseList">查询</el-button>
        </div>
        <div class="mb16" v-for="(item,index) in pageData.bindCourseList" :key="index">
          <div class="rx-bc mb16">
            <div class="rx-sc">
              <b class="mr20 color-333">
								<span class="d_i_b w5 h5 bg-000 bdr4 v_a_m mr10"></span> 
								<span class="v_a_m">支持{{item.title}}</span>
							</b>
              <span>已选择<span class="ml5 mr5 color-red">{{item.check.length}}</span>个{{item.title}}</span>
            </div>
            <el-button type="primary" :disabled="pageData.cardType == 3" size="small" @click="deductionClick(index)">批量设置扣除</el-button>
          </div>
          <div class="bind_table" :style="{height:item.open ? '' : '40px'}">
            <span class="bind_table_show rx-cc c_p" @click="item.open = !item.open">{{item.open ? '收起' : '展开'}}<el-icon class="el-icon--right" :style="{transform: 'rotate('+(item.open ? '-90deg':'90deg')+')'}"><DArrowRight /></el-icon></span>
            <el-table :data="item.table" stripe style="width: 100%;" max-height="300">
              <el-table-column width="55" >
                <template #header>
                  <el-checkbox v-model="item.checked" size="large" @change="tableCheck(index,'all')"></el-checkbox>
                </template>
                <template #default="scope">
                  <el-checkbox v-model="scope.row.checked" size="large" @change="tableCheck(index,scope.$index)"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="courseName" :label="'全选&nbsp;&nbsp;&nbsp;总共'+item.table.length+'个'+item.title" width="180" />
              <el-table-column prop="storeName" show-overflow-tooltip />
              <el-table-column prop="num" width="250">
                <template #default="scope">
                  <div class="rx-sc">
                    <span>每次上课消费扣除</span>
                    <input class="input w50 mr5 ml5 t_a_c" :class="pageData.cardType == 3 ? 'disInput' : ''" :disabled="pageData.cardType == 3" type="text" v-model="scope.row.consumeNum"/>
                    <span>次/节</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="pageData.bindCourseShow = false">取消</el-button>
          <el-button type="primary" @click="bindCourseSave">确定</el-button>
        </div>
      </template>
    </el-dialog>
    <el-dialog
        v-model="pageData.deductionShow"
        title="批量扣除次数"
        width="400px"
        :before-close="()=>{pageData.deductionShow = false}"
        custom-class="popwechat"
    >
      <div class="pl20 pt50 pb50 pr20">
        <div class="rx-cc">
          <span class="">每次上课消费扣除数：</span>
          <div class="w200">
            <el-input type="text" placeholder="请输入" v-model="pageData.deductionNum">
              <template #append>次/节</template>
            </el-input>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="pageData.deductionShow = false">取消</el-button>
          <el-button type="primary" @click="deductionSave">确定</el-button>
        </div>
      </template>
    </el-dialog>
	<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted,onActivated} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {useStore} from 'vuex'
	import {Refresh,DArrowRight,Connection,Close} from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
    import {courseNexus, list4Store, storeShelfState, updateCourseNexus, updateShelf,productDelete} from '@/api/cards.js';
    import {ElMessage} from "element-plus";
    import {courseList} from "@/api/course";
    import Confirm from '@/components/Confirm.vue'
    import formQuery from '@/components/branch-store/formQuery.vue';
    const store = useStore();
	const router = useRouter();
	const pageData = reactive({
		cardType: '',
		tabNum:'0',
		storeList:[],
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			type:4,
			productName:'',
			productTyp:'',
			shelfState:'1',
			storeId:'',
			sortType:'1'
		},
		isSellShow:false,//可售卖门店
		isSellTableData:[],
		isSellPage:1,
		bindCourseShow:false, //绑定课程
		bindCourseList:[
			{
				title:'团课',
				check:[],
				open:true,
				table:[{name:1,num:''}]
			},
			{
				title:'私教课',
				check:[],
				open:false,
				table:[{name:1,num:''}]
			},
			// {
			// 	title:'班课',
			// 	check:[],
			// 	open:false,
			// 	table:[{name:1,num:''}]
			// },
		],
    bindCourseId:0,
    deductionShow:false,
    deductionIndex:0,
    deductionNum:0,
	courseType: utils.getDict('courseType',{label : '全部课程类型' ,value : ''}),
	bindCourseForm:{
		sid:'',
		courseName:'',
		age:1,
		limit:99999,
	},
	confirmTitle:'',
	confirmText:'',
	deleCheckData:[],
	queryOpts:{
		fields:[{
			type : 'input',
			fieldName : 'productName',
			placehold : '请输入课程卡名称'
		},{
			type : 'select',
			fieldName : 'productTyp',
			placehold : '选择卡类型',
			options : utils.getDict('productType',{label : '全部类型' ,value : ''})
		},{
			type : 'select',
			fieldName : 'shelfState',
			placehold : '选择卡状态',
			options : utils.getDict('shelfState',{label : '全部状态' ,value : ''}),
			value:'1'
		},{
			type : 'select',
			fieldName : 'storeId',
			placehold : '全部发布渠道',
			options : [],
			value:''
		},{
			type : 'select',
			fieldName : 'sortType',
			placehold : '排序方式',
			options : utils.getDict('sortType'),
			value:'1'
		}]
	}
	})
	utils.getDynaDict('storeList',{label : '全部发布渠道' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[3].options.push(res[i]);
	    }
	});
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		list4Store(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
        pageData.totalCount = res.count;
			}
		})
	}
	//新增或编辑
	function updateRow(id){
		router.push({path:'/branch/experienceCard',query:{id}});
	}
	//查看详情
	function lookDetail(id){
		router.push({path:'/branch/cardDetail',query:{id}});
	}
  
  //绑定课程
  var allCourseList = [];
  function bindCourseClick(row){
  	pageData.bindCourseId = row.encryptionId
	pageData.cardType = row.productTyp
  	pageData.bindCourseShow = true;
  	pageData.bindCourseForm.sid = '';
  	pageData.bindCourseForm.courseName = '';
  	allCourseList = [];
  	courseNexus({
  		page:1,
  		limit:99999,
  		encryptionId: row.encryptionId
  	}).then((res)=>{
  		var bindList = res.data;
  		getcourseList(bindList,1) //首次加载
  	})
  }
  
  
  function getcourseList(bindList,type){
  	pageData.bindCourseList.map((n,i)=>{ //初始化数据
  		n.table = [];
  		n.check = [];
  		n.checked = false;
  		n.open = i == 0 ? true : false;
  	})
  	courseList(
  		pageData.bindCourseForm
  	).then((res2)=>{
  		if(res2.code == 0){
  			res2.data.map((n)=>{
  				var arr = bindList.filter(z=>z.courseNameId == n.courseNameId);
  				if(arr.length > 0){ //已被绑定
  					n.consumeNum = arr[0].consumeNum;
  					n.checked = true;
  					if(n.courseType == 11){
  						pageData.bindCourseList[0].check.push(n.courseNameId);
  					}else if(n.courseType == 12 || courseType == 13 || courseType == 14){
  						pageData.bindCourseList[1].check.push(n.courseNameId);
  					}else if(n.courseType == 15){
  						pageData.bindCourseList[2].check.push(n.courseNameId);
  					}
  				}else{
  					n.consumeNum = 0;
  					n.checked = false;
  				}
  				if(n.courseType == 11){
  					pageData.bindCourseList[0].table.push(n);
  				}else if(n.courseType == 12 || courseType == 13 || courseType == 14){
  					pageData.bindCourseList[1].table.push(n);
  				}else if(n.courseType == 15){
  					pageData.bindCourseList[2].table.push(n);
  				}
  				if(type){
  					allCourseList.push(n); //首次加载缓存所有数据
  				}
  			})
  			pageData.bindCourseList.map((n,i)=>{ //初始化数据
  				n.checked =	n.check.length > 0 ? n.table.length == n.check.length : false;
  			})
  		}
  	})
  }
  //查询课程列表
  function searchBindCourseList(){
  	if(pageData.bindCourseForm.sid || pageData.bindCourseForm.courseName){ //有搜索项才替换
  		pageData.bindCourseList.map((n,i)=>{ //查询前先把已修改的数据同步到总数据里
  			n.table.map((z)=>{
  				allCourseList.map((x,y)=>{
  					if(z.courseNameId == x.courseNameId){
  						allCourseList[y] = z; //替换
  					}
  				})
  			})
  		})
  	}
  	getcourseList(allCourseList);
  }
  function bindCourseSave(){
  	var  courseNameIds = [],courseNameConsumeNums = [];
  	pageData.bindCourseList.map((n,i)=>{ //查询前先把已修改的数据同步到总数据里
  		n.table.map((z)=>{
  			allCourseList.map((x,y)=>{
  				if(z.courseNameId == x.courseNameId){
  					allCourseList[y] = z; //替换
  				}
  			})
  		})
  	})
  	allCourseList.map((n,i)=>{
  		if(n.checked){
  			courseNameIds.push(n.courseNameId);
  			courseNameConsumeNums.push(n.consumeNum);
  		}
  	})
  	if(courseNameIds.length == 0){
  		ElMessage({
  			type: 'warning',
  			message: '请选择绑定的课程',
  		})
  		return false;
  	}
  	updateCourseNexus({
  		encryptionId:pageData.bindCourseId,
  		courseNameIds:courseNameIds.join(','),
  		courseNameConsumeNums:courseNameConsumeNums.join(','),
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  				type: 'success',
  				message: '绑定成功',
  			})
  			pageData.bindCourseShow = false;
  		}
  	})
  }
  //批量设置扣除数
  function deductionClick(index){
    pageData.deductionIndex = index;
    pageData.deductionShow = true;
    pageData.deductionNum = 0;
  }
  function deductionSave(){
    if(!pageData.deductionNum){
      ElMessage({
        type: 'warning',
        message: '请输入扣课次数',
      })
      return false;
    }
    var {table} = pageData.bindCourseList[pageData.deductionIndex];
    table.map((n)=>{
		if (n.checked) {
			n.consumeNum = pageData.deductionNum
		}
	})
    pageData.deductionShow = false;
  }
  //全选
  function tableCheck(index,type){
    var {table,checked,check} = pageData.bindCourseList[index];
    table.map((n)=>{
      if(type == 'all'){
        n.checked = checked ? true : false;
        if(checked && check.indexOf(n.courseNameId) == -1){
          check.push(n.courseNameId)
        }else if(!checked){
          check.length == 0;
        }
      }else{ //单选
        if(table[index].checked){ //选中
          var arr = table.filter(z=>z.checked);
          pageData.bindCourseList[index].checked = arr.length == table.length;
        }else{
          pageData.bindCourseList[index].checked = false;
        }

      }
    })

  }
  //批量删除员工
  const confirmRef = ref();
  function selectionChange(data){
  	pageData.deleCheckData = data.map(n=>n.encryptionId);
  }
  function deletSomeOne(){
  	if(pageData.deleCheckData.length == 0){
  		ElMessage({
  			type: 'warning',
  			message: '请选择要删除的卡项',
  		})
  		return false;
  	}
  	pageData.confirmTitle = '确定要删除选中的数据吗？';
  	confirmRef.value.show();
  }
  function confirmSave(){
  	productDelete({
  		encryptionId:pageData.deleCheckData.join(',')
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  				type: 'success',
  				message: '删除成功',
  			})
  			searchTable();
  		}
  	})
  	
  }
  //上下架操作
  function updateShelfState(row){
  	updateShelf({
  		productIds:row.encryptionId,
  		shelfState:row.shelfState ? 1 : 0
  	}).then((res)=>{
  		if(res.code == 0){
  			ElMessage({
  			  type: 'success',
  			  message: row.shelfState ?'上架成功':'下架成功',
  			})
  			searchTable();
  		}
  	})
  }
  
  // 初始化页面
  onActivated(()=>{
      getList();
  })
  
  // 页面缓存
  onBeforeRouteLeave((to, from)=>{
      if (to.path === '/branch/cardDetail' || to.path === '/branch/experienceCard') {
          store.commit('SetKeep',{keepLiveRoute : ['BranchCards']});
      } else {
          store.commit('SetKeep',{keepLiveRoute : []});
      }
  })
  
</script>

<style scoped lang="stylus">
	.course_card{
		.bind_table{
			position relative;
			overflow hidden;
			transition 0.5s;
			.bind_table_show{
				position absolute;
				right 10px;
				top 9px;
				z-index 100;
				color #409EFF;
				
			}
		}
		.bind_table_num{
			border none;
			border-bottom 1px solid #999;
			background-color initial;
		}
    .stateIcon{
      position relative;
    }
    .stateIcon::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #FBE3E3;
      background-color #FE8585;
      border-radius 100%;
    }
    .stateIcon.blue::after{
      content '';
      position absolute;
      left 0;
      top 50%;
      transform translateY(-50%);
      width 8px;
      height 8px;
      border 2px solid #DAF7F0;
      background-color #44D7B6;
      border-radius 100%;
    }
	}
	.input{
		border-top-style:none;
		border-right-style:none;
		border-left-style:none;
		border-bottom: 1px solid #abadb3;
		background:rgba(249,247,248,0);
	}
	.disInput {
		background:#abadb3;
	}
</style>
