<template>
	<workArea :barlist="pageData.barlist">
		<div class="card_manage bg-fff pl16 pt10 pr16">
			<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
				<el-tab-pane label="课程卡" name="0" v-if="utils.getMenuRole(31600)"></el-tab-pane>
				<el-tab-pane label="入场卡" name="1" v-if="utils.getMenuRole(31601)"></el-tab-pane>
				<el-tab-pane label="储值卡" name="2" v-if="utils.getMenuRole(31602)"></el-tab-pane>
				<el-tab-pane label="体验卡" name="3" v-if="utils.getMenuRole(31603)"></el-tab-pane>
			</el-tabs>
			<component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
		</div>
	</workArea>
</template>
<script>
  export default {
    name: 'BranchCards'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount } from 'vue';
	import workArea from '@/components/branch-store/workArea.vue';
	import CourseCard from '@/views/branch-store/work/cards/CourseCard.vue' //课程卡
	import EntranceCard from '@/views/branch-store/work/cards/EntranceCard.vue' //入场卡
	import StoreCard from '@/views/branch-store/work/cards/StoreCard.vue' //储值卡
	import ExperienceCard from '@/views/branch-store/work/cards/ExperienceCard.vue' //体验卡
	import utils from '@/common/utils.js';
	const components = [CourseCard,EntranceCard,StoreCard,ExperienceCard];
	const pageData = reactive({
		tabNum: 0,
		barlist:[{ name : '首页' },{ name : '教务中心' },{ name : '卡项管理' }]// 面包屑
	})
	var menuId = [31600,31601,31602,31603]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	} 
	function handleClick(tab) {
       localStorage.setItem('cards', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('cards') != null) {
           pageData.tabNum = localStorage.getItem('cards')
	    }
	})
	onBeforeUnmount(() => {
		localStorage.removeItem('cards')
	})
</script>

<style scoped lang="stylus">
	
</style>
