<template>
	<workArea :barlist="pageData.barlist">
	<div class="add_course bg-fff pt10 pl10 pr10 pb10" v-if="pageData">
		<CommonTitle text="基本信息" />
		<div class="rx-sc mb12">
			<div class="rx-sc mr40">
				<span class="w110 t_a_r"><span class="color-red">*</span>课程名称：</span>
				<div class="w240">
					<!--  :disabled="courseNameId" -->
					<el-input type="text" placeholder="请输入(限20字)" maxlength="20" v-model="pageData.formData.courseName">
					</el-input>
				</div>
			</div>
			<div class="rx-sc">
				<span class="w110 t_a_r"><span class="color-red">*</span>课程类型：</span>
				<div class="w240">
					<el-select class="w240" :disabled="courseNameId" fit-input-width v-model="pageData.formData.courseType" placeholder="请选择" @change="courseTypeChange">
						<el-option label="团体课" :value="11"></el-option>
						<el-option label="私教课" :value="12"></el-option>
						<el-option label="小班课" :value="13"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="rx-sc mb12">
			<div class="rx-sc mr40">
				<span class="w110 t_a_r"><span class="color-red">*</span>单课时长：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(5的倍数)" v-model="pageData.formData.courseTime"
						@blur="courseTimeBlur">
						<template #append>分钟</template>
					</el-input>
				</div>
			</div>
			<div class="rx-ss pt15">
				<span class="w110 t_a_r"><span class="color-red">*</span>课程颜色：</span>
				<div class="w240">
					<template v-for="(item,index) in pageData.courseColor" :key="index">
						<div class="course_color" :style="{border:'1px solid'+' '+item}"
							@click="pageData.courseColorCheck = index">
							<span :style="{backgroundColor:pageData.courseColorCheck == index ? item : ''}"></span>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12" v-show="pageData.formData.courseType == 12">
			<div class="rx-sc mr40">
				<span class="w110 t_a_r"><span class="color-red">*</span>私教模式：</span>
				<div class="w240">
					<el-radio-group v-model="pageData.accommodatePerson">
						<el-radio label="1">单人私教</el-radio>
						<el-radio label="2">多人私教</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="rx-sc" v-show="pageData.accommodatePerson == 2">
				<span class="w110 t_a_r"><span class="color-red">*</span>最大上课人数：</span>
				<div class="w240">
					<el-select class="w240" fit-input-width v-model="pageData.formData.accommodatePerson"
						placeholder="请选择">
						<el-option label="2" value="2"></el-option>
						<el-option label="3" value="3"></el-option>
						<el-option label="4" value="4"></el-option>
						<el-option label="5" value="5"></el-option>
						<el-option label="6" value="6"></el-option>
						<el-option label="7" value="7"></el-option>
					</el-select>
				</div>
			</div>
		</div>
		<div class="rx-sc mb12" v-show="pageData.formData.courseType == 13">
			<div class="rx-sc mr40">
				<span class="w110 t_a_r">适用范围：</span>
				<div class="w240">
					<el-input type="text" placeholder="请输入(限20字)" maxlength="20" v-model="pageData.formData.applyAge">
					</el-input>
				</div>
			</div>
		</div>
		<div class="rx-ss">
			<span class="w110 t_a_r"><span class="color-red" v-if="pageData.formData.courseType == 12">*</span>授课教练：</span>
			<div class="w630">
				<div class="add_coach_title pt10 pb10">
					<span class="color-999 fwb pl10">教练</span>
					<span class="color-999 fwb pl10">工作性质</span>
					<span class="color-999 fwb pl10">操作</span>
				</div>
				<div class="coach_list">
					<div class="add_coach_title pt10 pb10" v-for="(item, index) in pageData.checkCoachList" :key="index">
						<span class="pl10">{{item.realName}}</span>
						<span class="pl10">{{item.ifFullTime == 1 ? '全职' : item.ifFullTime == 2 ? '兼职':'未知'}}</span>
						<span class="pl10">
							<a href="javascript:;" class="t_d_u color-red "
								@click="pageData.checkCoachList.splice(index,1)">删除</a>
						</span>
					</div>
				</div>
				<div class="rx-cc pt10 pb10 color-409EFF c_p bg-fff tool_btn" @click="addCoach" style="border-bottom: 1px solid #eee">
					<el-icon>
						<Plus />
					</el-icon>
					选择教练
				</div>
			</div>
		</div>
		<CommonTitle text="售卖信息" />
		<div class="rx-ss mb12">
			<div class="rx-sc mr40">
				<span class="w110 rx-ec">
					<span class="color-red">*</span>
					<span>售卖方式</span>
					<span style="padding-top: 4px;" v-show="pageData.formData.courseType != 13">
						<el-tooltip
						    class="box-item"
						    effect="dark"
						    content="可上课门店均可售卖"
						    placement="right-start"
						  >
						  	<img class="w15 h15 ml5" src="@/assets/wenhao.png" >
						</el-tooltip> 
					</span>
					<span>：</span>
				</span>
				<div class="w240">
					<el-radio-group v-model="pageData.formData.sellType">
						<el-radio :label="1" v-show="pageData.formData.courseType != 13">线下售卖</el-radio>
						<el-radio :label="0">不售卖</el-radio>
					</el-radio-group>
					<span style="padding-top: 4px;" v-show="pageData.formData.courseType == 13">
						<el-tooltip
						    class="box-item"
						    effect="dark"
						    content="小班课类型的课程仅用于班课排课使用，会员报名购买班级即可"
						    placement="right-start"
						  >
						  	<img class="w15 h15 ml5" src="@/assets/wenhao.png" >
						</el-tooltip> 
					</span>
				</div>
				
			</div>
			<!-- <div class="rx-sc" v-show="pageData.formData.sellType == 1">
				<span class="w110 t_a_r"><span class="color-red">*</span>定价方式：</span>
				<div class="w240">
					<el-select class="w240" fit-input-width v-model="pageData.formData.pricingMethodType"
						placeholder="请选择">
						<el-option label="统一定价" :value="1"></el-option>
						<el-option label="门店自主定价" :value="2"></el-option>
					</el-select>
				</div>
			</div> -->
		</div>
		<div class="rx-sc mb12" v-show="pageData.formData.sellType == 1">
			<div class="rx-sc mr40">
				<span class="w110 t_a_r"><span class="color-red">*</span>课程单价：</span>
				<div class="w240">
					<el-input type="text" maxlength="6" placeholder="请输入(保留2为小数)" v-model="pageData.formData.coursePrice"
						@blur="coursePriceBlur">
						<template #append>元</template>
					</el-input>
				</div>
			</div>
			<div class="rx-sc" v-show="pageData.formData.courseType != 15">
				<span class="w110 rx-es">
					<span>单节预约</span>
					<span style="padding-top: 4px;">
						<el-tooltip
						    class="box-item"
						    effect="dark"
						    content="开启单节预约后，会员即可使用储值卡单次支付预约本课程，或当会员名下无可用于预约本课程的资产时，可支持在线微信支付预约"
						    placement="right-start"
						  >
						  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
						  </el-tooltip> 
					</span>
					<span>：</span>
				</span>
				<div class="w240 rx-sc pr15 h30">
					<el-checkbox v-model="pageData.orderType" />
					<span class="ml5">允许线上单节预约</span>
					<input class="course_money w50 mr5 ml5" maxlength="6" type="text" v-model="pageData.formData.orderPrice" />
					元
				</div>
			</div>
		</div>
		<CommonTitle text="扣课规则" v-show="pageData.formData.courseType == 15" />
		<div class="rx-cc mb12" v-show="pageData.formData.courseType == 15">
			<span class="w110 t_a_r">到课扣课：</span>
			<el-switch v-model="pageData.formData.signIfDeduct" inline-prompt active-text="是" inactive-text="否">
			</el-switch>
			<span class="w110 t_a_r">请假扣课：</span>
			<el-switch v-model="pageData.formData.leaveIfDeduct" inline-prompt active-text="是" inactive-text="否">
			</el-switch>
			<span class="w110 t_a_r">缺勤扣课：</span>
			<el-switch v-model="pageData.formData.absenceIfDeduct" inline-prompt active-text="是" inactive-text="否">
			</el-switch>
		</div>
		<CommonTitle text="其他信息" />
		<div class="rx-ss mb12">
			<span class="w110 t_a_r pt10"><span class="color-red">*</span>课程封面：</span>
			<div>
				<el-radio-group v-model="pageData.coverCheck">
					<el-radio label="0">风格一</el-radio>
					<el-radio label="1">风格二</el-radio>
					<el-radio label="2">自定义</el-radio>
				</el-radio-group>
				<div class="rx-ss">
					<div class="cover_img">
						<div v-if="pageData.coverCheck == 2" class="cover_dele">
							<unicon name="trash-alt" fill="#fff" hover-fill="#fff" @click="deleCover"></unicon>
						</div>
						<img v-show="pageData.cover[pageData.coverCheck]" class="w120 h80"
							:src="pageData.cover[pageData.coverCheck]" alt="" />
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="w120 t_a_c color-999 h80 rx-cc mr10"
						style="border: 1px solid #ccc;">
						<div>
							<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
								<img class="w25" src="@/assets/addBank.png" alt="">
								<p>上传图片</p>
							</CommonUpload>
						</div>
					</div>
					<div v-show="!pageData.cover[pageData.coverCheck]" class="color-999 w200">
						建议大小为750*500px的jpg、png、bmp格式不超过2M的图片。
					</div>
				</div>
			</div>
		</div>
		<div class="rx-sc mb12">
			<span class="w110 t_a_r"><span class="color-red">*</span>训练目的：</span>
			<div class="w240 mr16">
				<el-select multiple collapse-tags v-model="pageData.formData.trainPurposeIds" multiple-limit="2" placeholder="请选择">
					<el-option v-for="item in pageData.objectiveList" :key="item.id" :label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<el-button type="primary" @click="pageData.addUpdateObjectShow = true">新增</el-button>
		</div>
		<div class="rx-ss mb12">
			<span class="w110 t_a_r">课程介绍：</span>
			<div class="flex1" ref="editor"></div>
		</div>
		<div class="pl100">
			<el-button @click="addCourseCloce">取消</el-button>
			<el-button type="primary" @click="addCourseSave">保存</el-button>
		</div>
		<!-- 新增-编辑弹窗 -->
		<el-dialog v-model="pageData.addUpdateObjectShow" title="新增目的" width="350px"
			:before-close="()=>{pageData.addUpdateObjectShow = false}" custom-class="popwechat">
			<div class="pt30 pb30">
				<div class="query_item mb16 rx-bc">
					<div class="query_tip rx-ec w130"><span class="color-red">*</span>目的名称</div>
					<el-input v-model="pageData.objectiveName" placeholder="输入关键字" />
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="pageData.addUpdateObjectShow = false">取消</el-button>
					<el-button type="primary" @click="addOrUpdateSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
		<!-- 选择教练 -->
		<el-dialog v-model="pageData.addCoachShow" v-loading="pageData.loading" title="选择教练" width="650px"
			:before-close="handleClose" custom-class="popwechat">
			<div class="pt30 pb30 pl30 pr30">
				<div class="rx-sc mb12">
					<span class="w80">搜索教练</span>
					<div class="w200">
						<el-input type="text" placeholder="输入关键字" v-model="pageData.addCoachName"></el-input>
					</div>
					<el-button class="ml20" type="primary" @click="searchCoach">查询</el-button>
				</div>
				<CommonTip class="mb12" :text="'已选择 '+ pageData.selectCheckData.length +' 条'" />
				<el-table ref="multipleTableRef" :data="pageData.coachList.slice((pageData.addCoachPage-1)*10, pageData.addCoachPage*10)" stripe :row-key="getRowKey"
			     @selection-change="selectionChange">
					<el-table-column type="selection" :reserve-selection="true" align="center" width="55"/>
					<el-table-column prop="realName" label="姓名" />
					<el-table-column prop="stallPhone" label="手机号" width="120" />
					<el-table-column prop="frameName" label="所属机构" show-overflow-tooltip />
					<el-table-column prop="ifFullTime" label="员工类型">
						<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
					</el-table-column>
				</el-table>
				<div class="mt20" v-show="pageData.coachList.length > 0">
					<el-pagination v-model:currentPage="pageData.addCoachPage" :page-size="pageData.addCoachLimit"
						layout="prev, pager, next" :total="pageData.addCoachCount" @current-change="getStaffPage">
					</el-pagination>
				</div>
			</div>
			<template #footer>
				<div class="dialog-footer">
					<el-button @click="handleClose">取消</el-button>
					<el-button type="primary" @click="addCoachSave">确定</el-button>
				</div>
			</template>
		</el-dialog>
	</div>
	</workArea>
</template>

<script setup>
	import {
		ref,
		reactive,
		onMounted,
		onUnmounted,
		onBeforeUnmount,
		nextTick
	} from 'vue';
	import {
		useRouter
	} from 'vue-router';
	import {
		Plus
	} from '@element-plus/icons-vue'
	import {
		ElMessage
	} from 'element-plus'
	import CommonTitle from '@/components/CommonTitle.vue'
	import CommonTip from '@/components/CommonTip.vue'
	 import WangEditor from 'wangeditor'
	import {
		getStoreAccountStaffSimple
	} from '@/api/staff.js'
    import {
        courseInsert,
        courseUpdate,
        trainPurposeInsert,
        courseInfo, trainPurposeListAll
    } from '@/api/course.js'
	import {
		getLocalStorage,
		wangEditorUpload
	} from '@/common/common.js'
	import workArea from '@/components/branch-store/workArea.vue';
	import CommonUpload from '@/components/CommonUpload.vue';
	const router = useRouter();
	const courseNameId = router.currentRoute.value.query.id;
	const encryptionId = router.currentRoute.value.query.eId;
	const editor = ref();
	const multipleTableRef = ref()
	 let instance = ''; 
	const pageData = reactive({
		barlist : [{ name : '返回',type:0 },{ name : (courseNameId?'编辑':'新增')+'课程' }],// 面包屑
		addUpdateObjectShow: false,
		objectiveName: '',
		courseColor: ['#9E9E9E', '#78909C', '#5C6BC0', '#42A5F5', '#26C6DA', '#26A69A', '#66BB6A', '#9CCC65',
			'#D4E157', '#FFEE58', '#FFA726', '#FF7043', '#EC407A', '#7E57C2', '#AB47BC', '#8D6E63'
		],
		courseColorCheck: 0,
		formData: {
			courseName: '', //课程名称
			courseType: 11,
			accommodatePerson: '2', //课程类型
			courseTime: '',
			courseColor: '',
			sellType: 0,
			pricingMethodType: 1,
			coursePrice: '',
			orderPrice: '',
			trainPurposeIds: [],
			newImgs: '',
			courseContent: '',
			signIfDeduct: false,
			leaveIfDeduct: false,
			absenceIfDeduct: false,
			applyAge:''
		},
		accommodatePerson: '1',
		orderType: true,
		checkList: [],
		store: '1',
		coachList: [],
		checkCoachList: [],
		checkCoachIdList: [],
		objectiveList: [],
		coverCheck: '0',
		cover: ['https://image.taokatao.cn/imgs/runBusiness/course1.png',
			'https://image.taokatao.cn/imgs/runBusiness/course2.png'
		],
		addCoachShow: false,
		addCoachPage: 1,
		addCoachCount: 0,
		addCoachLimit: 10,
		addCoachName: '',
		selectCheckData: []
	})
	
	//目的列表
	trainList();

	function trainList() {
        trainPurposeListAll().then((res) => {
			if (res.code == 0) {
				pageData.objectiveList = res.data || [];
			}
		})
	}

	//查询
	function searchCoach() {
		pageData.addCoachPage = 1;
		getStaff();
	}

	//新增教练
	function addCoach() {
		pageData.addCoachPage = 1;
		pageData.addCoachShow = true;
		pageData.checkCoachList.map((item)=>{
            nextTick(()=>{
               multipleTableRef.value.toggleRowSelection(item, true)
            })
        })
		getStaff();
	}
	//获取员工列表
	function getStaff() {
		var teachType = 0;
		if(pageData.formData.courseType == 11){
			teachType = 1;
		}else if(pageData.formData.courseType == 12){
			teachType = 2;
		}
		getStoreAccountStaffSimple({
			teachType,
			delFlag:0,
			page: pageData.addCoachPage,
			limit: pageData.addCoachLimit,
			searchKeys: pageData.addCoachName
		}).then((res) => {
			if (res.code == 0) {
				pageData.coachList = res.data
				pageData.addCoachCount = res.count;
			}
		})
	}
	/**
     * 数据选择
     */
	function selectionChange(data) {
		pageData.selectCheckData = data
	}
	/**
	 * 取消教练选择
	 */
	function handleClose() {
       multipleTableRef.value.clearSelection()
       pageData.addCoachShow = false
    }
	/**
     * 记住分页
     */
    function getRowKey(row) {
       return row.id
    }
	function addCoachSave() {
		pageData.checkCoachList = pageData.selectCheckData;
		pageData.addCoachShow = false;
	}

	//新增目的
	function addOrUpdateSave() {
		if (!pageData.objectiveName) {
			ElMessage.warning('请输入目的名称')
			return false;
		}
		trainPurposeInsert({
			name: pageData.objectiveName
		}).then((res) => {
			if (res.code == 0) {
				ElMessage({
					type: 'success',
					message: '保存成功',
				})
				pageData.addUpdateObjectShow = false;
				pageData.objectiveName = '';
				trainList();
			}
		})
	}
    /**
	 * 取消
	 */
	function addCourseCloce() {
		history.back();
	}
	//新增课程
	function addCourseSave() {
		let formData = {} 
		if (!pageData.formData.courseName) {
			ElMessage({
				type: 'warning',
				message: '请填写课程名称',
			})
			return false;
		}
		if (!pageData.formData.courseTime || pageData.formData.courseTime % 5 != 0) {
			ElMessage({
				type: 'warning',
				message: '请正确填写课程时长，且为5的倍数',
			})
			return false;
		}
		if (pageData.formData.courseType == 12 && !pageData.checkCoachList.length) {
			ElMessage({
				type: 'warning',
				message: '请选择授课教练',
			})
			return false;
		}
		if (pageData.formData.sellType == 1  && !(pageData.formData.coursePrice.toString())) {
			ElMessage({
				type: 'warning',
				message: '请填写课程单价',
			})
			return false;
		}
		if (pageData.formData.sellType == 1 && pageData.orderType && !pageData.formData.orderPrice) {
			ElMessage({
				type: 'warning',
				message: '请填写线上单节预约价格',
			})
			return false;
		}
		if (pageData.coverCheck == 2 && pageData.cover.length == 2) {
			ElMessage({
				type: 'warning',
				message: '请上传课程封面',
			})
			return false;
		}
		if (pageData.formData.trainPurposeIds.length == 0) {
			ElMessage({
				type: 'warning',
				message: '请选择训练目的',
			})
			return false;
		}
		
		formData.courseType = pageData.formData.courseType
		if (pageData.formData.courseType == 12) { //私教课
			formData.containNum = pageData.accommodatePerson == 1 ? 1 : pageData.formData.accommodatePerson
			
		}
		var list = pageData.checkCoachList.map(n => n.id); //教练
		formData.courseTime = pageData.formData.courseTime
		formData.courseName = pageData.formData.courseName
		formData.applyAge = pageData.formData.applyAge
		formData.courseTeacher = list.join(',');
		formData.signIfDeduct = pageData.formData.signIfDeduct ? 1 : 0;
		formData.leaveIfDeduct = pageData.formData.leaveIfDeduct ? 1 : 0;
		formData.absenceIfDeduct = pageData.formData.absenceIfDeduct ? 1 : 0;
		formData.courseContent = instance.txt.html();
		formData.courseColor = pageData.courseColor[pageData.courseColorCheck];
		formData.ifOrder = pageData.orderType ? 1 : 0
		formData.orderPrice = pageData.formData.orderPrice
        formData.coursePrice = pageData.formData.sellType ? pageData.formData.coursePrice : ''
		formData.trainPurposeIds = pageData.formData.trainPurposeIds.join(',');
		formData.ifSell = pageData.formData.sellType; //为了兼容老的
		formData.defaultAddPic = pageData.coverCheck < 2 ? 1 : 0;
		formData.coursePerimeter = 1;
		formData.courseCover = pageData.cover[pageData.coverCheck]
		
		formData.sellSid = getLocalStorage('businessUserData').sid;
		formData.shelfSid = getLocalStorage('businessUserData').sid;
		if (courseNameId) {
			formData.courseNameId = courseNameId;
			formData.encryptionId = encryptionId;
			courseUpdate(formData).then((res) => {
				if (res.code == 0) {
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					history.back();
				}
			})
		} else {
			courseInsert(formData).then((res) => {
				if (res.code == 0) {
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					history.back();
					// initFormData()
				}
			})
		}
	}

	//初始数据
	function initFormData() {
		pageData.formData = {
			courseType: 11,
			courseName: '', //课程名称
			applyAge: '',
			accommodatePerson: '2',
			courseTime: '',
			courseColor: '',
			sellType: '0',
			pricingMethodType: 1,
			coursePrice: '',
			orderPrice: '',
			trainPurposeIds: [],
			newImgs: '',
			courseContent: '',
			signIfDeduct: false,
			leaveIfDeduct: false,
			absenceIfDeduct: false,
		}
		pageData.checkList = [];
		pageData.checkCoachList = [];
		pageData.courseColorCheck = '0';
		pageData.coverCheck = '0';
		pageData.accommodatePerson = '1';
		pageData.orderType = true;
		if (pageData.cover.length > 2) {
			pageData.cover.splice(2, 1);
		}
		instance.txt.html('');
	}

	//单课时长失去焦点验证
	function courseTimeBlur() {
		var {
			courseTime
		} = pageData.formData;
		if (courseTime && courseTime % 5 != 0) {
			ElMessage({
				type: 'warning',
				message: '单课时长需为5的倍数',
			})
			pageData.formData.courseTime = '';
			return false;
		}
	}
	//课程单价失去焦点
	function coursePriceBlur() {
		var {
			coursePrice
		} = pageData.formData;
		if (coursePrice) {
			if (isNaN(Number(coursePrice))) {
				ElMessage({
					type: 'warning',
					message: '输入课程单价格式不正确',
				})
				pageData.formData.coursePrice = '';
				return false;
			}
			pageData.formData.coursePrice = Number(coursePrice).toFixed(2);
		}
	}
	//切换教练重置授课教练
	function courseTypeChange(){
		pageData.checkCoachList = [];
	}

	//上传封面
	function uploadChange(url) {
		pageData.cover.push(url);
	}
	// 删除封面
	function deleCover() {
		pageData.cover.splice(2, 1);
	}
	onMounted(() => {
		instance = new WangEditor(editor.value);
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:300,
			zIndex:100,
		});
		instance.create();
		//编辑课程-获取详情
		if (courseNameId) {
			getStaff();
			courseInfo({
				courseNameId
			}).then((res) => {
				if (res.code == 0) {
					for (var key in res.data) {
						pageData.formData[key] = res.data[key]
					}
					instance.txt.html(res.data.courseContent);
					pageData.courseColorCheck = pageData.courseColor.indexOf('#' + res.data.courseColor);
					if (res.data.courseTeacher) {
						res.data.courseTeacher.split(',').map((n) => {
							var arr = pageData.coachList.filter(z =>  z.id == n);
							if (arr.length > 0) {
								pageData.checkCoachList.push(arr[0]);
							}
						})
					}
					if (res.data.trainPurposeIds) {
						pageData.formData.trainPurposeIds = res.data.trainPurposeIds.split(',').map(n =>
							Number(n));
					}
					pageData.orderType = res.data.ifOrder ? true : false;
					pageData.formData.signIfDeduct = res.data.signIfDeduct == 1 ? true : false;
					pageData.formData.leaveIfDeduct = res.data.leaveIfDeduct == 1 ? true : false;
					pageData.formData.absenceIfDeduct = res.data.absenceIfDeduct == 1 ? true : false;
					pageData.accommodatePerson = res.data.containNum == 1 ? '1' : '2';
					pageData.formData.accommodatePerson = pageData.accommodatePerson > 1 ? res.data.containNum +'' : ''
					let arry = pageData.cover.filter((item)=>{ return item == res.data.courseCover})
					if (arry.length) {
                       	pageData.cover.forEach((item, index)=>{
							if (item == res.data.courseCover) {
                    	        pageData.coverCheck = index+''
							}
						})
					} else {
						pageData.coverCheck = '2'
						if (res.data.courseCover) {
							pageData.cover.push(res.data.courseCover)
						}
					}
				}
			})
		}
	})
	onBeforeUnmount(() => {
	  instance.destroy();
	  instance = null;
	 });
</script>

<style scoped lang="stylus">
	.add_course {
		.course_color {
			display inline-block;
			vertical-align middle;
			width 20px;
			height 20px;
			border-radius 100%;
			margin-right 10px;
			position relative;
			margin-bottom 5px;

			span {
				position absolute;
				left 3px;
				top 3px;
				bottom 3px;
				right 3px;
				display inline-block;
				border-radius 100%;
			}
		}

		.add_coach_title {
			background-color #F4F9FF;
			overflow hidden;
			border-bottom: 1px solid #eee;

			span {
				width 33.33%;
				float left;
			}
		}

		.coach_list {
			max-height 400px;
			overflow auto;

			div {
				background-color #FAFAFA;
			}

			div:nth-child(even) {
				background-color white;
			}
		}

		.store_bg {
			background-color #F7F7F7;
			border 1px solid #EAF0F8;
			border-radius 1px;
		}

		.course_money {
			border none;
			border-bottom 1px solid #ccc
		}

		.cover_img {
			position relative;

			.cover_dele {
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				background-color rgba(0, 0, 0, .3);
				color white;
				text-align center;
				cursor pointer;
				display none;
				line-height 80px;
			}
		}
		.cover_img:hover .cover_dele {
			display block;
		}
	}
</style>
