<template>
    <div class="common-layout">
        <el-container>
            <!-- 顶部 -->
          <el-header>
            <div class="ww100 h50 rx-bc">
                <div class="w400 h50 rx-sc">
                    <div v-show="menuType == 1" class="w180 h50 rx-cc">
                        <div class="w160 h50 rx-cc "><!-- 两边空10px -->
                            <img :src="store.state.storeLogo.value || userInfo.logoUrl" class="w35 h35 bdr4">
                            <div class="ml10 h50 rx-sc maxLength fwb color-fff">{{userInfo.storeName}}</div>
                        </div>
                    </div>
                    <div v-show="menuType == 2" class="w60 h50 rx-cc">
                        <img :src="userInfo.logoUrl" class="w35 h35 bdr4">
                    </div>
                    <div class="w220 h50 rx-sc">
                        <div class="w35 h50 ml5 rx-cc" @click="changeMenuType()">
                            <img v-show="menuType == 1" src="@/assets/branch/toLeft.png" class="w15 h15 c_p" title="收缩" />
                            <img v-show="menuType == 2" src="@/assets/branch/toRight.png" class="w15 h15 c_p" title="展开" />
                        </div>
                        <div class="w35 h50 rx-cc" @click="toNet">
                            <img src="@/assets/branch/net.png" class="w15 h15 c_p" title="官网"/>
                        </div>
                        <div class="w35 h50 rx-cc" @click="reflush">
                            <img src="@/assets/branch/flush.png" class="w15 h15 c_p" title="刷新" />
                        </div>
                        
                    </div>
                </div>
                <div class="w500 h50 rx-ec">
                    <a href="javascript:void(0);" class="rx-cc mr15" @click="downloadList">
						<Download class="w15 h15 color-fff" style="font-weight:bold;"></Download>
						<span class="ft14 color-fff">下载</span>
					</a>
                    <el-divider direction="vertical" border-style="solid"></el-divider>
                    <div class="w100 h50 rx-cc" v-if="sysUserStoreList.length > 0">
                        <el-dropdown @command="handleChangeStore">
                            <div class="h50 rx-cc">
                                <span class="color-fff ft14">切换店铺</span>
                                <el-icon class="el-icon--right">
                                    <CaretBottom style="color:#fff;"></CaretBottom>
                                </el-icon>
                            </div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item v-for="store in sysUserStoreList" :key="store" :command="store.bid+'-'+store.id">
                                    <div class="ww100 rx-sc" style="margin:0px;">
                                        <div class="w15 h30 rx-sc">
                                            <LocationFilled class="w15 h15" v-if="userInfo.storeName == store.storeName"></LocationFilled>
                                        </div>
                                        <div class="ml5">{{store.storeName}}</div>
                                    </div>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                    <el-divider direction="vertical" border-style="solid"></el-divider>
                    <div class="mr20 ml10">
                        <el-dropdown @command="handleCommand">
                            <div class="h50 rx-cc">
                              <span style="color:#fff;">{{userInfo.realName}}</span>
                              <el-icon class="el-icon--right">
                                <CaretBottom style="color:#fff;"></CaretBottom>
                                <!-- <arrow-down style="color:#fff;"/> -->
                              </el-icon>
                            </div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </div>
            </div>
          </el-header>
          <el-container :style="{height:laySize.winHeight - 50 + 'px'}">
            <!-- 菜单 -->
            <el-aside :style="{height:laySize.winHeight - 50 + 'px',width:(menuType == 1 ? '180px':'60px')}">
                <div v-show="menuType == 1" class="ww100 hh100">
                    <div v-for="menu in menuList" :key="menu" class="w180 cx-sc">
                        <div class="w180 h55 menu bom1 c_p rx-sc bg-off" :class="[menu.check ? 'active' : '']"  @click="changeMenu(menu.id,menu.hasList)">
                            <div class="w5 h55" :class="[menu.check ? 'left-bar-on' : 'left-bar-off']"></div>
                            <div class="ml25 h55 rx-ec">
                                <img :src="[menu.check ? menu.iconSelected : menu.icon]" class="w15 h15 ">
                            </div>
                            <div class="w85 h55 ml20 rx-sc" :class="[menu.check ? 'menu-on' : 'menu-off']">{{menu.menuName}}</div>
                            <div class="w20 h55 rx-cc">
                                <img :class="[menu.active ? 'arrow-open' : 'arrow-close' ]" :src="[menu.check ? menu.arrowSelected : menu.arrow]"  v-if="menu.hasList"  style="width:12px;height:10px;">
                            </div> 
                        </div>
                        <div class="w180 cx-sc" :class="[menu.active ? 'open pt5 pb5':'close']">
                            <div v-show="menu.active" @click="changeMenu(subMenu.id,subMenu.hasList)" class="w180 h40 bom1 c_p rx-sc menu" v-for="subMenu in menu.list" :key="subMenu">
                                <div class="menu-item rx-sc ml25" :class="[subMenu.check ? 'menu-item-on' : 'menu-item-off']">{{subMenu.menuName}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="ww100 h80"></div>
                    <!-- <div class="posi-a w180 h60 rx-cc ft12 color-ddd" style="bottom:0px;z-index:-1">
                        <span>—卡蜜运动版本—</span>
                    </div> -->
                </div>
                <div v-if="menuType == 2" class="ww100 hh100 cx-sc">
                    <div class="ww100 h60 bom1 c_p rx-cc" @mouseenter="mouseenter(menu.id)" @mouseleave="mouseleave(menu.id)" v-for="menu in menuList" :key="menu">
                        <img :src="[menu.check ? menu.iconSelected : menu.icon]" class="w20 h20 ">
                    </div>
                </div>
            </el-aside>
            <el-main>
                <router-view v-slot="{ Component }">
                    <keep-alive :include="store.state.keepLiveRoute">
                      <component :is="Component"/>
                    </keep-alive>
                </router-view>
            </el-main>
          </el-container>
        </el-container>
        <div v-show="popMenu.show"  @mouseenter="mouseenterSub" @mouseleave="mouseleaveSub" class="posi-a w170" style="left:55px;z-index:999;" :style="{top:popMenu.top+'px'}">
            <div class="w160 ml10 pop-shadow">
                <div class="w160 h50 rx-cc menu c_p"  @click="changeMenu(subMenu.id,subMenu.hasList)" :class="[subMenu.check ? 'menu-item-on bg-on' : 'menu-item-off bg-off']" v-for="subMenu in popMenuList" :key="subMenu">
                    {{subMenu.menuName}}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {ref,reactive,onMounted, onBeforeUnmount} from 'vue'
    import { useStore } from 'vuex'
    import {useRouter} from 'vue-router';
    import { ElMessage, ElLoading } from 'element-plus'
    import { onBeforeRouteUpdate , onBeforeRouteLeave} from "vue-router";
    import { Location, Document, Menu as IconMenu, Setting, } from '@element-plus/icons-vue'
    import {Switch,ArrowDown,Upload,Download,LocationFilled,CaretBottom} from '@element-plus/icons-vue'
    import {getLocalStorage,enterStore} from '../../common/common.js';
    import {logout,getUserMenuListJson} from '../../api/login.js';
    import {loginByStore} from '../../api/store.js';

    const router = useRouter();
    const store = useStore();
    const nowStoreData = getLocalStorage('businessNowStoreData');
    const loginData = getLocalStorage('businessLoginData');
    const businessMenuList = getLocalStorage('businessMenuList');
    const userInfo = ref(getLocalStorage('businessUserData'));
    const laySize = reactive({ winHeight : window.innerHeight, winWidth : window.innerWidth })
    const menuList = reactive(getMenuList()); // 主菜单list
    const popMenuList = reactive([]); // 弹窗内菜单的list
    const menuType = ref(1);// 1：展开，2：收缩
    const popMenu = reactive({ top : 52, show : false });
    const currentMenu = reactive({ id : '1', url : 'dataCenter', nav : ["数据概览"] });
    function downloadList(){
        changeMenu(0, false)
		router.push('/branch/downloadList');
	}

    function mouseenter(id){
        var topPx = calTopPx(id);
        popMenu.top = topPx;
        popMenu.show = true;
        var subMenuList = getSubMenuList(id);
        popMenuList.length = 0;
        Object.assign(popMenuList,subMenuList);
    }
    function mouseleave(id){
        popMenu.show = false;
    }
    function mouseenterSub(){
        popMenu.show = true;
    }
    function mouseleaveSub(){
        popMenu.show = false;
    }
    // 计算顶部的px
    function calTopPx(id){
        var index = getMenuIndex(id);
        return index * 60 + 53;
    }
    function getSubMenuList(id){
        var subMenuList = [];
        for(var i=0;i<menuList.length;i++){
            if(menuList[i]['id'] == id){
                if(menuList[i]['list'] && menuList[i]['list'].length > 0){
                    subMenuList = menuList[i]['list'];
                }else{
                    subMenuList.push(menuList[i]);
                }
            }
        }
        return subMenuList;
    }
    function getMenuIndex(id){
        for(var i=0;i<menuList.length;i++){
            if(menuList[i]['id'] == id){
                return i;
            }
        }
        return 0;
    }
    // 菜单-初始化
    function getMenuList(){
        function getMenuArray(itemArray){
            var menuArray = [];
            for(var i=0;i<itemArray.length;i++){
                var item = itemArray[i];
                var iconName = item['icon'] || 'data.png';
                var iconArrowName = 'arrow-down.png';
                var obj = {
                    id : item['id'],
                    parentMenuId : item['parentMenuId'],
                    menuName : item['menuName'],
                    menuUrl : item['menuUrl'],
                    check : false, // 是否选择
                    active : false, // 是否激活
                    icon : require('@/assets/menuIcon/off-' + iconName),
                    iconSelected : require('@/assets/menuIcon/on-' + iconName),
                    arrow : require('@/assets/menuIcon/off-' + iconArrowName),
                    arrowSelected : require('@/assets/menuIcon/on-' + iconArrowName)
                };
                if(item['list'] && item['list'].length > 0){
                    obj['hasList'] = true;
                    obj['list'] = getMenuArray(item['list']);
                }
                menuArray.push(obj);
            }
            return menuArray;
        }
        return getMenuArray(businessMenuList);
    }
    // 菜单-收缩切换
    function changeMenuType(){
        menuType.value = menuType.value == 1 ? 2 : 1;
    }
    // 菜单-切换
    function changeMenu(id,hasList,isTwo){ //isTwo 判断是否是二级详情页面
		window.localStorage.setItem('nowMenuId',id);
        var parentMenuId = 0;
        function changeItemArray(itemArray){
            for(var i=0;i<itemArray.length;i++){
                if(itemArray[i]['id'] == id){
                    if(itemArray[i]['hasList']){
                        itemArray[i]['active'] = !itemArray[i]['active']; // 激活
                    }else{
                        itemArray[i]['check'] = true; // 选中
                        itemArray[i]['active'] = true; // 激活
                        parentMenuId = itemArray[i]['parentMenuId'];
						if(!isTwo){  //不是二级详情页面 再跳
							router.push(itemArray[i]['menuUrl']);
						}
                    }
                }else{
                    itemArray[i]['active'] = false; // 激活
                    if(!hasList){
                        itemArray[i]['check'] = false; // 激活
                    }
                }
                if(itemArray[i]['hasList']){
                    changeItemArray(itemArray[i]['list']);
                }
            }
        }
        changeItemArray(menuList);
        if( parentMenuId > 0 ){      
            for(var i=0;i<menuList.length;i++){
                if(menuList[i]['id'] == parentMenuId){
                    menuList[i]['check'] = true;
                    menuList[i]['active'] = true;
                }
            }
        } 
    }
    // 菜单-获取当前url
    function getCurrentUrl(){
        var tempUrl = location.href.split('#')[1];
        return tempUrl.indexOf('?') > -1 ? tempUrl.split('?')[0] : tempUrl;
    }
    // 菜单-获取选中的菜单
    function getCheckMenuByUrl(url,list){
        var obj = {id : 0};
        for(var i=0;i<list.length;i++){
            if(list[i]['menuUrl'] == url){
                obj = list[i];
            }
            if(obj['id'] == 0 && list[i]['list'] && list[i]['list'].length > 0){
                obj = getCheckMenuByUrl(url,list[i]['list']);
            }
        }
        return obj;
    }
    var currentMenuId = getCheckMenuByUrl(getCurrentUrl(),menuList)['id'];
    if( currentMenuId > 0){
        changeMenu(currentMenuId,false);
    }else{ //兼容二期详情页面菜单高亮显示
		var nowMenuId = window.localStorage.getItem('nowMenuId');
		changeMenu(nowMenuId,false,true); //第三个参数是否是二级详情页面
	}
    // 菜单-监听菜单变化
    onBeforeRouteUpdate((to,from) => {
        var obj = getCheckMenuByUrl(to['path'],menuList);
        if(obj['id'] > 0 && !obj['check']){
            changeMenu(obj['id'],false);
        }
    });
    function handleChangeStore(command){
        var bid = command.split('-')[0];
        var sid = command.split('-')[1];
        var item = sid == 0 ? -1 : 1;
        
        const loading = ElLoading.service({
            lock: true,
            text: '正在切换中，请稍后。。。'
        })
		store.state.storeLogo.value = '';
        enterStore(item, bid, sid, router,function(){
            setTimeout(()=>{
                loading.close();
				userInfo.value = getLocalStorage('businessUserData');
            },1000);
        });
    }
    // 处理顶部点击
    const handleCommand = (command) => {
      if(command == 'loginOut'){
        ElMessage({
            message:'退出成功',
            type: 'success'
        })
        setTimeout(()=>{
			var orderTips = localStorage.getItem('orderTips');//前台场地预订引导
            localStorage.clear();
            sessionStorage.clear();
			if(orderTips){
				localStorage.setItem('orderTips',orderTips);
			}
            router.push('/login');
        },1000)
      }
    }
    // 跳转官网
    function toNet(){
        window.open('http://yxysport.com/');
    }
    // 页面刷新
    function reflush(){
        location.reload();
    }
    const sysUserStoreList = reactive([]);
    function getStoreList(){
        // 获取切换店铺列表
        var storeList = getLocalStorage('businessNowStoreData') || [];
        sysUserStoreList.length = 0;
        for(var i=0;i<storeList.sysUserStoreList.length;i++){
            sysUserStoreList.push(storeList.sysUserStoreList[i]);
        }
    }
    onMounted(()=>{
        getStoreList();
    });
</script>

<style lang="stylus" scoped>

.common-layout{
    .pop-shadow{
        border-radius:4px;
        overflow:hidden;
        box-shadow: 1px 0px 2px #BAC9D533,
                    -1px 0px 2px #BAC9D533,
                    0px 1px 2px #BAC9D533,
                    0px -1px 2px #BAC9D533;
    }
    .el-header{
        padding:0px;
        height:50px;
        background: linear-gradient(90deg, #5172FF 0%, #57B7FF 100%);
    }
    .el-aside {
        padding:0px;
        box-shadow: 0px 2px 4px 0px #BAC9D5;
    }
    .el-main {
        margin-left:2px;
        background-color: #fff;
        padding:0px;
    }
    .left-bar-on{
        background-color:#00a3ff;
    }
    .left-bar-off{
        background-color:#ffffff;
    }
    .menu-on{
        color : #00a3ff;
    }
    .menu-off{
        color : #666666;
    }
    .menu-item-on{
        color : #FFF;
        background-image: url(../../assets/home/menu_active_bac.png);
        background-size: 100%;
    }
    .menu-item-off{
        color : #666666;
    }
    .menu-item {
        width: 144px;
        height: 40px
        padding: 0 40px
    }
    .bg-on{
        background-color:#f5f5f5;
    }
    .bg-off{
        background-color:#fff;
    }
    .bg-off:hover{
        background-color:#F8F9FB;
    }
    .active{
        background-color:#F8F9FB;
    }
    .arrow-open{
        -webkit-transform:rotate(180deg);
        transform:rotate(180deg);
        -webkit-transition:-webkit-transform .3s linear;
        transition:transform .3s linear; 
    }
    .arrow-close{
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg);
        -webkit-transition:-webkit-transform .3s linear;
        transition:transform .3s linear; 
    }
    .open{
        max-height: 600px;
        -webkit-transition: max-height .8s;
        transition: max-height .8s
    }
    .close{
        max-height: 0px;
        -webkit-transition: max-height .8s;
        transition: max-height .8s
    }
    .menu-item:hover{
        background-color:#F5F5F5;
    }
}
</style>
