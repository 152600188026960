<template>
	<div class="store_info" v-show="storeData.moduleStatus">
		<h3 class="ft16 mb10">门店信息</h3>
		<p class="mb5"><span class="color-red">*</span>添加轮播图：</p>
		<p class="ft12 mt10 mb10 color-999">图片建议尺寸750*400px，最多可添加5张，可拖动调整图片顺序。</p>
		<div>
			<MoveSort :first="true" :list="storeData.banners" :border="'left'" :hide="6">
				<template #[index] v-for="(item, index) in storeData.banners" :key="index">
					<template v-if="item == '1'">
						<div class="h100 bdr4" @click="uploadBanner">
							<div class="h100">
								<div class="t_a_c bg-fff upload ml10 mr10  rx-cc hh100 c_p">
									<CommonUpload @change="(url)=>{uploadChange(url,1)}" :autoCropWidth="375" :autoCropHeight="200" :isCropper="true">
										<div>
											<img class="w30" src="@/assets/addBank.png" alt="">
											<div class="color-333">上传图片</div>
										</div>
									</CommonUpload>
								</div>
							</div>
						</div>
					</template>
					
					<!-- <template v-for="(item,indx) in storeData.banners"> -->
						<div v-else-if="index > 0" class="h100 rx-cc pl10 pr10">
							<div class="ww100 hh100 uploadLogo bdr4">
								<img class="ww100 hh100 bdr4" :data-index="index" :src="item" >
								<div class="p">
									<CommonUpload @change="(url)=>{uploadChange(url, 'A'+index)}" :autoCropWidth="375" :autoCropHeight="200" :isCropper="true">
										<p class="ft12 reUpload">重新上传</p>
									</CommonUpload>
								</div>
								<img @click="deleBanner(index)" class="w20 dele_icon bg-fff" src="@/assets/icon_error.png" alt="">
							</div>
						</div>
					<!-- </template> -->
				</template>
			</MoveSort>
		</div>
		<div class="store_form mt20">
			<div class="rx-sc mb12">
				<span><span class="color-red">*</span>门店LOGO：</span>
				<div class="w200">
					<div class="w80 h80 rx-cc img-border" v-show="!storeData.logo">
						<CommonUpload @change="(url)=>{uploadChange(url,2)}" :autoCropWidth="375" :autoCropHeight="325" :isCropper="true">
							<div class="t_a_c">
								<img class="w15" src="@/assets/addBank.png" alt="">
								<p class="ft12 color-999">上传LOGO</p>
							</div>
						</CommonUpload>
					</div>
					<div class="w80 h80 uploadLogo" v-show="storeData.logo">
						<img class="ww100 hh100 bdr4" :src="storeData.logo" alt="">
						<div class="rx-cc deleLogo" @click="storeData.logo = ''">
							<unicon  name="trash-alt" fill="#ffffff"></unicon>
						</div>
					</div>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span><span class="color-red">*</span>门店名称：</span>
				<div class="w200">
					<el-input v-model="storeData.storeName" placeholder="请输入门店名称" type="text" maxlength="12"></el-input>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span><span class="color-red">*</span>营业时间：</span>
				<div class="w200">
					<el-time-picker 
						style="width: 100%;"
					      v-model="pageData.time"
						  width="200"
						  format="HH:mm"
					      is-range
					      range-separator="至"
					      start-placeholder="开始时间"
					      end-placeholder="结束时间"
						  @change="dateChange"
					    >
					    </el-time-picker>
				</div>
			</div>
			<div class="rx-sc mb12">
				<span><span class="color-red">*</span>联系电话：</span>
				<div class="w200">
					<el-input v-model="storeData.phone" maxlength="11" placeholder="请输入联系电话" type="tel"></el-input>
				</div>
			</div>
			<div class="rx-ss mb12">
				<span><span class="color-red">*</span>详细地址：</span>
				<div class="w200">
					<el-input v-if="pageData.cityAddress.length" v-model="pageData.cityAddress" disabled placeholder="请输入详细地址" type="textarea"></el-input>
					<a v-else href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="pageData.dialogVisible = true">设置门店地址</a>
				</div>
			</div>
		</div>

		<el-dialog
	        v-model="pageData.dialogVisible"
	        title="设置门店地址"
	        width="600px"
	        :close-on-click-modal="false"
	        :close-on-press-escape="false"
	        :before-close="handleClose"
	      >
	        <div class="p20">
	            <el-form 
	              :model="ruleForm" 
	              :rules="rules"
	              ref="refruleForm" 
	              label-width="85px" 
	            >
	                <el-row>
	                    <el-col>
	                        <el-form-item label="所在区域" prop="city">
	                            <div class="w220">
	    				        	<el-cascader
	    				        		class="ww100"
	    				        		v-model="ruleForm.city"
	    				        	    placeholder="请选择"
	    				        	    :options="pageData.allAddress"
	    				        	    clearable 
	    				        	/>
	    				        </div>
	                        </el-form-item>
	                    </el-col>
	                </el-row>
	                <el-row>
	                    <el-col>
	                        <el-form-item label="详细地址" prop="storeAdd">
	                          <el-input type="text" v-model="ruleForm.storeAdd" placeholder="请输入详细地址"></el-input>
	                        </el-form-item>
	                    </el-col>
	                </el-row>
	                <el-row>
	                    <el-col :span="12">
	                        <el-form-item label="经度" prop="longItude">
	                          <el-input type="Number" v-model="ruleForm.longItude" placeholder="请输入经度"></el-input>
	                        </el-form-item>
	                    </el-col>
	                    <el-col :span="12">
	                        <el-form-item label="纬度" prop="latItude">
	                          <el-input type="Number" v-model="ruleForm.latItude" placeholder="请输入纬度"></el-input>
	                        </el-form-item>
	                    </el-col>
	                </el-row>
	                <el-row>
	                    <el-col class="ml90 mb10 rx-c">
	                        <el-link class="t_d_u" href="https://lbs.qq.com/getPoint/" :underline="false" type="primary" target="_blank" @click="getCoordinate">获取坐标</el-link>
	                        <div class="rx-c" v-show="pageData.coordinateShow">
	                            <el-input class="mr20" v-model="pageData.coordinate" placeholder="请将复制的坐标粘贴到此处" />
	                            <el-button type="primary" @click="coordinateSubmit">确认</el-button>
	                        </div>
	                        
	                    </el-col>
	                    <el-col>
	                        <el-form-item>
	                          <span class="color-999 ft12">教程：点击获取坐标，打开网后输入店铺详细地址，选中地图上正确的位置后将出现一组数字（纬度，经度），即为坐标，可双击鼠标复制并粘贴到此处对应的输入框。</span>
	                        </el-form-item>
	                    </el-col>
	                </el-row>
	            </el-form>
	        </div>
	        <template #footer>
	          <span class="dialog-footer">
	            <el-button @click="handleClose">取 消</el-button>
	            <el-button type="primary" @click="onSubmit">确 定</el-button>
	          </span>
	        </template>
	    </el-dialog>
	</div>
</template>

<script setup>
	const propData = defineProps({
		sid: Number,
	  	tab:String,
	  	itemIndex:Number
	})
	import {ref,reactive, onMounted} from 'vue'
	import { useStore } from 'vuex'
	import { storeUpdAddr, getMyLineStore } from '@/api/storeCenter.js';
	import { getAllAddress } from '@/api/applet.js'
	import MoveSort from '../MoveSort.vue'; //通用移动排序
	 import CommonUpload from '@/components/CommonUpload.vue';
	const store = useStore();
	const storeData = reactive(store.state.appletFormData[propData.tab].list[propData.itemIndex])
	const pageData = reactive({
		areaList:[],
		time:[],
		allAddress: [],
		dialogVisible: false, // 弹框显隐
		cityAddress: []
	})
	//回显营业时间
	if(storeData.time){
		if(storeData.time.indexOf(' ~ ') > -1){
			var times = storeData.time.split(' ~ ');
			pageData.time = times.map((n)=>{
				return new Date('2022-01-01 '+ n);
			})
		}else{
			storeData.time = [];
		}
	}
	function dateChange(res){
		var str = [];
		res.map((n)=>{
			var d = new Date(n);
			var h = d.getHours() > 9 ? d.getHours() : '0'+d.getHours();
			var m = d.getMinutes() > 9 ? d.getMinutes() : '0'+d.getMinutes();
			str.push(h+':'+m);
		})
		storeData.time = str.join(' ~ ');
	}
	//上传banner
	function uploadChange(url,type){
		console.log(url)
		if(type == 1){
			storeData.banners.push(url);
		}else if (type == 2){
			storeData.logo = url;
		} else {
			let index = type.substring(1)
			storeData.banners[index] = url
		}
	}
	
	//删除banner
	function deleBanner(index){
		storeData.banners.splice(index,1);
	}
	/**
	 * 获取地址
	 */
	function getStoreAddress() {
		getMyLineStore({ sid:  propData.sid }).then((item) => {
			if(item.code == 0) {
				pageData.cityAddress = item.data.provinceName ? (item.data.provinceName + '-' + item.data.cityName + '-' + item.data.areaName + ' ' + item.data.storeAdd ): ''
    	    }
		})
	}
	getStoreAddress()
	/**
	 * 提交复制的坐标
	 */
	const coordinateSubmit=()=> {
	    if (pageData.coordinate) {
	        let arry = pageData.coordinate.split(',')
	        ruleForm.longItude = arry[1]
	        ruleForm.latItude = arry[0]
	    }
	    pageData.coordinateShow = false
	}
	const getCoordinate=() => {
	    pageData.coordinate = ''
	    pageData.coordinateShow = true
	}
	// 获取地区数据
	const getAddress=() => {
	    getAllAddress().then((res) => {
	        if(res.code == 0){
		    	pageData.allAddress = res.data;
		    }
	    })
	}
	getAddress()
	//设置地址
	const  refruleForm = ref()
	const ruleForm = reactive({
	    provinceId: '',
	    cityId: '',
	    areaId: '',
	    storeAdd: '',
	    longItude: '',
	    latItude: '',
	    city:[]
	});
	/**
	 * 取消关闭弹框
	 */
	const handleClose=() => {
	    refruleForm.value.resetFields()
	    pageData.dialogVisible = false
	}
	const getParams=() => {
	    let params = {
	        provinceId: ruleForm.city[0],
            cityId: ruleForm.city[1],
            areaId: ruleForm.city[2],
            storeAdd: ruleForm.storeAdd,
			sid: propData.sid,
            longItude: ruleForm.longItude,
            latItude: ruleForm.latIude,
	    }
	    return params
	}
	const onSubmit=() => {
	    refruleForm.value.validate((valid) => {
	        if (valid) {
	            let params = getParams()
	            storeUpdAddr(params).then((res) => {
	                if (res.code == 0) {
	                   ElMessage.success('设置成功')
					   getStoreAddress()
					   location.reload()
	                }
	            })
	        refruleForm.value.resetFields()
	        pageData.dialogVisible = false
	        } else {
	          console.log('error submit!!');
	          return false;
	        }
	    });
	}
</script>

<style scoped lang="stylus">
	.store_info{
		.img-border{
			border: 1px dashed rgb(214, 214, 214);
		}
		.upload {
			border: 1px dashed #D6D6D6
		}
		.upload:hover {
			border: 1px dashed #409eff
		}
		.uploadLogo{
			position relative;
			.deleLogo{
				position absolute;
				left 0;
				top 0;
				right 0;
				bottom 0;
				z-index 100;
				background-color rgba(0,0,0,.3);
				cursor pointer;
				display none;
				text-align center;
				line-height 90px;
			}
			border: 1px solid rgb(214, 214, 214);
		}
		.uploadLogo:hover .deleLogo{
			display block;
		}
		ul{
			width 420px
			li{
				float left;
				padding-right 10px;
				margin-bottom 20px;
				div{
					.dele_icon{
						position absolute;
						right -10px;
						top -10px;
						display none;
						cursor pointer;
						border-radius 100%
					}
					.p{
						padding 5px 0;
						position absolute;
						bottom 0;
						left 0;
						right 0;
						background-color rgba(0,0,0,0.3);
						text-align center;
						color white;
						display none;
						cursor pointer;
						border-bottom-left-radius: 4px;
						border-bottom-right-radius: 4px;
					}
				}
			}
			li:hover .dele_icon{
				display block;
			}
			li:hover .p{
				display block;
			}
			li:nth-child(2n){
				padding-left 10px;
				padding-right 0;
			}
		}
		.store_form{
			clear both;
		}
	}
</style>
