<template>
	<div class="assets_info posi-r">
		<div class="rx-sc pt10 pb10">
			<div :style="{'background': themeStore.bgColor == 2,'z-index': 10}">
				<div class="mr15 w60 h60" :class="themeStore.bgColor == 2 ? 'border-box1' : 'border-box'">
					<img class="ww100 hh100" src="@/assets/applet/coach2.png" alt="">
				</div>
			</div>
			<div>
				<h3 class="ft16 color-333" :class="{'color-fff':themeStore.bgColor == 2}">一只知更鸟</h3>
				<p class="ft12 color-999 mt5" :class="{'color-fff':themeStore.bgColor == 2}">好设计是尽可能的无设计</p>
			</div>
		</div>
		<div class="rx-bc pt10 pb10 pl20 pr20">
			<div class="t_a_c" v-for="(item, index) in storeData.list" :key="index">
				<h3 class="ft16 color-333" :class="{'color-fff':themeStore.bgColor == 2}">0</h3>
				<p class="ft12 color-666" :class="{'color-fff':themeStore.bgColor == 2}">{{item.newName || item.name}}</p>
			</div>
		</div>
		<div class="img_left posi-a">
			<img class="ww100 hh100" src="@/assets/applet/img_left.png" alt="">
		</div>
		<div class="img_right posi-a">
			<div class="posi-r">
				<img class="posi-a img1 w60 h60" :class="themeStore.bgColor == 2 ? 'border-img' : ''" src="@/assets/applet/img_right.png" alt="">
				<img class="posi-a img2 w60 h60" :class="themeStore.bgColor == 2 ? 'border-img1' : ''" src="@/assets/applet/img_right1.png" alt="">
			</div>
		</div>
		
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeStore = store.state.appletFormData[4].list[0];
</script>

<style scoped>
	.border-box {
		padding: 2px;
		border: 1px solid transparent;
		border-radius: 50%;
		background-clip: padding-box, border-box;
		background-origin: padding-box, border-box;
		background-image: linear-gradient(to right, #FFF,#FFF), linear-gradient(90deg,#5C41BD , #44A1D1);
	}
	.border-box1 {
		padding: 2px;
		border: 1px solid transparent;
		border-radius: 50%;
		background-clip: padding-box, border-box;
		background-origin: padding-box, border-box;
		background-image: linear-gradient(to right, #14141A,#14141A), linear-gradient(90deg,#5C41BD , #44A1D1);
	}
	.img_left {
		width: 44px;
		height: 67px;
		top: 35px;
    	left: -10px;
		z-index: 1
	}
	.img_right {
		top: 0;
    	right: 40px;
	}
	.img1{
		width: 50px;
		height: 82px;
	}
	.img2{
		width: 56px;
		height: 56px;
		left: -28px;
    	top: 28px;
	}
	.border-img {
		opacity: 0.3;
	}
	.border-img1 {
		opacity: 0.8;
	}
</style>
