<template>
    <div class="p20 pt0">
        <div class="rx-bc">
			<formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="searchGetList"></formQuery>
            <el-button v-if="utils.getMenuRole(3190100)" @click="getList(true)"  :icon="Upload" >导出</el-button>
		</div>
        <el-table v-loading="pageData.loading" :data="pageData.tableData" stripe style="width: 100%" >
			<el-table-column label="会员名称" fixed>
                <template #default="scope">
					<span @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    <span class="ml5">{{ scope.row.telephoneNum }}</span>
				</template>
            </el-table-column>
            <el-table-column label="会员类型">
                <template #default="scope">
					<span class="tag_blue tag" v-if="scope.row.trialFlag == 1">正式会员</span>
                    <span class="tag_red tag" v-else>潜在会员</span>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="消费时间" />
            <el-table-column prop="productName" label="扣费支付方式" />
            <el-table-column label="卡类种类">
                <template #default="scope">
					{{ filtersType(scope.row.cardType, 1) }}
				</template>
            </el-table-column>
            <el-table-column prop="consumeNum" label="本次扣除" width="100" >
            	<template #default="scope">
					<span>{{scope.row.cardType == 3 ? scope.row.storeMoneyAll + '元' : scope.row.consumeNum + '天'}}</span>
            	</template>
            </el-table-column>
            <el-table-column prop="consumeMoney" label="消费金额(元)" width="110" />
            <el-table-column label="消费方式" width="110" >
                <template #default>
                   按天自动扣费
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="printTicketEvent(scope.row)">打印小票</a>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span class="mr20">消费天数总计：<span class="color-red mr5">{{ pageData.remark.consumeNumAll || 0 }}</span>天</span>
                <span>消费金额总计：<span class="color-red mr5">{{ pageData.remark.consumeMoneyAll || 0 }}</span>元</span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
    </div>
    <PrintTicket v-model="pageData.printShow" type="3" :data="pageData.courseInfo" @save="printSave"></PrintTicket>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter,onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
    import utils from '@/common/utils.js'
    import { Upload } from '@element-plus/icons-vue'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import PrintTicket from '@/components/branch-store/PrintTicket.vue';
    import { orderConsume } from '@/api/consume.js';
	import { ElMessage } from 'element-plus'
    const propData = defineProps({
        operator: Array
    })

    const multipleTable= ref()
    const queryOptsRef = ref()
    const store = useStore();
	const router = useRouter();
    const timerList = [
        { value : "5" , label : '最近7天' },
        { value : "30" , label : '本周' },
        { value : "20" , label : '本月' },
        { value : "21" , label : '上月' },
        { value : "-1" , label : '其他' }
    ]
    const cardTypeList = [
        { value : '' , label : '全部卡种类' },
        { value : '1' , label : '课程卡' },
        { value : '2' , label : '入场卡' },
        { value : '4' , label : '体验卡' },
    ]
    const pageData = reactive({
        loading: false,
        tableData: [],
        searchForm: {
            page: 1,
            limit: 15
        },
        printShow: false, //打印小票弹框
        courseInfo: {},
        remark: {},
        totalCount: 0,
        queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'cardType',
				placehold : '卡种类',
				options : cardTypeList,
                value:''
		    },{
                type : 'select',
                fieldName : 'timeIn',
                placehold : '',
                value : '5',
                options : timerList,
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'createTimeStr',
                placeholder : '日期范围'
            }]
		}
    })
    /**
     * @param (String) value-类型
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        cardTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取列表
     */
    const getList=(isExport) => {
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        params.operatorIds = params.operatorIds ? params.operatorIds.join(',') : ''
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.timeIn == '-1' && !params.createTimeStr) {
            ElMessage.warning('请选择自定义消费时间')
            return false
        }
        pageData.loading = true
        params.createTimeStr = params.timeIn == '-1' && params.createTimeStr ?  params.createTimeStr.join('-') : ''
        params.consumeType = 3
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
        orderConsume(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if (res.code == 0) {
					if(isExport){ //导出
						ElMessage({
							type: 'success',
							message: '正在导出，请稍后前往下载中心下载'
						})
					}else{
						pageData.tableData = res.data
						pageData.totalCount = res.count
						pageData.remark = res.remark
					}
				}
                
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    // 打印小票
	function printTicketEvent(row){
		pageData.courseInfo = row;
		pageData.printShow = true;
	}
	function printSave(){
		pageData.printShow = false;
	}
    /**
     * 会员详情
     */
	const memberDetail=(id) => {
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	/**
     * 会员详情
     */
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	}),
    onMounted(()=> {
        getList()
    })
</script>
<style scoped>
    .tag {
        padding: 1px 4px;
    }
</style>