<template>
    <div class="p16">
        <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
              <el-form-item>
                <el-button type="primary" @click="addClass(12)">新增小班课班级</el-button>
              </el-form-item>
              <el-form-item>
                <el-input v-model="pageData.formInline.cycleNameLike" placeholder="输入小班级名称" />
              </el-form-item>
              <el-form-item>
                <el-select v-model="pageData.formInline.cycleState">
                  <el-option label="全部班级状态" value="" />
                  <el-option label="未开始" value="1" />
                  <el-option label="进行中" value="2" />
                  <el-option label="已结束" value="3" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="pageData.formInline.cycleCourseState">
                  <el-option label="全部排课情况" value="" />
                  <el-option label="排课中" value="1" />
                  <el-option label="未排课" value="2" />
                  <el-option label="已排完" value="3" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-select v-model="pageData.formInline.cycleSellState">
                  <el-option label="全部报名状态" value="" />
                  <el-option label="未开始" value="1" />
                  <el-option label="报名中" value="2" />
                  <el-option label="报名结束" value="3" />
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="search" :icon="Search">搜索</el-button>
              </el-form-item>
        </el-form>
        <el-table :data="pageData.tableData" stripe style="width: 100%">
            <el-table-column prop="cycleName" label="班级名称">
              <template #default="scope">
                <a href="javascript:;" class="color-409EFF " @click="details(scope.row)">{{ scope.row.cycleName }}</a>
              </template>
            </el-table-column>
            <el-table-column label="开班时间" width="161">
              <template #default="scope">
                <div>{{scope.row.cycleStartCourseTimeStr }}</div>
              </template>
            </el-table-column>
            <el-table-column label="排课情况">
              <template #default="scope">
                <span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5" v-if="scope.row.cycleNumHas == 0"></span>
                <span class="d_i_b w5 h5 v_a_m bdr4 badge-warning mr5" v-else-if="scope.row.cycleNumHas < scope.row.cycleNum"></span>
                <span class="d_i_b w5 h5 v_a_m bdr4 badge mr5" v-else></span>
                <span> {{ scope.row.cycleNumHas == 0 ? '未排课' : scope.row.cycleNumHas < scope.row.cycleNum ? '排课中' : '已排完' }}</span>
                ({{scope.row.cycleNumHas  }}/{{scope.row.cycleNum }})
              </template>
            </el-table-column>
            <el-table-column label="班级状态">
              <template #default="scope">
                <div class="courseType t_a_c box-009688" v-if="scope.row.cycleStatus == 1">未开始</div>
                <div class="courseType t_a_c box-1E9FFF" v-else-if="scope.row.cycleStatus == 2">进行中</div>
                <div class="courseType t_a_c box-FF5721" v-else>已结束</div>
              </template>
            </el-table-column>
            <el-table-column label="报名人数">
              <template #default="scope">
                <span> {{scope.row.sellPeopleNum }}/{{scope.row.allPeopleNum }}</span>
              </template>
            </el-table-column>
            <el-table-column label="报名状态">
              <template #default="scope">
                <div v-if="scope.row.sellStartTime">
                  <span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5" v-if="utils.expired(utils.getFormatDate(scope.row.sellStartTime)) > 0 "></span>
                  <span class="d_i_b w5 h5 v_a_m bdr4 badge-warning mr5" v-else-if="utils.expired(utils.getFormatDate(scope.row.sellStartTime)) < 0 && utils.expired(utils.getFormatDate(scope.row.sellEndTime)) > 0"></span>
                  <span class="d_i_b w5 h5 v_a_m bdr4 badge mr5" v-else></span>
                  {{ utils.expired(utils.getFormatDate(scope.row.sellStartTime)) > 0 ? '未开始' : utils.expired(utils.getFormatDate(scope.row.sellStartTime)) < 0 && utils.expired(utils.getFormatDate(scope.row.sellEndTime)) > 0 ? '报名中' : '报名结束'  }}
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column label="报名时间" width="200">
              <template #default="scope">
                  <div v-if="scope.row.sellStartTime">{{ utils.getFormatDate(scope.row.sellStartTime) }}</div>
                  <div v-if="scope.row.sellEndTime">{{ utils.getFormatDate(scope.row.sellEndTime) }}</div>
                  <div v-if="!scope.row.sellStartTime">-</div>
              </template>
            </el-table-column>
            <el-table-column prop="sellPrice" label="售价" />
            <el-table-column label="课节购买限制">
              <template #default="scope">
                <span> {{ scope.row.sellOneLimitMin }}-{{ scope.row.sellOneLimitMax  }}节</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="160">
                <template #default="scope">
			      	  	<div>
                    <el-link class="t_d_u" :underline="false" type="primary" @click="lookCourse(scope.row)">班级详情</el-link>
                    <el-link class="ml10 t_d_u" v-if="scope.row.cycleStatus != 3 && (utils.expired(utils.getFormatDate(scope.row.sellEndTime))  < 0 || !scope.row.sellEndTime)" :underline="false" type="danger" plain @click="endCourse(scope.row)">完结</el-link>
			      	  	  <el-link class="ml10 t_d_u" v-if="scope.row.cycleStatus != 3" :underline="false" type="danger" plain @click="deleCourse(scope.row)">删除</el-link>
                  </div>
			      	  </template>
			      </el-table-column>
        </el-table>
        <div class="mt20 rx-ec" v-show="pageData.total">
		    <el-pagination
		      v-model:currentPage="pageData.queryData.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.queryData.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.total"
		      @size-change="pagination"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search } from '@element-plus/icons-vue'
    import Confirm from '@/components/Confirm.vue'
    import { ElMessage } from 'element-plus'
    import { useRouter } from 'vue-router'
    import { courseCycleList, delCourseCycle, closeCourseCycle } from '@/api/course.js'
    import utils from '@/common/utils.js';
    const confirmRef = ref()
    const router = useRouter()
    const pageData = reactive({
        formInline: {
            cycleNameLike: '',
            cycleState: '',
            cycleCourseState: '',
            cycleSellState: ''
        },
        tableData: [],
        queryData: { // 表格分页
          page: 1,
          limit: 15
        },
        total: 0,
        confirmTitle:'',
		    confirmText:'',
    })
    function addClass(type) {
        router.push({ path: '/branch/classManage/addClassPage', query :{ type: type } })
    }
    /**
     * 表单搜索
     */
    function search() {
        pageData.queryData.page = 1;
        getList()
    }
    /**
     * 获取表格数据
     */
    function getList() {
        let params = {
            page: pageData.queryData.page,
            limit: pageData.queryData.limit,
            cycleType: 12,
            cycleNameLike: pageData.formInline.cycleNameLike,
            cycleState: pageData.formInline.cycleState,
            cycleCourseState: pageData.formInline.cycleCourseState,
            cycleSellState: pageData.formInline.cycleSellState,
        }
        courseCycleList(params).then(res => {
          if(res.code == 0) {
              pageData.tableData = res.data
              pageData.total = res.count
          }
        })
    }
    /**
     * 切换每页条数
     */
    function pagination(data) {
      pageData.queryData.limit = limit;
      getList()
    }
    /**
     * 班级详情
     */
    function lookCourse(row) {
        router.push({ path: '/branch/classManage/details', query :{ id: row.encryptionId, type: 12 } })
    }
    /**
     * 编辑班级
     */
    function details(row) {
      router.push({ path: '/branch/classManage/addClassPage', query :{ id: row.encryptionId, type: 12 } })
    }
    /**
     * 完结
     */
    var courseEId = '', operateType = '';
    function endCourse(row) {
      pageData.confirmTitle = '班级完结后，未来该班将不能进行任何操作，该操作无法撤回，班级无法在售卖和排课，未开始上课的课节也将从课表删除';
      courseEId = row.encryptionId
      operateType = 'end'
      confirmRef.value.show();
    }
    /**
     * 删除班级
     */
    
    function deleCourse(row) {
        if (row) {
            pageData.confirmTitle = '确定要删除该班级吗？';
            courseEId = row.encryptionId
            operateType = 'dele'
        } else {
            pageData.confirmTitle = '该班级已有会员报名，不可删除，请先退款';
            courseEId = ''
            operateType = ''
        }
		    confirmRef.value.show();
    }
    /**
     * 确定删除
     */
    function confirmSave(){
        if (operateType == 'dele') {
            delCourseCycle({encryptionId: courseEId }).then((res)=>{
              if(res.code == 0) {
                ElMessage.success('删除成功')
		    	      getList()
              }
		        })
        }
        if (operateType == 'end') {
          closeCourseCycle({encryptionId: courseEId }).then((res)=>{
            if(res.code == 0) {
              ElMessage.success('完结成功')
		    	    getList()
            }
		      })
        }
	  }
    onMounted(() => {
        getList()
    })
</script>
<style scoped>
  .badge {
		background: #FA6400;
		box-shadow: 0 0 5px rgba(250, 100, 0, 1);
	}
	.badge-success {
		background: #44D7B6;
		box-shadow: 0 0 5px rgba(68, 215, 182, 1);
	}
	.badge-warning {
		background: #1E9FFF;
		box-shadow: 0 0 5px rgba(29, 159, 255, 1);
	}
  .box-009688{
		border: 1px solid #009688;
		color: #FFF;
		background-color: #009688;
	}
  .box-1E9FFF{
		border: 1px solid #1E9FFF;
		color: #FFF;
		background-color: #1E9FFF;
	}
  .box-FF5721{
		border: 1px solid #FF5721;
		color: #FFF;
		background-color: #FF5721;
	}
  .courseType {
    border-radius: 3px;
    width: 60px;
  }
</style>