import {postAjax,getAjax}  from "../common/axios.js"

//上课分析-日期
export const courseInfoAnalysisDate = params => {
    return postAjax('/api/data/courseInfoAnalysisDate',params);
}

//上课分析-教练
export const courseInfoAnalysisTeacher = params => {
    return postAjax('/api/data/courseInfoAnalysisTeacher',params);
}

// 教务分析-团体课约课-日期
export const eduGroupAnalysisDate = params => {
    return postAjax('/api/data/eduGroupAnalysisDate',params);
}

// 教务分析-团体课约课-课程
export const eduGroupAnalysisCourse = params => {
    return postAjax('/api/data/eduGroupAnalysisCourse',params);
}

// 教务分析-私教课约课-教练
export const eduPrivateAnalysisTeacher = params => {
    return postAjax('/api/data/eduPrivateAnalysisTeacher',params);
}

// 教务分析-私教课存课-课程
export const eduPrivateRemainAnalysisCourse = params => {
    return postAjax('/api/data/eduPrivateRemainAnalysisCourse',params);
}

// 教务分析-私教课存课-课程-详情
export const eduPrivateRemainAnalysisCourseDetail = params => {
    return postAjax('/api/data/eduPrivateRemainAnalysisCourseDetail',params);
}

// 入场数据分析
export const getEnterOutAnalysis = params => {
    return postAjax('/api/enterout/getEnterOutAnalysis',params);
}

// 消费/未消预约课程消费
export const getBookAnalysis = params => {
    return postAjax('/api/consume/getBookAnalysis',params);
}

// 消费/未消，入场卡消费
export const getEnterAnalysis = params => {
    return postAjax('/api/consume/getEnterAnalysis',params);
}

// 消费/未消，未消统计
export const getLeftAnalysis = params => {
    return postAjax('/api/consume/getLeftAnalysis',params);
}

// 消费/未消，未消详情
export const getLeftCardDetailAnalysisData = params => {
    return postAjax('/api/consume/getLeftCardDetailAnalysisData',params);
}

// 会员分析-概况信息
export const getMember = params => {
    return postAjax('/api/member/getMemberProfile',params);
}

// 会员分析-签到统计-汇总
export const getSignData = params => {
    return postAjax('/api/member/getSignDataProfile',params);
}

// 会员分析-签到统计-汇总
export const getSignDataList = params => {
    return postAjax('/api/member/getSignDataPageList',params);
}

// 售出分析-销售员
export const getSellUserAnalysis = params => {
    return postAjax('/api/sell/getSellUserAnalysis',params);
}

// 售出分析-销售员详情
export const getSellUserDetailAnalysis = params => {
    return postAjax('/api/sell/getSellUserDetailAnalysis',params);
}

// 售出分析-商品
export const getSellProductAnalysis = params => {
    return postAjax('/api/sell/getSellProductAnalysis',params);
}

// 售出分析-商品详情
export const getSellProductDetailAnalysis = params => {
    return postAjax('/api/sell/getSellProductDetailAnalysis',params);
}

// 订单分析-订单汇总
export const orderSummary = params => {
    return postAjax('/api/data/orderSummary',params);
}

// 订单分析-订单订单分析
export const orderAnalysis = params => {
    return postAjax('/api/data/orderAnalysis',params);
}

// 来源分析
export const getChannelAnalysis = params => {
    return postAjax('/api/member/getChannelAnalysis',params);
}

// 跟进分析-会员分析
export const getFollowProfile = params => {
    return postAjax('/api/follow/getFollowProfile',params);
}

// 跟进分析-客户分析
export const getFollowDealProfile = params => {
    return postAjax('/api/follow/getFollowDealProfile',params);
}

// 财务中心-营收汇总
export const revenueSummary = params => {
    return postAjax('/api/data/revenueSummary',params);
}

// 财务中心-收入统计概况
export const getIncomeProfile = params => {
    return postAjax('/api/income/getIncomeProfile',params);
}

// 财务中心-收入统计列表
export const getIncomeList = params => {
    return postAjax('/api/income/getIncomeList',params);
}

// 财务中心-支出统计概况
export const getOutputProfile = params => {
    return postAjax('/api/output/getOutputProfile',params);
}

// 财务中心-支出统计列表
export const getOutputList = params => {
    return postAjax('/api/output/getOutputList',params);
}