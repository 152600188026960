<template>
    <div class="pl15 pr15">
        <div class="dialog_nav">
            <span class="d_i_b c_p" :class="pageData.activeName == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
        </div>
        <div class="rx-cc">
          <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList"></formQuery>
          <el-button v-if="utils.getMenuRole(3190100)" @click="getList(true)"  :icon="Upload" >导出</el-button>
        </div>
        <el-table class="ww100" ref="multipleTableRef"  :data="pageData.tableData" stripe v-loading="pageData.loading">
            <el-table-column prop="courseTeacherName" label="教练姓名" ></el-table-column>
            <el-table-column prop="courseNum" label="上课节数" />
            <el-table-column>
                <template #header>
                    <span class="v_a_m">有效课节数</span>
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="即课节里有会员签到"
                      placement="top"
                    >
                      <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
                    </el-tooltip>
                </template>
                <template #default="scope">
			    	<a v-if="scope.row.validCourseNum" href="javascript:;" class="color-409EFF tool_btn" @click="lookCourse(scope.row.encryptionId)">{{scope.row.validCourseNum}}</a>
                    <span v-else>-</span>
			    </template>
            </el-table-column>
            <el-table-column prop="validCourseTimeStr" label="有效上课时长" />
            <el-table-column label="已签到人数" >
                <template #default="scope">
                    <span v-if="scope.row.confirmClassCourse">{{scope.row.confirmClassCourse}}</span>
                    <span v-else>-</span>
				</template>
            </el-table-column>
            <el-table-column prop="noSignCourseNum" label="未签到人数" />
            <el-table-column prop="consumeMoney" label="有效课节消费金额（元）" />
            <el-table-column prop="courseMoneyAvg" label="课均价值（元/节）" />
            <el-table-column prop="courseMoneyHour" show-overflow-tooltip label="时均价值（元/h）" />
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt10 rx-bc" v-show="pageData.tableData.length >= 0">
            <div>
                <span class="mr20">有效课节数：<span class="color-red mr5">{{ pageData.remark.validCourseNumAll || 0 }}</span>节</span>
                <span>消课金额总计：<span class="color-red mr5">{{ pageData.remark.consumeMoneyAll || 0 }}</span>元</span>
            </div>
            <el-pagination
              v-model:currentPage="pageData.searchForm.page"
              :page-sizes="[15,20,30,50,100]"
              :page-size="pageData.searchForm.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageData.totalCount"
              @size-change="pageSizeChange"
              @current-change="()=>{getList()}"
            >
            </el-pagination>
        </div>
        <ClassDialog v-model="pageData.addShow" :sid="pageData.coachId" @close="handleClose"/>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import ClassDialog from '@/views/branch-store/work/consume/classDialog.vue'; // 弹框
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { Upload } from '@element-plus/icons-vue'
    import { useStore } from 'vuex'
    import utils from '@/common/utils.js'
    import { list4Teacher } from '@/api/consume.js'
    import { ElMessage } from 'element-plus'
    const store = useStore();
    const router = useRouter();
    const queryOptsRef = ref()
    const pageData = reactive({
        tabeList: [{
           label: '团课/私教课',
           val: 1
        }, {
           label: '班级课程',
           val: 2
        }],
        activeName: 1,
        loading: false,
        tableData: [],
        totalCount : 0,
        searchForm : {
            page : 1,
            limit : 15
        },
        addShow: false,
        coachId:'', // 教练id
        remark: {}
        
    })
    const timerList = [
        { value : "5" , label : '最近7天' },
        { value : "30" , label : '本周' },
        { value : "20" , label : '本月' },
        { value : "21" , label : '上月' },
        { value : "-1" , label : '其他' }
    ]
    const queryOpts = reactive({});
    queryOpts['fields'] = [{
        type : 'selectMany',
        fieldName : 'teacherIds',
        placehold : '全部教练',
        options : []
    },{
        type : 'select',
        fieldName : 'timeIn',
        placehold : '',
        value : '5',
        options : timerList,
    },{
        showField : 'timeIn', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'classTimeStr',
        placeholder : '日期范围'
    }];

    utils.getDynaDict('teacherList','',function(teacherList){
        for(var i=0;i<queryOpts['fields'].length;i++){
            if(queryOpts['fields'][i]['fieldName'] == 'teacherIds'){
                queryOpts['fields'][i]['options'] = teacherList;
            }
        }
    })
     /**
     * 切换tab (数据更换)
     */
    function getContentList(val) { 
       pageData.activeName = val
       getList()
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    // 查询列表
    const getList=(isExport) => {
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        params.teacherIds = params.teacherIds && params.teacherIds.join(',') || ''
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        params.courseType = pageData.activeName == 1 ? 21 : 13
        if (params.timeIn == '-1' && !params.classTimeStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
        pageData.loading = true
        params.classTimeStr = params.timeIn == '-1' && params.classTimeStr ?  params.classTimeStr.join('-') : ''
        list4Teacher(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					var obj = {  //这些字段没值赋0
						validCourseTimeStr:'',
						consumeMoney:'',
						courseMoneyAvg:'',
						courseMoneyHour:''
					}
					pageData.tableData = res.data.map((n)=>{
						for(var k in obj){
							n[k] = n[k] || 0;
						}
						return n;
					})
					pageData.totalCount = res.count
					pageData.remark = res.remark
				}
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 查看有效课节明细
     */
	  const lookCourse=(id) =>{
        let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
	  	router.push({path:'/branch/classSection',
            query:{
                id: id,
                timeIn: params.timeIn,
                classTimeStr: params.timeIn == '-1' && params.classTimeStr ?  params.classTimeStr.join('-') : '',
                type: pageData.activeName
            }
        })
	  }
    
    /**
     * 页面缓存
     */
    onBeforeRouteLeave((to, from)=>{
	      if ( to.path === '/branch/classSection') {
	          store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	      } else {
	          store.commit('SetKeep',{keepLiveRoute : []});
	      }
	  })
    onMounted(()=> {
      getList()
    })
</script>
<style lang="stylus">
    .dialog_nav {
        background: #F2F2F2;
        margin-top: 16px;
        span {
           display: inline-block;
           width: 150px;
           height: 32px;
           line-height: 32px;
           text-align: center;
           color: #666;
        }
        .active {
           background: #03A3FF;
           color: #FFF;
           border-radius: 2px;
        }
    }
</style>


