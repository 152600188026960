<template>
    <div>
        <div class="color-999 ft12 mt16">温馨提示：展示会员在本门店的消费记录，包含预约课程消费、入场消费、期限卡按天消费、场地预约消费和手动消费</div>
        <formQuery :queryOpts="pageData.queryOpts" ref="queryOptsRef" @query="searchGetList"></formQuery>
        <el-table v-loading="loading" :data="pageData.tableData" stripe>
            <el-table-column prop="name" label="消费类型">
                <template #default="scope">
                    <span>{{ filtersType(scope.row.consumeType, 1) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="productName" label="扣费支付方式" />
            <el-table-column label="卡类型">
                <template #default="scope">
                    <span>{{ filtersType(scope.row.cardType, 2) == '全部卡类型' ? '-' : filtersType(scope.row.cardType, 2) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="消费时间" />
            <el-table-column label="本次扣除">
                <template #default="scope">
                    <div>
                        <span v-if="scope.row.consumeType == 3">{{  scope.row.consumeNum ? scope.row.consumeNum : 1  + '天'  }}</span>
                        <span v-else-if="scope.row.consumeType == 4">{{ scope.row.consumeMoney + '元' }}</span>
                        <span v-else>{{ scope.row.cardType == 3 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次' }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="consumeMoney" label="消费金额" />
            <el-table-column prop="remark" label="备注" />
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc" v-show="pageData.totalCount > 0">
            <div class="rx-c">
                <h3 class="mr20">消费次数总计：<span class="color-red">{{ pageData.remark.consumeNumAll || 0 }}</span> 次</h3>
                <h3>消费金额总计：<span class="color-red">{{ pageData.remark.consumeMoneyAll || 0 }}</span> 元</h3>
            </div>
		    <el-pagination
		        v-model:currentPage="pageData.searchForm.page"
		        :page-sizes="[15,20,30,50,100]"
		        :page-size="pageData.searchForm.limit"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="pageData.totalCount"
		        @size-change="pageSizeChange"
		        @current-change="()=>{getList()}"
		    />
		</div>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
	import { ElMessage} from 'element-plus'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
    import { orderConsume } from '@/api/intentionList.js';
    
    const propData = defineProps({
		info: Object
	})
    const queryOptsRef = ref()
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "20" , label : '最近30天' },
        { value : "-1" , label : '其他' }
    ]
    const consumeTypeList = [
        { value : "" , label : '全部消费类型' },
        { value : "1" , label : '预约课程消费' },
        { value : "2" , label : '入场消费' },
        { value : "3" , label : '按天自动消费' },
        { value : "4" , label : '场地预约消费' },
        // { value : "5" , label : '手动消费' }
    ]
    const cardTypeList = [
        { value : "" , label : '全部卡类型' },
        { value : "1" , label : '课程卡' },
        { value : "2" , label : '入场卡' },
        { value : "3" , label : '储值卡' },
        { value : "4" , label : '体验卡' },
        { value : "5" , label : '课程' }
    ]
    const pageData = reactive({
        loading: false,
        tableData: [], // 表格数据
        totalCount: 0, // 表格总条数
        searchForm: {
            page: 1,
            limit: 15
        },
        remark: {},
        queryOpts: {
            fields: [{
                type : 'select',
                fieldName : 'consumeType',
                placehold : '消费类型',
                options : consumeTypeList,
                value:''
            },{
                type : 'select',
                fieldName : 'cardType',
                placehold : '卡类型',
                options : cardTypeList,
                value: ''
            },{
                type : 'select',
                fieldName : 'timeIn',
                placehold : '消费时间',
                value : '5',
                options : timerList
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'querydate',
                placeholder : '日期范围'
            }]
        }
    })
    /**
     * @param (String, Number) value-类型, type-1 消费类型 2-卡
     * 表格字段过滤过滤展示
     */
	function filtersType(value, type) {
		let arry = type == 1 ? consumeTypeList : cardTypeList
        let text = ''
        arry.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取预约记录
     */
    function getList() {
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        if (params.timeIn == '-1' && !params.querydate) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        params.createTimeStr = params.timeIn == '-1' && params.querydate ?  params.querydate.join('-') : ''
		params.encryptionCardMyId = propData.info.encryptionId
        pageData.loading = true
        orderConsume(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
                pageData.remark = res.remark
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    onMounted(() => {
		getList()
	})
</script>
<style type='css/style' scoped>

</style>