<template>
	<div class="theme_info" v-show="storeData.moduleStatus">
		<div class="rx-sc mb10">
			<h3 class="ft16 mr20">功能入口</h3>
			<span class="color-999 ft12">可自定义选择模块进行展示</span>
		</div>
		<div class="store_form mt20">
			<div class="rx-ss mb12">
				<span class="w100">选择背景色：</span>
				<div class="rx-ss">
					<div class="t_a_c pt15 pb15 pr15 pl15" @click="storeData.bgColor = 1" :class="{themActive:storeData.bgColor == 1,use:storeData.bgUse == 1}">
						<img class="w100" src="@/assets/applet/appBg1.png" >
						<p>简介白</p>
					</div>
					<div class="t_a_c pt15 pb15 pr15 pl15" @click="storeData.bgColor = 2" :class="{themActive:storeData.bgColor == 2,use:storeData.bgUse == 2}">
						<img class="w100" src="@/assets/applet/appBg2.png" >
						<p>炫酷黑</p>
					</div>
				</div>
			</div>
		</div>
		<div class="store_form mt20">
			<div class="rx-ss mb12">
				<span class="w100">选择主题色：</span>
				<ul class="colorList bg-fff pt10 pb10 pl10 pr10" style="max-width: 450px;">
					<li :class="{themActive:index == storeData.active,use:storeData.colorUse == index}" @click="storeData.active = index" v-for="(item,index) in storeData.list" :key="index">
						<div :style="{backgroundColor:item.color}"></div>
						<p>{{item.name}}</p>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
</script>

<style scoped lang="stylus">
	.theme_info{
		.use{
			position relative;
			
		}
		.use::after{
			position absolute;
			content '使用中';
			border-radius 100px;
			border 1px solid #409EFF;
			color #409EFF;
			background-color white;
			padding 0px 5px;
			top 10px;
			right 0px;
			font-size 12px;
		}
		li{
			display inline-block;
			padding 20px;
			div{
				width 65px;
				height 65px;
				position relative;
			}
			div::after{
				content '';
				position absolute;
				right 0;
				bottom 0;
				width 45px;
				height 45px;
				border-radius 9px 0 0 0;
				background-color rgba(255,255,255,.5);
			}
			p{
				text-align center;
			}
		}
		.themActive{
			background-color #EFF6FF;
			border 1px dashed #409EFF;
		}
	}
</style>
