import {postAjax,getAjax}  from "../common/axios.js"

//总店员工列表
export const getUpayOrderLog = params => {
    return postAjax('/api/upayOrderLog/selectAllRecord',params)
}
export const getUpayOrderLogDetail = params => {
    return postAjax('/api/upayOrderLog/selectRecordDetail',params)
}

export const exportAll = params => {
    return postAjax('/api/upayOrderLog/exportAll',params)
}

export const getDownloadBill = params => {
    return postAjax('/api/upayOrderLog/export',params)
}

