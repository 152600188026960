<template>
	<div class="p20 bg-fff">
		<!-- 查询 -->
		<div class="ww100 rx-wsc" ref="queryBox">
			<div class="mb16 rx-sc">
				<div class="mr16">发布渠道</div>
				<div class="mr16">
					<el-select class="" v-model="pageData.searchForm.storeId" placeholder="请选择">
						<el-option label="全部" value="" selected></el-option>
						<el-option v-for="item in pageData.storeList" :key="item.sid" :label="item.storeName" :value="item.sid"></el-option>
					 </el-select>
				</div>
				<el-button type="primary" @click="searchTable" :icon="Search">查询</el-button>
			</div>
		</div>
		<!-- 按钮区域 -->
		<div class="ww100 rx-sc">
			<el-button class="mb16" type="primary" @click="()=>{addOrUpdate()}" v-if="utils.getMenuRole(3170100)">新增目的</el-button>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="checkChange" v-loading="pageData.laoding">
				<!-- <el-table-column type="selection" width="55" :selectable="(row)=>{return row.userType != 2}" /> -->
			    <el-table-column prop="name" label="训练目的" />
			    <el-table-column prop="totalNum" label="标记课程数" />
			    <el-table-column prop="realName" label="创建人" />
			    <el-table-column prop="storeName" label="发布渠道" />
				<el-table-column label="操作" >
					<template #default="scope">
						<div v-if="scope.row.channel == 2">
							<el-link class="t_d_u" :underline="false" type="primary" v-if="utils.getMenuRole(3170101)" @click="addOrUpdate(scope.row)">编辑</el-link>
							<el-link class="ml10 t_d_u" :underline="false" type="danger" plain v-if="utils.getMenuRole(3170102)" @click="dele(scope.row)">删除</el-link>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="searchSizeTable"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
		<!-- 新增-编辑弹窗 -->
		<el-dialog
		    v-model="pageData.addUpdateObjectShow"
		    :title="pageData.addUpdateObjectTitle"
			width="350px"
		    :before-close="()=>{pageData.addUpdateObjectShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="pt30 pb30">
		    	<div class="query_item ml20 mr20 rx-c">
		    		<div class="query_tip rx-ec w130"><span class="color-red">*</span>目的名称</div>
		    		<el-input v-model="pageData.addFormData.name" placeholder="输入关键字" />
		    	</div>
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.addUpdateObjectShow = false">取消</el-button>
		        	<el-button type="primary" @click="addOrUpdateSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="deleSave"/>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { ElMessage } from 'element-plus'
	import QRCode from 'qrcodejs2'
	import { DArrowRight, Connection, Close, Search } from '@element-plus/icons-vue'
	import CommonTip from '@/components/CommonTip.vue'
	import Confirm from '@/components/Confirm.vue'
	import utils from '@/common/utils.js'
	import { getFrameworkAllList } from '@/api/staff.js'
	import { trainPurposeList, trainPurposeInsert, trainPurposeUpdate, trainPurposeDelete } from '@/api/course.js';
	const pageData = reactive({
		laoding: false,
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			storeId:''
		},
		storeList:[],
		addFormData:{
			encryptionId:'',
			name:'',
		},
		addUpdateObjectShow:false,
		addUpdateObjectTitle:false,
		confirmTitle:'',
		confirmText:'',
		nowTdRow:'',
	})
	// 新增目的
	function addOrUpdate(row){
		pageData.addUpdateObjectShow = true;
		pageData.addUpdateObjectTitle = row ? '编辑目的' : '新增目的';
		pageData.addFormData.encryptionId = row ? row.encryptionId : '';
		pageData.addFormData.name = row ? row.name : '';
	}
	//新增保存
	function addOrUpdateSave(){
		if(!pageData.addFormData.name){
			ElMessage({
				type: 'warning',
				message: '请输入目的名称',
			})
			return false;
		}
		if(pageData.addUpdateObjectTitle == '编辑目的'){
			trainPurposeUpdate(pageData.addFormData).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '编辑成功',
					})
					pageData.addUpdateObjectShow = false;
					pageData.searchForm.page = 1;
					searchTable();
				}
			})
		} else {
			trainPurposeInsert(pageData.addFormData).then((res)=>{
		    	if(res.code == 0){
		    		ElMessage({
		    			type: 'success',
		    			message: '保存成功',
		    		})
		    		pageData.addUpdateObjectShow = false;
		    		pageData.searchForm.page = 1;
		    		searchTable();
		    	}
		    })
		}
		
	}
	//所属架构搜索下拉框
	getFrameworkAllList({ ifBranchStore: 1 }).then((res)=>{
		if(res.code == 0){
			pageData.storeList = res.data;
		}
	})
	function searchSizeTable(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//获取目的列表数据
	getList();
	function getList(){
		pageData.laoding = true
		trainPurposeList(pageData.searchForm).then((res)=>{
			pageData.laoding = false
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		}).catch(() => {
			pageData.laoding = false
		})
	}
	
	const confirmRef = ref();
	//删除目的
	function dele(row){
		pageData.nowTdRow = row;
		pageData.confirmTitle = '确定要删除该目的吗？';
		confirmRef.value.show();
	}
	function deleSave(){
		trainPurposeDelete({
			encryptionId:pageData.nowTdRow.encryptionId
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '删除成功',
				})
				pageData.searchForm.page = 1;
				searchTable();
			}
		})
	}
	// 控制查询区域显示隐藏
	var queryBox = ref(null)
	var queryItemShowAll = ref(9);
	// 触发隐藏和展开
	function triggerShowItemAll(){
		for(var i=queryBox.value.children.length - 1;i>0;i--){
			if(queryBox.value.children[i].className.indexOf('query_item') > -1){
				queryBox.value.children[i].style.display= queryItemShowAll.value == 1 ? "none" : "";
			}
			if(queryItemShowAll.value == 1 && queryBox.value.offsetHeight < 60){
				break;
			}
		}
		queryItemShowAll.value = queryItemShowAll.value == 1 ? 0 : 1;
	}
	onMounted(()=>{
		utils.initQuery(queryItemShowAll,queryBox);
	});
</script>

<style scoped lang="stylus">
	@media screen and (max-width: 1366px) {
	    .h400{
			height 300px !important;
		}
	}
.el-dialog__body{padding:0px !important;}
	.shuttle_box{
		border 1px solid #EBEEF5;
		border-radius 4px;
		.add_title{
			background-color #F4F9FF;
			padding 10px;
			text-align center;
		}
		.add_checked{
			width 100%;
			padding 20px;
			li{
				background-color #F5F5F5;
				border-radius 2px;
				border 1px solid #D6D6D6;
				cursor pointer;
				padding 5px 10px;
			}
			li:hover{
				border 1px solid #409EFF;
				i{
					color red;
				}
			}
		}
		
	}
</style>
