import {postAjax,getAjax}  from "../common/axios.js"

//获取课表课节详情
export const getCourseInfoTable = params => {
    return getAjax('/api/course/getCourseListPlus',params);
}

// 排课获取冲突教练列表
export const getTeacherConflictList = params => {
    return postAjax('/api/course/getTeacherConflictList',params);
}
// 团课排课
export const courseGroupCycleArrange = params => {
    return postAjax('/api/course/courseGroupCycleArrange',params);
}
// 复制团课排期
export const copyWeekCourse = params => {
    return postAjax('/api/course/courseCopy',params);
}
// 课表列表
export const getCourseInfoPageList = params => {
    return getAjax('/api/course/getCourseListPagePlus',params);
}
// 批量删除课节
export const deleteCourseInfoBatch = params => {
    return postAjax('/api/course/courseDeleteMore',params);
}
// 批量关闭课节
export const closeCourseInfoBatch = params => {
    return postAjax('/api/course/courseCloseBatch',params);
}
// 新增教练日程
export const saveTeacherSchedule = params => {
    return postAjax('/api/sysUserSchedule/insert',params);
}
// 教练日程列表
export const sysUserScheduleList = params => {
    return postAjax('/api/sysUserSchedule/list',params);
}
// 课表关闭
export const courseClose = params => {
    return postAjax('/api/course/courseClose',params);
}
// 课表删除
export const courseDelete = params => {
    return postAjax('/api/course/courseDelete',params);
}
// 课表复制
export const courseCopyOne = params => {
    return postAjax('/api/course/courseCopyOne',params);
}
// 课节详请-预约会员
export const getCourseInfoList = params => {
    return postAjax('/api/course/getCourseInfoList',params);
}
// 删除教练日程
export const deleteBatch = params => {
    return postAjax('/api/sysUserSchedule/deleteBatch',params);
}
// 课节详情-会员编辑备注
export const courseInfoRemark = params => {
    return postAjax('/api/course/courseInfoRemark',params);
}
// 名额是否已占用
export const getCourseUnpay = params => {
    return postAjax('/api/member/appointment/getCourseUnpay',params);
}

// 班级删除课节-批量
export const batchCourseDelete = params => {
    return postAjax('/api/course/batchCourseDelete',params);
}

// 班级关闭课节-批量
export const courseCloseBatch = params => {
    return postAjax('/api/course/courseCloseBatch',params);
}