<template>
    <div class="manage p20">
        <div v-if="pageData.emptyShow" class="pt100">
            <el-empty description="还未创建分组，暂无场地信息，请先创建分组">
                <el-button type="primary" @click="addGrouping">创建分组</el-button>
            </el-empty>
        </div>
        <div v-else class="rx-c hh100">
            <div class="manage_left bg-F8FCFD mr25 pl35 pr35 hh100 w390">
                <div class="left_top">
                    <div class="color-333 fwb ft16 mt20 mb10">分组列表</div>
                    <div class="color-999 ft12 mb16">点击模块可对组内场地进行查看和设置。</div>
                    <div class="mb16">
                        <el-button type="primary" @click="addGrouping" v-if="utils.getMenuRole(3350101)">新增分组</el-button>
                    </div>
                </div>
                <div class="left_content">
                    <el-scrollbar max-height="610px">
                        <div class="mb10 bg-fff rx-bc scrollbar_div c_p" :class="{active:item.id == pageData.active}" v-for="item in pageData.grouping" :key="item.id" @click="seePlaceList(item, item.id)">
                            <div>{{ item.groupName }}</div>
                            <el-dropdown trigger="click" @command="handleCommand" v-if="utils.getMenuRole(3350102) || utils.getMenuRole(3350103)">
                                <span class="el-dropdown-link">
                                  <a href="javascript:;" class="t_d_u color-409EFF">操作</a>
                                </span>
                                <template #dropdown>
                                  <el-dropdown-menu>
                                    <el-dropdown-item :command="{item: item, command:'edit'}" v-if="utils.getMenuRole(3350102)">编辑</el-dropdown-item>
                                    <el-dropdown-item :command="{item: item, command:'remove'}" v-if="utils.getMenuRole(3350103)">删除</el-dropdown-item>
                                  </el-dropdown-menu>
                                </template>
                              </el-dropdown>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
            <div class="manage_right w0 flex1 hh100">
                <div class="mb20">
                    <span class="ft16 fwb color-225080">分组基本规则：</span>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="journal">操作日志></a>
                </div>
                <div class="bg-F8FCFD mb20 pl25 pt30 pb30 pr25 rx-bc ww100">
                    <div class="color-333 fwb">预约日期：
                        <span v-if="pageData.topForm.orderType == 0">不限制</span>
                        <span v-else>{{pageData.topForm.orderStartDate ? pageData.topForm.orderStartDate +'-'+ pageData.topForm.orderEndDate : '-'  }}</span>
                    </div>
                    <div class="color-333 fwb">预约时间：
                        <span v-if="pageData.topForm.startTime && pageData.topForm.endTime">{{ pageData.topForm.startTime ? pageData.topForm.startTime +'-' + pageData.topForm.endTime : '-' }}</span>
                        <span v-else>-</span>
                    </div>
                    <div class="color-333 fwb">每场时长：{{ pageData.topForm.perSceneTime == 60 ? '一' : '半' }}小时</div>
                    <div class="color-333 fwb">起订时长：{{ pageData.topForm.minHour  || '-'}}小时</div>
                    <div class="color-333 fwb">循环：
                        <span v-if="pageData.topForm.week && pageData.topForm.week != 0">
                            <span v-for="(item, index) in pageData.topForm.week.split(',')" :key="index">{{ filtersType(item) }}
                                <span v-if="index != pageData.topForm.week.split(',').length-1">、</span>
                            </span>
                        </span>
                        <span v-else>每天</span>
                    </div>
                </div>
                <div class="mb20">
                    <el-button type="primary" @click="addplace" v-if="utils.getMenuRole(3350104)">新增场地</el-button>
                    <el-button type="primary" v-if="utils.getMenuRole(3350112)" @click="setPrice(1)">场地定价</el-button>
                    <el-button @click="cogradient" v-if="utils.getMenuRole(3350110)">关联已有场地</el-button>
                    <el-button @click="allOrder" v-if="utils.getMenuRole(3350111)">批量新增场地</el-button>
                    <el-button @click="setPrice(2)" v-if="utils.getMenuRole(3350113)">节假日规则</el-button>
                </div>
                <div>
                    <el-table :data="pageData.tableList" stripe>
			        	<el-table-column prop="roomName" label="场地名称" width="120" show-overflow-tooltip>
                            <template  #default="scope">
                                <a v-if="utils.getMenuRole(3350105)" href="javascript:;" class="color-409EFF tool_btn" @click="edit(scope.row)">{{ scope.row.roomName }}</a>
                                <span v-else>{{ scope.row.roomName }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="containNum" label="定价时间&计费规则" show-overflow-tooltip>
                            <template #default="scope">
                                <div v-if="!scope.row.relateWeekStr || !scope.row.relateWeekStr.length">未开启</div>
                                <div v-else>
                                    <div class="o_h" :class="[ scope.row.show ? '' : 'h20']">
                                        <div class="o_w_t" v-for="(item, index) in scope.row.relateWeekStr" :key="index">
                                            <span>{{ item.weeks }};{{ item.ruleName }}</span>
                                        </div>
                                    </div>
                                    <a v-if="scope.row.relateWeekStr.length>1" href="javascript:;" class="tool_btn color-409EFF" @click="scope.row.show = !scope.row.show">{{ scope.row.show ? '收起' : '查看' }}更多></a>
                                </div>
                                
                            </template>
                        </el-table-column>
                        <el-table-column prop="containNum" label="节假日&计费规则" show-overflow-tooltip>
                            <template #default="scope">
                                <div v-if="!scope.row.relateHolidayStr || !scope.row.relateHolidayStr.length">未开启</div>
                                <div v-else>
                                    <div class="o_h" :class="[ scope.row.show1 ? '' : 'h20']">
                                        <div class="o_w_t" v-for="(item, index) in scope.row.relateHolidayStr" :key="index">
                                            {{ item.holidayName }}（{{ item.holidayStr }}）;{{ item.ruleName }}
                                        </div>
                                    </div>
                                    <a v-if="scope.row.relateHolidayStr.length>1" href="javascript:;" class="tool_btn color-409EFF" @click="scope.row.show1 = !scope.row.show1">{{ scope.row.show1 ? '收起' : '查看' }}更多></a>
                                </div>
                            </template>
                        </el-table-column>
			        	<el-table-column label="会员线上预约" width="110" v-if="utils.getMenuRole(3350114)">
			        		<template #default="scope">
                                <el-switch
                                    v-model="scope.row.stuOrderOnline"
                                    size="large"
                                    :width="60"
                                    :active-value="1"
                                    :inactive-value="0"
                                    inline-prompt
                                    active-text="开启"
                                    inactive-text="关闭"
                                    disabled 
                                    @click="changeval(scope.row,1)"
                                />
                            </template>
			        	</el-table-column>
			        	<el-table-column v-if="utils.getMenuRole(3350107)"  width="110">
                            <template #header>
					        	<div class="rx-sc">
					        		<span>场地状态</span>
					        		<div>
					        			<el-tooltip
					        					class="box-item"
					        					effect="dark"
					        					placement="top-start"
					        				  >
					        				<template #content>
					        					<div style="max-width: 200px;">场地状态关闭或未设置计费规则时，线上和前台线下进行场地预约时均不会出现该场地</div>
					        				</template>
					        				<div class="pt5">
					        					<unicon width="20px" name="question-circle" fill="#606266"></unicon>
					        				</div>
					        			</el-tooltip>
					        		</div>
					        	</div>
					        </template>
			        		<template #default="scope">
                                <el-switch
                                    v-model="scope.row.state"
                                    size="large"
                                    :width="60"
                                    :active-value="1"
                                    :inactive-value="0"
                                    inline-prompt
                                    active-text="开启"
                                    inactive-text="关闭"
                                    disabled 
                                    @click="changeval(scope.row,2)"
                                />
                            </template>
			        	</el-table-column>
			        	<el-table-column label="操作" width="120">
			        		<template #default="scope">
                                <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12"  v-if="utils.getMenuRole(3350115)" @click="orderSetting(scope.row.encryptionId)">查看详情</a>
			        			<a href="javascript:;" class="t_d_u color-EE4B36 ft12" v-if="utils.getMenuRole(3350106)" @click="remove(scope.row.encryptionId)">删除</a>
			        		</template>
			        	</el-table-column>
			        </el-table>
                    <div class="mt20 rx-ec">
		                <el-pagination
		                    v-model:currentPage="pageData.page"
		                    :page-sizes="[15,20,30,50,100]"
		                    :page-size="pageData.limit"
		                    layout="total, sizes, prev, pager, next, jumper"
		                    :total="pageData.count"
		                    @size-change="classPageSizeChange"
                            @current-change="pageChange"
		                >
		                </el-pagination>
		            </div>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="pageData.dialogVisible"
            :title="pageData.title"
            width="530px"
            :before-close="handleClose"
          >
            <div class="p20 h500">
                <div v-if="pageData.title == '编辑场地分组'" class="bg-FEE8E8 mb20 pl10 pt10 pr10 pb10">
                    <div class="mb10 color-666 ft12">提示：修改【每场时长】、【循环设置】、【可预约时间】信息后，新的规则将在当前最后一天产生场次预定信息的第二天开始生效，请谨慎修改！</div>
                    <div class="color-666 ft12">举例：该分组下的场地最后一次存在预约记录的时间是8月1号，则修改规则后，8月2号日期会按照新规则预约，8月1号之前仍按照上次的规则预约。</div>
                </div>
                <el-form ref="ruleFormRef" :model="pageData.ruleForm" :rules="rules" label-width="120px"  class="demo-ruleForm pb15">
                    <el-form-item label="场地分组名称:" prop="groupName">
                        <el-input v-model="pageData.ruleForm.groupName" placeholder="请输入（10字内）" maxlength="10" />
                    </el-form-item>
                    <el-form-item label="分组图片:">
                        <div class="color-999 ft12 mb10">图片建议尺寸750*500px，支持jpg、png、bmp格式，不超过2M图片</div>
                        <div v-if="!pageData.ruleForm.groupPic" class="upload w140 h95">
                            <CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
						    	<div>
						    		<img class="w30" src="@/assets/addBank.png" alt="">
						    		<div class="color-333">上传图片</div>
						    	</div>
						    </CommonUpload>
                        </div>
                        <div v-else>
                            <div class="w140 h95 uploadLogo bdr4">
								<img class="ww100 hh100" :src="pageData.ruleForm.groupPic" alt="">
								<div class="p">
									<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
										<p class="ft12 reUpload">重新上传</p>
									</CommonUpload>
								</div>
								<img @click="deleBanner" class="w20 dele_icon bg-fff" src="@/assets/icon_error.png" alt="">
							</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="每场时长:" prop="perSceneTime">
                        <el-radio-group v-model="pageData.ruleForm.perSceneTime" @change="changePerSceneTime">
                            <el-radio :label="30">
                                <span class="color-333">半小时</span>
                            </el-radio>
                            <el-radio :label="60">
                                <span  class="color-333">一小时</span>
                            </el-radio>
                        </el-radio-group>
                        <span class="mt5 ml10">
					    	<el-tooltip
					    	    class="box-item"
					    	    effect="dark"
					    	    placement="top"
					    	  >
                                <template #content>
                                    控制该分组下的场地预约每场次时长，<br />
                                    例如每场时长一小时，则预约时可选择9:00-10:00时间段；<br />
                                    若半小时，则预约时可选择9:00-9:30时间段 
                                </template>
					    	    <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
					    	</el-tooltip> 
					    </span>
                    </el-form-item>
                    <el-form-item label="起订时长:" prop="minHour">
                        <el-input class="w200" v-if="pageData.ruleForm.perSceneTime == 60" v-model="pageData.ruleForm.minHour" oninput="value=value.replace(/^(0+)|[^\d]+/g,'')">
                            <template #append>小时</template>
                        </el-input>
                        <el-input class="w200" v-else v-model="pageData.ruleForm.minHour" @change="checkInputIntFloat">
                            <template #append>小时</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="预约日期：" prop="orderType">
                        <el-radio-group v-model="pageData.ruleForm.orderType" @change="changeorderType">
                            <el-radio :label="0">
                                <span class="color-333 d_i_b w50">不限制</span> 
                            </el-radio>
                            <el-radio :label="1">
                                <span class="color-333">自定义</span> 
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="ml120 mb20" v-if="pageData.ruleForm.orderType == 1">
                        <el-date-picker
                            v-model="pageData.ruleForm.orderDateStr"
                            type="daterange"
                            range-separator="至"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :disabled-date="pageData.disabledDate"
                        />
                    </div>
                    <el-form-item label="循环设置：" prop="cycleType">
                        <el-radio-group v-model="pageData.ruleForm.cycleType">
                            <el-radio :label="0">
                                <span class="color-333">每天</span>
                            </el-radio>
                            <el-radio :label="1">
                                <span class="color-333">自定义</span>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <div class="ml120 mb20" v-if="pageData.ruleForm.cycleType == 1">
                        <el-checkbox-group v-model="pageData.checkList">
                            <el-checkbox v-for="item in pageData.weekList" :key="item.value" :label="item.value">
                                <span class="color-333">{{ item.name }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <el-form-item label="可预约时间:">
                        <div class="rx-c">
                            <el-time-select
                                v-model="pageData.ruleForm.startTime"
                                :max-time="pageData.ruleForm.endTime"
                                class="mr-4"
                                placeholder="开始时间"
                                start="00:00"
                                :step="pageData.ruleForm.perSceneTime == 30 ? '00:30' : '01:00'"
								:end="pageData.ruleForm.perSceneTime == 30 ? '23:30' : '23:00'"
                            />
                            <span class="pl20 pr20">至</span>
                            <el-time-select
                                v-model="pageData.ruleForm.endTime"
                                :min-time="pageData.ruleForm.startTime"
                                placeholder="结束时间"
                                start="00:00"
                                :step="pageData.ruleForm.perSceneTime == 30 ? '00:30' : '01:00'"
                                :end="pageData.ruleForm.perSceneTime == 30 ? '23:30' : '23:00'"
                            />
                        </div>
                    </el-form-item>
                    <el-form-item label="排序:">
                        <el-input v-model="pageData.ruleForm.orderNum" oninput="value=value.replace(/[^0-9]/g,'')"  maxlength="4" />
                        <div class="ft12 color-999">数字越大越靠前</div>
                    </el-form-item>
                    <el-form-item label="分组描述:">
                        <el-input
                          v-model="pageData.ruleForm.remark"
                          maxlength="30"
                          show-word-limit
                          :rows="4"
                          type="textarea"
                          placeholder="请输入备注（30字内）"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取 消</el-button>
                    <el-button type="primary" @click="onSubmit">确 定</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogPlace"
            :title="pageData.placeTitle"
            width="530px"
            :before-close="handleClosePlace"
          >
            <div class="p20 pr30">
                <el-form ref="formRef" :model="pageData.dialogForm" :rules="rules1" label-width="120px">
                    <el-form-item label="场地名称：" prop="roomName">
                        <el-input v-model="pageData.dialogForm.roomName" maxlength="10" placeholder="请输入（10字内）" />
                    </el-form-item>
                    <el-form-item label="是否连场：" prop="ifContinue">
                        <el-radio-group v-model="pageData.dialogForm.ifContinue">
                            <el-radio :label="0">
                                <span class="color-333">否</span>
                            </el-radio>
                            <el-radio :label="1">
                                <span class="color-333">是</span>
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item v-if="pageData.dialogForm.ifContinue == 1" label="连场时间：">
                        <div class="rx-c mb20" v-for="(item, index) in pageData.dialogForm.evenFieldList" :key="index">
                            <div class="rx-bc w325">
                                <el-time-select
                                    v-model="item.startTime"
                                    :max-time="item.endTime"
                                    class="w150"
                                    placeholder="开始时间"
                                    :step="pageData.ruleForm.perSceneTime == 30 ? '00:30': '00:60'"
                                    :start="index == 0 ? pageData.topForm.startTime: pageData.dialogForm.evenFieldList[index-1].endTime"
                                    :end="pageData.topForm.endTime"
                                />
                                <span class="pl20 pr20">至</span>
                                <el-time-select
                                    v-model="item.endTime"
                                    :min-time="item.startTime"
                                    class="w150"
                                    placeholder="结束时间"
                                    :step="pageData.ruleForm.perSceneTime == 30 ? '00:30': '00:60'"
                                    :start="index == 0 ? pageData.topForm.startTime: pageData.dialogForm.evenFieldList[index-1].endTime"
                                    :end="pageData.topForm.endTime"
                                />
                            </div>
                            <img class="w15 h15 ml20 c_p" src="@/assets/delete.png" v-if="index != 0" @click="deleteRulesList(index)">
                        </div>
                        <el-button type="primary" :icon="Plus" @click="addEvenField">新增一条</el-button>
                    </el-form-item>
                    <el-form-item label="会员端预约：">
                         <el-switch
                            v-model="pageData.dialogForm.stuOrderOnline"
                            size="large"
                            :width="60"
                            :active-value="1"
                            :inactive-value="0"
                            inline-prompt
                            active-text="开启"
                            inactive-text="关闭"
                        />
                    </el-form-item>
                    <el-form-item label="可容纳人数：">
                        <el-input v-model.number="pageData.dialogForm.containNum" maxlength="4" max="9999" id="txt" oninput="value=value.replace(/[^0-9]/g,'')">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input
                          v-model="pageData.dialogForm.remark"
                          :rows="4"
                          type="textarea"
                          placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleClosePlace">取 消</el-button>
                <el-button type="primary" @click="onSubmitPlace">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogCogradient"
            title="关联场地"
            width="530px"
            :before-close="handleCloseCogradient"
          >
            <div class="p20">
                <el-form ref="cogradientRef" :model="pageData.cogradientForm" :rules="cogradientRules" label-width="100px">
                    <el-form-item label="场地分组：">{{ pageData.topForm.groupName }}</el-form-item>
                    <el-form-item label="关联场地：" prop="checkList">
                        <div class="ft12 color-999">仅展示【机构中心】中创建后未被分组的场地</div>
                        <el-checkbox-group v-model="pageData.cogradientForm.checkList">
                            <el-checkbox v-for="item in pageData.allPlaceList" :key="item.encryptionId" :label="item.encryptionId">
                                <span class="color-333">{{ item.roomName }}</span>
                            </el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleCloseCogradient">取 消</el-button>
                <el-button type="primary" @click="onSubmitCogradient">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogAllPlace"
            title="批量新增场地"
            width="530px"
            :before-close="handleAllPlace"
          >
            <div class="p20">
                <el-form ref="allPlaceRef" :model="pageData.allPlaceForm" :rules="allPlaceRules" label-width="140px">
                    <el-form-item label="场地名称前缀：" prop="roomName">
                        <el-input class="w300" v-model="pageData.allPlaceForm.roomName" maxlength="10" placeholder="请输入（10字内）" />
                    </el-form-item>
                    <el-form-item label="起始编号：" prop="startNum">
                        <el-input class="w300" v-model="pageData.allPlaceForm.startNum" placeholder="请输入" @input="(v) => (pageData.allPlaceForm.startNum = v.replace(/[^\d]/g,''))" />
                    </el-form-item>
                    <el-form-item label="场地数量：" prop="roomCount">
                        <el-input class="w300" v-model="pageData.allPlaceForm.roomCount" placeholder="请输入" maxlength="2" @input="(v) => (pageData.allPlaceForm.roomCount = v.replace(/[^\d]/g,''))" />
                    </el-form-item>
                    <el-form-item label="会员端预约：">
                         <el-switch
                            v-model="pageData.allPlaceForm.stuOrderOnline"
                            size="large"
                            :width="60"
                            :active-value="1"
                            :inactive-value="0"
                            inline-prompt
                            active-text="开启"
                            inactive-text="关闭"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleAllPlace">取 消</el-button>
                <el-button type="primary" @click="onSubmitAllPlace">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogPrice"
            :title="pageData.dialogPriceTitle"
            width="630px"
            :before-close="handlePrice"
          >
            <template #default>
                <div class="p20 pl30 pr30 h600">
                    <el-form class="pb20" ref="priceRef" :model="pageData.priceForm" :rules="priceRules" label-width="100px">
                        <el-form-item label="场地分组：">
                            {{ pageData.topForm.groupName }}
                        </el-form-item>
                        <el-form-item label="场地选择：" prop="checkList">
                            <el-checkbox v-model="pageData.checkAll" :indeterminate="pageData.isIndeterminate" @change="handleCheckAllChange" >全选</el-checkbox>
                            <el-checkbox-group v-model="pageData.priceForm.checkList" @change="handleCheckedCitiesChange">
                                <el-checkbox v-for="item in pageData.priceForm.groupList" :key="item.id" :label="item">
                                    <span class="color-333">{{ item.roomName }}</span>
                                </el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                        <div class="pt10" v-if="pageData.dialogPriceTitle == '添加定价'">
                            <div class="rx-c mb10" v-for="(res, index) in pageData.priceForm.weekPriceList" :key="index">
                                <div class="dialogDiv_left flex1">
                                    <el-form-item label="定价时间：" :prop="'weekPriceList.'+ index +'.weeks'" :rules="priceRules.weeks">
                                        <el-checkbox-group v-model="res.weeks" @change="(val) => { changeBox(val, index, 0) }">
                                            <el-checkbox v-for="item in res.weekList" :key="item.value" :label="item.value">
                                                <span class="color-333">{{ item.name }}</span>
                                            </el-checkbox>
                                        </el-checkbox-group>
                                    </el-form-item>
                                    <el-form-item label="计费规则：" :prop="'weekPriceList.'+ index +'.weeksRule'" :rules="priceRules.weeksRule">
                                        <div class="w300">
                                            <el-select v-model="res.weeksRule">
                                                <el-option
                                                    v-for="item in pageData.priceForm.ruleList"
                                                    :key="item.id"
                                                    :label="item.ruleName"
                                                    :value="item.id"
                                                />
                                            </el-select>
                                        </div>
                                    </el-form-item>
                                </div>
                                <div class="cx-c">
                                    <div class="dialogDiv_right bg-FA2026">
                                        <a href="javascript:;" class="t_d_u color-EE4B36 ft12" @click="delNewHoliday(index, 0)">移除本条</a>
                                    </div>
                                    <div class="dialogDiv_right bg-03A3FF">
                                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="addNewHoliday(0)">新增一条</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pt10" v-else>
                            <el-form-item label="节假日启用：">
                                 <el-switch
                                    v-model="pageData.priceForm.ifHoliday"
                                    size="large"
                                    :width="60"
                                    :active-value="1"
                                    :inactive-value="0"
                                    inline-prompt
                                    active-text="开启"
                                    inactive-text="关闭"
                                />
                                <el-tooltip
			    		    	    class="box-item"
			    		    	    effect="dark"
			    		    	    placement="top"
			    		    	  >
                                    <template #content>
                                        启用：则可单独去配置节假日的计费规则，<br />
                                        当节假日规则和常规规则同时开启，且包含同一个日期时，优先走节假日的计费规则
                                    </template>
			    		    	    <img class="w15 h15 ml15" src="@/assets/wenhao.png" >
			    		    	</el-tooltip>
                                <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12 ml15" @click="holiday">假日管理模板</a>
                            </el-form-item>
                            <div v-show="pageData.priceForm.ifHoliday == 1">
                                <div class="rx-c mb10" v-for="(res, index) in pageData.priceForm.holidayList" :key="index">
                                    <div class="dialogDiv_left flex1">
                                        <el-form-item label="选择节假：" :prop="'holidayList.'+ index +'.holidays'" :rules="priceRules.holidays">
                                            <el-checkbox-group v-model="res.holidays" @change="(val) => { changeBox(val, index, 1) }">
                                                <el-checkbox v-for="item in res.holidayList" :key="item.value" :label="item.value">
                                                    <span class="color-333">{{ item.name }}</span>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </el-form-item>
                                        <el-form-item label="计费规则：" :prop="'holidayList.'+ index +'.holidaysRule'" :rules="priceRules.holidaysRule">
                                            <div class="w300">
                                                <el-select v-model="res.holidaysRule">
                                                    <el-option
                                                        v-for="item in pageData.priceForm.ruleList"
                                                        :key="item.id"
                                                        :label="item.ruleName"
                                                        :value="item.id"
                                                    />
                                                </el-select>
                                            </div>
                                        </el-form-item>
                                    </div>
                                    <div class="cx-c">
                                        <div class="dialogDiv_right bg-FA2026">
                                            <a href="javascript:;" class="t_d_u color-EE4B36 ft12" @click="delNewHoliday(index, 1)">移除本条</a>
                                        </div>
                                        <div class="dialogDiv_right bg-03A3FF">
                                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="addNewHoliday(1)">新增一条</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </el-form>
                </div>
                <el-dialog
                   v-model="pageData.priceInner"
                   width="810px"
                   title="法定假日模板"
                   append-to-body
                >
                    <div class="p20 pl35 pr65">
                        <div class="mb20">
                            <div class="ft15 fwb color-4E4E4E" v-for="(item, index) in pageData.holidayYear" :key="index">
                               <span class="c_p" :class="[pageData.holidayActive == index ? 'color-409EFF' : '']" @click="activeVal(index, item.year)">{{ item.year }}年假日安排</span>
                               <span class="bg-ccc d_i_b v_a_m" v-if="index != pageData.holidayYear.length-1" style="width:2px;height: 16px; margin-left: 7px"></span>
                            </div>
                        </div>
                        <el-table :data="pageData.holidayList" style="width: 100%">
                            <el-table-column prop="holidayName" label="法定假日" />
                            <el-table-column label="节日时间" width="250">
                                <template #default="scope">
                                    <div v-if="!scope.row.show" class="c_p" @click="scope.row.show = !scope.row.show">
                                        <el-icon :size="12" class="mr5 v_a_m">
                                          <EditPen />
                                        </el-icon>
                                        <span>{{ scope.row.holidayStr }}</span>
                                    </div>
                                    <div v-else>
                                        <el-date-picker
                                            style="width: 220px;"
                                            v-model="scope.row.orderData"
                                            type="daterange"
                                            range-separator="至"
                                            format="YYYY-MM-DD"
                                            value-format="YYYY-MM-DD"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :disabled-date="pageData.disabledDate1"
                                            @change="((val)=>{setHoliday(val, scope.row)})"
                                        />
                                    </div>
				                </template>
                            </el-table-column>
                            <el-table-column prop="realName" label="天数" align="center">
                                <template #default="scope">
                                    <div>共{{ scope.row.dayNum }}天</div>
				                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <template #footer>
                        <span class="dialog-footer">
                          <el-button @click="pageData.priceInner = false">取 消</el-button>
                          <el-button type="primary" @click="pageData.priceInner = false">确 定</el-button>
                        </span>
                    </template>
                </el-dialog>
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handlePrice">取 消</el-button>
                <el-button type="primary" @click="onSubmitPrice">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogJournal"
            title="分组规则操作日志"
            width="890px"
          >
            <div class="p20">
                <el-table :data="pageData.JournalData" stripe style="width: 100%">
                    <el-table-column prop="createByName" label="操作人" width="180" />
                    <el-table-column prop="createTime" label="操作时间" width="180" />
                    <el-table-column prop="contents" label="操作内容">
                        <template #default="scope">
                            <div v-for="(item, index) in scope.row.contents" :key="index">{{ item }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="startDayStr" width="180">
                        <template #header>
                            <span class="v_a_m">新规则执行时间</span>
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              placement="top"
                            >
                                <template #content>
                                    新规则执行时间，表示从今天开始是按照本次修改的规则内容生成新<br />
                                    的场地排期预约表，若多次修改规则后新规则执行时间相同，则按照<br />
                                    最后一次修改内容生成新的排期 
                                </template>
                                <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.dialogJournal = false">取 消</el-button>
                <el-button type="primary" @click="pageData.dialogJournal = false">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogDetail"
            :title="'【' + pageData.detailTile + '】详情'"
            width="810px"
          >
            <template #default>
                <div class="pl35 pr35">
                    <CommonTitle text="基本信息"/>
                    <div class="ml20">
                        <div class="mb20">
                            <span>容纳人数：{{ pageData.detailForm.containNum }}人</span>
                            <span class="ml200">连场时间：{{ pageData.detailForm.evenFieldList.length ? pageData.detailForm.evenFieldList.join() : '-' }}</span>
                        </div>
                        <div>
                            会员端线上预约：
                            <el-switch
                                v-model="pageData.detailForm.stuOrderOnline"
                                size="large"
                                :width="60"
                                :active-value="1"
                                :inactive-value="0"
                                inline-prompt
                                active-text="开启"
                                inactive-text="关闭"
                                disabled 
                                @click="changeval(pageData.detailForm, 1,'详情')"
                            />
                        </div>
                    </div>
                    <CommonTitle text="价格信息" v-if="(pageData.detailForm.relateWeekStr && pageData.detailForm.relateWeekStr.length)||(pageData.detailForm.relateHolidayStr && pageData.detailForm.relateHolidayStr.length)"/>
                    
                    <div class="ml20">
                        <div v-if="pageData.detailForm.relateWeekStr && pageData.detailForm.relateWeekStr.length">
                            <div class="color-225080 fwb mb20">定价时间&计费规则</div>
                            <div class="mb20" v-for="(item, index) in pageData.detailForm.relateWeekStr" :key="index">
                                <span>{{ item.weeks }};{{ item.ruleName }}</span>
                                <el-icon :size="12" class="ml20 color-03A3FF c_p v_a_m" v-if="utils.getMenuRole(3350116)" @click="detailInner(1, item.ruleId)">
                                  <EditPen />
                                </el-icon>
                            </div>
                        </div>
                        <div v-if="pageData.detailForm.relateHolidayStr && pageData.detailForm.relateHolidayStr.length">
                            <div class="color-225080 fwb mb20">
                                节假日规则：已开启
                                <el-icon :size="12" class="ml20 color-03A3FF c_p v_a_m" @click="detailInner(2)" v-if="utils.getMenuRole(3350116)">
                                  <EditPen />
                                </el-icon>
                            </div>
                            <div>
                                <div class="mb20"  v-for="(item, index) in pageData.detailForm.relateHolidayStr" :key="index">
                                    {{ item.holidayName }}（{{ item.holidayStr }}）;{{ item.ruleName }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <CommonTitle text="备注信息" v-if="pageData.detailForm.remark"/>
                    <div class="h50 ml20">{{ pageData.detailForm.remark }}</div>
                </div>
                <el-dialog
                   v-model="pageData.detailInner"
                   width="630px"
                   :title="pageData.detailInnerTile"
                   append-to-body
                >
                    <div class="pt35 pb35 pl35 pr35 h500">
                        <div v-if="pageData.detailInnerTile == '修改定价'">
                            <div class="rx-ss">
                                <div class="w100 l_h32"><span class="color-red mr5">*</span>定价时间：</div>
                                <el-checkbox-group v-model="pageData.detailInnerForm.weeks">
                                    <el-checkbox v-for="item in pageData.weekList" :key="item.value" :label="item.value" disabled>
                                        <span class="color-333">{{ item.name }}</span>
                                    </el-checkbox>
                                </el-checkbox-group>
                            </div>
                            <div class="rx-ss mt20">
                                <div class="w100 l_h32"><span class="color-red mr5">*</span>计费规则：</div>
                                <div class="w300">
                                    <el-select v-model="pageData.detailInnerForm.weeksRule">
                                        <el-option
                                            v-for="item in pageData.priceForm.ruleList"
                                            :key="item.id"
                                            :label="item.ruleName"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="rx-c mb10" v-for="(res, index) in pageData.detailInnerForm.holidayList" :key="index">
                                <div class="dialogDiv_left flex1" style="padding-left: 20px">
                                    <div class="rx-ss mb36">
                                        <div class="w100 l_h32"><span class="color-red mr5">*</span>选择节假：</div>
                                        <div class="w350">
                                            <el-checkbox-group v-model="res.holidays" @change="(val) => { changeBox(val, index, 2) }">
                                                <el-checkbox v-for="item in res.holidayList" :key="item.value" :label="item.value">
                                                    <span class="color-333">{{ item.name }}</span>
                                                </el-checkbox>
                                            </el-checkbox-group>
                                        </div>
                                    </div>
                                    <div class="rx-ss">
                                        <div class="w100 l_h32"><span class="color-red mr5">*</span>计费规则：</div>
                                        <div class="w300">
                                            <el-select v-model="res.holidaysRule">
                                                <el-option
                                                    v-for="item in pageData.priceForm.ruleList"
                                                    :key="item.id"
                                                    :label="item.ruleName"
                                                    :value="item.id"
                                                />
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                                <div class="cx-c">
                                    <div class="dialogDiv_right bg-FA2026">
                                        <a href="javascript:;" class="t_d_u color-EE4B36 ft12" @click="delNewHoliday(index,2)">移除本条</a>
                                    </div>
                                    <div class="dialogDiv_right bg-03A3FF">
                                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="addNewHoliday(2)">新增一条</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="pageData.detailInner = false">取 消</el-button>
                            <el-button type="primary" @click="editDetailInner">确 定</el-button>
                        </span>
                    </template>
                </el-dialog>
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.dialogDetail = false">取 消</el-button>
                <el-button type="primary" @click="pageData.dialogDetail = false">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { ElMessage } from 'element-plus'
    import { Plus, EditPen } from '@element-plus/icons-vue'
    import Confirm from '@/components/Confirm.vue'
    import utils from '@/common/utils.js';
    import CommonUpload from '@/components/CommonUpload.vue';
    import CommonTitle from '@/components/CommonTitle.vue'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { useStore } from 'vuex'
    import { sportRoomGroupList, sportRoomGroupInsert, sportRoomGroupUpdate, sportRoomGroupDelete, sportRoomUpdateGroup, listYear,
            listData, listUpdate, addRulePrice, settingRuleList, updateWeekPrice, updateHolidayPrice, sportActionLogList
    } from '@/api/appstore.js';
    import { sportRoomList, sportRoomUpdateState, sportRoomInsert, sportRoomInfo, sportRoomUpdate, sportRoomDelete, insertBatch } from '@/api/place.js';
    const router = useRouter();
    const store = useStore();
    const confirmRef = ref ()
    const ruleFormRef = ref()
    const multipleTableRef = ref()
    const formRef = ref()
    const cogradientRef = ref()
    const allPlaceRef = ref()
    const priceRef = ref()
    const pageData = reactive({
        dialogCogradient: false, // 场地同步弹框
        cogradientForm:{
            encryptionId: '',
            checkList: []
        }, // 场地同步表单
        allPlaceList: [], // 所有场地列表
        dialogVisible: false, // 分组弹框
        title: '', // 弹框标题
        grouping: [], // 分组列表
        tableList: [], // 场地列表
        topForm: {},
        page: 1,
        limit: 15,
        count: 0,
        show: false,
        show1: false,
        dialogPlace: false, // 场地弹框
        placeTitle: '', // 场地弹框标题
        dialogForm: {
            roomName:'',
            containNum: '',
            evenFieldList: [{startTime: '', endTime:''}],
            remark: '',
            ifContinue: 0,
            stuOrderOnline: 1,
        }, // 场地弹框表单
        confirmTitle:'',
		confirmText:'',
        emptyShow: false, // 空状态
        active: 0,
        dialogAllPlace: false, // 批量新增场地弹框
        allPlaceForm: {
            roomName: '',
            startNum: '',
            roomCount: '',
            stuOrderOnline: 1
        }, // 批量新增场地表单
        ruleForm: {
            groupName: '',
            groupPic: '',
            perSceneTime: 60,
            startTime: '',
            endTime: '',
            minHour: '',
            orderType: 0,
            cycleType: 0,
            orderNum: 1,
            remark: ''
        }, // 弹框表单
        weekList: [
            {name: '周一', value: 1, show: false},
            {name: '周二', value: 2, show: false},
            {name: '周三', value: 3, show: false},
            {name: '周四', value: 4, show: false},
            {name: '周五', value: 5, show: false},
            {name: '周六', value: 6, show: false},
            {name: '周日', value: 7, show: false},
        ],
        holidayList: [
            {name:'元旦', value:1},
            {name:'春节', value:2},
            {name:'清明节', value:3},
            {name:'劳动节', value:4},
            {name:'端午节', value:5},
            {name:'中秋节', value:6},
            {name:'国庆节', value:7},
        ],
        holidayYear: [],
        year: '',
        dialogPrice: false, // 添加定价弹框
        dialogPriceTitle: '', // 定价弹框标题
        priceForm: {
            checkList:[],
            groupList:[],
            weekPriceList: [{
                weeks: [],
                weeksRule: '',
                weekList: [
                    {name: '周一', value: 1},
                    {name: '周二', value: 2},
                    {name: '周三', value: 3},
                    {name: '周四', value: 4},
                    {name: '周五', value: 5},
                    {name: '周六', value: 6},
                    {name: '周日', value: 7},
                ]
            }],
            holidayList: [{
                holidays: [],
                holidaysRule: '',
                holidayList: [
                    {name:'元旦', value:1},
                    {name:'春节', value:2},
                    {name:'清明节', value:3},
                    {name:'劳动节', value:4},
                    {name:'端午节', value:5},
                    {name:'中秋节', value:6},
                    {name:'国庆节', value:7},
                ]
            }],
            groupId: '',
            ifHoliday: 0,
            ruleList:[]
        }, // 添加订单表单
        checkAll: false, // 定价全选
        isIndeterminate: false, // 定价半选
        priceInner: false, // 节假日模板
        holidayActive: 0,
        dialogJournal: false, // 操作日志弹框
        JournalData: [], // 操作日志数据
        dialogDetail: false, // 详情弹框
        detailTile: '', // 详情弹框标题
        detailForm: {}, // 详情弹框表单
        detailInner: false, // 修改定价弹框
        detailInnerTile: '',
        detailInnerForm: {
            weeks: [],
            weeksRule: '',
            holidayList: []
        },
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 8.64e7 
        },
        disabledDate1(time) {
            let year = Number(pageData.year)
            return time.getTime() > new Date(year+'.12.31').getTime() || time.getTime() < new Date(year+'.01.01').getTime()
        }
    })
    const validateMoney = (rule,value,callback) =>{
        if(value > 999999.99) {
            allback(new Error('金额最大不超过999999.99'));
        } else{
          callback();
        }
    };
    const rules = reactive({
        groupName: [{ required: true, message: '请输入分组名称', trigger: 'blur' }],
        perSceneTime: [{ required: true, message: '请选择时长', trigger: 'change'}],
        minHour: [{ required: true, message: '请输入起订时长', trigger: 'blur' }],
        cycleType: [{ required: true, message: '请选择循环设置', trigger: 'change'}],
    })
    const rules1 = reactive({
        roomName: [{ required: true, message: '请输入场地名称', trigger: 'blur' }],
        ifContinue: [{ required: true, message: '请选择是否连场', trigger: 'change'}]
    })
    const cogradientRules = reactive({
        encryptionId: [{ required: true, message: '请选择场地', trigger: 'change' }],
        checkList:  [{ type: 'array', required: true, message: '请选择场地', trigger: 'change' }]
    })
    const allPlaceRules = reactive({
        roomName: [{ required: true, message: '请输入场地名称前缀', trigger: 'blur' }],
        startNum: [{ required: true, message: '请输入起始编号', trigger: 'blur' }],
        roomCount: [{ required: true, message: '请输入场地编号', trigger: 'blur' }]
    })
    const priceRules =  reactive({
        groupId: [{ type: 'array', required: true, message: '请选择分组', trigger: 'change' }],
        checkList: [{ type: 'array', required: true, message: '请选择场地', trigger: 'change' }],
        weeks: [{ type: 'array', required: true, message: '请选择定价时间', trigger: 'change' }],
        weeksRule: [{ required: true, message: '请选择计费规则', trigger: 'change' }],
        holidays: [{ type: 'array', required: true, message: '请选择节假日', trigger: 'change' }],
        holidaysRule: [{ required: true, message: '请选择节假日计费规则', trigger: 'change' }],
    })
    /**
     * @param (String) value-类型 
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        pageData.weekList.forEach((item) => {
            if (item.value == value) {
                text = item.name
            }
        })
        return text
    }
    /**
     * 删除连场
     */
    function deleteRulesList(index) {
        pageData.dialogForm.evenFieldList.splice(index,1);
    }
    /**
     * 计费规则列表
     */
    function getrulesList(){
        let params = {
            page: 1,
            limit: 9999
        }
        settingRuleList(params).then(res => {
            if (res.code == 0) {
                pageData.priceForm.ruleList = res.data
            }
        })
    }
    /**
     * 添加定价切换分组
     */
    function changeGroup(val) {
        let params = {
            groupId: val,
            purposeOrder: 1,
            limit: 99999,
            page: 1
        }
        sportRoomList(params).then(res => {
            if(res.code == 0){
                pageData.priceForm.checkList = []
                pageData.checkAll = false
                pageData.isIndeterminate = false
                pageData.priceForm.groupList = res.data
            }
        })
        
    }
    /**
     * 修改定价
     */
    function detailInner(type, ruleId) {
        getrulesList()
        if (type == 1) {
            pageData.detailInnerTile = '修改定价'
            let arr = []
            pageData.detailForm.relateWeekList.forEach(item => {
                if (item.ruleId == ruleId) {
                    arr.push(item.week)
                }
            })
            pageData.detailInnerForm.weeks = arr
            pageData.detailInnerForm.weeksRule = ruleId
        } else {
             
            let arr = []
            pageData.detailForm.relateHolidayList.forEach(item => {
                let obj = {
                    holidays: item.holidayType.split(',').map(Number),
                    holidaysRule: item.ruleId,
                    holidayList: [
                        {name:'元旦', value:1},
                        {name:'春节', value:2},
                        {name:'清明节', value:3},
                        {name:'劳动节', value:4},
                        {name:'端午节', value:5},
                        {name:'中秋节', value:6},
                        {name:'国庆节', value:7},
                    ]
                }
                arr.push(obj)
            })
            pageData.detailInnerForm.holidayList=arr
            pageData.detailInnerTile = '修改节假日定价'
        }
        pageData.detailInner = true
    }
    /**
     * 确定修改详情的定价
     */
    function editDetailInner() {
        if (pageData.detailInnerTile == '修改定价') {
            let arr = []
            pageData.detailInnerForm.weeks.forEach(item => {
                arr.push(pageData.detailInnerForm.weeksRule)
            })
            let params = {
                encryptionId: modifyPlaceId,
                weeks: pageData.detailInnerForm.weeks.join(),
                weeksRule: arr.join()
            }
            updateWeekPrice(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('修改成功')
                    pageData.detailInner = false
                    orderSetting(modifyPlaceId)
                }
            })
        } else {
            let arr = [],arr2=[]
            pageData.detailInnerForm.holidayList.forEach(item => {
                arr.push(item.holidays.join('-'))
                arr2.push(item.holidaysRule)
            })
            if (arr.indexOf('') > -1 ) {
                ElMessage.warning('存在节假日未选择')
                return
            }
            if (arr2.indexOf('') > -1) {
                ElMessage.warning('存在计费规则未选择')
                return
            }
            let params = {
                encryptionId: modifyPlaceId,
                holidays: arr.join(),
                holidaysRule: arr2.join()
            }
            updateHolidayPrice(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('修改成功')
                    pageData.detailInner = false
                    orderSetting(modifyPlaceId)
                }
            })
        }
    }
    /**
     * 定价选择互斥
     */
    function changeBox(val, index, type) {
        if (type == 1) {
            pageData.priceForm.holidayList.forEach((item, v) => {
                if (index != v) {
                    item.holidays = item.holidays.filter((item) => {
                        return val.indexOf(item) < 0;
                    })
                }
            })
        } else if(type == 2) {
            pageData.detailInnerForm.holidayList.forEach((item, v) => {
                if (index != v) {
                    item.holidays = item.holidays.filter((item) => {
                        return val.indexOf(item) < 0;
                    })
                }
            })
        } else {
            pageData.priceForm.weekPriceList.forEach((item, v) => {
                if (index != v) {
                    item.weeks = item.weeks.filter((item) => {
                        return val.indexOf(item) < 0;
                    })
                }
            })
        }
       
    }
    /**
     * 新增一条假日计费规则
     */
    function addNewHoliday(type) {
        if (type == 1) {
            pageData.priceForm.holidayList.push({
                holidays: [],
                holidaysRule: '',
                holidayList: [
                    {name:'元旦', value:1},
                    {name:'春节', value:2},
                    {name:'清明节', value:3},
                    {name:'劳动节', value:4},
                    {name:'端午节', value:5},
                    {name:'中秋节', value:6},
                    {name:'国庆节', value:7},
                ]
            })
        } else if (type == 0) {
            pageData.priceForm.weekPriceList.push({
                weeks: [],
                weeksRule: '',
                weekList: [
                    {name: '周一', value: 1},
                    {name: '周二', value: 2},
                    {name: '周三', value: 3},
                    {name: '周四', value: 4},
                    {name: '周五', value: 5},
                    {name: '周六', value: 6},
                    {name: '周日', value: 7}
                ]
            })
        } else {
            pageData.detailInnerForm.holidayList.push({
                holidays: [],
                holidaysRule: '',
                holidayList: [
                    {name:'元旦', value:1},
                    {name:'春节', value:2},
                    {name:'清明节', value:3},
                    {name:'劳动节', value:4},
                    {name:'端午节', value:5},
                    {name:'中秋节', value:6},
                    {name:'国庆节', value:7},
                ]
            })
        }
    }
    /**
     * 删除一条假日计费规则
     */
    function delNewHoliday(index,type) {
        if (type == 0) {
            if (pageData.priceForm.weekPriceList.length == 1) {
                ElMessage.warning('必须存在一条')
                return
            }
            pageData.priceForm.weekPriceList.splice(index,1);
        } else if(type == 1) {
            if (pageData.priceForm.holidayList.length == 1) {
                ElMessage.warning('必须存在一条')
                return
            }
            pageData.priceForm.holidayList.splice(index,1);
        } else {
            if (pageData.detailInnerForm.holidayList.length == 1) {
                ElMessage.warning('必须存在一条')
                return
            }
            pageData.detailInnerForm.holidayList.splice(index,1);
        }
        
    }
    /**
     * 打开定价弹框
     */
    function setPrice(type) {
        getrulesList()
        changeGroup(pageData.topForm.id)
        pageData.dialogPriceTitle = type == 1 ? '添加定价' : '节假日规则'
        pageData.priceForm = {
            checkList:[],
            groupList:[],
            weekPriceList: [{
                weeks: [],
                weeksRule: '',
                weekList: [
                    {name: '周一', value: 1},
                    {name: '周二', value: 2},
                    {name: '周三', value: 3},
                    {name: '周四', value: 4},
                    {name: '周五', value: 5},
                    {name: '周六', value: 6},
                    {name: '周日', value: 7},
                ]
            }],
            holidayList: [{
                holidays: [],
                holidaysRule: '',
                holidayList: [
                    {name:'元旦', value:1},
                    {name:'春节', value:2},
                    {name:'清明节', value:3},
                    {name:'劳动节', value:4},
                    {name:'端午节', value:5},
                    {name:'中秋节', value:6},
                    {name:'国庆节', value:7},
                ]
            }],
            groupId: '',
            ifHoliday: 0,
            ruleList:[]
        }, // 添加订单表单
        pageData.dialogPrice = true
    }
    /**
     * 打开假日模板
     */
    function holiday() {
        listYear().then(res => {
            if (res.code == 0) {
                pageData.holidayYear = res.data
                pageData.priceInner = true
                getHolidayData(res.data[0].year)
            }
        })
    }
    /**
     * 获取节假日数据
     */
    function getHolidayData(val) {
        pageData.year = val
        listData({year: val}).then(res => {
            if (res.code == 0) {
                res.data.forEach(item => {
                    item.show = false
                })
                pageData.holidayList = res.data
            }
        })
    }
    /**
     * 切换假日年份
     */
    function activeVal(val, year) {
        pageData.holidayActive = val
        getHolidayData(year)
    }
    /**
     * 更改假日信息
     */
    function setHoliday(val, row) {
       let startTime =  Number(val[0].substring(5,7)) +'月'+ Number(val[0].substring(8,10)) +'日',
            endTime = Number(val[1].substring(5,7)) +'月'+ Number(val[1].substring(8,10)) +'日'
        let params = {
            encryptionId: row.encryptionId,
            holidayStr: startTime +'~'+endTime
        }
        listUpdate(params).then(res => {
            if (res.code == 0) {
                ElMessage.success('修改成功')
                row.show = false
                getHolidayData(row.year)
            }
        })
    }
    /**
     * 操作日志
     */
    function journal(){
        sportActionLogList({encryptionId:pageData.topForm.encryptionId}).then(res =>{
            if (res.code == 0) {
                pageData.JournalData = res.data
            }
        })
        pageData.dialogJournal = true
    }
    /**
     * 限制起订时间
     */
    function checkInputIntFloat(val) {
        //正的，小数部分以5结尾的浮点数  或  正整数
        var reg = /^[1-9]\d*\.[5]$|0\.[5]$|^[1-9]\d*$/;
        if('' != val.replace(reg, '')){
            val = val.match(reg) == null  ?  '' : val.match(reg);
        }
        if (val === '') {
            ElMessage.warning('请输 大于0且是0.5的倍数的数字')
        }
    }
    /**
     * 切换分组时间间隔
     */
    function changePerSceneTime(val) {
        if (pageData.title != '编辑场地分组') {
            pageData.ruleForm.startTime = ''
            pageData.ruleForm.endTime = ''
        }
    }
    /**
     * 获取分组列表
     */
    function getGroupList() {
        sportRoomGroupList().then(res => {
            if (res.code == 0) {
                pageData.grouping = res.data;
                 if(res.data.length > 0){
                     pageData.topForm = res.data[0];
                     pageData.active = res.data[0].id;
    	    	      seePlaceList( res.data[0],res.data[0].id);
    	           }
                
                pageData.emptyShow = res.data.length ? false: true
            }
        })
    }
    /**
     * 新增一条连场
     */
    function addEvenField() {
        pageData.dialogForm.evenFieldList.push({startTime: '', endTime:''})
    }
    /**
     * 场地同步
     */
    function cogradient(){
        let params = {
            page:1,
            limit: 9999,
            purposeClass: 1,
            purposeOrder: 0
        }
        sportRoomList(params).then((res) => {
            if (res.code == 0) {
                pageData.allPlaceList = res.data
                pageData.dialogCogradient = true
            }
        })
    }
    /**
     * 取消场地同步
     */
    function handleCloseCogradient() {
        cogradientRef.value.resetFields()
        pageData.dialogCogradient = false
    }
    /**
     * 确定提交
     */
    function onSubmitCogradient() {
        cogradientRef.value.validate((valid) => {
            if (valid) {
                let params = {
                    encryptionId: pageData.cogradientForm.checkList.join(','),
                    groupId: pageData.topForm.id 
                }
                sportRoomUpdateGroup(params).then(res => {
                    if (res.code == 0) {
                        ElMessage.success('同步成功')
                        pageData.dialogCogradient = false
                        getList(pageData.topForm.id)
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 取消批量新增场地
     */
    function handleAllPlace(){
        allPlaceRef.value.resetFields()
        pageData.dialogAllPlace = false
    }
    /**
     * 确定提交批量新增场地表单
     */
    function onSubmitAllPlace() {
         allPlaceRef.value.validate((valid) => {
            if (valid) {
                let params = {
                    roomName: pageData.allPlaceForm.roomName,
                    groupId: pageData.topForm.id,
                    purposeClass: 1,
                    purposeOrder: 1,
                    stuOrderOnline: pageData.allPlaceForm.stuOrderOnline,
                    startNum: pageData.allPlaceForm.startNum,
                    roomCount: pageData.allPlaceForm.roomCount,
                    state: 1
                }
                insertBatch(params).then(res => {
                    if (res.code == 0) {
                        ElMessage.success('新增成功')
                        pageData.dialogAllPlace = false
                        getList(pageData.topForm.id);
                    }
                })
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 定价全选
     */
    function handleCheckAllChange  (val) {
        pageData.priceForm.checkList = val ? pageData.priceForm.groupList : []
        pageData.isIndeterminate = false
    }
    function handleCheckedCitiesChange (value) {
        const checkedCount = value.length
        pageData.checkAll = checkedCount ===  pageData.priceForm.groupList.length
        pageData.isIndeterminate = checkedCount > 0 && checkedCount <  pageData.priceForm.groupList.length
    }
    /**
     * 取消添加定价
     */
    function handlePrice() {
        priceRef.value.resetFields()
        pageData.dialogPrice = false
    }
    /**
     * 确定提交添加定价表单
     */
    function onSubmitPrice() {
		console.log(1)
        priceRef.value.validate((valid) => {
		console.log(valid)
            if (valid) {
                if ( pageData.dialogPriceTitle == '节假日规则') {
                    pageData.confirmTitle = pageData.priceForm.ifHoliday == 1 ? '开启后，所选场地假日规则均按本次新规则更新，且立即生效，是否确定保存？' : '关闭后，所选场地假日规则均会关闭假日规则，且立即生效，是否确定保存？'
		            confirmRef.value.show();
                    courseType = 'holiday'
                } else {
                    let checkList = [],weeks = [],weeksRule=[]
                    pageData.priceForm.checkList.forEach(item => {
                        checkList.push(item.id)
                    })
                    pageData.priceForm.weekPriceList.forEach(item => {
                        weeks.push(item.weeks.join('-'))
                        weeksRule.push(item.weeksRule)
                    })
                    let params = {
                        groupId: pageData.topForm.id,
                        roomIds: checkList.join(','),
                        weeks: weeks.join(','),
                        weeksRule: weeksRule.join(','),
                    }
                    addRulePrice(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('添加成功')
                            pageData.dialogPrice = false
                            getList(pageData.topForm.id);
                        }
                    })
                }
                
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 点击分组查看场地
     */
    function seePlaceList(row, index) {
        pageData.topForm = row
        if (row.startTime && row.startTime.indexOf(':') == -1) {
            pageData.topForm.startTime = row.startTime.substr(0,2)+':'+row.startTime.substr(2,4)
            pageData.topForm.endTime = row.endTime.substr(0,2)+':'+row.endTime.substr(2,4)
        }
        getList(row.id)
        pageData.active = index
    }
    /**
     * 每页条数更改
     */
    function classPageSizeChange(limit) {
        pageData.limit = limit
        getList(pageData.topForm.id)
    }
    /**
     * 页数更改
     */
    function pageChange(val) {
        pageData.page = val
        getList(pageData.topForm.id)
    }
    /**
     * 获取分组下的场地列表
     */
    function getList(id){
        let params = {
            groupId: id,
            purposeOrder: 1,
            limit: pageData.limit,
            page: pageData.page
        }
        sportRoomList(params).then(res => {
            if(res.code == 0){
                pageData.tableList = res.data
                pageData.count = res.count
            }
        })
    }
    /**
     * 操作下拉点击选项事件
     */
    let encryptionId = '', confirmType = true
    function handleCommand(row) {
        encryptionId = row.item.encryptionId
        if (row.command == 'edit'){
            pageData.ruleForm= {
                groupName: row.item.groupName,
                groupPic: row.item.groupPic,
                perSceneTime: row.item.perSceneTime,
                minHour: row.item.minHour,
                orderType: row.item.orderType,
                cycleType: row.item.cycleType,
                orderNum: row.item.orderNum,
                remark: row.item.remark,
            }
            if (row.item.startTime && row.item.startTime.indexOf(':') == -1) {
                pageData.ruleForm.startTime= row.item.startTime.substr(0,2)+':'+row.item.startTime.substr(2,4)
                pageData.ruleForm.endTime= row.item.endTime.substr(0,2)+':'+row.item.endTime.substr(2,4)
            } else {
                pageData.ruleForm.startTime= row.item.startTime
                pageData.ruleForm.endTime= row.item.endTime
            }
            pageData.perSceneTime = row.item.perSceneTime
            pageData.checkList = row.item.week == 0 ? [] : row.item.week.split(',').map(Number),
            pageData.title = '编辑场地分组'
            pageData.dialogVisible = true
        }
        if (row.command == 'remove') {
            if (pageData.tableList.length) {
                pageData.confirmTitle = '请先将该分组下的场地选择其他分组后再删除';
                confirmType = false
            } else {
                pageData.confirmTitle = '确定删除该场地分组吗';
                confirmType = true
            }
            confirmRef.value.show();
        }
    }
    /**
     * 批量场地设置
     */
    function allOrder() {
        pageData.allPlaceForm = {
            roomName: '',
            startNum: '',
            roomCount: '',
            stuOrderOnline: 1
        }
        pageData.dialogAllPlace = true
    }
    let modifyPlaceId = ''
    function orderSetting(row) {
        modifyPlaceId = row
        sportRoomInfo({encryptionId: modifyPlaceId }).then(res => {
            if (res.code == 0) {
                pageData.detailForm = Object.assign({}, res.data)
                pageData.detailTile = res.data.roomName
                pageData.detailForm.evenFieldList = []
                if (res.data.ifContinue == 1) {
                    res.data.continueList.forEach(item => {
                        pageData.detailForm.evenFieldList.push(item.startEndTime)
                    })
                }
                pageData.dialogDetail = true
            }
        })
    }
    /**
     * 拉起新增场地
     */
    function addplace() {
        pageData.placeTitle =  '新增场地'
        pageData.dialogForm= {
            roomName:'',
            containNum: '',
            evenFieldList: [{startTime: '', endTime:''}],
            remark: '',
            ifContinue: 0,
            stuOrderOnline: 1
        }
        pageData.dialogPlace = true
    }
    /**
     * 编辑场地
     */
    let editPlaceId = ''
    function edit(row) {
        pageData.placeTitle = '编辑场地'
        editPlaceId = row.encryptionId
        sportRoomInfo({encryptionId: editPlaceId }).then(res => {
            if (res.code == 0) {
                pageData.dialogForm = Object.assign({}, res.data)
                pageData.dialogForm.evenFieldList = []
                if (res.data.ifContinue == 1) {
                    res.data.continueList.forEach(item => {
                        pageData.dialogForm.evenFieldList.push({
                            startTime: item.startEndTime.split('-')[0],
                            endTime: item.startEndTime.split('-')[1]
                        })
                    })
                }
                pageData.dialogPlace = true
            }
        })
    }
    /**
     * 取消场地弹框
     */
    function handleClosePlace() {
        formRef.value.resetFields()
        pageData.dialogPlace = false
    }
    /**
     * 确定提交场地弹框
     */
    function onSubmitPlace() {
        pageData.dialogForm.containNum = document.getElementById("txt").value
        formRef.value.validate((valid) => {
            if (valid) {
                let params = {
                    roomName: pageData.dialogForm.roomName,
                    containNum: pageData.dialogForm.containNum,
                    remark: pageData.dialogForm.remark,
                    groupId: pageData.topForm.id,
                    purposeClass: 1,
                    purposeOrder: 1,
                    stuOrderOnline: pageData.dialogForm.stuOrderOnline,
                    ifContinue: pageData.dialogForm.ifContinue
                }
                if (pageData.dialogForm.ifContinue) {
                    let arr = []
                    pageData.dialogForm.evenFieldList.forEach(item => {
                        arr.push(item.startTime + '-' + item.endTime)
                    })
                    params.continueTimes = arr.join(';')
                }
                if (pageData.placeTitle == '新增场地') {
                    sportRoomInsert(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            pageData.dialogPlace = false
                            getList(pageData.topForm.id);
                        }
                    })
                } else {
                    params.encryptionId = editPlaceId
                    sportRoomUpdate(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('编辑成功')
                            pageData.dialogPlace = false
                            getList(pageData.topForm.id);
                        }
                    })
                }
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 状态开关
     */
    function changeval(row, type, name) {
        console.log(row)
        let params = {
            encryptionId: name ? modifyPlaceId : row.encryptionId,
            state: type == 1 ? row.state : row.state ? 0 : 1,
            stuOrderOnline: type == 2 ? row.stuOrderOnline : row.stuOrderOnline ? 0 : 1,
        }
        sportRoomUpdateState(params).then((res) => {
            if (res.code == 0) {
                ElMessage.success('设置成功')
                if (name) {
                    orderSetting(modifyPlaceId)
                }
                getList(pageData.topForm.id);
            }
        })
    }
    /**
     * 删除场地提示
     */
    var courseEId = '', courseType;
    function remove(id) {
        pageData.confirmTitle = '确定要删除该场地吗？';
		confirmRef.value.show();
		courseEId = id;
        courseType = 'remove'
    }
    /**
     * 确定删除场地分组
     */
    function confirmSave(){
        if (courseType == 'remove') {
            sportRoomDelete({ encryptionId: courseEId }).then((res)=>{
		    	if(res.code == 0){
		    		ElMessage({
		    			type: 'success',
		    			message: '删除成功',
		    		})
                    courseType = ''
		    		getList(pageData.topForm.id);
		    	}
		    })
        } else if (courseType == 'holiday') {
            let checkList = [],weeks = [],weeksRule=[]
                    pageData.priceForm.checkList.forEach(item => {
                        checkList.push(item.id)
                    })
                    let params = {
                        groupId: pageData.topForm.id,
                        roomIds: checkList.join(','),
                        ifHoliday:pageData.priceForm.ifHoliday
                    }
                    if (pageData.priceForm.ifHoliday == 1) {
                        let arr = pageData.priceForm.holidayList
                        let arr1 = [],arr2 = []
                        for (let i=0; i < arr.length; i++) {
                            if (!arr[i].holidays.length) {
                                ElMessage.warning('请选择节假日')
                                return
                            }
                            let string = arr[i].holidays.join('-')
                            if (!arr[i].holidaysRule) {
                                ElMessage.warning('请选择节假日计费规则')
                                return
                            }
                            arr1.push(string)
                            arr2.push(arr[i].holidaysRule)
                        }
                        params.holidays = arr1.join(',')
                        params.holidaysRule = arr2.join(',')
                    }
                    addRulePrice(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('添加成功')
                            pageData.dialogPrice = false
                            courseType = ''
                            getList(pageData.topForm.id);
                        }
                    })
        } else if (courseType == 'edit') {
            delete courseEId.preUpdate
            sportRoomGroupUpdate(courseEId).then(res => {
                if (res.code == 0) {
                    ElMessage.success('编辑成功')
                    pageData.dialogVisible = false
                    getGroupList()
                }
            })
        } else {
            if (confirmType) {
                sportRoomGroupDelete({ encryptionId: encryptionId }).then((res)=>{
		        	if(res.code == 0){
		        		ElMessage({
		        			type: 'success',
		        			message: '删除成功',
		        		})
		        		getGroupList();
		        	}
		        })
            }
        }
	}
    /**
     * 上传分组图片
     */
    function uploadChange(url) {
        pageData.ruleForm.groupPic = url
    }
    /**
     * 删除图片
     */
    function deleBanner() {
        pageData.ruleForm.groupPic = ''
    }
    /**
     * 调起添加分组弹框
     */
    function addGrouping() {
        pageData.ruleForm= {
            groupName: '',
            groupPic: '',
            perSceneTime: 60,
            startTime: '',
            endTime: '',
            minHour: '',
            orderType: 0,
            cycleType: 0,
            orderNum: 1,
            remark: ''
        }
        pageData.checkList = []
        pageData.title = '新增场地分组'
        pageData.dialogVisible = true
    }
    /**
     * 取消分组弹框
     */
    function handleClose() {
        ruleFormRef.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 确定提交分组弹框
     */
    function onSubmit() {
        if (!pageData.ruleForm.groupPic) {
            ElMessage.warning('请上传分组图片')
            return
        }
        ruleFormRef.value.validate((valid) => {
            if (valid) {
                if (pageData.ruleForm.orderType && !pageData.ruleForm.orderDateStr) {
                    ElMessage.warning('请选择自定义预约日期')
                    return
                }
                if (pageData.ruleForm.cycleType && !pageData.checkList.length) {
                    ElMessage.warning('请选择自定义循环设置')
                    return
                }
                if (!pageData.ruleForm.startTime || !pageData.ruleForm.endTime) {
                    ElMessage.warning('请选择可预约时间')
                    return
                }
                let params = {
                    groupName: pageData.ruleForm.groupName,
                    groupPic: pageData.ruleForm.groupPic,
                    startEndTime: pageData.ruleForm.startTime + '-' +pageData.ruleForm.endTime,
                    perSceneTime: pageData.ruleForm.perSceneTime,
                    minHour: pageData.ruleForm.minHour,
                    orderType: pageData.ruleForm.orderType,
                    orderDateStr: pageData.ruleForm.orderType ? pageData.ruleForm.orderDateStr.join('-') : '',
                    cycleType: pageData.ruleForm.cycleType,
                    week: pageData.ruleForm.cycleType ? pageData.checkList.join(',') : 0,
                    orderNum: pageData.ruleForm.orderNum,
                    remark: pageData.ruleForm.remark,
                }
                if (pageData.title == '新增场地分组') {
                    sportRoomGroupInsert(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            pageData.dialogVisible = false
                            getGroupList()
                        }
                    })
                } else {
                    params.encryptionId = encryptionId
                    params.preUpdate = 1
                    sportRoomGroupUpdate(params).then(res => {
                        if (res.code == 0) {
                            if (res.data) {
                                pageData.confirmTitle = `当前存在场地预约记录的最后一天为${res.data.lastDay}号，本次编辑的规则在${res.data.startDay}号起开始执行，即${res.data.startDay}起的场地排期将按照新的规则生成，立即生效，是否确认修改？`;
		                        confirmRef.value.show();
                                courseType = 'edit'
                                courseEId = params
                            } else {
                                ElMessage.success('编辑成功')
                                pageData.dialogVisible = false
                                getGroupList()
                            }
                        }
                    })
                }
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    onMounted(() => {
        getGroupList()
    })
    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        if (to.path === '/branch/appstore/settingOrder') {
            store.commit('SetKeep',{keepLiveRoute : ['BranchAppstoreSettingOrder']});
        } else {
            store.commit('SetKeep',{keepLiveRoute : []});
        }
    })
</script>
<style type='css/style' scoped>
    .manage{
        height: 82vh;
    }
    .scrollbar_div {
        padding: 13px 26px;
    }
    .active {
        border: 1px dotted #409EFF;
    }
    .upload {
        border: 1px dashed #D6D6D6;
        text-align: center;
        padding-top: 15px;
    }
    .upload .uploadImg {
        height: 100%;
    }
    .upload:hover {
		border: 1px dashed #409eff;
	}
    .uploadLogo {
		position: relative;
		border: 1px dashed #D6D6D6
	}
    .p {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background-color: rgba(0,0,0,0.3);
		text-align: center;
		color: white;
		display: none;
		cursor: pointer;
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
    .dele_icon {
		position: absolute;
		right: -10px;
		top: -10px;
		display: none;
		cursor: pointer;
		border-radius: 100%
	}
    .uploadLogo:hover .p{
		display: block;
	}
    .uploadLogo:hover .dele_icon{
		display: block;
	}
    ::v-deep .el-form-item__label {
        font-weight: 500;
    }
    .dialogDiv_left{
        background: #F7F7F7;
        padding: 20px 0;
    }
    .dialogDiv_right{
        width: 88px;
        height: 86px;
        text-align: center;
        line-height: 86px;
    }
    ::v-deep .el-checkbox-group{
        width: 100%;
    }
</style>