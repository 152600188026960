<template>
	<div class="add_info">
		<div class="rx-sc mb20">
			<div class="w80 t_a_r">
				<span class="color-red">*</span>
				<span>关联员工：</span>
			</div>
			<div class="w200">
				<el-select v-model="storeData.staffId" value-key="id" @change="changeStaff" placeholder="请选择">
					<el-option v-for="item in pageData.staffList" :key="item.id" :label="item.realName" :value="item">
					</el-option>
				</el-select>
			</div>
		</div>
		<div class="rx-sc mb20">
			<div class="w80 t_a_r">
				<span class="color-red">*</span>
				<span>教练头像：</span>
			</div>
			<div class="rx-cc">
				<img class="w65 h65 mr10 b_r_100" :src="storeData.headImgUrl ? storeData.headImgUrl : require('@/assets/business_photo.png')" alt="">
				<div>
					<div>
						<CommonUpload @change="(url)=>{uploadChange(url,1)}" :autoCropWidth="375" :autoCropHeight="375" :isCropper="true">
							<el-button type="primary" size="small" plain>{{storeData.headImgUrl ? '重新上传' : '立即上传'}}</el-button>
						</CommonUpload>
					</div>
					<p class="ft12 color-999 mt5">建议大小：750*750</p>
				</div>
			</div>
		</div>
		<div class="rx-sc mb20">
			<div class="w80 t_a_r">
				<span class="color-red">*</span>
				<span>教练姓名：</span>
			</div>
			<div>
				<el-input class="w200" type="text" v-model="storeData.name" placeholder="请输入教练姓名"></el-input>
			</div>
		</div>
		<div class="rx-ss mb20">
			<div class="w80 t_a_r">
				<span>个人标签：</span>
			</div>
			<div class="w300">
				<el-tag class="mr10 mb10" v-for="(item,index) in storeData.labels" :key="index" type='info' size="large" closable :disable-transitions="false" @close="deleLabel(index,1)"> {{ item }}</el-tag>
				<el-input class="w200" v-show="storeData.labels.length < 5" placeholder="请输入" v-model="pageData.labelText" @keyup.enter="addLabel" @blur="addLabel"/>
			</div>
		</div>
		<div class="rx-ss mb20">
			<div class="w80 t_a_r">
				<span>个人证书：</span>
			</div>
			<div>
				<ul class="certificateList">
					<li class="rx-cc w70 h70">
						<CommonUpload @change="(url)=>{uploadChange(url,2)}">
							<div class="t_a_c">
								<el-icon><Plus /></el-icon>
								<p class="ft12 color-999">上传图片</p>
							</div>
						</CommonUpload>
					</li>
					<li class="w70 h70" v-for="(item,index) in storeData.certificateLink" :key="index">
						<img class="deleIcon w15" src="@/assets/icon_error.png" @click="deleLabel(index,2)">
						<img class="ww100 hh100" :src="item" alt="">
					</li>
				</ul>
			</div>
		</div>
		<div class="rx-ss">
			<div class="w80 t_a_r">
				<span>教练简介：</span>
			</div>
			<div class="flex1" id="wangeditor">
				
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  sid:String,
	})
	import {ref,reactive,onUpdated,onMounted,onUnmounted} from 'vue'
	import { useStore } from 'vuex'
	import { Plus } from '@element-plus/icons-vue';
	import E from 'wangeditor'
	import {
		getBessAccountStaff
	} from '@/api/staff.js';
	import CommonUpload from '@/components/CommonUpload.vue';
	const store = useStore();
	const storeData = store.state.addCoachForm;
	var editor = '';
	const pageData = reactive({
		labelText:'',
		staffList:[]
	})
	//获取员工列表
	getBessAccountStaff({
		frameId:propData.sid
	}).then((res)=>{
		if(res.code == 0){
			pageData.staffList = res.data;
		}
	})
	//选择员工
	function changeStaff(row){
		storeData.userId  = row.id
		storeData.staffId = row.realName;
		storeData.name = row.realName;
		storeData.headImgUrl = row.headImgUrl || '';
	}
	//增加标签
	function addLabel(e){
		if(pageData.labelText){
			storeData.labels.push(pageData.labelText);
			pageData.labelText = '';
		}
	}
	//删除标签/删除证书
	function deleLabel(index,type){
		if(type == 1){
			storeData.labels.splice(index,1);
		}else{
			storeData.certificateLink.splice(index,1);
		}
		
	}
	//上传头像
	function uploadChange(url,type){
		if(type == 1){
			storeData.headImgUrl = url;
		}else{
			storeData.certificateLink.push(url);
		}
	}
	//返回富文本编辑器内容
	function returnHtml(type){
		if(type){
			editor.txt.html(storeData.briefIntroduction);
		}else{
			storeData.briefIntroduction = editor.txt.html();
		}
	}
	//暴露方法
	defineExpose({
	  returnHtml,
	})
	onMounted(()=>{
		editor = new E('#wangeditor');
		editor.config.height = 150;
		editor.config.zIndex = 100;
		// editor.config.menus = [
		//         'bold',
		//         'head',
		//         'link',
		//         'italic',
		//         'underline'
		//     ]
		editor.create();
	})
	onUnmounted(()=>{
		editor.destroy();
		editor = '';
	})
</script>

<style scoped lang="stylus">
	.add_info{
		.certificateList{
			li{
				float left;
				margin-right 10px;
				margin-bottom 10px;
				position relative;
				border 1px solid #D6D6D6;
				border-radius 2px;
				img{
					border-radius 2px;
				}
				.deleIcon{
					position absolute;
					right -5px;
					top -5px;
					background-color white;
					border-radius 100%;
					cursor pointer;
				}
				div .el-icon {
					color: #999
				}
			}
			
			li:hover div p, li:hover div .el-icon{
				color: #666
			}
			li:first-child{
				border 1px dashed #D6D6D6;
			}
		}
	}
</style>
