<template>
    <div class="rx-bc mt20 ml40 mr40">
        <div class="content">
            <div class="content_top">
                <div class="color-333 fwb ft16 text">会员性别分布</div>
            </div>
            <div class="content_content">
                <div class="mt70 t_a_c h20">
                    <span class="fwb c_p color-333" :class="[ pageData.sex == 1 ? 'active' : '' ]" @click="sex(1)">全部会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.sex == 2 ? 'active' : '' ]" @click="sex(2)">正式会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.sex == 3 ? 'active' : '' ]" @click="sex(3)">潜在会员</span>
                </div>
                <div id="myChart" class="h440"></div>
            </div>
        </div>
        <div class="content">
            <div class="content_top">
                <div class="color-333 fwb ft16 text">会员年龄分布</div>
            </div>
            <div class="content_content">
                <div class="mt70 t_a_c h20">
                    <span class="fwb c_p color-333" :class="[ pageData.age == 1 ? 'active' : '' ]" @click="age(1)">全部会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.age == 2 ? 'active' : '' ]" @click="age(2)">正式会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.age == 3 ? 'active' : '' ]" @click="age(3)">潜在会员</span>
                </div>
                <div id="myChart1" class="h440"></div>
            </div>
        </div>
        <div class="content">
            <div class="content_top">
                <div class="color-333 fwb ft16 text">会员跟进情况</div>
            </div>
            <div class="content_content">
                <div class="mt70 t_a_c h20">
                    <span class="fwb c_p color-333" :class="[ pageData.follow == 1 ? 'active' : '' ]" @click="follow(1)">全部会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.follow == 2 ? 'active' : '' ]" @click="follow(2)">正式会员</span>
                    <el-divider direction="vertical" />
                    <span class="fwb c_p color-333" :class="[ pageData.follow == 3 ? 'active' : '' ]" @click="follow(3)">潜在会员</span>
                </div>
                <div id="myChart2" class="h440"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import { getMember } from '@/api/dataCenter.js';
    import * as echarts from 'echarts';
    let myChart = null
    let myChart1 = null
    let myChart2 = null
    const pageData = reactive({
        sex: 1,
        age: 1,
        follow: 1,
        option: {
            title: {
                text: '会员性别分布',
                x: 'center',
                y: 'center',
            },
            legend: {
                bottom: '5%',
                left: 'center',
                width: '70%',
                itemGap: 10,
                icon: "circle",
                formatter: ['{a|{name}}'].join('\n'),
                 textStyle: {
                   rich: {
                     a: {
                       width: 50, // 每个图例的宽度，具体根据字数调整
                       lineHeight: 12
                     }
                   }
                 }
            },
            series: [{
                name: 'Access From',
                type: 'pie',
                radius: ['40%', '50%'],
                avoidLabelOverlap: false,
                label: {        //展示文本设置 
                    normal: {
                        show: false,     //展示
                        formatter: '{b}  {c}人', //图形外文字上下显示,
                    },
                    emphasis: {    //文本样式
                        show: true,    //展示
                    }
                },
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
                labelLine: {    //引导线设置
                    normal: {
                        show: true,   //引导线显示
                    }
                },
                data: []
            }]
        }
    })
    /**
     * 切换性别分布
     */
    function sex(val) {
        pageData.sex = val
        let trialFlag = val == 1 ? '' : val-1
        getInfo(1, trialFlag)
    }
    /**
     * 切换年纪分布
     */
    function age(val) {
        pageData.age = val
        let trialFlag = val == 1 ? '' : val-1
        getInfo(2, trialFlag)
    }
    /**
     * 切换跟进情况
     */
    function follow(val) {
        pageData.follow = val
        let trialFlag = val == 1 ? '' : val-1
        getInfo(3, trialFlag)
    }
    /**
     * 获取图表数据
     */
    function getInfo(type, trialFlag) {
        let params = {
            type: type,
            trialFlag: trialFlag
        }
        myChart = echarts.init(document.getElementById('myChart'))
        myChart1 = echarts.init(document.getElementById('myChart1'))
        myChart2 = echarts.init(document.getElementById('myChart2'))
        getMember(params).then(res => {
            if (res.code == 0) {
                let totalNumber = 0
                if (type == 1) {
                    let data = [{ value: res.data.maleCount, name: '男性' },
                      { value: res.data.femaleCount, name: '女性' },
                      { value: res.data.unknownCount, name: '未知' }]
                    let maleCount =  res.data.maleCount ? res.data.maleCount : 0
                    let femaleCount =  res.data.femaleCount ? res.data.femaleCount : 0
                    let unknownCount =  res.data.unknownCount ? res.data.unknownCount : 0
                    totalNumber = maleCount + femaleCount + unknownCount
                    pageData.option.color= ['#66B5FF', '#FD4B70', '#5AD8A6']
                    pageData.option.title.text = `共${totalNumber}人`
                    pageData.option.series[0].name = '会员性别'
                    pageData.option.series[0].data = data
                    myChart.setOption( pageData.option, true);
                }
                if (type == 2) {
                    let data = [{ value: res.data.age10Count, name: '0-10岁' },
                                { value: res.data.age20Count, name: '11-20岁' },
                                { value: res.data.age30Count, name: '21-30岁' },
                                { value: res.data.age40Count, name: '31-40岁' },
                                { value: res.data.age50Count, name: '41-50岁' },
                                { value: res.data.age60Count, name: '51-60岁' },
                                { value: res.data.age100Count, name: '60岁以上' },
                                { value: res.data.unknownCount, name: '未知' }]

                    let age10Count =  res.data.age10Count ? res.data.age10Count : 0
                    let age20Count =  res.data.age20Count ? res.data.age20Count : 0
                    let age30Count =  res.data.age30Count ? res.data.age30Count : 0
                    let age40Count =  res.data.age40Count ? res.data.age40Count : 0
                    let age50Count =  res.data.age50Count ? res.data.age50Count : 0
                    let age60Count =  res.data.age60Count ? res.data.age60Count : 0
                    let age100Count =  res.data.age100Count ? res.data.age100Count : 0
                    let unknownCount =  res.data.unknownCount ? res.data.unknownCount : 0
                    totalNumber = age10Count + age20Count + age30Count + age40Count + age50Count + age60Count+ age100Count + unknownCount
                    pageData.option.color= ['#FAD97D', '#F794D1', '#8BE0A5', '#BE96FA', '#F7807C', '#66B5FF', '#60DBDE', '#D76FF7']
                    pageData.option.title.text = `共${totalNumber}人`
                    pageData.option.series[0].name = '会员年龄'
                    pageData.option.series[0].data = data
                    myChart1.setOption( pageData.option, true);
                }
                if (type == 3) {
                    let data = [{ value: res.data.followCount, name: '已跟进' },
                      { value: res.data.unfollowCount, name: '未跟进' }]
                    let followCount =  res.data.followCount ? res.data.followCount : 0
                    let unfollowCount =  res.data.unfollowCount ? res.data.unfollowCount : 0
                    totalNumber = followCount + unfollowCount
                    pageData.option.color= ['#FAD97D', '#F7807C']
                    pageData.option.title.text = `共${totalNumber}人`
                    pageData.option.series[0].name = '会员跟进情况'
                    pageData.option.series[0].data = data
                    myChart2.setOption( pageData.option, true);
                }
		        window.addEventListener("resize",function(){
                    if (type == 1) {
                        myChart.resize();
                    }
                    if (type == 2) {
                        myChart1.resize();
                    }
                    if (type == 3) {
                        myChart2.resize();
                    }
                });
            }
        })
        
    }
    onMounted(() => {
        getInfo(1, '')
        getInfo(2, '')
        getInfo(3, '')
    })
    onBeforeUnmount(() => {
      if (!myChart && !myChart1 && myChart2) {
        return
      }
      myChart.dispose()
      myChart = null
      myChart1.dispose()
      myChart1 = null
      myChart2.dispose()
      myChart2 = null
    })
</script>
<style type='css/style' scoped>
    .content {
        border-radius: 8px;
        height: 656px;
        width: 30%;
        border: 1px solid #E0E0E0
    }
    .content_top {
        padding: 17px 0 16px 12px;
        border-bottom: 1px solid #E0E0E0;
    }
    .text{
        padding-left: 9px;
        border-left: 4px solid #00A3FF;
    }
    .active {
        color: #00A3FF;
    }
</style>