import {postAjax,getAjax}  from "../common/axios.js"

// 场地分组列表
export const sportRoomGroupList = params => {
	return postAjax('/api/sportRoomGroup/list',params);
}

// 新增场地分组
export const sportRoomGroupInsert = params => {
	return postAjax('/api/sportRoomGroup/insert',params);
}

// 编辑场地分组
export const sportRoomGroupUpdate = params => {
	return postAjax('/api/sportRoomGroup/update',params);
}

// 删除场地分组
export const sportRoomGroupDelete = params => {
	return postAjax('/api/sportRoomGroup/delete',params);
}

// 同步场地
export const sportRoomUpdateGroup = params => {
	return postAjax('/api/sportRoom/updateGroup',params);
}

// 单个场地预约详情
export const getSportRoomOrderSettinginfo = params => {
	return postAjax('/api/sportRoomOrderSetting/info',params);
}

// 场地预约配置更新
export const getSportRoomOrderSettingConfig = params => {
	return postAjax('/api/sportRoomOrderSetting/config',params);
}

// 场地预约记录
export const sportRoomOrderList = params => {
	return postAjax('/api/sportRoomOrder/list',params);
}

// 取消场地预约
export const storeOrderRoomCancel = params => {
	return postAjax('/api/sportRoomOrder/storeOrderRoomCancel',params);
}

// 查询场地预约设置
export const sportRoomSettingInfo = params => {
	return postAjax('/api/sportRoomSetting/info',params);
}

// 更新场地预约设置
export const sportRoomSettingUpdate = params => {
	return postAjax('/api/sportRoomSetting/update',params);
}


//前台-预订-获取可预约时段
export const getRoomOrderTimeList = params => {
	return postAjax('/api/sportRoomOrderSetting/getRoomOrderTimeList',params);
}
//前台-场地预订-获取场地时段
export const getStoreRoomOrderTimeList = params => {
	return postAjax('/api/sportRoomAppointment/getStoreRoomOrderTimeList',params);
}
//前台-场地预订-锁定场地
export const sportRoomLockSectionInsert = params => {
	return postAjax('/api/sportRoomLockSection/insert',params,'json');
}
//前台-场地预订-解锁场地-暂不用
// export const sportRoomLockSectionDelete = params => {
// 	return postAjax('/api/sportRoomLockSection/delete',params);
// }
//前台-场地预订-预订
export const appointment = params => {
	return postAjax('/api/sportRoomAppointment/appointment',params);
}
//前台-场地预订-今日预订列表
export const getToday = params => {
	return postAjax('/api/sportRoomAppointment/getToday',params);
}
//前台-场地预订-获取订单信息
export const getOrderToPay = params => {
	return postAjax('/api/sportRoomAppointment/getOrderToPay',params);
}
//前台-场地预订-付款
export const doOrderPay = params => {
	return postAjax('/api/sportRoomAppointment/doOrderPay',params);
}
//前台-场地预订-取消不退款
export const cancelTimesOnly = params => {
	return postAjax('/api/sportRoomAppointment/cancelTimesOnly',params);
}
//前台-场地预订-退款
export const memberOrderRoomManyCancel = params => {
	return postAjax('/api/sportRoomOrder/memberOrderRoomManyCancel',params);
}
//前台-场地预订-换场
export const changeRoom = params => {
	return postAjax('/api/sportRoomAppointment/changeRoom',params);
}
//前台-场地预订-修改信息
export const updateAppointmentInfo = params => {
	return postAjax('/api/sportRoomAppointment/updateAppointmentInfo',params);
}
//前台-场地预订-加订
export const appendAppointment = params => {
	return postAjax('/api/sportRoomAppointment/appendAppointment',params);
}
//前台-场地预订-删除待支付订单
export const deleteOrderToPay = params => {
	return postAjax('/api/sportRoomAppointment/deleteOrderToPay',params);
}
// 查询分组场地锁场规则
export const sportRoomLockSetting = params => {
	return postAjax('/api/sportRoomLockSetting/list',params);
}

// 新增分组场地锁场规则
export const settingInsert = params => {
	return postAjax('/api/sportRoomLockSetting/insert',params);
}

// 取消分组场地锁场规则
export const settingCancel = params => {
	return postAjax('/api/sportRoomLockSetting/cancel',params);
}

// 计费规则模板列表
export const settingRuleList = params => {
	return postAjax('/api/sportRoomOrderSettingRule/list',params);
}

// 新增计费规则模板
export const settingRuleInsert = params => {
	return postAjax('/api/sportRoomOrderSettingRule/insert',params);
}

// 编辑计费规则模板
export const settingRuleUpdate = params => {
	return postAjax('/api/sportRoomOrderSettingRule/update',params);
}

// 删除计费规则模板
export const settingRuleUpdateDel= params => {
	return postAjax('/api/sportRoomOrderSettingRule/updateDel',params);
}

// 查询节假日年份
export const listYear= params => {
	return postAjax('/api/sportRoomHoliday/listYear',params);
}

// 查询节假日数据
export const listData= params => {
	return postAjax('/api/sportRoomHoliday/list',params);
}

// 更新节假日数据
export const listUpdate= params => {
	return postAjax('/api/sportRoomHoliday/update',params);
}

// 新增场地定价
export const addRulePrice= params => {
	return postAjax('/api/sportRoom/addRulePrice',params);
}

// 单独编辑场地的周几定价
export const updateWeekPrice= params => {
	return postAjax('/api/sportRoom/updateWeekPrice',params);
}

// 单独编辑场地的节假日定价
export const updateHolidayPrice= params => {
	return postAjax('/api/sportRoom/updateHolidayPrice',params);
}

// 操作日志
export const sportActionLogList= params => {
	return postAjax('/api/sportActionLog/list',params);
}

// 场地订单列表
export const queryAllSaleRoomList= params => {
	return postAjax('/api/order/product/queryAllSaleRoomList',params);
}
