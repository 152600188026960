<template>
	<workArea :barlist="data.barlist">
	<div class="card_manage bg-fff p20 pt10">
		<el-tabs v-model="data.tabNum" @tab-click="handleClick">
		    <el-tab-pane label="课程卡" name="0"></el-tab-pane>
		    <el-tab-pane label="入场卡" name="1"></el-tab-pane>
		    <el-tab-pane label="储值卡" name="2"></el-tab-pane>
		    <el-tab-pane label="体验卡" name="3"></el-tab-pane>
		</el-tabs>
		<component :is="components[data.tabNum]" :tab="data.tabNum"></component>
	</div>
	</workArea>
</template>
<script>
  export default {
    name: 'MainCards'
  }
</script>
<script setup>
	import { reactive, onBeforeUnmount, onMounted } from 'vue';
	import { useRouter } from 'vue-router';
	import CourseCard from '@/views/main-store/cards/CourseCard.vue' //课程卡
	import EntranceCard from '@/views/main-store/cards/EntranceCard.vue' //入场卡
	import StoreCard from '@/views/main-store/cards/StoreCard.vue' //储值卡
	import ExperienceCard from '@/views/main-store/cards/ExperienceCard.vue' //体验卡
	import workArea from '@/components/branch-store/workArea.vue';
	const router = useRouter();
	const components = [CourseCard,EntranceCard,StoreCard,ExperienceCard];
	const data = reactive({
		barlist:[{ name : '首页' },{ name : '经营类目' },{ name : '卡项管理' }],
		tabNum:'0',
	})
	function handleClick(tab) {
      localStorage.setItem('vipCard', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('vipCard') != null) {
          data.tabNum = localStorage.getItem('vipCard')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('vipCard')
	})
</script>

<style scoped>

</style>
