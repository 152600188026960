<template>
	<div class="color-999 ft12 mt16">温馨提示：展示可在本门店消费的已购班级</div>
	<div class="mt16">
		<div :name="(i+1)" v-for="(n,i) in pageData.list" :key="i">
			<div class="card-top rx-bc c_p" @click="n.open = !n.open">
				<div class="rx-sc">
					<span class="d_i_b w5 h5 v_a_m bdr4 badge mr5"></span>
					<span class="ml5 fwb">已加入班级{{ pageData.list[0].data.length }}个</span>
				</div>
				<a href="javaScript:;" class="tool_btn color-main">
					<span class="v_a_m">{{ n.open ? '收起' : '展开' }}</span>
					<DArrowRight v-show="!n.open" class="arrowdown"></DArrowRight>
                    <DArrowLeft v-show="n.open" class="arrowdown"></DArrowLeft>
				</a>
			</div>
            
			<div class="mb25" v-show="n.open">
				<el-table :data="n.data" strip>
					<el-table-column prop="cycleName" label="班级名称"  width="110" show-overflow-tooltip />
					<el-table-column prop="courseName" label="所上课程"  width="110" show-overflow-tooltip />
					<el-table-column prop="operationTime" label="开班时间" width="160" >
                        <template #default="scope">
                            <div>{{ scope.row.cycleStartCourseTimeStr }}</div>
						</template>
					</el-table-column>
					<el-table-column prop="actualMoney" label="班级状态">
                        <template #default="scope">
                            {{ utils.expired(scope.row.cycleStartCourseTimeStr) > 0 ? '未开始' : utils.expired(scope.row.cycleStartCourseTimeStr) < 0 && utils.expired(scope.row.cycleEndCourseTimeStr) > 0 ? '进行中' : '已结束'  }}
							
						</template>
                    </el-table-column>
					<el-table-column label="班级模式">
						<template #default="scope">
							<span>{{scope.row.cycleType == 11 ? '固定上课模式' :'自主预约模式' }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="createTime" label="加入时间" width="160" />
                    <el-table-column prop="courseBuyNum" label="已购课节数" />
                    <el-table-column prop="courseOrderNum" label="已加入课节数" />
                    <el-table-column prop="productNum" label="未消课节数" />
					<el-table-column label="状态">
						<template #default="scope">
							<span class="text-color">正常</span>
						</template>
					</el-table-column>
                    <el-table-column prop="storeName" label="购买门店"  width="110" show-overflow-tooltip />
					<el-table-column prop="canUse" label="可用门店">
						<template #default="scope">
							<span class="t_d_u color-409EFF tool_btn">{{ scope.row.storeName }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, reactive } from 'vue';
	import { DArrowRight, DArrowLeft } from '@element-plus/icons-vue';
	import { myCourseCycleList, queryConsumeSid } from '@/api/member.js';
    import utils from '@/common/utils.js';
	const propData = defineProps({
		info: Object,
		num: Object
	})
	console.log(propData)
	const pageData = reactive({
		lookStoreShow: false,
		lookStoreList: [],
		lookStoreCount: 0,
		lookStoreForm:{
			page:1,
			limit:15
		},
		list: [{
				title: '班级',
				data: [],
                open: true
			}]
	});
	function getBuyCardsList() {
		myCourseCycleList({
			encryptionCardMyId: propData.info.encryptionId
		}).then((res) => {
			if (res.code == 0) {
                res.data.map((n) => {
					pageData.list[0].data.push(n)
				})
			}
		})
	}
	if (propData.info.encryptionId) {
		getBuyCardsList();
	}
	//查看门店
	function lookStore(id) {
		pageData.lookStoreForm.id = id;
		queryConsumeSid(pageData.lookStoreForm).then((res) => {
			if (res.code == 0) {
				pageData.lookStoreList = res.data;
				pageData.lookStoreCount = res.count;
				pageData.lookStoreShow = true;
			}
		})
	}
</script>

<style scoped>
.badge {
	background: #333;
}
.text-color{
	color: #018000;
}
.card-top{
	background: rgba(64, 158, 255, 0.06);
	padding: 10px 17px 11px 17px;
	margin-bottom: 17px;
}
.arrowdown{
	transform:rotate(90deg);
	-ms-transform:rotate(90deg);     /* IE 9 */
	-moz-transform:rotate(90deg);    /* Firefox */
	-webkit-transform:rotate(90deg); /* Safari 和 Chrome */
	-o-transform:rotate(90deg);  /* Opera */
	width:13px;
	vertical-align: middle;
 	margin-left:2px;
}
</style>
