<template>
    <div class="pt20 pb20">
        <div class="rx-bc">
            <div class="formSearch">
                <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                    <el-form-item>
                      <el-select v-model="pageData.formInline.timeIn" @change="changeTimeIn">
                        <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
                      </el-select>
                    </el-form-item>
                    <el-form-item v-if="pageData.formInline.timeIn == -1">
                        <el-date-picker
                            style="height: 32px"
                            v-model="pageData.formInline.searchTime"
                            type="daterange"
                            range-separator="至"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :disabled-date="pageData.disabledDate"
                        />
                    </el-form-item>
                    <el-form-item v-if="pageData.formInline.timeIn == -2">
                      <el-date-picker
                            style="height: 32px"
                            v-model="pageData.formInline.searchTime"
                            type="monthrange"
                            format="YYYY-MM"
                            value-format="YYYY-MM"
                            range-separator="至"
                            start-placeholder="开始月份"
                            end-placeholder="结束月份"
                            :disabled-date="pageData.setMonthDisabled"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="mb15 rx-cc">
                <div class="mr20">
                    <span class="color-999 ft12 mr20">以下收入统计为订单购买的实收金额，其中包括使用储值卡赠送支付的金额，等于【收银记账】中总收入</span>
                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="formula">计算公式</a>
                </div>
                <el-button :icon="Upload" @click="getList(true)">导出表格</el-button>
            </div>
        </div>
        <div v-loading="pageData.loading">
            <div class="mt5 mb10 h90 rx-c">
                <div class="left hh100 ft16 color-333 fwb t_a_c">收入概览</div>
                <div class="right hh100 flex1 ft16 color-333 fwb t_a_c">总收入：￥{{ pageData.remark.allMoney }}</div>
            </div>
            <div class="rx-wcc">
                <div class="table table1 h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">私教课购买收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.privateBuyMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon.png" alt="">
                </div>
                <div class="table h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">团体课购买收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.teamBuyMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon1.png" alt="">
                </div>
                <div class="table h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">课程卡购买收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.courseCardBuyMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon2.png" alt="">
                </div>
                <div class="table h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">入场卡购买收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.enterCardBuyMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon3.png" alt="">
                </div>
                <div class="table table1 table2 h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">单次预约课程</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.memberOneMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon4.png" alt="">
                </div>
                <div class="table table2 h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">体验卡购买收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.testCardBuyMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon5.png" alt="">
                </div>
                <div class="table table2 h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">场地预约收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.bookMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon6.png" alt="">
                </div>
                <div class="table table2 h100 pl60 pr60 rx-bc">
                    <div>
                        <span class="color-999 v_a_m">人工录入收入</span>
                        <div class="color-333 fwb mt12">￥{{ pageData.remark.handMoney }}</div>
                    </div>
                    <img src="@/assets/billIcon7.png" alt="">
                </div>
            </div>
            <div class="color-333 fwb ft16 mt20 mb20 text">已营收收入</div>
            <div id="myChart" class="h400 mt20"></div>
            <div class="mt20">
                <el-table :data="pageData.tableData.slice((pageData.page-1)*pageData.limit, pageData.page*pageData.limit)" stripe >
		    		<el-table-column prop="productName" label="商品名称" show-overflow-tooltip />
                    <el-table-column label="类型">
                        <template #default="scope">
                            {{ filtersType(scope.row.productType) }}
                        </template>
                    </el-table-column>
		    		<el-table-column prop="actualMoney" label="实收金额（元）" />
                    <el-table-column label="占比">
                        <template #default="scope">
                            {{ scope.row.percent + '%' }}
                        </template>
                    </el-table-column>
		    	</el-table>
                <div class="mt20 rx-ec">
		            <el-pagination
		              v-model:currentPage="pageData.page"
		              :page-sizes="[15,20,30,50,100]"
		              :page-size="pageData.limit"
		              layout="total, sizes, prev, pager, next, jumper"
		              :total="pageData.totalCount"
		              @size-change="classPageSizeChange"
                      @current-change="pageChange"
		            >
		            </el-pagination>
		        </div>
            </div>
        </div>
        <Formula v-model="pageData.formulaShow" :type="'22'"></Formula>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
    import { Search, Upload, Download } from '@element-plus/icons-vue'
    import {ElMessage} from  'element-plus';
    import utils from '@/common/utils.js';
    import Formula from '@/components/branch-store/datacenter/formula.vue';
    import * as echarts from 'echarts';
    import { getIncomeProfile, getIncomeList } from '@/api/dataCenter.js';
    let myChart = null
    const dataList =[
        { value : "5" , label : '近7天' },
        { value : "7" , label : '近30天' },
        { value : "8" , label : '近60天' },
        { value : "9" , label : '近90天' },
        { value : "24", label : '近6个月' },
        { value : "25", label : '近12个月' },
        { value : "-1" , label : '自定义日期'},
        { value : "-2" , label : '自定义月份' }
    ]
	const productTypeList = [
		{value: 1, label: '课程卡收入' },
		{value: 2, label: '入场卡收入' },
        {value: 3, label: '储值卡收入' },
		{value: 4, label: '体验卡收入' },
		{value: 11, label: '团体课收入' },
		{value: 12, label: '私教课收入' },
        {value: 96, label: '场地预约' },
		{value: 97, label: '单次预约课程' },
        {value: 98, label: '场地预约收入' },
        {value: 99, label: '人工录入收入' }
	]
    const pageData = reactive({
        formulaShow: false,
        loading: false,
        formInline: {
            timeIn: '5',
            searchTime: []
        },
        tableData: [],
        remark: {},
        page:1,
        limit: 15,
        totalCount: 0,
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
        setMonthDisabled(time) {
            // 获取当前的月份信息
            const date = new Date(); // 获取当前的时间基本信息，值是这样的： Tue Jul 20 2021 14:59:43 GMT+0800 (中国标准时间)
            const year = date.getFullYear(); // 获取当前年份，值是这样的： 2021
            let month = date.getMonth() + 1; // 获取当前月份，值是这样的： 6   getMonth()方法返回值是0-11，也就是1月份到12月份，所以要加上1，才是当前月份
            if (month >= 1 && month <= 9) {
              // 如果是1月到9月就要在前面补上一个0   比如：02、07    月份这样表示
              month = "0" + month;
            }
            const nowDate = year.toString() + month.toString(); // 转换成字符串拼接，最终得到年和月，比如此时的时间是2021年7月20号，所以nowDate的值是：202107
            // 获取时间选择器的月份信息
            const timeyear = time.getFullYear(); // 获取时间选择器的年份（有很多）
            let timemonth = time.getMonth() + 1; // 与上面同理
            if (timemonth >= 1 && timemonth <= 9) {
              timemonth = "0" + timemonth;
            }
            const elTimeData = timeyear.toString() + timemonth.toString();

            // 返回，时间选择器的日期月份大于当前日期的月份，又因为disabledDate函数是控制月份禁用不可选
            // 所以，最终就是：时间选择器的月份大于当前的月份，就都禁用掉，所以就实现了最终效果：
            // 大于等于当前月份都不可选 
            return elTimeData > nowDate; // 这里虽然是字符串，但是弱类型语言js会做一个转换，是可以比较大小的如： '202107' > '202008'
        },
        option: {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        interval: 6,
                        rotate: 35
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            color : [ '#4488F7', '#4D99F2', '#57ABEE', '#65C5E7', '#73D2D8', '#7DD6C8', '#8EDEB1', '#A0E699'],
            series: [
                {
                  name: '私教课售出',
                  type: 'bar',
                  barWidth: 30,
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '团体课售出',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '课程卡售出',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '入场卡售出',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '体验卡售出',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '单次预约课程',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '场地预约',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                },{
                  name: '人工录入',
                  type: 'bar',
                  stack: 'Search Engine',
                  data: []
                }
            ]
        }  
    })
     /**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        productTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }
    /**
     * 计算公式
     */
    function formula() {
        pageData.formulaShow = true
    }
    /**
     * 搜索
     */
    function search() {
        if (pageData.formInline.timeIn == '-1') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择日期'
                });
                return;
            }
            if (utils.datedifference(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 31) {
                ElMessage({
                    type : 'warning',
                    message : '自定义日期不能超过31天'
                });
                return;
            }
        }
        if (pageData.formInline.timeIn == '-2') {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage({
                    type : 'warning',
                    message : '请选择月份'
                });
                return;
            }
            if (utils.MonthsBetw(pageData.formInline.searchTime[0], pageData.formInline.searchTime[1]) > 12) {
                ElMessage({
                    type : 'warning',
                    message : '自定义月份不能超过12个月'
                });
                return;
            }
        }
        pageData.page = 1
        getList()
        getInfo()
    }
    /**
     * 列表一页几条
     */
    function classPageSizeChange(val) {
        pageData.limit = val
    }
    /**
     * 列表翻页
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    /**
     * 获取列表数据
     */
    function getList(isExport) {
        let params = {
            timeIn: pageData.formInline.timeIn,
            searchTime: pageData.formInline.searchTime.join('-'),
            page: pageData.page,
            limit: pageData.limit
        }
        if(isExport){ //导出
			params.exportData = 1;
            params.page = 1
            params.limit = 9999
		}
        getIncomeList(params).then((res) => {
            if (res.code == 0) {
                if (isExport) {
                    ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
                } else {
                    pageData.totalCount = res.count
                    pageData.tableData = res.data
                }
            }
        })
    }
    /**
     * 获取收入概况汇总
     */
    function getInfo(){
        let params = {
            timeIn: pageData.formInline.timeIn,
            searchTime: pageData.formInline.searchTime.join('-')
        }
        getIncomeProfile(params).then((res) => {
            if (res.code == 0) {
                pageData.remark = res.results || {}
                pageData.option.xAxis[0].data = []
                pageData.option.series[0].data = []
                pageData.option.series[1].data = []
                pageData.option.series[2].data = []
                pageData.option.series[3].data = []
                pageData.option.series[4].data = []
                pageData.option.series[5].data = []
                pageData.option.series[6].data = []
                pageData.option.series[7].data = []
                res.data.sort(function (a, b) {
                        return a.createDate > b.createDate ? 1 : -1;
                })
				res.data&&res.data.forEach(item => {
                    pageData.option.xAxis[0].data.push(item.createDate)
                    pageData.option.series[0].data.push(item.privateBuyMoney)
                    pageData.option.series[1].data.push(item.teamBuyMoney)
                    pageData.option.series[2].data.push(item.courseCardBuyMoney)
                    pageData.option.series[3].data.push(item.enterCardBuyMoney)
                    pageData.option.series[4].data.push(item.testCardBuyMoney)
                    pageData.option.series[5].data.push(item.memberOneMoney)
                    pageData.option.series[6].data.push(item.bookMoney)
                    pageData.option.series[7].data.push(item.handMoney)
				})
                if (res.data.length > 10) {
                    pageData.option.xAxis[0].axisLabel = {
                        interval:0,
                        rotate: 40
                    }
                } else {
                    pageData.option.xAxis[0].axisLabel = {
                        interval:0,
                        rotate: 0
                    }
                }
		        myChart.setOption( pageData.option, true);
		        window.addEventListener("resize",function(){
		            myChart.resize();
                });
            }
        })
    }
    onMounted(() => {
        myChart = echarts.init(document.getElementById('myChart'))
        getInfo()
        getList()
    })
    onBeforeUnmount(() => {
      if (!myChart) {
        return
      }
      myChart.dispose()
      myChart = null
    })
</script>
<style type='css/style' scoped>
    .formSearch ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 15px;
    }
    ::v-deep .el-range-editor.el-input__inner {
        padding: 0 10px;
    }
    .table {
        width: 25%;
        border-top: 1px solid #D6D6D6;
        border-right: 1px solid #D6D6D6;
    }
    .table1{
        border-left: 1px solid #D6D6D6;
    }
    .table2{
        border-bottom: 1px solid #D6D6D6;
    }
    .table img{
        width: 44px;
        height: 44px;
    }
    .text{
        padding-left: 16px;
        border-left: 4px solid #00A3FF;
    }
    .left{
        width: 240px;
        background: #CBE2FF;
        line-height: 90px;
    }
    .right{
        background: #F2F2F2;
        line-height: 90px;
    }
</style>