import {postAjax,getAjax}  from "../common/axios.js"

//潜在会员列表
export const selectStoreTrailUser = params => {
    return postAjax('/api/member/selectStoreTrailMembers',params)
}
//跟进人列表
export const getAdviserList = params => {
    return postAjax('/api/associator/getAdviserList',params)
}
// 查询门店员工列表 用于下拉
export const getStoreAccountStaffSimple = params => {
    return postAjax('/api/run/system/getStoreAccountStaffSimple',params);
}
//跟进状态
export const followStatusManage = params => {
    return postAjax('/api/associator/followStatusManage',params)
}
//会员来源
export const stuChannels = params => {
    return postAjax('/api/associator/stuChannels',params)
}
//会员全部标签
export const allotTagToBatch = params => {
    return postAjax('/api/associator/allotTagToBatch',params)
}
//新增会员
export const newUserInsert = params => {
    return postAjax('/api/member/memberAdd',params)
}
//编辑会员
export const cardMyInfoUpdate = params => {
    return postAjax('/api/member/memberUpdate',params)
}
//会员详情
export const selectOneUser = params => {
    return postAjax('/api/member/selectOneMember',params)
}
//投入公海
export const studentInput = params => {
    return postAjax('/api/public-student/input',params)
}
// 获取跟进记录
export const getStudentFollowList = params => {
    return postAjax('/api/follow-record/list',params)
}
// 获取单个会员跟进记录
export const getFollowListStudent = params => {
    return postAjax('/api/follow-record/student/list',params)
}
//公海列表
export const publicStudentList = params => {
    return postAjax('/api/member/selectStorePublicMembers',params)
}
//分配跟进人
export const assignFollower = params => {
    return postAjax('/api/follow-record/assign-follower',params, 'json')
}

//添加跟进记录
export const followRecordAdd = params => {
    return postAjax('/api/follow-record/batch-add',params, 'json')
}
//获取跟进记录详情
export const followRecordDetail = params => {
    return postAjax('/api/follow-record/detail',params)
}

//编辑跟进记录保存
export const followRecordEdit= params => {
    return postAjax('/api/follow-record/edit',params, 'json')
}
//批量打标签
export const vipUpdateTagBatch= params => {
    return postAjax('/api/associator/vipUpdateTagBatch',params)
}
//批量删除标签-查询并去重的标签列表
export const followUpTagList= params => {
    return postAjax('/api/follow/followUpTagList', params)
}
//批量删除标签保存
export const deleteTagDetails= params => {
    return postAjax('/api/associator/deleteTagDetails',params)
}
//批量删除会员 
export const deleteMyCard= params => {
    return postAjax('/api/member/memberDelete',params)
}
//公海池-捞取 
export const assignToSelf= params => {
    return postAjax('/api/public-student/assign/to-self', params)
}
//跟进提醒列表
export const followRemindList= params => {
    return postAjax('/api/follow-record/remind/list', params)
}
//跟进记录删除
export const deleteFollow= params => {
    return postAjax('/api/follow-record/delete',params)
}
//跟进提醒-导出
export const followRemindExport= params => {
    return postAjax('/api/follow-record/remind/export',params)
}
//跟进记录-导出
export const studentExport= params => {
    return postAjax('/api/student/follow-record/export',params)
}
//新增会员手机号验证
export const checkUserTel= params => {
    return postAjax('/api/member/nologin/checkUserTel',params)
}
// 会员详情-解绑微信
export const unBindUser= params => {
    return postAjax('/api/member/unBindUser',params)
}
// 会员详情-预约记录
export const list4Member= params => {
    return postAjax('/api/orderCourse/list4Member',params)
}
// 会员详情-消费记录
export const orderConsume= params => {
    return postAjax('/api/orderConsume/list4Member',params)
}
// 会员详情-入场记录
export const enterOutSite = params => {
    return postAjax('/api/enterOutSite/list4Member',params);
}


