<template>
    <!-- 新增潜在会员 -->
    <el-dialog
         title="会员信息导出"
         width="850px"
         custom-class="popwechat"
         :append-to-body="true"
         @opened="opened"
       >
        <div class="pl20 pt20 pb20 pr20"> 
            <CommonTitle text="请选择需要导出的字段"></CommonTitle>
			<div class="mb25">
				<el-checkbox label="全选/全不选" v-model="pageData.checkAll" @change="checkAll"></el-checkbox>
			</div>
			<div>
				<el-checkbox-group v-model="pageData.checkList">
				    <template v-for="(item,index) in pageData.list">
						<el-checkbox class="mb12" style="width: 130px;" border :label="item.value" :disabled="index < 2">
							<span class="color-666 fw400">{{item.name}}</span>
						</el-checkbox>
					</template>
				</el-checkbox-group>
			</div>
        </div>
       <template #footer>
            <div class="dialog-footer">
                <el-button @click="close">取消</el-button>
                <el-button type="primary" @click="save">确定导出</el-button>
            </div>
        </template>
    </el-dialog>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { ElMessage } from 'element-plus'
	import CommonTitle from '@/components/CommonTitle.vue';
    const emit = defineEmits(['close','save']);
    const propData = defineProps({
        type:String, //1潜在 2公海池 3潜在会员+公海池会员 4正式
		data:Object
    })
    const pageData = reactive({
        type: propData.type,
		checkAll:false,
		list:[],
		checkList:[]
    })
	//潜在会员
	const potential = [
						{name:'会员姓名',value:'checked1'},
						{name:'手机号',value:'checked2'},
						{name:'跟进状态',value:'checked23'},
						{name:'意向级别',value:'checked20'},
						{name:'跟进人',value:'checked3'},
						{name:'会员来源',value:'checked4'},
						{name:'标签',value:'checked5'},
						{name:'上次跟进时间',value:'checked21'},
						{name:'下次跟进时间',value:'checked22'},
						];
	//正式会员
	const formal = [
						{name:'会员姓名',value:'checked1'},
						{name:'手机号',value:'checked2'},
						{name:'跟进人',value:'checked3'},
						{name:'会员来源',value:'checked4'},
						{name:'标签',value:'checked5'},
						{name:'入会时间',value:'checked19'},
						// {name:'实际购买',value:'checked18'},
						];
	//通用导出字段
	const currency = [
						{name:'备注',value:'checked6'},
						{name:'性别',value:'checked7'},
						{name:'生日',value:'checked8'},
						{name:'身高',value:'checked9'},
						{name:'体重',value:'checked10'},
						{name:'国家',value:'checked11'},
						{name:'民族',value:'checked12'},
						{name:'学历',value:'checked13'},
						{name:'职业',value:'checked14'},
						{name:'收入',value:'checked15'},
						{name:'家庭地址',value:'checked16'},
						{name:'详细地址',value:'checked17'},
					]
				
	// 切换导出类型
	function typeChange(){
		if(pageData.type == 2){ //公海类型
			pageData.list[4] = {name:'前跟进人',value:'checked3'};
			pageData.list[7].name = '投入公海时间';
		}else{
			pageData.list[4] = {name:'跟进人',value:'checked3'};
			pageData.list[7].name = '上次跟进时间';
		}
		var arr = [];
		for(var i=0;i<10;i++){
			arr.push(pageData.list[i].value);
		}
		pageData.checkList = arr;
	}
	
	//全选、全部选
	function checkAll(){
		if(pageData.checkAll){ //全选
			pageData.checkList = pageData.list.map(n=>n.value);
		}else{
			pageData.checkList = ['checked1','checked2'];
		}
	}
  
    function close(){
        emit('close');
    }
    function save(){
	   var obj = {};
	   pageData.checkList.map((n)=>{
		   obj[n] = 1;
	   })
	   emit('save',obj);
    }
  
    function opened(){
		pageData.checkList = ['checked1','checked2'];
		if(propData.type == 4){ //正式会员
			pageData.list = [...formal,...currency];
			var arr = [];
			for(var i=0;i<8;i++){
				arr.push(pageData.list[i].value);
			}
			pageData.checkList = arr;
		}else{
			pageData.list = [...potential,...currency];
			typeChange();
		}
    }
</script>

<style scoped>
	.el-checkbox.is-bordered.is-disabled {
	    border-color: #409eff
	}
	::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
	    background-color: #409eff;
	    border-color: #409eff
	}
	::v-deep .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
	    border-color: #FFF;
	}
	
</style>
