<template>
    <workArea :barlist="barlist">
        <div class="rx-bc p20">
            <div class="rx-c bg-F7F6FB c_p" @click="addClass(11)">
                <img class="w230 h130" src="@/assets/fixed.png" alt="">
                <div class="text">
                    <div class="h85">
                        有固定课节数，会员需完全按照排课规律上课，报名后将自动预约全部课节，且不可取消预约（支持请假）
                    </div>
                </div>
            </div>
            <div class="rx-c bg-F7F6FB c_p" @click="addClass(12)">
                <img class="w230 h130" src="@/assets/booking.png" alt="">
                <div class="text">
                    <div class="h85">
                        场馆可自由排课，会员购买后，可自主预约想上的课节，适用于课程进度体系不强的日常训练式课程
                    </div>
                </div>
            </div>
        </div>
        <el-tabs class="p16 pb0" v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="固定上课班级" name="0"  v-if="utils.getMenuRole(31900)"/>
            <el-tab-pane label="自主预约班级" name="1"  v-if="utils.getMenuRole(31901)"/>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </workArea>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import fixedClass from '@/views/branch-store/work/classManage/fixedClass.vue'; // 固定班级
    import bookingClass from '@/views/branch-store/work/classManage/bookingClass.vue'; //  预约班级
	import utils from '@/common/utils.js';
    import { useRouter } from 'vue-router'

    const router = useRouter()
    const components = [fixedClass, bookingClass];
    const barlist = reactive([{ name : '首页'},{ name : '教务中心' },{ name : '班级管理' }]); // 面包屑
    const pageData = reactive({
        tabNum: '0',
    })
	// var menuId = [31900,31901]; //会员有权限tab默认项
	// for(var i = 0;i < menuId.length;i++){
	// 	if(utils.getMenuRole(menuId[i])){
	// 		pageData.tabNum = i.toString();
	// 		break;
	// 	}
	// }
    function addClass(type) {
        router.push({ path: '/branch/classManage/addClassPage', query :{ type: type } })
    }
    function handleClick(tab){
		localStorage.setItem('classManage', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('classManage') != null) {
          pageData.tabNum = localStorage.getItem('classManage')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('classManage')
	})
</script>

<style scoped>
.bg-F7F6FB {
    background: #F7F6FB;
    border-radius: 10px;
    width: 48%;
}
.text {
    margin: auto;
    line-height: 28px;
}
.text div{
    max-width: 320px;
}
</style>
