<template>
	<el-container class="home_container">
		<el-aside width="200px" class="menu_box">
			<div class="menu_user">
				<div class="mt20 rx-c">
					<img class="w40 v_a_m ml25" :src="userInfo.logoUrl" alt="">
					<span class="color-fff fwb ml10 v_a_m d_i_b w115" :class="userInfo.storeName.length > 7 ? 'ft14 menu_user_span' : 'ft16'">{{ userInfo.storeName }}</span>
				</div>
			</div>
		     <el-menu 
		     	active-text-color="#ffd04b"
		        background-color="#001529"
		        class="mt20"
		        :default-active="currentMenu.url"
		        text-color="#fff"
		        @select="handleSelect"
		        router
		        unique-opened>
		      	<div v-for="item in menuLeft" :key="item.id">
					<el-sub-menu v-if="!item.url" :index="item.id">
			            <template #title>
			            		<img :src="[item.id == currentMenu.id ? item.iconSelected : item.icon]" class="w20 h20 mr5 mb5">
			                	<span>{{item.name}}</span>
			            </template>
			            <el-menu-item class="mb5" v-for="subItem in item.list" :key="subItem" :index="subItem.url">
				            <span>{{subItem.name}}</span>
				        </el-menu-item>
			        </el-sub-menu>
			        <el-menu-item v-if="item.url" :index="item.url">
						<img :src="[item.id == currentMenu.id ? item.iconSelected : item.icon]" class="w20 h20 mr5 mb5">
			            <span>{{item.name}}</span>
			        </el-menu-item>
		      	</div>
		    </el-menu>
		</el-aside>
		<el-container>
			<el-header>
				<div class="rx-bc h50" style="border-bottom: 1px solid #00000016;">
					<div class="c_p pl20">
                        
                    </div>
					
					<div class="w500 h50 rx-ec">
						<!-- <el-dropdown @command="changeStore">
                            <div class="w120 h30 rx-sc color-666">
                                <img  class="w15 ml5" style="opacity:0.6;" src="../../assets/home/qiehuan.png" alt="">
                                <span class="ml10 ft14">切换场馆</span>
                            </div>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item :command="store.bid+'-'+store.id" v-for="(store,index) in nowStoreData.sysUserStoreList">{{store.storeName}}</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown> -->
						<a href="javascript:void(0);" class="rx-cc" @click="downloadList">
							<Download class="w15 h15 color-main" style="font-weight:bold;"></Download>
							<span class="ft14 color-main">下载</span>
						</a>
						<el-divider direction="vertical" border-style="solid"></el-divider>
                        <el-dropdown @command="handleChangeStore">
                            <div class="h50 rx-cc">
                                <span class="color-main ft14">切换店铺</span>
                                <el-icon class="el-icon--right">
                                    <CaretBottom class="color-main"></CaretBottom>
                                </el-icon>
                            </div>
                            <template #dropdown>
                              <el-dropdown-menu>
                                <el-dropdown-item v-for="store in sysUserStoreList" :command="store.bid+'-'+store.id">
                                    <div class="ww100 rx-sc" style="margin:0px;">
                                        <div class="w15 h30 rx-sc">
                                            <LocationFilled class="w15 h15" v-if="'品牌总店' == store.storeName"></LocationFilled>
                                        </div>
                                        <div class="ml5">{{store.storeName}}</div>
                                    </div>
                                </el-dropdown-item>
                              </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                        <el-divider direction="vertical" border-style="solid"></el-divider>
						<div class="mr20 ml10">
                            <el-dropdown>
                                <div class="h50 rx-cc color-main">
                                  <span>{{userInfo.realName}}</span>
                                  <el-icon class="el-icon--right">
                                    <CaretBottom></CaretBottom>
                                    <!-- <arrow-down style="color:#fff;"/> -->
                                  </el-icon>
                                </div>
                                <template #dropdown>
                                  <el-dropdown-menu>
                                    <el-dropdown-item @click="outLogin">退出登录</el-dropdown-item>
                                  </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </div>
						<!-- <a href="javascript:void(0);" >
							<img style="opacity: 0.65;width: 17px;" class="mr15 c_p" src="../../assets/home/sousuo.png" >
						</a>
						<a href="javascript:void(0);" >
							<img style="opacity: 0.65;width: 17px;" class="mr15 c_p" src="../../assets/home/lingdang-xianxing.png" >
						</a>
						<a href="javascript:void(0);" >
							<img style="opacity: 0.65;" class="mr30 w15 c_p" src="../../assets/home/wang.png" >
						</a> -->
					</div>
				</div>
				<!-- <div class="pl20 rx-sc h40">
					<el-breadcrumb separator="/">
					    <el-breadcrumb-item  v-for="(item,index) in currentMenu.nav" :key="item">
					    	<span :class="[index == 0 ? 'color-000-45' : 'color-000-65']" class="ft12">{{item}}</span>
					    </el-breadcrumb-item>
					</el-breadcrumb>
				</div> -->
			</el-header>
			<el-main class="home_main" style="padding:0 !important;">
				    <keep-alive :include="store.state.keepLiveRoute">
						<router-view v-slot="{ Component }">
				           <component :is="Component" @update="updateStoreName"/>
					    </router-view>
				    </keep-alive>
			</el-main>
		</el-container>
	</el-container>
</template>

<script setup>
	import {ref,reactive,onMounted, onBeforeUnmount} from 'vue'
	import {useRouter} from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage, ElLoading } from 'element-plus'
	import { Location, Document, Menu as IconMenu, Setting, } from '@element-plus/icons-vue'
	import {Switch,ArrowDown,Upload,Download,CaretBottom,LocationFilled} from '@element-plus/icons-vue'
	import {getLocalStorage,enterStore} from '../../common/common.js';
	import {logout,getUserMenuListJson} from '../../api/login.js';
	import {loginByStore} from '../../api/store.js';
	const router = useRouter();
	const store = useStore();
	const nowStoreData = getLocalStorage('businessNowStoreData');
	const userInfo = getLocalStorage('businessUserData')
	const currentMenu = reactive({
		id : '1',
		url : 'dataCenter',
		nav : ["数据概览"]
	})

	function downloadList(){
		router.push('downloadList');
		currentMenu['id'] = '0';
		currentMenu['url'] = 'downloadList';
		currentMenu['nav'] = ['下载列表'];
	}

	function _getMenuItemByPath(path){
		path = path.indexOf('?') > -1 ? path.split('?')[0] : path
		var menuSelect = null;
	  	function getSelectItem(menuList){
	  		if(!menuList){
	  			return null;
	  		}
	  		for(var i=0;i<menuList.length;i++){
	  			if(menuList[i]['url'] == path){
	  				menuSelect = Object.assign({},menuList[i]);
	  				break;
	  			}
	  			if(menuList[i]['list'] && menuList[i]['list'].length > 0){
					getSelectItem(menuList[i]['list']);
	  			}
	  		}
	  	}
	  	getSelectItem(menuLeft.value);
	  	return menuSelect;
	}
	function flushPageUrl(url){
		var currentUrl = url || location.href.split('#')[1].replace('/','');
		var currentNowMenu = _getMenuItemByPath(currentUrl);
		if(currentNowMenu){
			currentMenu['id'] = currentNowMenu['id'];
			currentMenu['url'] = currentNowMenu['url'];
			currentMenu['nav'] = currentNowMenu['nav'];
		}else if(currentUrl == 'downloadList'){
			currentMenu['id'] = '0';
			currentMenu['url'] = 'downloadList';
			currentMenu['nav'] = ['下载列表'];
		}
	};

	const handleSelect = (key, keyPath) => {
		localStorage.setItem('mainHome', key)
	  	flushPageUrl(key);
	}

	var menuLeft = ref([]);
	function initMenuLeft(){
		const businessMenuList = getLocalStorage('businessMenuList');
		var tempMenuArray = [{
			id : 1,
			name : '数据概览',
			url : 'dataCenter',
			icon : require('../../assets/home/menu_icon1.png'),
			iconSelected : require('../../assets/home/select_menu_icon1.png'),
			nav : ['数据概览']
		}];
			var navObj = {
			1 : ['数据概览'],
			203 : ['经营类目','卡项管理'],
			208 : ['课程管理'],
			204 : ['组织架构','岗位管理'],
			205 : ['组织架构','员工管理'],
			206 : ['门店管理','门店信息'],
			106 : ['数据统计','收银台数据'],
			207 : ['小程序搭建']
		};
		function addUrlMenu(level,item, index){
			var tempItem = {
				name : item['menuName'],
				id : item['id']+''
			}
			if(level == 1){
				tempItem['icon'] = require('../../assets/home/menu_icon'+ (index + 1) +'.png');
				tempItem['iconSelected'] = require('../../assets/home/select_menu_icon'+ (index + 1) +'.png');
			}
			if(item['menuUrl'] && level != 1){
				tempItem['url'] = item['menuUrl'];
			}
			if(item['list'] && item['list'].length > 0){
				var list = [];
				for(var i=0;i<item['list'].length;i++){
					list.push(addUrlMenu(level + 1,item['list'][i]));
				}
				tempItem['list'] = list;
			}
			tempItem['nav'] = navObj[tempItem['id']];
			return tempItem;
		}

		for(var i=0;i<businessMenuList.length;i++){
			tempMenuArray.push(addUrlMenu(1,businessMenuList[i], (i+1)));
		}
		menuLeft.value = tempMenuArray;
		flushPageUrl();
	}
	//切换菜单
	function changeMenuNum(id){
		currentMenu.id = id;
		var obj = menuList.value.filter(item =>item.id == id);
		obj[0].nav ? currentMenu.nav = obj[0].nav : '';
	}
	//切换店铺
	function handleChangeStore(command){
		var bid = command.split('-')[0];
        var sid = command.split('-')[1];
        var item = sid == 0 ? -1 : 1;
        const loading = ElLoading.service({
            lock: true,
            text: '正在切换中，请稍后。。。'
          })
		enterStore(item,bid,sid,router,function(){
			loading.close()
		});
	}
	
	//退出登录
	function outLogin() {
		var access_token = getLocalStorage('businessLoginData').access_token;
		//执行退出接口
		// logout({access_token}).then((res)=>{
			// if(res.code == 0){
				ElMessage({
					message:'退出成功',
					type: 'success'
				})
				setTimeout(()=>{
					var orderTips = localStorage.getItem('orderTips'); //前台场地预订引导
					localStorage.clear();
					sessionStorage.clear();
					if(orderTips){
						localStorage.setItem('orderTips',orderTips);
					}
					router.push('/login');
				},1000)
			// }
		// })
	}
	initMenuLeft();
	const sysUserStoreList = reactive([]);
	function updateStoreName(data){
		sysUserStoreList.map((n,i)=>{
			if(n.id == data.id){
				sysUserStoreList[i].storeName = data.name
			}
		})
	}
    onMounted(()=>{
        // 获取切换店铺列表
		if (localStorage.getItem('mainHome') != null) {
            flushPageUrl(localStorage.getItem('mainHome'))
	    }
        var businessNowStoreData = getLocalStorage('businessNowStoreData');
        var storeList = businessNowStoreData.sysUserStoreList || [];
        for(var i=0;i<storeList.length;i++){
            sysUserStoreList.push(storeList[i]);
        }
    });
	onBeforeUnmount(() => {
		localStorage.removeItem('mainHome')
	})
</script>

<style lang="stylus" scoped>
	.home_container{
		position absolute;
		left 0;
		right 0;
		bottom 0;
		top 0;
		.menu_box{
			background-color #001529;
			.menu_user_span{
				display: -webkit-box; 
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
			}
			.menu_ul{
				li{
					height 40px;
					line-height 40px;
					color white;
					cursor pointer;
					opacity .4;
				}
				li.nav{
					opacity 1;
				}
				li.nav.active{
					border-radius 0 100px 100px 0;
					background-color #E6F7FF !important;
					position relative;
					span{
						color #0486FE;
					}
				}
				li.nav:hover{
					border-radius 0 100px 100px 0;
					background-color #E6F7FF20;
					position relative;
				}
				li.nav.active::after,li.nav:hover::after{
					content '';
					position absolute;
					top 0;
					left 0;
					bottom 0;
					border-left 4px solid #0486FE;
				}
			}
		}
		.home_main{
			background-color #F5F5F5;
		}
	}
	.img_hover{
		opacity:0.65;
		width:16px;
	}
	.img_hover:hover{
		opacity:1;
		width:18px;
	}
	.el-divider--vertical{
		border-left:1px solid #00a3ff;
		margin-left:20px;
		margin-right:20px;
	}
</style>
