<template>
	<div class="basic_config rx-ss">
		<div class="basic_left hh100 w300 p20" v-if="tab != '4'">
			<div>
				<h3>{{storeData.title}}</h3>
				<p class="mt10 mb10 color-999 ft12">{{storeData.subTitle}}</p>
			</div> 
			<MoveSort :key="(new Date).getTime()" :list="storeData.list" :moveover="moveover" :first="true">
				<template #[index] v-for="(item,index) in storeData.list" :key="index">
					<div class="pt5 pb5">
						<div class="moveItem pl20 bg-fff pr20" :class="{active:pageData.itemIndex == index}" @click="clickLi(index)">
							<div class="move_icon" v-if="index > 0">
								<unicon width="15px" name="align-center-v" fill="#C2C2C4"></unicon>
							</div>
							<div class="rx-bc" style="height: 44px;">
								<div>{{item.name}}</div>
								<span v-if="item.tips" class="ft12 color-999">{{item.tips}}</span>
								<div v-if="index > 0"><el-switch :disabled="item.name == '体测中心'" v-model="item.moduleStatus"/></div>
							</div>
						</div>
					</div>
				</template>
			</MoveSort>
		</div>
		<div class="w350">
			<div class="basic_phone">
				<div class="rx-bc basic_phone_title" :style="{'backgroundColor':store.state.appletFormData[4].list[0].bgColor == 2 ? '#14141A' : '',color:store.state.appletFormData[4].list[0].bgColor == 2 ? '#fff' : ''}">
					<el-icon><arrow-left /></el-icon>
					<b>{{pageData.title}}</b>
					<el-icon><more-filled /></el-icon>
				</div>
				<div class="basic_phone_cont ww100 hh100" :style="{'backgroundColor':store.state.appletFormData[4].list[0].bgColor == 2 ? '#14141A' : ''}">
					<component :is="centerShowComponent" :tab="tab" :itemIndex="pageData.itemIndex" :key="(new Date()).getTime()"></component>
				</div>
			</div>
		</div>
		<div class="w790 pt20 pl20 pb20 pr20" style="background-color: #FAFAFC; height: 74vh; overflow-y: scroll;">
			<component :is="pageData.rightShowComponent" :key="(new Date()).getTime()" :tab="tab" :item-index="pageData.itemIndex" :sid="sid"></component>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  sid:String
	})
	import {ref,reactive} from 'vue';
	import { useStore } from 'vuex'
	import {ArrowLeft,MoreFilled} from '@element-plus/icons-vue'
	import MoveSort from './MoveSort.vue'
	import HomeConfig from './center_page/HomeConfig.vue' //中间预览首页组件
	import OrderConfig from './center_page/OrderConfig.vue' //中间预览预约组件
	import MyConfig from './center_page/MyConfig.vue'  //中间预览我的组件
	import NavConfig from './center_page/NavConfig.vue' //中间预览导航组件
	import ThemeConfig from './center_page/ThemeConfig.vue' //中间预览导航组件
	import StoreInfo from './right_page/StoreInfo.vue' //右侧门店信息
	import FunEntry from './right_page/FunEntry.vue' //右侧功能入口
	import CoachIntroduction from './right_page/CoachIntroduction.vue' //右侧教练介绍
	import RecommendedCourses from './right_page/RecommendedCourses.vue' //右侧推荐课程
	import RecommendedCycles from './right_page/RecommendedCycles.vue' //右侧推荐班级
	import CourseCard from './right_page/CourseCard.vue' //右侧会员卡
	import EntranceCard from './right_page/EntranceCard.vue' //右侧会员卡
	import StoredCard from './right_page/StoredCard.vue' //右侧储值卡
	import ExperienceCard from './right_page/ExperienceCard.vue' //右侧体验卡
	import OrderType from './right_page/OrderType.vue'  //右侧课程预约
	import MemberAssets from './right_page/MemberAssets.vue'  //右侧会员资产
	import MoreService from './right_page/MoreService.vue' //右侧更多服务
	import BottomNav from './right_page/BottomNav.vue'  //右侧底部导航
	import SelectTheme from './right_page/SelectTheme.vue' //右侧选择主题
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab];
	//中间手机预览组件页面
	const centerComponents = [HomeConfig,OrderConfig,MyConfig,NavConfig,ThemeConfig];
	const centerShowComponent = ref(centerComponents[propData.tab]); //当前选中的tab
	const pageData = reactive({
		itemIndex:0,
		rightShowComponent:'',
		title:propData.tab == 1 ? '约课' : '首页'
	})
	//右侧设置页面组件
	const rightComponents = {
		StoreInfo,
		FunEntry,
		CoachIntroduction,
		RecommendedCourses,
		RecommendedCycles,
		CourseCard,
		EntranceCard,
		StoredCard,
		ExperienceCard,
		OrderType,
		MemberAssets,
		MoreService,
		BottomNav,
		SelectTheme
	};
	storeData.list.forEach(item => {
		if (item.name == '体测中心') {
			item.moduleStatus = false
		}
	})
	clickLi(0);
	//根据左侧切换右侧组件
	function clickLi(index){
		storeData.active = index;
		pageData.itemIndex = index;
		var comName = storeData.list[index].component;
		pageData.rightShowComponent = rightComponents[comName];
		// updateAppletData(storeData);
	}
	// function moveover(list){
	// 	console.log()
	// }
	//更新store
	function updateAppletData(data){
		store.commit('setAppletData',{name:propData.tab,value:data});
	}
</script>

<style scoped lang="stylus">
	.basic_config{
		height: 74vh;
		background-color white;
		.basic_left{
			min-height: 600px
			background-color #FAFAFC;
			.moveItem{
				position relative;
				.move_icon{
					position absolute;
					left 0;
					top 0;
				}
			}
			.active{
				background-color #EFF6FF;
				border 1px dashed #409EFF;
			}
		}
		.basic_phone{
			margin 0 auto;
			width 300px;
			height 600px
			padding 77px 21px 20px 21px;
			background:url(../../../assets/simulator.png) no-repeat center center;
			background-size contain;
			position relative;
			.basic_phone_title{
				position absolute;
				top 5px;
				top: 46px;
				left: 21px;
				right: 21px;
				height: 31px;
				padding 0 10px;
				color #333;
			}
			.basic_phone_cont{
				border-radius 0 0 35px 35px;
				overflow auto;
				position relative;
			}
		}
	}
	
</style>

