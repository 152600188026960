<template>
	<template v-for="(item,index) in storeData.list" :key="index">
		<div class="pl10 pr10" :class="{compActive:storeData.active == index,block:index > 0 && index < 3 && storeData.list[1].moduleStatus && storeData.list[2].moduleStatus }"  v-show="item.moduleStatus">
			<component :is="data.components[item.component]" :key="(new Date()).getTime()" :itemIndex="index" :tab="tab"></component>
		</div>
	</template>
</template>

<script setup>
	const propData = defineProps({
	  tab:String
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	// import MoveSort from '../MoveSort.vue'; //通用移动排序
	import MemberAssets from './myConfig/MemberAssets.vue'; //功能入口
	import AllClass from './myConfig/AllClass.vue'; //功能入口
	import TestCenter from './myConfig/TestCenter.vue'; //功能入口
	import MoreService from './myConfig/MoreService.vue'; //门店介绍
	const data = reactive({
		components:{
			MemberAssets,
			AllClass,
			TestCenter,
			MoreService
		}
	})
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab];
</script>

<style scoped lang="stylus">
	.compActive{
		border 1px dashed #409eff;
	}
	.block{
		display inline-block;
		width 50%;
	}
</style>
