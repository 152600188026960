<template>
    <div>
        <formQuery :queryOpts="pageData.queryOpts" ref="queryOptsRef" @query="searchGetList"></formQuery>
        <el-table v-loading="loading" v-show="pageData.tableData" stripe :data="pageData.tableData">
            <el-table-column prop="courseName" width="200" label="课程名称">
				<template #default="scope">
					<span>{{ scope.row.courseName }}</span>
					<span class="pl5 pr5 ml5" v-if="scope.row.ifMakeUpCourse == 1" :style="{ color: '#FFF',backgroundColor:'#F96667',border:'1px solid #F96667' }">补课</span>
				</template>
			</el-table-column>
            <el-table-column prop="name" label="课程类型">
               <template #default="scope">
				   	<div v-if="scope.row.courseType">
						<span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30', border:'1px solid '+ courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
				   	</div>
				</template>
            </el-table-column>
            <el-table-column prop="classTimeStr" label="上课时间" >
				<template #default="scope">
					<div>{{ scope.row.day ? scope.row.day : ''  }}</div>
					<div class="mt5">{{ scope.row.time ? scope.row.time : '' }}</div>
				</template>
			</el-table-column>
            <el-table-column prop="teacherName" label="教练" />
            <el-table-column prop="orderPeopleNum" label="预约人数" />
            <el-table-column label="预约状态" width="210">
				<template #default="scope">
                    <div v-if="scope.row.state == 4">
				    	<span class="d_i_b w10 h10 bdr8 badge1 mr5">
				    		<span class="d_i_b w5 h5 v_a_m bdr4 badge"></span>
				    	</span>
				    	<span>预约取消</span>
				    	<span class="ft10 ml5 color-999" v-if="scope.row.updateBy">{{ scope.row.updateBy == 0 ? '会员自主取消' : '教练代取消' }}</span>
				    </div>
					<div v-else-if="scope.row.state == 5">
						<span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5"></span>
						<span>预约成功</span>
					</div>
					<div v-else-if="scope.row.state == 6">
						<span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5"></span>
						<span>已补课</span>
					</div>
				    <div v-else>
				    	<span class="d_i_b w5 h5 v_a_m bdr4 mr5" :class="scope.row.state == 1 ? 'badge-success' : scope.row.state == 3 ? 'badge-info' : 'badge-warning'"></span>
				    	<span>{{ filtersType(scope.row.state, 1) }}<span v-if="scope.row.state == 3" class="ft10 ml5 color-999">候补失败</span></span>
				    </div>
                </template>
			</el-table-column>
            <el-table-column label="签到状态">
                <template #default="scope">
					<el-button size="small" v-if="scope.row.alreadyCome == 0 && scope.row.state != 5" type="success">未签到</el-button>
					<el-button size="small" v-if="scope.row.alreadyCome == 1" type="primary">已签到</el-button>
                    <el-button size="small" v-if="scope.row.alreadyCome == 0 && scope.row.state == 5" type="danger">已请假</el-button>
				</template>
            </el-table-column>
            <el-table-column prop="createTime" label="预约时间" show-overflow-tooltip />
            <el-table-column label="操作">
               	<template #default="scope">
					<div v-if="scope.row.courseType == 21">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="(scope.row.payMethodId == 100 || (scope.row.payMethodId == 3 && scope.row.createBy== 0))" @click="loadEvent(scope.row)">查看退款详情</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 1 && utils.getMenuRole(3130013)" @click="confirmEvent(scope.row, 'cancelOrder')">取消预约</a>
					</div>
					<div v-else>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.alreadyCome && utils.getMenuRole(3130009)" @click="printTicketEvent(scope.row)">打印小票</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 2 && utils.getMenuRole(3130010)" @click="confirmEvent(scope.row.encryptionCourseInfoId,'enterClass')">入课</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 2 && utils.getMenuRole(3130012)" @click="confirmEvent(scope.row,'cancelAlternate')">取消候补</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && scope.row.state == 1 && utils.getMenuRole(3130011)" @click="confirmEvent(scope.row.encryptionCourseInfoId,'signIn')">签到</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && scope.row.state == 1 && utils.getMenuRole(3130013)" @click="confirmEvent(scope.row, 'cancelOrder')">取消预约</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="(scope.row.payMethodId == 1 || scope.row.refundState == 3)" @click="loadEvent(scope.row)">查看退款详情</a>
					</div>
				</template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc" v-show="pageData.tableData.length > 0">
            <!-- <div class=" rx-c">
                <h3 class="mr20">消费次数总计：<span class="color-red">{{ 0 }}</span> 次</h3>
                <h3>消费金额总计：<span class="color-red">{{ 0 }}</span> 元</h3>
            </div> -->
		    <el-pagination
		        v-model:currentPage="pageData.searchForm.page"
		        :page-sizes="[15,20,30,50,100]"
		        :page-size="pageData.searchForm.limit"
		        layout="total, sizes, prev, pager, next, jumper"
		        :total="pageData.totalCount"
		        @size-change="pageSizeChange"
		        @current-change="()=>{getList()}"
		    />
		</div>
		<el-dialog
		    v-model="pageData.dialogVisible"
		    title="查看退款详情"
		    width="370px"
		  >
		    <div class="p20 pt30 pb30">
				<div class="timeline_left">
					<img class="w15 h15 mr15 v_a_m" src="@/assets/order1.png" alt="">
					<span class="color-666" v-if="pageData.activities.state == 4">
						<span v-if="pageData.courseType == 21">{{ pageData.updateBy == 0 ? '会员已取消' : '教练代取消' }}</span>
						<span v-else>会员已取消</span>
						预约，已发起自动退款
					</span>
					<span class="color-666" v-else>"会员候补失败，已发起自动退款</span>
				</div>
				<div class="h25" style="line-height: 25px">
					<el-divider direction="vertical" border-style="dashed" />
				</div>
				<div class="timeline_right">
					<div v-if="pageData.activities.refundState == 1">
						<img class="w15 h15 mr15 v_a_m" src="@/assets/order2.png" alt="">
						<span class="color-666">退款成功，费用已退回至原支付账户</span>
					</div>
					<div v-if="pageData.activities.refundState == 3">
						<img class="w15 h15 mr15 v_a_t" style="margin-top: 2px;" src="@/assets/order3.png" alt="">
						<div class="color-666 d_i_b w300" v-if="pageData.activities.buyPayType == 0">退款失败，会员预约付费所使用的资产已失效，请联系会员线下处理</div>
						<div class="color-666 d_i_b w300 " v-else>退款失败，门店商户账户可用余额不足，请到订单管理中找到该笔订单进行手动退款</div>
					</div>
				</div>
			</div>
		    <template #footer>
		    	<span class="dialog-footer">
		    	  <el-button type="primary" @click="pageData.dialogVisible = false">好 的</el-button>
		    	</span>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<PrintTicket v-show="pageData.printShow" v-model="pageData.printShow" type="0" :id="pageData.courseInfo" @save="printSave"></PrintTicket>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import PrintTicket from '@/components/branch-store/PrintTicket.vue';
	import Confirm from '@/components/Confirm.vue'
	import { list4Member } from '@/api/intentionList.js';
	import { ElMessage } from 'element-plus'
	import utils from '@/common/utils.js'
	import { courseInfoSign, storeBookCancel, storeBookAfterCancel, courseAfterBatchTurnClass } from '@/api/course.js';
	import { storeOrderRoomCancel } from '@/api/appstore.js';
    const propData = defineProps({
		info: Object
	})
    const queryOptsRef = ref()
    const confirmRef = ref();
	const courseTypeList = [
		{label : '全部课程类型' ,value : ''},
		{label : '团体课' ,value : '11'},
		{label : '小班课' ,value : '13'},
		{label : '私教1V1' ,value : '14'},
		{label : '私教1V多' ,value : '15'},
	]
    const pageData = reactive({
		dialogVisible:false,
		activities: {},
        loading: false,
        tableData:[],
        totalCount: 0, // 表格总条数
        searchForm: {
            page: 1,
            limit: 15
        },
        printShow:false, //打印小票
		courseInfo:{},
		confirmTitle:'',
		confirmText:'',
        queryOpts: {
            fields: [{
				type : 'input',
				fieldName : 'courseName',
				placehold : '课程名称',
                value:''
			}, {
                type : 'select',
                fieldName : 'courseType',
                placehold : '课程类型',
                options :courseTypeList,
                value:''
            }, {
                type : 'select',
                fieldName : 'state',
                placehold : '预约状态',
                options : [{label : '全部预约状态' ,value : ''},{label : '预约成功' ,value : '1'},{label : '预约失败' ,value : '3'},{label : '候补中' ,value : '2'},{label : '预约取消' ,value : '4'}],
                value: ''
            }, {
                type : 'select',
                fieldName : 'alreadyCome',
                placehold : '签到状态',
                options : [{label : '全部签到状态' ,value : ''},{label : '已签到' ,value : '1'},{label : '未签到' ,value : '0'}],
                value: ''
            }, {
				type : 'dateRange',
				fieldName : 'createTimeStr',
				placeholder : '预约时间',
				width:380,
				value:''
			}]
        }
    })
	const stateType = [
        { value : '1' , label : '预约成功' },
        { value : '3' , label : '预约失败' },
        { value : '2' , label : '候补中' },
        { value : '4' , label : '预约取消' }
    ]
	
	//课程类型
	const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		21:{
			text:'场地预约',
			color:'#9476FF'
		},
	}
	/**
     * @param (String, Number) value-类型, type-1 课程类型 2-预约状态
     * 表格字段过滤过滤展示
     */
	function filtersType(value) {
		let text = ''
        stateType.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    let confirmType = ''//通用确认调用类型
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取预约记录
     */
    function getList() {
        pageData.loading = true
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		params.createTimeStr = params.createTimeStr ? params.createTimeStr.join('-') : '';
		params.encryptionCardMyId = propData.info.encryptionId
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        list4Member(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				pageData.tableData = res.data.map((n)=>{
					var time = n.classTimeStr.substring(0,10)
					var s = n.classTimeStr.substring(11,16);
					var e = n.classTimeEndStr ? n.classTimeEndStr.substring(11,16) : n.classTimeStr.substring(17,22)
					n.day = time
					n.time = s +' - '+ e;
					return n;
				})
                pageData.totalCount = res.count
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * @param(Object) row-预约详情数据
     * 打印小票
     */
	function printTicketEvent(row){
		pageData.courseInfo = row.encryptionId;
		pageData.printShow = true;
	}
	function printSave(){
		pageData.printShow = false;
	}
	/**
	 * 查看退款详情
	 */
	function loadEvent(row) {
		pageData.activities = row
		pageData.dialogVisible = true
	}
    /** 提示弹框 */
    let rowId = '';
	function confirmEvent(id,type){
		rowId = id;
		if(type == 'enterClass'){ //入课
			pageData.confirmTitle = '确定入课吗？';
			pageData.confirmText = '会员入课即预约成功，入课后该课节人数将超过<span class="color-red"> 1 </span>人！';
		}else if(type == 'cancelAlternate'){  //取消候补
			pageData.confirmTitle = '确定取消候补吗？';
			pageData.confirmText = '会员候补时已扣费，取消候补后费用将原路退回！ ';
		}else if(type == 'signIn'){ //签到
			pageData.confirmTitle = '是否确认签到？';
            pageData.confirmText = ''
		}else if(type == 'cancelOrder'){ //取消预约
			pageData.confirmTitle = '确定取消预约吗？';
			pageData.confirmText = '会员预约成功时已扣费，取消预约后将归还会员资产！';
		}
		confirmType = type;
		confirmRef.value.show();
		
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'enterClass'){
			courseAfterBatchTurnClass({
				ids:rowId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage.success('入课成功')
				}
			})
		}else if(confirmType == 'cancelAlternate'){
			let params = {
        	    encryptionId: rowId.encryptionCourseId,
        	    encryptionCardMyId: rowId.encryptionCardMyId,
        	    type: 4
        	}
			storeBookAfterCancel(params).then((res) => {
				if(res.code == 0){
					ElMessage.success('取消成功')
					getList()
				}
			})
		}else  if(confirmType == 'signIn'){
			let params = {
        	    ids: rowId,
        	    alreadyCome: 1,
        	    signType: 6
        	}
        	courseInfoSign(params).then((res) => {
        	    if (res.code == 0) {
					ElMessage.success('签到成功')
        	        getList()
        	    }
        	})
		}else if(confirmType == 'cancelOrder'){
			if (rowId.courseType == 21) {
				let params = {
        		    encryptionId: rowId.encryptionId
        		}
        		storeOrderRoomCancel(params).then(res => {
        		    if (res.code == 0) {
        		        ElMessage({
							type: 'success',
							message: '取消成功'
						})
        		        getList()
        		    }
        		})
			} else {
				let params = {
        		    encryptionId: rowId.encryptionCourseId,
        		    encryptionCardMyId: rowId.encryptionCardMyId,
        		    type: 3
        		}
				storeBookCancel(params).then((res) => {
					if(res.code == 0){
						ElMessage.success('取消成功')
						getList()
					}
				})
			}
			
		}
	}
	onMounted(() => {
		getList()
	})
</script>
<style type='css/style' scoped>
	.badge {
		background: #FA6400;
		box-shadow: 0 0 5px rgba(250, 100, 0, 1);
	}
	.badge-success {
		background: #44D7B6;
		box-shadow: 0 0 5px rgba(68, 215, 182, 1);
	}
	.badge-info {
		background: #ccc;
		box-shadow: 0 0 5px rgba(204, 204, 204, 1);
	}
	.badge-warning {
		background: #F7B500;
		box-shadow: 0 0 5px rgba(247, 181, 0, 1);
	}
</style>