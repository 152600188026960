<template>
    <el-dialog
	    v-model="pageData.addShow"
	    :title="'选择展示' + pageData.title"
	    width="700px"
	    :before-close="handleClose"
	>
	    <div class="p20">
		    <div class="rx-sc mb12">
		    	<span class="mr16">{{ pageData.title }}名称</span>
		    	<div class="w150 mr16">
		    		<el-input type="text" v-model="pageData.courseName" placeholder="输入关键字"></el-input>
		    	</div>
		    	<span class="mr16">{{ pageData.title }}类型</span>
		    	<div class="w150 mr16">
		    		<el-select v-model="pageData.courseType" placeholder="请选择">
                        <el-option v-for="item in pageData.selectList" :key="item" :label="item.label" :value="item.value"></el-option>
		    		</el-select>
		    	</div>
		    	<el-button :icon="Search" type="primary" @click="getList">查询</el-button>
		    </div>
		<el-table
            v-loading="pageData.loading"
		    ref="multipleTableRef"
		    :data="pageData.tableData"
		    style="width: 100%"
			height="550px"
			:row-key="getRowKey"
			@selection-change="selectionChange"
		>
			<el-table-column type="selection" :reserve-selection="true" align="center" width="55"/>
		    <el-table-column :label="pageData.title+'信息'" width="250">
		        <template #default="scope">
				    <div class="rx-sc">
				    	<img class="w120 h80 mr10 bdr4" :src="pageData.title == '班级' ? scope.row.courseCoverPic : pageData.title == '课程' ? scope.row.courseCover : scope.row.productCover" alt="">
				    	<div v-if="pageData.title == '课程'">
				    		<h3 class="mb15">{{ scope.row.courseName }}</h3>
				    		<p class="color-999">{{ scope.row.orderPrice ? '可单次预约' :'不可单次预约' }}</p>
				    	</div>
						<div v-else-if="pageData.title == '班级'">
							<h3 class="mb15">{{ scope.row.cycleName }}</h3>
						</div>
                        <div v-else>
				    		<h3 class="mb15">{{ scope.row.productName }}</h3>
				    		<p class="color-999" v-if="pageData.title == '储值卡'">含{{scope.row.storeCardValue+'元/'+ (scope.row.productTyp == 1 ? scope.row.productNum + '次' : (scope.row.ifMonth == 1 ? '天' :scope.row.ifMonth == 2 ? '月' : '年'))}}</p>
                            <p class="color-999" v-else>含{{scope.row.productTyp == 1 ? scope.row.productNum+'次' : (scope.row.ifMonth == 1 ? '天' :scope.row.ifMonth == 2 ? '月' : '年')}}</p>
				    	 </div>
				    </div>
			    </template>
		    </el-table-column>
		    <el-table-column v-if="pageData.title == '课程'" label="课程类型">
                <template #default="scope">
				   	<div v-if="scope.row.courseType">
						<span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30', border:'1px solid '+ courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
				   	</div>
				</template>
			</el-table-column>
            <el-table-column v-else :label="pageData.title+'类型'">
				<template #default="scope">
					<div v-if="pageData.title == '班级'">
						<div class="w60 h25 t_a_c qika">{{ scope.row.cycleType == 11 ? '固定上课模式' :'自主预约模式' }}</div>
					</div>
					<div v-else>
						<div class="w60 h25 t_a_c cika" v-if="scope.row.productTyp == 1">次卡</div>
						<div class="w60 h25 t_a_c qika" v-else>期限卡</div>
					</div>
				</template>
			</el-table-column>
            <el-table-column v-if="pageData.title != '班级' && pageData.title != '课程' && pageData.title != '体验卡'" prop="productPrice" label="售价" />
            <el-table-column v-if=" pageData.title == '体验卡'" prop="experiencePrice" label="售价" />
			<el-table-column prop="storeName" label="发布渠道" show-overflow-tooltip />
		</el-table>
		<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
            <el-pagination
                v-model:currentPage="pageData.page"
                :page-sizes="[15,20,30,50,100]"
                :page-size="pageData.limit"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.totalCount"
                @size-change="pageSizeChange"
                @current-change="()=>{getList()}"
           />
        </div>
	    </div>
	    <template #footer>
	        <div class="dialog-footer">
	            <el-button @click="handleClose">取消</el-button>
	            <el-button type="primary" @click="addSave">确定</el-button>
	        </div>
	    </template>
	</el-dialog>
</template>

<script setup>
    import { ref, reactive, nextTick } from 'vue';
    import { Search } from '@element-plus/icons-vue'
    import { appletList } from '@/api/applet.js'
    import { productList } from '@/api/cards.js'
    import { courseCycleList } from '@/api/course.js'
	import { ElMessage } from 'element-plus'
    const emit = defineEmits(['handleClose','addSave'])
    const multipleTableRef = ref()
    const pageData = reactive({
        loading: false,
        title: '',
        courseName: '',
        courseType: '',
        addShow:false,
		tableData:[],
		page:1,
		limit:15,
		totalCount: 0,
		selectCheckData: [],
        checkList: [],
        selectList: [],
        sid: ''
    })
    const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
	}
    /**
     * 获取列表
     */
	function getList(){
        pageData.loading = true
		let params = {
			page: pageData.page,
			limit: pageData.limit
		}
        if (pageData.title == '课程' ) {
            params.storeId = pageData.sid;
            params.courseType = pageData.courseType;
            params.courseName = pageData.courseName;
            appletList(params).then((res)=>{
                pageData.loading = false
            	if(res.code == 0){
            		pageData.tableData = res.data.map(n=>{
						n.httpPath = n.courseCover;
						return n;
					})
            		pageData.totalCount = res.count
            	}
            }).catch(() => {
                pageData.loading = false
            })
        } else if (pageData.title == '班级' ) {
            params.sid = pageData.sid;
            params.cycleType = pageData.courseType;
            params.sellTypeInt = 1;
            courseCycleList(params).then((res)=>{
                pageData.loading = false
		    	if(res.code == 0){
		    		pageData.tableData = res.data
		    		pageData.totalCount = res.count
		    	}
		    }).catch(() => {
                pageData.loading = false
            })
        } else {
            params.productTyp = pageData.courseType;
		    params.productName = pageData.courseName;
            params.type = pageData.type;
            productList(params).then((res)=>{
                pageData.loading = false
		    	if(res.code == 0){
		    		pageData.tableData = res.data;
		    		pageData.totalCount = res.count
		    	}
		    }).catch(() => {
                pageData.loading = false
            })
        }
        
	}
    /**
     * 数据选择
     */
	function selectionChange(data) {
		pageData.selectCheckData = data
	}
    /**
     * 每页多少条
     */
	function pageSizeChange(limit) {
		pageData.limit = limit;
		getList();
	}
    /**
     * 记住分页
     */
    function getRowKey(row) {
        if (pageData.title == '课程') {
            return row.courseNameId
        } else if (pageData.title == '班级') {
            return row.id
        } else {
            return row.productId
        }
      
    }
    function addSave() {
		if(pageData.selectCheckData.length > 20){
			ElMessage({
				type: 'warning',
				message: '选择'+pageData.title+'不能超过20个',
			})
			return false;
		}
        emit('addSave', pageData.selectCheckData);
        multipleTableRef.value.clearSelection()
		pageData.addShow = false
	}
    /**
     * 取消编辑课节弹框
     */
    function handleClose() {
       multipleTableRef.value.clearSelection()
       pageData.addShow = false
       emit('handleClose');
    }
    function show(){
        pageData.addShow = !pageData.addShow;
	}
    /**
     * 获取信息
     */
    function getInfo(data) {
        pageData.sid = data.sid
        pageData.storeData = data.storeData
        pageData.title = data.title
        pageData.selectCheckData = data.checkedList.length ? data.checkedList : []
        pageData.type = data.type
        pageData.selectCheckData.length && pageData.selectCheckData.map((item)=>{
            nextTick(()=>{
               multipleTableRef.value.toggleRowSelection(item, true)
            })
        })
        if (data.title == '课程') {
            pageData.selectList= [{ label: '私教课', value: '12' }, { label: '团体课', value: '11' }]
        } else if (data.title == '班级') {
            pageData.selectList= [{ label: '固定上课模式', value: '11' }, { label: '自由预约模式', value: '12' }]
        } else {
            pageData.selectList= [{ label: '次卡', value: '1' }, { label: '期限卡', value: '3' }]
        }
        getList()
    }
    defineExpose({
	  show,
      getInfo
	})
</script>
<style type='css/style' scoped>
    h3 {
		width: 96px;
        overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
    .cika {
        background: rgba(255, 100, 0, 0.2);
        border-radius: 2px;
        border: 1px solid #FF6400;
        color: #FF6400;
        line-height: 25px;
    }
    .qika{
        background: rgba(68, 215, 182, 0.2);
        border-radius: 2px;
        border: 1px solid #44D7B6;
        color: #44D7B6;
        line-height: 25px;
    }
</style>