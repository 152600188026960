import {postAjax,getAjax}  from "../common/axios.js"

//登录
export const changeToStore = params => {
	return postAjax('/api/system/changeToStore',params)
}
//分店登录
export const loginByStore = params => {
	return postAjax('/api/system/loginByStore',params)
}
//分店信息
export const getBusinessStores = params => {
	return postAjax('/api/system/getBusinessStores',params)
}
//跨店开关
export const updateStore = params => {
	return postAjax('/api/system/updateStore',params)
}

//编辑
export const lineStoreEdit = params => {
	return postAjax('/api/system/lineStoreEdit',params)
}


