import {postAjax,getAjax}  from "../common/axios.js"

// 下载列表
export const getDownloadList = params => {
    return postAjax('/api/download/getDownloadList',params)
}

export const getDownloadNow = params => {
    return postAjax('/api/download/downloadNumber',params)
}


