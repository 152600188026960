<template>
	<div class="course_list">
		<div class="rx-bc mb5">
			<div class="color-333 fwb course_title" :style="{'border-left': '4px solid ' + themeBgColor.list[themeBgColor.active].textColor}" :class="{'color-fff':themeBgColor.bgColor == 2}">推荐班级</div>
			<div class="ft12" :class="{'color-fff':themeBgColor.bgColor == 2}">全部 ></div>
		</div>
		<ul>
			<li class="pt10 pb10" v-for="item in storeData.list" :key="item.id" :class="storeData.showType == 1 ? 'ww50' :'ww100'">
				<img class="ww100 mr10" :src="item.courseCoverPic" >
				<div>
					<h3 class="maxLength color-333 mb5" :class="{'color-fff':themeBgColor.bgColor == 2}">{{item.cycleName}}</h3>
					<p>
						<template v-for="n in item.labels" :key="n">
							<span class="color-999 ft12 mr5 pl5 pr5 mb5" :class="{'color-fff':themeBgColor.bgColor == 2}">{{n}}</span>
						</template>
					</p>
				</div>	
			</li>
		</ul>
	</div>
</template>
<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeBgColor = store.state.appletFormData[4].list[0];
</script>

<style scoped lang="stylus">
	.course_list{
		.course_title{
			padding-left 5px;
		}
		ul{
			overflow hidden;
			li{
				float left;
				span{
					display inline-block;
					background-color #00000008
				}
			}
			li.ww100{
				border-bottom 1px solid #ccc;
				img{
					float left;
					width 80px;
					margin-right 10px;
					margin-top 5px;
					margin-left 5px;
				}
			}
			li.ww50:nth-child(2n-1){
				padding-right 5px;
			}
			li.ww50:nth-child(2n){
				padding-left 5px;
			}
			
		}
	}
	.maxLength {
		height: 20px;
    	line-height: 20px;
	}
</style>
