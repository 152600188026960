<template>
    <div class="pt20 pb20">
        <CommonTip text="展示通过收款码方式收款和退款的明细。"/>
        <div class="nav"> 
            <el-button class="mt16 mr20" type="primary" plain @click="collection">收款码 </el-button>
            <formQuery class="pt0" ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList" style="flex: 1"></formQuery>
        </div>
        <el-table class="mt10" v-loading="pageData.loading" stripe element-loading-text="拼命加载中。。。" :data="pageData.tableData">
			<el-table-column prop="transTime" label="时间" show-overflow-tooltip />
            <el-table-column label="类型">
                <template #default="scope">
                    <el-button v-if="scope.row.orderType == 1" type="primary" size="small">收款</el-button>
                    <el-button v-else type="danger" size="small">退款</el-button>
                </template>
            </el-table-column>
            <el-table-column label="金额" >
                <template #default="scope">
                    ￥{{ scope.row.ordAmt ? scope.row.ordAmt : 0 }}
                </template>
            </el-table-column>
            <el-table-column>
                <template #header>
                    <span class="v_a_m">手续费</span>
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      placement="top"
                    >
                    <template #content>收款类型：收款需要支付的手续费；<br />退款类型：需返还的手续费 </template>
                      <img class="w15 h15 ml5 v_a_m" src="@/assets/wenhao.png" >
                    </el-tooltip>
                </template>
                <template #default="scope">
                    ￥{{ scope.row.merFee ? scope.row.merFee : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="交易单号" show-overflow-tooltip>
                <template #default="scope">
                    {{ scope.row.outTransId ? scope.row.outTransId : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="batchCode" label="关联订单号" show-overflow-tooltip>
                <template #default="scope">
                    {{ scope.row.batchCode ? scope.row.batchCode :'-' }}
                </template>
            </el-table-column>
            <el-table-column label="备注" >
                <template #default="scope">
                    {{ scope.row.remark  ? scope.row.remark : '-' }}
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span class="mr20">
                    收款金额总计：
                    <span class="color-red mr5">
                        {{ pageData.remark.ordAmt || 0 }}
                    </span>元
                    手续费：
                    <span class="color-red mr5">
                        {{ pageData.remark.merFee || 0 }}
                    </span>元
                </span>
                <span>
                    退款金额总计：
                    <span class="color-red mr5">
                        {{ pageData.remark.ordAmtRef || 0 }}
                    </span>元
                    手续费：
                    <span class="color-red mr5">
                        {{ pageData.remark.ordAmtRefMerFee || 0 }}
                    </span>元
                </span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
        <img :src="pageData.imgUrl" alt="">
        <el-dialog
            v-model="pageData.dialogVisible"
            title="收款码"
            width="400px"
        >
            <div class="p20" id="downBox">
                <div class="collection-top pt30 pl30 pr30 pb30">
                    <div class="rx-c top-nav pb20">
                        <div class="w60 h60 top_img mr20">
                            <img class="ww100 hh100" ref="imgs" src="" alt="">
                        </div>
                        <dl>
                            <dt class="ft18 color-fff mb10">{{ userInfo.storeName }}</dt>
                            <dd class="color-fff">Payment   changes  life</dd>
                        </dl>
                    </div>
                    <div class="ft18 color-fff pt20 pb30 t_a_c">收银台</div>
                    <div class="w160 h160 pb20 bg-fff code-box">
                        <div id="qrcode" class="login_top_code pl10 pr10 pt10 pb10" v-loading="pageData.codeLoading"></div>
                    </div>
                </div>
                <div class="collection-footer rx-ac p20 pl30 pr30">
                    <img class="w85 h35" src="@/assets/zhifubao.png" alt="">
                    <img class="w90 h35" src="@/assets/weixin.png" alt="">
                </div>
            </div>
            <template #footer>
                <span class="dialog-footer">
                  <el-button type="primary" @click="downTable">下载收款码</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';
    import CommonTip from '@/components/CommonTip.vue'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
    import { getLocalStorage } from '@/common/common.js';
    import { upayOrderLog } from '@/api/finance.js';
    import { getUid } from '@/api/login.js'
    import html2canvas from 'html2canvas'
    import QRCode from 'qrcodejs2'
    import { getPayCode } from '@/api/pay.js'
	import { ElMessage } from 'element-plus'
    const userInfo = ref(getLocalStorage('businessUserData'));
    const queryOptsRef = ref()
    const imgs = ref(null)
    const pageData = reactive({
        imgUrl: '',
        loading: false,
        dialogVisible: false,
        codeLoading: false,
        tableData: [],
        searchForm: {
            limit: 15,
            page: 1
        },
        totalCount: 0,
        remark: {}
    })
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "7" , label : '最近30天' },
        { value : "20" , label : '本月' },
        { value : "-1" , label : '其他' }
    ]
    const queryOpts = reactive({});
    queryOpts['fields'] = [{
		type : 'input',
		fieldName : 'outTransId',
		placehold : '交易单号',
        value:''
	},{
		type : 'input',
		fieldName : 'batchCode',
		placehold : '关联订单号',
        value:''
	},{
        type : 'select',
        fieldName : 'timeIn',
        placehold : '支付时间',
        value : '5',
        options : timerList
    },{
        showField : 'timeIn', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'transTimeStr',
        placeholder : '日期范围',
        value:''
    },{
		type : 'select',
		fieldName : 'orderType',
		placehold : '',
		options: [{ label: '全部类型', value: '' },{ label: '收款', value: '1' },{ label: '退款', value: '2' }],
        value:''
	}]
    function getBase64Image(url) {
      var image = new Image();
      image.src = url + "?v=" + Math.random(); // 处理缓存
      image.crossOrigin = "*"; // 支持跨域图片
      image.onload = function () {
        var base64 = drawBase64Image(image)
        imgs.value.src = base64
      };
    }
    
    function drawBase64Image(img) {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png");
      return dataURL;
    }
    /**
     * 收款码
     */
    function collection() {
        getPayCode().then(res => {
			if (res.code == 0) {
				if (res.data && res.data.url) {
					pageData.dialogVisible = true
                    getBase64Image(userInfo.value.logoUrl) 
                    getUUid()
				} else {
					ElMessage.warning('请先开通收款码')
				}
			}
		})
        
    }
    const uuid = ref('');
	//获取扫码登录唯一key
	function getUUid(){
		getUid({}).then((res)=>{
			if(res.code == 0){
				uuid.value = res.data;
				createQrCode(res.data);
			}
		})
	}
    //生成二维码
	function createQrCode(uid){
        document.getElementById('qrcode').innerHTML = ''
        const token = getLocalStorage('businessToken');
		new QRCode('qrcode', {
		  width: 140,
		  height: 140, // 高度
		  text: location.origin +'/h5/payment.html?coodie=' + encodeURIComponent(token),
		  background: '#fff'
		})
		pageData.codeLoading = false;
	}
    /**
     * 切换每页条数
     */
    function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
    /**
     * 搜索
     */
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取表格数据
     */
    function getList() {
        let params = {}
        Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.timeIn == '-1' && !params.transTimeStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        if (params.timeIn == '-1' && params.transTimeStr) {
            params.transTimeStr = params.transTimeStr.join('-')
        }
        pageData.loading = true
        upayOrderLog(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				pageData.tableData = res.data
				pageData.totalCount = res.count
				pageData.remark = res.remark || {}
			}
		}).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 下载收款码
     */
    function downTable(){
      html2canvas(document.getElementById('downBox'), {
          useCORS: true,
      }).then((canvas)=>{
        pageData.dialogVisible = false
        const imgUrl = canvas.toDataURL('png');
        const aDom = document.createElement('a');
        aDom.href = imgUrl;
        aDom.download = '收款码';
        document.body.appendChild(aDom);
        aDom.click();
        aDom.remove();
      })
    }
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
    .nav{
		display: flex;
	}
    .derive {
        margin-top: 16px;
    }
    .collection-top {
         background: rgba(0, 163, 255, 1);
    }
    .top-nav {
        border-bottom: 1px dashed #FFF;
    }
    .top_img img {
        border-radius: 50%;
    }
    .code-box, .login_top_code {
        margin: auto;
    }
    .collection-footer {
         background: rgba(245, 245, 245, 1);
    }
</style>