<template>
    <workArea :barlist="barlist">
        <el-tabs class="pt20 pl20 pr20" v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="销售记录" name="0" v-if="utils.getMenuRole(32000)"/>
            <el-tab-pane label="退款记录" name="1" v-if="utils.getMenuRole(32001)"/>
            <el-tab-pane label="场地订单" name="2" />
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </workArea>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import salesRecords from '@/views/branch-store/finance/sale/salesRecords.vue'; // 销售记录
    import refundRecords from '@/views/branch-store/finance/sale/refundRecords.vue'; //  退款记录
    import fieldOrder from '@/views/branch-store/finance/sale/fieldOrder.vue'; //  退款记录
	import utils from '@/common/utils.js';
    const components = [salesRecords, refundRecords, fieldOrder];
    const barlist = reactive([{ name : '首页'},{ name : '财务中心' },{ name : '销售记录' }]); // 面包屑
    const pageData = reactive({
        tabNum: '0',
    })
	var menuId = [32000,32001]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	}
    function handleClick(tab) {
      localStorage.setItem('sale', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('sale') != null) {
          pageData.tabNum = localStorage.getItem('sale')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('sale')
	})
</script>

<style>
</style>
