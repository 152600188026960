<template>
	<workArea class="posi-r" :barlist="pageData.barlist">
	<div class="nav-top p24 pb0 posi-f bg-fff">
		<ul class="step_box rx-bc pt10 pb10 pl100 pr100">
			<li class="rx-cc flex1">
				<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum == 1" :class="{'active':pageData.tabNum == 1}">1</div>
				<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum != 1}"><el-icon><Check /></el-icon></div>
				<div class="fwb ft16" :class="pageData.tabNum == 1 ? 'color-333' :'color-999'">{{ id ? '编辑' :'创建' }}体验卡</div>
				<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
			</li>
			<li class="rx-cc flex1">
				<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum <= 2" :class="{'active':pageData.tabNum == 2}">2</div>
				<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum > 2}"><el-icon><Check /></el-icon></div>
				<div class="fwb ft16" :class="pageData.tabNum == 2 ? 'color-333' :'color-999'">绑定课程</div>
				<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
			</li>
			<li class="rx-cc flex1">
				<div class="step_icon ft18 fwb mr10" :class="{'active':pageData.tabNum == 3}">3</div>
				<div class="fwb ft16" :class="pageData.tabNum == 3 ? 'color-333' :'color-999'">完成</div>
			</li>
		</ul>
	</div>
	<div class="update_course_card bg-fff p24 mt75 pt0 hh90 o_a o_y_s" v-loading="pageData.loading">
		<div v-show="pageData.tabNum == 1">
			<CommonTitle text="基本信息"/>
			<div class="rx-sc mb25">
				<div class="rx-sc mr40">
					<span class="w120 t_a_r"><span class="color-red">*</span>体验卡名称：</span>
					<div class="w240">
						<!--  :disabled="id ? true : false" -->
						<el-input type="text" placeholder="请输入(限50字)" maxlength="50" v-model="pageData.formData.productName"></el-input>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w120 t_a_r"><span class="color-red">*</span>体验卡类型：</span>
					<div class="w240">
						<el-select class="w240" fit-input-width v-model="pageData.formData.productTyp" placeholder="请选择" :disabled="id ? true : false">
							<el-option label="次卡" :value="1"></el-option>
							<el-option label="期限卡" :value="3"></el-option>
						</el-select>
					</div>
				</div>
			</div>
			<div class="rx-sc mb25" v-show="pageData.formData.productTyp == 1">
				<div class="rx-sc mr40">
					<span class="w120 t_a_r"><span class="color-red">*</span>体验卡次数：</span>
					<div class="w240">
						<el-input type="text" placeholder="请输入(整数)" v-model="pageData.formData.productNum" @blur="productNumBlur">
							<template #append>次</template>
						</el-input>
					</div>
				</div>
			</div>
			<div class="rx-sc mb10">
				<div class="rx-sc mr40">
					<span class="w120 t_a_r">卡消费时长：</span>
					<div class="w240 select-name">
						<el-input type="text" placeholder="请输入大于0的数字" v-model="pageData.formData.productCycle" @blur="productCycleBlur">
							<template #append>
								<el-select v-model="pageData.formData.ifMonth" style="width: 115px">
        						  	<el-option v-for="item in pageData.dateType" :key="item.id" :label="item.name" :value="item.id" />
        						</el-select>
							</template>
						</el-input>
					</div>
				</div>
				<div class="rx-sc mr40">
					<span class="w120 rx-ec">时长开始：</span>
					<div class="w300">
						<el-radio-group v-model="pageData.formData.productTimeType" :disabled="id ? true : false">
						    <el-radio :label="1"><span class="color-666 fw400">从购买之日起算</span></el-radio>
						    <el-radio :label="2"><span class="color-666 fw400">第一次消费起算</span></el-radio>
						</el-radio-group>
					</div>
				</div>
			</div>
			<div class="mb25 o_h">
				<span class="w120 mt9 f_l t_a_r">
					<span class="color-red">*</span>
					<span>可体验门店</span>
					<span class="v_a_m d_i_b w15 h15 ml5">
						<el-tooltip
						    class="box-item"
						    effect="dark"
						    content="会员可使用本卡跨店消费体验"
						    placement="right-start"
						  >
						  <img class="w15 h15" src="@/assets/wenhao.png" >
						</el-tooltip> 
					</span>
					<span>：</span>
				</span>
				<div class="w500 f_l">
					<el-radio v-model="pageData.formData.consumeSid" label="0" size="large"><span class="color-666 fw400">全部门店</span></el-radio>
					<el-radio v-model="pageData.formData.consumeSid" label="1" size="large"><span class="color-666 fw400">多门店</span></el-radio>
					<div class="rx-wss mt10" v-show="pageData.formData.consumeSid == '1'">
						<template v-for="(item,index) in pageData.storeList" :key="index">
							<div class="category mb20 mr20" v-if="item.ifBranchStore == 1">
				     			<el-checkbox v-model="item.check" :label="item.id" >
			         		      	<span class="color-666 fw400">{{item.storeName}}</span>
			         		 	</el-checkbox>
				     		</div>
						</template>
					</div>
				</div>
			</div>
			<CommonTitle text="售卖信息"/>
			<div class="rx-sc mb25">
				<div class="rx-sc mr40">
					<span class="w120 t_a_r"><span class="color-red">*</span>体验价格：</span>
					<div class="w240">
						<el-input type="text" placeholder="请输入(保留2位小数)" maxlength="6" v-model="pageData.formData.experiencePrice" @blur="experiencePriceBlur">
							<template #append>元</template>
						</el-input>
					</div>
				</div>
				<div class="rx-sc mr40">
					<span class="w120 t_a_r"><span class="color-red">*</span>售卖原价：</span>
					<div class="w240">
						<el-input type="text" placeholder="请输入(保留2位小数)" maxlength="6" v-model="pageData.formData.productPrice" @blur="productPriceBlur">
							<template #append>元</template>
						</el-input>
					</div>
				</div>
				
			</div>
			<div class="rx-ss mb25">
				<div class="rx-sc mr40">
					<span class="w120 t_a_r"><span class="color-red">*</span>售卖方式：</span>
					<div class="w240">
						<el-checkbox-group v-model="pageData.formData.sellType">
							<el-checkbox label="1"><span class="color-666 fw400">会员端售卖</span></el-checkbox>
							<el-checkbox label="2"><span class="color-666 fw400">线下售卖</span></el-checkbox>
						</el-checkbox-group>
					</div>
				</div>
				<div class="rx-sc">
					<span class="w120 t_a_r"><span class="color-red">*</span>购买限制：</span>
					<div class="">
						<el-radio v-model="pageData.formData.buyUserType" :label="1" size="large"><span class="color-666 fw400">仅限新客</span></el-radio>
						<el-radio v-model="pageData.formData.buyUserType" :label="2" size="large"><span class="color-666 fw400">仅会员</span></el-radio>
						<el-radio v-model="pageData.formData.buyUserType" :label="0" size="large"><span class="color-666 fw400">全部人</span></el-radio>
					</div>
					<span class="ml10">
						<el-tooltip placement="right-start">
						   <template #content>
							   仅限新客：即非正式会员可以购买
							   <br />
							   仅会员：即正式会员可以购买
							   <br />
							   全部人：即门店全部人可以购买
							</template>
						  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
						</el-tooltip> 
					</span>
				</div>
			</div>
			<div class="mb25 o_h">
				 <span class="w120 mt9 f_l t_a_r">
				 	<span class="color-red">*</span>
				 	<span>可销售门店</span>
				 	<span class="v_a_m d_i_b w15 h15 ml5">
				 		<el-tooltip
				 		    class="box-item"
				 		    effect="dark"
				 		    content="门店将直接上架此卡售卖，无需再次创建"
				 		    placement="right-start"
				 		  >
				 		  <img class="w15 h15" src="@/assets/wenhao.png" >
				 		</el-tooltip> 
				 	</span>
				 	<span>：</span>
				 </span>
				<div class="w500 f_l">
					<el-radio v-model="pageData.formData.sellSid" label="0" size="large"><span class="color-666 fw400">全部门店</span></el-radio>
					<el-radio v-model="pageData.formData.sellSid" label="1" size="large"><span class="color-666 fw400">多门店</span></el-radio>
					<div class="rx-wss mt10" v-show="pageData.formData.sellSid == '1'">
						<template v-for="(item,index) in pageData.storeList1" :key="index">
							<div class="category mb20 mr20" v-if="item.ifBranchStore == 1">
				     			<el-checkbox v-model="item.check" :label="item.id" >
			         		      	<span class="color-666 fw400">{{item.storeName}}</span>
			         		 	</el-checkbox>
				     		</div>
						</template>
					</div>
				</div>
			</div>
			<CommonTitle text="其他信息"/>
			<div class="rx-ss mb25">
				<span class="w120 t_a_r pt10"><span class="color-red">*</span>体验卡封面：</span>
				<div>
					<el-radio-group v-model="pageData.coverCheck">
					    <el-radio label="0"><span class="color-666 fw400">风格一</span></el-radio>
					    <el-radio label="1"><span class="color-666 fw400">风格二</span></el-radio>
					    <el-radio label="2"><span class="color-666 fw400">自定义</span></el-radio>
					</el-radio-group>
					<div class="rx-ss  mt15">
						<div class="cover_img">
							<div v-if="pageData.coverCheck == 2" class="cover_dele"><unicon name="trash-alt" fill="#fff" hover-fill="#fff" @click="deleCover"></unicon></div>
							<img v-show="pageData.cover[pageData.coverCheck]" class="w120 h80" :src="pageData.cover[pageData.coverCheck]" alt=""/>
						</div>
						<div v-show="!pageData.cover[pageData.coverCheck]" class="w120 t_a_c color-999 h80 rx-cc mr10" style="border: 1px solid #ccc;">
							<div>
								<CommonUpload @change="uploadChange" :autoCropWidth="375" :autoCropHeight="250" :isCropper="true">
								<img class="w25" src="@/assets/addBank.png" alt="">
								<p>上传图片</p>
								</CommonUpload>
							</div>
						</div>
						<div v-show="!pageData.cover[pageData.coverCheck]" class="color-999 w200">
							建议大小为750*500px的jpg、png、bmp格式不超过2M的图片。
						</div>
					</div>
				</div>
			</div>
			<div class="rx-ss mb25">
				<span class="w120 t_a_r">体验卡介绍：</span>
				<div class="flex1" ref="editor"></div>
			</div>
		</div>
		<div v-show="pageData.tabNum == 2">
			<div class="rx-cc p20">
				<template v-for="(item, index) in pageData.courseTableList" :key="index">
					<el-checkbox :label="item.name" v-model="item.check"></el-checkbox>
				</template>
			</div>
			<template v-for="(item,index) in pageData.courseTableList" :key="index">
				<div class="table_list mb30" v-show="item.check">
					<table cellspacing="0" cellpadding="10">
						<thead>
							<tr>
								<th class="w150"><div class="rx-sc cell">课程名称</div></th>
								<th class="w150"><div class="rx-sc cell">课程种类</div></th>
								<th><div class="rx-sc cell">上课门店</div></th>
								<th class="w300"><div class="rx-sc cell">预约课程扣除次数
									<el-tooltip
                    				  class="box-item"
                    				  effect="dark"
                    				  content="会员使用次卡预约课程时每节需要扣除的次数，使用期限卡默认不扣次数"
                    				  placement="top"
                    				>
                    				  <el-icon class="ml5"><question-filled /></el-icon>
                    				</el-tooltip>
								</div></th>
								<th class="w90"><div class="rx-sc cell">操作</div></th>
							</tr>
							<tr>
								<th colspan="4">
									<div class="rx-cc color-409EFF c_p cell tool_btn" @click="selectCourse(index)"><el-icon><Plus /></el-icon><span class="fw400">选择支持预约的{{item.name}}</span></div>
								</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(n,i) in item.list" :key="i">
								<td><div class="rx-sc cell">{{n.courseName}}</div></td>
								<td><div class="rx-sc cell">{{n.courseType == 11 ? '团体课' : n.courseType == 12 ? '私教课' :''}}</div></td>
								<td><div class="rx-sc cell">{{n.storeName}}</div></td>
								<td>
									<div class="rx-sc cell">
										<span class="w100 mr10">
											<el-input type="text" v-model="n.deductNum" :disabled="pageData.formData.productTyp == 3">
												<template #append>次/节</template>
											</el-input>
										</span>
										<el-link class="ft13" :underline="false" type="primary" @click="bindCourseCopyAdll(n.deductNum,index)">复制到全部</el-link>
									</div>
								</td>
								<td>
									<div class="rx-sc cell">
										<el-link class="t_d_u" :underline="false" type="danger" @click="item.list.splice(i,1)">删除</el-link>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</template>
		</div>
		<div v-show="pageData.tabNum == 3" class="rx-cc">
			<div class="mt100 t_a_c">
				<div class="rx-cc">
					<circle-check-filled  style="width: 6em; height: 6em;color:#74C041"/>
				</div>
				<h3 class="ft24 mt20">创建成功</h3>
				<div class="mt20">
					<el-button type="primary" @click="toInit">继续添加</el-button>
					<el-button @click="toCardList">返回列表</el-button>
				</div>
			</div>
		</div>
		<div class="rx-sc">
			<div class="w75 mr16">
				<el-button v-show="pageData.tabNum == 2" @click="pageData.tabNum = 1">上一步</el-button>
			</div>
			<el-button v-show="pageData.tabNum != 3" type="primary" @click="addSave">{{pageData.tabNum == 2 ? '保存并上架' : '下一步，绑定课程'}}</el-button>
		</div>
		<el-dialog
		    v-model="pageData.bindCourseShow"
		    :title="pageData.bindCourseTitle"
			width="650px"
		    :before-close="()=>{pageData.bindCourseShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="pt30 pb30 pl30 pr30">
		    	<el-table height="500" ref="bindCourseTable" :data="pageData.bindCourseList" stripe style="width: 100%" @selection-change="checkChange" :row-key="getRowKeys">
		    		<el-table-column width="55" >
		    			<template #header>
		    				<el-checkbox v-model="pageData.tableCheckAll" size="large" @change="tableCheck('all')"></el-checkbox>
		    			</template>
		    			<template #default="scope">
		    				<el-checkbox v-model="scope.row.checked" size="large" @change="tableCheck(scope.row)"></el-checkbox>
		    			</template>
		    		</el-table-column>
		    	    <el-table-column prop="courseName" label="课程名称" />
		    	    <el-table-column prop="courseType" label="课程类型" >
		    			<template #default="scope">
		    				<span>{{scope.row.courseType == 11 ? '团体课' : scope.row.courseType == 12 ? '私教课' :''}}</span>
		    			</template>
		    		</el-table-column>
		    	    <el-table-column prop="storeName" label="上课门店" show-overflow-tooltip />
		    	</el-table>
		    	<div class="mt20 rx-ec" v-show="pageData.bindCourseList.length > 0">
		    	    <el-pagination
		    	      v-model:currentPage="pageData.bindCoursePage"
		    	      :page-sizes="[15,20,30,50,100]"
		    	      :page-size="15"
		    	      layout="prev, pager, next"
		    	      @current-change="getCourseList"
		    	    >
		    	    </el-pagination>
		    	</div>
		    </div>
		    <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.bindCourseShow = false">取消</el-button>
		        	<el-button type="primary" @click="addCoachSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
	</div>
	</workArea>
</template>

<script setup>
	import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
  	import { useRouter } from 'vue-router';
  	import { Plus, Check, ArrowRight, ArrowDown, ArrowUp, CircleCheckFilled } from '@element-plus/icons-vue'
	import { ElMessage} from 'element-plus'
	import WangEditor from 'wangeditor'
	import CommonTitle from '@/components/CommonTitle.vue'
	import { getFrameworkAllList } from '@/api/staff.js'
  	import { courseNexus, productInfo, productInsert,productUpdate } from '@/api/cards.js'
	import { adminList } from '@/api/course.js'
	import { wangEditorUpload } from '@/common/common.js'
	import workArea from '@/components/branch-store/workArea.vue';
	 import CommonUpload from '@/components/CommonUpload.vue';
  	const router = useRouter();
  	const id = router.currentRoute.value.query.id;
  	const editor = ref();
  	let instance = ''; 
	const pageData = reactive({
		barlist:[{ name : '返回',type:'0' },{ name : (id?'编辑':'创建')+'体验卡' }],
		loading:false,
		dropdown: false,
		formData:{
			type:4,
			productName:'',
			productTyp:1,
			ifMonth:1,
			productCycle:'',
			productNum:'',
			productTimeType:'',
			productPrice:'',
			buyUserType:0,
			sellSid:'0',
			consumeSid:'0',
			sellType:[],
			productContent:''
		},
		consumeCheckList:[],
		sellCheckList:[],
		storeList:[],
		storeList1:[],
		tabNum:1,
		coverCheck:'0',
		cover:['https://image.taokatao.cn/imgs/runBusiness/card7.png','https://image.taokatao.cn/imgs/runBusiness/card8.png'],
		courseTableList:[{
			name:'团体课',
			id:'11',
			check:true,
			list:[]
		},
		{
			name:'私教课',
			id:'12',
			check:true,
			list:[]
		},
		// {
		// 	name:'班课',
		// 	id:'15',
		// 	check:false,
		// 	list:[]
		// },
		],
		dateType:[{id:1,name:'天'},{id:2,name:'月'},{id:3,name:'年'}],
		bindCourseList:[],
		bindCourseCheck:[],
		bindCoursePage:1,
		bindCourseShow:false,
		bindCourseTitle:'',
		bindCourseIndex:0,
		tableCheckAll:false,
		courseLoading:false
	})
	//所属架构搜索下拉框
	getFrameworkAllList().then((res)=>{
		if(res.code == 0){
			res.data.forEach((item) => {
				item.check = false
			})
			pageData.storeList = res.data;
			pageData.storeList1 = JSON.parse(JSON.stringify(res.data))
		}
	})
	
	//保存
	function addSave(){
		var formData = JSON.parse(JSON.stringify(pageData.formData));
		if(pageData.tabNum == 1){
			if(!formData.productName){
				ElMessage({
					type: 'warning',
					message: '请填写体验卡名称',
				})
				return false;
			}
			if(formData.productTyp == 1 && !formData.productNum){
				ElMessage({
					type: 'warning',
					message: '请填写体验卡次数',
				})
				return false;
			}
			if(formData.productTyp == 3 && !formData.productCycle){
				ElMessage({
					type: 'warning',
					message: '期限卡类型须填写卡消费时长',
				})
				return false;
			}
			if(!formData.productPrice){
				ElMessage({
					type: 'warning',
					message: '请输入售卖金额',
				})
				return false;
			}
			if(formData.sellType.length == 0){
				ElMessage({
					type: 'warning',
					message: '请选择售卖方式',
				})
				return false;
			}
			if(formData.sellSid == 1){
				pageData.sellCheckList = []
      			pageData.storeList1.filter((n) => {
      			  	if (n.check) {
      			  	    pageData.sellCheckList.push(n.id)
      			  	}
      			})
				if (pageData.sellCheckList.length == 0) {
					ElMessage({
						type: 'warning',
						message: '请选择销售门店',
					})
					return false;
				}
			}
			if(formData.consumeSid == 1){
				pageData.consumeCheckList = []
      			pageData.storeList.filter((n) => {
      			  	if (n.check) {
      			  	    pageData.consumeCheckList.push(n.id)
      			  	}
      			})
				if (pageData.consumeCheckList.length == 0) {
					ElMessage({
						type: 'warning',
						message: '请选择消费门店',
					})
					return false;
				}
			}
			if(pageData.coverCheck == 2 && pageData.cover.length == 2){
				ElMessage({
					type: 'warning',
					message: '请上传体验卡封面',
				})
				return false;
			}
			if(!pageData.formData.productCycle && pageData.formData.productCycle != 0){
				pageData.formData.ifMonth = 0
			}
			pageData.tabNum = 2;
			return false;
		}else{
			var courseNameIds = [],courseNameConsumeNums = [];
			pageData.courseTableList.map((n)=>{
				if(n.check){
					n.list.map((z)=>{
						courseNameIds.push(z.courseNameId);
						courseNameConsumeNums.push(z.deductNum);
					})
				}
			})
			if(courseNameIds.length == 0){
			 	ElMessage({
			 		type: 'warning',
			 		message: '请选择要绑定的课程',
			 	})
			 	return false;
			} 
			formData.courseNameIds = courseNameIds.join(','); //绑定课程id
			formData.courseNameConsumeNums = courseNameConsumeNums.join(','); //绑定课程扣除数
			if(formData.sellSid == 1){
				formData.sellSid = pageData.sellCheckList.join(',');
			}
			if(formData.consumeSid == 1){
				formData.consumeSid = pageData.consumeCheckList.join(',');
			}
			formData.sellType = formData.sellType.join(',');
			formData.productCover = pageData.cover[pageData.coverCheck];
			formData.productContent = instance.txt.html();
			if(id){
				formData.encryptionId = id;
				productUpdate(formData).then((res)=>{
							pageData.loading = false;
							if(res.code == 0){
								// ElMessage({
								// 	type: 'success',
								// 	message: '保存成功',
								// })
								pageData.tabNum = 3;
							}
						})
			}else{
				productInsert(formData).then((res)=>{
					if(res.code == 0){
						// ElMessage({
						// 	type: 'success',
						// 	message: '保存成功',
						// })
						pageData.tabNum = 3;
					}
				})
			}
		}
		
	}
	//选择课程table全选和单选
	function tableCheck(item){
		if(item == 'all'){  //全选
			pageData.bindCourseList.map((n)=>{
				if(pageData.tableCheckAll){//全选
					if(!n.checked){ //把未选的放进去
						pageData.bindCourseCheck.push(n);
						n.checked = true;
					}
				}else{ //全取消
					pageData.bindCourseList.map((z,i)=>{
						if(n.id == z.id){
							pageData.bindCourseCheck.splice(i,1);
							n.checked = false;
						}
					})
				}
			})
		}else{ //单选
			if(item.checked){
				pageData.bindCourseCheck.push(item);
				var checkList = pageData.bindCourseList.filter( n=> n.checked);
				if(checkList.length == pageData.bindCourseList.length){
					pageData.tableCheckAll = true;
				}
			}else{
				var index = pageData.bindCourseCheck.indexOf(item.courseNameId);
				pageData.bindCourseCheck.splice(index,1);
				pageData.tableCheckAll = false;
			}
		}
	}
	//选择绑定课程
	function selectCourse(index){
		pageData.courseLoading = true;
		pageData.bindCourseIndex = index;
		pageData.tableCheckAll = false;
		var item = pageData.courseTableList[index];
		pageData.bindCourseCheck = JSON.parse(JSON.stringify(item.list));
		pageData.bindCourseTitle = '选择支持可预约的'+item.name;
		if(item.check){
			pageData.bindCoursePage = 1; 
			getCourseList(item.id);
		}
	}
	const bindCourseTable = ref();
	function getCourseList(type){
		adminList({
			page:pageData.bindCoursePage,
			limit:15,
			courseType:type
		}).then((res)=>{
			if(res.code == 0){
				var num = 0;
				pageData.bindCourseList = res.data.map((n)=>{
					n.deductNum = 0;
					var check = pageData.bindCourseCheck.filter(z=>z.courseNameId == n.courseNameId);
					n.checked = check.length > 0 ? true : false;
					if(check.length > 0){
						num+=1;
					}
					return n;
				})
				if(res.data.length == num){ //全部选中
					pageData.tableCheckAll = true;
				}
				pageData.bindCourseShow = true;
				pageData.courseLoading = false;
			}
		})
	}
	
	function addCoachSave(){
		pageData.courseTableList[pageData.bindCourseIndex].list = pageData.bindCourseCheck;
		pageData.bindCourseShow = false;
	}
	//返回列表
	function toCardList(){
		history.back();
		// router.push({path:'/vipCard',query:{tab:3}});
	}
	//初始化数据
	function toInit(){
		pageData.formData = {
			type:4,
			productName:'',
			productTyp:1,
			ifMonth:2,
			productCycle:'',
			productNum:'',
			productTimeType:'',
			productPrice:'',
			buyUserType:0,
			sellSid:'0',
			consumeSid:'0',
			sellType:[],
			productContent:''
		}
		pageData.consumeCheckList = [];
		pageData.sellCheckList = [];
		pageData.tabNum = 1;
		pageData.coverCheck = '0';
		pageData.cover = ['https://image.taokatao.cn/imgs/runBusiness/card7.png','https://image.taokatao.cn/imgs/runBusiness/card8.png'];
        pageData.courseTableList = [{
			name:'团体课',
			id:'11',
			check:false,
			list:[]
		},
		{
			name:'私教课',
			id:'12',
			check:false,
			list:[]
		},
		// {
		// 	name:'班课',
		// 	id:'15',
		// 	check:false,
		// 	list:[]
		// },
		];
		instance.txt.html('');
	}
	//绑定课程复制到全部
	function bindCourseCopyAdll(val,index){
		pageData.courseTableList[index].list.map((n) =>{
			n.deductNum = val;
		});
	}
	//体验卡次数失去焦点
	function productNumBlur(){
		var {productNum} = pageData.formData;
		if(!(/(^[1-9]\d*$)/.test(productNum))){
			ElMessage({
				type: 'warning',
				message: '请输入大于0的整数',
			})
			pageData.formData.productNum = '';
		}
	}
	//消费时长失去焦点
	function productCycleBlur(){
    	var {productCycle,productTimeType} = pageData.formData;
    	if(id && productTimeType){
    	  	return false;
    	}
    	if(productCycle && !productTimeType){
    	  	pageData.formData.productTimeType = 1;
    	}else if(!productCycle){
    	 	pageData.formData.productTimeType = '';
    	}
		if (productCycle > 999999) {
			ElMessage({
				type: 'warning',
				message: '最大仅支持六个九',
			})
            pageData.formData.productCycle = ''
			pageData.formData.productTimeType = '';
			return false;
		}
		if (productCycle == 0) {
            pageData.formData.productCycle = ''
			pageData.formData.productTimeType = '';
		}
	}
	
	//消销售金额失去焦点
	function productPriceBlur(){
		var {productPrice} = pageData.formData;
		if(productPrice){
			if(isNaN(Number(productPrice))){
				ElMessage({
					type: 'warning',
					message: '输入售卖原价数不正确',
				})
				pageData.formData.productPrice = '';
				return false;
			}
			pageData.formData.productPrice = Number(productPrice).toFixed(2);
		}
	}
	//消销售金额失去焦点
	function experiencePriceBlur(){
		var {experiencePrice} = pageData.formData;
		if(experiencePrice){
			if(isNaN(Number(experiencePrice))){
				ElMessage({
					type: 'warning',
					message: '输入体验价格数不正确',
				})
				pageData.formData.experiencePrice = '';
				return false;
			}
			pageData.formData.experiencePrice = Number(experiencePrice).toFixed(2);
		}
	}
	
	//上传封面
	function uploadChange(url){
		pageData.cover.push(url);
	}
	// 删除封面
	function deleCover(){
		pageData.cover.splice(2,1);
	}
	onMounted(()=>{
		instance = new WangEditor(editor.value);
		instance.config.customUploadImg = function (resultFiles, insertImgFn) {
			wangEditorUpload({fileData:resultFiles[0]},(res)=>{
				if(res.data.code == 0){
					insertImgFn(res.data.data.wholeUrl);
				}else{
					ElMessage({
						type: 'warning',
						message: '上传失败',
					})
				}
			});
		}
		Object.assign(instance.config, {
			height:300,
			zIndex:100,
		});
		instance.create();
    //编辑
    if(id){
      	pageData.loading = true;
      	productInfo({ encryptionId:id }).then((res)=>{
        	if(res.code == 0){
          		for(var key in res.data){
          		  	pageData.formData[key] = res.data[key];
          		}
          		if(res.data.sellSid != 0){
					pageData.formData.sellSid = '1';
					pageData.sellCheckList = res.data.sellSid.split(',').map(n=>Number(n));
					res.data.sellSid.split(',').forEach((item) => {
    				  	pageData.storeList1.filter((n) => {
    				  	  	if (n.id == item) {
    				  	  	    n.check = true
    				  	  	}
    				  	})
    				})
				}
				if(res.data.consumeSid != 0){
					pageData.formData.consumeSid = '1';
					res.data.consumeSid.split(',').forEach((item) => {
    				  	pageData.storeList.filter((n) => {
    				  	  	if (n.id == item) {
    				  	  	    n.check = true
    				  	  	}
    				  	})
    				})
				}
          		pageData.formData.sellType = res.data.sellType.split(',');
          		var coverIndex = '2';
          		pageData.cover.map((n,i)=>{
            	if(n == res.data.productCover){
            	  	coverIndex = i+'';
            	}
        });
        if(coverIndex == 2){ //证明是自定义
            pageData.cover.push(res.data.productCover);
        }
        pageData.coverCheck = coverIndex;
        instance.txt.html(res.data.productContent);
    }
        pageData.loading = false;
      })
      //获取绑定课程
      courseNexus({
        page:1,
        limit:99999,
        encryptionId:id
      }).then((res)=>{
        res.data.map((n,i)=>{
          n.deductNum = n.consumeNum;
          if(n.courseType == 11){
            pageData.courseTableList[0].list.push(n);
            pageData.courseTableList[0].check = true;
          }else if(n.courseType == 12){
            pageData.courseTableList[1].list.push(n);
            pageData.courseTableList[1].check = true;
          }else{
            pageData.courseTableList[2].list.push(n);
            pageData.courseTableList[2].check = true;
          }
        });
      })
    }
	})
	onBeforeUnmount(() => {
		instance.destroy();
		instance = null;
	});
	localStorage.setItem('vipCard', 3)
</script>

<style scoped lang="stylus">
	.nav-top {
		z-index:100;
		width:83%
	}
	.step_box{
			background-color #FFFAE7;
			li{
				position relative;
				.step_icon{
					width 32px;
					height 32px;
					line-height 32px;
					text-align center;
					border-radius 100%;
					border 1px solid #909399;
					color #909399;
				}
				.step_icon.active{
					color white;
					background-color #409EFF;
					border-color #409EFF;
				}
				.step_icon.complete{
					color: #409eff;
    				background: #fffae7;
					border-color #409EFF;
				}
				.step_right{
					position absolute;
					right 0;
					top 0;
				}
			}
		}
	.update_course_card{
		.table_list{
			table{
				width 100%;
				border-bottom 1px solid #ccc;
				thead{
					tr:first-child{
						background-color #F4F9FF;
						color #999;
					}
					
				}
				tbody{
					tr:nth-child(even){
						background-color #fafafa;
					}
				}
			}
			.cell{
				padding 10px;
			}
		}
		.table_list thead tr>th:first-child .cell{ padding-left: 20px;}
		.table_list tbody tr>td:first-child .cell{ padding-left: 20px;}
		.cover_img{
			position relative;
			.cover_dele{
				position absolute;
				left 0;
				top 0;
				bottom 0;
				right 0;
				background-color rgba(0,0,0,.3);
				color white;
				text-align center;
				cursor pointer;
				display none;
				line-height 80px;
			}
		}
		.cover_img:hover .cover_dele{
			display block;
		}
	}
	.select-name /deep/ .el-input-group__append{
	    padding:0 !important;
		width: 62px
	}
	.category {
		padding: 0 13px;
		background: #F7F7F7;
    	border-radius: 1px;
    	border: 1px solid #EAF0F8;
	}
</style>
