<template>
	<div class="potential_member">
		<formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb12 rx-bc">
			<div>
				<el-button type="primary" @click="batchFollowEvent" v-if="utils.getMenuRole(3140200)">批量跟进</el-button>
			</div>
			<el-button @click="getList(true)"  :icon="Upload" >导出</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange" v-loading="pageData.loading">
				<el-table-column type="selection" width="55"/>
				<el-table-column fixed prop="realName" label="会员姓名" width="110" show-overflow-tooltip >
					<template #default="scope">
						<span @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
				<el-table-column label="跟进状态" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.upStatusName || '暂无状态'}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="productTyp" label="意向级别" min-width="150">
					<template #default="scope">
						<el-rate v-model="scope.row.ifImportanceDegree" disabled size="large"></el-rate>
					</template>
				</el-table-column>
				<el-table-column prop="adviserNames" label="跟进人" min-width="100" show-overflow-tooltip />
				<el-table-column prop="thisFollowTime" label="上次跟进时间" min-width="110" />
			    <el-table-column prop="followUpContent" label="跟进内容" min-width="100" show-overflow-tooltip />
				<el-table-column prop="nextFollowTime" label="下次跟进时间" min-width="110" />
				<el-table-column prop="tagName" label="标签" width="100">
					<template #default="scope">
						<template v-if="scope.row.userTag && scope.row.userTag.length > 0">
							<span v-for="(item,index) in scope.row.userTag" :key="index" class="pl5 pr5 mr5" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="160">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3140201)" @click="oneFollowEvent(scope.row)">跟进</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
		<AddFollowStep v-model="pageData.addFollowShow" :star="pageData.followStar" :sid="pageData.memberId" @close="addFollowClose" @save="addFollowSave"/>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import { ref, reactive, onMounted} from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { ArrowDown, Close, Upload } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import { followRemindList, followRemindExport } from '@/api/intentionList.js'
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import AddFollowStep from '@/components/branch-store/member/AddFollowStep.vue';
	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const queryOptsRef = ref()
	const pageData = reactive({
		loading: false,
		addFollowShow:false, //批量跟进
		memberId:'',
		followStar:'',
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
			followStatusId:'',
			ifImportanceDegree:'',
			adviserIds:'',
			channelId:''
		},
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			}, {
                type : 'select',
                fieldName : 'timeIn',
                placehold : '时间范围',
                value : '1',
                options : [{ label: '今天', value: '1' }, { label: '其他', value: '-1'}]
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'searchTime',
                placeholder : '日期范围'
            },{
				type : 'select',
				fieldName : 'followRemindSelect',
				placehold : '',
				options : [{ label: '待跟进', value: '1' }, { label: '已跟进', value: '3' }, { label: '会员生日', value: '5' }, { label: '新分配', value: '6' }, { label: '逾期未跟进', value: '2' }],
				value:'1'
			},{
				type : 'select',
				fieldName : 'followStatusId',
				placehold : '跟进状态',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'ifImportanceDegree',
				placehold : '意向级别',
				options : utils.getDict('starState',{label : '意向级别' ,value : ''}),
				value:''
			},{
				type : 'selectMany',
				fieldName : 'adviserIds',
				placehold : '跟进人',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'channelId',
				placehold : '会员来源',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'storeId',
				placehold : '会员标签',
				type:'selectMany',
				options : [],
				value:''
			}]
		}
	}) 
	// 不需要响应式监听的全局变量
	let confirmType = '',//通用确认调用类型
	nowTableRow = '',//操作-当前行数据
	selectCheckData = [];//批量多选数据集合;
	
	//获取字典
	utils.getDynaDict('followState',{label : '跟进状态' ,value : ''},function(res){
		for(var i=0;i<pageData.queryOpts['fields'].length;i++){
            if(pageData.queryOpts['fields'][i]['fieldName'] == 'followStatusId'){
				pageData.queryOpts['fields'][i]['options'] = res;
            }
        }
	});
	utils.getDynaDict('followerList','',function(res){
		for(var i=0;i<pageData.queryOpts['fields'].length;i++){
            if(pageData.queryOpts['fields'][i]['fieldName'] == 'adviserIds'){
				pageData.queryOpts['fields'][i]['options'] = res;
            }
        }
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
		for(var i=0;i<pageData.queryOpts['fields'].length;i++){
            if(pageData.queryOpts['fields'][i]['fieldName'] == 'channelId'){
				pageData.queryOpts['fields'][i]['options'] = res;
            }
        }
	});
	utils.getDynaDict('upTagList',{label : '会员标签' ,value : ''},function(res){
		for(var i=0;i<pageData.queryOpts['fields'].length;i++){
            if(pageData.queryOpts['fields'][i]['fieldName'] == 'storeId'){
				pageData.queryOpts['fields'][i]['options'] = res;
            }
        }
	});
	//筛选表单回调
	function getTableData(){
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function getList(isExport){
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.timeIn == '-1' && !params.searchTime) {
            ElMessage.error('请选择自定义时间')
            return
        }
		params.adviserIds = params.adviserIds.length ? params.adviserIds.join(',') : ''
        params.searchTime = params.searchTime ? params.searchTime.join('-') :''
		if(isExport){ //导出
			params.page = 1;
			params.limit = 9999;
			params.exportData = 1;
		}
		pageData.loading = true
		followRemindList(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data.map((n)=>{
						n.ifImportanceDegree = utils.getStartNum(n.ifImportanceDegree); //特殊处理意向级别
						return n;
					});
					pageData.totalCount = res.count;
				}
			}
		}).catch(() => {
			pageData.loading = false
		})
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	
	
	// 批量跟进
	function batchFollowEvent(){ //批量
		CheckLengthCallBack(()=>{
			oneFollowEvent({cardMyId:getSelectCheckKey('cardMyId')});
		})
	}
	function oneFollowEvent(row){
		pageData.memberId = row.cardMyId;
		pageData.followStar = row.ifImportanceDegree;
		pageData.addFollowShow = true;
	}
	function addFollowSave(res){
		pageData.addFollowShow = false;
		searchTable();
	}
	function addFollowClose(){
		pageData.addFollowShow = false;
	}
	
	/**
	 * 导出
	 */
	function deletSomeOne() {
		followRemindExport().then((res) => {
			if (res.code == 0) {
                ElMessage({
		        	type: 'success',
		        	message: '正在导出，请稍后前往下载中心下载'
		        })
			}
		})
	}
	
	//通用确认框确认事件回调
	// function confirmSave(){
	// 	if(confirmType == 'delete'){
	// 		productDelete({
	// 			encryptionId:selectCheckData.join(',')
	// 		}).then((res)=>{
	// 			if(res.code == 0){
	// 				ajaxSaveCallBack('删除成功');
	// 			}
	// 		})
	// 	}
		
	// }
	
	
	
	
	
	//会员详情
	function memberDetail(id){
        router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if ( to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	onMounted(() => {
		getList();
	})
	
</script>

<style scoped lang="stylus">
	.potential_member{
		.uploadTips{
			border 1px solid #00A3FF;
			border-radius 2px;
			padding-left 5px;
			background #EBF8FF;
			color #00A3FF;
		}
	}
</style>
