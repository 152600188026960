import {postAjax,getAjax}  from "../common/axios.js"

//获取地区
export const getAllAddress = params => {
    return getAjax('/api/system/nologin/getAllAddress',params);
}

//新增教练
export const appletCoachInsert = params => {
    return postAjax('/api/run/appletCoach/insert',params);
}

//保存配置
export const insertAll = params => {
    return postAjax('/api/run/appletSetting/update',params,'json');
}

//获取功能模块名字
export const moduleList = params => {
    return postAjax('/api/run/appletShowModule/list',params);
}
//获取配置
export const editList = params => {
    return postAjax('/api/run/appletSetting/list',params);
}

//获取小程序绑定状态
export const getStoreMiniBindInfo = params => {
    return getAjax('/api/open/getStoreMiniBindInfo',params);
}

//授权小程序
export const getAuthUrl = params => {
    return getAjax('/api/open/nologin/getAuthUrl',params);
}

//更新小程序配置
export const updateSystemSettingMini = params => {
    return postAjax('/api/open/nologin/updateSystemSettingMini',params);
}

//小程序配置获取课程列表
export const appletList = params => {
    return postAjax('/api/run/courseName/appletList',params);
}
