<template>
	<workArea :barlist="data.barlist">
	<div class="card_manage bg-fff p20 pt10">
		<el-tabs v-model="data.tabNum" @tab-click="handleClick">
		    <el-tab-pane label="课程列表" name="0"></el-tab-pane>
		    <el-tab-pane label="训练目的" name="1"></el-tab-pane>
		</el-tabs>
		<component :is="components[data.tabNum]" :tab="data.tabNum" ></component>
	</div>
	</workArea>
</template>
<script>
  export default {
    name: 'MainCourse'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount} from 'vue';
	import List from '@/views/main-store/course/List.vue' //课程
	import Objective from '@/views/main-store/course/Objective.vue' //入场
	import workArea from '@/components/branch-store/workArea.vue';
	const components = [List,Objective];
	const data = reactive({
		barlist:[{ name : '首页' },{ name : '经营类目' },{ name : '课程管理' }],
		tabNum:'0',
	})
	function handleClick(tab) {
      localStorage.setItem('Course', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('Course') != null) {
          data.tabNum = localStorage.getItem('Course')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('Course')
	})
</script>

<style scoped lang="stylus">
	
</style>
