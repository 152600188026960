<template>
	<div class="bg-fff">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<!-- 按钮区域 -->
		<div class="rx-sc mb16">
			<el-button type="primary" @click="()=>{addStaff('')}" v-if="utils.getMenuRole(3170000)">新增课程</el-button>
			<el-button type="danger" plain @click="deletSomeOne" v-if="utils.getMenuRole(3170001)">批量删除</el-button>
		</div>
		<!-- 数据表格 -->
		<div>
			<el-table ref="satffTable" :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column type="selection" width="55" :selectable="(row)=>{return row.sid != 0}" />
			    <el-table-column prop="courseName" label="课程名称" show-overflow-tooltip>
					<template #default="scope">
						<a href="javascript:;" class="color-409EFF tool_btn" v-if="scope.row.sid != 0 && utils.getMenuRole(3170004)" @click="addStaff(scope.row)">{{scope.row.courseName}}</a>
						<span v-else>{{scope.row.courseName}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="courseType" label="课程类型" >
					<template #default="scope">
						<span>{{scope.row.courseType == 11 ? '团体课' : scope.row.courseType == 12 ? '私教课' : scope.row.courseType == 13 ?'小班课':scope.row.courseType == 14 ?'私教1V1':scope.row.courseType == 15 ?'私教1V多':''}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="courseTime" label="单课时长" />
			    <!-- <el-table-column prop="storeName" label="上课门店" show-overflow-tooltip /> -->
			    <el-table-column prop="sellType" label="售卖方式" >
					<template #default="scope">
						<span>{{scope.row.sellType == 1 ? '线下售卖' :'不售卖'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="coursePrice" label="课程单价" >
					<template #default="scope">
						<div v-if="scope.row.sellType == 1">
							<span v-if="scope.row.coursePrice || scope.row.coursePrice == 0">
								￥{{ scope.row.coursePrice }}
								<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.priceType == 2 &&  utils.getMenuRole(3170006)" @click="setCoursePrice(scope.row.encryptionId, scope.row.coursePrice)"><el-icon><EditPen /></el-icon></a>
							</span>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn"  v-else-if="utils.getMenuRole(3170005)" @click="setCoursePrice(scope.row.encryptionId, '')">设置价格</a>
						</div>
						<span v-else>-</span>
					</template>
				</el-table-column>
				<el-table-column prop="name" label="课程状态" v-if="utils.getMenuRole(3170002)">
					<template #default="scope">
						<div @click="updateShelfState(scope.row)">
							<!-- el-switch 初始值为0默认回走一次onchange事件 所以不用 -->
							<el-switch v-model="scope.row.state" inline-prompt :active-value="1" :inactive-value="0" ></el-switch>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="courseTacher" label="课程颜色" >
					<template #default="scope">
						<div class="w25 h25" style="padding:3px;" :style="{border:'1px solid'+' '+'#'+scope.row.courseColor}">
							<div class="ww100 hh100" :style="{backgroundColor:'#'+scope.row.courseColor}"></div>
						</div>
					</template>
				</el-table-column>
			    <el-table-column prop="courseTacher" label="授课教练数" >
					<template #default="scope">
						<span :class="scope.row.courseTeacher ? 'color-409EFF c_p tool_btn' : ''" @click="lookCoach(scope.row)">{{scope.row.courseTeacher ? getTeacherNum(scope.row.courseTeacher) : 0}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="channelName" label="发布渠道 " />
				<el-table-column label="操作" min-width="110">
					<template #default="scope">
						<el-link class="t_d_u" :underline="false" type="primary" @click="lookCourse(scope.row.courseNameId)">查看详情</el-link>
						<el-link class="ml10 t_d_u" :underline="false" type="danger" plain v-if="scope.row.sid > 0 && utils.getMenuRole(3170003)" @click="deleCourse(scope.row.encryptionId)">删除</el-link>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			  </div>
		</div>
		<!-- 查看授课教练 -->
		<el-dialog
		    v-model="pageData.lookCoachShow"
		    :title="pageData.lookCoachTitle"
			width="500px"
		    :before-close="()=>{pageData.lookCoachShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="p20">
		    	<el-table ref="satffTable" :data="pageData.lookCoachList" stripe style="width: 100%">
		    	    <el-table-column prop="realName" label="教练名称" />
		    	    <el-table-column prop="ifFullTime" label="教练类型" >
						<template #default="scope">
							<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
						</template>
					</el-table-column>
		    	    <el-table-column prop="storeName" label="教练归属" />
				</el-table>
		    </div>
			<template #footer>
    		  	<span class="dialog-footer"></span>
    		</template>
		</el-dialog>
		<!-- 设置价格 -->
		<el-dialog
		    v-model="pageData.setCoursePriceShow"
		    title="设置单价"
			width="400px"
		    :before-close="()=>{pageData.setCoursePriceShow = false}"
		    custom-class="popwechat"
		  >
		    <div style="border-top:1px solid #d6d6d6;border-bottom:1px solid #d6d6d6;padding:20px;">
				<div class="rx-sc">
					<span><span class="color-red">*</span>课程单价：</span>
					<div class="w250">
						<el-input v-model="pageData.setCoursePriceValue" type="text" placeholder="请输入课程单价(保留2位小数)" @blur="setCoursePriceBlur"></el-input>
					</div>
				</div>
			</div>
			<template #footer>
			 	<div class="dialog-footer">
			     	<el-button @click="pageData.setCoursePriceShow = false">取消</el-button>
			     	<el-button type="primary" @click="setCoursePriceSave">确定</el-button>
			 	</div>
			 </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted, computed } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex';
	import { ElMessage } from 'element-plus'
	import { DArrowRight, Close, EditPen } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import Confirm from '@/components/Confirm.vue'
	import { courseList,queryCourseTeacher,updateStateOrValue,courseNameDelete } from '@/api/course.js'
	import { getStoreAccountStaffSimple } from '@/api/staff.js';
	import formQuery from '@/components/branch-store/formQuery.vue';
	const store = useStore();
	const router = useRouter()
	const confirmRef = ref ()
	const courseTypeList = [
		{label : '全部课程类型' ,value : ''},
		{label : '团体课' ,value : '11'},
		{label : '小班课' ,value : '13'},
		{label : '私教1V1' ,value : '14'},
		{label : '私教1V多' ,value : '15'}
	]
	const saleUserList =[]
	const pageData = reactive({
		tableData:[],
		totalCount : 0,
		searchForm:{
			page:1,
			limit:15,
			storeId:'',
			courseName:'',
			channelSid:'',
			courseType:''
		},
		storeList:[],
		lookCoachList:[],
		lookCoachShow:false,
		lookCoachTitle:'查看教练',
		confirmTitle:'',
		confirmText:'',
		deleCheckData:[],
		setCoursePriceShow:false,
		setCoursePriceValue:'',
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'courseName',
				placehold : '请输入课程名称'
			},{
				type : 'select',
				fieldName : 'courseType',
				placehold : '全部课程类型',
				options : courseTypeList,
				value:''
			},{
				type : 'select',
				fieldName : 'state',
				placehold : '全部课程状态',
				options : utils.getDict('courseStatus',{label : '全部课程状态' ,value : ''}),
				value:''
			},{
				type : 'select',
				fieldName : 'channelSid',
				placehold : '全部发布渠道',
				options : [],
				value:''
			}, {
				type : 'select',
				fieldName : 'courseTeacher',
				placehold : '全部教练',
				options : saleUserList,
				value:''
			},{
				type : 'select',
				fieldName : 'sortType',
				placehold : '排序方式',
				options : utils.getDict('sortType'),
				value:'1'
			}]
		}
	})
	utils.getDynaDict('storeList',{label : '全部发布渠道' ,value : ''},function(res){
	    for(var i=0;i<res.length;i++){
	        pageData.queryOpts.fields[3].options.push(res[i]);
	    }
	});
	function getPersonnel() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 0,
			teachType: 0
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
				saleUserList.push({
                       label: '全部教练',
                       value: ''
                    })
                res.data.forEach(item => {
                    let params = {
                       label: item.realName,
                       value: item.id
                    }
                   saleUserList.push(params)
               })
            }
        })
    }
	//筛选表单回调
	function getTableData(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	// 查看详情
	function lookCourse(id){
		router.push({path:'/branch/courseDetail',query:{id}})
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	getList();
	function getList(){
		courseList(pageData.searchForm).then((res)=>{
			if(res.code == 0){
				pageData.tableData = res.data;
				pageData.totalCount = res.count;
			}
		})
	}
	
	// 新增-编辑
	function addStaff(row){
		var query = row ? {id:row.courseNameId,eId:row.encryptionId} : {};
		router.push({path:'/branch/addUpdateCourse',query});
	}
	
	function getTeacherNum(text){
		var list = text.split(',');
		return list.length;
	}
	//查看教练数
	function lookCoach(row){
		if(row.courseTeacher){
			queryCourseTeacher({
				courseTeacher:row.courseTeacher
			}).then((res)=>{
				if(res.code == 0){
					pageData.lookCoachList = res.data;
					pageData.lookCoachTitle = row.courseName+'-授课教练';
					pageData.lookCoachShow = true;
				}
			})
		}
	}
	//删除课程
	var confirmType = '',courseEId = '';
	function deleCourse(id){
		pageData.confirmTitle = '确定要删除该课程吗？';
		confirmRef.value.show();
		confirmType = 'deleOne';
		courseEId = id;
		
	}
	//批量删除课程
	function selectionChange(data){
		pageData.deleCheckData = data.map(n=>n.encryptionId);
	}
	function deletSomeOne(){
		if(pageData.deleCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择要删除的课程',
			})
			return false;
		}
		pageData.confirmTitle = '确定要删除选中的课程吗？';
		confirmRef.value.show();
	}
	function confirmSave(){
		courseNameDelete({
			ids:confirmType == 'deleOne' ? courseEId :pageData.deleCheckData.join(',')
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '删除成功',
				})
				searchTable();
			}
		})
	}
	// 设置价格
	function setCoursePriceBlur(){
		if (pageData.setCoursePriceValue) {
			if (isNaN(Number(pageData.setCoursePriceValue))) {
				ElMessage({
					type: 'warning',
					message: '输入课程单价格式不正确',
				})
				pageData.setCoursePriceValue = '';
				return false;
			}
			pageData.setCoursePriceValue = Number(pageData.setCoursePriceValue).toFixed(2);
		}
	}
	var courseId = '';
	function setCoursePrice(id, val){
		courseId = id;
		pageData.setCoursePriceValue = val
		pageData.setCoursePriceShow = true;
	}
	function setCoursePriceSave(){
		updateStateOrValue({
			encryptionId:courseId,
			customPrice:pageData.setCoursePriceValue
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
				  type: 'success',
				  message: '设置成功',
				})
				pageData.setCoursePriceShow = false
				searchTable();
			}
		})
	}
	//上下架操作
	function updateShelfState(row){
		if (row.sellType == 1) {
			if (row.coursePrice) {
				updateStateOrValue({
					encryptionId:row.encryptionId,
					state:row.state ? 1 : 0
				}).then((res)=>{
					if(res.code == 0){
						ElMessage({
						  type: 'success',
						  message: row.state ?'上架成功':'下架成功',
						})
						searchTable();
					}
				})
			} else {
				ElMessage({
				  type: 'warning',
				  message: '请先设置售卖价格',
				})
				searchTable();
			}
		} else {
			updateStateOrValue({
				encryptionId:row.encryptionId,
				state:row.state ? 1 : 0
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
					  type: 'success',
					  message: row.state ? '上架成功' : '下架成功',
					})
					searchTable();
				}
			})
		}
	}
	onMounted(() => {
		getPersonnel()
	})
	// 页面缓存
		onBeforeRouteLeave((to, from)=>{
		    if (to.path === '/branch/courseDetail') {
		        store.commit('SetKeep',{keepLiveRoute : ['BranchCourse']});
		    } else {
		        store.commit('SetKeep',{keepLiveRoute : []});
		    }
		})
</script>

<style scoped lang="stylus">
	@media screen and (max-width: 1366px) {
	    .h400{
			height 300px !important;
		}
	}
.el-dialog__body{padding:0px !important;}
	.shuttle_box{
		border 1px solid #EBEEF5;
		border-radius 4px;
		.add_title{
			background-color #F4F9FF;
			padding 10px;
			text-align center;
		}
		.add_checked{
			width 100%;
			padding 20px;
			li{
				background-color #F5F5F5;
				border-radius 2px;
				border 1px solid #D6D6D6;
				cursor pointer;
				padding 5px 10px;
			}
			li:hover{
				border 1px solid #409EFF;
				i{
					color red;
				}
			}
		}
		
	}
</style>
