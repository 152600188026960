<template>
	<div class="all_info mt10 mb10" :class="{active:storeData[1].moduleStatus && storeData[2].moduleStatus}">
		<div class="bg-FAFAFC pt10 pr10 pb10 pl10 rx-bc box" :style="{backgroundColor:themeStore.bgColor == 2 ? 'rgba(255,255,255,0.1)' : ''}">
			<div>
				<h3 class="ft12 mb5" :class="{'color-fff':themeStore.bgColor == 2}">累计上课</h3>
				<p class="ft12 mb5" :class="{'color-fff':themeStore.bgColor == 2}">今天<span class="color-red ft16"> 0 </span>分钟</p>
				<p class="ft12 color-999" :class="{'color-fff':themeStore.bgColor == 2}">本周已上课0分钟</p>
			</div>
			<img class="w70" :class="{active:storeData[1].moduleStatus && storeData[2].moduleStatus}" :src="storeData[1].moduleStatus && storeData[2].moduleStatus ? require('@/assets/applet/allClass1.png') : require('@/assets/applet/allClass.png')" alt="">
			<el-icon class="icon" :class="{'color-fff':themeStore.bgColor == 2}"><ArrowRight /></el-icon>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ArrowRight} from '@element-plus/icons-vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list;
	const themeStore = store.state.appletFormData[4].list[0];
</script>

<style scoped lang="stylus">
	.all_info {
		.box{
			position relative
			.icon{
				position absolute;
				right 0;
				top 5px;
				color #999;
			}
			img.active{
				position absolute;
				right 0px;
				top: 25px;
				opacity: 0.1;
				width: 50px;
			}
		}
	}
</style>
