<template>
    <div class="p20 pt0">
        <formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="searchGetList" style="flex: 1"></formQuery>
        <el-table v-loading="pageData.loading" stripe element-loading-text="拼命加载中。。。" ref="multipleTable" :data="pageData.tableData" style="width: 100%">
			<el-table-column prop="realName" fixed label="会员名称" width="180" show-overflow-tooltip>
                <template #default="scope">
					<div @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p">{{scope.row.realName}}</div>
                    <div>{{scope.row.telephoneNum}}</div>
				</template>
            </el-table-column>
            <el-table-column label="订场详情" width="280">
                <template #default="scope">
                    <div class="o_h" :class="[ scope.row.show ? '' : 'h20']">
                        <div v-for="(item, index) in scope.row.orderDetailList" :key="index">
                            <span>{{ item }}</span>
                        </div>
                    </div>
                    <a v-if="scope.row.orderDetailList.length>1" href="javascript:;" class="tool_btn color-409EFF ft12" @click="scope.row.show = !scope.row.show">{{ scope.row.show ? '收起' : '查看' }}更多></a>
                </template>
            </el-table-column>
            <el-table-column label="订单金额(元)" width="120">
                <template #default="scope">
                    ￥{{ scope.row.shouldMoney ? scope.row.shouldMoney : 0 }}
                </template>
            </el-table-column>
            <el-table-column label="实收金额(元)" width="120">
                <template #default="scope">
                    <span v-if="scope.row.orderQueryState == 1 || scope.row.orderQueryState == 2 || scope.row.orderQueryState == 3">￥{{ scope.row.actualMoney ? scope.row.actualMoney : 0 }}</span>
                    <span v-else>￥0</span>
                </template>
            </el-table-column>
            <el-table-column label="订单来源" width="125" >
                <template #default="scope">
                    {{ scope.row.createBy ? '后台创建' : '小程序自主预约'  }}
                </template>
            </el-table-column>
            <el-table-column label="订单状态">
                <template #default="scope">
                    <span v-if="scope.row.orderQueryState == 0 || scope.row.orderQueryState == 6" class="color-FA6400">未支付</span>
                    <span v-if="scope.row.orderQueryState == 1" class="color-44D7B6">已支付</span>
                    <span v-if="scope.row.orderQueryState == 2" class="color-FA0008">已退款</span>
                    <span v-if="scope.row.orderQueryState == 3" class="color-FA0008">部分退款</span>
                    <span v-if="scope.row.orderQueryState == 4" class="color-FA6400">待支付</span>
                    <span v-if="scope.row.orderQueryState == 5" class="color-666">已取消</span>
                </template>
            </el-table-column>
            <el-table-column label="支付方式">
                <template #default="scope">
                    <el-tooltip
                      v-if="scope.row.orderQueryState != 0 && scope.row.orderQueryState != 6 && scope.row.orderQueryState != 4"
                      class="box-item"
                      effect="dark"
                      :content="filtersType(scope.row.payMethodId, 3)"
                      placement="top-start"
                    >
                       <img class="w20 h20" :src="payIconList[scope.row.payMethodId]" alt="">
                    </el-tooltip>
                    <span v-else>-</span>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="160">
                <template #default="scope">
                    {{ utils.getFormatDate(scope.row.createTime) }}
                </template>
            </el-table-column>
            <el-table-column label="支付时间" width="160">
                <template #default="scope">
                    {{ scope.row.orderQueryState != 0 && scope.row.orderQueryState != 6 && scope.row.orderQueryState != 4  ? (scope.row.paySuccessTime ? utils.getFormatDate(scope.row.paySuccessTime) : utils.getFormatDate(scope.row.createTime)) : '-' }}
                </template>
            </el-table-column>
            <el-table-column prop="batchCode" label="订单号" width="180" />
            <el-table-column prop="userName" label="销售员" show-overflow-tooltip />
            <el-table-column fixed="right" align="center" label="操作" width="150">
                <template #default="scope">
                     <!-- && utils.getMenuRole(3200001) -->
                    <a v-if="scope.row.orderQueryState == 0 || scope.row.orderQueryState == 6" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="collection(scope.row)">收款</a>
                    <div v-if="scope.row.orderQueryState == 1 || scope.row.orderQueryState == 3">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="memberRefundEvent(scope.row)">退订</a>
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="printTicketEvent(scope.row)">打印</a>
                    </div>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-ec">
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
        <el-dialog
            v-model="pageData.dialogVisible"
            title="退订"
            width="875px"
        >
            <div class="p20 pt10">
                <CommonTitle text="订单详情" />
                <div class="pl20 pr20">
                    <el-row class="mb30">
                        <el-col :span="8">订单编号：{{ pageData.cashierForm.batchCode }}</el-col>
                        <el-col :span="8">订单来源：{{ pageData.cashierForm.createBy ? '后台创建' : '小程序自主预约' }}建</el-col>
                        <el-col :span="8">订单计费方式：{{ pageData.cashierForm.costType == 1 ? '按时间计费' : '按人数计费' }}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="8" v-if="pageData.cashierForm.costType == 1">应收总金额：{{ pageData.cashierForm.shouldMoney }}</el-col>
                        <el-col :span="8" v-else>应收总金额：
                            {{ pageData.cashierForm.peopleNum }} 人*{{ pageData.cashierForm.peopleNum ==1 ? pageData.cashierForm.shouldMoney : pageData.cashierForm.peopleMoney }}元
                        </el-col>
                        <el-col :span="8">实收总金额：{{ pageData.cashierForm.actualMoneyOld }}</el-col>
                        <el-col :span="8">订单状态：
                            <span :class="pageData.cashierForm.partRefund == 1? 'color-red' : 'color-018000'">{{pageData.cashierForm.partRefund == 1 ? '部分退款' : '已支付'}}</span>
                        </el-col>
                    </el-row>
                </div>
                <CommonTitle text="订场明细" />
                <div class="pl20 pr20">
                    <el-table  stripe :data="pageData.cashierForm.list" style="width: 100%">
                        <el-table-column prop="roomName" label="场地名称" />
					    <el-table-column prop="time" label="预订时段" width="180" />
					    <el-table-column prop="actualMoney" label="单价" v-if="pageData.cashierForm.costType == 1" />
 					    <el-table-column prop="actualMoney" label="实收" v-if="pageData.cashierForm.costType == 1" />
					    <el-table-column label="支付状态" >
					    	<template #default="scope">
					    		<span :class="scope.row.refundState == 1? 'color-red' : 'color-018000'">
                                    <span v-if="scope.row.refundState == 1">已退款</span>
                                    <span v-else>{{ scope.row.state == 4 ? '已释放，未退款' : '已支付'}}</span>
                                </span>
					    	</template>
					    </el-table-column>
					    <el-table-column label="操作" >
					    	<template #default="scope">
					    		<el-checkbox v-if="scope.row.refundState != 1" @change="refundCheckChange(scope.row)" v-model="scope.row.check" label="" size="large" />
					    	</template>
					    </el-table-column>
                    </el-table>
                </div>
                <CommonTitle text="操作退款" />
                <div class="rx-bc pl20 pr20 mb20">
			    	<div class="rx-sc ">
			    		<span class="w110 t_a_r"><span class="color-red">*</span>退款金额：</span>
			    		<div class="w220">
			    			<el-input v-model="pageData.cashierForm.actualMoneyNew" type="text" placeholder="请输入退款金额" @input="(v) => (pageData.cashierForm.actualMoney = v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))">
			    				<template #append>元</template>
			    			</el-input>
			    		</div>
			    	</div>
			    	<div class="rx-sc">
			    		<span class="w110 t_a_r"><span class="color-red">*</span>退款方式：</span>
			    		<div class="w220">
			    			<el-select v-model="pageData.cashierForm.refundType" placeholder="请选择">
                                <el-option label="退现金" value="1" v-if="pageData.cashierForm.payMethodId != 101 " />
							    <el-option label="退款至原支付账户" value="2" v-if="pageData.cashierForm.payMethodId == 100 || (pageData.cashierForm.payMethodId == 3 && pageData.cashierForm.createBy == 0)" />
							    <el-option label="退至储值卡" value="3" v-if="pageData.cashierForm.payMethodId == 101"/>
			    			</el-select>
			    		</div>
			    	</div>
			    </div>
                <div class="rx-bs pl20 pr20 mb16">
                    <div class="rx-c" v-if="pageData.cashierForm.costType != 1">
			    		<span class="w110 t_a_r ">退款人数：</span>
			    		<div class="w220">
			    			<el-input class="t_a_c" type="number" v-model="pageData.cashierForm.peopleNum1" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                <template #append>人</template>
                            </el-input>
			    		</div>
			    	</div>
			    	<div class="rx-cs">
			    		<span class="w110 t_a_r ">备注：</span>
			    		<div class="w220">
			    			<el-input
                                v-model="pageData.cashierForm.remark"
                                :autosize="{ minRows: 2 }"
                                type="textarea"
                                placeholder="请输入"
                            />
			    		</div>
			    	</div>
			    </div>
            </div>
            <template #footer>
                <div class="dialog-footer rx-bc">
                    <div class="fwb color-333">
                        <span>退款：</span>
                        <span class="color-FA0008">￥{{ pageData.cashierForm.actualMoneyNew }}</span>
                    </div>
                    <div>
                        <el-button @click="pageData.dialogVisible = false">取消</el-button>
                        <el-button type="primary" @click="onSubmit">确定</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
        <el-dialog
            v-model="pageData.dialogCashier"
            title="收银台"
            width="690px"
            :before-close="handleCashier"
        >
            <div class="pl40 pr40 pt20 pb20">
                <div class="pb20 pl20">
                    <div class="posi-r d_i_b">
                        <el-avatar :size="60" :src="pageData.cashierForm.headImgUrl"></el-avatar>
                    </div>
                    <div class="ml15 d_i_b v_a_t ww80">
                            <div>
                                <h4 class="d_i_b">{{ pageData.cashierForm.realName }}</h4>
                                <div class="ml5 d_i_b tag_blue" :class="pageData.cashierForm.trialFlag == 1 ? 'tag_blue' : 'tag_red'">{{ pageData.cashierForm.trialFlag == 1 ? "正式会员" : "潜在会员" }}</div>
                            </div>
                            <div class="color-333 mt15">
                                <img class="w20 h20 mr5 v_a_m" src="@/assets/try_icon6.png" alt="">
                                <span class="v_a_m">{{ pageData.cashierForm.telephoneNum}}</span>
                            </div>
                        </div>
                </div>
                <el-form class="pb20" ref="priceRef" :model="pageData.cashierForm" :rules="cashierRules" label-width="100px">
                    <el-form-item label="计费方式："><span class="color-333">{{ pageData.cashierForm.costType == 1 ? '按时间计费' : '按人数计费' }}</span></el-form-item>
                    <el-form-item label="预订人数：" v-if="pageData.cashierForm.costType == 2">
                        <el-input class="w290 t_a_c" type="number" v-model="pageData.cashierForm.peopleNum" @input="getActualMoneyByPeople" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>人</template>
                        </el-input>
                        <span class="ml20">单价：{{ pageData.cashierForm.peopleMoney }}元/人</span>
                    </el-form-item>
                    <el-form-item label="订单应收：">
                        <span class="color-red">￥{{ pageData.cashierForm.shouldMoney }}</span>
                    </el-form-item>
                    <el-form-item label="场地实收：">
                        <el-input class="w290 t_a_c" type="number" v-model="pageData.cashierForm.actualMoney" maxlength="4"  @input="getMoneyByActualMoney">
                            <template #append>元</template>
                        </el-input>
                    </el-form-item>
                    <div class="pb20">
                        <el-table :data="pageData.cashierForm.list" stripe style="width: 100%">
                            <el-table-column prop="roomName" label="场地名称" />
					        <el-table-column prop="time" label="预订时段" width="180" />
					        <el-table-column prop="actualMoney" label="会员单价" v-if="pageData.cashierForm.costType == 1" />
					        <el-table-column label="实收金额" v-if="pageData.cashierForm.costType == 1" >
					        	<template #default="scope">
					        		<el-input type="number" @input="getActualMoneyByMoney" v-model="scope.row.money" />
					        	</template>
					        </el-table-column>
                        </el-table>
                    </div>
                    <el-form-item label="收款方式：">
                        <div class="w290">
                            <el-select v-model="pageData.cashierForm.payMethodId" placeholder="请选择">
			    		    	<el-option
					              v-for="item in payTypeData.payList"
					              :key="item.payId"
					              :label="item.payMethodName"
					              :value="item.payId"
					            />
			    		    </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item label="储值卡：" v-if="pageData.cashierForm.payMethodId == 101">
                        <div class="w290">
                            <el-select v-model="pageData.cashierForm.orderId" placeholder="请选择">
					            <el-option
					              v-for="item in payTypeData.cardList"
					              :key="item.id"
					              :label="item.productName"
					              :value="item.id"
                                />
					        </el-select>
                        </div>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input
                            class="w290"
                            v-model="pageData.cashierForm.remark"
                            :autosize="{ minRows: 4 }"
                            type="textarea"
                            placeholder="请输入"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <div class="dialog-footer rx-bc">
                    <div class="fwb color-333">
                        <span>实收：</span>
                        <span class="color-FA0008">￥{{ pageData.cashierForm.actualMoney }}</span>
                    </div>
                    <div>
                        <el-button @click="handleCashier">取消</el-button>
                        <el-button type="primary" @click="onSubmitCashier">确定</el-button>
                    </div>
                </div>
            </template>
        </el-dialog>
  
        <Confirm ref="confirmRef" :title="confirmData.title" :text="confirmData.text" @save="confirmSave">
	    	<template #btns>
	    		<el-button type="primary" @click="refundEvent">去退款</el-button>
	    		<el-button type="primary" @click="cancelEvent">仅释放，不退款</el-button>
	    	</template>
	    </Confirm>
        <PrintTicket v-model="pageData.printShow" type="4" :data="pageData.courseInfo" @save="printSave"></PrintTicket>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex'
    import { useRouter,onBeforeRouteLeave } from 'vue-router';
    import { colProps, ElMessage } from 'element-plus'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import PrintTicket from '@/components/branch-store/PrintTicket.vue';
    import CommonTitle from '@/components/CommonTitle.vue'
    import Confirm from '@/components/Confirm.vue'
    import utils from '@/common/utils.js'
    import { queryAllSaleRoomList, getOrderToPay, doOrderPay, memberOrderRoomManyCancel, cancelTimesOnly } from '@/api/appstore.js';
    import { payDetailToEdit } from '@/api/storeCenter.js'
    import { getPayCode } from '@/api/pay.js'
    import { listStoreCard4Order } from '@/api/member.js';
    const store = useStore();
	const router = useRouter();
    const queryOptsRef = ref()
    const confirmRef = ref ()
	const confirmData = reactive({
		title:'',
		text:''
	})
    const orderType = [
        {label : '全部订单状态' ,value : ''},
        {label : '未支付' ,value : '0'},
        {label : '已支付' ,value : '1'},
        {label : '已退款' ,value : '2'},
        {label : '部分退款' ,value : '3'},
        {label : '待支付' ,value : '4'},
        {label : '已取消' ,value : '5'}
    ]
    const timerList = [
        { value : "5" , label : '最近7天' },
        { value : "30" , label : '本周' },
        { value : "20" , label : '本月' },
        { value : "21" , label : '上月' },
        { value : "-1" , label : '其他' }
    ]
    const payIconList = {
		0: require('@/assets/payIcon/payIcon0.png'), 
		1: require('@/assets/payIcon/payIcon1.png'), 
		2: require('@/assets/payIcon/payIcon2.png'), 
		3: require('@/assets/payIcon/payIcon3.png'), 
		4: require('@/assets/payIcon/payIcon4.png'), 
		8: require('@/assets/payIcon/payIcon8.png'),
        100: require('@/assets/payIcon/payIcon100.png'), 
		101: require('@/assets/payIcon/payIcon101.png'), 
	}
    const pageData = reactive({
        loading: false,
        show:false,
        tableData: [],
        searchForm: {
            page: 1,
            limit: 15
        },
        printShow: false, //打印小票弹框
        courseInfo: {}, // 打印小票表单
        dialogVisible: false, // 退订弹框
        unsubscribe: {}, // 退订表单
        dialogCashier: false, // 收银台弹框
        cashierForm: {}, // 收银台表单
        queryOpts:{
			fields:[{
	        	type : 'input',
	        	fieldName : 'keyword',
	        	placehold : '订单编号/姓名/手机号'
	        },{
				type : 'select',
				fieldName : 'orderFrom',
				placehold : '订单来源',
				options : [{label : '全部订单来源' ,value : ''},{ value : '2' , label : '后台创建' },{ value : '1' , label : '小程序自主预约' }],
                value:''
		    },{
				type : 'select',
				fieldName : 'orderQueryState',
				placehold : '订单状态',
				options : orderType,
                value:''
		    },{
				type : 'select',
				fieldName : 'payMethodId',
				placehold : '支付方式',
				options : utils.getDict('waterPayType',{label : '全部支付方式' ,value : ''}),
                value:''
		    },{
                type : 'select',
                fieldName : 'timeIn',
                placehold : '',
                value : '5',
                options : timerList,
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'createTimeStr',
                placeholder : '日期范围'
            }]
        }
    })
    /**
     * @param (String, Number) value-类型, type-1 商品类型 2-支付状态 3-支付方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        utils.getDict('waterPayType').forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    //取消预订
    let pageRow = {}
	function cancelClick(row){
		confirmData.title = '订单已支付，是否取消并退款？'
        pageRow = row
		confirmRef.value.show();
	}
    // 去退款
    function refundEvent(){
        confirmRef.value.close();
		getOrderInfo(pageRow, function(){
			pageData.dialogVisible = true
		})
	}
          //确认框回调
	function confirmSave(){
        cancelEvent();
	}
    // 确认框回调
	function cancelEvent(){
		confirmRef.value.close();
        getOrderInfo(pageRow, function(){
            var roomOrderIds = []
            pageData.cashierForm.list.map((item)=>{
		    	roomOrderIds.push(item.encryptionRoomOrderId);
		    })
		    cancelTimesOnly({
		    	encryptionRoomOrderIds:roomOrderIds.join(',')
		    }).then((res)=>{
		    	if(res.code == 0){
		    		ElMessage({
		    			type: 'success',
		    			message: '取消成功'
		    		})
		    		getList()
		    	}
		    })
		})
	}
    function getActualMoneyByPeople(){
		pageData.cashierForm.actualMoney = pageData.cashierForm.peopleNum*pageData.cashierForm.peopleMoney;
	}
    //通过实收计算每个场地的金额
	function getMoneyByActualMoney(){
		if(pageData.cashierForm.costType == 1){ //按时间计费
			var actualMoney = isNaN(Number(pageData.cashierForm.actualMoney)) ? 0 : pageData.cashierForm.actualMoney;
			var len = pageData.cashierForm.list.length;
			var average = parseInt(Number(actualMoney)/len); //平均差不多分配的金额
			var num = Number(actualMoney) - (len-1)*average; //最后一条记录剩下的钱数
			pageData.cashierForm.list.map((n,i)=>{
				if(i == len-1){
					n.money = num;
				}else{
					n.money = average;
				}
			})
		}
	}
    //通过每个场地的金额计算实收
	function getActualMoneyByMoney(){
		var num = 0;
		pageData.cashierForm.list.map((n,i)=>{
			num+=isNaN(Number(n.money)) ? 0 : Number(n.money);
		})
		pageData.cashierForm.actualMoney = num;
	}
    //获取支付方式
	const payTypeData = reactive({
		payList:[],
		cardList:[],
	})
	function getPayTypeList(encryptionId){
		payDetailToEdit().then((res)=>{
			if(res.code == 0){
				getPayCode().then((resolve) => {
					if (resolve.code == 0) {
						payTypeData.payList = res.data;
						if (resolve.data&&resolve.data.url) {
							payTypeData.payList.push({
								payMethodName:'收款码',
								payId:100
							})
						}
					}
				})
				getStoreCard(encryptionId);
			}
		})
	}
    //获取可用消费的储值卡
	function getStoreCard(stuId){
		listStoreCard4Order({
			encryptionId:stuId,
			consumeType: 7
		}).then((res)=>{
			if(res.code == 0){
				if(res.data.length > 0){
					payTypeData.cardList = res.data;
					payTypeData.payList.push({
						payMethodName:'储值卡',
						payId:101
					})
				}
			}
		})
	}
    //切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取列表
     */
    const getList=() => {
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())

		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.timeIn == '-1' && !params.createTimeStr) {
            ElMessage.warning('请选择自定义消费时间')
            return false
        }
        pageData.loading = true
        params.startTimeStr = params.timeIn == '-1' && params.createTimeStr ?  params.createTimeStr[0] : ''
        params.endTimeStr = params.timeIn == '-1' && params.createTimeStr ? params.createTimeStr[1] :' '
        delete params.createTimeStr
        queryAllSaleRoomList(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if (res.code == 0) {
					pageData.tableData = res.data
					pageData.totalCount = res.count
				}
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 退订
     */
    function memberRefundEvent(row) {
        cancelClick(row)
    }
    /**
     * 收款
     */
    function collection(row){
        getOrderInfo(row, function(){
			pageData.dialogCashier = true
		})
    }
    /**
     * 获取订单信息
     */
    function getOrderInfo(row,callback) {
        getOrderToPay({ encryptionBatchId: row.encryptionBatchId }).then(res => {
            if (res.code == 0) {
                pageData.cashierForm = res.results.batch;
                pageData.cashierForm.actualMoneyNew = 0;
			    pageData.cashierForm.actualMoneyOld = res.results.batch.actualMoney;
			    pageData.cashierForm.payMethodId = !pageData.cashierForm.payMethodId ? '' : pageData.cashierForm.payMethodId;
			    pageData.cashierForm.headImgUrl = res.results.roomOrderList[0].headImgUrl;
			    pageData.cashierForm.list =res.results.roomOrderList.map(n=>{
			    	var d = n.orderStartTime.split(' ')[0];
			    	var s = n.orderStartTime.substring(11,16);
			    	var e = n.orderEndTime.substring(11,16);
			    	n.time = d+' '+s+'-'+e;
                    n.money = n.actualMoney
                    n.check = false
			    	return n;
			    })
                getPayTypeList(pageData.cashierForm.encryptionCardMyId);
                callback && callback();
            }
        })
    }
    /**
     * 勾选场次计算退款金额
     */
    function refundCheckChange(row){
		var num = 0;
		pageData.cashierForm.list.map(n=>{
			if(n.check){
				num+=n.actualMoney;
			}
		})
		pageData.cashierForm.actualMoneyNew = num;
	}
    /**
     * 会员详情
     */
	const memberDetail=(id) => {
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
	/**
     * 会员详情
     */
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail' || to.path === '/branch/orderDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
    /**
     * 确定退订
     */
    function onSubmit(){
		var {encryptionBatchId,actualMoneyNew,refundType,remark,costType,peopleNum,peopleNum1,actualMoneyOld} = pageData.cashierForm;
		var roomOrderIds = [];
		pageData.cashierForm.list.map((n,i)=>{
			if(n.check){
				roomOrderIds.push(n.roomOrderId);
			}
		})
		if(costType == 1 && roomOrderIds.length == 0){ //按时间计费
			ElMessage({
				type: 'warning',
				message: '请选择需要退款的时段'
			})
			return false;
		}
		if(!actualMoneyNew && actualMoneyNew !== 0){
			ElMessage({
				type: 'warning',
				message: '请输入退款金额'
			})
			return false;
		}
		if(!refundType){
			ElMessage({
				type: 'warning',
				message: '请选择退款方式'
			})
			return false;
		}
        if (Number(actualMoneyOld)<Number(actualMoneyNew)) {
             ElMessage({
				type: 'warning',
				message: '退款金额错误'
			})
			return false;
        }
        if (Number(peopleNum)<Number(peopleNum1)) {
            ElMessage({
				type: 'warning',
				message: '退款人数错误'
			})
			return false;
        }
		memberOrderRoomManyCancel({
		 	encryptionBatchId,
		 	actualMoney: actualMoneyNew,
		 	refundType,
		 	roomOrderIds:roomOrderIds.join(','),
			remark,
            peopleNum: peopleNum1
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '退款成功'
				})
				getList()
				pageData.dialogVisible = false
			}
		})
	}
    /**
     * 取消收银台
     */
    function handleCashier() {
        pageData.dialogCashier = false
    }
    /**
     * 确定提交收银台
     */
    function onSubmitCashier() {
        var {encryptionBatchId,actualMoney,payMethodId,peopleNum,orderId,costType} = pageData.cashierForm;
		var roomOrderMoney = [];
		if(costType == 1){ //按时间计费
			pageData.cashierForm.list.map((n,i)=>{
				roomOrderMoney.push(n.roomOrderId+':'+n.money);
			})
		}
		doOrderPay({
			encryptionBatchId,
			actualMoney,
			payMethodId,
			peopleNum,
			orderId,
			roomOrderMoney:roomOrderMoney.join(',')
		}).then((res)=>{
			if(res.code == 0){
				if (payMethodId == 100) {
					if (actualMoney  == 0.00) {
						ElMessage({
							type: 'success',
							message: '支付成功'
						})
                        getList()
						pageData.dialogCashier = false
					} else {
                        let data = {
                            encryptionBatchId: encryptionBatchId,
                            batchCode: pageData.cashierForm.batchCode,
                            actualMoney: actualMoney,
                            realName: pageData.cashierForm.realName,
                            telephoneNum: pageData.cashierForm.telephoneNum
                        } 
						window.open('/#/branch/payCourseMoney?from=6&info=' + encodeURIComponent(JSON.stringify(data)), '_blank')
					}
				} else {
					ElMessage({
						type: 'success',
						message: '支付成功'
					})
                    getList()
					pageData.dialogCashier = false
				}
			}
		})
	}
        
    // 打印小票
	function printTicketEvent(row){
		pageData.courseInfo = row;
        pageData.courseInfo.createTime = utils.getFormatDate(row.createTime) 
		pageData.printShow = true;
	}
	function printSave(){
		pageData.printShow = false;
	}
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
    .dialog-footer{
        padding-left: 20px;
    }
    .sex{
        width: 17px;
        height: 17px;
        bottom: 3px;
        right: -4px;
    }
    .color-018000{
		color: #018000;
	}
    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {-webkit-appearance: none;}
    ::v-deep input[type=‘number’] {-moz-appearance: textfield;}
</style>