<template>
	<div class="bottom_info">
		<h3 class=" mb12 rx-sc">
			<span class="ft16 mr20">{{storeData.name}}</span>
			<span class="color-999 ft12">可选择其中一种导航风格</span>
		</h3>
		<div class="rx-ss">
			<span style="line-height: 40px;">展示形式：</span>
			<div>
				<div class="w330">
					<el-radio-group v-model="storeData.showType">
						<el-radio label="1" size="large">平铺</el-radio>
						<el-radio label="2" size="large">异形</el-radio>
					</el-radio-group>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12">
			<span style="line-height: 40px;">展示风格：</span>
			<div>
				<div class="w330">
					<el-radio-group v-model="storeData.iconType">
						<el-radio label="1" size="large">纯色</el-radio>
						<el-radio label="2" size="large">炫彩</el-radio>
					</el-radio-group>
				</div>
				<div style="width: 255px;">
					<BottomNav></BottomNav>
				</div>
			</div>
		</div>
		<div class="rx-ss mb12">
			<div class="w70">展示规则：</div>
			<div class="bg-fff pt20 pb20 pl10 pr10" style="width: 330px;">
				<template v-for="(item,index) in storeData.list">
					<div class="pt5 pb5">
						<div class="moveItem bg-fff	" :class="{active:storeData.active == index}">
							<div class="rx-bc pt5 pb5 pl15 pr20" style="border-bottom: 1px solid #ccc;">
								<div class="rx-sc">
									<span>{{item.name}}</span>
								</div>
								<div v-if="index == 2">
									<el-checkbox v-model="item.checked" size="large"></el-checkbox>
								</div>
							</div>
							<div class="rx-sc pl15 pt20 pb20 pr15">
								<span class="w75">显示名称：</span>
								<div class="w200">
									<el-input type="text" placeholder="请输入（ 限2-3个字 ）" maxlength="3" v-model="item.newName"></el-input>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	import MoveSort from '../MoveSort.vue'; //通用移动排序
	import BottomNav from '../center_page/BottomNav.vue'; //
	
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const navIcon = store.state.navIcon;
</script>

<style scoped lang="stylus">
	.bottom_info{
		.navBottom{
			li{
				height 60px;
				background-color white;
			}
			li.active{
				position relative;
				background-color initial;
				div{
					position absolute;
					left 0;
					top 22px;
					right 0;
					text-align center;
					z-index 50;
					img{
						position absolute;
						top -50px;
						left 50%;
						transform translateX(-50%);
						z-index 100;
						background-color white;
						border-radius 100%;
						padding: 9px;
						width: 40px;
						height: 40px;
					}
				}
			}
			li.active::after{
				content '';
				position absolute;
				top 0;
				right 0;
				bottom 0;
				left 0;
				clip-path: polygon(
				0% 0%, 
				5% 0%, 
				10% 1%,
				15% 3%,
				20% 8%,
				25% 17%,
				30% 23%,
				35% 26%,
				40% 28%,
				45% 29%,
				50% 30%,
				55% 29%,
				60% 28%,
				65% 26%,
				70% 23%,
				75% 17%,
				80% 8%,
				85% 3%,
				90% 1%,
				95% 0%,
				100% 0%,
				100% 100%,
				0 100%);
				background-color white;
			}
		}
		.moveItem{
			border: 1px solid #ccc;
		}
		.moveItem.active{
			border: 1px dashed #409EFF;
			background-color #EFF6FF;
		}
	}
</style>

