<template>
	<div class="color-999 ft12 mt16">温馨提示：展示可在本门店消费的已购卡项</div>
	<div class="mt16">
		<div :name="(i+1)" v-for="(n,i) in pageData.list" :key="i">
			<div class="card-top rx-bc c_p" @click="n.open = !n.open">
				<div class="rx-sc">
					<span class="d_i_b w5 h5 v_a_m bdr4 badge mr5"></span>
					<span class="ml5 fwb">已购{{n.title}}</span>
					<div class="ml20 color-666">
						共
						<span class="ml5 mr5 color-red">
							{{ n.title == '课程卡' ? (propData.num.courseCardNum || 0) : n.title == '入场卡' ? (propData.num.enterCardNum || 0) : n.title == '储值卡' ? n.data.length : (propData.num.trialCardNum || 0) }}
						</span>
						张
					</div>
				</div>
				<a href="javaScript:;" class="tool_btn color-main">
					<span class="v_a_m">{{ n.open ? '收起' : '展开' }}</span>
					<DArrowRight v-show="!n.open" class="arrowdown"></DArrowRight>
                    <DArrowLeft v-show="n.open" class="arrowdown"></DArrowLeft>
				</a>
			</div>
			<div class="mb25" v-show="n.open">
				<el-table :data="n.data" stripe class="mt10 itemtable">
					<el-table-column prop="productName" :label="n.title+'名称'" width="110" show-overflow-tooltip />
					<el-table-column prop="storeName" label="购买门店" width="110" show-overflow-tooltip />
					<el-table-column prop="operationTime" label="购买时间" />
					<el-table-column prop="actualMoney" label="实收金额(元)" />
					<el-table-column label="购买额度">
						<template #default="scope">
							<div v-if="scope.row.type == 3">
								<p>本金：{{scope.row.storeMoneyBaseBuy}}</p>
								<p>赠送：{{scope.row.storeMoneyGiveBuy}}</p>
							</div>
							<div v-else>
								<p v-if="scope.row.productTyp == 1">
									{{scope.row.productNumBuy+' ; '+scope.row.productCycleBuy}}
								</p>
								<p v-else>
									{{scope.row.productCycleBuy+' ; '+scope.row.productNumBuy}}
								</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="剩余额度">
						<template #default="scope">
							<div v-if="scope.row.type == 3">
								<p>本金：{{scope.row.storeMoneyBase}}</p>
								<p>赠送：{{scope.row.storeMoneyGive}}</p>
							</div>
							<div v-else>
								<p v-if="scope.row.productTyp == 1">
									{{scope.row.productNum+' ; '+scope.row.productCycle}}
								</p>
								<p v-else>
									{{scope.row.productCycle+' ; '+scope.row.productNum}}
								</p>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="有效期" width="200">
						<template #default="scope">
							<span v-if="scope.row.startTime">{{scope.row.startTime+'至'+scope.row.endTime}}</span>
							<span v-else>未开始</span>
						</template>
					</el-table-column>
					<el-table-column label="状态">
						<template #default="scope">
							<span :class="scope.row.orderState == 1 ? 'text-color' : '' ">{{scope.row.orderState == 1 ? '正常' : scope.row.orderState == 2 ? '已冻结' : scope.row.orderState == 3 ? '已结束': '已退款'}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="canUse" label="可用门店">
						<template #default="scope">
							<span v-if="scope.row.consumeStoreName">{{scope.row.consumeStoreName}}</span>
							<span v-else class="t_d_u color-409EFF tool_btn c_p" @click="lookStore(scope.row.encryptionProductId)">{{scope.row.consumeSidCount}}家门店</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>
	</div>
	<el-dialog v-model="pageData.lookStoreShow" title="查看门店" width="700px"
		:before-close="()=>{pageData.lookStoreShow = false}" custom-class="popwechat">
		<div class="p20">
			<div>
				<el-table :data="pageData.lookStoreList" style="width: 100%;" stripe>
					<el-table-column type="index" width="60" label="序号" />
					<el-table-column prop="storeName" label="门店名称" width="150" show-overflow-tooltip/>
					<el-table-column prop="storeAdd" label="门店地址" show-overflow-tooltip/>
				</el-table>
			</div>
			<div class="mt20 rx-ec">
				<el-pagination v-model:currentPage="pageData.lookStoreForm.page" :page-size="15"
					layout="total, prev, pager, next" :total="pageData.lookStoreCount" @current-change="lookStore">
				</el-pagination>
			</div>
		</div>
	</el-dialog>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { DArrowRight, DArrowLeft } from '@element-plus/icons-vue';
	import { queryConsumeSid, queryBuyCardList } from '@/api/member.js';
	const propData = defineProps({
		info: Object,
		num: Object
	})
	
	const pageData = reactive({
		lookStoreShow: false,
		lookStoreList: [],
		lookStoreCount: 0,
		lookStoreForm:{
			page:1,
			limit:15
		},
		list: [{
				title: '课程卡',
				data: []
			},
			{
				title: '入场卡',
				data: []
			},
			{
				title: '储值卡',
				data: []
			},
			{
				title: '体验卡',
				data: []
			}
		], // 已购卡项
	});

	function getBuyCardsList() {
		queryBuyCardList({
			encryptionCardMyId: propData.info.encryptionId
		}).then((res) => {
			if (res.code == 0) {
				res.data.map((n) => {
					n.open = false
					if (n.type != 3) {
						n.productNumBuy = n.productNumBuy !== '' && n.productNumBuy !== undefined ? n
							.productNumBuy + '次' : '不限次';
						n.productCycleBuy = n.productCycleBuy !== '' && n.productCycleBuy !== undefined ? n
							.productCycleBuy + (n.productUnit == 1 ? '天' : n.productUnit == 2 ? '月' :
							'年') : '不限时';
						n.productNum = n.productNum !== '' && n.productNum !== undefined && n.productNum >=0 ? n.productNum +
							'次' : '不限次';
						n.productCycle = n.productCycle !== '' && n.productCycle !== undefined ? n.productCycle == '-1' ? '未开始计时' : n.productCycle == '-2' ? '不限时' : n
							.productCycle + (n.productUnit == 1 ? '天' : n.productUnit == 2 ? '月' : '年') :
							'不限时';
					}
					pageData.list[n.type - 1].data.push(n);
				})
			}
		})
	}
	
	//查看门店
	function lookStore(id) {
		pageData.lookStoreForm.encryptionProductId = id;
		queryConsumeSid(pageData.lookStoreForm).then((res) => {
			if (res.code == 0) {
				pageData.lookStoreList = res.data;
				pageData.lookStoreCount = res.count;
				pageData.lookStoreShow = true;
			}
		})
	}
	onMounted(() => {
		getBuyCardsList();
	})
</script>

<style scoped>
.badge {
	background: #333;
}
.text-color{
	color: #018000;
}
.card-top{
	background: rgba(64, 158, 255, 0.06);
	padding: 10px 17px 11px 17px;
	margin-bottom: 17px;
}
.arrowdown{
	transform:rotate(90deg);
	-ms-transform:rotate(90deg);     /* IE 9 */
	-moz-transform:rotate(90deg);    /* Firefox */
	-webkit-transform:rotate(90deg); /* Safari 和 Chrome */
	-o-transform:rotate(90deg);  /* Opera */
	width:13px;
	vertical-align: middle;
 	margin-left:2px;
}
</style>
