import {postAjax,getAjax}  from "../common/axios.js"

//组织架构列表
export const getStoreFramework = params => {
	return postAjax('/api/run/system/getStoreFramework',params);
}
//岗位列表
export const roleManage = params => {
	return postAjax('/api/run/system/roleManage',params);
}
//岗位下的员工列表
export const roleUserList = params => {
	return postAjax('/api/run/system/roleUserList',params);
}
//新增岗位
export const userRoleInsert = params => {
	return postAjax('/api/run/system/userRoleInsert',params);
}
//编辑岗位
export const userRoleUpdate = params => {
	return postAjax('/api/run/system/userRoleUpdate',params);
}

//删除岗位
export const userRoleDelete = params => {
	return postAjax('/api/run/system/userRoleDelete',params);
}
//获取岗位权限
export const menuToRole = params => {
	return postAjax('/api/system/menuToRole',params);
}
//编辑岗位权限
export const userRoleMenuInsert = params => {
	return postAjax('/api/system/userRoleMenuInsert',params);
}

//复制岗位组织架构
export const roleCopyList = params => {
	return postAjax('/api/run/system/roleCopyList',params);
}
//复制岗位保存
export const roleCopySave = params => {
	return postAjax('/api/run/system/roleCopySave',params);
}

//门店-岗位列表
export const roleManageStore = params => {
	return postAjax('/api/run/system/roleManageStore',params);
}

//门店-添加岗位
export const userRoleInsertStore = params => {
	return postAjax('/api/run/system/userRoleInsertStore',params);
}



