<template>
	<div class="rx-bc pb10" :style="{'border-bottom': themeStore.bgColor == 2 ? '1px solid #4D4D4D' : '1px solid #E0E0E0'}" >
		<h3 class="ft12" :class="{'color-fff':themeStore.bgColor == 2}">更多服务</h3>
		<el-icon class="icon" :class="{'color-fff':themeStore.bgColor == 2}"><ArrowRight /></el-icon>
	</div>
	<ul class="move_list mt10">
		<template v-for="(item,index) in storeData.modules" :key="index">
			<li v-if="item.checked" class="t_a_c ww25">
				<img class="ww50" :src="serviceIcon[storeData.showType-1]['icon'+storeData.showType+''+(index+1)]" alt="">
				<p class="color-333 ftb ft12" :class="{'color-fff':themeStore.bgColor == 2}">{{item.name}}</p>
			</li>
		</template>
	</ul>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	import {ArrowRight} from '@element-plus/icons-vue'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const serviceIcon = store.state.serviceIcon;
	const themeStore = store.state.appletFormData[4].list[0];
</script>

<style scoped lang="stylus">
	.move_list{
		overflow hidden;
		li{
			float left;
			padding 10px 0;
		}
	}
	.icon{
		color #999;
	}
</style>
