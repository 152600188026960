

<template>
	<router-view></router-view>
</template>

<style lang="stylus">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.try_select_box .el-select .el-input__inner{
		padding-left:30px;
	}
	.home_container .el-header{
		padding:0;
		height:50px;
	}
	router-link{
		color : initial;
	}
	.el-table__body tr.current-row>td.el-table__cell,.el-table--striped .el-table__body tr.el-table__row--striped.current-row td.el-table__cell{
		background-color : #409EFF !important;
		color white !important;
	}
	.el-table__body tr.current-row>td.el-table__cell a,.el-table--striped .el-table__body tr.el-table__row--striped.current-row td.el-table__cell a{
		color white !important;
	}
	.el-drawer__header{
		background-color: #EDF6FF;
		margin-bottom: 0px;
		padding :10px 20px;
		border-bottom: 1px solid #eee;
	}
	.el-drawer__body{
		height: 100%
	}
	
	.drawer_btn{
		position absolute
		left 0;
		right 0;
		bottom 0px;
		background-color white;
		border-top 1px solid #eee;
		padding 10px 20px;
		text-align right;
		z-index 100;
	}
	.w-e-toolbar .w-e-menu{
		width: 25px;
		height: 25px;
	}
</style>
