<template>
    <workArea :barlist="barlist">
        <div class="p20 pt10">
            <formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="searchGetList"></formQuery>
            <el-table :data="pageData.tableData" stripe v-loading="pageData.loading">
                <el-table-column prop="date" fixed label="会员姓名" min-width="120">
                    <template #default="scope">
                        <span @click="toMemberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="telephoneNum" fixed label="手机号" min-width="120" />
                <el-table-column prop="adviserNames" label="跟进人"  min-width="100">
                    <template #default="scope">
                        {{ scope.row.adviserNames ? scope.row.adviserNames : '-' }}
                    </template>
                </el-table-column>
                <el-table-column label="会员类型" min-width="100">
                    <template #default="scope">
                        <span class="tag_blue tag" v-if="scope.row.trialFlag == 1">正式会员</span>
                        <span class="tag_red tag" v-else>潜在会员</span>
                    </template>
                </el-table-column>
				<el-table-column label="入场类型" min-width="120" show-overflow-tooltip>
					<template #default="scope">
				        {{ scope.row.enterType || '预约场地' }}
				    </template>
				</el-table-column>
                <el-table-column label="入场凭证" min-width="120" show-overflow-tooltip>
					<template #default="scope">
						<span v-if="!scope.row.enterConsume && !scope.row.roomOrder">--</span>
				        {{ scope.row.enterConsume || scope.row.roomOrder  }}
				    </template>
				</el-table-column>
                <el-table-column label="入场时间" min-width="220">
                    <template #default="scope">
                        <span>{{ scope.row.enterSiteTime + (scope.row.enterPeopleNum ? '（'+scope.row.enterPeopleNum+'人）' :'')}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="入场方式" min-width="120">
                    <template #default="scope">
                        {{ scope.row.enterSiteType == 1 ? '前台手动入场' :  '' }}
                    </template>
                </el-table-column>
                <el-table-column label="离场时间" min-width="160">
                    <template #default="scope">
                        {{ scope.row.state ==1 ? scope.row.outSiteTime : '--' }}
                    </template>
                </el-table-column>
                <el-table-column prop="outSiteType" label="离场方式" min-width="120">
                    <template #default="scope">
                        {{ scope.row.state && scope.row.outSiteType == 1 ? '前台手动离场' :  '--' }}
                    </template>
                </el-table-column>
                <el-table-column label="在场时间"  min-width="100">
                    <template #default="scope">
                        {{ scope.row.state && scope.row.time ? scope.row.time :  '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="overtime" label="是否超时" min-width="100">
                    <template #default="scope">
                        <span v-if="scope.row.state">{{ scope.row.overtime ? '是' :  '否' }}</span>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec" v-show="pageData.tableData.length > 0">
		        <el-pagination
		            v-model:currentPage="pageData.searchForm.page"
		            :page-sizes="[15,20,30,50,100]"
		            :page-size="pageData.searchForm.limit"
		            layout="total, sizes, prev, pager, next, jumper"
		            :total="pageData.totalCount"
		            @size-change="pageSizeChange"
		            @current-change="()=>{getList()}"
		        />
		    </div>
        </div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import workArea from '@/components/branch-store/workArea.vue';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import utils from '@/common/utils.js';
    import { list } from '@/api/reception.js';
    
    const barlist = reactive([{ name : '返回', type:0 },{ name : '入离场记录' }]); // 面包屑
    const router = useRouter();
	const store = useStore();
    const queryOptsRef = ref()
    const pageData = reactive({
        loading: false,
        tableData: [],
        searchForm: {
            page: 1,
            limit: 15
        },
        totalCount: 0,
        queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'trialFlag',
				placehold : '全部会员类型',
				options : [{label : '全部会员类型' ,value : ''},{label : '正式会员' ,value : '1'},{label : '潜在会员' ,value : '2'}],
				value:''
			},{
				type : 'select',
				fieldName : 'enterType',
				placehold : '全部入场类型',
				options : [{label : '全部入场类型' ,value : ''},{label : '预约课程' ,value : '1'},{label : '入场卡' ,value : '2'},{label : '预约到访' ,value : '3'},{label : '临时到访' ,value : '4'}],
				value:''
			},{
                type : 'select',
                fieldName : 'timeIn',
                placehold : '时间范围',
                value : '5',
                options : utils.getDict('timeIn')
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'enterSiteTimeStr',
                placeholder : '日期范围'
            },{
				type : 'select',
				fieldName : 'overtime',
				placehold : '是否超时',
				options : [{label : '全部' ,value : '-1'},{label : '否' ,value : '0'},{label : '是' ,value : '1'}],
				value:'-1'
			}]
		}
    })
    //切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取入离场列表数据
     */
    function getList(){
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        if (params.timeIn == '-1' && !params.enterSiteTimeStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        params.enterSiteTimeStr = params.timeIn == '-1' && params.enterSiteTimeStr.join('-')
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        pageData.loading = true
        list(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 跳转会员详情
     */
    function toMemberDetail(id){
        router.push('/branch/memberDetail?encryptionId='+id);
    }
    // 页面缓存
     onBeforeRouteLeave((to, from)=>{
         if (to.path === '/branch/memberDetail') {
             store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
         } else {
             store.commit('SetKeep',{keepLiveRoute : []});
         }
     })
     onMounted(()=>{
        // 查询会员卡项列表
        getList();
    });
</script>
<style type='css/style' scoped>
.tag {
        padding: 1px 4px;
    }
</style>