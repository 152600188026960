<template>
	<div class="order_class mt16">
		<div class="mb12">
			<ul class="rx-bc course_type_tab">
				<li class="t_a_c pt30 pb30 flex1" :class="{active:pageData.courseTypeTabNum == item.id}" v-for="item in pageData.courseTypeTab" :key="item.id" @click="courseTypeTabChange(item.id)">
					<h3 class="color-000 mb10">{{item.title}}</h3>
					<p class="color-999 ft12">今日 <b class="color-red">{{item.value}}</b> 人预约</p>
				</li>
			</ul>
		</div>
		<div class="rx-bc">
			<formQuery ref="queryOptsRef" :queryOpts="pageData.queryOpts" @query="searchTable"></formQuery>
			<div class="mb12 rx-ec mt16">
				<el-button @click="downloadImg">下载签到码</el-button>
				<el-button @click="getList(true)" :icon="Upload" >导出</el-button>
			</div>
		</div>
		<el-table :data="pageData.tableData" v-loading="pageData.loading" stripe style="width: 100%">
			<el-table-column prop="courseName" label="课程名称" width="200">
				<template #default="scope">
					<span>{{ scope.row.courseName }}</span>
					<span class="pl5 pr5 ml5" v-if="scope.row.ifMakeUpCourse == 1" :style="{ color: '#FFF',backgroundColor:'#F96667',border:'1px solid #F96667' }">补课</span>
				</template>
			</el-table-column>
			<el-table-column fixed prop="cycleName" label="所属班级" width="150" show-overflow-tooltip v-if="pageData.courseTypeTabNum == 13" />
			<el-table-column fixed label="课程类型" width="100">
				<template #default="scope">
					<span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30',border:'1px solid '+courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="classTimeStr" label="上课时间" width="140">
				<template #default="scope">
					<div>{{ scope.row.classTimeStr.substring(0, 10) ? scope.row.classTimeStr.substring(0, 10) : ''  }}</div>
					<div class="mt5">{{ scope.row.classTimeStr.substring(11, 16) ? scope.row.classTimeStr.substring(11, 16) : '' }}</div>
				</template>
			</el-table-column>
			<el-table-column prop="teacherName" label="上课教练" show-overflow-tooltip>
				<template #default="scope" >
					{{ scope.row.teacherName ? scope.row.teacherName : '-' }}
				</template>
			</el-table-column> 
			<el-table-column label="会员" width="110" show-overflow-tooltip>
				<template #default="scope" >
					<div @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p name tool_btn">{{scope.row.realName}}</div>
					<div>{{scope.row.telephoneNum}}</div>
				</template>
			</el-table-column>
			<el-table-column prop="orderPeopleNum" label="预约人数" />
			<el-table-column  label="签到状态">
				<template #default="scope">
					<span class="d_i_b bdr2 pl5 pr5 color-fff" :style="{backgroundColor:scope.row.alreadyCome == 1 ? '#1E9FFF' : '#009688'}">{{ scope.row.alreadyCome == 1 ? '已签到' : '未签到' }}</span>
				</template>
			</el-table-column>
			<el-table-column label="预约状态" width="210">
				<template #default="scope">
					<div v-if="scope.row.state == 4">
						<span class="d_i_b w10 h10 bdr8 badge1 mr5">
							<span class="d_i_b w5 h5 v_a_m bdr4 badge"></span>
						</span>
						<span>预约取消</span>
						<span class="ft10 ml5 color-999">{{ scope.row.updateBy == 0 ? '会员自主取消' : '教练代取消' }}</span>
					</div>
					<div v-else-if="scope.row.state == 5">
						<span class="d_i_b w5 h5 v_a_m bdr4 badge mr5"></span>
						<span>已请假</span>
					</div>
					<div v-else-if="scope.row.state == 6">
						<span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5"></span>
						<span>已补课</span>
					</div>
					<div v-else>
						<div>
							<span class="d_i_b w5 h5 v_a_m bdr4 mr5" :class="scope.row.state == 1 ? 'badge-success' : scope.row.state == 3 ? 'badge-info' : 'badge-warning'"></span>
							<span>{{ filtersType(scope.row.state) }}<span v-if="scope.row.state == 3" class="ft10 ml5 color-999">候补失败</span></span>
						</div>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="createTime" label="预约时间" min-width="160" />
			<el-table-column label="预约人"  width="210">
				<template #default="scope">
					<div v-if="scope.row.buyPayType == 2">{{scope.row.realName}} <span class="ft10 ml5 color-999">小程序单次预约</span></div>
					<div v-else>
						<div v-if="scope.row.orderUserName">{{ scope.row.orderUserName }} <span class="ft10 ml5 color-999">后台代预约</span></div>
						<div v-else>{{scope.row.realName}} <span class="ft10 ml5 color-999">小程序自主预约</span></div>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="签到时间" min-width="160">
				<template #default="scope">
					<div>{{ scope.row.signTime ? scope.row.signTime : '-' }}</div>
				</template>
			</el-table-column>
			<el-table-column label="扣费支付方式" min-width="110">
				<template #default="scope">
					<div>{{ scope.row.productName ? scope.row.productName : '-' }}</div>
				</template>
			</el-table-column>
			<el-table-column label="本次扣除" min-width="100">
				<template #default="scope">
					<div v-if="scope.row.courseType == 13">
						<span v-if="scope.row.ifConsume == 1">{{ scope.row.payMethodId == 100 || scope.row.payMethodId == 101 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次' }}</span>
                        <span v-else>未消课</span>
					</div>
					<div v-else>
						<div v-if="scope.row.state == 1">{{ scope.row.cardType  == 3 ? scope.row.consumeMoney + '元' : scope.row.consumeNum + '次' }}</div> 
						<div v-if="scope.row.state == 0 || scope.row.state == 4"> - </div>
					</div>
					
				</template>
			</el-table-column>
			<el-table-column label="消费金额(元)" min-width="110">
				<template #default="scope">
					<div>{{ scope.row.consumeMoney ? scope.row.consumeMoney : '-' }}</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="160">
				<template #default="scope">
					<div v-if="scope.row.courseType == 21">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if=" scope.row.state == 4" @click="loadEvent(scope.row)">查看退款详情</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if=" scope.row.state == 1 && utils.getMenuRole(3180003)" @click="confirmEvent(scope.row, 'cancelOrder')">取消预约</a>
					</div>
					<div v-else>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.alreadyCome && utils.getMenuRole(3180000)" @click="printTicketEvent(scope.row)">打印小票</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 2 && utils.getMenuRole(3180004)" @click="confirmEvent(scope.row.encryptionCourseInfoId,'enterClass')">入课</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 2 && utils.getMenuRole(3180005) && scope.row.courseType !=13" @click="confirmEvent(scope.row,'cancelAlternate')">取消候补</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && scope.row.state == 1 && utils.getMenuRole(3180002)" @click="confirmEvent(scope.row.encryptionCourseInfoId,'signIn')">签到</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="!scope.row.alreadyCome && scope.row.state == 1 && utils.getMenuRole(3180003)" @click="confirmEvent(scope.row, 'cancelOrder')">取消预约</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="(scope.row.refundState == 1 || scope.row.refundState == 3) && utils.getMenuRole(3180001)" @click="loadEvent(scope.row)">查看退款详情</a>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="mt20 rx-ec">
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
		<el-dialog
		    v-model="pageData.dialogVisible"
		    title="查看退款详情"
		    width="370px"
		  >
		    <div class="p20 pt30 pb30">
				<div class="timeline_left">
					<img class="w15 h15 mr15 v_a_m" src="@/assets/order1.png" alt="">
					<span class="color-666" v-if="pageData.activities.state == 4">会员已取消预约，已发起自动退款</span>
					<span class="color-666" v-else>"会员候补失败，已发起自动退款</span>
				</div>
				<div class="h25" style="line-height: 25px">
					<el-divider direction="vertical" border-style="dashed" />
				</div>
				<div class="timeline_right">
					<div v-if="pageData.activities.refundState == 1">
						<img class="w15 h15 mr15 v_a_m" src="@/assets/order2.png" alt="">
						<span class="color-666">退款成功，费用已退回至原支付账户</span>
					</div>
					<div v-if="pageData.activities.refundState == 3">
						<img class="w15 h15 mr15 v_a_t" style="margin-top: 2px;" src="@/assets/order3.png" alt="">
						<div class="color-666 d_i_b w300" v-if="pageData.activities.buyPayType == 0">退款失败，会员预约付费所使用的资产已失效，请联系会员线下处理</div>
						<div class="color-666 d_i_b w300 " v-else>退款失败，门店商户账户可用余额不足，请到订单管理中找到该笔订单进行手动退款</div>
					</div>
				</div>
			</div>
		    <template #footer>
		    	<span class="dialog-footer">
		    	  <el-button type="primary" @click="pageData.dialogVisible = false">好 的</el-button>
		    	</span>
		    </template>
		</el-dialog>
		<el-dialog
		    v-model="pageData.dialogSign"
		    title="门店签课码"
		    width="280px"
		  >
		    <div class="signCode t_a_c" id="downBox">
				<div class="color-666 mb16">仅用于{{ userInfo.storeName }}门店扫码签课</div>
				<div class="signCode_code" v-loading="pageData.codeLoading">
					<img :src="pageData.wxCode" alt="">
				</div>
			</div>
		    <template #footer>
		    	<span class="dialog-footer">
		    	  <el-button type="primary" @click="downTable">下载签课码</el-button>
		    	</span>
		    </template>
		</el-dialog>
		<PrintTicket v-if="pageData.printShow" v-model="pageData.printShow" type="0" :id="pageData.courseInfo" @save="printSave"></PrintTicket>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import { ref,reactive,onMounted, onBeforeUnmount } from 'vue';
	import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
	import { ElMessage } from 'element-plus'
	import { Upload } from '@element-plus/icons-vue'
	import PrintTicket from '@/components/branch-store/PrintTicket.vue';
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import utils from '@/common/utils.js';
	import { getLocalStorage } from '@/common/common.js';
	import { orderCourse, listSimple, orderCourseSummary } from '@/api/consume.js'
	import { courseInfoSign, courseAfterBatchTurnClass, storeBookCancel, storeBookAfterCancel } from '@/api/course.js';
	import { storeOrderRoomCancel } from '@/api/appstore.js';
	import axios from 'axios'
	import html2canvas from 'html2canvas'
	const userInfo = ref(getLocalStorage('businessUserData'));
	const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "20" , label : '本月' },
		{ value : "30" , label : '本周' },
        { value : "-1" , label : '其他' }
    ]
	const stateList = [
		{label : '全部预约状态' ,value : ''},
		{label : '预约成功' ,value : '1'},
		{label : '预约失败' ,value : '3'},
		{label : '候补中' ,value : '2'},
		{label : '预约取消' ,value : '4'}
	]

	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const queryOptsRef = ref();
	const pageData = reactive({
		wxCode: '',
		dialogVisible:false,
		dialogSign: false,
		activities: {},
		loading: false,
		codeLoading: false,
		courseTypeTabNum: 0,
		courseTypeTab:[
			{
				id: '',
				title:'全部课程类型',
				value:0
			},
			{
				id:14,
				title:'私教1v1',
				value:0
			},
			{
				id:15,
				title:'私教1v多',
				value:0
			},
			{
				id:11,
				title:'团体课',
				value:0
			},
			{
				id:13,
				title:'小班课',
				value:0
			},
		],
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15
		},
		printShow:false, //打印小票
		courseInfo:{},
		confirmTitle:'',
		confirmText:'',
		queryOpts : {
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '会员姓名/手机号'
			},{
				type : 'selectMany',
    	        fieldName : 'courseNameIds',
    	        placehold : '全部课程',
    	        options : [],
    	        value:''
			},{
				type : 'select',
				fieldName : 'state',
				placehold : '全部预约状态',
				options : stateList,
				value:''
			},{
				type : 'select',
				fieldName : 'alreadyCome',
				placehold : '全部签到状态',
				options : [{label : '全部签到状态' ,value : ''},{label : '已签到' ,value : '1'},{label : '未签到' ,value : '0'}],
				value:''
			},{
    	    	type : 'select',
    	    	fieldName : 'timeIn',
    	    	placehold : '退款时间',
    	    	value : '5',
    	    	options : timerList
    		},{
    		    showField : 'timeIn', // 根据显示字段
    		    showValue : '-1', // 根据显示字段值
    		    type : 'dateRange',
    		    fieldName : 'createTimeStr',
    		    placeholder : '日期范围'
    		}]
		}
	}) 
	let confirmType = '' //通用确认调用类型
	//课程类型
	const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		21:{
			text:'场地预约',
			color:'#9476FF'
		},
	}
	/**
     * @param (String) value-类型 
     * 表格字段过滤过滤展示
     */
    function filtersType(value) {
        let text = ''
        stateList.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
	/**
	 * 下载签到码
	 */
	function downTable(){
      html2canvas(document.getElementById('downBox')).then((canvas)=>{
        const imgUrl = canvas.toDataURL('png');
        const aDom = document.createElement('a');
        aDom.href = imgUrl;
        aDom.download = '门店签课码';
        document.body.appendChild(aDom);
        aDom.click();
        aDom.remove();
		pageData.dialogSign = false
      })
    }
	/**
	 * 获取全部课程列表
	 */
	function getClassList() {
		pageData.queryOpts.fields[1].options = []
		let params = {
			courseType: pageData.courseTypeTabNum ? pageData.courseTypeTabNum : '',
			page: 1,
			limit: 9999
		}
		listSimple(params).then((res) => {
			if (res.code == 0) {
				res.data.forEach((item) => {
					let obj = {
						label: item.courseName,
						value: item.courseNameId +''
					}
					pageData.queryOpts.fields[1].options.push(obj)
				})
			}
		})
	}
	//切换课程类型
	function courseTypeTabChange(type){
		pageData.courseTypeTabNum = type
		localStorage.setItem('orderClass', type)
		getList()
		getClassList()
	}
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function getList(isExport){
		let params = {}
        Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
		params.courseType = pageData.courseTypeTabNum ? pageData.courseTypeTabNum : ''
		params.courseNameIds = params.courseNameIds && params.courseNameIds.length > 0 ? params.courseNameIds.join(',') : '';
        if (params.timeIn == '-1' && !params.createTimeStr) {
            ElMessage.warning('请选择自定义时间')
            return false
        }
        if (params.timeIn == '-1' && params.createTimeStr) {
            params.createTimeStr = params.createTimeStr.join('-')
        }
        
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		} else {
			pageData.loading = true
			pageData.tableData = []
		}
		orderCourse(params).then((res)=>{
			pageData.loading = false
			if (res.code == 0) {
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data;
					pageData.totalCount = res.count;
				}
			}
		}).catch(() => {
			pageData.loading = false
		})
	}
	/**
	 * 获取顶部统计
	 */
    function getInfo() {
		let params = {
            timeIn: 1
        }
	    pageData.courseTypeTab.forEach((item) => {
		    params.courseType = item.id
            orderCourseSummary(params).then((res) => {
                if (res.code == 0) {
                    item.value = res.data.orderPeopleNumAll
                }
            })
	    })
		
	}
	/**
	 * 下载二维码
	 */
	function downloadImg() {
		wxCode()
		pageData.dialogSign = true
		pageData.codeLoading = true
	}
	/**
	 * 获取小程序码
	 */
	function wxCode () {
		let params = {
			path: '/pages-sub-sign/sign-change/sign-change?sid=' + userInfo.value.sid,
			appId: userInfo.value.appIdMini,
			width: '137'
		}
		axios({
    	  method: 'get',
    	  url: '/api/open/getQrcodeMiniProd',
    	  params: params,
		  headers:{'access_token': getLocalStorage('businessToken')},
		  responseType: "blob",
    	}).then((res) => {
    	  	let blob = new Blob([res.data], { type: "image/png" });
        	var Fr = new FileReader();
        	Fr.readAsDataURL(blob);
        	Fr.onload = (event) => {
				pageData.codeLoading = false
				pageData.wxCode = event.target.result
        	};
    	})
		
	}
	
	/**
     * @param(Object) row-预约详情数据
     * 打印小票
     */
	function printTicketEvent(row){
		pageData.courseInfo = row.encryptionId;
		pageData.printShow = true;
	}
	function printSave(){
		pageData.printShow = false;
	}
	/**
	 * 查看退款详情
	 */
	function loadEvent(row) {
		pageData.activities = row
		pageData.dialogVisible = true
	}
	/** 提示弹框 */
    let rowId = '';
	function confirmEvent(id,type){
		rowId = id;
		if(type == 'enterClass'){ //入课
			pageData.confirmTitle = '确定入课吗？';
			pageData.confirmText = '会员入课即预约成功，入课后该课节人数将超过<span class="color-red"> 1 </span>人！';
		}else if(type == 'cancelAlternate'){  //取消候补
			pageData.confirmTitle = '确定取消候补吗？';
			pageData.confirmText = '会员候补时已扣费，取消候补后费用将原路退回！ ';
		}else if(type == 'signIn'){ //签到
			pageData.confirmTitle = '是否确认签到？';
            pageData.confirmText = ''
		}else if(type == 'cancelOrder'){ //取消预约
			pageData.confirmTitle = '确定取消预约吗？';
			pageData.confirmText = '会员预约成功时已扣费，取消预约后将归还会员资产！';
		}
		confirmType = type;
		confirmRef.value.show();
		
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'enterClass'){
			courseAfterBatchTurnClass({
				ids:rowId
			}).then((res)=>{
				if(res.code == 0){
					ElMessage.success('入课成功')
					getList()
				}
			})
		}else if(confirmType == 'cancelAlternate'){
			let params = {
        	    encryptionId: rowId.encryptionCourseId,
        	    encryptionCardMyId: rowId.encryptionCardMyId,
        	    type: 4
        	}
			storeBookAfterCancel(params).then((res) => {
				if(res.code == 0){
					ElMessage.success('取消成功')
					getList()
				}
			})
		}else  if(confirmType == 'signIn'){
			let params = {
        	    ids: rowId,
        	    alreadyCome: 1,
        	    signType: 6
        	}
        	courseInfoSign(params).then((res) => {
        	    if (res.code == 0) {
					ElMessage.success('签到成功')
        	        getList()
        	    }
        	})
		}else if(confirmType == 'cancelOrder'){
			if (rowId.courseType == 21) {
				let params = {
        		    encryptionId: rowId.encryptionCourseId
        		}
        		storeOrderRoomCancel(params).then(res => {
        		    if (res.code == 0) {
        		        ElMessage({
							type: 'success',
							message: '取消成功'
						})
        		        getList()
        		    }
        		})
			} else {
				let params = {
        		    encryptionId: rowId.encryptionCourseId,
        		    encryptionCardMyId: rowId.encryptionCardMyId,
        		    type: 3
        		}
				storeBookCancel(params).then((res) => {
					if(res.code == 0){
						ElMessage.success('取消成功')
						getList()
					}
				})
			}
		}
	}
	//会员详情
	function memberDetail(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}

	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	onMounted(() => {
		if (localStorage.getItem('orderClass') != null) {
          pageData.courseTypeTabNum = localStorage.getItem('orderClass')
	    }
		getList()
		getClassList()
		getInfo()
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('orderClass')
	})
</script>

<style scoped lang="stylus">
	.name{
		overflow:hidden;
	    white-space: nowrap;
	    text-overflow: ellipsis;
	    -o-text-overflow:ellipsis;
		width: 100px
	}
	.order_class{
		.course_type_tab{
			li{
				background-color #F7F6FB;
			}
			li:not(:first-child){
				 margin-left 20px;
			}
			li.active{
				background-color #EBF5FF;
				position relative
			}
			li.active::after{
				content '';
				position absolute;
				bottom 0;
				left 0;
				right 0;
				border-bottom 4px solid #00A3FF;
			}
			li.active::before{
				content '';
				width 0;
				height 0;
				border 5px solid transparent;
				border-top-color #00A3FF;
				position absolute;
				bottom -10px;
				left 50%;
				transform translateX(-50%);
			}
		}
	}
	.badge {
		background: #FA6400;
		box-shadow: 0 0 5px rgba(250, 100, 0, 1);
	}
	.badge-success {
		background: #44D7B6;
		box-shadow: 0 0 5px rgba(68, 215, 182, 1);
	}
	.badge-info {
		background: #ccc;
		box-shadow: 0 0 5px rgba(204, 204, 204, 1);
	}
	.badge-warning {
		background: #F7B500;
		box-shadow: 0 0 5px rgba(247, 181, 0, 1);
	}
	.signCode {
		padding: 20px 58px 40px 58px;
	}
	.signCode_code {
		width: 137px;
		height: 146px;
		margin: 0 auto;
	}
	.signCode_code img {
		width: 137px;
		height: 146px;
	}
	.el-divider--vertical {
        border-color: #ccc
    }
	</style>
