<template>
	<div class="member_card">
		<div class="rx-bc mb10">
			<div class="color-333 fwb member_title" :style="{'border-left': '4px solid ' + themeBgColor.list[themeBgColor.active].textColor}" :class="{'color-fff':themeBgColor.bgColor == 2}">入场卡</div>
			<div class="ft12" :class="{'color-fff':themeBgColor.bgColor == 2}">全部 ></div>
		</div>
		<ul>
			<li v-for="item in storeData.list" :key="item.id" :class="storeData.showType == 1 ? 'ww50' :'ww100'">
				<div :style="{backgroundColor:themeBgColor.bgColor == 2 ? 'rgba(255,255,255,.1)' : ''}">
					<img class="ww100 h75" :src="item.productImg" alt="">
					<div class="pt10 pb10 pl5 pr5 bg" :style="{backgroundColor:themeBgColor.bgColor == 2 ? 'rgba(255,255,255,.1)' : ''}">
						<h3 class="maxLength" :class="{'color-fff':themeBgColor.bgColor == 2}">{{item.productName}}</h3>
						<div :class="storeData.showType == 2 ? 'rx-bc' :''">
							<p class="ft12 mt5 mb5" :class="{'color-fff':themeBgColor.bgColor == 2}">含{{item.productTyp == 1 ? item.productNum+'次' : (item.ifMonth == 1 ? '天' :item.ifMonth == 2 ? '月' : '年')}}</p>
							<p class="money"><span class="ft12">￥</span>{{item.productPrice}}</p>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const themeBgColor = store.state.appletFormData[4].list[0];
</script>

<style scoped lang="stylus">
	.member_card{
		.member_title{
			padding-left 5px;
		}
		ul{
			overflow hidden;
			li{
				float left;
				div.bg{
					background-color #FAFAFC;
				}
				.money{
					color #F74F4F;
				}
			}
			li.ww100{
				img{
					float left;
					width 80px;
					margin-right 10px;
					margin-top 5px;
					margin-left 5px;
				}
			}
			li:nth-child(2n-1){
				padding-right 5px;
			}
			li:nth-child(2n){
				padding-left 5px;
			}
		}
	}
	.maxLength {
		height: 20px;
    	line-height: 20px;
	}
</style>
