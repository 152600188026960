<template>
    <div class="p20 pt0">
        <formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList"></formQuery>
        <div class="f_r mb15">
            <a href="javascript:;" class="color-409EFF mb10 t_d_u" @click="undoData">查看撤销数据>></a>
            <el-button class="ml20" v-if="utils.getMenuRole(3190000)" @click="getList(true)"   :icon="Upload" >导出</el-button>
        </div>
        
        <el-table v-loading="pageData.loading" :data="pageData.tableData" stripe style="width: 100%">
			<el-table-column label="会员名称" width="230" fixed show-overflow-tooltip>
                <template #default="scope">
					<span @click="memberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    <span class="ml5">{{ scope.row.telephoneNum }}</span>
                    <span class="pl5 pr5 ml5" v-if="scope.row.ifMakeUpCourse == 1" :style="{ color: '#FFF',backgroundColor:'#F96667',border:'1px solid #F96667' }">补课</span>
				</template>
            </el-table-column>
            <el-table-column label="会员类型" width="100">
                <template #default="scope">
					<span class="tag_blue tag" v-if="scope.row.trialFlag == 1">正式会员</span>
                    <span class="tag_red tag" v-else>潜在会员</span>
				</template>
            </el-table-column>
            <el-table-column prop="courseName" label="课程类型" width="100">
                <template #default="scope">
					<div v-if="scope.row.courseType">
						<span class="d_i_b bdr2 pl5 pr5" :style="{color: courseType[scope.row.courseType].color,backgroundColor:courseType[scope.row.courseType].color+'30', border:'1px solid '+ courseType[scope.row.courseType].color}">{{courseType[scope.row.courseType].text }}</span>
				   	</div>
				</template>
            </el-table-column>
            <el-table-column prop="courseName" label="上课课程" width="100" show-overflow-tooltip/>
            <el-table-column prop="classTimeStr" label="上课时间" width="230" />
            <el-table-column prop="cycleName" label="上课班级" width="100" show-overflow-tooltip/>
            <el-table-column prop="teacherName" label="上课教练" width="100" show-overflow-tooltip/>
            <el-table-column prop="productName" label="扣费支付方式" width="110" show-overflow-tooltip/>
            <el-table-column label="卡类种类" width="100">
                <template #default="scope">
					{{ filtersType(scope.row.cardType, 1) }}
				</template>
            </el-table-column>
            <el-table-column prop="consumeNum" label="本次扣除" width="100" >
				<template #default="scope">
					<span>{{scope.row.cardType == 3 ? scope.row.storeMoneyAll + '元' : scope.row.consumeNum + '次'}}</span>
				</template>
			</el-table-column>
            <el-table-column prop="consumeMoney" label="消费金额(元)" width="110" />
            <el-table-column prop="createTime" label="消费时间" width="160" />
            <el-table-column label="签到状态" width="100">
                <template #default="scope">
                    <div v-if="scope.row.ifLeave" class="courseType t_a_c box-F56C6C">
                        已请假
                    </div>
                    <div class="courseType t_a_c" v-else :class="{'box-009688':!scope.row.alreadyCome,'box-1E9FFF':scope.row.alreadyCome}">
                        {{scope.row.alreadyCome ? '已签到' :'未签到'}}
                    </div>
				</template>
            </el-table-column>
            <el-table-column label="签到方式" width="100" show-overflow-tooltip>
                <template #default="scope">
					{{ filtersType(scope.row.signType, 2) }}
				</template>
            </el-table-column>
            <el-table-column prop="signTime" label="签到时间" width="160" />
            <el-table-column prop="signUserName" label="操作人" width="100" show-overflow-tooltip />
            <el-table-column label="操作" fixed="right" width="100">
                <template #default="scope">
                    <a v-if="scope.row.alreadyCome && utils.getMenuRole(3180102)" href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="printTicketEvent(scope.row)">打印小票</a>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="暂无数据"></el-empty>
            </template>
        </el-table>
        <div class="mt20 rx-bc">
            <div>
                <span class="mr20">消费次数总计：<span class="color-red mr5">{{ pageData.remark.consumeNumAll || 0 }}</span>次</span>
                <span>消费金额总计：<span class="color-red mr5">{{ pageData.remark.consumeMoneyAll || 0 }}</span>元</span>
            </div>
		    <el-pagination
		      v-model:currentPage="pageData.searchForm.page"
		      :page-sizes="[15,20,30,50,100]"
		      :page-size="pageData.searchForm.limit"
		      layout="total, sizes, prev, pager, next, jumper"
		      :total="pageData.totalCount"
		      @size-change="pageSizeChange"
		      @current-change="()=>{getList()}"
		    >
		    </el-pagination>
		</div>
    </div>
    <PrintTicket v-model="pageData.printShow" type="1" :data="pageData.courseInfo" @save="printSave"></PrintTicket>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
	import { useStore } from 'vuex'
    import { Upload } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import formQuery from '@/components/branch-store/formQuery.vue';
    import PrintTicket from '@/components/branch-store/PrintTicket.vue';
    import { orderConsume, listSimple, listConsumeProductName } from '@/api/consume.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import { ElMessage } from 'element-plus'
    const propData = defineProps({
        operator: Array
    })
    const emit = defineEmits(['undoData'])
    const multipleTable= ref()
    const queryOptsRef = ref()
    const store = useStore();
	const router = useRouter();
    const pageData = reactive({
        loading: false,
        tableData: [],
        searchForm: {
            page: 1,
            limit: 15
        },
        totalCount: 0,
        printShow: false, //打印小票弹框
        courseInfo: {},
        remark: {}
    })
    const queryOpts = reactive({});
    const cardTypeList = [
        { value : '1' , label : '课程卡' },
        { value : '2' , label : '入场卡' },
        { value : '3' , label : '储值卡' },
        { value : '4' , label : '体验卡' },
        { value : '5' , label : '课程' }
    ]
    const signTypeLsit = [
        {label : '全部签到方式' ,value : ''},
        { value : '2' , label : '会员自主扫码' },
        { value : '4' , label : '门店扫码签到' },
        { value : '6' , label : '后台课表签到' },
        { value : '0' , label : '系统自动签到' },
        { value : '9' , label : '小程序签到' },
    ]
    const timerList = [
        { value : "5" , label : '最近7天' },
        { value : "30" , label : '本周' },
        { value : "20" , label : '本月' },
        { value : "21" , label : '上月' },
        { value : "-1" , label : '其他' }
    ]
    const courseType = {
		11:{
			text:'团体课',
			color:'#FA7D29'
		},
		12:{
			text:'私教课',
			color:'#3FC9FF'
		},
		14:{
			text:'私教1v1',
			color:'#3FC9FF'
		},
		15:{
			text:'私教1v多',
			color:'#3FC9FF'
		},
		13:{
			text:'小班课',
			color:'#9476FF'
		},
	}
    queryOpts['fields'] = [{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'selectMany',
				fieldName : 'operatorIds',
				placehold : '全部操作人',
                options : propData.operator,
                value:''
			},{
				type : 'selectMany',
				fieldName : 'courseTypes',
				placehold : '全部课程类型',
				options : [{ value : '11' , label : '团体课' },{ value : '12' , label : '私教课' },{ value : '13' , label : '小班课' }],
                value:''
		    },{
				type : 'selectMany',
				fieldName : 'courseNameIds',
				placehold : '全部课程',
				options : [],
                value:''
		    },{
				type : 'selectMany',
				fieldName : 'productIds',
				placehold : '扣费方式',
				options : [],
                value:''
		    },{
                type : 'selectMany',
                fieldName : 'teacherIds',
                placehold : '全部教练',
                options : [],
                value:''
            },{
				type : 'select',
				fieldName : 'alreadyCome',
				placehold : '签到状态',
				options : [{ value : '0' , label : '未签到' },{ value : '1' , label : '已签到' },{ value : '3' , label : '已请假' }],
                value:''
		    },{
				type : 'select',
				fieldName : 'signType',
				placehold : '签到方式',
				options : signTypeLsit,
                value:''
		    },{
                type : 'select',
                fieldName : 'timeIn',
                placehold : '',
                value : '5',
                options : timerList,
            },{
                showField : 'timeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'createTimeStr',
                placeholder : '日期范围'
            },{
		    	type : 'select',
		    	fieldName : 'classTimeIn',
		    	placehold : '全部上课时间',
		    	options : timerList ,
		    	value:''
		    }, {
                showField : 'classTimeIn', // 根据显示字段
                showValue : '-1', // 根据显示字段值
                type : 'dateRange',
                fieldName : 'classTimeStr',
                placeholder : '日期范围'
            }]
    /**
     * 查看撤销数据
     */
    function undoData() {
        emit('undoData', true);
    }
    /**
     * 获取扣费支付方式
     */
    getListConsumeProductName()
    function getListConsumeProductName() {
        queryOpts['fields'][4].options = []
        listConsumeProductName().then((res) => {
            res.data.forEach((item) => {
				let obj = {
					label: item.productName,
					value: item.productId +''
				}
				queryOpts['fields'][4].options.push(obj)
			})
        })
    }
    /**
	 * 获取全部课程列表
	 */
	function getClassList() {
		queryOpts['fields'][3].options = []
		let params = {
			page: 1,
			limit: 9999
		}
		listSimple(params).then((res) => {
			if (res.code == 0) {
				res.data.forEach((item) => {
					let obj = {
						label: item.courseName,
						value: item.courseNameId +''
					}
					queryOpts['fields'][3].options.push(obj)
				})
			}
		})
	}
    getPersonnel()
    function getPersonnel() {
		queryOpts['fields'][5].options = []
        let params = {
            page:1,
            limit:1000,
            delFlag: 0,
            teachType: ''
        }
        getStoreAccountStaffSimple(params).then((res) => {
            if (res.code == 0) {
                res.data.forEach(item => {
                    let params = {
                       label: item.realName,
                       value: item.id
                    }
                    queryOpts['fields'][5].options.push(params)
               })
            }
        })
    }
    /**
     * @param (String, Number) value-类型, type-1 卡种类 2-签到方式
     * 表格字段过滤过滤展示
     */
    function filtersType(value, type) {
        let arry = type == 1 ? cardTypeList : signTypeLsit
        let text = ''
        arry.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
    /**
     * 获取列表
     */
    const getList=(isExport) => {
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
        params.productIds = params.productIds ? params.productIds.join(',') : ''
        params.courseNameIds = params.courseNameIds ? params.courseNameIds.join(',') : ''
        params.teacherIds = params.teacherIds ? params.teacherIds.join(',') : ''
        params.operatorIds = params.operatorIds ? params.operatorIds.join(',') : ''
        params.courseTypes = params.courseTypes ? params.courseTypes.join(',') : ''
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
        if (params.alreadyCome == 3) {
            params.ifLeave = 1
            delete params.alreadyCome;
        }
        if (params.timeIn == '-1' && !params.createTimeStr) {
            ElMessage.warning('请选择自定义消费时间')
            return false
        }
        if (params.classTimeIn == '-1' && !params.classTimeStr) {
            ElMessage.warning('请选择自定义上课时间')
            return false
        }
        pageData.loading = true
        params.createTimeStr = params.timeIn == '-1' && params.createTimeStr ?  params.createTimeStr.join('-') : ''
        params.classTimeStr = params.classTimeIn == '-1' && params.classTimeStr ?  params.classTimeStr.join('-') : ''
        params.consumeType = 1
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
        orderConsume (params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data
					pageData.totalCount = res.count
					pageData.remark = res.remark
				}
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 会员详情
     */
	const memberDetail=(id) => {
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
    // 打印小票
	function printTicketEvent(row){
		pageData.courseInfo = row;
		pageData.printShow = true;
	}
	function printSave(){
		pageData.printShow = false;
	}
	/**
     * 会员详情
     */
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
    onMounted(()=>{
        getList()
        getClassList()
    })
</script>

<style  type="text/css" scoped>
    .box-009688{
		border: 1px solid #009688;
		color: #FFF;
		background-color: #009688;
	}
    .box-1E9FFF{
		border: 1px solid #1E9FFF;
		color: #FFF;
		background-color: #1E9FFF;
	}
    .box-F56C6C{
		border: 1px solid #F56C6C;
		color: #FFF;
		background-color: #F56C6C;
	}
    .courseType {
        border-radius: 3px;
    }
    .tag {
        padding: 1px 4px;
    }
</style>