<template>
    <workArea :barlist="barlist">
        <el-tabs class="pt20 pl20 pr20" v-model="pageData.tabNum" @tab-click="handleClick">
            <el-tab-pane label="上课明细（教练）" name="0"  v-if="utils.getMenuRole(31900)"/>
            <el-tab-pane label="消费记录（会员）" name="1"  v-if="utils.getMenuRole(31901)"/>
        </el-tabs>
        <component :is="components[pageData.tabNum]" :tab="pageData.tabNum"></component>
    </workArea>
</template>

<script setup>
    import { reactive, onMounted, onBeforeUnmount } from 'vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import classDetail from '@/views/branch-store/work/consume/classDetail.vue'; // 上课明细（教练）
    import consumeRecord from '@/views/branch-store/work/consume/consumeRecord.vue'; //  消费记录（会员）
	import utils from '@/common/utils.js';
    const components = [classDetail, consumeRecord];
    const barlist = reactive([{ name : '首页'},{ name : '教务中心' },{ name : '消费记录' }]); // 面包屑
    const pageData = reactive({
        tabNum: '0',
    })
	var menuId = [31900,31901]; //会员有权限tab默认项
	for(var i = 0;i < menuId.length;i++){
		if(utils.getMenuRole(menuId[i])){
			pageData.tabNum = i.toString();
			break;
		}
	} 
    function handleClick(tab){
		localStorage.setItem('consume', tab.props.name)
	}
	onMounted(()=> {
      if (localStorage.getItem('consume') != null) {
          pageData.tabNum = localStorage.getItem('consume')
	    }
	})
    onBeforeUnmount(() => {
		localStorage.removeItem('consume')
	})
</script>

<style>
</style>
