<template>
	<ul class="navBottom rx-bc pt20">
		<template v-for="(item,index) in storeData.list" :key="index">
			<li class="flex1 rx-cs t_a_c" :class="{active:index == 0 && storeData.showType == 2,block:themeBgColor == 2,three:!storeData.list[2].checked}" :style="{backgroundColor:themeBgColor == 2 && index > 0 ? '#14141A' : ''}" v-show="item.checked">
				<div>
					<div class="img">
						<img class="w20" :style="{backgroundColor:index == 0 ? themeColorList[themeStore.active].color : ''}" :class="{block:themeBgColor == 2,'b_r_100':storeData.iconType == 2}" :src="getImgUrl(index)" alt="">
					</div>
					<p class="ft12 color-999" :class="{'color-fff':themeBgColor == 2}">{{item.newName || item.name}}</p>
				</div>
			</li>
		</template>
	</ul>
</template>

<script setup>
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	import HomeConfig from './HomeConfig.vue' //中间预览首页组件
	const store = useStore();
	const storeData = store.state.appletFormData[3].list[0];
	const navIcon = store.state.navIcon;
	const themeStore = store.state.appletFormData[4].list[0];
	const themeBgColor = themeStore.bgColor;
	const themeColorList = themeStore.list;
	// 获取图标路径
	function getImgUrl(index){
		if(index == 0 && storeData.iconType == 1){ //首页图标
			return require('@/assets/applet/homeIcon'+storeData.iconType+''+themeBgColor+'.png');
		}else{
			return navIcon[storeData.iconType-1]['icon'+storeData.iconType+''+(index+1)];
		}
	}
</script>

<style scoped lang="stylus">
	.navBottom{
		li{
			height 60px;
			background-color white;
			margin-left -3px;
		}
		li.active{
			position relative;
			background-color initial;
			div{
				position absolute;
				left 0;
				top 22px;
				right 0;
				text-align center;
				z-index 50;
				.img{
					position absolute;
					top -48px;
					left 50%;
					transform translateX(-50%);
					z-index 100;
					background-color white;
					border-radius 100%;
					padding: 9px;
					width: 40px;
					height: 40px;
				}
			}
		}
		li.block{
			background-color #14141A;
		}
		li.active::after{
			content '';
			position absolute;
			top 0;
			right 0;
			bottom 0;
			left 0;
			clip-path:polygon(0% 0%, 10% 1%, 15% 3%, 20% 8%, 25% 16%, 30% 21%, 35% 25%, 40% 28%, 45% 29%, 50% 30%, 55% 29%, 60% 28%, 65% 25%, 70% 21%, 75% 16%, 80% 8%, 85% 3%, 90% 1%, 100% 0%, 100% 0%, 100% 100%, 0 100%);
			background-color white;
		}
		li.active.three::after{
			content '';
			position absolute;
			top 0;
			right 0;
			bottom 0;
			left 0;
			clip-path: polygon(
			0 0,
			18px 0px,
			18.5px 0.5px,
			19px 1px,
			19.5px 1.5px,
			20px 2px,
			25.5px 10px,
			32px 15px,
			38px 17px,
			44px 18px,
			50px 17px,
			56px 15px,
			62.5px 10px,
			69px 2px,
			69.5px 1.5px,
			70px 1px,
			70.5px 0.5px,
			71px 0px,
			100% 0,
			100% 100%,
			0 100%
			);
		}
		li.active.block{
			background-color initial;
			.img{
				background-color #14141A;
			}
		}
		li.block::after{
			background-color #14141A;
		}
	}
</style>
