<template>
    <div class="pl16 pt16 pr16">
        <formQuery v-show="pageData.tableShowType == 1" ref="formQ" :queryOpts="queryOpts" @query="queryTableData"></formQuery>
        <formQuery v-show="pageData.tableShowType == 2" ref="formQList" :queryOpts="queryOptsList" @query="queryTableData"></formQuery>
    </div>
    <div class="ww100 h30 rx-bc p16">
        <div class="w300 rx-sc">
            <el-button type="primary" @click="toAddCourseInfo" v-if="utils.getMenuRole(3150000)">团课批量排期</el-button>
            <el-button v-show="pageData.tableShowType == 1" @click="pageData.popcopy = true"  v-if="utils.getMenuRole(3150001)">复制团课排期</el-button>
        </div>
        <div class="rx-cc ml30" v-show="pageData.tableShowType == 1">
            <el-button class="mr10" type="default" @click="changeWeek(-1)">上周</el-button>
            <div class="w180 h30 rx-cc">{{pageData.tableWeekStart}}~{{pageData.tableWeekEnd}}</div>
            <el-button class="ml10" type="default" @click="changeWeek(1)">下周</el-button>
        </div>
        <div class="w500 rx-ec">
            <el-button v-show="pageData.tableShowType == 2" @click="batchDelete" type="danger" plain>批量删除</el-button>
            <el-button v-show="pageData.tableShowType == 2" @click="batchClose" type="danger" plain>批量关闭</el-button>
            <el-button v-show="pageData.tableShowType == 1" @click="changeTableShowType(2)">切换为列表</el-button>
            <el-button v-show="pageData.tableShowType == 2" @click="changeTableShowType(1)">切换为表格</el-button>
            <el-button v-if="utils.getMenuRole(3150002)" :icon="Printer" @click="print">打印</el-button>
            <el-button v-if="utils.getMenuRole(3150003)" :icon="Download" @click="downTable">下载</el-button>
        </div>
    </div>
    <div class="ww100 pl16 pr16 pt16 pb16" id="downBox">
        <!-- 团课排期课表 -->
        <table v-show="pageData.tableShowType == 1" class="ww100 tableout" cellpadding="0" cellspacing="0">
            <thead>
                <tr>
                    <td class="w60 h50 rx-cc" style="border-bottom:1px solid #e5e5e5;">时间</td>
                    <td class="headcell" v-for="week in pageData.tableDataTitle" :key="week">{{week}}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="time in pageData.tableData" :key="time">
                    <td class="w60" style="border-bottom:1px solid #e5e5e5;text-align:center;">{{time.name}}</td>
                    <td class="bodycell" style="vertical-align:top;" v-for="week in time.weekArray" :key="week">
                        <div v-for="course in week.courseArray" :key="course ">
                            <div class="ww100 hh100" v-if="course.blank">
                                <div @mouseenter="pageData.blankName = course.blank" @mouseleave="pageData.blankName = ''" class="ww100 rx-cc tableblank c_p h110">
                                    <div class="ww100 h100 rx-cc color-main" @click="toAddCourseInfo" v-show="course.blank == pageData.blankName">
                                        <Plus class="mr5" style="height:12px;width:12px;"></Plus>新增排课</div>
                                </div>
                            </div>
                            <div v-else :class="course.status == 2 ? 'close_class' : '' ">
                                <el-popover placement="top" :show-after="200" :width="275" trigger="hover">
                                <template #reference>
                                    <div class="ww100 cx-cc tablecell c_p h110 posi-r" :style="{'background-color':'#'+course.courseColor + '11'}">
                                        <div class="ww100 posi-a top0" style="height:4px;margin-top: -1px;" :style="{'background-color': '#'+ course.courseColor }"></div>
                                        <div class="posi-a right0 top0 bg-main color-fff ft10 pl10 pr10 rx-cc" style="height:18px;margin-top: -1px;">{{course.confirmClassNum}}/{{course.allPeopleNum}}</div>
                                        <div class="ww100 cx-sc" @click="toCourseInfoDetail(course)">
                                            <div class="rx-cc ft12"><Clock class="w15 h15"></Clock><span class="ml5">{{course.classTimeShow}}</span></div>
                                            <div class="ft12 h20 o_h">{{ course.courseCustomName ? course.courseCustomName +' ('+ course.courseName + ')' : course.courseName }}</div>
                                            <div class="ft12 h20 o_h color-999">{{course.teachers}}</div>
                                        </div>
                                        <div class="w120 h20 rx-cc tablecell_icon" v-if="course.status != 2">
                                            <el-tooltip v-if="utils.getMenuRole(3150004)" class="box-item" effect="light" content="编辑本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="editWork(course)"><Edit></Edit></el-icon>
                                            </el-tooltip>
                                            <el-tooltip v-if="utils.getMenuRole(3150006)" class="box-item" effect="light" content="复制本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="copyWorkEvent(course)"><DocumentCopy></DocumentCopy></el-icon>
                                            </el-tooltip>
                                            <el-tooltip v-if="utils.getMenuRole(3150007)" class="box-item" effect="light" content="删除本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="removeWork(course.encryptionId)"><Delete></Delete></el-icon>
                                            </el-tooltip>
                                            <el-tooltip v-if="utils.getMenuRole(3150005)" class="box-item" effect="light" content="关闭本课节" placement="bottom">
                                                <el-icon class="cellicon" @click="closeWork(course.encryptionId)"><View></View></el-icon>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                </template>
                                <!-- 悬浮框内容 -->
                                <div style="width:250px;">
                                    <img v-if="course.status == 2 && course.fullStatus != 2" src="https://s.flow360.cn/src/style/res/manualClose.png" class="autoClose">
                                    <img v-if="course.status == 2 && course.fullStatus == 2" src="https://s.flow360.cn/src/style/res/autoClose.png" class="autoClose">
                                    <div class="ww100 h30 mb8 rx-sc fwb">
                                        <div class="w80 rx-ec">上课时间：</div>
                                        <div class="w170 h30 maxLength rx-sc">{{course.classTimeBetween}}</div>
                                    </div>
                                    <div class="ww100 cx-sc" >
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec ">
                                                <span class="teacher d_i_b">师</span>
                                                <span class="teacher-name">{{ course.teachers }}</span>
                                            </div>
                                            <div class="w80 rx-ec">
                                                <span class="student d_i_b">生</span>
                                                <span>{{course.confirmClassNum}}/{{course.allPeopleNum}}</span>
                                            </div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="ml10">团体课</div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">课程名称：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{course.courseName}}</div>
                                        </div>
                                        <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">课程主题：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{ course.courseCustomName }}</div>
                                        </div>
                                         <div class="ww100 h20 mb8 rx-sc">
                                            <div class="w80 rx-ec">上课场地：</div>
                                            <div class="w170 h30 maxLength rx-sc">{{ course.roomName }}</div>
                                        </div>
                                        <div class="ww100 h30 mb8 rx-sc">
                                            <div class="rx-cc ml10">
                                                <span>已签到({{ course.confirmClassCourse }})</span>
                                            </div>
                                            <div class="rx-cc ml10">
                                                <span>未签到({{course.noAttendanceCount}})</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ww100 h30 ml10 rx-sc" v-if="course.status != 2">
                                        <el-button size="small" type="primary" @click="editWork(course)">调课</el-button>
                                        <el-button size="small" type="success"  @click="toCourseInfoDetail(course)">签到</el-button>
                                    </div>
                                </div>
                            </el-popover>
                            </div>
                            
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- 团课排期-列表 -->
        <div class="ww100" v-show="pageData.tableShowType == 2" >
            <el-table class="ww100"  :data="pageData.teamListData.queryTeam.tableData" stripe border style="" @selection-change="checkChange">
                <el-table-column type="selection" width="55" />
                <el-table-column prop="classTimeStr" fixed width="160" label="上课时间" ></el-table-column>
                <el-table-column label="团体课名称">
                    <template #default="scope">
                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="toCourseInfoDetail(scope.row)">{{ scope.row.courseName }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="courseCustomName"  label="课节主题" />
                <el-table-column prop="teachers"  label="上课教练" />
                <el-table-column prop="roomName"  label="上课场地" />
                <el-table-column prop="confirmClassNum"  label="已预约人数" />
                <el-table-column prop="confirmClassCourse"  label="签到人数" />
                <el-table-column prop="openBookStatusStr"  label="预约状态" />
                <el-table-column width="210" fixed="right" label="操作" >
                    <template #default="scope">
                        <div v-if="scope.row.status != 2">
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="copyWorkEvent(scope.row)">复制</a>
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="closeWork(scope.row.encryptionId)">关闭课节</a>
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="removeWork(scope.row.encryptionId)">删除课节</a>
                       </div> 
                    </template>
                </el-table-column>
            </el-table>
            <div class="mt20 ml20" v-show="pageData.teamListData.queryTeam.tableData.length >= 0">
                <el-pagination
                  v-model:currentPage="pageData.teamListData.queryTeam.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.teamListData.queryTeam.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.teamListData.queryTeam.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="getCourseInfoPage">
                </el-pagination>
            </div>
        </div>
    </div>
    <el-dialog v-model="pageData.popcopy"  title="温馨提示" :width="300" >
        <div class="ww100 h80">
            <div class="t_a_c mt30 mb10">确定将当前周的所有团课课程复制到下一周？</div>
            <div class="rx-cc">
                <el-checkbox v-model="pageData.ifCopyName" label="同时复制课节主题"></el-checkbox>
            </div>
            
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="pageData.popcopy = false;pageData.copyCourseTopic=false">取消</el-button>
            <el-button type="primary" @click="subCopyWeekCourse()">确定</el-button>
          </span>
        </template>
    </el-dialog>
    <el-dialog v-model="pageData.copyWork" title="复制课程" :width="800">
        <template #default>
            <div class="p20">
                <fieldset>
                <legend>被复制原始课程</legend>
                <el-form ref="formRef" :model="copyForm" label-width="120px">
                    <el-form-item label="课程名称">
                        <span>{{ copyForm.courseName }}</span>
                    </el-form-item>
                    <el-form-item label="上课时间">
                        <span>{{copyForm.classTimeStr}}</span>
                    </el-form-item>
                    <el-form-item label="下课时间">
                        <span>{{copyForm.classTimeEndStr}}</span>
                    </el-form-item>
                </el-form>
                </fieldset>
                <el-divider></el-divider>
                <fieldset>
                <legend>复制到目标课程</legend>
                <el-form ref="formRef" :model="copyForm" label-width="120px">
                    <el-form-item label="上课时间">
                        <el-date-picker
                            v-model="copyForm.classTimeStr2"
                            type="datetime"
                            placeholder="请选择上课时间"
                            format="YYYY-MM-DD HH:mm"
                            value-format="YYYY-MM-DD HH:mm"
                        />
                    </el-form-item>
                    <el-form-item label="下课时间">
                        <el-date-picker
                            v-model="copyForm.classTimeEndStr2"
                            type="datetime"
                            placeholder="请选择下课时间"
                            format="YYYY-MM-DD HH:mm"
                            value-format="YYYY-MM-DD HH:mm"
                        />
                    </el-form-item>
                </el-form>
                </fieldset>
            </div>
            <el-dialog
              v-model="pageData.innerVisible"
              width="400px"
              title="温馨提示"
              append-to-body
            >
            <template #default>
                <div class="t_a_c">
                    <div class="mt30 mb20">确定要复制吗？</div>
                    <div class="mb20">
                        <el-checkbox v-model="ifCopyName" label="同时复制课节主题"></el-checkbox>
                    </div>
                </div>
                 
            </template>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="pageData.innerVisible = false">取 消</el-button>
                <el-button type="primary" @click="copyWorkForm">确 定</el-button>
              </span>
            </template>
            </el-dialog>
        </template>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="pageData.copyWork = false">取 消</el-button>
            <el-button type="primary" @click="pageData.innerVisible = true">确 定</el-button>
          </span>
        </template>
    </el-dialog>
    <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave">
		<template #btns>
            <div v-if="confirmType == 'courseClose'">
                <el-button size="small" @click="confirmClose">取消</el-button>
		        <el-button size="small" type="primary" @click="()=>{confirmSave(2)}">关闭课节</el-button>
                <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">关闭课节并提醒会员</el-button>
            </div>
		    <div v-if="confirmType == 'courseDelete'">
                <el-button size="small" @click="confirmClose">取消</el-button>
		        <el-button size="small" type="primary" @click="()=>{confirmSave(2)}">删除课节</el-button>
                <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">删除课节并提醒会员</el-button>
            </div>
		</template>
	</Confirm>
    <EditWork ref="editWorkRef" @close="editWorkClose" :type="11" @onEditWork="confirmEditWork"></EditWork>
</template>

<script setup>
    import moment from "moment";
    import utils from '@/common/utils.js';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import workArea from '@/components/branch-store/workArea.vue';
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router';
    import { ElMessage } from 'element-plus';
    import { ref,reactive,onMounted,onActivated } from 'vue';
    import { onBeforeRouteLeave,onBeforeRouteUpdate } from "vue-router";
    import { Search,Edit,DocumentCopy,Delete,View,Clock,Plus,Download,Printer } from '@element-plus/icons-vue';
    import { 
        getCourseInfoTable,
        copyWeekCourse,
        getCourseInfoPageList,
        deleteCourseInfoBatch,
        closeCourseInfoBatch,
        courseClose,
        courseDelete,
        courseCopyOne
    } from '@/api/courseInfo.js';
    import { getStoreAccountStaffSimple } from '@/api/staff.js';
    import { sportRoomListAll } from '@/api/place.js';
    import EditWork from '@/components/branch-store/course/editWork.vue'
    import Confirm from '@/components/Confirm.vue'
    import html2canvas from 'html2canvas'
    const router    = useRouter();
    const store     = useStore();
    const confirmRef = ref();
    const editWorkRef = ref()
    const formQ     = ref();                // 表单查询条件
    const formQList = ref();                // 表单查询条件
    const barlist   = reactive([{ name : '首页' },{ name : '课表管理' }]); // 面包屑
    const pageData  = reactive({
        copyWork: false, // 复制弹框
        innerVisible: false, // 确定复制
        loadingTable   : false,
        tabName        : '1',               // 1团课排期，2私教 
        tableData      : reactive([]),      // 团课表格数据
        tableWeekNum   : 0,                 // 团课表格周
        tableWeekStart : '',                // 团课表格周显示开始
        tableWeekEnd   : '',                // 团课表格周显示结束
        tableDataTitle : reactive([]),      // 团课表格头
        tableForm      : {},                // 表单内容
        teamListData   : {                  // 列表内容数据集合
            queryTeam      : {              // 列表数据
                totalCount : 0,
                searchForm : {
                    page   : 1,
                    limit  : 15
                },
                tableData  : []                 // 列表--团课列表数据
            },
        },
        popcopy        : false,             // 显示复制课程
        ifCopyName     : false,             // 是否复制课节名称
        tableShowType  : 1,                 // 1 课表表格，2课表列表
        blankName      : '',                 // 空格显示名称
        deleCheckData  : []                 //  待删除列表
    });
    let confirmType = ref() // 全局弹框类型
    let confirmId = '' // 全局课节id
    let ifCopyName = ref(true) // 是否复制课节主题
    let copyForm = reactive({
       courseName: '',
       classTimeStr: '',
       classTimeStr2: '',
       classTimeEndStr: '',
       classTimeEndStr2: '',
    })
    const queryOpts = reactive({});         // 表单查询字段
    queryOpts['fields'] = [{
        type : 'input',
        fieldName : 'keywords',
        placehold : '搜索会员姓名手机号'
    },{
        type : 'selectMany',
        fieldName : 'courseNameIds',
        placehold : '全部团体课',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'teacherIds',
        placehold : '全部教练',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'classroomIds',
        placehold : '全部场地',
        options : []
    }];
    const queryOptsList = reactive({});
    queryOptsList['fields'] = [{
        type : 'input',
        fieldName : 'keywords',
        placehold : '搜索会员姓名手机号'
    },{
        type : 'selectMany',
        fieldName : 'courseNameIds',
        placehold : '全部团体课',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'teacherIds',
        placehold : '全部教练',
        options : []
    },{
        type : 'selectMany',
        fieldName : 'classroomIds',
        placehold : '全部场地',
        options : []
    },{
        type : 'select',
        fieldName : 'datetype',
        placehold : '时间范围',
        value : '4',
        options : utils.getDict('dateType')
    },{
        showField : 'datetype', // 根据显示字段
        showValue : 6, // 根据显示字段值
        type : 'dateRange',
        fieldName : 'querydate',
        placeholder : '日期范围'
    }];
    /**
     * 获取场地
     */
    sportRoomListAll({page: 1, limit: 9999}).then(res => {
       if (res.code == 0) {
            var typeArray = [];
			for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['id'],
			        label : res.data[i]['roomName'] 
			    });
			}
            queryOpts['fields'][3]['options'] = typeArray
            queryOptsList['fields'][3]['options'] = typeArray
        } 
    })
    /**
     * 获取教练
     */
    getStoreAccountStaffSimple({teachType: 1, page: 1, limit: 9999}).then((res) => {
        if (res.code == 0) {
            var typeArray = [];
			for(var i=0;i<res.data.length;i++){
			    typeArray.push({
			        value : res.data[i]['id'],
			        label : res.data[i]['realName'] 
			    });
			}
            queryOpts['fields'][2]['options'] = typeArray
            queryOptsList['fields'][2]['options'] = typeArray
        }
    })
    utils.getDynaDict('teamCourseNameAll','',function(courseNameIds){
        for(var i=0;i<queryOpts['fields'].length;i++){
            if(queryOpts['fields'][i]['fieldName'] == 'courseNameIds'){
                queryOpts['fields'][i]['options'] = courseNameIds;
            }
        }
        for(var i=0;i<queryOptsList['fields'].length;i++){
            if(queryOptsList['fields'][i]['fieldName'] == 'courseNameIds'){
                queryOptsList['fields'][i]['options'] = courseNameIds;
            }
        }
    })
    
    // 批量删除
    function batchDelete(){
        if(pageData.deleCheckData.length == 0){
            ElMessage({ type : 'warning' ,message : '请先选择数据'});
            return;
        }
        deleteCourseInfoBatch({
            ids : pageData.deleCheckData.join(',')
        }).then((res)=>{
            if(res.code == 0){
                pageData.deleCheckData = [];
                ElMessage({ type : 'success' ,message : res.msg});
                getCourseInfoPage();
            }else{
                ElMessage({ type : 'warning' ,message : res.msg});
            }
        });
    }
    // 批量关闭
    function batchClose(){
        if(pageData.deleCheckData.length == 0){
            ElMessage({ type : 'warning' ,message : '请先选择数据'});
            return;
        }
        closeCourseInfoBatch({
            encryptionCourseIds : pageData.deleCheckData.join(',')
        }).then((res)=>{
            if(res.code == 0){
                pageData.deleCheckData = [];
                ElMessage({ type : 'success' ,message : '关闭成功'});
                getCourseInfoPage();
            }
        });
    }
    // 列表-选择列表内容
    function checkChange(data){
        pageData.deleCheckData = data.map(n=>n.encryptionId);
    }
    
    // 复制团课排期
    function subCopyWeekCourse(){
        var ifCopyName = pageData.ifCopyName ? 1 : 0;
        copyWeekCourse({
            monday : pageData.monday,
            ifCopyName : ifCopyName
        }).then((res)=>{
            pageData.popcopy = false;
            if(res.code == 0){
                if(res.data == -1){
                    ElMessage({ type: 'warning', message: '下周已存在团体课排课，复制失败' })
                }else if(res.data == -2){
                    ElMessage({ type: 'warning', message: '当前周没有可以复制的团体课，请先排课' })
                }else{
                    ElMessage({ type: 'success', message: '复制成功' })
                }
                return;
            }
            ElMessage({ type: 'warning', message: res.message })  
        });
    }

    // 表单回调
    function queryTableData(){
        if(pageData.tableShowType == 1){
            getTableData();
        }else{
            getCourseInfoPage();
        }
        pageData.teamListData.queryTeam.searchForm.page = 1
    }
    
    // 查询表格内容
    function getTableData(cb){
        var param             = Object.assign({},formQ.value.getQueryForm());
        param['startDate']    = pageData.tableWeekStart;
        param['endDate']      = pageData.tableWeekEnd;
        pageData.loadingTable = true;
        param['courseType']   = 11;
        param['teacherIds']  = param['teacherIds'] && param['teacherIds'].join(',') || '';
        param['courseNameIds']= param['courseNameIds'] && param['courseNameIds'].join(',') || '';
        param['classroomIds']= param['classroomIds'] && param['classroomIds'].join(',') || '';
        

        getCourseInfoTable(param).then((res)=>{
            pageData.loadingTable = false;
            if(res.code != 0 ){
                ElMessage({ type: 'warning', message: res.message })
                return;
            }
            pageData.tableDataTitle = utils.getWeekArray(pageData.tableWeekStart); // 根据周一日期获取一周的日期名称
            var tableData   = []; // 表格数据集合
            var timeCourses = {};
            var courseList  = res.data.map((n)=>{
				n.classTimeBetween = n.startTimeBetween.split(' ')[1];
				return n;
			});
            for(var i=0;i<courseList.length;i++){
                var lessonTimeStart = moment(courseList[i]['classTimeStr']).format('HH:mm');
                var lessonTimeEnd   = moment(courseList[i]['classTimeEndStr']).format('HH:mm');
                courseList[i]['classTimeShow'] = lessonTimeStart + '~' + lessonTimeEnd;
                var time            = moment(courseList[i]['classTimeStr']).format('H');
                var timeObj         = timeCourses[time] || {};
                var timeCourseCount = timeObj['timeCourseCount'] || 0;
                var weekIdx         = moment(courseList[i]['classTimeStr']).format('E');
                var courseWeekArray = timeObj[weekIdx] || [];
                courseWeekArray.push(courseList[i]);
                timeObj[weekIdx]  = courseWeekArray;
                timeCourseCount   = courseWeekArray.length > timeCourseCount ? courseWeekArray.length : timeCourseCount;
                timeObj['timeCourseCount'] = timeCourseCount;
                timeCourses[time] = timeObj;
            }
            for(var i=0;i<24;i++){
                if(timeCourses[i]){
                    var hourStart = i+'';
                    hourStart     = hourStart.length == 1 ? '0'+hourStart : hourStart;
                    var hourEnd   = i+1 + '';
                    hourEnd       = hourEnd.length == 1 ? '0'+hourEnd : hourEnd;
                    var name      = hourStart + ':00';
                    var timeObj   = { name : name, weekArray : [] };
                    for(var j=1;j<8;j++){
                        var dayCourse = { courseArray : [] };
                        if(timeCourses[i][j] && timeCourses[i][j].length > 0){
                            dayCourse['courseArray'] = timeCourses[i][j];
                        }
                        for(var k = dayCourse.courseArray.length;k<timeCourses[i]['timeCourseCount'];k++){
                            dayCourse['courseArray'].push({blank:i+'-'+j+'-'+k});
                        }
                        timeObj.weekArray.push(dayCourse);
                    }
                    tableData.push(timeObj);
                }
            }
            pageData.monday    = res.results.monday;
            pageData.tableData = tableData;
            cb && cb();
        });
    }
    // 切换课节展示方式
    function changeTableShowType(type){
        pageData.tableShowType = type;
        if(type == 2 && pageData.teamListData.queryTeam.tableData.length == 0){
            getCourseInfoPage();
        }
        if(type == 1 && pageData.tableData.length == 0){
            getTableData();
        }
    }
	//切换分页数量
	function pageSizeChange(limit){
		pageData.teamListData.queryTeam.searchForm.limit = limit;
		getCourseInfoPage();
	}
    // 列表--分页查询课节列表
    function getCourseInfoPage(){
        var param = {};
        Object.assign(param,formQList.value.getQueryForm());
        param.limit = pageData.teamListData.queryTeam.searchForm.limit
        param.page = pageData.teamListData.queryTeam.searchForm.page
        if(param['datetype'] == 1){ // 今天
            param['startDate'] = moment().format('YYYY-MM-DD');
            param['endDate']   = moment().format('YYYY-MM-DD');
        }else if(param['datetype'] == 2){ // 昨天
            param['startDate'] = moment().add(1, 'days').format('YYYY-MM-DD');
            param['endDate']   = moment().add(1, 'days').format('YYYY-MM-DD');
        }else if(param['datetype'] == 3){ // 明天
            param['startDate'] = moment().add(-1, 'days').format('YYYY-MM-DD');
            param['endDate']   = moment().add(-1, 'days').format('YYYY-MM-DD');
        }else if(param['datetype'] == 4){ // 本周
            var weekDates = utils.getWeekDays();
            param['startDate'] = weekDates[0];
            param['endDate']   = weekDates[1];
        }else if(param['datetype'] == 5){ // 本月
            param['startDate'] = moment().startOf('month').format("YYYY-MM-DD");;
            param['endDate']   = moment().endOf('month').format("YYYY-MM-DD");;
        }else if(param['datetype'] == 6){ // 其他
            var querydateArray = param['querydate'];
            if(!param['querydate']){
                ElMessage({ type : 'warning' , message : '请先选择日期范围'});
                return;
            }
            param['startDate'] = param['querydate'][0];
            param['endDate']   = param['querydate'][1];
        }
        delete param['querydate'];
        param['teacherIds']  = param['teacherIds'] && param['teacherIds'].join(',') || '';
        param['courseNameIds']= param['courseNameIds'] && param['courseNameIds'].join(',') || '';
        param['classroomIds']= param['classroomIds'] && param['classroomIds'].join(',') || '';
        param['courseType']   = 11;
        pageData.loadingTable = true;
        getCourseInfoPageList(param).then((res)=>{
            pageData.loadingTable = false;
            if(res.code == 0){
                pageData.teamListData.queryTeam.tableData = res.data;
                pageData.teamListData.queryTeam.totalCount = res.count
            }
        });
    }
    // 跳转课节详情-关闭当前页面全部浮窗
    function toCourseInfoDetail(row){
        if (row.status == 2 ) {
            return false
        }
        var x = document.getElementsByClassName("el-popper");
        for (var i = 0; i < x.length; i++) {
            x[i].style.display = "none";
        }
        router.push({
            path:'/branch/courseInfoDetail',
            query: {
                encryptionId: row.encryptionId,
                encryptionCourseNameId: row.encryptionCourseNameId
            }
        });
    }
    // 点击上一周/下一周 type：-1上周1下周
    function changeWeek(type){
        if(pageData.loadingTable){ return; }
        pageData.tableWeekNum   = type == -1 ? pageData.tableWeekNum - 1 : pageData.tableWeekNum + 1;
        var tableWeekArray      = utils.getWeekDays(pageData.tableWeekNum);
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd   = tableWeekArray[1];
        getTableData();
    }
    // 跳转排课
    function toAddCourseInfo(){
        router.push('/branch/courseInfoAdd');
    }
    /**
     * 编辑课节
     */
    function editWork(row) {
        editWorkRef.value.getEditInfo(row.encryptionId)
		editWorkRef.value.show();
    }
    /**
     * 确定编辑
     */
    function confirmEditWork(){
        getTableData()
    }
    /**
     * 删除课节
     */
    function removeWork(id) {
        pageData.confirmTitle = '确定删除课节吗？';
        pageData.confirmText = '删除后将自动为会员取消预约本节课！'
		confirmType.value = 'courseDelete';
        confirmId = id
		confirmRef.value.show();
    }
    /**
     * @param(Object) row 课节详情
     * 拉起复制课节
     */
    function copyWorkEvent(row) {
		for(var k in copyForm){
			copyForm[k] = row[k];
		}
		var s = utils.getDateObj(row.classTimeStr);
		var e = utils.getDateObj(row.classTimeEndStr);
		var st = utils.getDateObj(s.time+24*60*60*1000);
		var et = utils.getDateObj(e.time+24*60*60*1000);
		copyForm.classTimeStr2 = st.Y+'-'+st.M+'-'+st.D+' '+st.h+':'+st.m;
		copyForm.classTimeEndStr2 = et.Y+'-'+et.M+'-'+et.D+' '+et.h+':'+et.m;
        confirmId = row.encryptionId;
        pageData.copyWork = true
    }
    /**
     * 确定复制课节
     */
    function copyWorkForm() {
        let params = {
            encryptionId: confirmId,
            classTimeStr: copyForm.classTimeStr2,
            classTimeEndStr: copyForm.classTimeEndStr2,
            ifCopyName: ifCopyName ? 1 : 0
        }
        courseCopyOne(params).then((res) => {
            if (res.code == 0) {
				pageData.copyWork = false;
				pageData.innerVisible = false;
               ElMessage.success('复制成功')
               getTableData()
            }
        })
    }
    /**
     * 关闭课节
     */
    function closeWork(id) {
        pageData.confirmTitle = '确定关闭课节吗？';
        pageData.confirmText = '关闭后的课节不能再次开启，关闭后将自动为会员取消本节课！'
		confirmType.value = 'courseClose';
        confirmId = id
		confirmRef.value.show();
    }
    /**
     * 确定提交询问框
     */
    function confirmSave(type) {
        if (confirmType.value == 'courseClose') {
           courseClose({ encryptionId: confirmId, type}).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功关闭')
                   getTableData()
                }
				confirmRef.value.close();
           })
        } else {
            courseDelete({ encryptionId: confirmId, type}).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功删除')
                   getTableData()
                }
                confirmRef.value.close();
           })
        }
    }
	function confirmClose(){
		confirmRef.value.close();
	}

  // 下载
    function downTable(){
      html2canvas(document.getElementById('downBox')).then((canvas)=>{
        const imgUrl = canvas.toDataURL('png');
        const aDom = document.createElement('a');
        aDom.href = imgUrl;
        aDom.download = '团体排期课表';
        document.body.appendChild(aDom);
        aDom.click();
        aDom.remove();
      })
    }
    /**
     * 打印
     */
    function print() {
        html2canvas(document.getElementById('downBox')).then((canvas)=>{
        const imgUrl = canvas.toDataURL('png');
        var iframe = document.createElement('iframe');
        iframe.style.width = '100%';
		document.body.appendChild(iframe)
        iframe.contentWindow.document.write(`<!DOCTYPE html>
						                    <html>
						                    	<head>
                                                    <title>团体排期课表</title>
						                    	</head>
						                    	<body>
                                                    <img src="${imgUrl}" style="width:100%;">
						                    	</body>
						                    </html>`)
        setTimeout(() =>{
            iframe.contentWindow.print();
		    iframe.remove();
        }, 200)
      })
    }
    // 页面启动
    onMounted(()=>{
        var tableWeekArray      = utils.getWeekDays(pageData.tableWeekNum)
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd   = tableWeekArray[1];
        getTableData();
    })
    // 页面激活，返回时刷新
    onActivated(()=>{
        getTableData();
    })
</script>

<style type="text/css" scoped>
.box-item{ font-size:10px !important;}
    .tableout{
        border:1px solid #e5e5e5;
        border-bottom: none;
        border-radius: 5px;
    }
    .headcell{
        width:14.2857%;
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .bodycell{
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .tablecell{
        border:1px solid #ffffff;
        border-bottom: 1px solid #f0f0f0;
    }
    .tablecell_icon {
        display: none;
    }
    .tablecell:hover .tablecell_icon{
        display: block;
    }
    
    .tableblank{
        border:1px solid #ffffff;
        border-bottom: 1px solid #ffffff;
    }
     .tableblank:hover{
        border:1px dashed #00a3ff;
    }
    .cellicon{ width : 22px;height:22px; }
    .cellicon:hover{color:#00a3ff;}
    fieldset {
        display: block;
        margin-inline-start: 2px;
        margin-inline-end: 2px;
        padding-block-start: 0.35em;
        padding-inline-start: 0.75em;
        padding-inline-end: 0.75em;
        padding-block-end: 0.625em;
        min-inline-size: min-content;
        border-width: 2px;
        border-style: groove;
        border-color: threedface;
        border-image: initial;
    }
    legend {
        display: block;
        padding-inline-start: 2px;
        padding-inline-end: 2px;
        border-width: initial;
        border-style: none;
        border-color: initial;
        border-image: initial;
    }
    .close_class {
        background: #ffffff;
        opacity: .3;
    }
    .autoClose {
        position: absolute;
        top: 0;
        right: 0;
        width: 25%;
    }
    .teacher {
        padding: 0 3px;
        border: 1px solid #10D2B7;
        color: #10D2B7;
        border-radius: 2px;
        margin-right: 8px;
    }
    .teacher-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 40px;
    }
    .student {
        padding: 0 3px;
        border: 1px solid #FE8080;
        color: #FE8080;
        border-radius: 2px;
        margin-right: 8px;
    }
</style>




