import {postAjax, getAjax}  from "../common/axios.js"

// 获取前台banner图
export const allBanners = params => {
    return postAjax('/api/systemBanner/allBanners',params);
}
// 入离场-查询会员入场凭证列表
export const getMemberVoucher = params => {
    return postAjax('/api/enterOutSite/getMemberVoucher',params);
}
// 入场
export const enter = params => {
    return postAjax('/api/enterOutSite/enter',params);
}
// 在场列表
export const onSiteList = params => {
    return postAjax('/api/enterOutSite/onSiteList',params);
}
// 单个离场 
export const out = params => {
    return postAjax('/api/enterOutSite/out',params);
}
// 批量离场 
export const batchOut = params => {
    return postAjax('/api/enterOutSite/batchOut',params);
}
// 入离场记录
export const list = params => {
    return postAjax('/api/enterOutSite/list',params);
}

// 公告列表
export const systemNotice = params => {
    return postAjax('/api/systemNotice/list',params);
}

// 公告详情
export const detail = params => {
    return getAjax('/api/systemNotice/detail', params);
}
