<template>
	<workArea :barlist="barlist">
	<div class="card_manage bg-fff pl16 pt10 pr16">
		<el-tabs v-model="pageData.tabNum" @tab-click="handleClick">
		    <el-tab-pane label="课程列表" name="0" v-if="utils.getMenuRole(31700)"></el-tab-pane>
		    <el-tab-pane label="训练目的" name="1" v-if="utils.getMenuRole(31701)"></el-tab-pane>
		</el-tabs>
		<component :is="components[pageData.tabNum]" :tab="pageData.tabNum" ></component>
	</div>
	</workArea>
</template>
<script>
  export default {
    name: 'BranchCourse'
  }
</script>
<script setup>
	import { reactive, onMounted, onBeforeUnmount} from 'vue';
	import List from '@/views/branch-store/work/courseName/List.vue' //课程卡
	import Objective from '@/views/branch-store/work/courseName/Objective.vue' //入场卡
	import workArea from '@/components/branch-store/workArea.vue';
	import utils from '@/common/utils.js';
	const components = [List,Objective];
	const barlist = [{ name : '首页' },{ name : '教务中心' },{ name : '课程管理' }];
	const pageData = reactive({
		tabNum:'0',
	})
    var menuId = [31700,31701]; //会员有权限tab默认项
    for(var i = 0;i < menuId.length;i++){
    	if(utils.getMenuRole(menuId[i])){
    		pageData.tabNum = i.toString();
    		break;
    	}
    }  
	function handleClick(tab) {
       localStorage.setItem('courseName', tab.props.name)
	}
	onMounted(()=> {
        if (localStorage.getItem('courseName') != null) {
           pageData.tabNum = localStorage.getItem('courseName')
	    }
	})
	onBeforeUnmount(() => {
		localStorage.removeItem('courseName')
	})
	
</script>

<style scoped lang="stylus">
	
</style>
