<template>
	<ul class="fun_list">
		<template v-for="(item,index) in storeData.modules" :key="index">
			<li v-if="item.checked" class="t_a_c ww25 h70">
				<img class="ww50" :src="moduleIcon[storeData.moduleType-1]['icon'+storeData.moduleType+''+(index+1)]" alt="">
				<p class="color-333 ftb ft12" :class="{'color-fff':themeBgColor == 2}">{{item.name}}</p>
			</li>
		</template>
	</ul>
</template>

<script setup>
	const propData = defineProps({
	  tab:String,
	  itemIndex:Number
	})
	import {ref,reactive} from 'vue'
	import { useStore } from 'vuex'
	const store = useStore();
	const storeData = store.state.appletFormData[propData.tab].list[propData.itemIndex];
	const moduleIcon = store.state.moduleIcon;
	const themeBgColor = store.state.appletFormData[4].list[0].bgColor;
</script>

<style scoped lang="stylus">
	.fun_list{
		overflow hidden;
		li{
			float left;
			padding 10px 0;
		}
	}
</style>
