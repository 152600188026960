<!-- 课节详情页面 -->
<template>
    <workArea :barlist="barlist">
        <div class="ww100 h250">
            <div class="rx-bc p20">
                <div class="rx-c">
                    <img class="w70 h70 mr5" :src="require('@/assets/courseDetailAvatar.png')" alt="">
                    <h4 class="color-409EFF ft16">【{{ pageData.form.cycleStatus == 1 ? '未开始' : pageData.form.cycleStatus == 2 ? '进行中' : '已结束' }}】</h4>
                    <span class="ft16">{{ pageData.form.cycleName }}</span>
                </div>
            </div>
            <div class="pl100">
                <el-row>
                    <el-col class="mb25" :span="6"><span class="d_i_b w100 t_a_r">上课课程：</span>{{ pageData.form.courseName }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">小班课类型：</span>{{ pageData.form.cycleType == 11 ? '固定上课模式' : '自由预约模式' }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">最大报名人数：</span>{{ pageData.form.allPeopleNum }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">在班人数：</span>{{ pageData.form.sellPeopleNum }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">已排课节：</span>{{ pageData.form.cycleNumHas }}/{{ pageData.form.cycleNum }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">班级状态：</span>{{ pageData.form.cycleStatus == 1 ? '未开始' : pageData.form.cycleStatus == 2 ? '进行中' : '已结束'  }}</el-col>
                    <el-col :span="6"><span class="d_i_b w100 t_a_r">单课时长：</span>{{ pageData.form.courseTime }}</el-col>
                    <el-col :class="pageData.form.cycleType == 11 ? 'mb25' : '' " :span="6"><span class="d_i_b w100 t_a_r">开班时间：</span>{{ pageData.form.cycleStartCourseTimeStr}}</el-col>
                    <el-col :span="6" v-if="pageData.form.cycleType == 11"><span class="d_i_b w100 t_a_r">是否允许请假：</span>{{ pageData.form.ifLeave == 1 ? '允许请假' : '不允许请假' }}</el-col>
                    <el-col :span="6" v-if="pageData.form.cycleType == 11 && pageData.form.ifLeave == 1"><span class="d_i_b w100 t_a_r">请假是否扣课：</span>{{ pageData.form.ifLeave == 1 && pageData.form.leaveConsumeFlag == 1 ? '扣课' : '不扣课' }}</el-col>
                    <el-col :span="6" v-if="pageData.form.cycleType == 11 && pageData.form.ifLeave == 1 "><span class="d_i_b w100 t_a_r">可请假次数：</span>{{ pageData.form.leaveLimitNum }}</el-col>
                    <el-col :span="6" v-if="pageData.form.cycleType == 11 && pageData.form.ifLeave == 1 "><span class="d_i_b w100 t_a_r">请假截止时间：</span>课前{{ pageData.form.leaveEndRule }}小时</el-col>
                </el-row>
            </div>
        </div>
        <div class="ww100 h10 bg-gray"></div>
        <!-- tab切换 -->
        <div class="pl20 pr20">
            <el-tabs v-model="pageData.tabName" @tab-click="handleClick">
                <el-tab-pane label="课节信息" name="1">
                    <div class="rx-sc mt16" v-if="pageData.form.cycleStatus != 3">
                        <el-button type="primary" plain @click="addClass">新增排课</el-button>
                        <el-button type="primary" plain @click="batchSignIn">调整上课时间</el-button>
                        <el-button type="danger" plain @click="batchDelete">批量删除</el-button>
                        <el-button type="danger" plain @click="batchClose">批量关闭</el-button>
                    </div>
                    <el-table class="mt16" ref="multipleTableRef" :row-key="getRowId" :data="pageData.classTableData" stripe @selection-change="checkChange">
                        <el-table-column type="selection" fixed :reserve-selection="true" width="55" />
                        <el-table-column prop="cycleCurrent" fixed width="80" label="序号" />
                        <el-table-column prop="classTimeStr" label="课节时间" width="240" />
                        <el-table-column prop="courseCustomName" label="课节主题" width="260" show-overflow-tooltip>
                            <template #default="scope">
                                <div class="rx-bc" v-if="!scope.row.show">
                                    <div class="mr10">{{ scope.row.courseCustomName }}</div>
                                    <el-icon v-if="pageData.form.cycleStatus != 3" class="c_p" @click="scope.row.show = true"><EditPen /></el-icon>
                                </div>
                                <div v-else>
                                    <el-input class="mr10 w150" v-model="scope.row.courseCustomName" placeholder="请输入" />
                                    <el-button type="primary" @click="preserve(scope.row)">保存</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="confirmClassNum" label="当前课节人数">
                            <template #default="scope">
                                {{ scope.row.confirmClassNum + scope.row.leaveStudentNum}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="teacherName" label="上课教练" />
                        <el-table-column prop="roomName" label="上课场地" />
                        <el-table-column width="160" fixed="right" label="操作">
                            <template #default="scope">
                                <div v-if="pageData.form.cycleStatus != 3">
                                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="editClass(scope.row)">编辑</a>
                                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="classDetail(scope.row)">课节详情</a>
                                </div>
                                
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt10 ml20">
                        <el-pagination
                          v-model:currentPage="pageData.query.page"
                          :page-sizes="[15,20,30,50,100]"
                          :page-size="pageData.query.limit"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="pageData.query.totalCount"
                          @size-change="classPageSizeChange"
                          @current-change="getCoursegetCycleCourse"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="在班会员" name="2">
                   <div class="mt16" v-if="TYPE == 12">
                         <div class="rx-c mb16">
                             <img class="w15 h15 mr5" src="@/assets/wenhao.png" >
                             <span class="color-999">通过课程卡/体验卡资产进行课程自主预约的会员不会进入班级，若会员在班级有固定上课时间，可添加会员进班并批量拉入课节</span>
                        </div>
                        <el-button v-if="pageData.form.cycleStatus != 3" type="primary" plain @click="addMember">添加会员</el-button>
                   </div>
                   <el-table class="mt16" :data="pageData.memberTableData" stripe>
                        <el-table-column label="会员姓名" width="150" >
                            <template #default="scope">
                                <span>{{ scope.row.realName }}</span>
                                <span class="ft12 color-fff bg-FBB63D pl5 pr5 pt3 pb2 ml5 bdr2" v-if="utils.datedifference1(pageData.form.cycleFirstCourseTimeStr.split(' ')[0], scope.row.createTimeStr.split(' ')[0])>0" >中途进班</span>
                                <span class="ft12 color-fff bg-ED412A pl5 pr5 pt3 pb2 ml5 bdr2" v-if="scope.row.status == 2">已退班</span>
			                </template>
                        </el-table-column>
                        <el-table-column prop="telephoneNum" label="手机号" ></el-table-column>
                        <el-table-column>
                            <template #header>
                                <div class="rx-sc">
                                    <span class="mr12">已购买课节数</span>
                                    <el-tooltip
                                        class="box-item"
                                        effect="dark"
                                        content="即会员进班时购买的总课节数"
                                        placement="top-start"
                                      >
                                        <img src="@/assets/wenhao.png" class="w15">
                                    </el-tooltip>
                                </div>
                            </template>
			                <template #default="scope">
                                {{ scope.row.productNumBuy || 0 }}
			                </template>
			            </el-table-column>
                        <el-table-column prop="hasOrderCourseCount" label="已加入课节数" />
                        <el-table-column prop="hasSignCourseCount" label="已签到课节数" />
                        <el-table-column prop="createTimeStr" label="报名时间" />
                        <el-table-column prop="classOutTimeStr" label="末次退班时间" />
                        <el-table-column label="报名方式">
                            <template #default="scope">
                                {{ scope.row.createBy ? 'PC端签单' : '会员端购买'  }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <div v-if="pageData.form.cycleStatus != 3">
                                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.status == 1" @click="dismissal(scope.row)">退班</a>
                                </div>
                                
                                <!-- <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="transfer(scope.row)">转让</a>
                                <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="shiftTransfer(scope.row)">转班</a> -->
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="mt10 ml20">
                        <el-pagination
                          v-model:currentPage="pageData.query1.page"
                          :page-sizes="[15,20,30,50,100]"
                          :page-size="pageData.query1.limit"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="pageData.query1.totalCount"
                          @size-change="classPageSizeChange1"
                          @current-change="getCourseCycleStu"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="出勤详情" name="3">
                    <!-- <div class="mt16"  v-if="TYPE == 12">
                        <el-radio-group v-model="pageData.radio">
                            <el-radio :label="1">仅展示班级学员</el-radio>
                            <el-radio :label="2">展示所有学员</el-radio>
                        </el-radio-group>
                    </div> -->
                    <el-table v-if="pageData.signTableData.cycleNameOriginalList && pageData.signTableData.cycleNameOriginalList.length" class="mt16" :data="pageData.signTableData.stuStateList" stripe style="width: 100%">
                        <el-table-column prop="realName" fixed width="160" label="会员姓名" />
                        <template v-for="(item,index) in pageData.signTableData.cycleNameOriginalList" :key="index">
                            <el-table-column min-width="140" align="center">
                                <template #header>
                                    <div class="color-409EFF c_p" v-html="item"></div>
                                </template>
			                    <template #default="scope">
                                    <div v-for="(items, j) in scope.row.stuStates" :key="j">
                                        <div v-if="index == j">
                                            <span v-if="items == 1">X</span>
                                            <span v-else>{{ filtersType(items) }}</span>
                                        </div>
                                    </div>
			                    </template>
			                </el-table-column>
                        </template>
                    </el-table>
                    <div v-else>
                        <el-empty description="暂无数据" />
                    </div>
                    <div class="mt10 ml20" v-if="pageData.query2.totalCount">
                        <el-pagination
                          v-model:currentPage="pageData.query2.page"
                          :page-sizes="[15,20,30,50,100]"
                          :page-size="pageData.query2.limit"
                          layout="total, sizes, prev, pager, next, jumper"
                          :total="pageData.query2.totalCount"
                          @size-change="classPageSizeChange2"
                          @current-change="getCourseCycleStatis"
                        >
                        </el-pagination>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <el-dialog
                v-model="pageData.dialogVisible"
                title="调整上课时间"
                width="400px"
                :before-close="dialogClose"
              >
                <div class="p20 pt40 pb40">
                    <div class="rx-sc mb12">
			        	<span><span class="color-red">*</span>上课时间：</span>
			        	<div class="w270">
			        		<el-time-picker
                                style="width: 100%;"
			        		    v-model="pageData.time"
			        		    format="HH:mm"
                                value-format="HH:mm"
			        		    is-range
			        		    range-separator="至"
			        		    start-placeholder="开始时间"
			        		    end-placeholder="结束时间"
			        		    @change="dateChange"
			        		/>
			        	</div>
			        </div>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="dialogClose">取消</el-button>
                        <el-button type="primary" @click="adjust">确定</el-button>
                    </span>
                </template>
            </el-dialog>
            <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave">
	        	<template #btns>
                    <div v-if="confirmType == 'courseCloseBatch'">
                        <el-button size="small" @click="confirmClose">取消</el-button>
	        	        <el-button size="small" type="primary" @click="()=>{confirmSave(0)}">关闭课节</el-button>
                        <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">关闭课节并提醒会员</el-button>
                    </div>
	        	    <div v-if="confirmType == 'batchCourseDelete'">
                        <el-button size="small" @click="confirmClose">取消</el-button>
	        	        <el-button size="small" type="primary" @click="()=>{confirmSave(0)}">删除课节</el-button>
                        <el-button size="small" type="primary" @click="()=>{confirmSave(1)}">删除课节并提醒会员</el-button>
                    </div>
	        	</template>
	        </Confirm>
            <EditWork ref="editWorkRef" @close="editWorkClose" :type="13" :classType="pageData.form.cycleType" @onEditWork="confirmEditWork"></EditWork>
            <MemberRefund v-model="pageData.memberRefundShow" :memberid="pageData.memberId" :type="pageData.type" :orderid="pageData.orderId" @close="memberRefundClose" @save="memberRefundSave"></MemberRefund>
        </div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
    import { useRouter } from 'vue-router';
    import { ElMessage } from 'element-plus'
    import workArea from '@/components/branch-store/workArea.vue';
    import { Search, Edit, Plus ,EditPen} from '@element-plus/icons-vue';
    import utils from '@/common/utils.js';
    import Confirm from '@/components/Confirm.vue'
    import EditWork from '@/components/branch-store/course/editWork.vue'
    
    import { courseCloseBatch, batchCourseDelete } from '@/api/courseInfo.js';
    import { courseCycleInfo, courseList, coursegetCycleCourse, courseCycleStu, courseCycleStatis, updateCourseCustomName, courseTimeEditPlus } from '@/api/course.js'
    import MemberRefund from '@/components/branch-store/member/MemberRefund.vue';
    const editWorkRef = ref()
    const router = useRouter()
    const barlist = reactive([{ name : '返回',type:0 },{ name : '小班课详情' }]); // 面包屑
    const encryptionId = router.currentRoute.value.query.id;
    const TYPE = router.currentRoute.value.query.type;
    const confirmRef = ref();
    const multipleTableRef=ref()
    const pageData = reactive({
        memberRefundShow: false,
        orderId: '',
        memberId: '',
        type: '',
        payTypeId: '',
        form: {},
        tabName : '1',
        classTableData: [],
        query : {
            totalCount : 0,
            page: 1,
            limit: 15
        },
        multipleSelection: [],
        dialogVisible: false,
        time:[],
        memberTableData: [],
        query1: {
            totalCount : 0,
            page: 1,
            limit: 15
        },
        signTableData: [],
        query2: {
            totalCount : 0,
            page: 1,
            limit: 15
        },
    });
    function filtersType(value) {
        let text = ''
        let productTypeList = [
	    	{value: 1, label: '未知' },
	    	{value: 2, label: '不在课' },
	    	{value: 3, label: '未签到' },
	    	{value: 4, label: '已签到' },
	    	{value: 5, label: '已请假' },
	    	{value: 6, label: '候补中' },
            {value: 7, label: '缺勤中' }
	    ]
        productTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }
    /**
     * 退班
     */
    function dismissal(row) {
        pageData.orderId = row.encryptionFinanceId
		pageData.memberId = row.encryptionId
        pageData.type = 13
		pageData.memberRefundShow = true;
    }
    function memberRefundSave(){
		pageData.memberRefundShow = false;
		getCourseCycleStu();
	}
	function memberRefundClose(){
		pageData.memberRefundShow = false;
	}
    /**
     * 转让
     */
    function transfer(row) {

    }
    /**
     * 转班
     */
    function shiftTransfer(row) {

    }
    let confirmType = ref() // 全局弹框类型
    /**
     * 编辑课节
     */
    function editClass(row) {
        editWorkRef.value.getEditInfo(row.encryptionId)
		editWorkRef.value.show();
    }
    /**
     * 确定编辑
     */
    function confirmEditWork(){
        getCoursegetCycleCourse()
    }
    /**
     * 跳转课节详情
     */
    function classDetail(row) {
        router.push({
            path:'/branch/courseInfoDetail',
            query: {
                encryptionId: row.encryptionId,
                encryptionCourseNameId: row.encryptionCourseNameId
            }
        });
    }
    /**
     * 获取班级详情
     */
    function getInfo() {
        courseCycleInfo({encryptionId: encryptionId}).then((res) => {
            if (res.code == 0) {
                pageData.form = res.data
                pageData.form.cycleEndCourseTimeStr1 = res.data.cycleEndCourseTimeStr
                pageData.form.cycleStartCourseTimeStr = res.data.cycleStartCourseTimeStr.substring(0,10)
                courseList({
                    page: 1,
                    limit: 99999,
                    courseType: 13
                }).then(res => {
                    if (res.code == 0) {
                        res.data.forEach(item => {
                            if (item.courseNameId == pageData.form.courseNameId) {
                                pageData.form.courseName = item.courseName
                            }
                        })
                    }
                })
            }
        })
    }
    /**
     * 班级的全部课节
     */
    function getCoursegetCycleCourse() {
        let params = {
            encryptionId: encryptionId,
            page: pageData.query.page,
            limit: pageData.query.limit
        }
        coursegetCycleCourse(params).then(res => {
            if (res.code == 0) {
                pageData.classTableData = res.data
                pageData.query.totalCount = res.count
            }
        })
    }
    /**
     * 在班会员
     */
    function getCourseCycleStu() {
        let params = {
            encryptionId: encryptionId,
            page: pageData.query1.page,
            limit: pageData.query1.limit,
            stuStatus: 0
        }
        courseCycleStu(params).then(res => {
            if (res.code == 0) {
                pageData.memberTableData = res.data
                pageData.query1.totalCount = res.count
            }
        })
    }
    /**
     * 班级出勤详情
     */
    function getCourseCycleStatis() {
        let params = {
            encryptionId: encryptionId,
            page: pageData.query2.page,
            limit: pageData.query2.limit
        }
        courseCycleStatis(params).then(res => {
            if (res.code == 0) {
                pageData.signTableData = res.data
                pageData.query2.totalCount = res.count
            }
        })
    }
    /**
     * 切换Tab
     */
    function handleClick(tab){
        localStorage.setItem('classManageDetails', tab.props.name)
        if (tab.props.name == 1) {
            getCoursegetCycleCourse()
        } else if (tab.props.name == 2) {
            getCourseCycleStu()
        } else {
            getCourseCycleStatis()
        }
    }
    /**
     * 新增排课
     */
    function addClass() {
        router.push({ path: '/branch/addClass', query :{ id: pageData.form.encryptionId } })
    }
    /**
     * 获取选择课节数据
     */
    const checkChange = (val) => {
        pageData.multipleSelection = val
    }
    /**
     * 调整上课时间
     */
    const batchSignIn=() => {
        if (!pageData.multipleSelection.length) {
            ElMessage.warning('请先选择需要调整的课节')
            return
        } else {
            pageData.dialogVisible = true
        }
    }
    /**
     * 确定调整上课时间
     */
    function adjust() {
        if (pageData.time.length) {
            let arr = []
            pageData.multipleSelection.forEach(item => {
                arr.push(item.encryptionId)
            })
            let params = {
                ids: arr.join(),
                startTimeHour: pageData.time[0].split(':')[0],
                startTimeMinute: pageData.time[0].split(':')[1],
                endTimeHour: pageData.time[1].split(':')[0],
                endTimeMinute: pageData.time[1].split(':')[1],
            }
            courseTimeEditPlus(params).then(res => {
                if (res.code == 0) {
                    ElMessage.success('调整成功')
                    getCoursegetCycleCourse()
                    pageData.dialogVisible = false
                }
            })
            
        } else {
            ElMessage.warning('请先选择上课时间')
        }
    }
    /**
     * 取消调整上课时间
     */
    function dialogClose() {
        pageData.time = []
        pageData.dialogVisible = false
    }
    /**
     * 批量删除
     */
    function batchDelete() {
        if (!pageData.multipleSelection.length) {
            ElMessage.warning('请先选择需要删除的课节')
            return
        } else {
            pageData.confirmTitle = '确定删除课节吗？';
            pageData.confirmText = '删除后将自动为会员取消预约本节课！'
		    confirmType.value = 'batchCourseDelete';
		    confirmRef.value.show();
        }
    }
    /**
     * 批量关闭
     */
    function batchClose() {
        if (!pageData.multipleSelection.length) {
            ElMessage.warning('请先选择需要关闭的课节')
            return
        } else {
            pageData.confirmTitle = '确定关闭课节吗？';
            pageData.confirmText = '关闭后的课节不能再次开启，关闭后将自动为会员取消本节课！'
		    confirmType.value = 'courseCloseBatch';
		    confirmRef.value.show();
        }
    }
    /**
     * 添加会员
     */
    function addMember() {
        router.push({ 
            path: '/branch/classManage/addMember',
            query:{ 
                id: pageData.form.courseNameId,
                type: 0,
                cycleId: pageData.form.id,
                name: pageData.form.cycleName,
                encryptionId: pageData.form.encryptionId
            } })
    }
    /**
     * 确定提交询问框
     */
    function confirmSave(type) {
        let arr = []
        pageData.multipleSelection.forEach(item => {
            arr.push(item.encryptionId)
        })
        if (confirmType.value == 'courseCloseBatch') {
           courseCloseBatch({ encryptionCourseIds: arr.join(), type: type }).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功关闭')
                   getCoursegetCycleCourse()
                   multipleTableRef.value.clearSelection()
				   confirmRef.value.close();
                }
           })
        } else {
            batchCourseDelete({ encryptionCycleId: pageData.form.encryptionId ,idS: arr.join(), type: type }).then((res)=> {
                if (res.code == 0) {
                   ElMessage.success('成功删除')
                   getCoursegetCycleCourse()
                    multipleTableRef.value.clearSelection()
                    confirmRef.value.close();
                }
                
           })
        }
    }
	function confirmClose(){
		confirmRef.value.close();
	}

    /**
     * 修改课节主题
     */
    function preserve(row) {
        if (!row.courseCustomName) {
            ElMessage.warning('请输入课节主题')
            return
        }
        let params = {
            newCourseCustomName: row.courseCustomName,
            encryptionId: row.encryptionId
        }
        updateCourseCustomName(params).then(res => {
            if (res.code == 0) {
                ElMessage.success('编辑成功')
                getCoursegetCycleCourse()
            }
            
        })
        
    }

    //切换分页数量（课节信息）
	function classPageSizeChange(limit){
		pageData.query.limit = limit;
		getCoursegetCycleCourse();
	}
    /**
     * 记住分页标记（课节信息）
     */
    const getRowId=(row) => {
        return row.id
    }
    //切换分页数量（在班会员）
	function classPageSizeChange1(limit){
		pageData.query1.limit = limit;
		getCourseCycleStu();
	}
    //切换分页数量（考勤详情）
	function classPageSizeChange2(limit){
		pageData.query2.limit = limit;
		getCourseCycleStatis()
	}
    onMounted(()=>{
        if (localStorage.getItem('classManageDetails') != null) {
          pageData.tabName = localStorage.getItem('classManageDetails')
	    }
        getInfo()
        if (pageData.tabName == 1) {
            getCoursegetCycleCourse()
        } else if (pageData.tabName == 2) {
            getCourseCycleStu()
        } else {
            getCourseCycleStatis()
        }
        if (TYPE == 12) {
            localStorage.setItem('classManage', '1')
        }
        if (TYPE == 11) {
            localStorage.setItem('classManage', '0')
        }
    })
    onBeforeUnmount(() => {
		localStorage.removeItem('classManageDetails')
	})
</script>
<style scoped>
    .bg-FFB800 {
        color: #47545C;
    }
    .title_menu {
        background: #eef9fe;
        padding: 7px 8px;
    }
    .title_content {
        border-left: 3px solid #00a3ff;
        padding-left: 8px;
        padding-top: 2px;
        font-weight: bold;
        padding-bottom: 1px;
    }
    
</style>