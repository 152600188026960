<template>
	<workArea :barlist="pageData.barlist">
	<div class="post_container bg-gray rx-c ww100 hh100" v-loading="pageData.loading">
		<div class="post_tree hh100">
			<div class="bg-fff w250 p20 hh100">
				<h3 class="ft16 color-333 mb10">{{nowStoreData.businessName}}</h3>
				<ul class="pl10">
					<li class="color-333" :class="{'active':pageData.storeNum == index}" @click="changeStore(index)" v-for="(item,index) in pageData.organization" :key="index">{{item.storeName}}（{{item.userCount}}人）</li>
				</ul>
			</div>
		</div>
		<div class="post_list bg-fff flex1 w0 ml10 mr10 p20 hh100">
				<CommonTitle text="岗位列表"/>
				<div class="rx-sc mb12">
					<span class="mr16">岗位名称</span>
					<div class="mr16"><el-input v-model="pageData.postSearchForm.roleName" placeholder="输入关键字" /></div>
					<el-button type="primary" :icon="Search" @click="getRoleManage">查询</el-button>
				</div>
				<div>
					<el-table empty-text="无数据" highlight-current-row :data="pageData.postTable" stripe style="width: 100%" @current-change="clickTableTd" ref="postTableRef" :max-height="pageData.tableMaxH">
					    <el-table-column prop="roleName" label="岗位">
							<template #default="scope">
								<strong>{{ scope.row.roleName }}</strong>
							</template>
						</el-table-column>
					    <el-table-column prop="userCount" label="数量"/>
					    <el-table-column prop="remark" label="描述" show-overflow-tooltip />
					    <el-table-column label="操作" width="150">
							<template #default="scope">
								<div v-if="scope.row.roleType != 10 && scope.row.roleType != 13">
									<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="addAndupdate(scope.row)">编辑</a>
									<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="setPower(scope.row)">权限</a>
									<a href="javascript:;" class="t_d_u color-F96667 tool_btn_r" @click="delePost(scope.row)">删除</a>
								</div>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="rx-cc mt30" v-if="pageData.organization.length > 0">
					<el-button type="primary" :icon="Plus" @click="addAndupdate(1)">新增岗位</el-button>
					<el-button v-show="pageData.organization[pageData.storeNum].ifBranchStore == 1" :icon="CopyDocument" @click="copyPost">复制岗位</el-button>
				</div>
		</div>
		<div class="post_people bg-fff w450 p20 pb20 hh100">
				<CommonTitle text="岗位成员"/>
				<div class="rx-sc mb12">
					<span class="mr16 w60">员工名称</span>
					<div class="mr16"><el-input v-model="pageData.staffSearchForm.realName" placeholder="输入关键字" /></div>
					<el-button type="primary" :icon="Search" @click="getStaffByPost">查询</el-button>
				</div>
				<div>
					<el-table empty-text="无数据" :data="pageData.staffTable" stripe style="width: 100%"  :max-height="pageData.tableMaxH+50">
					    <el-table-column prop="realName" label="员工姓名" show-overflow-tooltip />
					    <el-table-column prop="stallPhone" label="手机号" />
						<el-table-column label="员工类型">
							<template #default="scope">
								<span>{{scope.row.ifFullTime == 1 ? '全职' : scope.row.ifFullTime == 2 ? '兼职':'未知'}}</span>
							</template>
						</el-table-column>
					</el-table>
				</div>
		</div>
		<el-drawer
		    v-model="pageData.roleMenu.show"
		    direction="rtl"
			:size="410"
		    :before-close="()=>{pageData.roleMenu.show = false}"
		  >
		  	<template #title>
    		  <h4 class="ft18 fw400 color-333 pt5 pb5">{{ pageData.roleMenu.title }}</h4>
    		</template>
			<div class="tree_box hh100 pb100 o_a">
				<div v-if="pageData.roleMenu.title == '复制岗位'">
					<CommonTip v-if="pageData.roleMenu.title == '复制岗位'" text="可选择复制其他门店岗位到本店，复制后需设置岗位对应权限"/>
					<div class="tree_item pl20 pr20 pb20 mb20" v-for="(item,index) in pageData.treeData" :key="index">
						<el-tree
							ref="tree"
						    :data="item"
							default-expand-all
						    show-checkbox
							:props="{label:'menuName'}"
						    node-key="id"
							@check-change="(val,check)=>{changeChecked(val,check,index)}"
						    :default-checked-keys="pageData.treeChecked[index]"
						/>
					</div>
				</div>
				<div v-else>
					<el-tree
						ref="treeRef"
					    :data="pageData.treeData"
						default-expand-all
					    show-checkbox
						:props="{label:'menuName', id: id, children: children}"
					    node-key="id"
					/>
				</div>
				
			</div>
			<div class="drawer_btn">
				<el-button @click="pageData.roleMenu.show = false">取消</el-button>
				<el-button type="primary" @click="savePower">确定</el-button>
			</div>
		</el-drawer>
		<!-- 新增/编辑岗位 -->
		<el-dialog
		    v-model="pageData.updatePost.show"
		    :title="pageData.updatePost.title"
			width="400px"
		    :before-close="()=>{pageData.updatePost.show = false}"
		  >
		    <div class="pl30 pt20 pb20">
				<div class="rx-sc mb12">
					<span class="mr10 w80 t_a_r fwb"><span class="color-red">*</span>岗位名称:</span>
					<div class="w200">
						<el-input v-model="pageData.updatePost.form.roleName" maxlength="15" placeholder="请输入岗位名称" />
					</div>
				</div>
				<div class="rx-ss mb12">
					<span class="mr10 w80 t_a_r fwb">岗位备注:</span>
					<div class="w200">
						<el-input :rows="5" type="textarea" v-model="pageData.updatePost.form.remark"  maxlength="30" show-word-limit placeholder="请输入岗位备注" />
					</div>
				</div>
				<div class="rx-ss">
					<span class="mr10 w80 t_a_r mt5 fwb">授课权限:</span>
					<div v-if="pageData.sysVersion == 1" class="w300 rx-wsc">
						<el-checkbox class="w90" v-model="pageData.ifTeaCourse" label="可授班课" />
						<el-checkbox class="w90" v-model="pageData.ifTeaOne" label="可授一对一" />
					</div>
					<div v-if="pageData.sysVersion == 2" class="w300 rx-wsc">
						<el-checkbox class="w90" v-model="pageData.ifRunTeam">
							<span class="color-666 fw400">可授团体课</span>
						</el-checkbox>
						<el-checkbox class="w90" v-model="pageData.ifRunPrivate">
							<span class="color-666 fw400">可授私教课</span>
						</el-checkbox>
						<el-checkbox class="w90" v-model="pageData.ifRunCourse">
							<span class="color-666 fw400">可授班课</span>
						</el-checkbox>
					</div>
				</div>
			</div>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button @click="pageData.updatePost.show = false">取消</el-button>
		        <el-button type="primary" @click="postSave">确定</el-button>
		      </span>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="delePostSave"/>
		
	</div>
	</workArea>
</template>
<script setup>
	import {ref,reactive,onMounted, nextTick} from 'vue';
	import { ElMessage,ElMessageBox} from 'element-plus'
	import {Search,Plus,CopyDocument} from '@element-plus/icons-vue'
	import CommonTip from '../../components/CommonTip.vue'
	import CommonTitle from '../../components/CommonTitle.vue'
	import Confirm from '../../components/Confirm.vue'
	import {roleManage,roleUserList,getStoreFramework,userRoleInsert,userRoleUpdate,userRoleDelete,menuToRole,userRoleMenuInsert,roleCopyList,roleCopySave} from '../../api/post.js';
	import {getLocalStorage} from '../../common/common.js';
	import workArea from '@/components/branch-store/workArea.vue';
	const nowStoreData = getLocalStorage('businessNowStoreData');
	//组织架构集合
	const treeRef = ref()
	const pageData = reactive({
		barlist:[{ name : '首页' },{ name : '组织架构' },{ name : '岗位管理' }],
		sysVersion:nowStoreData['sysVersion'],
		tableMaxH:window.innerHeight-320,
		loading:true,
		confirmTitle:'',
		confirmText:'',
		organization:[], //组织架构集合
		postSearchForm:{ //岗位搜索项
			roleName:'',
			ifBranchStore:1,
			encryptionId:''
		},
		staffSearchForm:{ //员工搜索项
			realName:'',
			encryptionId:''
		},
		storeNum:0, //选中的组织架构
		currentPost:{},// 当前岗位
		postTable:[], //岗位table数据
		staffTable:[],//员工table数据
		updatePost:{  //新增员工、编辑员工
			show:false,
			title:'新增岗位',
			form:{
				encryptionId:'',
				ifBranchStore:'',
				roleName:'',
				remark:'',
			}
		},
		roleMenu:{   //岗位权限弹窗状态
			title:'设置[大区经理]的岗位权限',
			show:false
		},
		treeData:[] ,//岗位权限树
		treeChecked:[],
		nowTd:'',//当前点击的td
		ifRunTeam:0, // 运动版，授课权限，团体
		ifRunPrivate:0,// 运动版，授课权限，私教
		ifRunCourse:0,// 运动版，授课权限，班课
		ifTeaOne:0,// 教务版，授课权限，一对一
		ifTeaCourse:0// 教务版，授课权限，班课

	})
	//获取组织架构
	getStoreFramework().then((res)=>{
		if(res.data && res.data.length > 0){
			pageData.organization = res.data;
			changeStore(0);
		}
	})
	// 切换分店
	function changeStore(num){
		pageData.storeNum = num;
		pageData.postSearchForm.roleName = '';
		getRoleManage();
	}
	//获取岗位
	const postTableRef = ref();
	function getRoleManage(){
		pageData.postSearchForm.encryptionId = pageData.organization[pageData.storeNum].encryptionId;
		pageData.postSearchForm.ifBranchStore = pageData.organization[pageData.storeNum].ifBranchStore;
		roleManage(pageData.postSearchForm).then((res)=>{
			if(res.code == 0){
				pageData.postTable = res.data || [];
				pageData.staffSearchForm.roleName = '';
				if(res.data && res.data.length > 0){
					pageData.currentPost = res.data[0];
					postTableRef.value.setCurrentRow(res.data[0]);
				}
			}
		})
	}
	//切换岗位
	function clickTableTd(val){
		pageData.staffSearchForm.roleName = '';
		pageData.currentPost = val;
		if(val){
			getStaffByPost();
		}
	}
	
	//获取员工
	function getStaffByPost(){
		var currentPost = pageData.currentPost || {};
		if(!currentPost['encryptionId']){
			ElMessage({
				type: 'warning',
				message: '请选择岗位名称',
			})
			return;
		}
		pageData.staffSearchForm.encryptionId = currentPost['encryptionId'];
		pageData.staffTable = [];
		roleUserList(pageData.staffSearchForm).then((res)=>{
			pageData.loading = false;
			if(res.code == 0 && res.data){
				pageData.staffTable = res.data || [];
			}
		})
	}
	//新增、编辑岗位
	function addAndupdate(row){
		pageData.updatePost.title = row != 1 ? '编辑岗位' : '新增岗位';
		pageData.updatePost.form.roleName = row != 1 ? row.roleName :'';
		pageData.updatePost.form.remark = row != 1 ? row.remark :'';
		pageData.updatePost.form.encryptionId = row != 1 ? row.encryptionId :'';
		pageData.ifRunTeam = row.ifRunTeam == 1 ? true : false;
		pageData.ifRunPrivate = row.ifRunPrivate == 1 ? true : false;
		pageData.ifRunCourse = row.ifRunCourse == 1 ? true : false;
		pageData.ifTeaOne = row.ifTeaOne == 1 ? true : false;
		pageData.ifTeaCourse = row.ifTeaCourse == 1 ? true : false;
		pageData.updatePost.show = true;
	}
	//提交新增、编辑岗位
	function postSave(){
		if(!pageData.updatePost.form.roleName){
			ElMessage({
				type: 'warning',
				message: '请输入岗位名称',
			})
			return false;
		}
		pageData.updatePost.form.ifRunTeam = pageData.ifRunTeam ? 1 : 0;
		pageData.updatePost.form.ifRunPrivate = pageData.ifRunPrivate ? 1 : 0;
		pageData.updatePost.form.ifRunCourse = pageData.ifRunCourse ? 1 : 0;
		pageData.updatePost.form.ifTeaOne = pageData.ifTeaOne ? 1 : 0;
		pageData.updatePost.form.ifTeaCourse = pageData.ifTeaCourse ? 1 : 0;
		if(pageData.updatePost.title == '新增岗位'){
			pageData.updatePost.form.encryptionId = pageData.postSearchForm.encryptionId;
			pageData.updatePost.form.ifBranchStore = pageData.postSearchForm.ifBranchStore;
			userRoleInsert(pageData.updatePost.form).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.updatePost.show = false;
					getRoleManage();
				}
			})
		}else{
			var form = pageData.updatePost.form;
			delete form.ifBranchStore;
			userRoleUpdate(form).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.updatePost.show = false;
					getRoleManage();
				}
			})
		}
	}
	const confirmRef = ref();
	//删除岗位
	function delePost(row){
		pageData.nowTd = row;
		pageData.confirmTitle = '确认删除该岗位吗？';
		pageData.confirmText = '';
		confirmRef.value.show();
	}
	function delePostSave(){
		userRoleDelete({
			encryptionId:pageData.nowTd.encryptionId
		}).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '删除成功',
				})
				getRoleManage();
			}
		})
	}
	//岗位权限
	function setPower(row){
		pageData.roleMenu.show = true;
		pageData.nowTd = row;
		pageData.treeChecked.length = 0;
		menuToRole({
			encryptionId:row.encryptionId
		}).then((res)=>{
			pageData.treeChecked.push(res.remark2 || []);
			if (pageData.roleMenu.title == '复制岗位') {
				pageData.treeData.length = 0
				pageData.treeData.push(res.data);
			} else {
				pageData.treeData = []
				pageData.treeData = res.data
				res.remark2.forEach((item) => {
					nextTick(() => {
						var node = treeRef.value.getNode(item)
						if(node.isLeaf){
						 	treeRef.value.setChecked(node, true);
						}
					})  
            	})
			}
			
			
		})
		pageData.roleMenu.title = '设置【'+row.roleName+'】的岗位权限'
		
	}
	//树组件勾选复选框
	function changeChecked(val,isCheck,index){
		if(!(val.id < 0)){ //判断小于0不放进去  这里注意不能写成 >0 id是传字符也不大于0
			pageData.treeChecked[index].push(val.id);
		}else{
			var checkIndex = pageData.treeChecked[index].indexOf(val.id);
			if(checkIndex > -1){
				pageData.treeChecked[index].splice(checkIndex,1);
			}
		}
	}
	//保存权限编辑
	function savePower(){
		if(pageData.roleMenu.title == '复制岗位'){
			var newIds = [];
			pageData.treeChecked.map((n,i)=>{
				newIds = [...newIds,...n];
			})
			if(newIds.length == 0){
				ElMessage({
					type: 'warning',
					message: '请选择要复制岗位',
				})
				return false;
			}
			roleCopySave({
				encryptionId:pageData.organization[pageData.storeNum].encryptionId,
				roleId:newIds.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.roleMenu.show = false;
					getRoleManage();
				}
			})
		}else{
			let arry = []
			treeRef.value.getCheckedNodes(false, true).forEach((item) => {
				arry.push(item.id)
			})
			if(arry.length == 0){
				ElMessage({
					type: 'warning',
					message: '请选择权限',
				})
				return false;
			}
			userRoleMenuInsert({
				encryptionId:pageData.nowTd.encryptionId,
				menu_ids:arry.join(',')
			}).then((res)=>{
				if(res.code == 0){
					ElMessage({
						type: 'success',
						message: '保存成功',
					})
					pageData.roleMenu.show = false;
				}
			})
		}
	}
	//复制岗位
	function copyPost(){
		if(pageData.organization[pageData.storeNum].ifBranchStore == 0){
			ElMessage({
				type: 'warning',
				message: '总店无法复制岗位',
			})
			return false;
		}
		pageData.treeData.length = 0;
		pageData.treeChecked.length = 0;
		pageData.roleMenu.title = '复制岗位';
		roleCopyList({
			encryptionId:pageData.organization[pageData.storeNum].encryptionId,
		}).then((res)=>{
			if(res.code == 0){
				res.data.map((n,i)=>{
					var obj = {
						id:'-'+(i+1),
						menuName:n.storeName,
						isParent:1,
						children:[]
					}
					if(n.children && n.children.length > 0){
						n.children.map((j,k)=>{
							obj.children.push({
								id:j.id,
								isParent:0,
								menuName:j.roleName,
								children:[]
							})
						})
					}
					pageData.treeData.push([obj]);
					pageData.treeChecked.push([]);
				})
				
			}
		})
		pageData.roleMenu.show = true;
	}
</script>

<style scoped lang="stylus">
	.post_container{
		.post_tree{
			li{
				border-left 2px dashed #DFEDF3;
				border-bottom 2px dashed #DFEDF3;
				padding 20px 0 10px 10px;
				position relative;
				cursor pointer;
			}
			li.active{
				color #409EFF;
				font-weight bold;
			}
			li:hover{
				color #409EFF;
				font-weight bold;
			}
			li.active::after{
				content '';
				position absolute;
				right -5px;
				top 50%;
				width: 0;
				height: 0;
				border-top 4px solid transparent;
				border-left 8px solid #409EFF;
				border-bottom 4px solid transparent;
			}
		}
		.tree_box{
			.tree_item:not(:last-child){
				border-bottom 1px dashed #ccc;
			}
		}
	}
</style>
