import {createRouter,createWebHashHistory} from 'vue-router';
import {getUrlQuery} from '../common/common.js';


// 通用页面
import Login from '../views/login.vue';           // 登录
import Store from '../views/store.vue';           // 选店页面
import WeChart from '../views/weChart.vue';       // 扫码登录，授权页面
import WeChartPre from '../views/weChartPre.vue';       // 扫码登录，授权页面
import NotFound from '../views/404.vue';          // 404页面
// 总店页面
import Home from '@/views/main-store/home.vue';             // 主页
import DataCenter from '../views/main-store/dataCenter.vue'; // 数据中心
import VipCard from '../views/main-store/vipCard.vue';       // 会员卡管理
import UpdateCourseCard from '../views/main-store/cards/UpdateCourseCard.vue';       // 课程卡新增
import UpdateEntranceCard from '../views/main-store/cards/UpdateEntranceCard.vue';       // 入场卡新增
import UpdateStoreCard from '../views/main-store/cards/UpdateStoreCard.vue';       // 储值卡新增
import UpdateExperienceCard from '../views/main-store/cards/UpdateExperienceCard.vue';       // 体验卡新增
import CardDetail from '../views/main-store/cards/CardDetail.vue';       // 卡项详情
import Course from '../views/main-store/Course.vue';       // 课程管理
import AddUpdateCourse from '../views/main-store/course/AddUpdateCourse.vue';       // 课程添加、编辑
import CourseDetail from '../views/main-store/course/CourseDetail.vue';       // 课程详情
import Post from '../views/main-store/post.vue';             // 岗位管理
import Staff from '../views/main-store/staff.vue';           // 员工管理
import StoreInfo from '../views/main-store/storeInfo.vue';   // 店铺信息
import GatherData from '../views/main-store/gatherData.vue';   // 收银台数据
import Applet from '../views/main-store/applet.vue';      // 小程序配置
import WechatAccount from '../views/main-store/wechatAccount.vue';      // 小程序配置
import DownloadList from '../views/main-store/downloadList.vue'; // 下载列表
// 分店页面
import BranchHome from '@/views/branch-store/home.vue';
import BranchDataPreview from '@/views/branch-store/dataPreview.vue';    
import BranchGoodsList from '@/views/branch-store/goods/goodsList.vue';   
import BranchMemberList from '@/views/branch-store/member/memberList.vue';     
import BranchMemberDetail from '@/views/branch-store/member/memberDetail.vue';   
import BranchReception from '@/views/branch-store/reception.vue'; 
import BranchReceptionRecord from '@/views/branch-store/reception/receptionRecord.vue'; // 入离场记录
import BranchNoticeList from '@/views/branch-store/reception/noticeList.vue'; // 公告列表
import BranchAgentOrder from '@/views/branch-store/reception/agentOrder.vue'; // 场次预订
import BranchNoticeDetail from '@/views/branch-store/reception/noticeDetail.vue'; // 公告详情
import BranchIntentionList from '@/views/branch-store/member/intentionList.vue';   
import BranchCourseTable from '@/views/branch-store/work/courseTable.vue';   
import BranchPrivateWorkTime from '@/views/branch-store/work/courseTable/privateWorkTime.vue';   
import BranchCards from '@/views/branch-store/work/cards.vue';   
import BranchCourseInfoAdd from '@/views/branch-store/work/courseInfoAdd.vue';
import BranchCourseInfoDetail from '@/views/branch-store/work/courseInfoDetail.vue';
import BranchCourseCard from '@/views/branch-store/work/cards/UpdateCourseCard.vue';       // 课程卡新增
import BranchEntranceCard from '@/views/branch-store/work/cards/UpdateEntranceCard.vue';       // 入场卡新增
import BranchStoreCard from '@/views/branch-store/work/cards/UpdateStoreCard.vue';       // 储值卡新增
import BranchExperienceCard from '@/views/branch-store/work/cards/UpdateExperienceCard.vue';       // 体验卡新增
import BranchCardDetail from '@/views/branch-store/work/cards/CardDetail.vue';       // 卡项详情
import BranchCourseName from '@/views/branch-store/work/courseName.vue';
import BranchAddUpdateCourse from '@/views/branch-store/work/courseName/AddUpdateCourse.vue';       // 课程添加、编辑
import BranchCourseDetail from '@/views/branch-store/work/courseName/CourseDetail.vue';       // 课程详情
import BranchClassManage from '@/views/branch-store/work/classManage.vue';       // 班级管理
import BranchClassManageDetails from '@/views/branch-store/work/classManage/details.vue';       // 班级详情
import BranchClassManageAddMember from '@/views/branch-store/work/classManage/addMember.vue';       // 班级详情
import BranchClassManageSelectClassPage from '@/views/branch-store/work/classManage/selectClassPage.vue';       // 新增小班课类型选择页
import BranchClassManageAddClassPage from '@/views/branch-store/work/classManage/addClassPage.vue';  // 添加班级页面
import BranchAddClass from '@/views/branch-store/work/courseTable/addClass.vue';

import BranchAppointment from '@/views/branch-store/work/appointment.vue';   
import BranchConsume from '@/views/branch-store/work/consume.vue';
import BranchClassSection from '@/views/branch-store/work/consume/classSection.vue';  // 有效课节详情
import BranchMagic from '@/views/branch-store/magic/magic.vue';   
import BranchSale from '@/views/branch-store/finance/sale.vue';
import BranchOrderDetail from '@/views/branch-store/finance/orderDetail.vue'; // 销售记录/订单详情
import BranchWaterflow from '@/views/branch-store/finance/waterflow.vue';  
import BranchOwn from '@/views/branch-store/finance/own.vue'; 
import BranchCollectionCode from '@/views/branch-store/finance/collectionCode.vue'; 
import BranchWorkData from '@/views/branch-store/datacenter/workData.vue';   
import BranchSaleData from '@/views/branch-store/datacenter/saleData.vue';    
import BranchFinanceData from '@/views/branch-store/datacenter/financeData.vue';
import BranchOrderCenter from '@/views/branch-store/cashier/orderCenter.vue';    
import BranchBalanceCenter from '@/views/branch-store/cashier/balanceCenter.vue';   
import BranchStoreInfo from '@/views/branch-store/store/storeInfo.vue';     
import BranchEmploy from '@/views/branch-store/store/employ.vue';
import BranchPlace from '@/views/branch-store/store/place.vue'; // 机构中心场地管理
import BranchAppstore from '@/views/branch-store/appstore.vue';  // 应用中心
import BranchAppstorePlace from '@/views/branch-store/appstore/place.vue';  // 应用中心-场地管理
import BranchAppstoresettingOrder from '@/views/branch-store/appstore/settingOrder.vue';  // 应用中心-预约设置
import BranchBaseInfo from '@/views/branch-store/store/baseInfo.vue';
import BranchDownloadList from '@/views/branch-store/downloadList.vue'; // 下载列表
import BranchPayCourseMoney from '@/views/branch-store/payCourseMoney.vue'; // 下载列表



const routes = [
    { path:'/', redirect:'/login' },
    { path:'/login', name:'login', component:Login },
    { path:'/weChart', name:'weChart', component:WeChart },
    { path:'/weChartPre', name:'weChartPre', component:WeChartPre },
    { path:'/store',name:'store',component:Store},
    { path:'/branch-store/home',
      name:'branchHome',
      component:BranchHome,
      redirect:'/branch/reception',
      children:[
        { path:'/branch/dataPreview',component:BranchDataPreview},
        { path:'/branch/reception',component:BranchReception},
        { path:'/branch/appstore',component:BranchAppstore},
        { path:'/branch/appstore/place',component:BranchAppstorePlace},
        { path:'/branch/appstore/settingOrder',component:BranchAppstoresettingOrder},
        { path:'/branch/receptionRecord',component:BranchReceptionRecord},
        { path:'/branch/noticeList',component:BranchNoticeList},
        { path:'/branch/agentOrder',component:BranchAgentOrder},
        { path:'/branch/noticeDetail',component:BranchNoticeDetail},
        { path:'/branch/goodsList',component:BranchGoodsList},
        { path:'/branch/memberList',component:BranchMemberList},
        { path:'/branch/memberDetail',component:BranchMemberDetail},
        { path:'/branch/intentionList',component:BranchIntentionList},
        { path:'/branch/courseTable',component:BranchCourseTable},
        { path:'/branch/privateWorkTime',component:BranchPrivateWorkTime},
        { path:'/branch/cards',component:BranchCards},
        { path:'/branch/courseCard',component:BranchCourseCard},
        { path:'/branch/entranceCard',component:BranchEntranceCard},
        { path:'/branch/experienceCard',component:BranchExperienceCard},
        { path:'/branch/storeCard',component:BranchStoreCard},
        { path:'/branch/cardDetail',component:BranchCardDetail},
        { path:'/branch/courseName',component:BranchCourseName},
        { path:'/branch/classManage',component:BranchClassManage},
        { path:'/branch/classManage/details',component:BranchClassManageDetails},
        { path:'/branch/classManage/addMember',component:BranchClassManageAddMember},
        { path:'/branch/classManage/selectClassPage',component:BranchClassManageSelectClassPage},
        { path:'/branch/classManage/addClassPage',component:BranchClassManageAddClassPage},
        { path:'/branch/addClass',component:BranchAddClass},
        
        { path:'/branch/addUpdateCourse',component:BranchAddUpdateCourse},
        { path:'/branch/courseDetail',component:BranchCourseDetail},
        { path:'/branch/courseInfoAdd',component:BranchCourseInfoAdd},
        { path:'/branch/courseInfoDetail',component:BranchCourseInfoDetail},
        { path:'/branch/appointment',component:BranchAppointment},
        { path:'/branch/consume',component:BranchConsume},
        { path:'/branch/magic',component:BranchMagic},
        { path:'/branch/sale',component:BranchSale},
        { path:'/branch/orderDetail',component:BranchOrderDetail},
        { path:'/branch/waterflow',component:BranchWaterflow},
        { path:'/branch/own',component:BranchOwn},
        { path:'/branch/workData',component:BranchWorkData},
        { path:'/branch/saleData',component:BranchSaleData},
        { path:'/branch/financeData',component:BranchFinanceData},
        { path:'/branch/collectionCode',component:BranchCollectionCode},
        { path:'/branch/orderCenter',component:BranchOrderCenter},
        { path:'/branch/balanceCenter',component:BranchBalanceCenter},
        { path:'/branch/storeInfo',component:BranchStoreInfo},
        { path:'/branch/employ',component:BranchEmploy},
        { path:'/branch/place',component:BranchPlace},
        { path:'/branch/baseInfo',component:BranchBaseInfo},
        { path:'/branch/classSection',component:BranchClassSection},
        { path:'/branch/downloadList',component:BranchDownloadList},
        { path:'/branch/payCourseMoney',component:BranchPayCourseMoney},
        
      ]
    },
    { path:'/main-store/home',
      name:'home',
      component:Home,
      redirect:'/dataCenter',
      children:[
        { path:'/dataCenter',name:'dataCenter',component:DataCenter},
        { path:'/post',name:'post',component:Post},
        { path:'/staff',name:'staff',component:Staff},
        { path:'/gatherData',name:'gatherData',component:GatherData},
        { path:'/storeInfo',name:'storeInfo',component:StoreInfo},
	      { path:'/vipCard', name:'vipCard', component:VipCard },
	      { path:'/cardDetail', name:'cardDetail', component:CardDetail },
	      { path:'/updateCourseCard', name:'updateCourseCard', component:UpdateCourseCard },
	      { path:'/updateEntranceCard', name:'updateEntranceCard', component:UpdateEntranceCard },
	      { path:'/updateStoreCard', name:'updateStoreCard', component:UpdateStoreCard },
	      { path:'/updateExperienceCard', name:'updateExperienceCard', component:UpdateExperienceCard },
	      { path:'/course', name:'course', component:Course },
	      { path:'/addUpdateCourse', name:'addUpdateCourse', component:AddUpdateCourse },
	      { path:'/courseDetail', name:'courseDetail', component:CourseDetail },
        { path:'/applet',name:'applet',component:Applet},
        { path:'/wechatAccount',name:'wechatAccount',component:WechatAccount},
        { path:'/downloadList',name:'downloadList',component:DownloadList},
      ]
    },
    { path:'/:pathMatch(.*)*', name:'notFound', component:NotFound }
]
const router = createRouter({
    history:createWebHashHistory(),
    routes
})
export default router