<template>
    <div class="order mt16" v-loading="pageData.loading" element-loading-text="正在努力加载中。。。">
        <div class="order_nav">
            <!-- <el-radio-group v-model="pageData.tabNum" size="large" @change="getContentList">
                <el-radio-button v-for="(item, index) in pageData.tabeList" :key="index" :label="item.val">{{ item.label }}</el-radio-button>
            </el-radio-group> -->
            <span class="d_i_b c_p" :class="pageData.tabNum == item.val ? 'active': '' " v-for="(item, index) in pageData.tabeList" :key="index" @click="getContentList(item.val)">{{ item.label }}</span>
        </div>
        <div class="mt20 w800">
            <el-card class="box-card mb25" shadow="never">
                <div class="rx-bc">
                   <h4 class="ft16 pb10">{{ pageData.tabNum == 11 ? '会员预约规则' : pageData.tabNum == 12 ? '会员预约限制' : '会员自主预约模式限制'}}</h4>
                   <el-button type="primary" :icon="EditPen" plain @click="rouseDialog(1)" v-if="utils.getMenuRole(3320001)">编辑</el-button>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 11 || pageData.baseInfo.courseType == 13">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>预约开始时间</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员在课程开始前 
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.orderStartTime }}&nbsp;</span>
                        小时开始预约{{ pageData.baseInfo.courseType == 11 ? '团体课' : pageData.baseInfo.courseType == 12 ? '私教课' : '小班课' }},员工代预约不受此限制。
                    </p>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 11 || pageData.baseInfo.courseType == 13">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>预约截止时间</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员在课程开始前
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.orderEndTime }}&nbsp;</span>
                        小时将不可再预约,员工代取消不受此限制
                    </p>
                </div>
                 <div class="pt25" v-if="pageData.baseInfo.courseType == 11">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>满员开课</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        截止课前
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.fullPeopleFlag ? pageData.baseInfo.fullCancelTime : '-' }}&nbsp;</span>
                        分钟未满足
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.fullPeopleFlag ? pageData.baseInfo.fullPeopleNum : "-" }}&nbsp;</span>
                        人，课节将自动取消
                    </p>
                </div> 
                <div class="pt25">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>取消预约时间</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员在课程开始前
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.orderCancel ? pageData.baseInfo.orderCancelTime : '-' }}&nbsp;</span>
                        小时取消预约,员工代取消不受此限制。
                    </p>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 11">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>候补预约</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        支持会员候补预约，开课前
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.afterFlag ? pageData.baseInfo.afterFlag : '-' }}&nbsp;</span>
                        小时自动取消候补预约。
                    </p>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 12">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>可预约范围</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员最多预约
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.orderDateRange ? pageData.baseInfo.orderDateRange : '-' }}&nbsp;</span>
                        {{ pageData.baseInfo.orderDateUnit == 1 ? '天' : '周' }} 以内的私教课，员工代预约不受此限制。
                    </p>
                </div>
                <div class="pt25">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>签到设置</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        支持会员自主扫码签到、系统在课前 
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.autoSignFlag ? pageData.baseInfo.autoSignTime : '-' }}&nbsp;</span>
                        分钟自动签到。</p>
                </div>
            </el-card>
            <el-card class="box-card" shadow="never">
                <div class="rx-bc">
                   <h4 class="ft16 pb10">{{ pageData.tabNum == 11 ? '会员预约限制' : pageData.tabNum == 12 ? '预约时间间隔' : '扣费规则设置'}}</h4>
                   <el-button type="primary" :icon="EditPen" plain  @click="rouseDialog(2)" v-if="utils.getMenuRole(3320000) && pageData.tabNum != 13">编辑</el-button>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 13">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>扣费方式</h4>
                    </div>
                    <p class="color-666 ml15 mt15">预约成功即扣费</p>
                </div>
                <div class="pt25" v-if="pageData.baseInfo.courseType == 12">
                    <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>约课时间间隔</h4>
                    </div>
                    <p class="color-666 ml15 mt15">会员预约私教时间以每 <span class="text-color">&nbsp;{{ pageData.baseInfo.orderInterval }}&nbsp;</span>分钟间隔。</p>
                </div>
                <div v-if="pageData.baseInfo.courseType == 11">
                    <div class="pt25">
                        <div class="rx-c">
                        <div class="circle mr10"></div>
                        <h4>可预约范围</h4>
                    </div>
                    <p class="color-666 ml15 mt15">
                        会员最多预约
                        <span class="text-color">&nbsp;{{ pageData.baseInfo.orderDateRange ? pageData.baseInfo.orderDateRange : '-' }}&nbsp;</span>
                        {{ pageData.baseInfo.orderDateUnit == 1 ? '天' : '周' }} 以内的团体课，员工代预约不受此限制。
                    </p>
                    </div>
                    <div class="pt25">
                        <div class="rx-c">
                            <div class="circle mr10"></div>
                            <h4>预约人数上限</h4>
                        </div>
                        <p class="color-666 ml15 mt15">允许会员每节课预约 <span class="text-color">&nbsp;{{ pageData.baseInfo.orderPeopleNum }}&nbsp;</span>人。</p>
                    </div>
                </div>
            </el-card>
        </div>
        <el-dialog
            v-model="pageData.dialogVisible"
            :title="pageData.title"
            :width="pageData.width"
            :before-close="handleClose"
          >
            <div class="mt25 ml30 dialog_form">
                <el-form v-if="pageData.cardType == 1" ref="formRef" :model="form" label-width="120px">
                    <el-form-item v-if="pageData.tabNum == 11 || pageData.tabNum == 13" label="预约开始时间:">
                        <span>会员需课前&nbsp;</span>
                        <el-input class="w115 t_a_c" v-model="form.orderStartTime" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>小时</template>
                        </el-input>
                        <span>&nbsp;预约{{ pageData.tabNum == 11 ? '团体课' : pageData.tabNum == 12 ? '私教课' : '小班课' }}，员工代预约不受此限制</span>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 11 || pageData.tabNum == 13" label="预约截止时间:">
                        <span>会员将在课前&nbsp;</span>
                        <el-input class="w115 t_a_c" v-model="form.orderEndTime" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>小时</template>
                        </el-input>
                        <span>&nbsp;不可预约{{ pageData.tabNum == 11 ? '团体课' : pageData.tabNum == 12 ? '私教课' : '小班课' }}，员工代预约不受此限制</span>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 11 || pageData.tabNum == 13" label="是否满员开课:">
                        <el-radio-group v-model="form.fullPeopleFlag">
                            <el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
                            <el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
                        </el-radio-group>
                        <div v-if="form.fullPeopleFlag">
                            <span>课节满足&nbsp;</span>
                            <el-input class="w115 t_a_c" v-model="form.fullPeopleNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                <template #append>人</template>
                            </el-input>
                            <span>&nbsp;开课，截止课前&nbsp;</span>
                            <el-input class="w115 t_a_c" v-model="form.fullCancelTime" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                <template #append>分钟</template>
                            </el-input>
                            <span>&nbsp; 未满足开课要求，将自动关闭课节</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="预约可以取消:">
                        <el-radio-group v-model="form.orderCancel">
                            <el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
                            <el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
                        </el-radio-group>
                        <div v-if="pageData.tabNum == 12 && form.orderCancel">
                            <span>会员需在课程开始前&nbsp;</span>
                            <el-input class="w115 t_a_c" v-model="form.orderCancelTime" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                <template #append>小时</template>
                            </el-input>
                            <span>&nbsp;取消预约，员工代取消不受此限制</span>
                        </div>
                        <div v-if="(pageData.tabNum == 11  || pageData.tabNum == 13) && form.orderCancel">
                            <div>
                                <span>取消截止时间：课前&nbsp;</span>
                                <el-input class="w115 t_a_c" v-model="form.orderCancelTime" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                    <template #append>小时</template>
                                </el-input>
                                <span>&nbsp;取消预约，员工代取消不受此限制</span>
                            </div>
                            <div class="mt10" v-show="form.fullPeopleFlag">
                                <span>满员后当课节人数大于&nbsp;</span>
                                <el-input class="w115 t_a_c" v-model="form.orderCancelPeople" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                    <template #append>人</template>
                                </el-input>
                                <span>&nbsp;时，才可取消预约</span>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 12" label="可预约范围:">
                        <el-radio-group v-model="form.radio2">
                            <el-radio :label="0"><span class="color-666 fw400">不限制</span></el-radio>
                            <el-radio :label="1"><span class="color-666 fw400">自定义</span></el-radio>
                        </el-radio-group>
                        <div class="d_i_b ml5" v-if="form.radio2">
                            <el-input-number class="w65 v_a_m" v-model="form.orderDateRange" :min="0" :max="9999" :precision="0" :step="1" :controls="false" />
                            <el-select class="v_a_m form_select" v-model="form.orderDateUnit">
                                <el-option label="天" value="1"></el-option>
                                <el-option label="周" value="2"></el-option>
                            </el-select>
                        </div>
                        <div v-if="form.radio2">
                            <span>会员最多可以预约{{form.orderDateRange}}{{ form.orderDateUnit == 1 ? '天' : '周' }}内的私教课，员工代预约不受此限制</span>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 11 || pageData.tabNum == 13" label="是否候补预约">
                        <el-radio-group v-model="form.afterFlag">
                            <el-radio :label="0"><span class="color-666 fw400">否</span></el-radio>
                            <el-radio :label="1"><span class="color-666 fw400">是</span></el-radio>
                        </el-radio-group>
                        <div v-if="form.afterFlag">
                            <span>候补人数上限&nbsp;</span>
                            <el-input class="w115 t_a_c" v-model="form.afterNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                                <template #append>人</template>
                            </el-input>
                            <span>&nbsp;开课前1小时候补不成功，将自动取消预约）</span>
                        </div>
                    </el-form-item>
                    <el-form-item label="签到设置:">
                        <el-checkbox v-model="form.stuSignFlag"><span class="color-666 fw400">支持会员自主扫码签到</span></el-checkbox>
                        <el-checkbox v-model="form.autoSignFlag">
                            <span class="color-666 fw400">系统自动签到</span>
                            <span class="ml15 color-666">课前&nbsp;</span>
                            <el-input class="w115 t_a_c"  style="vertical-align: middle;" v-model="form.autoSignTime" maxlength="4"  @blur="autoConsume" onkeyup="value=value.match(/^-?[0-9]\d*$/)||value.match(/-?/)">
                                <template #append>分钟</template>
                            </el-input>
                            <span class="color-666 fw400">&nbsp;系统自动签到（仅支持5的倍数）</span>
                        </el-checkbox>
                    </el-form-item>
                </el-form>
                <el-form v-if="pageData.cardType == 2" ref="formRef" :model="form" label-width="120px">
                    <el-form-item v-if="pageData.tabNum == 12" label="约课间隔:">
                        <el-select class="v_a_m form_select1" v-model="form.orderInterval">
                            <el-option label="5" value="5"></el-option>
                            <el-option label="10" value="10"></el-option>
                            <el-option label="15" value="15"></el-option>
                            <el-option label="20" value="20"></el-option>
                            <el-option label="30" value="30"></el-option>
                            <el-option label="60" value="60"></el-option>
                        </el-select>
                        <span class="append v_a_m">分钟</span>
                        <span>&nbsp;（仅支持5的倍数）</span>
                        <div>修改后会员端小程序私教课预约时间间隔会调整</div>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 11 " label="可预约范围:">
                        <el-radio-group v-model="form.radio2">
                            <el-radio :label="0"><span class="color-666 fw400">不限制</span></el-radio>
                            <el-radio :label="1"><span class="color-666 fw400">自定义</span></el-radio>
                        </el-radio-group>
                        <div class="d_i_b ml10" v-if="form.radio2">
                            <el-input-number class="w65 v_a_m" v-model="form.orderDateRange" :min="0" :max="9999" :precision="0" :step="1" :controls="false" />
                            <el-select class="v_a_m form_select" v-model="form.orderDateUnit">
                                <el-option label="天" value="1"></el-option>
                                <el-option label="周" value="2"></el-option>
                            </el-select>
                        </div>
                        <div v-if="form.radio2">
                            <span>会员最多可以预约{{form.orderDateRange}}{{ form.orderDateUnit == 1 ? '天' : '周' }}内的团体课，员工代预约不受此限制</span>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="pageData.tabNum == 11" label="预约人数上限:">
                        <span>允许会员每节课最多可预约&nbsp;</span>
                        <el-input class="w115 t_a_c" v-model="form.orderPeopleNum" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="handleClose">取消</el-button>
                    <el-button type="primary" @click="confirm">确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue'
    import { EditPen } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import { getBaseInfo, setBaseInfo } from '@/api/storeCenter.js';
    import { id } from 'element-plus/lib/locale';
    import utils from '@/common/utils.js'
    const pageData = reactive({
        loading: false,
        tabNum: 12, // teb默认选中第一个
        cardType: '', // 规则中的卡片类型
        title: '', // 弹框标题
        width: '', // 弹框宽度
        dialogVisible: false,
        tabeList: [{
            label: '私教课设置',
            val: 12
        }, {
            label: '团体课设置',
            val: 11
        }, {
            label: '小班课设置',
            val: 13
        }], // 头部tabe数组（单选伪装）
        baseInfo: {}
    })
    const getInitalForm = () => {
      return {
        orderStartTime: '',
        orderEndTime: '',
        fullPeopleFlag: 0,
        fullPeopleNum: '',
        fullCancelTime: '',
        orderCancel: 0,
        orderCancelTime: '',
        orderCancelPeople: '',
        radio2: 0,
        afterFlag: 0,
        afterNum: '',
        orderDateUnit: '1',
        orderDateRange: '',
        stuSignFlag: false,
        autoSignFlag: false,
        autoSignTime: '',
        orderInterval: '5',
        orderPeopleNum:''
      }
    }
    const form = reactive(getInitalForm())

    const resetForm = () => {
      Object.assign(form, getInitalForm())
    }
    function autoConsume(e) {
        if (e.target.value > 360) {
            e.target.value = 360
        }
        if (e.target.value < -360) {
             e.target.value = -360
        }
        if (e.target.value % 5 == 0) {
            form.value.autoSignTime = e.target.value
        } else {
            ElMessage.warning('课前签到时间仅支持5的倍数')
            form.value.autoSignTime = ''
        }
    }
    /**
     * @param (NUmber) type 1-第一个卡片、2-第二个卡片
     * 唤醒弹框
     */
    function rouseDialog(type) {
        resetForm()
        form.orderStartTime = pageData.baseInfo.orderStartTime
        form.orderEndTime = pageData.baseInfo.orderEndTime
        form.fullPeopleFlag = pageData.baseInfo.fullPeopleFlag
        form.fullPeopleNum = pageData.baseInfo.fullPeopleNum
        form.fullCancelTime = pageData.baseInfo.fullCancelTime
        form.orderCancel = pageData.baseInfo.orderCancel
        form.orderCancelTime = pageData.baseInfo.orderCancelTime
        form.orderCancelPeople = pageData.baseInfo.orderCancelPeople
        form.afterFlag = pageData.baseInfo.afterFlag
        form.afterNum = pageData.baseInfo.afterNum
        form.orderDateUnit = pageData.baseInfo.orderDateUnit + ''
        form.orderDateRange = pageData.baseInfo.orderDateRange
        form.stuSignFlag = pageData.baseInfo.stuSignFlag ? true : false
        form.autoSignFlag = pageData.baseInfo.autoSignFlag ? true : false
        form.autoSignTime = pageData.baseInfo.autoSignTime
        form.orderInterval = pageData.baseInfo.orderInterval
        form.orderPeopleNum = pageData.baseInfo.orderPeopleNum
        form.radio2 = pageData.baseInfo.orderDateRange ? 1 : 0
        pageData.cardType = type
        if (pageData.tabNum == 12) {
            pageData.title = type == 1 ? '会员预约限制' : '约课时间间隔'
        } else if (pageData.tabNum == 11) {
            pageData.title = type == 1 ? '会员预约规则' : '会员预约限制'
        } else if (pageData.tabNum == 13) {
            pageData.title = type == 1 ? '会员自主预约模式限制' : ''
        }
        pageData.width = type == 1 ? '692px' : '500px'
        pageData.dialogVisible = true
    }
    /**
     * 弹框确定修改
     */
    function confirm() {
        let params = {
            encryptionId: pageData.baseInfo.encryptionId,
            courseType: pageData.baseInfo.courseType,
        }
        if (pageData.cardType == 1) {
            params.orderCancel = form.orderCancel
            params.stuSignFlag = form.stuSignFlag ? 1 : 0
            params.autoSignFlag = form.autoSignFlag ? 1 : 0
            if (pageData.baseInfo.courseType == 11 || pageData.baseInfo.courseType == 13 ) {  // 团体课参数
                params.orderStartTime = form.orderStartTime
                params.orderEndTime = form.orderEndTime
                params.fullPeopleFlag = form.fullPeopleFlag
                if (params.fullPeopleFlag) {
                    params.fullPeopleNum = form.fullPeopleNum
                    params.fullCancelTime = form.fullCancelTime
                    if (!params.fullPeopleNum && params.fullPeopleNum != 0) {
                        ElMessage.error('请输入满员开课的人数限制')
                        return false
                    }
                    if (!params.fullCancelTime && params.fullCancelTime != 0) {
                        ElMessage.error('请输入满员开课的时间限制')
                        return false
                    }
                }
                params.afterFlag = form.afterFlag
                if (params.afterFlag) {
                    params.afterNum = form.afterNum
                    if (!params.afterNum && params.afterNum != 0) {
                        ElMessage.error('请输入候补人数上限')
                        return false
                    }
                }
                if (params.orderCancel) {
                    params.orderCancelTime = form.orderCancelTime
                    if (!params.orderCancelTime && params.orderCancelTime != 0) {
                        ElMessage.error('请输入取消预约的时间限制')
                        return false
                    }
                    if (params.fullPeopleFlag) {
                        params.orderCancelPeople = form.orderCancelPeople
                        if (!params.orderCancelPeople && params.orderCancelPeople != 0) {
                            ElMessage.error('请输入取消预约的人数限制')
                            return false
                        }
                    }
                }
            } else {  // 私教课餐宿
                if (form.radio2){
                   params.orderDateRange = form.orderDateRange
                   params.orderDateUnit = form.orderDateUnit
                   if (!form.orderDateRange && form.orderDateRange != 0) {
                      ElMessage.error('请输入可预约范围的时间限制')
                      return false
                   }
                } else {
                   params.orderDateRange = 0
                }
            }
            if (params.orderCancel) {
                params.orderCancelTime = form.orderCancelTime
                if (!params.orderCancelTime && params.orderCancelTime != 0) {
                    ElMessage.error('请输入取消预约的时间限制')
                    return false
                }
            }
            if (params.autoSignFlag) {
                params.autoSignTime = form.autoSignTime
                if (params.autoSignTime > 360 || params.autoSignTime < -360 ) {
                    ElMessage.error('系统自动签到时间应在-360至360之间')
                    return false
                }
                if (!params.autoSignTime&&params.autoSignTime!=0) {
                    ElMessage.error('请输入系统自动签到时间限制')
                    return false
                }
            }
            
        } else {  // 下面卡片参数
            if (pageData.baseInfo.courseType == 11 ) {  // 团体课参数
               params.orderPeopleNum = form.orderPeopleNum
               if (form.radio2){
                   params.orderDateRange = form.orderDateRange
                   params.orderDateUnit = form.orderDateUnit
                   if (!form.orderDateRange && form.orderDateRange != 0) {
                      ElMessage.error('请输入可预约范围的时间限制')
                      return false
                   }
                } else {
                   params.orderDateRange = 0
                }
            } else { 
              params.orderInterval = form.orderInterval
            }
        }
        setBaseInfo(params).then((res) => {
            if (res.code == 0) {
               ElMessage.success('编辑成功')
               getInfo(pageData.baseInfo.courseType)
               pageData.dialogVisible = false
            }
        })
    }
    /**
     * 取消关闭弹框
     */
    function handleClose() {
        pageData.dialogVisible = false
    }
    /**
     * 切换tab(更换数据)
     */
    function getContentList(val) { // 切换tab (数据更换)
        pageData.tabNum = val
        getInfo(pageData.tabNum)
    }
    /**
     * @param (Number) type 11-团体课;12-私教课
     * 获取预约设置信息
     */
    function getInfo(type) {
        let params = {
            courseType: type
        }
        pageData.loading = true
        getBaseInfo(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.baseInfo = res.data
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    onMounted(() => {
        getInfo(12)
    })
</script>

<style type="text/scss" scoped>
    .order_nav {
        background: #F2F2F2
    }
.order_nav span{
    padding: 10px 20px 11px 18px;
    color: #666;
}
.order_nav .active {
    background: #03A3FF;
    color: #FFF;
    border-radius: 2px;
}
    .circle {
        width: 8px;
        height: 8px;
        border: 2px solid #CCC;
        border-radius: 50%;
    }
    .text-color {
        color: #FA0008
    }
    .dialog_form {
        min-height: 280px;
    }
    .dialog_form /deep/ .el-form-item__content {
       display: inline;
    }
    .form_select{
        width:60px !important;
        margin: -10px -6px !important;
    }
    .form_select1{
        width:60px !important;
    }
    .dialog_form /deep/ .form_select .el-input__wrapper{
        background-color: #F2F6F9;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .dialog_form /deep/ .form_select1 .el-input__wrapper{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0
    }
    .el-checkbox span{
        color: var(--el-checkbox-text-color)
    }
    .append{
        display: inline-block;
        height: 32px;
        width: 45px;
        text-align: center;
        border-top-right-radius: var(--el-input-border-radius,var(--el-border-radius-base));
        border-bottom-right-radius: var(--el-input-border-radius,var(--el-border-radius-base));
        box-shadow: 0 0 0 1px var(--el-input-border-color,var(--el-border-color)) inset;
        background: #F2F6F9;
    }
</style>