<template>
	<!-- 打印小票 -->
	<el-dialog title="票据打印" width="250px" custom-class="popwechat" :append-to-body="true" @opened="opened"
		destroy-on-close>
		<div class="p20" id="printBox">
			<h3 class="t_a_c mb20 ft16 color-000">{{pageData.info.title}}</h3>
			<div class="rx-ss mb10 color-333">
				<span class="w60 ft12">会员姓名：</span>
				<div class="flex1 ft12">{{pageData.info.realName}}</div>
			</div>
			<div class="rx-ss mb10 color-333">
				<span class="w60 ft12">会员手机：</span>
				<div class="flex1 ft12">{{pageData.info.telephoneNum}}</div>
			</div>
			<div class="rx-ss mb10 color-333" v-if="propData.type == 5">
				<span class="w60 ft12">支付方式：</span>
				<div class="flex1 ft12">{{pageData.info.payMethodName}}</div>
			</div>
			<div class="rx-ss mb10 color-333">
				<span class="w60 ft12">{{propData.type == 5 ? '销售' :'消费'}}时间：</span>
				<div class="flex1 ft12">{{pageData.info.createTime}}</div>
			</div>
			<hr class="mb10" style="border:1px dashed #666666" />
			<div v-if="propData.type == 4">
				<!-- <div class="rx-ss mb10">
					<b class="w60 color-000 ft12">场地名称：</b>
					<div class="flex1 ft12">{{pageData.info.roomName}}</div>
				</div> -->
				<div class="rx-ss mb10">
					<b class="w60 color-000 ft12">场地场次：</b>
					<div class="flex1">
						<div class="ft12" :class="{'mb10':pageData.info.orderDetailList.length-1 != index}" v-for="(item,index) in pageData.info.orderDetailList" :key="item">{{item}}</div>
					</div>
				</div>
				<div class="rx-ss mb10">
					<b class="w85 color-000 ft12">扣费支付方式：</b>
					<div class="flex1 ft12">{{pageData.info.productName || getPayMethod(pageData.info.payMethodId)}}</div>
				</div>
			</div>
			<div v-else-if="propData.type == 5">
				<div class="rx-ss mb10">
					<b class="w60 color-000 ft12">商品名称：</b>
					<div class="flex1 ft12">{{pageData.info.productName}}</div>
				</div>
				<div class="rx-ss mb10" v-if="!pageData.info.type">
					<b class="w60 color-000 ft12">商品单价：</b>
					<div class="flex1 ft12">{{pageData.info.productPrice}}</div>
				</div>
				<div class="rx-ss mb10" v-if="!pageData.info.type">
					<b class="w60 color-000 ft12">购买课节：</b>
					<div class="flex1 ft12">{{pageData.info.productNum}}</div>
				</div>
				<div class="rx-ss mb10" v-if="!pageData.info.type">
					<b class="w60 color-000 ft12">赠送课节：</b>
					<div class="flex1 ft12">{{pageData.info.buyGiveNum || 0}}</div>
				</div>
				<div class="rx-ss mb10">
					<b class="w60 color-000 ft12">商品金额：</b>
					<div class="flex1 ft12">{{pageData.info.shouldMoney}}</div>
				</div>
				<div class="rx-ss mb10">
					<b class="w60 color-000 ft12">减免金额：</b>
					<div class="flex1 ft12">{{pageData.info.reducePrice || 0}}</div>
				</div>
			</div>
			<div v-else>
				<div class="rx-ss mb10" v-if="propData.type != 2 && propData.type != 3">
					<b class="w60 color-000 ft12">签到课程：</b>
					<div class="flex1 ft12">{{pageData.info.courseName}}</div>
				</div>
				<div class="rx-ss mb10" v-if="propData.type != 2 && propData.type != 3">
					<b class="w60 color-000 ft12">上课教练：</b>
					<div class="flex1 ft12">{{pageData.info.teacherName}}</div>
				</div>
				<div class="rx-ss mb10" v-if="propData.type != 2 && propData.type != 3">
					<b class="w60 color-000 ft12">上课时间：</b>
					<div class="flex1 ft12">{{pageData.info.classTimeStr}}</div>
				</div>
				<div class="rx-ss mb10" v-if="propData.type == 2 && propData.type != 3">
					<b class="w60 color-000 ft12">入场时间：</b>
					<div class="flex1 ft12">{{pageData.info.signTime}}</div>
				</div>
				<div class="rx-ss mb10"
					v-if="propData.type != 3 && pageData.info.productTyp == 1 && pageData.info.cardType != 3">
					<b class="w60 color-000 ft12">本次扣除：</b>
					<div class="flex1 ft12">{{pageData.info.consumeNum}}</div>
				</div>
				<div class="rx-ss mb10">
					<b class="w85 color-000 ft12">扣费支付方式：</b>
					<div class="flex1 ft12">{{pageData.info.productName}}</div>
				</div>
			</div>
			<hr class="mb10" style="border:1px dashed #666666" v-show="pageData.isMoney" />
			<div class="t_a_r mb10 color-000" v-show="pageData.isMoney"><b
					class="ft18">消课金额：￥{{pageData.info.consumeMoney}}</b></div>
			<hr class="mb10" style="border:1px dashed #666666" />
			<div class="rx-ss mb10 color-333">
				<span class="w40 ft12">电话：</span>
				<div class="flex1 ft12">{{pageData.info.tel}}</div>
			</div>
			<div class="rx-ss color-333">
				<span class="w40 ft12">地址：</span>
				<div class="flex1 ft12">{{pageData.info.area}}</div>
			</div>
		</div>
		<div class="pl20">
			<el-checkbox v-model="pageData.isMoney" label="是否打印消费金额" size="large"></el-checkbox>
		</div>
		<template #footer>
			<div class="dialog-footer">
				<el-button type="primary" @click="save">打印</el-button>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
	import {
		ref,
		reactive
	} from 'vue';
	import {
		ElMessage
	} from 'element-plus'
	import {
		getMyLineStore
	} from '@/api/storeCenter.js';
	import {
		orderCourseInfo
	} from '@/api/consume'
	import {
		querySaleInfo
	} from '@/api/finance.js'
	import utils from '@/common/utils.js';
	const emit = defineEmits(['save']);
	const propData = defineProps({
		data: Object,
		id: String,
		type: String //0->预约管理-上课预约 1->消费记录-预约课程消费 2->消费记录-入场消费 3->消费记录-期限卡按天自动消费 4->消费记录-场地预约消费/财务中心-销售记录-场地订单 5-> 财务中心-销售记录 
	})
	const pageData = reactive({
		isMoney: false,
		info: {
			title: '', //机构标题
			realName: '', //会员名称
			telephoneNum: '', //会员手机号
			createTime: '', //消费时间
			courseName: '', //课程名称
			teacherName: '', //上课教练
			classTimeStr: '', //上课时间
			consumeNum: '', //扣除次数
			productName: '', //扣除支付方式（商品名称）
			productTyp: '', //卡类型 3期限卡 或 1次卡
			signTime: '', //入场时间
			consumeMoney: '', //扣费金额
			tel: '', //机构电话
			area: '', //机构地址,
			cardType: '',
			roomName: '',
			orderTimeStr: '',
			//销售记录
			type: '', //商品类型
			productPrice: '', //商品价格
			remainMoney: '', //商品金额
			payMethodName: '', //支付方式
			reducePrice: '', //减免金额
			shouldMoney: '', //商品金额
			productNum: '', //购买课节
			buyGiveNum: '', //赠送课节 
			payMethodId:'',//支付方式id
			orderDetailList: []
		}
	})

	var cssKey = ['color', 'fontColor', 'fontSize', 'display', 'flex', 'width', 'flexFlow', 'justifyContent', 'alignItems',
		'margin', 'padding', 'boxSizing', 'textAlign'
	];

	function childStyle(dom, pDom) {
		for (var i = 0; i < cssKey.length; i++) {
			dom.style[cssKey[i]] = getComputedStyle(pDom).getPropertyValue(cssKey[i]);
		}
		if (dom.children.length > 0) {
			for (var o = 0; o < dom.children.length; o++) {
				childStyle(dom.children[o], pDom.children[o]);
			}
		}
	}

	function save() {
		var printHtml = document.getElementById('printBox');
		var iframe = document.createElement('iframe');
		var cloneDom = printHtml.cloneNode(true);
		childStyle(cloneDom, printHtml)
		iframe.style.width = '250px';
		document.body.appendChild(iframe);
		iframe.contentWindow.document.write(`<!DOCTYPE html>
												<html>
													<head>
														<style>
															*{  margin: 0;
																padding: 0;
																box-sizing: border-box;
																font-size: 12px;
															}
															body{
																padding:20px;
															}
															.t_a_r{
																text-align:right;
															}
															.t_a_c{
																text-align:center;
															}
															.ft18{
																font-size:18px;
															}
															.ft16{
																font-size:16px;
															}
														</style>
													</head>
													<body>
													${cloneDom.innerHTML}
													</body>
												</html>`)
		iframe.contentWindow.print();
		iframe.remove();
		emit('save');
	}
	getMyLineStore().then((res) => {
		if (res.code == 0) {
			pageData.info.title = res.data.storeName;
			pageData.info.tel = res.data.storePhone;
			pageData.info.area = res.data.provinceName + res.data.cityName + res.data.areaName + res.data.storeAdd;

		}
	})

	function opened() {
		if (propData.type == 0) {
			orderCourseInfo({
				encryptionId: propData.id
			}).then((res) => {
				if (res.code == 0) {
					initForm(res.data);
				}
			})
		} else if (propData.type == 5) {
			querySaleInfo({
				encryptionId: propData.id
			}).then((res) => {
				if (res.code == 0) {
					initForm(res.data);
					pageData.info.consumeMoney = res.data.actualMoney;
				}
			})
		} else {
			initForm(propData.data);
		}
	}

	function initForm(data) {
		for (var k in pageData.info) {
			if (k != 'title' && k != 'tel' && k != 'area') {
				pageData.info[k] = data[k];
				if(!data.courseType && !data.type){ //场地预约
					pageData.info.type = 21;
				}
				if(propData.type == 4){
					var timeObj = {};
					data.orderDetailList.map(n=>{
						var str = n.split(' ');
						var obj = {
							timeStr:str[2],
							day:str[1],
							st:utils.getDateObj(str[1]+' '+str[2].substring(0,5)+':00').time
						}
						if(timeObj[str[0]]){
							timeObj[str[0]].push(obj)
						}else{
							timeObj[str[0]] = [obj];
						}
					})
					
					var orderList = [];
					for(var k in timeObj){
						timeObj[k].map((q,w)=>{
							orderList.push(k+'（'+q.timeStr+'）');
						})
					}
					// list.sort((a,b)=>a.timeNum-b.timeNum); //按时间排序
					pageData.info.orderDetailList = orderList;
				}
			}
		}
	}
	//获取支付方式
	function getPayMethod(payMethodId){
		var payList = utils.getDict('waterPayType');
		var arr = payList.filter(n=>n.value == payMethodId.toString());
		return arr.length > 0 ? arr[0].label : '';
	}
</script>

<style lang="stylus">
</style>
