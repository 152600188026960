<!-- 会员卡项列表页面 -->
<template>
    <div class="bg-fff">
        <formQuery :queryOpts="pageData.queryOpts" ref="queryOptsRef" @query="searchGetList"></formQuery>
        <!-- 按钮区域 -->
        <div class="rx-bc mb20 mt10">
            <div>
				<el-button type="primary" @click="delayCard" v-if="utils.getMenuRole(3130100)">会员卡延期</el-button>
                <el-button @click="batchUnfrozen(1)" v-if="utils.getMenuRole(3130101)">批量解冻</el-button>
                <el-button type="danger" plain @click="batchFrozen(1)" v-if="utils.getMenuRole(3130102)">批量冻结</el-button>
			</div>
			<div>
				<el-button @click="screen" :icon="Operation">筛选</el-button>
				<el-button @click="getList(true)" :icon="Upload" v-if="utils.getMenuRole(3130103)">导出</el-button>
			</div>
        </div>
        <!-- 数据表格 -->
        <div class="">
            <el-table :data="pageData.memberList" stripe style="width: 100%" v-loading="pageData.loading" @selection-change="selectionChange">
                <el-table-column fixed type="selection" width="55" :selectable="(row)=>{return row.sid != 0}" />
                <el-table-column fixed label="会员姓名" show-overflow-tooltip >
					<template #default="scope">
					    <span @click="toMemberDetail(scope.row.encryptionCardMyId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
                <el-table-column prop="telephoneNum" label="手机号" width="120"></el-table-column>
                <el-table-column prop="productName" label="会员卡名称" show-overflow-tooltip min-width="100" ></el-table-column>
                <el-table-column label="会员卡类型" min-width="100">
					<template #default="scope">
						<span>{{ filtersType(scope.row.type) }}</span>
					</template>
				</el-table-column>
                <el-table-column prop="actualMoney" label="购买金额(元)" min-width="110"></el-table-column>
                <el-table-column label="购买次数" >
					<template #default="scope">
                        <span>{{ scope.row.productNumBuy ? scope.row.productNumBuy : '--' }}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="productCycleBuy" label="消费时长">
					<template #default="scope">
						{{ scope.row.productCycleBuy ? scope.row.productCycleBuy : '--' }}{{ scope.row.productUnit == 1 ? '天' : scope.row.productUnit == 2 ? '月' : scope.row.productUnit == 3 ? '年' : '' }}
					</template>
				</el-table-column>
                <el-table-column label="剩余金额(元)" min-width="180" >
					<template #default="scope">
						<div v-if="pageData.results && pageData.results.stuAssetBalance == 1">
							<div v-if="scope.row.type == 3">
								<p class="ft12">本金：{{ scope.row.storeMoneyBase }} <span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.storeMoneyGive > 0">（已过期）</span></p>
								<p class="ft12">赠送：{{ scope.row.storeMoneyGive }} <span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.storeMoneyGive > 0">（已过期）</span></p>
							</div>
							<div v-else>{{ scope.row.remainMoney ? scope.row.remainMoney : '--' }}<span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.remainMoney > 0">（已过期）</span></div>
						</div>
						<div v-else>
							<div v-if="scope.row.type == 3">
								<p class="ft12">本金：*** <span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.storeMoneyGive > 0">（已过期）</span></p>
								<p class="ft12">赠送：*** <span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.storeMoneyGive > 0">（已过期）</span></p>
							</div>
							<div v-else>{{ scope.row.remainMoney ? '***' : '--' }}<span class="color-red ft12" v-if="scope.row.orderState == 3 && scope.row.remainMoney > 0">（已过期）</span></div>
						</div>
					</template>
				</el-table-column>
                <el-table-column label="剩余次数" >
					<template #default="scope">
                        <span>{{ scope.row.productNum ? scope.row.productNum : '--' }}</span>
                    </template>
				</el-table-column>
                <el-table-column label="剩余天数(天)" min-width="110">
					<template #default="scope">
                        <span>{{ scope.row.productCycle == -1 ? '未开始计时' : scope.row.productCycle == -2 ? '不限时' : scope.row.productCycle}}</span>
                    </template>
				</el-table-column>
                <el-table-column prop="actualMoney" label="状态" >
					<template #default="scope">
						<el-link v-if="scope.row.orderState == 1" :underline="false" type="success">正常</el-link>
						<el-link v-if="scope.row.orderState == 2" :underline="false">已冻结</el-link>
						<el-link v-if="scope.row.orderState == 3" :underline="false" type="danger">已结束</el-link>
                    </template>
				</el-table-column>
                <el-table-column prop="adviserNames" label="跟进人" show-overflow-tooltip />
                <el-table-column fixed="right" label="操作" width="160">
                    <template #default="scope">
						<div v-if="scope.row.orderState == 3 && scope.row.storeMoneyGive > 0">-</div>
						<div v-else>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.orderState != 2 && utils.getMenuRole(3130104) " @click="batchFrozen(2,scope.row.encryptionId)">冻结</a>
							<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.orderState == 2 && utils.getMenuRole(3130105) " @click="batchUnfrozen(2,scope.row.encryptionId)">解冻</a>
							<el-link class="t_d_u v_a_m ml10" :underline="false" type="danger" v-if="scope.row.orderState != 2 && utils.getMenuRole(3130106) " @click="memberRefundEvent(scope.row)">退款</el-link>
						</div>
                    </template>
                </el-table-column>
				<template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec" v-show="pageData.memberList.length > 0">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="()=>{getList()}"
                >
                </el-pagination>
              </div>
        </div>
		<!-- //冻结 -->
		<el-dialog
		     v-model="pageData.frozenShow"
		     title="冻结设置"
		 	 width="530px"
		     :before-close="()=>{pageData.frozenShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt20 pb20 pr20">
				<div class="rx-cc mb16">
					<b class="w90 t_a_r">冻结类型：</b>
					<div class="w300">
						<el-radio v-model="pageData.frozenType" :label="1" size="large"><span class="color-666 fw400">手动冻结</span></el-radio>
						<el-radio v-model="pageData.frozenType" :label="2" size="large"><span class="color-666 fw400">自动冻结</span></el-radio>
					</div>
				</div>
				<div class="rx-cc mb16" v-show="pageData.frozenType == 2">
					<b class="w90 t_a_r ml20">冻结时间：</b>
					<div class="data-Input">
						<el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="pageData.frozenForm.autoFrozenDate" type="date" placeholder="冻结时间"></el-date-picker>
						<span style="padding-top: 4px;">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="若选择当天，则冻结时间为当前时间；若选择其他日期，则冻结时间为00：00：00"
							    placement="top"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							  </el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-cc mb16" v-show="pageData.frozenType == 2">
					<b class="w90 t_a_r ml20">解冻时间：</b>
					<div class="data-Input">
						<el-date-picker format="YYYY-MM-DD" value-format="YYYY-MM-DD" v-model="pageData.frozenForm.autoUnFrozenDate" type="date" placeholder="解冻时间"></el-date-picker>
						<span style="padding-top: 4px;">
							<el-tooltip
							    class="box-item"
							    effect="dark"
							    content="自动解冻时间为选择日期的00：00：00"
							    placement="top"
							  >
							  <img class="w15 h15 ml5" src="@/assets/wenhao.png" >
							  </el-tooltip> 
						</span>
					</div>
				</div>
				<div class="rx-cc mb16">
					<b class="w90 t_a_r">备注：</b>
					<div class="w300">
						 <el-input
						    v-model="pageData.frozenForm.remark"
						    :rows="3"
						    type="textarea"
						    placeholder="请输入备注"
						  />
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.frozenShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchFrozenSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 会员卡延期 -->
		<el-dialog
		     v-model="pageData.delayShow"
		     title="会员卡延期"
		 	 width="530px"
		     :before-close="()=>{pageData.delayShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt20 pb20 pr20">
				<CommonTip text="延期后，期限卡的单价会进行变更，单价=会员卡剩余价值/剩余天数。未开始计时的期限卡和次卡不受影响。"></CommonTip>
				<div class="rx-cc mb12">
					<span class="w90 t_a_r"><span class="color-red">*</span>延期时间：</span>
					<div class="w300">
						<el-input v-model="pageData.delayForm.productCycle" placeholder="请输入">
						      <template #append>天</template>
						</el-input>
					</div>
				</div>
				<div class="rx-cc mb12">
					<span class="w90 t_a_r">备注：</span>
					<div class="w300">
						 <el-input
						    v-model="pageData.delayForm.remark"
						    :rows="3"
						    type="textarea"
						    placeholder="请输入备注"
						  />
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.delayShow = false">取消</el-button>
		        	<el-button type="primary" @click="delaySave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<MemberRefund v-model="pageData.memberRefundShow" :memberid="pageData.memberId" :type="pageData.type" :orderid="pageData.orderId" @close="memberRefundClose" @save="memberRefundSave"></MemberRefund>
		<Screen ref="screenRef" :type="1" @reset="screenReset" @save="screenSave"></Screen>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted} from 'vue';
	import { useStore } from 'vuex'
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { ElMessage } from 'element-plus'
    import { DArrowRight, Close, Upload, Calendar, Operation} from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import Confirm from '@/components/Confirm.vue'
    import CommonTip from '@/components/CommonTip.vue'
    import { queryAllBuyCardList, unFrozen, frozen, autoFrozen, delay } from '@/api/member.js';
    import formQuery from '@/components/branch-store/formQuery.vue';
	import Screen from '@/components/branch-store/member/Screen.vue'
    import MemberRefund from '@/components/branch-store/member/MemberRefund.vue';
	import {  getStoreAccountStaffSimple } from '@/api/staff.js'

	const queryOptsRef = ref()
	const screenRef = ref()
	const confirmRef = ref()
    const router = useRouter();
	const store = useStore();
    const pageData = reactive({
		memberRefundShow:false,
		results: {},
		orderId:'',
		memberId:'',
		loading: false,
        memberList : [],
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15
        },
		type: 0,
		screenData: {}, // 筛选
		frozenShow:false,//冻结
		frozenType:1,
		frozenForm:{
			encryptionId:'',
			autoFrozenDate: '',
			autoUnFrozenDate: '',
			remark: ''
		},
		
		delayShow:false,//延期
		delayForm:{
			productCycle: '',
			encryptionId: '',
			remark: '',
		},
		queryOpts: {
            fields:[{
                type : 'input',
                fieldName : 'searchKeys',
                placehold : '会员姓名/手机号'
            },{
                type : 'input',
                fieldName : 'productName',
                placehold : '会员卡名称'
            },{
                type : 'select',
                fieldName : 'type',
                placehold : '会员卡类型',
                options : [{label : '全部类型' ,value : ''},{label : '课程卡' ,value : '1'},{label : '入场卡' ,value : '2'},{label : '储值卡' ,value : '3'},{label : '体验卡' ,value : '4'}],
                value:''
            },{
                type : 'select',
                fieldName : 'orderState',
                placehold : '状态',
                options : [{label : '全部状态' ,value : '0'},{label : '正常' ,value : '1'},{label : '已冻结' ,value : '2'},{label : '已结束' ,value : '3'}],
                value: '0'
            },{
                type : 'selectMany',
                fieldName : 'adviserIds',
                placehold : '跟进人',
                options : []
            }]
        }
    })
	const cardType = [
		{ label: '课程卡', value: '1' },
		{ label: '入场卡', value: '2' },
		{ label: '储值卡', value: '3' },
		{ label: '体验卡', value: '4' },
	]
	/**
     * @param (String, Number) value-类型, type-1 课程类型 2-预约状态
     * 表格字段过滤过滤展示
     */
	function filtersType(value) {
		let text = ''
        cardType.forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
	//获取字典
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[4].options = res;
	});
	let confirmType = '',
	selectCheckData = [];

	/**
	 * 筛选
	 */
	function screen() {
		screenRef.value.show();
	}
	/**
	 * 确定筛选
	 */
	function screenSave(data) {
		pageData.screenData = data
		getList()
	}
	/**
	 * 筛选重置
	 */
	function screenReset(data) {
		pageData.screenData = data
		getList()
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.searchForm.page = 1;
       getList()
	}
	function getList(isExport){
		pageData.loading = true
		let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		if (Object.keys(pageData.screenData).length > 0) {
			params = Object.assign(pageData.screenData, params)
		}
		params.limit = pageData.searchForm.limit
		params.page = pageData.searchForm.page
		params.adviserIds = params.adviserIds && params.adviserIds.length > 0 ? params.adviserIds.join(',') : '';
		if(isExport){ //导出
			params.page = 1;
			params.limit = 9999;
			params.exportData = 1;
		}
		queryAllBuyCardList(params).then((res)=>{
			pageData.loading = false
			if(res.code == 0){
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.memberList = res.data;
					pageData.totalCount = res.count;
					pageData.results = res.results;
				}
			}
		}).catch(() => {
            pageData.loading = false
        })
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		getList();
		callback && callback();
	}
	
	// 延期
	function delayCard(){
		CheckLengthCallBack(()=>{
			pageData.delayForm= {
				productCycle: '',
				encryptionId: '',
				remark: '',
			}
			pageData.delayForm.encryptionId = getSelectCheckKey('encryptionId')
			pageData.delayShow = true
		})
	}
	function delaySave(){
		var arr = selectCheckData.filter(n=>n.productTyp == 1);
		if(arr.length > 0){
			pageData.confirmTitle = '部分为次卡，不能进行延期；其余卡项确定要进行延期吗？延期后不能撤回此操作！';
			confirmType = 'delay';
			confirmRef.value.show();
			return false;
		}else{
			delayAjaxSave();
		}
	}
	function delayAjaxSave(){
		delay(pageData.delayForm).then((res)=>{
			if(res.code == 0){
				ajaxSaveCallBack('延期成功');
				getList()
				pageData.delayShow = false;
			}
		})
	}
	//冻结
	function batchFrozen(type,id){
		pageData.frozenForm = {}
		pageData.frozenType = 1
		if(type == 1){ //批量
			CheckLengthCallBack(()=>{
				pageData.frozenForm.encryptionId = getSelectCheckKey('encryptionId');
				pageData.frozenShow = true;
			})
		}else{  //单个
			pageData.frozenForm.encryptionId = id;
			pageData.frozenShow = true;
		}
	}
	function batchFrozenSave(){
		var formData = Object.assign({},pageData.frozenForm);
		if(pageData.frozenType == 1){
			frozen(formData).then((res)=>{
		    	if(res.code == 0){
		    		ajaxSaveCallBack('冻结成功');
		    		pageData.frozenShow = false;
		            getList()
		    	}
		    })
		}else{
			if(!formData.autoUnFrozenDate){
				ElMessage({
					type: 'warning',
					message: '请选择冻结时间',
				})
				return false;
			}
			if(!formData.autoFrozenDate){
				ElMessage({
					type: 'warning',
					message: '请选择解冻时间',
				})
				return false;
			}
			autoFrozen(formData).then((res)=>{
		    	if(res.code == 0){
		    		ajaxSaveCallBack('冻结成功');
		    		pageData.frozenShow = false;
		            getList()
		    	}
		    })
		}
		
	}
	
	
	//解冻
	let unfrozenId = '';
	function batchUnfrozen(type,id){
		if(type == 1){ //批量
			CheckLengthCallBack(()=>{
				unfrozenId = getSelectCheckKey('encryptionId');
				pageData.confirmTitle = '确定要解冻勾选的记录吗？';
				confirmType = 'unfrozen';
				confirmRef.value.show();
				return false; 
			})
		}else{  //单个
			unfrozenId = id;
			pageData.confirmTitle = '确定要解冻该记录吗？';
			confirmType = 'unfrozen';
			confirmRef.value.show();
		}
	}
	
	//退款
	function memberRefundEvent(row){
		pageData.orderId = row.encryptionFinanceId;
		pageData.memberId = row.encryptionCardMyId
		pageData.type = row.orderProductType
		pageData.memberRefundShow = true;
	}
	function memberRefundSave(){
		pageData.memberRefundShow = false;
		getList();
	}
	function memberRefundClose(){
		pageData.memberRefundShow = false;
	}
	
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'unfrozen'){ //解冻
			unFrozen({
				encryptionId: unfrozenId
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('解冻成功');
					getList()
				}
			})
		}else if(confirmType == 'delay'){ //延期
			delayAjaxSave();
		}
		
	}
	
	function toMemberDetail(id){
	    router.push('/branch/memberDetail?encryptionId='+id);
	}
    onMounted(()=>{
        // 查询会员卡项列表
        getList();
    });
   // // 页面缓存
   // onBeforeRouteLeave((to, from)=>{
   //     if (to.path === '/branch/memberDetail') {
   //         store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
   //     } else {
   //         store.commit('SetKeep',{keepLiveRoute : []});
   //     }
   // })
</script>
<style scoped>
	.data-Input /deep/ .el-input {
        width: 300px;
	}
</style>