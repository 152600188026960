<template>
	<div class="login_container" v-loading="pageData.formLoading">
		<div class="login_logo rx-bc">
			<img class="w140 h30" src="../assets/logo_primary.png" alt="">
			<div class="rx-cc">
				<img src="../assets/tel.png" alt="">
				<span>热线电话：400-100-6311</span>
			</div>
		</div>
		<div class="login_top" id="login_top" :style="{height:pageData.height}">
			<canvas id="canvasBcakground" width="100%" height="100%"></canvas>
			<form class="login_top_form" v-show="pageData.formNum == 1">
				<div class="rx-bc login_top_box">
					<div class="rx-sc flex1">
						<img class="login_top_logo" src="../assets/loginLogo.png" alt="">
					</div>
					<div class="login_top_right flex1 pt20">
						<div class="rx-ce login_top_tab">
							<span class="c_p" :class="tabNum == 1 ? 'active' : ''" @click="changeTab(1)">扫码登录</span>
							<span>&nbsp;/&nbsp;</span>
							<span class="c_p" :class="tabNum == 2 ? 'active' : ''" @click="changeTab(2)">账号登录</span>
						</div>
						<div v-show="tabNum == 2" class="rx-bc login_top_input">
							<img src="../assets/user.png" alt="">
							<input class="flex1" type="text" v-model="loginFormData.logingName" name="userName" placeholder="请输入账号" spellcheck="false" />
							<img v-if="userNameState == 1" src="../assets/icon_yes.png" alt=""/>
							<img v-if="userNameState == 2" src="../assets/icon_error.png" alt=""/>
						</div>
						<div v-show="tabNum == 2" class="rx-bc login_top_input">
							<img src="../assets/pass.png" alt="">
							<input class="flex1" type="password" v-model="loginFormData.password" name="password" placeholder="请输入密码" spellcheck="false" />
							<img v-if="passWordState == 1" src="../assets/icon_yes.png" alt=""/>
							<img v-if="passWordState == 2" src="../assets/icon_error.png" alt=""/>
						</div>
						<div v-show="tabNum == 2" class="login_top_btn c_p" @click="loginClick">登录</div>
						<div v-show="tabNum == 1" class="t_a_c">请使用微信扫一扫登录</div>
						<div v-show="tabNum == 1" class="rx-cc ">
							<div id="qrcode" class="login_top_code" v-loading="pageData.codeLoading"></div>
						</div>
						<div class="t_a_c">
							没有账号？<a href="JavaScript:;" class="t_d_u color-409EFF tool_btn" @click="changeForm">申请试用</a>
						</div>
					</div>
				</div>
			</form>
			<form class="login_top_try" v-show="pageData.formNum == 2">
				<div class="try_close">
					<img class="close c_p ww100 hh100" src="../assets/close.png" alt="" />
					<img class="close1 c_p ww100 hh100" src="../assets/order3.png" alt=""  @click="changeForm" />
				</div>
				
				<h3>申请试用</h3>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<el-input v-model="tryFormData.storeName" placeholder="机构名称">
						<template #prefix>
							<img class="input_icon" src="../assets/try_icon1.png" alt="">
						</template>
					</el-input>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<div class="try_select_box">
						<img class="input_icon" src="../assets/try_icon2.png" alt="">
						<el-select v-model="tryFormData.storeCategory" clearable placeholder="场馆类目">
                			<el-option label="连锁健身房" :value="1" />
                			<el-option label="私教工作室" :value="2" />
                			<el-option label="瑜伽馆" :value="3" />
                			<el-option label="普拉提馆" :value="4" />
                			<el-option label="舞蹈室" :value="5" />
                			<el-option label="跆拳道馆" :value="6" />
                			<el-option label="游泳馆" :value="7" />
                			<el-option label="少儿运动馆" :value="8" />
                			<el-option label="综合体育馆" :value="9" />
                			<el-option label="羽毛球馆" :value="10" />
                			<el-option label="高尔夫球馆" :value="11" />
                			<el-option label="篮球馆" :value="12" />
                			<el-option label="足球馆" :value="13" />
                			<el-option label="其他" :value="0" />
						</el-select>
					</div>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<el-row :gutter="20">
					    <el-col :span="12">
							<div class="try_select_box">
								<img class="input_icon" src="../assets/try_icon3.png" alt="">
								<el-select v-model="tryFormData.provinceName" alue-key placeholder="请选择省" @change="provinceAddress">
								    <el-option
								      v-for="item in pageData.provinceAddress"
								      :key="item.value"
								      :label="item.label"
								      :value="item"
								    >
								    </el-option>
								  </el-select>
							</div>
						</el-col>
					    <el-col :span="12">
							<div class="try_select_box">
								<img class="input_icon" src="../assets/try_icon4.png" alt="">
								<el-select v-model="tryFormData.cityId" placeholder="请选择市">
								    <el-option
								      v-for="item in pageData.cityAddress"
								      :key="item.value"
								      :label="item.label"
								      :value="item.value"
								    >
								    </el-option>
								  </el-select>
							</div>
						</el-col>
					  </el-row>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<el-input v-model="tryFormData.userName" placeholder="联系人">
						<template #prefix>
							<img class="input_icon" src="../assets/try_icon5.png" alt="">
						</template>
					</el-input>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<el-input v-model="tryFormData.userTel" placeholder="手机号码" @blur="verifyMobile">
						<template #prefix>
							<img class="input_icon" src="../assets/try_icon6.png" alt="">
						</template>
					</el-input>
				</div>
				<div class="try_item rx-bc" v-show="pageData.tryStatus == 1">
					<el-input v-model="tryFormData.telCode" placeholder="验证码" class="mr16">
						<template #prefix>
							<img class="input_icon" src="../assets/try_icon7.png" alt="">
						</template>
					</el-input>
					<el-button v-show="pageData.verShow" type="primary" @click="getCode">获取验证码</el-button>
					<el-button v-show="!pageData.verShow" disabled type="info"><span>{{pageData.timer}}</span>秒后重新获取</el-button>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 1">
					<div class="try_btn" @click="changeTryStatus">提交申请</div>
				</div>
				<div class="try_item t_a_c" style="color:#889EAF;" v-show="pageData.tryStatus == 1">
					提交信息后会有商务人员联系您
				</div>
				<div class="try_item try_success" v-show="pageData.tryStatus == 2">
					<div>
						<img src="../assets/try_success.png" >
					</div>
					<p>提交成功！</p>
					<p>商务人员会尽快联系您，</p>
					<p>请耐心等待</p>
				</div>
				<div class="try_item" v-show="pageData.tryStatus == 2">
					<div class="try_btn" @click="changeTryStatus">我知道了</div>
				</div>
			</form>
		</div>
		<div class="login_bottom posi-r">
			<div class="login_bottom_cont rx-bc posi-a">
				<div class="login_bottom_cont_left">
					<h2 class="ft18 mb15 mt30">杭州博跃体育科技有限公司</h2>
					<div class="mb12">
						<img class="iphone v_a_m" src="../assets/iphone.png" >
						<span class="v_a_m">电话：400-100-6311</span>
					</div>
					<div class="mb12">
						<img class="iphone v_a_m" src="../assets/E-mail.png" >
						<span class="v_a_m">邮箱：service@yxysport.com</span>
					</div>
					<div class="mb12">
						<img class="address v_a_m" src="../assets/address.png" >
						<span class="v_a_m">地址：杭州市江干区2号大街海聚中心6幢715室</span>
					</div>
					<div>浙ICP备2022007547号-1 ©2021-2022 杭州博跃体育科技有限公司版权所有</div>
				</div>
				<div class="login_bottom_cont_right">
					<h2 class="ft18 mt30">微信公众号</h2>
					<div class="w105 h105 bg-fff ewm">
						<img style="width:83px; height:83px" src="../assets/ewm.png" alt="">
					</div>
					
				</div>
			</div>
		</div>
		<el-dialog
		    v-model="pageData.dialogVisible"
		    title="图形验证码"
		    width="300px"
		    :before-close="handleClose"
		  >
		    <div class="p20">
				<div class="rx-bc">
					<img class="h30" :src="pageData.img" alt="">
					<el-input class="w100" v-model="pageData.code" placeholder="请输入结果" />
				</div>
			</div>
		    <template #footer>
		      	<span class="dialog-footer">
		      	  	<el-button @click="handleClose">取消</el-button>
		      	  	<el-button type="primary" @click="confirm">确定</el-button>
		      	</span>
		    </template>
		</el-dialog>
	</div>
</template>
<script setup>
	import { ref, reactive, onMounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRouter } from 'vue-router'
	import QRCode from 'qrcodejs2'
	import { ElMessage } from 'element-plus'
	import { login, getUserMenuListJson, tryUse, getUid, checkLogin, loginByOpenidPre, captchaImage, getTelRandomCode } from '../api/login.js'
	import { config } from '../common/config.js';
	import { enterStore } from '@/common/common.js';
	import { canvasBackgroundInit } from '../common/canvasBackground.js';
	import { getAllAddress } from '@/api/applet.js';
	const store = useStore();
	store.state.storeLogo.value = '';
	localStorage.clear();
	const router = useRouter();
	const pageData = reactive({
		dialogVisible: false,
		codeLoading:false,
		formLoading:false,
		tryStatus:1,
		formNum:1,
		height:(window.innerHeight - 50)+'px',
		img: '',
		provinceAddress: [],
		cityAddress: [],
		code: '',
		uuid: '',
		verShow: true,
		timer: 60
	})
	
	//切换窗口大小计算高度
	window.onresize = function(){
		pageData.height = (window.innerHeight - 50)+'px';
	}
	
	// 切换登录方式
	const tabNum = ref(2);
	function changeTab(num){
		tabNum.value = num;
		if(tabNum.value == 1){
			pageData.codeLoading = true;
			getUUid();
		}
	}
	// 申请使用
	const tryFormData = reactive({
		storeName:'',
		storeCategory:'',
		provinceName:'',
		provinceId: '',
		cityId:'',
		userName:'',
		userTel:'',
		telCode:'',       
	})
	//申请试用-切换
	function changeForm(){
		pageData.formNum = pageData.formNum == 1 ? 2 : 1;
		pageData.tryStatus = 1;
	}
	/**
	 * 获取地区
	 */
	getAddress()
	function getAddress() {
	    getAllAddress().then((res) => {
	        if(res.code == 0){
		    	pageData.provinceAddress = res.data;
		    }
	    })
	}
	/**
	 * 获取省
	 */
	function provinceAddress(row) {
		tryFormData.provinceName = row.label
		tryFormData.provinceId = row.value
		tryFormData.cityId = ''
		pageData.cityAddress = row.children
	}
	/**
	 * 验证手机号
	 */
	var verify = true
	function verifyMobile() {
		let re = /^0?(13[0-9]|15[012356789]|17[013678]|18[0-9]|14[57])[0-9]{8}$/
        let str = tryFormData.userTel;
        if(!re.test(str)){
			ElMessage({
				message: '请输入正确的手机号',
				type: 'warning'
			})
			verify = false
        } else {
			verify = true
		}
	}
	/**
	 * 获取验证码
	 */
	function getCode() {
		if (!tryFormData.userTel || !verify) {
			ElMessage({
				message: '请输入正确的手机号',
				type: 'warning'
			})
			return false
		}
		if (!tryFormData.storeName) {
			ElMessage({
				message: '请输入机构名称',
				type: 'warning'
			})
			return false
		}
		if (!tryFormData.storeCategory) {
			ElMessage({
				message: '请选择场馆类目',
				type: 'warning'
			})
			return false
		}
		if (!tryFormData.provinceId) {
			ElMessage({
				message: '请选择省',
				type: 'warning'
			})
			return false
		}
		if (!tryFormData.cityId) {
			ElMessage({
				message: '请选择市',
				type: 'warning'
			})
			return false
		}
		if (!tryFormData.userName) {
			ElMessage({
				message: '请输入联系人',
				type: 'warning'
			})
			return false
		}
		captchaImage().then((res) => {
			if (res.code == 0) {
				pageData.dialogVisible = true
				pageData.img = 'data:image/png;base64,'+ res.data.img
				pageData.uuid = res.data.uuid
			}
		})
	}
	/**
	 * 取消图形验证码
	 */
	function handleClose() {
		pageData.code = ''
		pageData.dialogVisible = false
	}
	/**
	 * 确定图形验证码
	 */
	function confirm() {
		if (!pageData.code) {
			ElMessage({
				message: '请输入计算结果',
				type: 'warning'
			})
			return false
		}
		let params = {
			uuid: pageData.uuid,
      		tel: tryFormData.userTel,
      		code: pageData.code
		}
		getTelRandomCode(params).then((res) => {
			if (res.code == 0) {
				ElMessage({
					message: '发送成功',
					type: 'success'
				})
				pageData.dialogVisible = false
				pageData.verShow = false
				const authTimer = setInterval(() => {
        		  	pageData.timer--
        		  	if (pageData.timer <= 0) {
        		  	  pageData.verShow = true
        		  	  pageData.timer = 60
        		  	  clearInterval(authTimer)
        		  	}
        		}, 1000)
			}
		})
	}
	//申请试用-提交
	function changeTryStatus(){
		if(pageData.tryStatus == 1){
			if (!tryFormData.storeName) {
				ElMessage({
					message: '请输入机构名称',
					type: 'warning'
				})
				return false
			}
			if (!tryFormData.storeCategory) {
				ElMessage({
					message: '请选择场馆类目',
					type: 'warning'
				})
				return false
			}
			if (!tryFormData.provinceId) {
				ElMessage({
					message: '请选择省',
					type: 'warning'
				})
				return false
			}
			if (!tryFormData.cityId) {
				ElMessage({
					message: '请选择市',
					type: 'warning'
				})
				return false
			}
			if (!tryFormData.userName) {
				ElMessage({
					message: '请输入联系人',
					type: 'warning'
				})
				return false
			}
			if (!tryFormData.userTel || !verify) {
				ElMessage({
					message: '请输入正确的手机号',
					type: 'warning'
				})
				return false
			}
			let params = {
				storeName: tryFormData.storeName,
				storeCategory: tryFormData.storeCategory,
				provinceId: tryFormData.provinceId,
				cityId: tryFormData.cityId,
				userName: tryFormData.userName,
				userTel: tryFormData.userTel,
				telCode: tryFormData.telCode
			}
			tryUse(params).then((res)=>{
				if(res.code == 0){
					pageData.tryStatus = 2
				}
			})
		}else{
			pageData.tryStatus = 1
		}
	}
	//登录
	const loginFormData = reactive({logingName:'',password:'',version:2});
	//输入框状态
	const userNameState = ref(0);
	const passWordState = ref(0);
	function loginClick(){
		pageData.formLoading = true;
		login(loginFormData).then(function(res){
			pageData.formLoading = false;
			if(res.code == 0){
				userNameState.value = 1;
				passWordState.value = 1;
				localStorage.setItem('businessToken',res.remark.access_token);
				localStorage.setItem('businessLoginData',JSON.stringify(res.data));
				if(res.data.length == 1 && res.data[0].sysUserStoreList.length == 1){ //单个账号
					var item = res.data[0].sysUserStoreList[0];
					enterStore(item,item.bid,item.id,router);
					// if(res.data.storeType == 2){ //分店
						// localStorage.setItem('businessToken',res.data.access_token);
						// getUserMenuListJson({access_token:res.data.access_token}).then((res2)=>{
						// 	localStorage.setItem('businessMenuList',JSON.stringify(res2.data));
						// 	localStorage.setItem('businessUserData',JSON.stringify(res2.remark));
						// 	setTimeout(()=>{
						// 		router.push('/branch-store/home');
						// 	},1000)
						// })
					// }else{ //总店
					// 	localStorage.setItem('businessToken',res.data.access_token);
					// 	getUserMenuListJson({access_token:res.data.access_token}).then((res2)=>{
					// 		localStorage.setItem('businessMenuList',JSON.stringify(res2.data));
					// 		localStorage.setItem('businessUserData',JSON.stringify(res2.remark));
					// 		setTimeout(()=>{
					// 			router.push('/main-store/home');
					// 		},1000)
					// 	})
					// }
				}else{   //多个账号
					ElMessage({
						message:'登录成功',
						type: 'success'
					})
					setTimeout(()=>{
						router.push('/store');
					},1000)
				}
			}else{
				userNameState.value = 2;
				passWordState.value = 2;
			}
		})
	}
	
	const uuid = ref('');
	//获取扫码登录唯一key
	function getUUid(){
		if(tabNum.value == 1){
			document.getElementById('qrcode').innerHTML = '';
			getUid({}).then((res)=>{
				if(res.code == 0){
					uuid.value = res.data;
					createQrCode(res.data);
				}
			})
		}
	}
	//生成二维码
	function createQrCode(uid){
		new QRCode('qrcode', {
		  width: 100,
		  height: 100, // 高度
		  text: location.origin +'/h5/scanCodeLoginPre.html?uuid='+uid, // 二维码内容
		  // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
		  background: '#fff'
		  // foreground: '#ff0'
		})
		pageData.codeLoading = false;
		qrCodeLoginState(uid);
	}
	//获取扫码登录状态
	function qrCodeLoginState(uid){
		if(tabNum.value == 1){
			checkLogin({uuid:uid}).then(function(res){
				if(res.code == 0){
					if(res.msg == 'wait'){
						setTimeout(()=>{
							qrCodeLoginState(uid);
						},2000)
					}else{
						var data = res.data;
						localStorage.setItem('businessToken',res.remark.access_token);
						var loginArray = [];
						loginArray.push(res.data.sysUser);
						console.log('loginArray',loginArray);
						localStorage.setItem('businessLoginData',JSON.stringify(loginArray));
						if(res.data.sysUserStoreList.length == 1){ //单个账号
							var item = res.data.sysUserStoreList[0];
							var sysUser = res.data.sysUser;
							enterStore(item,item.bid,item.id,router);
							// if(res.data.storeType == 2){ //分店
								// localStorage.setItem('businessToken',res.data.access_token);
								// getUserMenuListJson({access_token:res.data.access_token}).then((res2)=>{
								// 	localStorage.setItem('businessMenuList',JSON.stringify(res2.data));
								// 	localStorage.setItem('businessUserData',JSON.stringify(res2.remark));
								// 	setTimeout(()=>{
								// 		router.push('/branch-store/home');
								// 	},1000)
								// })
							// }else{ //总店
							// 	localStorage.setItem('businessToken',res.data.access_token);
							// 	getUserMenuListJson({access_token:res.data.access_token}).then((res2)=>{
							// 		localStorage.setItem('businessMenuList',JSON.stringify(res2.data));
							// 		localStorage.setItem('businessUserData',JSON.stringify(res2.remark));
							// 		setTimeout(()=>{
							// 			router.push('/main-store/home');
							// 		},1000)
							// 	})
							// }
						}else{   //多个账号
							ElMessage({
								message:'登录成功',
								type: 'success'
							})
							setTimeout(()=>{
								router.push('/store');
							},1000)
						}
						// localStorage.setItem('businessToken',res.remark.access_token);
						// if(data['total'] == 'many'){ //总店
						// 	localStorage.setItem('businessLoginData',data['data']);
						// 	ElMessage({
						// 		message:'登录成功',
						// 		type: 'success'
						// 	})
						// 	setTimeout(()=>{
						// 		router.push('/store');
						// 	},1000)
						// }else if(data['total'] == 'one'){  //分店
						// 	var token = data['data'];
						// 	getUserMenuListJson({access_token:token}).then((res)=>{
						// 		enterStore(1,null,);
						// 		// location.href = location.origin+'/cardmee/';
						// 		// location.href = httpUrl+'/cardmee/#/user/login/access_token='+loginData.data.access_token;
						// 	})
						// }
					}
				}else{
					alert('二维码已失效');
				}
				
			});
		}
	}
	function loginNow(){
		loginByOpenidPre({
			uuid:uuid.value,
			openid:'oeLsWwsbzm7Gy6L5WsLFy-H2dOWA',
		}).then((res)=>{
			if(res.code == 0){
				
			}
		})
	}
	
	onMounted(()=>{
		canvasBackgroundInit('login_top');
	})
	
</script>

<style lang="stylus" scoped>
	.login_container{
		position absolute;
		left 0;
		top 0;
		right 0;
		bottom 0;
		background-color white;
		// padding-bottom 240px;
		.login_logo{
			margin 0 auto;
			width 1120px;
			height 50px;
			span{
				font-size 16px;
				margin-left 10px;
				color #666;
			}
		}
		.login_top{
			width 100%;
			// height 100%;
			background:linear-gradient(to bottom,#000000 0%,#5788fe 100%);
			// background url(../assets/loginBg.png) no-repeat center center;
			background-size cover;
			position relative;
			min-height: 678px;
			#canvasBcakground{
				position absolute;
				left 0;
				top 0;
				z-index 50;
			}
			.login_top_form{
				position absolute;
				left 50%;
				top 50%
				transform translate(-50%,-50%);
				background-color rgba(255,255,255,0.1);
				border-radius 6px;
				padding 40px;
				width 664px;
				height 424px
				z-index 100;
				.login_top_box{
					width 100%;
					height 100%;
					background-color white;
					border-radius 6px
					padding 0 50px;
					.login_top_logo{
						width 192px;
					}
					.login_top_right{
						div{
							margin-bottom 20px;
						}
						.login_top_tab{
							color #656565;
							span.active{
								color #0051A4;
								font-size 22px;
								font-weight bold;
							}
						}
						.login_top_input{
							border-bottom 1px solid #D6D6D6;
							padding-bottom 5px;
							img{
								width 16px;
								margin-right 8px;
							}
							input{
								border none;
								background-color white;
							}
						}
						.login_top_code{
							background-color white;
							border 1px solid #ccc;
							border-radius 5px;
							padding 5px;
							margin 0;
							height 112px;
							width 112px;
						}
						.login_top_btn{
							background-color #409EFF;
							border-radius 100px;
							color white;
							height 32px;
							text-align center;
							line-height 32px;
						}
						
					}
					
				}
			}
			.login_top_try{
				position absolute;
				left 50%;
				top 50%;
				transform translate(-50%,-50%);
				background-color white;
				box-shadow 0 0 10px white;
				width 500px;
				height 604px;
				z-index 200;
				border-radius 6px;
				padding 40px 30px 30px 30px;
				.try_close{
					position absolute;
					right 10px;
					top 10px;
					width 28px;
					height 28px;
				}
				.try_close:hover .close {
					display: none
				}
				.try_close:hover .close1 {
					display: block
				}
				.close1 {
					display: none
				}
				.try_item{
					margin-bottom 30px;
					img.input_icon{
						width 16px;
						height 16px;
						margin-left: 3px;
					}
					.try_select_box{
						position relative;
						img{
							position absolute;
							z-index: 100;
							left: 5px;
						}
						img.input_icon{
							margin-top: 9px
							margin-left: 9px;
						}
					}
					.try_btn{
						background-color #409EFF;
						border-radius 4px;
						height 48px;
						line-height 48px;
						text-align center;
						color white;
						font-size 18px;
						font-weight bold;
						cursor pointer;
					}
					
				}
				.try_success{
					text-align center;
					margin-bottom 65px;
					img{
						width 210px;
						margin 60px 0 50px 0;
					}
					p{
						font-size 24px;
						color #0F3866;
						text-align center;
						margin-bottom 15px;
					}
				}
				h3{
					font-size 28px;
					font-weight bold;
					color #32384F;
					text-align center;
					margin-bottom 30px;
				}
			}
		}
		.login_bottom{
			margin-top 20px;
			height 214px;
			background-color #30343E;
			color white;
			.login_bottom_cont{
				left: 50%;
            	top: 50%;
            	margin-left: -355px;
            	margin-top: -107px;
				width 710px;
				.iphone{
					width: 16px;
					height: 17px;
					margin-right: 19px;
				}
				.address {
					width: 16px;
					height: 16px;
					margin-right: 19px;
				}
				.ewm {
            		display: flex;
            		justify-content: center;
            		align-items: center;
					margin-top: 20px
				}
			}

		}
	}
	
</style>
