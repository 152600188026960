<template>
    <div>
        商品管理
    </div>
</template>

<script>
</script>

<style>
</style>
