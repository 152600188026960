<template>
	<div class="store_container">
		<div class="h55 ww100 header">
			<div class="header_title ft16 fwb">账号信息</div>
		</div>
		<div class="main hh100 bg-fff" v-if="pageData.list.length > 0">
			<div class="rx-bc pl40 pr40 h170">
				<div class="main_info rx-sc">
					<img class="w80 h80 b_r_100" :src="pageData.list[storeNum].logoTag" alt="">
					<div class="ml20">
						<h3 class="fwb ft16 mb20 color-333">
							<span v-if="pageData.list.length == 1">{{pageData.list[storeNum].businessName}}</span>
							<el-dropdown @command="storeNum = index" v-if="pageData.list.length > 1">
								<span>
									{{pageData.list[storeNum].storeName}}
									<el-icon class="el-icon--right">
										<ArrowDown />
									</el-icon>
								</span>
								<template #dropdown>
									<el-dropdown-menu>
										<el-dropdown-item :command="index" v-for="(item,index) in pageData.list">{{item.storeName}}</el-dropdown-item>
									</el-dropdown-menu>
							    </template>
							</el-dropdown>
						</h3>
						<p v-if="pageData.list[storeNum].roleName"><span class="main_info_tag">{{pageData.list[storeNum].roleName}}</span></p>
					</div>
				</div>
				<div>
					<el-button v-if="pageData.list[storeNum].isLogin" type="primary" @click="goToStore(-1)">进入总店</el-button>
					<el-button @click="outLogin">退出登录</el-button>
				</div>
			</div>
			<div class="main_list" style="border-top: 20px solid #f0f5f9;">
				<div class="main_list_title pl20 ft14 color-333">
					我的分店（{{ pageData.list[storeNum].sysUserStoreList.length}}）
				</div>
				<ul class="pl10 pt20 pr10 pb20 main_list_ul">
					<template v-for="(item,index) in pageData.list[storeNum].sysUserStoreList">
						<li class="mb20" v-if="item.id > 0">
							<div class="bg-fff cx-bc" @click="goToStore(item)">
								<div class="rx-bc ww100">
									<div class="rx-cc">
										<img class="w40 mr10 b_r_100" :src="item.storeUrl" >
										<div>
											<div class="fwb ft16">{{item.storeName}}</div>
											<p class="color-409EFF">{{item.roleNames}}</p>
										</div>
									</div>
									<div class="main_info_tag" :class="{'color-FA6400' : item.storeGrade == 1}">
										{{item.storeGrade == 1 ? '直营店' : '加盟店'}}
									</div>
								</div>
								<div class="ww100">
									<div class="color-333 ft14">上次登录时间：{{item.lastLoginTimeStr || '-'}}</div>
									<div class="color-333 ft14 mt10">店铺到期时间：{{item.deadTimeStr}}</div>
								</div>
							</div>
						</li>
					</template>
					<li @click="createStore">
						<div class="bg-fff cx-cc create" style="background-color: #FCFCFC;">
							<p class="mb10">
								<img class="w30" src="../assets/addBank.png" >
							</p>
							<p class="ft16">创建分店</p>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>	
<script setup>
	import {ref,reactive} from 'vue'
	import {useRouter} from 'vue-router';
	import { ElMessage,ElMessageBox} from 'element-plus'
	import {ArrowDown} from '@element-plus/icons-vue'
	import {logout} from '../api/login.js';
	import {getLocalStorage,enterStore} from '../common/common.js';
	import {config} from '../common/config.js';
	const router = useRouter();
	const loginData = getLocalStorage('businessLoginData');
	const storeNum = ref(0);
	const pageData = reactive({
		list:[] //总店列表
	})
	//处理总店和分店数据
	loginData.map((n,i)=>{
		var obj = {
			id:n.id,
			bid:n.bid,
			storeName:n.storeName,
			businessName:n.businessName,
			logoTag:n.logoTag,
			isLogin:false
		}
		var arr = [];
		if(n.sysUserStoreList && n.sysUserStoreList.length > 0){
			n.sysUserStoreList.map((z,x)=>{
				if(z.id == 0){
					obj.isLogin = true;
				}else{
					arr.push(z);
				}
			});
		}
		obj.sysUserStoreList = arr;
		pageData.list.push(obj);
	})
	//进店
	function goToStore (item) {
		enterStore(item,loginData[storeNum.value].bid,item.id,router);
	}
	
	//创建店铺提示
	function createStore(){
		 ElMessageBox.alert('请联系您的专属顾问开通新店铺，或拨打客服4001006311或0571-88217623<br/>服务时间：周一至周五（9:00-18:00）','温馨提示',{
			confirmButtonText: '确认',
			dangerouslyUseHTMLString: true,
		 });
	}
	//退出登录
	function outLogin() {
		ElMessage({
			message:'退出成功',
			type: 'success'
		})
		setTimeout(()=>{
			localStorage.clear();
			sessionStorage.clear();
			router.push('/login');
		},1000)
	}
</script>
<style lang="stylus" scoped>	
	.store_container{
		background-color #F0F5F9;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		padding-top: 55px;
		overflow: auto;
		.header {
			position: fixed;
			left: 0;
			top: 0;
			background-image: linear-gradient(to right, #5172FF, #57B7FF);
		}
		
		.header_title {
			max-width: 1200px;
			margin: 0 auto;
			color: white;
			line-height: 55px;
		}
		
		.main {
			max-width: 1200px;
			margin: 0 auto;
		}
		
		.main_info_tag {
			display: inline-block;
			padding: 5px 8px;
			border-radius: 2px;
			color: #03A3FF;
			border: 1px solid #03A3FF;
		}
		
		.main_list {
			flex: 1;
		}
		
		.main_list_title {
			height: 48px;
			line-height: 48px;
			border-bottom: 1px solid #E6E6E6;
		}
		
		.main_list_ul {
			overflow: hidden;
		}
		
		.main_list_ul li {
			float: left;
			width: 33.33%;
			list-style-type: none;
			padding: 0px 10px;
		}
		
		.main_list_ul li>div {
			border: 1px solid #D6D6D6;
			padding: 20px;
			height: 176px;
		
		}
		
		.main_list_ul li>div:hover {
			cursor: pointer;
			border: 2px dashed #00A3FF !important;
		}
		
		.main_list_ul li>div .main_info_tag {
			background: rgba(0, 213, 176, 0.12);
		}
		
		.color-FA6400 {
			border-color: #FA6400;
			background-color: rgba(255, 83, 0, 0.12) !important;
			color: #FF5600;
		}
		
		.create {
			color: #bbb;
		}
		
		.main_list_ul li>div.create:hover {
			color: #808080;
		}
	}
</style>	
