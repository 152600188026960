<template>
	<div class="potential_member">
		<formQuery :queryOpts="pageData.queryOpts" @query="getTableData"></formQuery>
		<div class="mb12 rx-bc">
			<div>
				<el-button type="primary" @click="createMemberEvent('')" v-if="utils.getMenuRole(3140000)">新增潜在会员</el-button>
				<el-button @click="followPeopleEvent" v-if="utils.getMenuRole(3140001)">分配跟进人</el-button>
				<el-button @click="batchFollowEvent" v-if="utils.getMenuRole(3140004)">批量跟进</el-button>
				<el-button @click="batchSetLabelEvent" v-if="utils.getMenuRole(3140003)">打标签</el-button>
				<el-button class="mr16" @click="sethighSeasEvent" v-if="utils.getMenuRole(3140002)">投入公海</el-button>
				<el-dropdown @command="dropdownEvent">
					<el-button type="primary" plain v-if="utils.getMenuRole(3140005) || utils.getMenuRole(3140006)">
						更多操作<el-icon class="el-icon--right"><ArrowDown /></el-icon>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="1" v-if="utils.getMenuRole(3140005)">删除标签</el-dropdown-item>
							<el-dropdown-item command="2" v-if="utils.getMenuRole(3140006)">删除会员</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<el-button @click="exportMember" v-if="utils.getMenuRole(3140007)" :icon="Upload" >导出</el-button>
		</div>
		<div>
			<el-table :data="pageData.tableData" stripe style="width: 100%" @selection-change="selectionChange">
				<el-table-column fixed type="selection" width="55"/>
			    <el-table-column fixed label="会员姓名" width="110" show-overflow-tooltip >
					<template #default="scope">
						<span @click="memberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="telephoneNum" label="手机号" min-width="110" />
				<el-table-column prop="upStatusName" label="跟进状态" min-width="100" >
					<template #default="scope">
						<span>{{ scope.row.upStatusName || '暂无状态'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productTyp" label="意向级别" min-width="150">
					<template #default="scope">
						<el-rate v-model="scope.row.ifImportanceDegree" disabled size="large"></el-rate>
					</template>
				</el-table-column>
			    <el-table-column prop="adviserNames" label="跟进人" min-width="100" show-overflow-tooltip />
			    <el-table-column prop="channelName" label="会员来源" min-width="100" >
					<template #default="scope">
						<span>{{scope.row.channelName || '暂无来源'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="productNum" label="标签" min-width="100">
					<template #default="scope">
						<template v-if="scope.row.userTag && scope.row.userTag.length > 0">
							<span v-for="(item,index) in scope.row.userTag" :key="index" class="pl5 pr5 mr5" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
						</template>
					</template>
				</el-table-column>
			    <el-table-column prop="thisFollowTime" label="上次跟进时间" min-width="110" show-overflow-tooltip />
			    <el-table-column prop="nextFollowTime" label="下次跟进时间" min-width="110" show-overflow-tooltip />
				<el-table-column label="操作" width="160" fixed="right">
					<template #default="scope">
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3140008)" @click="createMemberEvent(scope.row.encryptionId)">编辑</a>
						<a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3140009)" @click="oneFollowEvent(scope.row)">跟进</a>
					</template>
				</el-table-column>
			</el-table>
			<div class="mt20 rx-ec">
			    <el-pagination
			      v-model:currentPage="pageData.searchForm.page"
			      :page-sizes="[15,20,30,50,100]"
			      :page-size="pageData.searchForm.limit"
			      layout="total, sizes, prev, pager, next, jumper"
			      :total="pageData.totalCount"
			      @size-change="pageSizeChange"
			      @current-change="()=>{getList()}"
			    >
			    </el-pagination>
			</div>
		</div>
		
		<!-- 分配跟进人 -->
		 <el-dialog
		     v-model="pageData.followPeopleShow"
		     title="选择跟进人"
		 	width="400px"
		     :before-close="()=>{pageData.followPeopleShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">跟进人：</span>
					<div>
						<el-select multiple collapse-tags v-model="pageData.followPeopleId" placeholder="请选择">
							<el-option v-for="(item,index) in pageData.followPeopleList" :key="item.id" :label="item.realName" :value="index">
							</el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.followPeopleShow = false">取消</el-button>
		        	<el-button type="primary" @click="followPeopleSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>

		<!-- 批量打标签 -->
		 <el-dialog
		     v-model="pageData.batchSetLabelShow"
		     title="批量分配标签"
		 	width="400px"
		     :before-close="()=>{pageData.batchSetLabelShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">选择标签：</span>
					<div>
						<el-select v-model="pageData.batchSetLabelId" placeholder="请选择">
							<template v-for="item in pageData.queryOpts.fields[5].options">
								<el-option v-if="item.value" :key="item.value" :label="item.label" :value="item.eId"></el-option>
							</template>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.batchSetLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchSetLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		
		<!-- 预约体验-->
		<el-dialog
		    v-model="pageData.orderShow"
		    :title="pageData.orderTitle"
			width="700px"
		    :before-close="()=>{pageData.orderShow = false}"
		    custom-class="popwechat"
		  >
		    <div class="pl20 pr20 pt20">
		    	<div class="rx-sc mb12">
					<div class="w200 mr16">
						<el-input type="text" v-model="pageData.orderForm.courseName" placeholder="输入课程名称"></el-input>
					</div>
					<div class="w200 mr16">
						<el-input type="text" v-model="pageData.orderForm.relName" placeholder="输入教练名称"></el-input>
						<!-- <el-select class="query_con" v-model="pageData.orderForm.name" placeholder="上课教练">
							<el-option v-for="item in pageData.coachList" :key="item.encryptionId" :label="item.realName" :value="item.encryptionId">
							</el-option>
						</el-select> -->
					</div>
					<div class="w200 mr16">
						<el-select v-model="pageData.orderForm.type" placeholder="请选择">
							<el-option label="最近七天" value=""></el-option>
							<el-option label="其他" value="1"></el-option>
						</el-select>
					</div>
					<div class="mr16" v-show="pageData.orderForm.type == 1">
						<el-date-picker
						        v-model="pageData.orderForm.timeIn"
						        type="daterange"
						        range-separator="至"
						        start-placeholder="开始日期"
						        end-placeholder="结束日期"
						      >
						      </el-date-picker>
					</div>
					<el-button class="query_btn_blue" @click="searchOrderList" type="small">查询</el-button>
				</div>
				<div>
					<el-table :data="pageData.orderList" stripe style="width: 100%;">
						<el-table-column fixed prop="courseName" label="课程名称" />
						<el-table-column prop="courseType" label="课程类型" >
							<template #default="scope">
								<span>{{scope.row.courseType == 11 ? '团体课' : scope.row.courseType == 12 ? '私教课' :''}}</span>
								<!-- <span>{{scope.row.courseType == 11 ? '团体课' : scope.row.courseType == 12 ? '私教课' :'班课'}}</span> -->
							</template>
						</el-table-column>
						<el-table-column prop="courseTacher" label="上课教练" />
						<el-table-column prop="channelName" label="上课场地" />
						<el-table-column prop="channelName" label="上课时间" />
						<el-table-column prop="channelName" label="预约人数" >
							<template #default="scope">
								<span>{{scope.row.num+'/'+scope.row.num}}</span>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="160">
							<template #default="scope">
								<a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="addMemberEvent(scope.row)">添加会员</a>
							</template>
						</el-table-column>
					</el-table>
				</div>
				<div class="mt20 rx-ec">
				    <el-pagination
				      v-model:currentPage="pageData.orderForm.page"
				      :page-size="15"
				      layout="total, prev, pager, next"
				      :total="pageData.orderCount"
				      @current-change="getOrderList"
				    >
				    </el-pagination>
				</div>
		    </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="pageData.orderShow = false">取消</el-button>
          <el-button @click="bindCourseSave">确定</el-button>
        </div>
      </template>
		</el-dialog>
		<!-- 批量删除标签 -->
		<el-dialog
		    v-model="pageData.deletLabelShow"
		    title="删除标签"
			width="600px"
		    :before-close="()=>{pageData.deletLabelShow = false}"
		    custom-class="popwechat"
		  >
		  <div class="pl20 pt50 pb50 pr20">
			  <div class="mb12">会员标签去重后如下,删除对应标签，点击【确认】即可批量去除会员名下对应标签。</div>
			  <ul class="delTagList rx-sc">
				  <li class="mr16 rx-cc" v-for="(item,index) in pageData.deletLabelList" :key="index">
					  <span class="pl10 pr10 pt10 pb10">{{item.tagName}}</span>
					  <span class="pl10 pr10 pt10 pb10 c_p" @click="delOneLabel(index)">X</span>
				  </li>
			  </ul>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.deletLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="deletLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<AddMember v-model="pageData.addMemberShow" :title="pageData.addMemberTitle" :id="pageData.memberId" @close="addMemberClose" @save="addMemberSave" flag="2"/>
		<AddFollowStep v-model="pageData.addFollowShow" :star="pageData.followStar" :sid="pageData.memberId" @close="addFollowClose" @save="addFollowSave"/>
		<ExportMember v-model="pageData.exportMemberShow" type="1" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
	</div>
</template>

<script setup>
	import {ref,reactive,onMounted} from 'vue';
	import {useRouter,onBeforeRouteLeave} from 'vue-router';
	import {useStore} from 'vuex'
	import { ElMessage} from 'element-plus'
	import { ArrowDown, Close, Upload } from '@element-plus/icons-vue'
	import utils from '@/common/utils.js'
	import {selectStoreTrailUser,studentInput,getStoreAccountStaffSimple,assignFollower,vipUpdateTagBatch,followUpTagList,deleteTagDetails,deleteMyCard} from '@/api/intentionList.js'
	import {courseList} from '@/api/course.js'
	import CommonTitle from '@/components/CommonTitle.vue'
	import Confirm from '@/components/Confirm.vue'
	import formQuery from '@/components/branch-store/formQuery.vue';
	import AddMember from '@/components/branch-store/member/AddMember.vue';
	import AddFollowStep from '@/components/branch-store/member/AddFollowStep.vue';
	import ExportMember from '@/components/branch-store/member/ExportMember.vue';
	const store = useStore();
	const router = useRouter();
	const confirmRef = ref();
	const pageData = reactive({
		followStatusIdList: [],
		memberId:'', //编辑潜在会员
		followStar:'',
		addMemberShow:false,
		
		followPeopleShow:false, //分配跟进人
		followPeopleList:[],
		followPeopleId:[],
		
		addFollowShow:false, //批量跟进
		
		
		batchSetLabelShow:false, //批量打标签
		batchSetLabelId:'',
		
		setHighSeasShow:false, //批量投入公海
		batchHighSeasList:[],
		batchHighSeasId:'',
		
		deletLabelShow:false,//批量删除标签
		deletLabelList:[],
		deletedLabelArr:[],
		
		confirmTitle:'', //通用确认框
		confirmText:'',
		
		orderShow:false,//预约体验
		orderTitle:'',
		orderCount:0,
		orderForm:{
			page:1,
			encryptionId: '',
			status: 1,
			type: '',
			relName: '',
			timeIn: [],
			courseName: ''
		},
		orderList:[],//课程列表
		// coachList:[],//教练列表
		
		exportMemberShow:false,
		
		tableData:[],
		totalCount:0,
		searchForm:{
			page:1,
			limit:15,
			searchKeys:'',
			followStatusId:'',
			ifImportanceDegree:'',
			adviserIds:'',
			channelId:'',
			tagList:''
		},
		queryOpts:{
			fields:[{
				type : 'input',
				fieldName : 'searchKeys',
				placehold : '请输入会员姓名/手机号'
			},{
				type : 'select',
				fieldName : 'followStatusId',
				placehold : '跟进状态',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'ifImportanceDegree',
				placehold : '意向级别',
				options : utils.getDict('starState',{label : '意向级别' ,value : ''}),
				value:''
			},{
				type : 'selectMany',
				fieldName : 'adviserIds',
				placehold : '跟进人',
				options : [],
				value:''
			},{
				type : 'select',
				fieldName : 'channelId',
				placehold : '会员来源',
				options : [],
				value:''
			},{
				fieldName : 'tagList',
				placehold : '标签',
				type:'selectMany',
				options : [],
				value:''
			},{
				type : 'dateRange',
				fieldName : 'lastFollowUpTimeStr',
				placeholder : '上次跟进时间',
				width:380,
				value:''
			},{
				type : 'dateRange',
				fieldName : 'nextFollowUpTimeStr',
				placeholder : '下次跟进时间',
				width:380,
				value:''
			}]
		}
	}) 
	// 不需要响应式监听的全局变量
	let confirmType = '',//通用确认调用类型
	nowTableRow = '',//操作-当前行数据
	selectCheckData = [];//批量多选数据集合;
	
	//获取字典
	utils.getDynaDict('followState',{label : '跟进状态' ,value : ''},function(res){
	    pageData.queryOpts.fields[1].options = res
		pageData.followStatusIdList = res
		getList();
	});
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[3].options = res;
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
	    pageData.queryOpts.fields[4].options = res;
	});
	utils.getDynaDict('upTagList','',function(res){
	    pageData.queryOpts.fields[5].options = res;
	});
	//获取跟进状态中文名称
	function getFollowStatusName(id){
		pageData.queryOpts.fields[1].options.map((n)=>{
			if(id == n.value){
				return n.label;
			}
		})
	}
	//筛选表单回调
	function getTableData(options){
		options.nextFollowUpTimeStr = options.nextFollowUpTimeStr ? options.nextFollowUpTimeStr.join('-') : '';
		options.lastFollowUpTimeStr = options.lastFollowUpTimeStr ? options.lastFollowUpTimeStr.join('-') : '';
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		searchTable();
	}
	
	//搜索table
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function getList(exportObj){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		formData.tagList = formData.tagList && formData.tagList.length > 0 ? formData.tagList.join(',') : '';
		formData.adviserIds = formData.adviserIds && formData.adviserIds.length > 0 ? formData.adviserIds.join(',') : '';
		if(exportObj){ //导出
			exportObj.exportData = 1;
			formData.page = 1;
			formData.limit = 9999;
			Object.assign(formData,exportObj);
		}
		selectStoreTrailUser(formData).then((res)=>{
			if(res.code == 0){
				if(exportObj){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.tableData = res.data && res.data.map((n)=>{
						n.ifImportanceDegree = utils.getStartNum(n.ifImportanceDegree); //特殊处理意向级别
						return n;
					});
					pageData.totalCount = res.count;
				}
			}
		})
	}
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
	
	// 导出
	function exportMember(){
		pageData.exportMemberShow = true;
	}
	function exportMemberSave(res){
		pageData.exportMemberShow = false;
		getList(res);
	}
	function exportMemberClose(){
		pageData.exportMemberShow = false;
	}
	
	// 新增潜在会员
	function createMemberEvent(id){
		pageData.memberId = id;
		pageData.addMemberTitle= id ? '编辑潜在会员' : '新增潜在会员';
		pageData.addMemberShow = true;
	}
	function addMemberSave(res){
		pageData.addMemberShow = false;
		searchTable();
	}
	function addMemberClose(){
		pageData.addMemberShow = false;
	}
	
	// 分配跟进人
	function followPeopleEvent(){
		CheckLengthCallBack(()=>{
			getStoreAccountStaffSimple().then((res)=>{
				if(res.code == 0){
					pageData.followPeopleList = res.data;
				}
			})
			pageData.followPeopleShow = true;
		})
	}
	
	// 分配跟进人保存
	function followPeopleSave(){
		if(pageData.followPeopleId.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择跟进人',
			})
			return false;
		}
		var arr = [];
		pageData.followPeopleId.map((n)=>{
			arr.push(pageData.followPeopleList[n]);
		})
		assignFollower({
			followerList:arr,
			studentIdList:getSelectCheckKey('cardMyId').split(','),
		}).then((res)=>{
			if(res.code == 0){
				pageData.followPeopleShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}
	// 批量跟进
	function batchFollowEvent(){ //批量
		CheckLengthCallBack(()=>{
			oneFollowEvent({cardMyId:getSelectCheckKey('cardMyId')});
		})
	}
	function oneFollowEvent(row){
		pageData.memberId = row.cardMyId;
		pageData.followStar = row.ifImportanceDegree;
		pageData.addFollowShow = true;
	}
	function addFollowSave(res){
		pageData.addFollowShow = false;
		searchTable();
	}
	function addFollowClose(){
		pageData.addFollowShow = false;
	}
	
	
	
	// 批量打标签
	function batchSetLabelEvent(){
		CheckLengthCallBack(()=>{
			pageData.batchSetLabelShow = true;
		})
	}
	// 批量打标签保存
	function batchSetLabelSave(){
		vipUpdateTagBatch({
			idStr:getSelectCheckKey('encryptionId'),
			encryptionId:pageData.batchSetLabelId,
		}).then((res)=>{
			if(res.code == 0){
				pageData.batchSetLabelShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}
	
	
	// 批量投入公海
	function sethighSeasEvent(){
		CheckLengthCallBack(()=>{
			pageData.confirmTitle = '是否将选择的会员投入公海？';
			confirmType = 'highSeas';
			confirmRef.value.show();
		})
	}
	// 解绑微信
	function unboundEvent(id){
		nowTableRow = id;
		pageData.confirmTitle = '确定解除该会员的微信绑定关系吗？';
		confirmType = 'unbound';
		confirmRef.value.show();
	}
	
	//预约体验
	function orderEvent(id){
		pageData.orderForm.encryptionId = id;
		searchOrderList();
		pageData.orderTitle = '预约体验'
		pageData.orderShow = true;
	}
	function searchOrderList(){
		pageData.orderForm.page = 1;
		getOrderList();
	}
	function getOrderList(){
		var obj = {};
		Object.assign(obj,pageData.orderForm);
		obj.timeIn = utils.getFormatDate2(obj.timeIn[0])+' - '+utils.getFormatDate2(obj.timeIn[1]);
		courseList(obj).then((res)=>{
			if(res.code == 0){
				pageData.coachList = res.data;
				pageData.orderCount = res.count;
			}
		})
	}
	// function getCoachList(){
	// 	getStoreAccountStaff({
	// 		page:1,
	// 		limit:9999
	// 	}).then((res)=>{
	// 		if(res.code == 0){
	// 			pageData.courseList = res.data;
	// 			pageData.orderCount = res.count;
	// 		}
	// 	})
	// }
	// 预约体验-添加会员
	let addNowRow = '';
	function addMemberEvent(row,type){ //type判断是否提示后进来
		addNowRow = row;
		if(!type && row.num == row.num){
			pageData.confirmTitle = '添加后将超出容纳人数，确定添加吗？';
			confirmType = 'addMember';
			confirmRef.value.show();
			return false;
		}
		productDelete({
			encryptionId:row.id
		}).then((res)=>{
			if(res.code == 0){
				ajaxSaveCallBack('添加成功');
				searchOrderList();
			}
		})
	}
	
	//批量删除标签
	function delOneLabel(index){
		pageData.deletedLabelArr.push(pageData.deletLabelList[index].id);
		pageData.deletLabelList.splice(index,1);
	}
	function deletLabelSave(){
		if(pageData.deletedLabelArr.length == 0){
			pageData.deletLabelShow = false;
			return false;
		}
		var ids = pageData.deletedLabelArr.join(',');
		deleteTagDetails({
			ids:getSelectCheckKey('encryptionId'),
			tagIds:ids
		}).then((res)=>{
			if(res.code == 0){
				pageData.deletLabelShow = false;
				ajaxSaveCallBack('删除成功');
			}
		})
	}
	//下拉框按钮操作事件
	function dropdownEvent(data){
		CheckLengthCallBack(()=>{
			if(data == 1){
				pageData.deletedLabelArr = [];
				if(selectCheckData.length == 1 && (!selectCheckData[0].userTag || selectCheckData[0].userTag.length == 0)){
					ElMessage({
						type: 'warning',
						message: '该会员暂无标签可删除',
					})
					return false;
				}
				followUpTagList({
					ids:getSelectCheckKey('encryptionId')
				}).then((res)=>{
					if(res.code == 0){
						pageData.deletLabelList = res.data;
						pageData.deletLabelShow = true;
					}
				})
			}else{
				pageData.confirmTitle = '是否删除勾选的会员？';
				confirmType = 'delete';
				confirmRef.value.show();
			}
		})
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'highSeas'){
			studentInput({
				studentIds:getSelectCheckKey('cardMyId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('操作成功');
				}
			})
		}else if(confirmType == 'delete'){
			deleteMyCard({
				ids:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}else  if(confirmType == 'unbound'){
			productDelete({
				encryptionId:nowTableRow
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('解绑成功');
				}
			})
		}else if(confirmType == 'addMember'){
			addMemberEvent(addNowRow,1);
		}
		
	}
	
	
	
	//会员详情
	function memberDetail(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}


	
	// 页面缓存
	onBeforeRouteLeave((to, from)=>{
	    if (to.path === '/branch/memberDetail') {
	        store.commit('SetKeep',{keepLiveRoute : ['IntentionList']});
	    } else {
	        store.commit('SetKeep',{keepLiveRoute : []});
	    }
	})
	
</script>

<style scoped lang="stylus">
	.delTagList{
		li{
			border 1px solid #ccc;
			span:first-child{
				border-right 1px solid #ccc;
			}
		}
	}
</style>
