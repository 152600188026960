<template>
    <workArea :barlist="barlist">
        <div class="pl35 pr35 pt25 pb25">
            <div class="nav">
                <ul class="step_box rx-bc pt10 pb10 pl100 pr100">
		        	<li class="rx-cc flex1">
		        		<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum == 1" :class="{'active':pageData.tabNum == 1}">1</div>
		        		<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum != 1}"><el-icon><Check /></el-icon></div>
		        		<div class="fwb ft16" :class="pageData.tabNum == 1 ? 'color-333' :'color-999'">预约时间段</div>
		        		<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
		        	</li>
		        	<li class="rx-cc flex1">
		        		<div class="step_icon ft18 fwb mr10" v-if="pageData.tabNum <= 2" :class="{'active':pageData.tabNum == 2}">2</div>
		        		<div class="step_icon ft18 fwb mr10" v-else :class="{'complete':pageData.tabNum > 2}"><el-icon><Check /></el-icon></div>
		        		<div class="fwb ft16" :class="pageData.tabNum == 2 ? 'color-333' :'color-999'">预约价格</div>
		        		<div class="step_right"><arrow-right style="width: 2.5em; height: 2.5em;color:#999" /></div>
		        	</li>
		        	<li class="rx-cc flex1">
		        		<div class="step_icon ft18 fwb mr10" :class="{'active':pageData.tabNum == 3}">3</div>
		        		<div class="fwb ft16" :class="pageData.tabNum == 3 ? 'color-333' :'color-999'">设置完成</div>
		        	</li>
		        </ul>
            </div>
            <div class="content">
                <div v-show="pageData.tabNum == 1">
                    <CommonTitle text="预约时间段设置"/>
                    <div>
                        <el-form ref="settingRef" :model="pageData.settingForm" :rules="settingRules" label-width="120px">
                            <el-form-item label="预约日期：" prop="dateType">
                                <el-radio-group v-model="pageData.settingForm.dateType" @change="changeDateType">
                                    <el-radio :label="0">
                                        <span class="color-333 d_i_b w50">不限制</span> 
                                    </el-radio>
                                    <el-radio :label="1">
                                        <span class="color-333">自定义</span> 
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <div class="ml120 mb20" v-if="pageData.settingForm.dateType == 1">
                                <el-date-picker
                                    style="height: 32px"
                                    v-model="pageData.settingForm.orderData"
                                    type="daterange"
                                    range-separator="至"
                                    format="YYYY-MM-DD"
                                    value-format="YYYY-MM-DD"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    :disabled-date="pageData.disabledDate"
                                />
                            </div>
                            <el-form-item label="循环设置：" prop="cycleType">
                                <el-radio-group v-model="pageData.settingForm.cycleType">
                                    <el-radio :label="0">
                                        <span class="color-333 d_i_b w50">每天</span> 
                                    </el-radio>
                                    <el-radio :label="1">
                                        <span class="color-333">自定义</span> 
                                    </el-radio>
                                </el-radio-group>
                            </el-form-item>
                            <div class="ml120 mb20" v-if="pageData.settingForm.cycleType == 1">
                                <el-checkbox v-model="pageData.checked1">
                                    <span class="color-333">周一</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked2">
                                    <span class="color-333">周二</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked3">
                                    <span class="color-333">周三</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked4">
                                    <span class="color-333">周四</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked5">
                                    <span class="color-333">周五</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked6">
                                    <span class="color-333">周六</span>
                                </el-checkbox>
                                <el-checkbox v-model="pageData.checked7">
                                    <span class="color-333">周日</span>
                                </el-checkbox>
                            </div>
                            <el-form-item label="可预约时间：" prop="dateType">
                                <div v-if="pageData.settingForm.cycleType == 0" class="w300 rx-c">
                                    <el-time-select
                                        v-model="pageData.settingForm.weekTime0startTime"
                                        :max-time="pageData.settingForm.weekTime0endTime"
                                        class="mr-4"
                                        placeholder="开始时间"
                                        start="00:00"
                                        :step="step== 30 ? '00:30' : '01:00'"
                                        :end="step == 30 ? '23:30' : '23:00'"
                                    />
                                    <span class="pl20 pr20">至</span>
                                    <el-time-select
                                        v-model="pageData.settingForm.weekTime0endTime"
                                        :min-time="pageData.settingForm.weekTime0startTime"
                                        placeholder="结束时间"
                                        start="00:00"
                                        :step="step == 30 ? '00:30' : '01:00'"
                                        :end="step == 30 ? '23:30' : '23:00'"
                                    />
                                </div>
                                <div v-else>
                                    <div class="rx-c mb20" v-show="pageData.checked1">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周一</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime1startTime"
                                                :max-time="pageData.settingForm.weekTime1endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime1endTime"
                                                :min-time="pageData.settingForm.weekTime1startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked2">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周二</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime2startTime"
                                                :max-time="pageData.settingForm.weekTime2endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime2endTime"
                                                :min-time="pageData.settingForm.weekTime2startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked3">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周三</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime3startTime"
                                                :max-time="pageData.settingForm.weekTime3endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime3endTime"
                                                :min-time="pageData.settingForm.weekTime3startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked4">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周四</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime4startTime"
                                                :max-time="pageData.settingForm.weekTime4endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime4endTime"
                                                :min-time="pageData.settingForm.weekTime4startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked5">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周五</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime5startTime"
                                                :max-time="pageData.settingForm.weekTime5endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime5endTime"
                                                :min-time="pageData.settingForm.weekTime5startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked6">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周六</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime6startTime"
                                                :max-time="pageData.settingForm.weekTime6endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime6endTime"
                                                :min-time="pageData.settingForm.weekTime6startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                    <div class="rx-c mb20" v-show="pageData.checked7">
                                        <div class="w450 rx-c">
                                            <div class="mr20 w100">周日</div>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime7startTime"
                                                :max-time="pageData.settingForm.weekTime7endTime"
                                                class="mr-4"
                                                placeholder="开始时间"
                                                start="00:00"
                                                :step="step== 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                            <span class="pl20 pr20">至</span>
                                            <el-time-select
                                                v-model="pageData.settingForm.weekTime7endTime"
                                                :min-time="pageData.settingForm.weekTime7startTime"
                                                placeholder="结束时间"
                                                start="00:00"
                                                :step="step == 30 ? '00:30' : '01:00'"
                                                :end="step == 30 ? '23:30' : '23:00'"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                        <div class="ml120 mt30">
                            <el-button @click="toCardList">返 回</el-button>
                            <el-button type="primary" @click="next(2)">下 一 步</el-button>
                        </div>
                    </div>
                </div>
                <div v-show="pageData.tabNum == 2">
                    <CommonTitle text="预约价格设置"/>
                    <div>
                        <el-button class="mb20" type="primary" @click="allSetprice">批量设置价格</el-button>
                        <el-table :data="pageData.priceList" stripe @selection-change="selectionChange">
                            <el-table-column type="selection" />
			            	<el-table-column prop="key" label="星期" >
                                <template #default="scope">
                                    {{ filtersType(scope.row.key) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderPrice" label="每场价格（元）" />
			            	<el-table-column label="操作" >
			            		<template #default="scope">
                                    <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="setprice(scope.row)">价格设置</a>
			            		</template>
			            	</el-table-column>
			            </el-table>
                        <div class="mt50">
                            <el-button @click="next(1)">上 一 步</el-button>
                            <el-button type="primary" @click="onSubmit">确定保存</el-button>
                        </div>
                    </div>
                </div>
                <div v-show="pageData.tabNum == 3" class="rx-cc">
		        	<div class="mt100 t_a_c">
		        		<div class="rx-cc">
		        			<circle-check-filled  style="width: 6em; height: 6em;color:#74C041"/>
		        		</div>
		        		<h3 class="ft24 mt20">设置完成</h3>
		        		<div class="mt20">
		        			<el-button @click="toCardList">返回列表</el-button>
		        		</div>
		        	</div>
		        </div>
            </div>
            <el-dialog
                v-model="pageData.dialogPlace"
                :title="pageData.placeTitle"
                :width="pageData.width"
                :before-close="handleClosePlace"
              >
                <div class="p20">
                    <div v-if="pageData.placeTitle == '价格设置'">
                        <el-table :data="pageData.childrenList" stripe  height="500">
                            <el-table-column type="selection" />
			            	<el-table-column prop="key" label="星期" >
                                <template #default="scope">
                                    {{ filtersType(scope.row.week) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="orderTimeInterval" label="预约时段"/>
                            <el-table-column prop="orderPrice" label="预约价格">
                                <template #default="scope">
                                   <div class="rx-c">
                                        <div class="w130 mr20">
                                            <el-input type="text" maxlength="6" v-model="scope.row.orderPrice" @input="(v) => (scope.row.orderPrice = v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))" >
                                                <template #append>元</template>
                                            </el-input>
                                        </div>
                                        <a href="javascript:;" class="t_d_u color-409EFF tool_btn ft12" @click="copy(scope.row.orderPrice)">复制到全部</a>
                                   </div>
                                </template>
                            </el-table-column>
			            </el-table>
                    </div>
                    <div v-else class="rx-c pt50 pb50">
                        <div class="w100 t_a_r">
                            <span class="color-red">*</span>
                            <span>价格：</span>
                        </div>
                        <el-input class="w300" type="text" maxlength="6" placeholder="请输入(保留2为小数)" v-model="pageData.allPrice"  @input="(v) => (pageData.allPrice = v.replace(/[^\d.]/g,'').replace(/\.{2,}/g, '.').replace(/^(\d+)\.(\d\d).*$/, '$1.$2'))" />
                    </div>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                      <el-button @click="handleClosePlace">取 消</el-button>
                      <el-button type="primary" @click="onSubmitPlace">确 定</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted, nextTick } from 'vue';
    import { Check, ArrowRight, CircleCheckFilled } from '@element-plus/icons-vue'
    import { useRouter } from 'vue-router';
    import { ElMessage } from 'element-plus'
    import workArea from '@/components/branch-store/workArea.vue';
    import CommonTitle from '@/components/CommonTitle.vue'
    import utils from '@/common/utils.js';
    import moment from 'moment'
    import { getSportRoomOrderSettinginfo, getSportRoomOrderSettingConfig } from '@/api/appstore.js';

    const router = useRouter();
    const settingRef = ref()
    const barlist = reactive([{ name : '返回',type:0 },{ name : '预约设置' }]); // 面包屑
    const { encryptionId, price, step, encryptionIds } = router.currentRoute.value.query;
    const pageData = reactive({
        dialogPlace: false,
        placeTitle: '',
        width: '',
        allPrice: '',
        tabNum: 1,
        settingForm: {
            dateType: 0,
            orderData: [],
            cycleType: 0,
            weekTime0startTime: '',
            weekTime0endTime: '',
            weekTime1startTime: '',
            weekTime1endTime: '',
            weekTime2startTime: '',
            weekTime2endTime: '',
            weekTime3startTime: '',
            weekTime3endTime: '',
            weekTime4startTime: '',
            weekTime4endTime: '',
            weekTime5startTime: '',
            weekTime5endTime: '',
            weekTime6startTime: '',
            weekTime6endTime: '',
            weekTime7startTime: '',
            weekTime7endTime: '',
        }, // 预约时间设置
        checked1: false,
        checked2: false,
        checked3: false,
        checked4: false,
        checked5: false,
        checked6: false,
        checked7: false,
        priceList: [],
        infrPriceList: [],
        childrenList: [],
        selectlist: [],
        disabledDate(time) {
          return time.getTime() < new Date().getTime() - 8.64e7 
        },
    })
    const settingRules = reactive({
        dateType: [{ required: true, message: '请选择预约日期', trigger: 'change' }],
        cycleType: [{ required: true, message: '请选择循环设置', trigger: 'change' }],
    })
    /**
     * 获取单个场地预约设置
     */
    function getInfo(){
        let params = {
            encryptionId: encryptionId
        }
        getSportRoomOrderSettinginfo(params).then((res) => {
            if (res.code == 0 && res.data) {
                pageData.settingForm.dateType = res.data.dateType
                pageData.settingForm.cycleType = res.data.cycleType
                pageData.settingForm.orderData = [res.data.orderStartDate, res.data.orderEndDate]
                pageData.settingForm.weekTime0startTime = res.data.weekTimeInterval0 ? res.data.weekTimeInterval0.split('-')[0] : ''
                pageData.settingForm.weekTime0endTime = res.data.weekTimeInterval0 ? res.data.weekTimeInterval0.split('-')[1] : ''
                pageData.settingForm.weekTime1startTime = res.data.weekTimeInterval1 ? res.data.weekTimeInterval1.split('-')[0] : ''
                pageData.settingForm.weekTime1endTime = res.data.weekTimeInterval1 ? res.data.weekTimeInterval1.split('-')[1] : ''
                pageData.settingForm.weekTime2startTime = res.data.weekTimeInterval2 ? res.data.weekTimeInterval2.split('-')[0] : ''
                pageData.settingForm.weekTime2endTime =  res.data.weekTimeInterval2 ? res.data.weekTimeInterval2.split('-')[1] : ''
                pageData.settingForm.weekTime3startTime =  res.data.weekTimeInterval3 ? res.data.weekTimeInterval3.split('-')[0] :''
                pageData.settingForm.weekTime3endTime =  res.data.weekTimeInterval3 ? res.data.weekTimeInterval3.split('-')[1] :''
                pageData.settingForm.weekTime4startTime =  res.data.weekTimeInterval4 ? res.data.weekTimeInterval4.split('-')[0] : ''
                pageData.settingForm.weekTime4endTime =  res.data.weekTimeInterval4 ? res.data.weekTimeInterval4.split('-')[1] : ''
                pageData.settingForm.weekTime5startTime =  res.data.weekTimeInterval5 ? res.data.weekTimeInterval5.split('-')[0] : ''
                pageData.settingForm.weekTime5endTime =  res.data.weekTimeInterval5 ? res.data.weekTimeInterval5.split('-')[1] : ''
                pageData.settingForm.weekTime6startTime =  res.data.weekTimeInterval6 ? res.data.weekTimeInterval6.split('-')[0] : ''
                pageData.settingForm.weekTime6endTime =  res.data.weekTimeInterval6 ? res.data.weekTimeInterval6.split('-')[1] : ''
                pageData.settingForm.weekTime7startTime =  res.data.weekTimeInterval7 ? res.data.weekTimeInterval7.split('-')[0] : ''
                pageData.settingForm.weekTime7endTime =  res.data.weekTimeInterval7 ? res.data.weekTimeInterval7.split('-')[1] : ''
                pageData.checked1 = res.data.weekTimeInterval1 ? true : false
                pageData.checked2 = res.data.weekTimeInterval2 ? true : false
                pageData.checked3 = res.data.weekTimeInterval3 ? true : false
                pageData.checked4 = res.data.weekTimeInterval4 ? true : false
                pageData.checked5 = res.data.weekTimeInterval5 ? true : false
                pageData.checked6 = res.data.weekTimeInterval6 ? true : false
                pageData.checked7 = res.data.weekTimeInterval7 ? true : false
                pageData.priceList = res.data.priceList
                pageData.infrPriceList = res.data.priceList
                let week = ''
                pageData.priceList.forEach(item => {
                    let arr = []
                    item.value.forEach(items => {
                        arr.push(Number(items.orderPrice).toFixed(2))
                        week = items.week
                    })
                    arr = [...new Set(arr)]
                    arr= arr.sort((n1,n2)=>{
                        return n2-n1;
                    });
                    if (item.key == week) {
                        if (arr.length>1) {
                            item.orderPrice = `${arr[arr.length-1]}~${arr[0]}`
                        }else {
                            item.orderPrice = arr[0]
                        }
                    }
                })
            }
        })
    }
    /**
     * 下一步
     */
    function next(val) {
        if (val == 1) {
            pageData.tabNum = val
            pageData.priceList = pageData.infrPriceList
        } else {
            if (pageData.settingForm.dateType == 1) {
                if (!pageData.settingForm.orderData || !pageData.settingForm.orderData.length) {
                    ElMessage.warning('请选择预约日期范围')
                    return
                }
            }
            if (pageData.settingForm.cycleType == 0) {
                if (!pageData.settingForm.weekTime0startTime || !pageData.settingForm.weekTime0endTime) {
                    ElMessage.warning('请选择可预约时间')
                    return
                }
                if (!pageData.priceList.length) {
                    pageData.priceList = [{key: 1,orderPrice: price, value:[]},
                        {key: 2,orderPrice: price, value:[]},
                        {key: 3,orderPrice: price, value:[]},
                        {key: 4,orderPrice: price, value:[]},
                        {key: 5,orderPrice: price, value:[]},
                        {key: 6,orderPrice: price, value:[]},
                        {key: 7,orderPrice: price, value:[]}]
                } else {
                    let arr = [ ...pageData.priceList]
                    let arr1 = [{key: 1,orderPrice: price, value:[]},
                        {key: 2,orderPrice: price, value:[]},
                        {key: 3,orderPrice: price, value:[]},
                        {key: 4,orderPrice: price, value:[]},
                        {key: 5,orderPrice: price, value:[]},
                        {key: 6,orderPrice: price, value:[]},
                        {key: 7,orderPrice: price, value:[]}]
                   let arr3 = [...arr, ...arr1]
                   pageData.priceList = sortKey(unRepeat(arr3), 'key')
                }
            }
            if (pageData.settingForm.cycleType == 1) {
                if (!pageData.checked1 && !pageData.checked2 && !pageData.checked3 && !pageData.checked4 && !pageData.checked5 && !pageData.checked6 && !pageData.checked7) {
                    ElMessage.warning('请选择循环设置')
                    return
                }
                let arr = []
                if (pageData.checked1) {
                    if (!pageData.settingForm.weekTime1startTime || !pageData.settingForm.weekTime1endTime) {
                        ElMessage.warning('请选择周一可预约时间')
                        return
                    }
                    arr.push({key: 1,orderPrice: price, value:[]})
                }
                if (pageData.checked2) {
                    if (!pageData.settingForm.weekTime2startTime || !pageData.settingForm.weekTime2endTime) {
                        ElMessage.warning('请选择周二可预约时间')
                        return
                    }
                    arr.push({key: 2,orderPrice: price, value:[]})
                }
                if (pageData.checked3) {
                    if (!pageData.settingForm.weekTime3startTime || !pageData.settingForm.weekTime3endTime) {
                        ElMessage.warning('请选择周三可预约时间')
                        return
                    }
                    arr.push({key: 3,orderPrice: price, value:[]})
                }
                if (pageData.checked4) {
                    if (!pageData.settingForm.weekTime4startTime || !pageData.settingForm.weekTime4endTime) {
                        ElMessage.warning('请选择周四可预约时间')
                        return
                    }
                    arr.push({key: 4,orderPrice: price, value:[]})
                }
                if (pageData.checked5) {
                    if (!pageData.settingForm.weekTime5startTime || !pageData.settingForm.weekTime5endTime) {
                        ElMessage.warning('请选择周五可预约时间')
                        return
                    }
                    arr.push({key: 5,orderPrice: price, value:[]})
                }
                if (pageData.checked6) {
                    if (!pageData.settingForm.weekTime6startTime || !pageData.settingForm.weekTime6endTime) {
                        ElMessage.warning('请选择周六可预约时间')
                        return
                    }
                    arr.push({key: 6,orderPrice: price, value:[]})
                }
                if (pageData.checked7) {
                    if (!pageData.settingForm.weekTime7startTime || !pageData.settingForm.weekTime7endTime) {
                        ElMessage.warning('请选择周日可预约时间')
                        return
                    }
                    arr.push({key: 7,orderPrice: price, value:[]})
                }
                 if (!pageData.priceList.length) {
                   pageData.priceList = arr
                } else {
                    let arr1 = [ ...pageData.priceList]
                    let arr2 = [...arr, ...arr1]
                    pageData.priceList = sortKey(unRepeat(arr2), 'key')
                }
            }
            pageData.priceList.forEach((row)=>{
                if (pageData.settingForm.cycleType == 0) {
                    setChildrenList(row, pageData.settingForm.weekTime0endTime, pageData.settingForm.weekTime0startTime)
                } else {
                    switch(row.key) {
		                case 1:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime1endTime, pageData.settingForm.weekTime1startTime)
		                    break;
                        case 2:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime2endTime, pageData.settingForm.weekTime2startTime)
		                    break;
                        case 3:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime3endTime, pageData.settingForm.weekTime3startTime)
		                    break;
                        case 4:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime4endTime, pageData.settingForm.weekTime4startTime)
		                    break;
                        case 5:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime5endTime, pageData.settingForm.weekTime5startTime)
		                    break;
                        case 6:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime6endTime, pageData.settingForm.weekTime6startTime)
		                    break;
                        case 7:
    	        	    	setChildrenList(row, pageData.settingForm.weekTime7endTime, pageData.settingForm.weekTime7startTime)
		                    break;
                    }
                }
            })
            settingRef.value.validate((valid) => {
                if (valid) {
                    pageData.tabNum = val
                } else {
                  console.log('error submit!!');
                  return false;
                }
            })
        }
    }
    /**
     * 数组去重
     */
    function unRepeat(arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.key) && res.set(arr.key, 1))
    }
    /**
     * 排序
     */
    function sortKey(array, key) {
      return array.sort((a, b) => {
        let x = a[key];
        let y = b[key];
        return x > y ? 1 : x < y ? -1 : 0;
      });
    }
    /**
     * 切换预约日期类型
     */
    function changeDateType(val){
        if (pageData.settingForm.orderData[0] == '2000-01-01'){
            pageData.settingForm.orderData = []
        }
    }
    function filtersType(value) {
        let text = ''
        let productTypeList = [
	    	{value: 1, label: '周一' },
	    	{value: 2, label: '周二' },
	    	{value: 3, label: '周三' },
	    	{value: 4, label: '周四' },
	    	{value: 5, label: '周五' },
	    	{value: 6, label: '周六' },
            {value: 7, label: '周日' }
	    ]
        productTypeList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }
    /**
     * 批量设置价格
     */
    function selectionChange(val){
        pageData.selectlist = val
    }
    /**
     * 批量设置价格
     */
    function allSetprice() {
        if (!pageData.selectlist.length) {
            ElMessage.warning('请选择需要设置价格的数据')
            return
        }
        pageData.placeTitle = '批量设置预约价格'
        pageData.width = '500px'
        pageData.dialogPlace = true
    }
    /**
     * 单个设置价格
     */
    function setprice(row) {
        pageData.placeTitle = '价格设置'
        if (pageData.settingForm.cycleType == 0) {
            setChildrenList(row, pageData.settingForm.weekTime0endTime, pageData.settingForm.weekTime0startTime)
        } else {
            switch(row.key) {
		        case 1:
    		    	setChildrenList(row, pageData.settingForm.weekTime1endTime, pageData.settingForm.weekTime1startTime)
		            break;
                case 2:
    		    	setChildrenList(row, pageData.settingForm.weekTime2endTime, pageData.settingForm.weekTime2startTime)
		            break;
                case 3:
    		    	setChildrenList(row, pageData.settingForm.weekTime3endTime, pageData.settingForm.weekTime3startTime)
		            break;
                case 4:
    		    	setChildrenList(row, pageData.settingForm.weekTime4endTime, pageData.settingForm.weekTime4startTime)
		            break;
                case 5:
    		    	setChildrenList(row, pageData.settingForm.weekTime5endTime, pageData.settingForm.weekTime5startTime)
		            break;
                case 6:
    		    	setChildrenList(row, pageData.settingForm.weekTime6endTime, pageData.settingForm.weekTime6startTime)
		            break;
                case 7:
    		    	setChildrenList(row, pageData.settingForm.weekTime7endTime, pageData.settingForm.weekTime7startTime)
		            break;
            }
        }
        pageData.width = '810px'
        pageData.dialogPlace = true
    }
    /**
     * 生成子元素列表
     */
    function setChildrenList(row, weekEndTime, weekStartTime){
        let meLength = moment('2022-06-03 '+ weekEndTime).diff('2022-06-03 '+ weekStartTime, "hours")
        let meLength1 = moment('2022-06-03 '+ weekEndTime).diff('2022-06-03 '+ weekStartTime, "minute")
        if (step == 30) {
            meLength = meLength1/30
        }
        let Object = {},childrenList = []
        let start = weekStartTime.split(':').map(Number)
        pageData.childrenList = []
        for(let i = 0; i < meLength; i++){
            Object = {
                week: row.key,
                orderPrice: Number(price).toFixed(2)
            }
            if (step == 60) {
                if (start[0]+i+1<=9) {
                    Object.orderTimeInterval = '0'+(start[0]+i)+':'+start[1]+'0-0'+(start[0]+i+1)+':'+start[1]+'0'
                } else if(start[0]+i+1 == 10) {
                    Object.orderTimeInterval = '0'+(start[0]+i)+':'+start[1]+'0-'+(start[0]+i+1)+':'+start[1]+'0'
                } else {
                    Object.orderTimeInterval = (start[0]+i)+':'+start[1]+'0-'+(start[0]+i+1)+':'+start[1]+'0'
                }
            } else {
                if (start[1] == 30) {
                    if(i%2 == 0){
                        if (start[0]+i/2+1<=9) {
                            Object.orderTimeInterval = '0'+(start[0]+i/2)+':'+start[1]+'-0'+(start[0]+i/2+1)+':00'
                        }  else if(start[0]+i/2+1 == 10) {
                            Object.orderTimeInterval = '0'+(start[0]+i/2)+':'+start[1]+'-'+(start[0]+i/2+1)+':00'
                        } else {
                            Object.orderTimeInterval = (start[0]+i/2)+':'+start[1]+'-'+(start[0]+i/2+1)+':00'
                        }
                    } else{
                        if (start[0]+((i-1)/2)+1<=9) {
                            Object.orderTimeInterval = '0'+(start[0]+((i-1)/2)+1)+':00-0'+(start[0]+((i-1)/2)+1)+':30'
                        }  else if(start[0]+((i-1)/2)+1 == 10) {
                            Object.orderTimeInterval = +(start[0]+((i-1)/2)+1)+':00-'+(start[0]+((i-1)/2)+1)+':30'
                        } else {
                            Object.orderTimeInterval = (start[0]+((i-1)/2)+1)+':00-'+(start[0]+((i-1)/2)+1)+':30'
                        }
                        
                    }
                } else {
                    if(i%2 == 0){
                        if ((start[0]+i/2)<=9) {
                            Object.orderTimeInterval = '0'+(start[0]+i/2)+':00-0'+(start[0]+i/2)+':30'
                        }  else if((start[0]+i/2) >= 10) {
                            Object.orderTimeInterval = (start[0]+i/2)+':00-'+(start[0]+i/2)+':30'
                        }
                    } else{
                        if ((start[0]+((i-1)/2)+1) <= 9) {
                            Object.orderTimeInterval = '0'+(start[0]+((i-1)/2))+':30-0'+(start[0]+((i-1)/2)+1)+':00'
                        }  else if((start[0]+((i-1)/2)+1) == 10) {
                            Object.orderTimeInterval = '0'+(start[0]+((i-1)/2))+':30-'+(start[0]+((i-1)/2)+1)+':00'
                        } else {
                            Object.orderTimeInterval = (start[0]+((i-1)/2))+':30-'+(start[0]+((i-1)/2)+1)+':00'
                        }
                    }
                }
            }
            if (!row.value.length) {
                pageData.childrenList.push(Object)
                row.value.push(Object)
            }
            childrenList.push(Object)
        }
        if (row.value.length) {
            if (row.value.length >= childrenList.length) {
                row.value.forEach(item => {
                    childrenList.find((v) => {
                        if (v.orderTimeInterval == item.orderTimeInterval ) {
                            v.orderPrice = Number(item.orderPrice).toFixed(2)
                        }
                    });
                })
                pageData.childrenList = childrenList
            } else {
                // setChildrenList(row, weekEndTime , row.value[row.value.length-1].orderTimeInterval.split('-')[1])
                row.value.forEach(item => {
                    childrenList.find((v) => {
                        if (v.orderTimeInterval == item.orderTimeInterval ) {
                            v.orderPrice = Number(item.orderPrice).toFixed(2)
                        }
                    });
                })
                pageData.childrenList = childrenList
            }
             row.value = childrenList
            if (childrenList.length >0) {
                let priceArr = [],priceArr1
                childrenList.forEach(items => {
                    priceArr.push(Number(items.orderPrice).toFixed(2))
                })
                priceArr = priceArr.map(Number)
                priceArr1 = Array.from(new Set(priceArr))
                priceArr1= priceArr1.sort((n1,n2)=>{
                    return n2-n1;
                });
                if (priceArr1.length>1) {
                    row.orderPrice = `${Number(priceArr1[priceArr1.length-1]).toFixed(2)}~${Number(priceArr1[0]).toFixed(2)}`
                }else {
                    row.orderPrice = Number(priceArr1[0]).toFixed(2)
                }
            }
            
        }    
    }
    /**
     * 复制到全部
     */
    function copy(val) {
        pageData.childrenList.forEach(item => {
            item.orderPrice = val
        })
    }
    /**
     * 取消设置
     */
    function handleClosePlace(){
        pageData.dialogPlace = false
    }
    /**
     * 确定设置价格
     */
    function onSubmitPlace(){
        if(pageData.placeTitle == '价格设置') {
            let week = '', arr=[]
            pageData.childrenList.forEach(item => {
                week = item.week
                arr.push(Number(item.orderPrice).toFixed(2))
            })
            arr = [...new Set(arr)]
            arr= arr.sort((n1,n2)=>{
                return n2-n1;
            });
            pageData.priceList.forEach(item => {
                if (item.key == week) {
                    item.value = pageData.childrenList
                    if (arr.length>1) {
                        item.orderPrice = `${arr[arr.length-1]}~${arr[0]}`
                    }else {
                        item.orderPrice = arr[0]
                    }
                }
            })
        } else {
            pageData.priceList.forEach(item => {
                pageData.selectlist.forEach( items => {
                    if (item.key == items.key) {
                        item.value.forEach(row => {
                            row.orderPrice = pageData.allPrice
                        })
                        item.orderPrice = pageData.allPrice
                    }
                })
            })
        }
        pageData.dialogPlace = false
    }
    /**
     * 确定保存
     */
    function onSubmit() {
        let params = {
            encryptionId: encryptionId ? encryptionId : encryptionIds,
            dateType: pageData.settingForm.dateType,
            cycleType: pageData.settingForm.cycleType,
        }
        if (pageData.settingForm.dateType == 1) {
            params.orderDateStr = pageData.settingForm.orderData.join('-')
        }
        if (pageData.settingForm.cycleType == 0) {
            params.weekTimeInterval0 = pageData.settingForm.weekTime0startTime + '-' + pageData.settingForm.weekTime0endTime
        } else {
            if (pageData.checked1) {
                params.weekTimeInterval1 = pageData.settingForm.weekTime1startTime + '-' + pageData.settingForm.weekTime1endTime
            }
            if (pageData.checked2) {
                params.weekTimeInterval2 = pageData.settingForm.weekTime2startTime + '-' + pageData.settingForm.weekTime2endTime
            }
            if (pageData.checked3) {
                params.weekTimeInterval3 = pageData.settingForm.weekTime3startTime + '-' + pageData.settingForm.weekTime3endTime
            }
            if (pageData.checked4) {
                params.weekTimeInterval4 = pageData.settingForm.weekTime4startTime + '-' + pageData.settingForm.weekTime4endTime
            }
            if (pageData.checked5) {
                params.weekTimeInterval5 = pageData.settingForm.weekTime5startTime + '-' + pageData.settingForm.weekTime5endTime
            }
            if (pageData.checked6) {
                params.weekTimeInterval6 = pageData.settingForm.weekTime6startTime + '-' + pageData.settingForm.weekTime6endTime
            }
            if (pageData.checked7) {
                params.weekTimeInterval7 = pageData.settingForm.weekTime7startTime + '-' + pageData.settingForm.weekTime7endTime
            }
        }
        let arr1 = [],arr2 = [],arr3 = [],arr4 = [],arr5 = [],arr6 = [],arr7 = []
        pageData.priceList.forEach(item=> {
            item.value.forEach(childrenItem=> {
                switch(item.key) {
		            case 1:
    		        	arr1.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 2:
    		        	arr2.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 3:
    		        	arr3.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 4:
    		        	arr4.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 5:
    		        	arr5.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 6:
    		        	arr6.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                    case 7:
    		        	arr7.push(childrenItem.orderTimeInterval+',' +childrenItem.orderPrice)
		                break;
                }
            })
        })
        params.week1Price = arr1.join(';')
        params.week2Price = arr2.join(';')
        params.week3Price = arr3.join(';')
        params.week4Price = arr4.join(';')
        params.week5Price = arr5.join(';')
        params.week6Price = arr6.join(';')
        params.week7Price = arr7.join(';')
        getSportRoomOrderSettingConfig(params).then(res => {
            if (res.code == 0) {
                pageData.tabNum = 3
            }
        })
    }
    /**
     * 返回列表
     */
    function toCardList(){
		router.push({path:'/branch/appstore/place'})
	}
    onMounted(() => {
        if (encryptionId){
            getInfo()
        }
    })
</script>
<style type='css/style' scoped>
    .step_box{
    	background-color: #FFFAE7
    }
    .step_box li{
    		position: relative;
    }
    .step_box li .step_icon{
    	width: 32px;
    	height: 32px;
    	line-height: 32px;
    	text-align: center;
    	border-radius: 100%;
    	border: 1px solid #909399;
    	color: #909399;
    }
     .step_box li .step_icon.active{
    	color: white;
    	background-color: #409EFF;
    	border-color: #409EFF;
    }
     .step_box li .step_icon.complete{
    	color: #409eff;
    	background: #fffae7;
    	border-color: #409EFF;
    }
    .step_box li .step_right{
    	position: absolute;
    	right: 0;
    	top: 0;
    }
</style>