<template>
    <el-form
        v-loading="loading"
        ref="ruleFormRef"
        :model="pageData.ruleForm"
        :rules="rules"
        label-width="130px"
        class="demo-ruleForm"
    >
        <el-row v-if="!Object.keys(propData.info).length">
            <el-col :span="12">
                <el-form-item label="会员姓名：" prop="encryptionCardMyId">
                    <el-select
					    v-model="pageData.ruleForm.encryptionCardMyId"
					    filterable
					    reserve-keyword
					    placeholder="请选择会员名称"
					    :remote-method="searchMember"
					    :loading="pageData.searchLoading"
                        @change="changeMember"
					>
					    <el-option v-for="item in pageData.memberList" :key="item.encryptionId" :label="item.realName" :value="item.encryptionId" />
					</el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="预约课程：" prop="courseNameId">
                    <el-select
					    v-model="pageData.ruleForm.courseNameId"
					    filterable
					    reserve-keyword
					    placeholder="请选择预约课程"
					    :remote-method="searchCourse"
					    :loading="pageData.searchLoading"
                        @change="getTeacher"
					>
					    <el-option v-for="item in pageData.courseLsit" :key="item.courseNameId" :label="item.courseName" :value="{
                            value:item.courseNameId,
                            label:item.courseName,
                            orderPrice: item.orderPrice,
                            courseTime: item.courseTime
                        }" />
					</el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="!Object.keys(propData.info).length">
            <el-col :span="12">
                <el-form-item label="上课教练：" prop="teacherId">
                    <el-select v-model="pageData.ruleForm.teacherId" placeholder="请选择上课教练" @change="changeTeacher">
                        <el-option v-for="item in pageData.teacherList" :key="item.id" :label="item.realName" :value="item.id" />
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="扣费方式：" prop="deduction">
                    <el-select v-model="pageData.ruleForm.deduction" value-key="value">
                        <el-option v-for="item in pageData.AvailableAssetsLsit" :key="item.value" :label="item.label" :value="item" />
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-show="pageData.ruleForm.deduction && !Object.keys(propData.info).length">
            <el-col>
                <el-form-item label="本次扣除：">
                    <span v-if="pageData.ruleForm.deduction.type == 3">{{ pageData.object.orderPrice }} 元</span>
                    <span v-if="pageData.ruleForm.deduction.type != 3 && pageData.ruleForm.deduction.type != '课程'">{{ pageData.ruleForm.deduction.consumeNum }} 次</span>
                    <span v-if="pageData.ruleForm.deduction.type == '课程'">{{ 1 }} 次</span>
                </el-form-item>
            </el-col>
        </el-row>
        <el-row v-if="pageData.itemData">
            <el-col>
                <el-form-item label="请选择预约时间：">
                    <b class="color-409EFF">已选择：{{ utils.getFormatDate3(pageData.itemData) }}</b>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
    <div class="tbody" v-if="pageData.ruleForm.teacherId">
        <table class="ww100 tableout" cellpadding="0" cellspacing="0">
            <thead>
                <tr >
                    <td class="w100 h50 rx-cc" style="border-bottom:1px solid #e5e5e5;">
                        <el-button class="query_btn_gray" type="default" @click="changeWeek(-1)">上周</el-button>
                    </td>
                    <td class="headcell" v-for="week in pageData.tableDataTitle" :key="week">{{week}}</td>
                    <td class="w100 h50 rx-cc" style="border-bottom:1px solid #e5e5e5; border-left: 1px solid #e5e5e5;">
                        <el-button class="query_btn_gray" type="default" @click="changeWeek(1)">下周</el-button>
                    </td>
                </tr>
            </thead>
                <tbody>
                    <tr v-for="(time, index) in pageData.tableData" :key="index">
                        <td class="w100" style="border-bottom:1px solid #e5e5e5;text-align:center;">{{ time.name }}</td>
                        <template v-for="week in time.weekArray" :key="week">
                            <td class="bodycell" style="vertical-align:top;">
                                <div class="ww100 hh100" v-if="week.blank && !week.disabled">
                                    <div class="ww100 h50">
                                        <div class="ww100 hh100" >
                                            <el-popover v-show="pageData.blankName == week.blank" :width="(week.courseArray.length+1)*70" :hide-after="0" trigger="hover">
                                                <template #reference>
                                                    <div @mouseenter="pageData.blankName = week.blank" @mouseleave="pageData.blankName = ''" class="rx-cc tableblank h50">
                                                        <div class="ww100 hh100 ft10 posi-r dotted" v-show="pageData.blankName == week.blank">
                                                            <div class="ww100 h50 rx-cc" >
                                                                <Plus class="mr5 color-main" style="height:10px;width:10px;"></Plus>
                                                                <span class="color-main">新增</span>
                                                            </div>
                                                        </div>
                                                        <div v-show="week.currentIndex != -1 && pageData.blankName != week.blank">已选：{{ utils.getFormatDate3(pageData.itemData).substring(11, 13) + ':' + utils.getFormatDate3(pageData.itemData).substring(14, 16) }}</div>
                                                    </div>
                                                    </template>
                                                    <div class="t_a_c bg-fff rx-cc p10 pt20 pb25">
                                                        <div class="d_i_b addBookPuls" v-for="(timer, index) in week.courseArray" :key="index">
                                                            <div class="w70 h40 bde cx-cc mb5 t_a_c c_p" v-if="timer.state != 4" :class="{active:week.currentIndex === index || timer.state ==5 , disabled:timer.state !=1&&timer.state !=5}" 
                                                                @click="selectTime(timer, week, index )">
                                                                <div class="ft12">{{timer.timeKey}}</div>
                                                                <div class="ft12" v-if="timer.state !=1">{{timer.state == 0 || timer.state == 3 ? '不可约' : timer.state == 2 ? '已满' : timer.state == 5 ? '已预约' : ' '}}</div>
                                                                <div class="ft12" v-if="timer.state == 1 && timer.allPeopleNum > 1 ">{{ timer.count }}/{{ timer.allPeopleNum }}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </el-popover>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="ww100 h50 disabled"></div>
                            </td>
                        </template>
                        <td class="w100" style="border-bottom:1px solid #e5e5e5;text-align:center; border-left: 1px solid #e5e5e5;">{{ time.name }}</td>
                    </tr>
                </tbody>
            
        </table>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted, watch  } from 'vue';
    import { Plus } from '@element-plus/icons-vue';
    import { teacherList } from '@/api/staff.js';
    import utils from '@/common/utils.js';
    import moment from "moment";
    import { selectStoreMembersSimple } from '@/api/member.js'
    import { ElMessage} from 'element-plus'
    import { courseList, appointment, getTeacherSchedule, getAvailableAssets, getAppointmentCards, storeBook, courseTimeEditPlus } from '@/api/course.js';
    const propData = defineProps({
		info : Object,
        tableWeekNum: Number
	})
    const emit = defineEmits(['onEditWork']);
    const ruleFormRef = ref()
    const pageData = reactive({
        cardMyId: '',
        period: 5,
        tableData      : reactive([]),
        loading: false,
        show: false,
        ruleForm: {
            encryptionCardMyId: '', // 会员姓名
            courseNameId: '', // 预约课程
            teacherId: '', // 上课教练
            deduction: '' // 扣费方式
        },
        memberList: [], // 会员选择框
        courseLsit: [], // 课程列表
        teacherList: [],  // 教练列表
        AvailableAssetsLsit: [], // 扣费方式列表
        tableDataTitle: [], // 表格数据
        tableWeekNum: 0, // 团课表格周
        blankName      : '',                 // 空格显示名称
        searchLoading:false,
        object: {
            type: '课程'
        }, // 选中的课程数据
        itemData:'',
    })
    const rules = reactive({
        encryptionCardMyId: [{ required: true, message: '请选择会员名称', trigger: 'change' }],
        courseNameId: [{ required: true, message: '请选择预约课程', trigger: 'change' }],
        teacherId: [{ required: true, message: '请选择上课教练', trigger: 'change' }],
        deduction: [{ required: true, message: '请选择扣费方式', trigger: 'change' }],
    })
    //搜索会员
	var timer = ''
    var timer1 = ''
    searchMember()
	function searchMember(key){
		clearTimeout(timer);
		timer = setTimeout(()=>{
			pageData.searchLoading = true;
			selectStoreMembersSimple({
				searchKeys:key,
                page: 1,
                limit: 9999
			}).then((res)=>{
				pageData.searchLoading = false;
				if(res.code == 0){
					pageData.memberList = res.data
				}
			})
		}, 100)
	}
    function changeMember(val) {
        (pageData.memberList).forEach((item) => {
            if (item.encryptionCardMyId == val) {
                pageData.cardMyId = item.cardMyId
            }
        })
        pageData.ruleForm.courseNameId = ''
        pageData.ruleForm.teacherId = ''
        pageData.ruleForm.deduction = ''
    }
    searchCourse()
    function searchCourse(key){
        clearTimeout(timer1);
		timer1 = setTimeout(()=>{
			pageData.searchLoading = true;
			courseList({
				courseName:key,
                limit:9999,
                courseType: 12
			}).then((res)=>{
				pageData.searchLoading = false;
				if(res.code == 0){
					pageData.courseLsit = res.data;
				}
			})
		}, 100)
	}
    function getTeacher(row) {
        if (!pageData.ruleForm.encryptionCardMyId) {
            pageData.ruleForm.courseNameId = ''
            // pageData.ruleForm.deduction = ''
            ElMessage({ type: 'warning', message: '请先选择会员' })
            return
        }
        pageData.ruleForm.teacherId = ''
        pageData.object.label = row.label
        pageData.object.value = row.value
        pageData.object.orderPrice = row.orderPrice
        pageData.object.courseTime = row.courseTime,
        pageData.object.type = '课程'
        let params = {
            encryptionCardMyId: pageData.ruleForm.encryptionCardMyId,
            courseNameId: row.value
        }
        appointment(params).then((res) => {
            if (res.code == 0) {
                pageData.teacherList = res.data.teacherList
            }
        })
    }


    function getCard() {
        let params = {
            encryptionCardMyId: pageData.ruleForm.encryptionCardMyId,
            courseNameId: pageData.ruleForm.courseNameId.value
        }
        getAvailableAssets(params).then((res) => {
            if (res.code == 0) {
                 pageData.AvailableAssetsLsit = []
                if (res.data.cardCount > 0) {
                    let obj = {
                        courseNameId: params.courseNameId,
                        encryptionCardMyId: params.encryptionCardMyId,
                        encryptionId: propData.info.encryptionId,
                        // cycleId: pageData.form.cycleId
                    }
                    getAppointmentCards(obj).then((reserve) => {
                        if ( reserve.code == 0)  {
                            reserve.results.list.forEach((item) => {
                                let params = {
                                    value: item.id,
                                    consumeNum: item.consumeNum,
                                    type: item.type
                                }
                                
                                if (item.type == 3) {
                                    if (utils.expired(item.startTime) > 0) {
                                        params.label = `${item.productName}(未开始)`
                                    } else if (utils.expired(item.endTime) <= 0) {
                                        params.label = `${item.productName}(已结束)`
                                    } else {
                                        params.label = `${item.productName}(可用${ item.storeMoneyGive+item.storeMoneyBase }元)`
                                    }
                                } else {
                                    if (item.productTyp == 3) {
                                        if (utils.expired(item.startTime) > 0) {
                                            params.label = `${item.productName}(未开始)`
                                        } else if (utils.expired(item.endTime) <= 0) {
                                            params.label = `${item.productName}(已结束)`
                                        } else {
                                            params.label = `${item.productName}`
                                        }
                                   } else {
                                       if (utils.expired(item.startTime) > 0) {
                                            params.label = `${item.productName}(未开始)`
                                        } else if (utils.expired(item.endTime) <= 0) {
                                            params.label = `${item.productName}(已结束)`
                                        } else {
                                            params.label = `${item.productName}(剩余${ item.productNum })`
                                        }
                                   }
                                }
                                if (res.data.ifOrder && item.type == 3) {
                                    pageData.AvailableAssetsLsit.push(params)
                                } else if(item.type != 3) {
                                    pageData.AvailableAssetsLsit.push(params)
                                }
                            })
                        }
                    })
                }
                if (res.data.courseCount > 0) {
                    pageData.object.label = pageData.object.label +'(剩余'+res.data.courseCount+'节)'
                    pageData.AvailableAssetsLsit.push(pageData.object)
                }
            }
        })
    }
    /**
     * 选择教练调用教练排期
     */
    function changeTeacher() {
        getCard()
        getTableData()
    }
    function makeDurationToSeconds(time) {
      let str = time
      let arr = str.split(':')
      let hs = parseInt(arr[0] * 3600)
      let ms = parseInt(arr[1] * 60)
      let seconds = hs + ms
      return seconds
    }
    //  秒数转化为时分秒
    function formatSeconds(value) {
        let second = parseInt(value)
        let minute = 0
        let hour = 0
        if (second > 60) {
            minute = parseInt(second / 60)
            second = parseInt(second % 60)
            if (minute > 60) {
              hour = parseInt(minute / 60)
              minute = parseInt(minute % 60)
            }
        }
        let result = ''
        if (minute > 0) {
          result = '' + parseInt(minute)
        }
        if (hour > 0) {
          result = '' + (parseInt(hour)>9? parseInt(hour) : ('0'+parseInt(hour))) + ':' + result
        }
        return result
    }
    // 查询表格内容
    function getTableData(){
        pageData.loading = true
        var param = {}
        param['startDate']   = pageData.tableWeekStart.slice(0,4) + pageData.tableWeekStart.slice(5,7) + pageData.tableWeekStart.slice(8,10)
        param['endDate']     = pageData.tableWeekEnd.slice(0,4) + pageData.tableWeekEnd.slice(5,7) + pageData.tableWeekEnd.slice(8,10)
        param['teacherId']   = pageData.ruleForm.teacherId;
        param['courseNameId']= pageData.ruleForm.courseNameId.value;
        param['cardMyId'] =  pageData.cardMyId

        getTeacherSchedule(param).then((res)=>{
            if(res.code != 0 ){
                ElMessage({ type: 'warning', message: res.message })
                return;
            }
            pageData.tableDataTitle = utils.getWeekArray(pageData.tableWeekStart); // 根据周一日期获取一周的日期名称
            var tableData   = []; // 表格数据集合
            var timeCourses = {};
            let timeStart = []
            let timeEnd = [];
            var courseList = Object.values(res.results)
            for (var i=0; i < courseList.length; i++ ) {
                let hash = {}; 
				courseList[i] = courseList[i].reduce((preVal, curVal) => {
					hash[curVal.timeStart] ? '' : hash[curVal.timeStart] = true && preVal.push(curVal); 
					return preVal 
				}, [])
                courseList[i].forEach((item) => {
                    let statrt = moment(parseInt(item.timeStart)).format('YYYY-MM-DD HH:mm:ss').substring(11,16)
                    let time = moment(parseInt(item.timeEnd)).format('YYYY-MM-DD HH:mm:ss').substring(11,16)
                    timeStart.push(makeDurationToSeconds(statrt))
                    timeEnd.push(makeDurationToSeconds(time))
                })
                timeCourses[i] = courseList[i]
            }
            let Minute = Math.max.apply(null, timeEnd)
            let Maxute = Math.min.apply(null, timeStart)
            let tartTime =  Number(Number(formatSeconds(Maxute).substring(0,2)))
            let endTime = Number(formatSeconds(Minute).substring(3,5)) > 0 ? Number(formatSeconds(Minute).substring(0, 2)) + 1 : Number(formatSeconds(Minute).substring(0,2))
            for(var i = 0; i < 24; i++) {
                if (i >= tartTime && i < endTime ) {
                    var hourStart = i+'';
                    hourStart     = hourStart.length == 1 ? '0'+hourStart : hourStart;
                    var hourEnd   = i+1 + '';
                    hourEnd       = hourEnd.length == 1 ? '0'+hourEnd : hourEnd;
                    var name      = hourStart + ':00 - ' + hourEnd + ':00';
                    var timeObj   = { name : name, weekArray : [] }
                   
                    for(var j = 0; j < 7; j++ ) { 
                        var dayCourse = { courseArray : [], blank: '',currentIndex: '-1', disabled: false }
                        if(timeCourses[j] && timeCourses[j].length){
                            let arr = []
                            timeCourses[j].forEach((item) => {
                                if(item.state !=4){
                                    if (Number(utils.getFormatDate3(item.timeStart).substring(11,13)) == i) {
                                         arr.push(item)
                                         item.interval = (item.timeEnd-item.timeStart)/1000/60
                                         dayCourse['blank'] = i + '-' + j
                                    }
                                }
                                
                            })
                            dayCourse.disabled = arr.findIndex((item) => item.state === 1) == -1 ? true : false
                            dayCourse['courseArray'] = arr;
                        }
                        timeObj.weekArray.push(dayCourse);
                    }
                   
                    tableData.push(timeObj);
                }
            }
            
            pageData.tableData = tableData
            pageData.loading = false
        }).catch(()=> {
            pageData.loading = false
        })
    }
    /**
     * 将每天分段
     */
    function againGroup (data, num){
        var result=[];
        for(var i=0,len=data.length; i<len; i+=num){
            result.push(data.slice(i, i+num))
        }
        return result
    }
    // 点击上一周/下一周 type：-1上周1下周
    function changeWeek(type){
        if(pageData.loading){ return; }
        pageData.tableWeekNum   = type == -1 ? pageData.tableWeekNum - 1 : pageData.tableWeekNum + 1;
        var tableWeekArray      = utils.getWeekDays(pageData.tableWeekNum);
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd   = tableWeekArray[1];
        getTableData();
    }
    /**
     * 表单验证
     */
    function formValidate() {
        if (Object.keys(propData.info).length) {
            let string = utils.getFormatDate3(pageData.itemData)
            let endString = utils.getFormatDate3(pageData.itemData + ((propData.info.classTimeEnd-propData.info.classTime)/100)*60*1000)
            let params = {
                ids: propData.info.encryptionId,
                bookingEndRule: 0,
                cancelEndRule: 0,
                autoConsumeRule: 0,
                leaveEndRule: 0,
                startTimeHour: string.substring(11, 13),
                startTimeMinute: string.substring(14, 16),
                endTimeHour:endString.substring(11, 13),
                endTimeMinute: endString.substring(14, 16),
                classDate: string.substring(0, 4)+string.substring(5, 7)+string.substring(8, 10)
            }
            courseTimeEditPlus(params).then((res)=> {
                if (res.code == 0) {
                    emit('onEditWork', true);
                    ElMessage({ type: 'success', message: '编辑成功' })
                }
            })
        } else {
            ruleFormRef.value.validate((valid) => {
                if (valid) {
                    if(!pageData.itemData){
                        ElMessage({ type: 'warning', message: '请选择上课时间' })
                        return;
                    }
                    let string = utils.getFormatDate3(pageData.itemData)
                    let endString = utils.getFormatDate3(pageData.itemData + pageData.object.courseTime*60*1000)
                    let params = {
                        ifPrivate: 1,
                        encryptionCardMyId: pageData.ruleForm.encryptionCardMyId,
                        type: 1,
                        ifRepeat: 1,
                        courseNameId: pageData.ruleForm.courseNameId.value,
                        classTime: string.substring(0, 4)+string.substring(5, 7)+string.substring(8, 10)+string.substring(11, 13)+string.substring(14, 16)+string.substring(17, 19),
                        classTimeEnd: endString.substring(0, 4)+endString.substring(5, 7)+endString.substring(8, 10)+endString.substring(11, 13)+endString.substring(14, 16)+endString.substring(17, 19),
                        courseTime: pageData.object.courseTime ,
                        courseTeacher: pageData.ruleForm.teacherId,
                        chargeType: pageData.ruleForm.deduction.type == '课程' ? 2 : 1,
                        orderId:  pageData.ruleForm.deduction.type == '课程' ? '' : pageData.ruleForm.deduction.value,
                        bookPeopleNum: 1,
                    }
                    storeBook(params).then((res)=> {
                        if (res.code == 0) {
                            emit('onEditWork', true);
                            ElMessage({ type: 'success', message: '预约成功' })
                        }
                    })
                } else {
                  console.log('error submit!!');
                  return false;
                }
            })
        }
    }
    /**
     * 选择时间
     */
    function selectTime(row, item, index) {
        if (row.state !=1) {
            return
        }
        pageData.tableData.forEach((obj) => {
            obj.weekArray.forEach((k) => {
                k.currentIndex = '-1'
            })
        })
        pageData.itemData = row.timeStart
        if (row.state == 1) {
            if (item.currentIndex && item.currentIndex == index) {
               item.currentIndex = ''
            } else {
                item.currentIndex = index
            }
        }
    }
    /**
     * 取消验证
     */
    function closeForm() {
        ruleFormRef.value.resetFields()
    }
    /**
     * 编辑课节回显
     */
    function getInfo() {
        pageData.ruleForm.teacherId = propData.info.teacherIds
        pageData.ruleForm.courseNameId = {
            value: propData.info.courseNameId
        }
        pageData.itemData = ''
        getTableData()
    }
    defineExpose({
	    formValidate,
        closeForm
	})
    onMounted(() => {
        var tableWeekArray = utils.getWeekDays(propData.tableWeekNum);
        pageData.tableWeekStart = tableWeekArray[0];
        pageData.tableWeekEnd = tableWeekArray[1];
        if (Object.keys(propData.info).length) {
            getInfo()
        } else {
            pageData.ruleForm.courseNameId = ''
        }
    })
</script>
<style type='css/style' scoped>
    .tableout{
        border:1px solid #e5e5e5;
        border-bottom: none;
    }
    .tableout1{
        border:1px solid #e5e5e5;
        border-bottom: none;
        border-top: none;
    }
    .headcell{
        width:11%;
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .bodycell{
        border-left:1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        text-align: center;
    }
    .addBookPuls{
        font-size: 0;
        vertical-align: middle;
    }
    .active{
        background-color: #03A3FF;
        border-color: #03A3FF;
        color: #FFF;
    }
    .disabled {
        background-color: #F7F7F7;
    }
    .tbody {
        max-height: 500px;
        overflow-y: auto;
    }
    .dotted {
        border: 1px dotted #03A3FF;
    }
</style>