<template>
    <div class="pt20">
        <div class="top rx-bc">
            <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                <el-form-item>
                   <el-input v-model="pageData.formInline.searchKeys" placeholder="会员姓名或手机号" clearable />
                </el-form-item>
                <el-form-item>
                  <el-select v-model="pageData.formInline.timeIn">
                    <el-option v-for="(item, index) in dataList" :key="index" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="pageData.formInline.timeIn == -1">
                    <el-date-picker
                        style="height: 32px"
                        v-model="pageData.formInline.searchTime"
                        type="daterange"
                        range-separator="至"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :disabled-date="pageData.disabledDate"
                    />
                </el-form-item>
                <el-form-item>
                    <el-select v-model="pageData.formInline.state" >
                        <el-option label="全部状态" value="" />
                        <el-option label="预约成功" value="1" />
                        <el-option label="预约取消" value="4" />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                </el-form-item>
            </el-form>
            <el-button class="mr16" :icon="Upload" @click="getList(true)">导出</el-button>
        </div>
        <div class="mt20" v-loading="pageData.loading">
            <el-table :data="pageData.tableData" stripe >
				<el-table-column prop="roomName" label="会员姓名" show-overflow-tooltip>
                    <template  #default="scope">
                        <a href="javascript:;" class="color-409EFF tool_btn ft12" @click="toMemberDetail(scope.row.encryptionCardMyId)">{{ scope.row.realName }}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="telephoneNum" label="手机号" show-overflow-tooltip/>
				<el-table-column prop="roomName" label="场地名称" show-overflow-tooltip />
				<el-table-column prop="orderTimeStr" label="预约场次" width="175"></el-table-column>
                <el-table-column label="预约状态" width="180">
					<template #default="scope">
                        <div v-if="scope.row.state == 4">
					    	<span class="d_i_b w10 h10 bdr8 badge1 mr5">
					    		<span class="d_i_b w5 h5 v_a_m bdr4 badge"></span>
					    	</span>
					    	<span>预约取消</span>
					    	<span class="ft10 ml5 color-999">{{ scope.row.updateBy == 0 ? '会员自主取消' : '教练代取消' }}</span>
					    </div>
                        <div v-else-if="scope.row.state == 5">
					    	<span class="d_i_b w5 h5 v_a_m bdr4 badge mr5"></span>
					    	<span>已请假</span>
					    </div>
					    <div v-else-if="scope.row.state == 6">
					    	<span class="d_i_b w5 h5 v_a_m bdr4 badge-success mr5"></span>
					    	<span>已补课</span>
					    </div>
					    <div v-else>
					    	<span class="d_i_b w5 h5 v_a_m bdr4 mr5" :class="scope.row.state == 1 ? 'badge-success' : scope.row.state == 3 ? 'badge-info' : 'badge-warning'"></span>
					    	<span>{{ filtersType(scope.row.state, 1) }}<span v-if="scope.row.state == 3" class="ft10 ml5 color-999">候补失败</span></span>
					    </div>
                    </template>
				</el-table-column>
                <el-table-column prop="createTime" label="预约时间" width="160" />
                <el-table-column label="计费方式" min-width="110">
			    	<template #default="scope">
			    		<div>{{ scope.row.costType == 1 ? '按时间计费' : '按人数计费' }}</div>
			    	</template>
			    </el-table-column>
                <el-table-column label="扣费支付方式" min-width="110">
			    	<template #default="scope">
			    		<div>{{ scope.row.productName ? scope.row.productName : '-' }}</div>
			    	</template>
			    </el-table-column>
                <el-table-column label="消费金额（元）" >
					<template #default="scope">
                        {{ scope.row.actualMoney? scope.row.actualMoney : '-' }}
                    </template>
				</el-table-column>
				<el-table-column label="操作" >
					<template #default="scope">
                        <a v-if="scope.row.state == 4 && utils.getMenuRole(3350202)" href="javascript:;" class="t_d_u color-409EFF" @click="seeDetail(scope.row)">查看退款详情</a>
						<a v-if="scope.row.state == 1 && utils.getMenuRole(3350201)" href="javascript:;" class="t_d_u color-409EFF" @click="cancel(scope.row.encryptionId)">取消预约</a>
					</template>
				</el-table-column>
			</el-table>
            <div class="mt20 rx-ec">
		        <el-pagination
		            v-model:currentPage="pageData.page"
		            :page-sizes="[15,20,30,50,100]"
		            :page-size="pageData.limit"
		            layout="total, sizes, prev, pager, next, jumper"
		            :total="pageData.totalCount"
		            @size-change="classPageSizeChange"
                    @current-change="pageChange"
		        >
		        </el-pagination>
		    </div>
        </div>
		<el-dialog
		    v-model="pageData.dialogVisible"
		    title="查看退款详情"
		    width="370px"
		  >
		    <div class="p20 pt30 pb30">
				<div class="timeline_left">
					<img class="w15 h15 mr15 v_a_m" src="@/assets/order1.png" alt="">
					<span class="color-666">会员已取消预约，已发起自动退款</span>
				</div>
				<div class="h25" style="line-height: 25px">
					<el-divider direction="vertical" border-style="dashed" />
				</div>
				<div class="timeline_right">
					<div v-if="pageData.activities.refundState == 1">
						<img class="w15 h15 mr15 v_a_m" src="@/assets/order2.png" alt="">
						<span class="color-666">退款成功，费用已退回至原支付账户</span>
					</div>
					<div v-if="pageData.activities.refundState == 3">
						<img class="w15 h15 mr15 v_a_t" style="margin-top: 2px;" src="@/assets/order3.png" alt="">
						<div class="color-666 d_i_b w300" v-if="pageData.activities.buyPayType == 0">退款失败，会员预约付费所使用的资产已失效，请联系会员线下处理</div>
						<div class="color-666 d_i_b w300 " v-else>退款失败，门店商户账户可用余额不足，请到订单管理中找到该笔订单进行手动退款</div>
					</div>
				</div>
			</div>
		    <template #footer>
		    	<span class="dialog-footer">
		    	  <el-button type="primary" @click="pageData.dialogVisible = false">好 的</el-button>
		    	</span>
		    </template>
		</el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search, Upload } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import { useRouter } from 'vue-router';
    import { sportRoomOrderList, storeOrderRoomCancel } from '@/api/appstore.js';
    import utils from '@/common/utils.js';
    import Confirm from '@/components/Confirm.vue'
    const confirmRef = ref()
    const router = useRouter();
    const dataList =[
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '近7天' },
        { value : "20" , label : '本月' },
        { value : "-1" , label : '其他'}
    ]
    const pageData = reactive({
        dialogVisible: false, // 退款详情
        activities: {},
        confirmTitle: '',
        confirmText: '',
        loading: false,
        formInline:{
            searchKeys: '',
            timeIn: '5',
            searchTime: [],
            state: ''
        },
        page: 1,
        limit: 15,
        totalCount: 0,
        tableData: [],
        disabledDate(time) {
          return time.getTime() > new Date().getTime()
        },
    })
    function filtersType(value) {
        let text = ''
        const stateList = [
	    	{label : '预约成功' ,value : '1'},
	    	{label : '预约取消' ,value : '4'}
	    ]
        stateList.forEach((item) => {
            if (item.value == value) {
                text = item.label
    		}
        })
        return text
    }
    /**
     * 搜索
     */
    function search() {
        pageData.page = 1
        getList()
    }
    /**
     * 获取场地列表数据
     */
    function getList(isExport) {
        let params = {
            searchKeys: pageData.formInline.searchKeys,
            state: pageData.formInline.state,
            timeIn: pageData.formInline.timeIn,
            roomId: pageData.formInline.roomId,
            page: pageData.page,
            limit: pageData.limit
        }
        if(isExport){ //导出
			params.exportData = 1;
            params.page = 1
            params.limit = 9999
		} else {
            pageData.loading = true
        }
        if (pageData.formInline.timeIn == -1) {
            if (!pageData.formInline.searchTime || !pageData.formInline.searchTime.length) {
                ElMessage.warning('请输入自定义日期')
                return
            }
            params.searchTime = pageData.formInline.searchTime.join('-')
        }
        
        sportRoomOrderList(params).then(res => {
            pageData.loading = false
            if (res.code == 0) {
                if (isExport) {
                    ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
                } else {
                    pageData.totalCount = res.count
                    pageData.tableData = res.data
                }
            }
        }).catch(()=>{
            pageData.loading = false
        })
    }
    /**
     * 查看退款详情
     */
    function seeDetail(row) {
        pageData.activities = row
		pageData.dialogVisible = true
    }
    let rowId = '';
    function cancel(id){
		rowId = id;
		pageData.confirmTitle = '确定取消预约吗？';
		pageData.confirmText = '会员预约成功时已扣费，取消预约后将归还会员资产！';
		confirmRef.value.show();
	}
    /**
     * 取消预约
     */
    function confirmSave() {
        let params = {
            encryptionId: rowId
        }
        storeOrderRoomCancel(params).then(res => {
            if (res.code == 0) {
                ElMessage({
					type: 'success',
					message: '取消成功'
				})
                getList()
            }
        })
    }
    /**
     * 列表一页几条
     */
    function classPageSizeChange(val) {
        pageData.limit = val
    }
    /**
     * 列表翻页
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    //会员详情
	const toMemberDetail = function(id){
		router.push({path:'/branch/memberDetail',query:{encryptionId:id}});
	}
    onMounted(() => {
        getList()
    })
</script>
<style type='css/style' scoped>
.top ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 0;
    }
    .badge {
		background: #FA6400;
		box-shadow: 0 0 5px rgba(250, 100, 0, 1);
	}
	.badge-success {
		background: #44D7B6;
		box-shadow: 0 0 5px rgba(68, 215, 182, 1);
	}
	.badge-info {
		background: #ccc;
		box-shadow: 0 0 5px rgba(204, 204, 204, 1);
	}
	.badge-warning {
		background: #F7B500;
		box-shadow: 0 0 5px rgba(247, 181, 0, 1);
	}
    .el-divider--vertical {
        border-color: #ccc
    }
</style>
