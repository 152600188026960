<template>
	<div>
		<formQuery ref="queryOptsRef" :queryOpts="queryOpts" @query="searchGetList"></formQuery>
		<div class="ww100 h30 rx-bc">
			<div class="rx-sc">
				<el-button type="primary" @click="pullUp(1)" v-if="utils.getMenuRole(3220000)">新增收入</el-button>
				<el-button type="danger" plain @click="pullUp(2)" v-if="utils.getMenuRole(3220001)">新增支出</el-button>
			</div> 
			<div class="rx-ec">
				<el-button @click="costType" v-if="utils.getMenuRole(3220003)">费用类型</el-button>
				<el-button @click="setExamine" v-if="utils.getMenuRole(3220002)">审核设置</el-button>
				<el-button v-if="utils.getMenuRole(3220008)" @click="getList(true)"  :icon="Upload" >导出</el-button>
			</div>
		</div>
		<div class="ww100 mt16">
			<el-table class="ww100" :data="pageData.query.tableData" stripe v-loading="pageData.loading">
				<el-table-column prop="title" label="收支类型" ></el-table-column>
                <el-table-column label="金额">
                    <template #default="scope">
                        ￥{{ scope.row.amount }}
                    </template>
                </el-table-column>
				<el-table-column label="类别">
                    <template #default="scope">
                        <el-button size="small" :type="scope.row.type ? 'primary' : 'danger'">{{ scope.row.type ? '收入' : '支出' }}</el-button>
                    </template> 
                </el-table-column>
				<el-table-column label="支付方式">
                    <template #default="scope">
                        {{ filtersType(scope.row.payMethodId) }}
                    </template> 
                </el-table-column>
				<el-table-column label="状态">
                    <template #default="scope">
                        <el-button v-if="scope.row.state == 0" size="small" type="primary">审核拒绝</el-button>
                        <el-button v-if="scope.row.state == 1" size="small" type="success">待审核</el-button>
                        <el-button v-if="scope.row.state >= 2" size="small" type="danger" plain>已录入</el-button>
                    </template>
                </el-table-column>
				<el-table-column prop="updateByName" label="操作员" />
				<el-table-column prop="memberName" label="会员姓名" />
                <el-table-column label="关联订单号" width="120" show-overflow-tooltip>
                    <template #default="scope">
                        <div v-if="scope.row.batchCode">
                            <a href="javascript:;" v-if="scope.row.encryptionFinanceId" class="t_d_u color-409EFF tool_btn d_i_b ww100 maxLength"  @click="details(scope.row)">{{ scope.row.batchCode }}</a>
                            <span v-else>{{ scope.row.batchCode }}</span>
                        </div>
                        <span v-else>-</span>
                    </template>
                </el-table-column>
				<el-table-column prop="happenTime" label="操作时间" width="160" />
				<el-table-column prop="checkUserName" label="审核人" />
                <el-table-column prop="remark" label="备注" />
				<el-table-column width="160" fixed="right" align="center" label="操作">
                    <template #default="scope">
                        <div v-if="scope.row.state < 2">
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3220005)" @click="edit(scope.row)">修改</a>
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="scope.row.state == 1 && utils.getMenuRole(3220006)" @click="examine(scope.row)">审核</a>
                            <a href="javascript:;" class="t_d_u color-409EFF tool_btn" v-if="utils.getMenuRole(3220007)" @click="remove(scope.row)">删除</a>
                        </div>
                    </template>
                </el-table-column>
                <template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
			</el-table>
			<div class="mt20 rx-bc" v-show="pageData.query.tableData.length >= 0">
                <div>
                    <p class="p10 pt0">支出：已录入<span class="color-red mr5">{{ pageData.query.remark && pageData.query.remark.amountOutPass }}</span>元/ 待审核 <span class="color-red mr5">{{ pageData.query.remark && pageData.query.remark.amountOutWait }}</span> 元</p>
                    <p class="p10 pt0">收入：已录入<span class="color-red mr5">{{ pageData.query.remark && pageData.query.remark.amountInPass }}</span>元/ 待审核 <span class="color-red mr5">{{ pageData.query.remark && pageData.query.remark.amountInWait }}</span> 元</p>
                </div>
				<el-pagination
				  v-model:currentPage="pageData.query.searchForm.page"
				  :page-sizes="[15,20,30,50,100]"
				  :page-size="pageData.query.searchForm.limit"
				  layout="total, sizes, prev, pager, next, jumper"
				  :total="pageData.query.totalCount"
				  @size-change="pageSizeChange"
				  @current-change="()=>{getList()}"
				>
				</el-pagination>
			</div>
		</div>
	</div>

    <el-dialog
        v-model="pageData.dialogVisible"
        :title="pageData.title"
        :width="pageData.width"
        :before-close="handleClose"
      >
        <div class="p20">
            <div v-if="pageData.title == '审核设置'">
                <label class="mr10 w100 d_i_b t_a_r">是否需要审核：</label>
                <el-radio-group class="v_a_m" v-model="pageData.checkType">
                    <el-radio :label="0">不需要</el-radio>
                    <el-radio :label="1">需要</el-radio>
                </el-radio-group>
                <div v-show="pageData.checkType" class="setExamine mt20">
                    <label class="mr15 w100 d_i_b t_a_r">审核人员：</label>
                    <el-select
                        v-model="pageData.checkUser"
                        multiple
                        collapse-tags
                        placeholder="请选择审核人员"
                      >
                        <el-option
                          v-for="item in pageData.checkUserList"
                          :key="item.id"
                          :label="item.realName"
                          :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <div v-else-if="pageData.title == '审核账单'">
                <el-form ref="examineFormRef" :model="examineFormReForm" :rules="rules" label-width="120px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="类型:">
                                <span>{{ examineFormReForm.title }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="类别:">
                                <span>{{ examineFormReForm.type ? '收入' : '支出' }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="金额（元）:">
                                <span>{{ examineFormReForm.amount }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="支付时间:">
                                <span>{{ examineFormReForm.happenTime }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="录入人:">
                                <span>{{ examineFormReForm.updateByName }}</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item :label="examineFormReForm.type ? '收入人员:' : '支出人员:'">
                                <span>{{ examineFormReForm.happenByName }}</span>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="是否通过:">
                                <el-select v-model="examineFormReForm.state" placeholder="please select your zone">
                                    <el-option label="通过" :value="2"></el-option>
                                    <el-option label="不通过" :value="0"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-form-item label="备注:">
                                <el-input
                                  v-model="examineFormReForm.remark"
                                  :autosize="{ minRows: 4 }"
                                  type="textarea"
                                  placeholder="请输入内容"
                                >
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div class="formRef" v-else-if="pageData.title != '费用类型'">
                <el-form ref="formRef" :model="form" :rules="rules" label-width="120px">
                    <el-form-item label="类型" prop="typeId">
                        <el-select v-model="form.typeId">
                            <el-option
                                v-for="item in pageData.classifyTableData"
                                :key="item.id"
                                :label="item.title"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="金额" prop="amount" required>
                       <el-input v-model="form.amount" placeholder="请输入金额" />
                    </el-form-item>
                    <el-form-item :label="pageData.title == '新增收入:'? '收入人员:' :'支出人员:'">
                        <el-select v-model="form.happenBy">
                            <el-option
                                v-for="item in pageData.checkUserList"
                                :key="item.id"
                                :label="item.realName"
                                :value="item.id"
                            >
                            </el-option>
                        </el-select>
                        <span class="tips mt10">注：如不选择，系统默认为录入人！</span>
                    </el-form-item>
                    <el-form-item :label="pageData.title == '新增收入:'? '收入日期:' :'支出日期:'">
                        <el-date-picker
                            v-model="form.happenTimeStr"
                            type="datetime"
                            placeholder="请选择开课日期"
                            format="YYYY-MM-DD HH:mm:ss"
                            value-format="YYYY-MM-DD HH:mm:ss"
                        >
                        </el-date-picker>
                        <span class="tips mt10">注：如不选择，系统默认为当前时间！</span>
                    </el-form-item>
                    <el-form-item label="支付方式" prop="payMethodId">
                        <el-select v-model="form.payMethodId" placeholder="请选择支付方式">
                            <el-option
                                v-for="item in utils.getDict('waterPayType')"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="备注">
                        <el-input
                          v-model="form.remark"
                          :autosize="{ minRows: 4 }"
                          type="textarea"
                          placeholder="请输入内容"
                        >
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-else>
                <div class="rx-ec">
			    	<el-button class="mr15" type="primary" @click="classify(0, 1)"  v-if="utils.getMenuRole(3220004)">新增分类</el-button>
                    <formQuery ref="classQueryRef" :queryOpts="innerQueryOpts" @query="classSearchGetList"></formQuery>
			    </div>
                <el-table :data="pageData.classifyTableData" stripe v-loading="pageData.loading">
		        	<el-table-column prop="title" fixed label="名称" />
                    <el-table-column label="类别">
                        <template #default="scope">
                            <div class="ml5 d_i_b" :class="scope.row.type == 1 ? 'tag_blue' : 'tag_red'">{{ scope.row.type == 1 ? "收入" : "支出" }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="渠道">
                        <template #default="scope">
                            <div class="ml5 d_i_b" :class="scope.row.sourceFrom == 1 ? 'tag_blue' : 'tag_red'">{{ scope.row.sourceFrom == 1 ? "手工录入" : "系统默认" }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" />
                    <el-table-column prop="createTime" label="创建时间" />
                    <el-table-column prop="updateTime" label="更新时间" />
                    <el-table-column prop="date" fixed="right" label="操作">
                        <template #default="scope">
                            <div v-if="scope.row.sourceFrom == 1">
                                <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="classify(scope.row, 2)">修改</a>
                                <a href="javascript:;" class="t_d_u color-409EFF tool_btn" @click="removeClassify(scope.row)">删除</a>
                            </div>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty description="暂无数据"></el-empty>
                    </template>
                </el-table>
                <div class="mt20 rx-bc">
		            <el-pagination
		              v-model:currentPage="pageData.classifyForm.page"
		              :page-sizes="[15,20,30,50,100]"
		              :page-size="pageData.classifyForm.limit"
		              layout="total, sizes, prev, pager, next, jumper"
		              :total="pageData.classifyForm.totalCount"
		              @size-change="classPageSizeChange"
		              @current-change="getClassifyList"
		            >
		            </el-pagination>
		        </div>
    
                <el-dialog
                    v-model="pageData.innerVisible"
                    width="500px"
                    :title="pageData.innerTitle"
                    :before-close="innerClose"
                    append-to-body
                  >
                    <template #default>
                        <el-form
                            class="p20"
                            ref="innerFormRef"
                            :model="innerForm"
                            :rules="innerRules"
                            label-width="100px"
                          >
                            <el-form-item label="分类名称" prop="title">
                                <el-input v-model="innerForm.title"></el-input>
                            </el-form-item>
                            <el-form-item label="类型" prop="type">
                                <el-select v-model="innerForm.type" placeholder="请选择类型">
                                    <el-option
                                        v-for="item in utils.getDict('waterKind')"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="备注">
                                <el-input
                                  v-model="innerForm.remark"
                                  :autosize="{ minRows: 4 }"
                                  type="textarea"
                                  placeholder="请输入内容"
                                >
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </template>
                    <template #footer>
                      <div class="dialog-footer">
                        <el-button @click="innerClose">取 消</el-button>
                        <el-button type="primary" @click="classifySubmit">确 定</el-button>
                      </div>
                    </template>
                </el-dialog>
            </div>
        </div>
        <template v-if="pageData.title != '费用类型'" #footer>
          <span class="dialog-footer">
            <el-button  @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
          </span>
        </template>
    </el-dialog>
</template>


<script setup>
    import {ref,reactive,onMounted,onActivated, nextTick} from 'vue';
    import {onBeforeRouteLeave,onBeforeRouteUpdate} from "vue-router";
    import {useStore} from 'vuex'
    import {useRouter} from 'vue-router';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import { Upload } from '@element-plus/icons-vue'
    import { ElMessageBox, ElMessage } from 'element-plus'
    import utils from '@/common/utils.js';
    import { getConfig, setConfig, tallyTypeLsit, tallyTypeUpdate, tallyTypeAdd, tallyTypeDelete, tallyAdd, tallyList, tallyDelete, tallyUpdate, tallyCheck } from '@/api/finance.js';
    import {  getStoreAccountStaffSimple } from '@/api/staff.js'
    const formRef = ref()
    const innerFormRef = ref()
    const queryOptsRef = ref()
    const classQueryRef = ref()
    const router = useRouter();
    const store = useStore();
    const queryOpts = reactive({}); // 课程列表
    const innerQueryOpts=reactive({}) // 分类列表搜索表单
    const queryStatisticsOpts = reactive({}); // 训练目的
    const pageData = reactive({
        dialogVisible: false, // 弹框显隐
        innerVisible: false, // 新增、编辑分类弹框
        loading: false,
        query : {
            totalCount : 0,
            searchForm : {
                page : 1,
                limit : 15
            },
            tableData : [],
            remark: {}
        },
        classifyForm:{
            totalCount : 0,
            page : 1,
            limit : 15
        },
        checkType: 0, // 是否需要审核 0-不 1-需要
        checkUserList:[], // 审核人员列表
        checkUser: [], // 需要审核的人员id
        title: '', // 弹框标题
        width: '', // 弹框宽度
        courseList: [], // 类型数据
        staffList: [], // 收入人员数据
        innerTitle: '', // 分类弹框标题
        classifyTableData: [], // 分类列表数据
    });
    function filtersType(value) {
        let text = ''
        utils.getDict('waterPayType').forEach((item) => {
            if (item.value == value) {
                text = item.label
            }
        })
        return text
    }
    const form = ref({
        typeId: '',
        amount: '',
        happenBy: '', 
        payMethodId: '', 
        happenTimeStr: '',
        remark:''    
    })
     const rules = {
        typeId: [{ required: true, message: '请选择类型', trigger: 'change' }],
        amount: [
            { required: true, trigger: 'blur', message:'请输入金额'},
            { pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/, message: '请输入正确额格式,可保留两位小数' }
        ],
        payMethodId: [{ required: true, message: '请选择支付方式', trigger: 'change' }],
    };
    const examineFormReForm = ref({
        remark: '',
        state: 2
    })
    const innerForm = ref({
        title: '',
        type: '',
        remark: ''
    })
    const innerRules = {
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        title: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
    };
    const timerList = [
        { value : "1" , label : '今天' },
        { value : "2" , label : '昨天' },
        { value : "5" , label : '最近7天' },
        { value : "7" , label : '最近30天' },
        { value : "-1" , label : '其他' }
    ]
    const typeIdOptions = reactive([]);
    const updateByOptions = reactive([{label : '全部操作员' ,value : ''}]);
    queryOpts['fields'] = [{
        type : 'input',
        fieldName : 'memberName',
        placehold : '输入会员姓名',
        value: ''
    },{
        type : 'select',
        fieldName : 'datetype',
        placehold : '请选择时间',
        options : timerList,
        value: '5'
    },{
        showField : 'datetype', // 根据显示字段
        showValue : '-1', // 根据显示字段值
        type : 'dateRange',
        fieldName : 'searchTime',
        placeholder : '日期范围'
    },{
        type : 'select',
        fieldName : 'typeId',
        placehold : '请选择类型',
        options : typeIdOptions,
        value: ''
    },{
        type : 'select',
        fieldName : 'type',
        placehold : '请选择类别',
        options : utils.getDict('waterKind',{label : '全部类别' ,value : ''}),
        value: ''
    },{
        type : 'select',
        fieldName : 'state',
        placehold : '请选择状态',
        options : [{label : '全部状态' ,value : ''},{label : '待审核' ,value : '1'},{label : '已录入' , value: '2'},{label : '审核不通过' ,value : '0'}],
        value: ''
    },{
        type : 'select',
        fieldName : 'payMethodId',
        placehold : '请选择支付方式', 
        options : utils.getDict('waterPayType',{label : '全部支付方式' ,value : ''}),
        value: ''
    },{
        type : 'select',
        fieldName : 'updateBy',
        placehold : '请选择操作员',
        options : updateByOptions,
        value: ''
    }];
    innerQueryOpts['fields'] = [{
        type : 'input',
        fieldName : 'searchKeys',
        placehold : '输入分类名称、备注'
    },{
        type : 'select',
        fieldName : 'type',
        placehold : '',
        options : utils.getDict('waterKind',{label : '全部类别' ,value : ''}),
        value:''
    }]
    /**
     * 收支类型
     */
    function getTypeIdOptions() {
        tallyTypeLsit().then((res) => {
            if (res.code == 0) {
               for(var i=0;i< res.data.length;i++){
                    let params = {}
                    params.label = res.data[i].title
                    params.value = res.data[i].id
                    typeIdOptions.push(params);
                }
               typeIdOptions.unshift({label: '全部类型',value:''})
            }
        })
    }
    /**
     * 操作员
     */
    function getUpdateByOptions() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 1
        }
        getStoreAccountStaffSimple(params).then((res) => {
           if (res.code == 0) {
                for(var i=0;i< res.data.length;i++){
                    let params = {}
                    params.label = res.data[i].realName
                    params.value = res.data[i].id
                    updateByOptions.push(params)
                }
            }
        })
    }

    /**
     * @param (Object) row 订单数据
     * 订单详情
     */
    const details=(row)=>{
        router.push({path:'/branch/orderDetail',query:{encryptionId: row.encryptionFinanceId}});
    }
    //切换分页数量
	function pageSizeChange(limit){
		pageData.query.searchForm.limit = limit;
		getList();
	}
	function searchGetList() {
	   pageData.query.searchForm.page = 1;
       getList()
	}
    //切换分页数量
	function classPageSizeChange(limit){
		pageData.classifyForm.limit = limit;
		getClassifyList();
	}
	function classSearchGetList() {
	   pageData.classifyForm.page = 1;
       getClassifyList()
	}
    // 查询数据列表
    function getList(isExport){
        let params = {}
		Object.assign(params, queryOptsRef.value.getQueryForm())
		params.limit = pageData.query.searchForm.limit
		params.page =  pageData.query.searchForm.page
        params.timeIn= params.datetype
        if (params.datetype == '-1' && !params.searchTime) {
            ElMessage.warning('请选择自定义时间')
            return
        }
        if (params.datetype == '-1' && params.searchTime) {
            params.searchTime = params.searchTime.join('-')
        }
        pageData.loading = true
		if(isExport){ //导出
			params.limit = 9999;
			params.page = 1;
			params.exportData = 1;
		}
        tallyList(params).then((res) => {
            pageData.loading = false
            if (res.code == 0) {
				if(isExport){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.query.tableData = res.data
					pageData.query.totalCount = res.count
					pageData.query.remark = res.remark
				}
            }
        }).catch(() => {
            pageData.loading = false
        })
    }
    /**
     * 查询分类列表
     */
    const getClassifyList=(data)=>{
        let params = {}
        data ? Object.assign(params, data) : Object.assign(params, classQueryRef.value.getQueryForm())
        params.limit = pageData.classifyForm.limit
		params.page = pageData.classifyForm.page
        tallyTypeLsit(params).then((res) => {
            if (res.code == 0) {
               pageData.classifyForm.totalCount = res.count
               pageData.classifyTableData = res.data
            }
        })
    }
    /**
     * 取消新增、编辑弹框
     */
    const handleClose=() => {
        if ( pageData.title == '审核账单') {
            examineFormReForm.value.remark = ''
            examineFormReForm.value.state = 1
        } else if (pageData.title != "审核设置" && pageData.title != "费用类型") {
            formRef.value.resetFields()
            form.value.remark = ''
            form.value.happenBy = ''
            form.value.happenTimeStr = ''
        }
       pageData.dialogVisible = false
    }
    /**
     * 费用类型拉起弹框
     */
    const costType=()=>{
        pageData.title = "费用类型"
        pageData.width = '800px'
        pageData.dialogVisible = true
        nextTick(()=>{
            getClassifyList()
        })
    }
    /**
     * 取消新增或编辑分类弹框
     */
    const innerClose=()=>{
        innerFormRef.value.resetFields()
        pageData.innerVisible = false
    }
    /**
     * @param(Obj||Num, Num) row 分类数据||0 type 1-新增 2-编辑
     * 新增或编辑分类拉起弹框
     */
    const classify=(row, type)=>{
        pageData.innerTitle = type == 1 ? '新增分类' : '编辑分类'
        if (type == 2) {
            innerForm.value =  Object.assign({}, row) 
            innerForm.value.type = row.type + ''
        } else {
            innerForm.value.remark = ''
            innerForm.value.title = ''
        }
        pageData.innerVisible = true
    }
    /**
     * 确定新增或编辑分类
     */
    const classifySubmit=()=>{
        innerFormRef.value.validate((valid) => {
            if (valid) {
                let params = {
                        title: innerForm.value.title,
                        type: innerForm.value.type,
                        remark: innerForm.value.remark
                    }
                if (pageData.innerTitle == '新增分类' ) {
                    tallyTypeAdd(params).then((res) => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            pageData.innerVisible = false
                            getClassifyList()
                        }
                    })
                } else {
                    params.encryptionId= innerForm.value.encryptionId,
                    tallyTypeUpdate(params).then((res) => {
                        if (res.code == 0) {
                            ElMessage.success('编辑成功')
                            pageData.innerVisible = false
                            getClassifyList()
                        }
                    })
                }
            innerClose()
            } else {
              console.log('error submit!!');
              return false;
            }
        });
    }
    /**
     * @param(Number) type 1-新增收入 2-新增支出
     * 拉起新增收入/支出弹框
     */
    const pullUp=(type)=>{
        let params ={}
        if (type == 1) {
           pageData.title = "新增收入"
           params.type = 1
           params.sourceFrom = 1
        } else {
           pageData.title = "新增支出"
           params.type = 0
           params.sourceFrom = 1
        }
        pageData.width = '600px'
        form.value = {
            typeId: '',
            amount: '',
            happenBy: '', 
            payMethodId: '', 
            happenTimeStr: '',
            remark:''    
        }
        getClassifyList(params)
        getPersonnel()
        pageData.dialogVisible = true
    }
    /**
     * @param(Object) row 收支数据
     * 编辑修改弹框
     */
    const edit=(row)=>{
        pageData.title = "修改账单"
        pageData.width = '600px'
        getPersonnel()
        form.value = Object.assign({}, row)
        let params ={}
        params.type = row.type
        params.sourceFrom = 1
        getClassifyList(params)
        form.value.happenBy = Number(form.value.happenBy)
        form.value.typeId = form.value.typeId
        form.value.payMethodId = form.value.payMethodId +''
        pageData.dialogVisible = true
       
    }
    /**
     * @param(Object) row 明细数据
     * 拉起审核弹框
     */
    const examine=(row)=>{
        pageData.title = '审核账单'
        examineFormReForm.value =  Object.assign({}, row)
        examineFormReForm.value.state = 2
        pageData.dialogVisible = true
    }
    
    /**
     * @param(Object) row 明细数据
     * 删除分类
     */
    const removeClassify=(row)=>{
        ElMessageBox.confirm('确定删除该分类吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            tallyTypeDelete({ encryptionId: row.encryptionId }).then((res)=> {
                if (res.code == 0) {
                    ElMessage.success('删除成功')
                    getClassifyList()
                }
            })
        })
    }
    /**
     * @param(Object) row 明细数据
     * 删除明细
     */
    const remove=(row)=>{
        ElMessageBox.confirm('确定删除该记录吗？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
        }).then(() => {
            tallyDelete({ encryptionId: row.encryptionId}).then((res) => {
                if (res.code == 0) {
                    ElMessage.success('删除成功')
                    getList()
                }
            })
        })
    }
    /**
     * 获取审核人员或者是收入支出人员
     */
    function getPersonnel() {
        let params = {
            page:1,
            limit:1000,
            delFlag: 0
        }
        getStoreAccountStaffSimple(params).then((res) => {
           if (res.code == 0) {
                pageData.checkUserList = res.data
            }
        })
    }
    /**
     * 审核设置弹框
     */
    const setExamine=()=>{
        getConfig().then((res) => {
            if (res.code == 0) {
                pageData.checkType = res.data.checkType
            }
        })
        getPersonnel()
        pageData.title = "审核设置"
        pageData.width = '600px'
        pageData.dialogVisible = true
    }
    /**
     * 弹框确定
     */
    const onSubmit=()=>{
        if(pageData.title == "审核设置") {
            let params = {
                checkType: pageData.checkType,
                sysUserIds: pageData.checkUser.join(',')  
            }
            setConfig(params).then((res) => {
                if (res.code == 0) {
                    ElMessage.success('设置成功')
                    pageData.dialogVisible = false
                }
            })
        }else if(pageData.title == '审核账单'){
            let params = {
                encryptionId: examineFormReForm.value.encryptionId,
                state: examineFormReForm.value.state,
                remark: examineFormReForm.value.remark,
            }
            tallyCheck(params).then((res) => {
                if (res.code == 0) {
                    ElMessage.success('审核成功')
                    getList()
                    pageData.dialogVisible = false
                }
            })
        } else if (pageData.title != "审核设置" && pageData.title != "费用类型") {
           formRef.value.validate((valid) => {
             if (valid) {
                let params = {
                    typeId: form.value.typeId,
                    happenBy: form.value.happenBy,
                    happenTimeStr: form.value.happenTimeStr,
                    payMethodId: form.value.payMethodId,
                    amount: form.value.amount,
                    remark: form.value.remark,
                }
                if (pageData.title == "修改账单") {
                    params.encryptionId = form.value.encryptionId
                    tallyUpdate(params).then((res) => {
                        if (res.code == 0) {
                            ElMessage.success('修改成功')
                            getList()
                            pageData.dialogVisible = false
                        }
                    })
                } else {
                    if (pageData.title == '新增收入') {
                        params.type = 1
                    } else if (pageData.title == '新增支出') {
                        params.type = 0
                    }
                    tallyAdd(params).then((res) => {
                        if (res.code == 0) {
                           ElMessage.success('新增成功')
                           getList()
                           pageData.dialogVisible = false
                        }
                    })
                }
             } else {
               console.log('error submit!!');
               return false;
             }
         });
       }
    }
    
    onMounted(()=>{
        getUpdateByOptions()
        getTypeIdOptions()
        getList()
    })
</script>
<style type='css/style' scoped>
    .setExamine /deep/ .el-select{
        width: 400px !important;
    }
    .formRef /deep/ .el-input,.formRef /deep/ .el-textarea{
        width: 400px;
    }
    .formRef /deep/ .el-input-number.is-without-controls .el-input__inner{
        text-align: left;
    }
    .tips {
        display: inline-block;
        padding: 0 6px;
        font-size: 12px;
        text-align: center;
        background-color: #FF5722;
        color: #fff;
        border-radius: 2px;
        height: 18px;
        line-height: 18px;
    }
</style>


