<template>
	<workArea :barlist="info.barlist">
	<div class="bg-fff hh100 o_a pl10 pt10 pr10 pb10">
		<div class="rx-sc mb12">
			<img class="w240 h160 mr20" :src="info.courseCover" alt="">
			<div class="flex1">
				<div class="rx-sc mb12">
					<span class="mr10 pl5 pr5 box-3FC9FF br-2px" style="height: 26px;line-height: 26px;" :class="{'box-FA7D29':info.courseType == 11,'box-9476FF':info.courseType == 15}">{{info.courseType == 11 ? '团体课' :info.courseType == 12 ? '私教课' : info.courseType == 14 ?'私教1v1':info.courseType == 15?'私教1v多':''}}</span>
					<b class="ft16 color-47545C">{{info.courseName}}</b>
				</div>
				<div class="rx-sc pl10 pt10 pb10 bg-FFF5ED mb12">
					<span class="color-666 fw400">售价：</span>
					<b class="color-red">
						<span class="ft24">￥{{info.priceType == 1 ? info.coursePrice : '-'}}</span>
						<span>/节</span>
					</b>
				</div>
				<div class="color-333 mb12">单课时长：{{info.courseTime}}分钟</div>
				<div class="rx-sc ">
					<span class="mr10 color-999 pl5 pr5 trainPurpose" v-for="item in info.trainPurposeName" :key="item">{{item}}</span>
				</div>
			</div>
		</div>
		<CommonTitle text="售卖信息"></CommonTitle>
		<div class="mb12 color-333 pl20">售卖方式：{{ info.sellType == 1 ? '线下售卖' : '不售卖'}}</div>
		<div v-if="info.sellType == 1" class="mb12 color-333 pl20">单次预约：<span>{{info.ifOrder ? '￥'+ info.orderPrice +'/节' : '不支持'}}</span></div>
		<CommonTitle text="上课门店"></CommonTitle>
		<div class="mb12">
			<el-table :data="info.storeList" stripe style="width: 100%" max-height="300">
			    <el-table-column prop="storeName" label="上课门店" />
			    <el-table-column prop="state" label="课程状态">
					<template #default="scope">
						<span class="stateIcon pl15" :class="{open:scope.row.state == 1}">{{scope.row.state == 1 ? '已开启' :'已关闭'}}</span>
					</template>
				</el-table-column>
			    <el-table-column prop="storeAdd" label="门店地址" show-overflow-tooltip />
				<el-table-column v-if="info.priceType == 2" label="课程单价">
					<template #default="scope">
						{{ scope.row.singleValue ? scope.row.singleValue + '元' : '-' }} 
					</template>
				</el-table-column>
			</el-table>
		</div>
		<CommonTitle text="绑定卡项"></CommonTitle>
		<div>
			<el-table :data="info.productOrderList" stripe style="width: 100%" max-height="300">
			    <el-table-column prop="productName" label="卡名称" width="300"/>
			    <el-table-column prop="type" label="卡类型" width="200" >
            <template #default="scope">
              <span>{{scope.row.type == 1 ? '课程卡' :scope.row.type == 2 ? '入场卡':scope.row.type == 3 ? '储值卡':'体验卡'}}</span>
            </template>
          </el-table-column>
			    <el-table-column prop="storeName" label="卡消费门店"  show-overflow-tooltip />
			    <el-table-column prop="consumeNum" label="每节课扣除次数" />
			</el-table>
		</div>
		<CommonTitle text="其他信息"></CommonTitle>
		<div class="rx-ss">
			<div class="mb12 color-333 pl20">课程介绍：</div>
			<div class="flex1" v-html="info.courseContent"></div>
		</div>
	</div>
	</workArea>
</template>

<script setup>
	import {reactive} from 'vue';
	import {useRouter} from 'vue-router';
	import {courseInfo} from '@/api/course.js'
	import CommonTitle from '@/components/CommonTitle.vue';
	import workArea from '@/components/branch-store/workArea.vue';
	const router = useRouter();
	const {id} = router.currentRoute.value.query;
	const info = reactive({
		courseCover:'',
		courseType:0,
		courseName:'',
		coursePrice:0,
		courseTime:0,
		trainPurposeName:[],
		orderPrice:0,
		ifOrder:'',
		priceType:0,
		storeList:[],
		productOrderList:[],
		courseContent:'',
		sellType: ''
	})
	
	courseInfo({
		courseNameId:id
	}).then((res)=>{
		if(res.code == 0){
			for(var key in info){
				info[key] = res.data[key]
			}
			info.trainPurposeName = res.data.trainPurposeName? res.data.trainPurposeName.split(',') : [];
			info.barlist = [{ name : '返回',type:'0' },{ name : '课程详情' }];
		}
	})
	
</script>

<style scoped lang="stylus">
	.box-FA7D29{
		border 1px solid #FA7D29;
		color #FA7D29;
		background-color #FEE5D4;
	}
	.box-9476FF{
		border 1px solid #9476FF;
		color #9476FF;
		background-color #EAE4FF;
	}
	.box-3FC9FF{
		border 1px solid #3FC9FF;
		color #3FC9FF;
		background-color #D9F4FF;
	}
	.bg-FFF5ED{
		background-color #FFF5ED;
	}
	.color-47545C{
		color #47545C;
	}
	.br-2px{
		border-radius 2px;
	}
	.trainPurpose{
		background-color #F5F5F5;
	}
	.stateIcon{
		position relative;
	}
	.stateIcon::after{
			content '';
			position absolute;
			left 0;
			top 50%;
			transform translateY(-50%);
			width 8px;
			height 8px;
			border 2px solid #FBE3E3;
			background-color #FE8585;
			border-radius 100%;
		}
	.stateIcon.open::after{
		content '';
		position absolute;
		left 0;
		top 50%;
		transform translateY(-50%);
		width 8px;
		height 8px;
		border 2px solid #DAF7F0;
		background-color #44D7B6;
		border-radius 100%;
	}
</style>
