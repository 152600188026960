<template>
    <workArea :barlist="pageData.barlist">
        <div class="p20">
            <div class="top rx-c mb20">
                <el-button class="mr16" type="primary" @click="addPlace" v-if="utils.getMenuRole(33301)">新增场地</el-button>
                <el-form :inline="true" :model="pageData.formInline" class="demo-form-inline">
                    <el-form-item>
                       <el-input v-model="pageData.formInline.roomName" placeholder="场地名称" clearable />
                    </el-form-item>
                    <el-form-item>
                        <el-select v-model="pageData.formInline.state" >
                            <el-option label="全部状态" value="" />
                            <el-option label="关闭" value="0" />
                            <el-option label="开启" value="1" />
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" :icon="Search" @click="search">搜索</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="content" v-loading="pageData.loading">
                <el-table :data="pageData.tableData" stripe >
			    	<el-table-column prop="roomName" label="场地名称" show-overflow-tooltip>
                        <template  #default="scope">
                            <a v-if="utils.getMenuRole(33302)" href="javascript:;" class="color-409EFF tool_btn ft12" @click="edit(scope.row)">{{ scope.row.roomName }}</a>
                            <span v-else>{{ scope.row.roomName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="containNum" label="容纳人数"/>
			    	<el-table-column prop="remark" label="备注" show-overflow-tooltip>
                        <template  #default="scope">
                            {{ scope.row.remark ? scope.row.remark : '-' }}
                        </template>
                    </el-table-column>
			    	<el-table-column label="状态" v-if="utils.getMenuRole(33304)">
			    		<template #default="scope">
                            <el-switch
                                v-model="scope.row.state"
                                size="large"
                                :width="60"
                                :active-value="1"
                                :inactive-value="0"
                                inline-prompt
                                active-text="开启"
                                inactive-text="关闭"
                                disabled 
                                @click="changeval(scope.row)"
                            />
                        </template>
			    	</el-table-column>
			    	<el-table-column label="操作" >
			    		<template #default="scope">
			    			<a href="javascript:;" v-if="utils.getMenuRole(33303)" class="t_d_u color-EE4B36 ft12" @click="remove(scope.row.encryptionId)">删除</a>
			    		</template>
			    	</el-table-column>
			    </el-table>
                <div class="mt20 rx-ec">
		            <el-pagination
		                v-model:currentPage="pageData.page"
		                :page-sizes="[15,20,30,50,100]"
		                :page-size="pageData.limit"
		                layout="total, sizes, prev, pager, next, jumper"
		                :total="pageData.totalCount"
		                @size-change="classPageSizeChange"
                        @current-change="pageChange"
		            >
		            </el-pagination>
		        </div>
            </div>
        </div>
        <el-dialog
            v-model="pageData.dialogVisible"
            :title="pageData.title"
            width="530px"
            :before-close="handleClose"
          >
            <div class="p20 pr80">
                <el-form ref="formRef" :model="pageData.dialogForm" :rules="rules" label-width="120px">
                    <el-form-item label="场地名称：" prop="roomName">
                        <el-input v-model="pageData.dialogForm.roomName" maxlength="10" placeholder="请输入（10字内）" />
                    </el-form-item>
                    <el-form-item label="可容纳人数：">
                        <el-input v-model.number="pageData.dialogForm.containNum" maxlength="4" max="9999" id="txt" oninput="value=value.replace(/[^0-9]/g,'')">
                            <template #append>人</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="备注：">
                        <el-input
                          v-model="pageData.dialogForm.remark"
                          :rows="4"
                          maxlength="50"
                          show-word-limit
                          type="textarea"
                          placeholder="请输入备注"
                        />
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
              </span>
            </template>
        </el-dialog>
        <Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
    </workArea>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { Search } from '@element-plus/icons-vue'
    import { ElMessage } from 'element-plus'
    import Confirm from '@/components/Confirm.vue'
    import workArea from '@/components/branch-store/workArea.vue';
    import utils from '@/common/utils.js'; 
    import { sportRoomList, sportRoomUpdateState, sportRoomInsert, sportRoomInfo, sportRoomUpdate, sportRoomDelete } from '@/api/place.js';
    const confirmRef = ref()
    const formRef=ref()
    const pageData = reactive({
        barlist:[{ name : '首页' },{ name : '机构中心' },{ name : '场地管理' }],// 面包屑
        loading: false,
        formInline:{
            roomName: '',
            state: ''
        },
        tableData:[],
        page: 1,
        limit: 15,
        totalCount: 0,
        title: '',
        dialogVisible: false, // 弹框显隐
        dialogForm: {
            roomName:'',
            containNum: '',
            remark: '',
            ifContinue:0
        },
        confirmTitle:'',
		confirmText:'',
    })
    const rules = reactive({
        roomName: [{ required: true, message: '请输入场地名称', trigger: 'blur' }],
    })
    /**
     * 搜索
     */
    function search() {
        pageData.page = 1
        getList()
    }
    /**
     * 获取场地列表数据
     */
    function getList() {
        pageData.loading = true
        let params = {
            roomName: pageData.formInline.roomName,
            state: pageData.formInline.state,
            purposeClass: 1,
            page: pageData.page,
            limit: pageData.limit
        }
        sportRoomList(params).then(res => {
            pageData.loading = false
            if (res.code == 0) {
                pageData.tableData = res.data
                pageData.totalCount = res.count
            }
        }).catch(()=>{
            pageData.loading = false
        })
    }
    /**
     * 列表一页几条
     */
    function classPageSizeChange(val) {
        pageData.limit = val
    }
    /**
     * 列表翻页
     */
    function pageChange(val) {
        pageData.page = val
        getList()
    }
    /**
     * 拉起新增场地弹框
     */
    function addPlace() {
        pageData.title = '新增场地'
        pageData.dialogForm = {
            roomName:'',
            containNum: '',
            remark: '',
            ifContinue:0
        }
        pageData.dialogVisible = true
    }
    /**
     * 编辑场地
     */
    let encryptionId = ''
    function edit(row) {
        pageData.title = '编辑场地'
        encryptionId = row.encryptionId
        sportRoomInfo({encryptionId: encryptionId }).then(res => {
            if (res.code == 0) {
                pageData.dialogForm = Object.assign({}, res.data)
                pageData.dialogForm.ifContinue = 0
                pageData.dialogVisible = true
            }
        })
    }
    /**
     * 取消弹框
     */
    function handleClose() {
        formRef.value.resetFields()
        pageData.dialogVisible = false
    }
    /**
     * 提交弹框
     */
    function onSubmit() {
         pageData.dialogForm.containNum = document.getElementById("txt").value
        formRef.value.validate((valid) => {
            if (valid) {
                let params = {
                    roomName: pageData.dialogForm.roomName,
                    containNum: pageData.dialogForm.containNum,
                    remark: pageData.dialogForm.remark,
                    purposeClass: 1,
                    purposeOrder: 0,
                    ifContinue: 0
                }
                if (pageData.title == '新增场地') {
                    sportRoomInsert(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('新增成功')
                            pageData.dialogVisible = false
                            getList()
                        }
                    })
                } else {
                    params.encryptionId = encryptionId
                    sportRoomUpdate(params).then(res => {
                        if (res.code == 0) {
                            ElMessage.success('编辑成功')
                            pageData.dialogVisible = false
                            getList()
                        }
                    })
                }
            } else {
              console.log('error submit!!');
              return false;
            }
        })
    }
    /**
     * 状态开关
     */
    function changeval(row) {
        let params = {
            encryptionId: row.encryptionId,
            state: row.state ? 0 : 1
        }
        sportRoomUpdateState(params).then((res) => {
            if (res.code == 0) {
                ElMessage.success('设置成功')
                getList()
            }
        })
    }
    /**
     * 删除场地提示
     */
    var courseEId = '';
    function remove(id) {
        pageData.confirmTitle = '确定要删除该场地吗？';
		confirmRef.value.show();
		courseEId = id;
    }
    /**
     * 确定删除场地
     */
    function confirmSave(){
		sportRoomDelete({ encryptionId: courseEId }).then((res)=>{
			if(res.code == 0){
				ElMessage({
					type: 'success',
					message: '删除成功',
				})
				getList();
			}
		})
	}
    onMounted(() => {
        getList()  
    })
</script>
<style type='css/style' scoped>
    .top ::v-deep .el-form--inline .el-form-item {
        margin-right: 16px;
        margin-bottom: 0;
    }
</style>