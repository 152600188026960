import qs from 'qs'
import axios from 'axios'
import router from '@/router'
import { ElMessage } from 'element-plus'
import { getLocalStorage } from '../common/common.js';

var instance = axios.create({
    timeout: 120000
});

let messageInstance = null;
    const resetMessage = (options)=>{
      if(messageInstance){
        messageInstance.close()
      }
      messageInstance = ElMessage(options)
    }
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
    return config; // 在发送请求之前做些什么config.data = qs.stringify(config.data);
}, function (error) {
	resetMessage({
		message:'请求失败，请稍后再试！',
		type: 'warning'
	})
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
	if(response.data.code != 0){
		resetMessage({
			message:response.data.msg,
			type: 'warning'
		})
		if(response.data.code == -1){
			router.push('/login');
		}
	}
	return response.data;
}, function (error) {
	resetMessage({
		message:'请求失败，请稍后再试！',
		type: 'warning'
	})
    return Promise.reject(error);
});

export const postAjax = (url,data,isJson) => {
	const token = getLocalStorage('businessToken');
	// url += url.indexOf('?') == -1 ? '?graycode=keep4' : '&graycode=keep4';
	data = data || {};
	data['access_token'] = token;
	var reqData = {
	    url: url,
	    method: 'POST',
	    data:data,
		headers:{'access_token':token ? token : ''},
	}
	if(!isJson){
		reqData.transformRequest = [function (data) {
			data = qs.stringify(data);
			return data;
		}]
	}
	return instance.request(reqData);
}

export const getAjax = (url,data) => {
	const token = getLocalStorage('businessToken');
	url += url.indexOf('?') == -1 ? '?graycode=keep4' : '&graycode=keep4';
	data = data || {};
	data['access_token'] = token;
	return instance.request({
	    url: url,
	    method: 'GET',
	    params:data,
		headers: {'access_token':token ? token : ''}
	})
}






