<!-- 会员列表页面 -->
<template>
    <div class="bg-fff">
        <formQuery :queryOpts="pageData.queryOpts" @query="getMemberListPage"></formQuery>
        <!-- 按钮区域 -->
        <div class="rx-bc mb16">
			<div class="rx-sc">
				<el-button type="primary" @click="toAddMember" v-if="utils.getMenuRole(3130000)">新增正式会员</el-button>
				<el-button @click="followPeopleEvent" v-if="utils.getMenuRole(3130001)">分配跟进人</el-button>
				<el-button class="mr16"  @click="batchSetLabelEvent" v-if="utils.getMenuRole(3130002)">打标签</el-button>
				<!-- <el-button class="query_btn_red" size="small" @click="batchDelMember">批量删除</el-button> -->
				<el-dropdown @command="dropdownEvent">
					<el-button type="primary" plain  v-if="utils.getMenuRole(3130003) || utils.getMenuRole(3130004)">
						更多操作<el-icon class="el-icon--right"><ArrowDown /></el-icon>
					</el-button>
					<template #dropdown>
						<el-dropdown-menu>
							<el-dropdown-item command="1" v-if="utils.getMenuRole(3130003)">删除标签</el-dropdown-item>
							<el-dropdown-item command="2" v-if="utils.getMenuRole(3130004)">删除会员</el-dropdown-item>
						</el-dropdown-menu>
					</template>
				</el-dropdown>
			</div>
			<el-button @click="exportMember" :icon="Upload" v-if="utils.getMenuRole(3130005)" >导出</el-button>
        </div>
        <!-- 数据表格 -->
        <div class="">
            <el-table :data="pageData.memberList" stripe style="width: 100%" @selection-change="selectionChange">
                <el-table-column type="selection" width="55" />
                <el-table-column fixed prop="realName" label="会员姓名" show-overflow-tooltip >
                    <template #default="scope">
                        <span @click="toMemberDetail(scope.row.encryptionId)" class="color-409EFF c_p tool_btn">{{scope.row.realName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="telephoneNum" label="手机号" width="120" ></el-table-column>
                <el-table-column prop="channelName" label="会员来源" show-overflow-tooltip></el-table-column>
				<el-table-column prop="userTag" label="标签" min-width="100">
					<template #default="scope">
						<template v-if="scope.row.userTag && scope.row.userTag.length > 0">
							<span v-for="(item,index) in scope.row.userTag" :key="index" class="pl5 pr5 mr5 bdr2" :style="{color:'#'+item.fontColor,backgroundColor:'#'+item.backColor}">{{item.tagName}}</span>
						</template>
					</template>
				</el-table-column>
                <el-table-column prop="joinTime" label="入会时间"></el-table-column>
                <el-table-column prop="actualMoney" label="实际购买(元)"></el-table-column>
                <el-table-column prop="adviserNames" label="跟进人" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="130" >
                    <template #default="scope">
						<a href="javascript:;" class="t_d_u v_a_m color-409EFF tool_btn" v-if="utils.getMenuRole(3130006)" @click="toEdit(scope.row.encryptionId)">编辑</a>
						<el-dropdown class="v_a_m" @command="(res)=>{dropdownSign(scope.row,res)}" @visible-change="(bool)=> {changeDropdown(scope.row, bool)}">
							<span v-if="utils.getMenuRole(3130007)">
								<a href="javascript:;" class="Sign t_d_u color-409EFF">签单</a>
								<el-icon v-show="!scope.row.bool" class="el-icon--right color-409EFF"><ArrowDown /></el-icon>
								<el-icon v-show="scope.row.bool" class="el-icon--right color-409EFF"><ArrowUp /></el-icon>
							</span>
							<template #dropdown>
								<el-dropdown-menu>
									<el-dropdown-item command="1">课程卡签单</el-dropdown-item>
									<el-dropdown-item command="2">入场卡签单</el-dropdown-item>
									<el-dropdown-item command="3">储值卡签单</el-dropdown-item>
									<el-dropdown-item command="4">体验卡签单</el-dropdown-item>
									<el-dropdown-item command="11">团体课签单</el-dropdown-item>
									<el-dropdown-item command="12">私教课签单</el-dropdown-item>
									<el-dropdown-item command="13">小班课签单</el-dropdown-item>
								</el-dropdown-menu>
							</template>
						</el-dropdown>
                    </template>
                </el-table-column>
				<template #empty>
                    <el-empty description="暂无数据"></el-empty>
                </template>
            </el-table>
            <div class="mt20 rx-ec">
                <el-pagination
                  v-model:currentPage="pageData.searchForm.page"
                  :page-sizes="[15,20,30,50,100]"
                  :page-size="pageData.searchForm.limit"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageData.totalCount"
                  @size-change="pageSizeChange"
                  @current-change="()=>{getList()}"
                >
                </el-pagination>
            </div>
        </div>
<!-- 分配跟进人 -->
		 <el-dialog
		     v-model="pageData.followPeopleShow"
		     title="选择跟进人"
		 	width="400px"
		     :before-close="()=>{pageData.followPeopleShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">跟进人：</span>
					<div>
						<el-select multiple collapse-tags v-model="pageData.followPeopleId" placeholder="请选择">
							<el-option v-for="(item,index) in pageData.followPeopleList" :key="item.id" :label="item.realName" :value="index">
							</el-option>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.followPeopleShow = false">取消</el-button>
		        	<el-button type="primary" @click="followPeopleSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>

		<!-- 批量打标签 -->
		 <el-dialog
		     v-model="pageData.batchSetLabelShow"
		     title="批量分配标签"
		 	 width="400px"
		     :before-close="()=>{pageData.batchSetLabelShow = false}"
		     custom-class="popwechat"
		   >
			<div class="pl20 pt50 pb50 pr20">
				<div class="rx-cc">
					<span class="">选择标签：</span>
					<div class="w200">
						<el-select class="query_con" v-model="pageData.batchSetLabelId" placeholder="请选择">
							<template v-for="item in pageData.queryOpts.fields[2].options">
								<el-option v-if="item.value" :key="item.value" :label="item.label" :value="item.eId"></el-option>
							</template>
						</el-select>
					</div>
				</div>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.batchSetLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="batchSetLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
		<!-- 批量删除标签 -->
		<el-dialog
		    v-model="pageData.deletLabelShow"
		    title="删除标签"
			width="600px"
		    :before-close="()=>{pageData.deletLabelShow = false}"
		    custom-class="popwechat"
		  >
		  <div class="pl20 pt50 pb50 pr20">
			  <div class="mb12">会员标签去重后如下,删除对应标签，点击【确认】即可批量去除会员名下对应标签。</div>
			  <ul class=" rx-sc">
				  <div class="pl5 pr5 bdr4 h20 ft12 bg-main rx-cc color-fff mr10" :style="{'background-color':'#'+ tag.backColor,'color':'#' + tag.fontColor}" v-for="(tag, index) in pageData.deletLabelList" :key="index">
                    {{tag.tagName}}
                    <img src="@/assets/branch/tag-delete.png" class="w15 h15 ml5 c_p" @click="delOneLabel(index)">
                  </div>
			  </ul>
		  </div>
		   <template #footer>
		    	<div class="dialog-footer">
		        	<el-button @click="pageData.deletLabelShow = false">取消</el-button>
		        	<el-button type="primary" @click="deletLabelSave">确定</el-button>
		    	</div>
		    </template>
		</el-dialog>
        <AddMember v-model="pageData.addMemberShow" :title="pageData.addMemberTitle" :id="pageData.memberId" @close="addMemberClose" @save="addMemberSave" flag="1"/>
		<Confirm ref="confirmRef" :title="pageData.confirmTitle" :text="pageData.confirmText" @save="confirmSave"/>
		<MemberSignature v-model="pageData.memberSignatureShow" :from="2" :type="pageData.memberSignatureType" :info="pageData.userInfo" @close="memberSignatureClose" @save="memberSignatureSave" />
		<ExportMember v-model="pageData.exportMemberShow" type="4" :data="pageData.searchForm" @close="exportMemberClose" @save="exportMemberSave"></ExportMember>
    </div>
</template>

<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter, onBeforeRouteLeave } from 'vue-router';
    import { useStore } from 'vuex'
    import { ElMessage } from 'element-plus'
    import { ArrowDown, Close, Upload, ArrowUp } from '@element-plus/icons-vue'
    import utils from '@/common/utils.js'
    import Confirm from '@/components/Confirm.vue'
    import { getMemberList } from '@/api/member.js';
    import { getStoreAccountStaffSimple, assignFollower, vipUpdateTagBatch, followUpTagList,deleteTagDetails,deleteMyCard} from '@/api/intentionList.js';
    import formQuery from '@/components/branch-store/formQuery.vue';
    import AddMember from '@/components/branch-store/member/AddMember.vue';
    import MemberSignature from '@/components/branch-store/member/MemberSignature.vue';
    import ExportMember from '@/components/branch-store/member/ExportMember.vue';
import { fa } from 'element-plus/lib/locale';
    const store = useStore();
    const router = useRouter();
    const pageData = reactive({
		exportMemberShow:false,
        addMemberTitle : '新增正式会员',
        memberId : '',
        addMemberShow:false, // 显示新增会员
        memberList : [],
        totalCount : 0,
        searchForm:{
            page:1,
            limit:15,
			searchKeys:'',
			channelId:'',
			tagList:'',
			joinTimeStr:'',
			adviserIds:''
        },
		followPeopleShow:false, //分配跟进人
		followPeopleList:[],
		followPeopleId:[],
		
		batchSetLabelShow:false, //批量打标签
		batchSetLabelId:'',
		confirmTitle:'', //通用确认框
		confirmText:'',
		
		memberSignatureShow : false, //签单
		memberSignatureType : 0,
		userInfo:'',
        queryOpts:{
            fields:[{
                type : 'input',
                fieldName : 'searchKeys',
                placehold : '会员姓名/手机号'
            },{
                type : 'select',
                fieldName : 'channelId',
                placehold : '会员来源',
                options : [],
                value:''
            },{
                type : 'selectMany',
                fieldName : 'tagList',
                placehold : '会员标签',
                options : [],
                value:''
            },{
                type : 'dateRange',
                fieldName : 'joinTimeStr',
                placeholder : '入会时间',
                options : [],
                value:''
            },{
                type : 'selectMany',
                fieldName : 'adviserIds',
                placehold : '跟进人',
                options : [],
                value:''
            }]
        }
    })
	var selectCheckData = [],confirmType = '';
	const confirmRef = ref();
	//获取字典
	utils.getDynaDict('followerList','',function(res){
	    pageData.queryOpts.fields[4].options = res;
	});
	utils.getDynaDict('channelList',{label : '会员来源' ,value : ''},function(res){
	    pageData.queryOpts.fields[1].options = res;
	});
	utils.getDynaDict('upTagList','',function(res){
	    pageData.queryOpts.fields[2].options = res;
	});
	function changeDropdown(row, bool) {
		row.bool = bool ? true : false
	}
	// 导出
	function exportMember(){
		pageData.exportMemberShow = true;
	}
	function exportMemberSave(res){
		pageData.exportMemberShow = false;
		getList(res);
	}
	function exportMemberClose(){
		pageData.exportMemberShow = false;
	}
	
	
	
    function toMemberDetail(id){
        router.push('/branch/memberDetail?encryptionId='+id);
    }
    // 编辑会员
    function toEdit(encryptionId){
        pageData.addMemberTitle = "编辑正式会员";
        pageData.memberId = encryptionId;
        pageData.addMemberShow = true;
    }
    function toAddMember(){
        pageData.addMemberTitle = "新增正式会员";
        pageData.memberId = '';
        pageData.addMemberShow = true;
    }
    // 新增会员后
    function addMemberSave(res){
        pageData.addMemberShow = false;
        searchTable();
    }
    // 关闭新增会员
    function addMemberClose(){
        pageData.addMemberShow = false;
    }
	//筛选表单回调
	function getMemberListPage(options){
		for(var key in options){
			pageData.searchForm[key] = options[key];
		}
		pageData.searchForm.joinTimeStr =  pageData.searchForm.joinTimeStr && pageData.searchForm.joinTimeStr.join('-')
		searchTable();
	}
	//切换分页数量
	function pageSizeChange(limit){
		pageData.searchForm.limit = limit;
		searchTable();
	}
	function searchTable(){
		pageData.searchForm.page = 1;
		getList();
	}
	getList();
    function getList(exportObj){
		var formData = JSON.parse(JSON.stringify(pageData.searchForm));
		formData.tagList = formData.tagList && formData.tagList.length > 0 ? formData.tagList.join(',') : '';
		formData.adviserIds = formData.adviserIds && formData.adviserIds.length > 0 ? formData.adviserIds.join(',') : '';
		if(exportObj){ //导出
			exportObj.exportData = 1;
			formData.page = 1;
			formData.limit = 9999;
			Object.assign(formData,exportObj);
		}
        getMemberList(formData).then(function(res){
            if(res.code == 0){
				if(exportObj){ //导出
					ElMessage({
						type: 'success',
						message: '正在导出，请稍后前往下载中心下载'
					})
				}else{
					pageData.memberList = res.data || [];
					pageData.totalCount = res.count
				}
            }
        });
    }
	
	// 分配跟进人
	function followPeopleEvent(){
		CheckLengthCallBack(()=>{
			getStoreAccountStaffSimple().then((res)=>{
				if(res.code == 0){
					pageData.followPeopleList = res.data;
				}
			})
			pageData.followPeopleShow = true;
		})
	}
	
	// 分配跟进人保存
	function followPeopleSave(){
		if(pageData.followPeopleId.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择跟进人',
			})
			return false;
		}
		var arr = [];
		pageData.followPeopleId.map((n)=>{
			arr.push(pageData.followPeopleList[n]);
		})
		assignFollower({
			followerList:arr,
			studentIdList:getSelectCheckKey('cardMyId').split(','),
		}).then((res)=>{
			if(res.code == 0){
				pageData.followPeopleShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}

	
	// 批量打标签
	function batchSetLabelEvent(){
		CheckLengthCallBack(()=>{
			pageData.batchSetLabelShow = true;
		})
	}
	// 批量打标签保存
	function batchSetLabelSave(){
		vipUpdateTagBatch({
			idStr:getSelectCheckKey('encryptionId'),
			encryptionId:pageData.batchSetLabelId,
		}).then((res)=>{
			if(res.code == 0){
				pageData.batchSetLabelShow = false;
				ajaxSaveCallBack('分配成功');
			}
		})
	}
	//批量删除标签
	function delOneLabel(index){
		pageData.deletedLabelArr.push(pageData.deletLabelList[index].id);
		pageData.deletLabelList.splice(index,1);
	}
	function deletLabelSave(){
		if(pageData.deletedLabelArr.length == 0){
			pageData.deletLabelShow = false;
			return false;
		}
		var ids = pageData.deletedLabelArr.join(',');
		deleteTagDetails({
			ids:getSelectCheckKey('encryptionId'),
			tagIds:ids
		}).then((res)=>{
			if(res.code == 0){
				pageData.deletLabelShow = false;
				ajaxSaveCallBack('删除成功');
			}
		})
	}
	//下拉框按钮操作事件
	function dropdownEvent(data){
		CheckLengthCallBack(()=>{
			if(data == 1){
				pageData.deletedLabelArr = [];
				if(selectCheckData.length == 1 && (!selectCheckData[0].userTag || selectCheckData[0].userTag.length == 0)){
					ElMessage({
						type: 'warning',
						message: '该会员暂无标签可删除',
					})
					return false;
				}
				followUpTagList({
					ids:getSelectCheckKey('encryptionId')
				}).then((res)=>{
					if(res.code == 0){
						pageData.deletLabelList = res.data;
						pageData.deletLabelShow = true;
					}
				})
			}else{
				pageData.confirmTitle = '是否删除勾选的会员？';
				confirmType = 'delete';
				confirmRef.value.show();
			}
		})
	}
	//通用确认框确认事件回调
	function confirmSave(){
		if(confirmType == 'delete'){
			deleteMyCard({
				ids:getSelectCheckKey('encryptionId')
			}).then((res)=>{
				if(res.code == 0){
					ajaxSaveCallBack('删除成功');
				}
			})
		}
		
	}
	// //批量删除
	// function batchDelMember(){
	// 	CheckLengthCallBack(()=>{
	// 		pageData.confirmTitle = '是否删除勾选的会员？';
	// 		confirmType = 'delete';
	// 		confirmRef.value.show();
	// 	})
	// }
	// function confirmSave(){
	// 	if(confirmType == 'delete'){
	// 		deleteMyCard({
	// 			ids:getSelectCheckKey('encryptionId')
	// 		}).then((res)=>{
	// 			if(res.code == 0){
	// 				ajaxSaveCallBack('删除成功');
	// 			}
	// 		})
	// 	}
	// }
	
	//列表签单
	function dropdownSign(row,data){
		pageData.userInfo = row;
		pageData.memberSignatureType = data;
		pageData.memberSignatureShow = true;
	}
	// 保存会员签单
	function memberSignatureSave(res){
		memberSignatureClose();
		searchTable();
	}
	// 关闭会员签单
	function memberSignatureClose(){
	    pageData.memberSignatureShow = false;
	}
	
	
	
	//table通用勾选
	function selectionChange(data){
		selectCheckData = data;
	}
	//获取table勾选集合的某个字段集合
	function getSelectCheckKey(key){
	 	return selectCheckData.map(n=>n[key]).join(',');
	}
	// 验证批量操作是否勾选了数据
	function CheckLengthCallBack(callback){
		if(selectCheckData.length == 0){
			ElMessage({
				type: 'warning',
				message: '请选择需要操作的会员',
			})
			return false;
		}
		callback && callback();
	}
	//ajax提交保存通用提示
	function ajaxSaveCallBack(text,callback){
		ElMessage({
			type: 'success',
			message: text
		})
		searchTable();
		callback && callback();
	}
    onMounted(()=>{
    });
    // 页面缓存
    onBeforeRouteLeave((to, from)=>{
        // if (to.path === '/branch/memberDetail') {
        //     store.commit('SetKeep',{keepLiveRoute : ['BranchMemberList']});
        // } else {
        //     store.commit('SetKeep',{keepLiveRoute : []});
        // }
    })
</script>
<style scoped lang="stylus">
	.delTagList{
		li{
			border 1px solid #ccc;
			span:first-child{
				border-right 1px solid #ccc;
			}
		}
	}
	.Sign:hover{
		color: #2D6FB2;
	}
</style>